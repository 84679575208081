export enum Layout {
  NONE,
  LAYERS,
  CATALOG,
  MEASUREMENTS,
  CROSS_SECTION,
  SLICING,
  GEOLOCATION,
}

export enum PcTool {
  MOVE = 'MOVE',
  ROTATE = 'ROTATE',
}

export enum View {
  TOP = 'TOP',
  SIDE = 'SIDE',
}
