import React, { useState } from 'react'
import {
  SubmitButton,
  Stack,
  ActionButton,
  ConfirmationModal,
} from '@digitalreality/ui'
import {
  PointcloudIcon,
  RedoIcon,
  ResetIcon,
  UndoIcon,
} from '@digitalreality/ui-icons'
import { useSnackbar } from 'notistack'

interface IAlignmentHelperProps {
  undo: Function
  redo: Function
  reset: Function
  finalize: Function
  buttonActive: { undo: boolean; redo: boolean; reset: boolean }
}

export const AlignmentHelper = ({
  undo,
  redo,
  reset,
  finalize,
  buttonActive,
}: IAlignmentHelperProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [openResetModal, setOpenResetModal] = useState(false)
  // TODO find a better way to handle active buttons

  const handleClick = () => {
    setLoading(true)
    console.log('Send!')

    // TODO link with the real API
    setTimeout(() => {
      setLoading(false)
      enqueueSnackbar('Process completed!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      })
    }, 1000)
  }

  const handleReset = () => {
    reset()
    setOpenResetModal(false)
  }

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        borderRadius={2}
        padding={2}
        margin={1}
        bgcolor="background.paper"
        gap={1}
      >
        <ActionButton
          tooltip="Undo pointcloud transformation"
          icon={<UndoIcon />}
          onClick={() => undo()}
          size="small"
          disabled={!buttonActive.undo}
        />
        <ActionButton
          tooltip="Redo pointcloud transformation"
          icon={<RedoIcon />}
          onClick={() => redo()}
          size="small"
          sx={{ mr: '12px' }}
          disabled={!buttonActive.redo}
        />
        <ActionButton
          tooltip="Reset to initial pointcloud position"
          icon={<ResetIcon />}
          onClick={() => setOpenResetModal(true)}
          size="small"
          sx={{ mr: '18px' }}
          disabled={!buttonActive.reset}
        />
        {/* <Text variant="small" sx={{ pl: '8px', pr: '8px' }}>
          Align the 2nd pointcloud and press "align" to run the algorithm
        </Text> */}
        <SubmitButton
          size="small"
          onClick={() => finalize()}
          endIcon={<PointcloudIcon />}
          loading={loading}
          loadingPosition="end"
          variant="contained"
          color="secondary"
        >
          Finalize Alignment
        </SubmitButton>
      </Stack>
      <ConfirmationModal
        title="Reset position"
        icon={<ResetIcon fontSize="inherit" />}
        open={openResetModal}
        onConfirm={handleReset}
        onCancel={() => setOpenResetModal(false)}
        size="small"
      >
        Are you sure you want to reset to initial position?
      </ConfirmationModal>
    </>
  )
}
