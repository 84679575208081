import {KMLGroundOverlayFeature} from "@luciad/ria/model/kml/KMLGroundOverlayFeature";
import {KMLModel} from "@luciad/ria/model/kml/KMLModel";
import {KMLNetworkLinkFeature} from "@luciad/ria/model/kml/KMLNetworkLinkFeature";
import {createGroundOverlayLayer} from "@luciad/ria/util/kml/KMLUtil";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {LoadEverything} from "@luciad/ria/view/feature/loadingstrategy/LoadEverything";
import {KMLLayer} from "@luciad/ria/view/kml/KMLLayer";
import {Layer} from "@luciad/ria/view/Layer";
import {Map} from "@luciad/ria/view/Map";

/**
 * Automates the responses to KML events with behaviors:
 * <ul>
 *   <li>When a Network Link is discovered, it is automatically processed and added to the map as a new KMLLayer.
 *   <li>When a Ground Overlay is discovered, it is automatically processed and added to the map as a new RasterTileSetLayer.
 * </ul>
 * <p>All placemarks that have "visibility=0" in KML file will be still displayed on the map.</p>
 *
 * This KML layer manager only supports basic handling of Network Links and Ground overlays.
 * Please refer to the KML sample for extended behavior including support:
 * <lu>
 *   <li>for hierarchical KML data (documents & folders)</li>
 *   <li>refreshing network links</li>
 *   <li>creating UI component to control visibility of KML features</li>
 * </lu>
 *
 * @param kmlLayer the kml layer to automate creation of related layers for network links and overlays
 */
export function manageKMLLayer(kmlLayer: KMLLayer): void {
  addListeners(kmlLayer);
}

function addListeners(layer: FeatureLayer): void {
  (layer.model as KMLModel).on("KMLNetworkLink",
      (networkLink: KMLNetworkLinkFeature) => onKMLNetworkLinkEvent(networkLink, layer));
  (layer.model as KMLModel).on("KMLGroundOverlay",
      (groundOverlay: KMLGroundOverlayFeature) => onKMLGroundOverlayEvent(groundOverlay, layer));

  layer.workingSet.on("QueryFinished", () => onForceVisible(layer));
}

function onKMLNetworkLinkEvent(networkLink: KMLNetworkLinkFeature, kmlLayer: FeatureLayer): void {
  const {map} = kmlLayer;
  if (!map) {
    return
  }

  const networkLinkLayer = createLayerForNetworkLink(map, kmlLayer, networkLink);
  if (networkLinkLayer) {
    addListeners(networkLinkLayer);
    kmlLayer.parent!.addChild(networkLinkLayer, "above", kmlLayer);
  }
}

function onKMLGroundOverlayEvent(groundOverlay: KMLGroundOverlayFeature, kmlLayer: FeatureLayer): void {
  const {map} = kmlLayer;
  if (!map) {
    return
  }

  createLayerForGroundOverlay(map, kmlLayer, groundOverlay)?.then(layer => {
    if (layer) {
      kmlLayer.parent!.addChild(layer, "below", kmlLayer);
    }
  });
}

function createLayerForGroundOverlay(map: Map, masterLayer: FeatureLayer,
                                     groundOverlayFeature: KMLGroundOverlayFeature): Promise<Layer | null> | null {
  if (checkIfLayerExists(map, `${masterLayer.id}-${groundOverlayFeature.id}`)) {
    throw new Error("Cannot create a layer for KML Ground Overlay feature: layer already exists");
  }
  if (!masterLayer || !masterLayer.model ||
      !groundOverlayFeature || !groundOverlayFeature.shape || !groundOverlayFeature.shape.bounds ||
      !groundOverlayFeature.properties.icon || !groundOverlayFeature.properties.icon.href
  ) {
    throw new Error("Cannot create a layer for KML Ground Overlay feature: incomplete data");
  }

  const {id, properties} = groundOverlayFeature;
  const {name} = properties;

  return createGroundOverlayLayer(map, groundOverlayFeature, {
    layerOptions: {
      id: id as string,
      label: name as string
    }
  }).catch(e => {
    console.error(e);
    throw new Error(`Cannot create layer for GroundOverlay feature '${name}'. Check the console for details`);
  });
}

function createLayerForNetworkLink(map: Map, masterLayer: FeatureLayer,
                                   networkLink: KMLNetworkLinkFeature): KMLLayer | null {
  const {link, name} = networkLink.properties;
  const {href} = link;
  if (checkIfLayerExists(map, `${masterLayer.id}-${networkLink.id}`)) {
    throw new Error("Cannot create a layer for KML Network Link feature: layer already exists");
  }

  return new KMLLayer(new KMLModel(href as string), {
    id: `${networkLink.id}`,
    label: name as string,
    loadingStrategy: new LoadEverything(),
    selectable: true,
  });
}

function checkIfLayerExists(map: Map, layerId: string): boolean {
  return typeof map.layerTree.findLayerById(`${layerId}`) !== "undefined";
}

function onForceVisible(layer: FeatureLayer): void {
  let needInvalidate = false;
  layer.workingSet.get().forEach(kmlFeature => {
    if (!kmlFeature.properties.visibility) {
      // make the feature visible again
      kmlFeature.properties.visibility = true;
      needInvalidate = true;
    }
  });
  if (needInvalidate) {
    // there were some KML features invisible.
    layer.painter.invalidateAll();
  }
}