import { Controller } from '@luciad/ria/view/controller/Controller'
import { GeoCanvas } from '@luciad/ria/view/style/GeoCanvas'
import { OPTIONS_CHANGED, SliceSupport } from '../../alignment/SliceSupport'
import { createFacePolygons } from '../util/AdvancedShapeFactory'

export const DRAW = 'DrawSliceController'
export class DrawSliceController extends Controller {
  private _sliceSupport: SliceSupport

  constructor(sliceSupport: SliceSupport) {
    super()
    this._sliceSupport = sliceSupport
    const handleBox = this._sliceSupport.on(OPTIONS_CHANGED, () =>
      this.invalidate()
    )
  }

  onDraw(geoCanvas: GeoCanvas) {
    if (
      !this._sliceSupport.topOrientedBox ||
      !this._sliceSupport.sideOrientedBox
    )
      return
    if (!this._sliceSupport.mainSliceEnabled) return
    const topCubePolys = createFacePolygons(this._sliceSupport.topOrientedBox)
    topCubePolys.forEach((p) => {
      geoCanvas.drawShape(p, {
        fill: { color: 'rgba(255,255,0, 0.05)' },
        stroke: { color: 'rgba(255,255,0, 0.8)' },
      })
    })

    const sideCubePolys = createFacePolygons(this._sliceSupport.sideOrientedBox)
    sideCubePolys.forEach((p) => {
      geoCanvas.drawShape(p, {
        fill: { color: 'rgba(255,0,255, 0.05)' },
        stroke: { color: 'rgba(255,0,255, 0.8)' },
      })
    })
  }
}
