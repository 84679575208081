import ReactDOM from 'react-dom';
import React from 'react';
import { getBearerToken } from './authentication/AuthenticationUtil';
import { LoginPage } from './authentication/LoginPage';
import { loadLicenseFromUrl } from '@luciad/ria/util/License';
import './common/typings/image-typings';

//workaround for https://github.com/mui/material-ui/issues/35287 (@mui clashes with react 17 typings)
declare global {
  namespace React {
    interface DOMAttributes<T> {
      onResize?: ReactEventHandler<T> | undefined;
      onResizeCapture?: ReactEventHandler<T> | undefined;
      nonce?: string | undefined;
    }
  }
}

console.log(
  `%c ${process.env.APP_VERSION}`,
  'font-weight: bold; font-size: 20px;color: blue;',
);

/**
 * Main render function of the LuciadRIA application template.
 * This is called once each time that the web page is loaded.
 */
async function render() {
  let bearerToken;
  try {
    bearerToken = await getBearerToken();
  } catch (e) {
    console.error(e);
  }
  let componentToRender;
  if (bearerToken) {
    //TODO: get the license from HxDR & load it in here
    await loadLicenseFromUrl('./licenses/luciadria_development.txt');
    const App = require('./App').App;
    componentToRender = <App />;
  } else {
    componentToRender = <LoginPage />;
  }
  ReactDOM.render(componentToRender, document.getElementById('root'));
}

render();
