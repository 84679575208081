import { Feature } from '@luciad/ria/model/feature/Feature'
import { FeatureModel } from '@luciad/ria/model/feature/FeatureModel'
import { MemoryStore } from '@luciad/ria/model/store/MemoryStore'
import { OGC3DTilesModel } from '@luciad/ria/model/tileset/OGC3DTilesModel'
import { CoordinateReference } from '@luciad/ria/reference/CoordinateReference'
import { getReference } from '@luciad/ria/reference/ReferenceProvider'
import { OrientedBox } from '@luciad/ria/shape/OrientedBox'
import { PointCoordinates } from '@luciad/ria/shape/PointCoordinate'
import { Polyline } from '@luciad/ria/shape/Polyline'
import { createShapeList, createPolyline } from '@luciad/ria/shape/ShapeFactory'
import { createGradientColorMap } from '@luciad/ria/util/ColorMap'
import { Vector3 } from '@luciad/ria/util/Vector3'
import { PerspectiveCamera } from '@luciad/ria/view/camera/PerspectiveCamera'
import {
  FeatureLayer,
  PerformanceHint,
} from '@luciad/ria/view/feature/FeatureLayer'
import { ParameterizedLinePainter } from '@luciad/ria/view/feature/ParameterizedLinePainter'
import { Map } from '@luciad/ria/view/Map'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import {
  cross,
  average,
  scalar,
  addArray,
  scale,
  add,
  Length,
  sub,
} from './Vector3Util'

const DEFAULT_GRID_COLOR = '#646464'
const GRID_SEGMENT_SIZES = [1, 2, 5, 10, 20, 50, 100]
const GRID_CENTER: Vector3 = { x: 0.1, y: 0.1, z: 0 }
const GRID_SIZE = 40000
const geodetic_ref = getReference('EPSG:4978')

export const addGrid = (
  RIAMap: WebGLMap | Map,
  asset?: OGC3DTilesModel | TileSet3DLayer
) => {
  const gridCenter = asset
    ? calculateBottomCenterPoint(asset.orientedBox)
    : GRID_CENTER
  const size = asset ? (asset.bounds.depth / 2) * 30 : GRID_SIZE
  const painter = createPainter(gridCenter, size)
  const gridLayer = new FeatureLayer(
    new FeatureModel(new MemoryStore(), { reference: geodetic_ref }),
    {
      painter,
      label: 'Grid-layer',
      id: `GridLayer-${RIAMap.domNode}`,
      performanceHints: {
        discretization: PerformanceHint.PREFER_PERFORMANCE,
        tessellation: PerformanceHint.PREFER_PERFORMANCE,
      },
    }
  )

  const northFacingCamera = (RIAMap.camera as PerspectiveCamera).lookFrom({
    eye: gridCenter,
    pitch: 0,
    yaw: 0,
    roll: 0,
  })
  const _segmentSize = calculateSegmentSize(size)
  const features = createGridFeatures(
    gridCenter,
    northFacingCamera.forward,
    cross(northFacingCamera.forward, northFacingCamera.up),
    size,
    _segmentSize
  )
  ;((gridLayer.model as FeatureModel).store as MemoryStore).clear()
  for (const feature of features) {
    ;(gridLayer.model as FeatureModel).add(feature)
  }
  RIAMap.layerTree.addChild(gridLayer)
}

function calculateBottomCenterPoint(box: OrientedBox) {
  if (!getReference('EPSG:4978').equals(box.reference)) {
    throw new Error(
      'Can only calculate the bottom center point if the box is referenced in a geocentrical reference '
    )
  }
  const corners = box.getCornerPoints()
  const downVector = average(box.getCornerPoints())
  corners.sort((a, b) => scalar(a, downVector) - scalar(b, downVector))

  return average(corners.slice(0, 4))
}

function calculateSegmentSize(totalSize: number) {
  const exactSegmentSize = totalSize / 150
  let i = 0
  let segmentSize = GRID_SEGMENT_SIZES[0]
  while (segmentSize < exactSegmentSize) {
    if (i < GRID_SEGMENT_SIZES.length - 1) {
      segmentSize = GRID_SEGMENT_SIZES[++i]
    } else {
      segmentSize += GRID_SEGMENT_SIZES[GRID_SEGMENT_SIZES.length - 1]
    }
  }
  return segmentSize
}

function createGridFeatures(
  center: Vector3,
  dir1: Vector3,
  dir2: Vector3,
  totalSize: number,
  segmentSize: number
) {
  const result: Feature[] = []
  const halfSize = totalSize / 2

  for (let i = 0; i < halfSize; i += segmentSize) {
    const lines: Polyline[] = []

    lines.push(
      toPolyLine(geodetic_ref, [
        addArray([center, scale(dir2, i), scale(dir1, -1 * halfSize)]),
        add(center, scale(dir2, i)),
        addArray([center, scale(dir2, i), scale(dir1, halfSize)]),
      ])
    )
    lines.push(
      toPolyLine(geodetic_ref, [
        addArray([center, scale(dir1, i), scale(dir2, -1 * halfSize)]),
        add(center, scale(dir1, i)),
        addArray([center, scale(dir1, i), scale(dir2, halfSize)]),
      ])
    )

    if (i !== 0) {
      lines.push(
        toPolyLine(geodetic_ref, [
          addArray([center, scale(dir2, -i), scale(dir1, -1 * halfSize)]),
          add(center, scale(dir2, -i)),
          addArray([center, scale(dir2, -i), scale(dir1, halfSize)]),
        ])
      )

      lines.push(
        toPolyLine(geodetic_ref, [
          addArray([center, scale(dir1, -i), scale(dir2, -1 * halfSize)]),
          add(center, scale(dir1, -i)),
          addArray([center, scale(dir1, -i), scale(dir2, halfSize)]),
        ])
      )
    }

    const alpha = Math.pow(1 - Math.abs(i) / halfSize, 4) * 0.999
    result.push(new Feature(createShapeList(geodetic_ref, lines), { alpha }))
  }

  return result
}

function toPolyLine(reference: CoordinateReference, vectors: Vector3[]) {
  return createPolyline(
    reference,
    vectors.map(({ x, y, z }) => [x, y, z] as PointCoordinates)
  )
}

function createPainter(gridCenter: Vector3, gridSize: number) {
  const colorMap: any[] = []
  for (let i = 0; i < 1; i += 0.05) {
    colorMap.push({
      level: i,
      color: `rgba(255, 255, 255, ${Math.pow(i, 6)})`,
    })
  }

  return new ParameterizedLinePainter({
    defaultColor: DEFAULT_GRID_COLOR,
    rangeColorMap: createGradientColorMap(colorMap),
    rangePropertyProvider: (feature, shape, pointIndex) =>
      (1 -
        Length(sub((shape as Polyline).getPoint(pointIndex), gridCenter)) /
          gridSize) *
      0.999,
  })
}
