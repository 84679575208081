// Utility to create an easing function based on the Bezier functions.
// It is used to compute animation easings for the navigation controllers based on the initial velocity.
import {clamp} from '../../common/util/Math';
import {EasingFunction} from './MoveSpeedSupport';

const NEWTON_ITERATIONS = 4;
const NEWTON_MIN_SLOPE = 0.001;
const SUBDIVISION_PRECISION = 0.0000001;
const SUBDIVISION_MAX_ITERATIONS = 10;

const kSplineTableSize = 11;
const kSampleStepSize = 1.0 / (kSplineTableSize - 1.0);

const A = (v1: number, v2: number): number => 1.0 - 3.0 * v2 + 3.0 * v1;
const B = (v1: number, v2: number): number => 3.0 * v2 - 6.0 * v1;
const C = (v1: number): number => 3.0 * v1;
const Identity = (f: number): number => f;

// Returns x(t) given t, x1, and x2, or y(t) given t, y1, and y2.
function calcBezier(f: number, v1: number, v2: number): number {
  return ((A(v1, v2) * f + B(v1, v2)) * f + C(v1)) * f;
}

// Returns dx/dt given t, x1, and x2, or dy/dt given t, y1, and y2.
function getSlope(f: number, v1: number, v2: number): number {
  return 3.0 * A(v1, v2) * f * f + 2.0 * B(v1, v2) * f + C(v1);
}

function binarySubdivide(
  aX: number,
  aA: number,
  aB: number,
  mX1: number,
  mX2: number
): number {
  let currentT, currentX;
  let i = 0;
  do {
    currentT = aA + (aB - aA) / 2.0;
    currentX = calcBezier(currentT, mX1, mX2) - aX;
    if (currentX > 0.0) {
      aB = currentT;
    } else {
      aA = currentT;
    }
  } while (
    Math.abs(currentX) > SUBDIVISION_PRECISION &&
    ++i < SUBDIVISION_MAX_ITERATIONS
  );
  return currentT;
}

function newtonRaphsonIterate(
  aX: number,
  aGuessT: number,
  mX1: number,
  mX2: number
) {
  for (let i = 0; i < NEWTON_ITERATIONS; ++i) {
    const currentSlope = getSlope(aGuessT, mX1, mX2);
    if (currentSlope === 0.0) {
      return aGuessT;
    }
    const currentX = calcBezier(aGuessT, mX1, mX2) - aX;
    aGuessT -= currentX / currentSlope;
  }
  return aGuessT;
}

export function bezier(
  mX1: number,
  mY1: number,
  mX2: number,
  mY2: number
): EasingFunction {
  mX1 = clamp(mX1, 0, 1);
  mX2 = clamp(mX2, 0, 1);
  mY1 = clamp(mY1, 0, 1);
  mY2 = clamp(mY2, 0, 1);

  if (mX1 === mY1 && mX2 === mY2) {
    return Identity;
  }

  // Precompute samples table
  const sampleValues = new Array(kSplineTableSize);
  for (let i = 0; i < kSplineTableSize; ++i) {
    sampleValues[i] = calcBezier(i * kSampleStepSize, mX1, mX2);
  }

  function getTForX(aX: number): number {
    let intervalStart = 0.0;
    let currentSample = 1;
    const lastSample = kSplineTableSize - 1;

    for (
      ;
      currentSample !== lastSample && sampleValues[currentSample] <= aX;
      ++currentSample
    ) {
      intervalStart += kSampleStepSize;
    }
    --currentSample;

    // Interpolate to provide an initial guess for t
    const dist =
      (aX - sampleValues[currentSample]) /
      (sampleValues[currentSample + 1] - sampleValues[currentSample]);
    const guessForT = intervalStart + dist * kSampleStepSize;

    const initialSlope = getSlope(guessForT, mX1, mX2);
    if (initialSlope >= NEWTON_MIN_SLOPE) {
      return newtonRaphsonIterate(aX, guessForT, mX1, mX2);
    } else if (initialSlope === 0.0) {
      return guessForT;
    } else {
      return binarySubdivide(
        aX,
        intervalStart,
        intervalStart + kSampleStepSize,
        mX1,
        mX2
      );
    }
  }

  return function (x: number): number {
    // Because JavaScript number are imprecise, we should guarantee the extremes are right.
    if (x === 0 || x === 1) {
      return x;
    }
    return calcBezier(getTForX(x), mY1, mY2);
  };
}
