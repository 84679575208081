import {ApolloClient} from '@apollo/client';
import {
  AddFileDocument,
  ConfirmAssetDocument,
  ConfirmFileDocument,
  CreateAssetDocument,
  GetAssetForAddressesDocument,
  GetAssetForStatusDocument,
  TAddFileMutation,
  TAddFileMutationVariables,
  TAddressTypeEnum,
  TArtifactTypeEnum,
  TConfirmAssetMutation,
  TConfirmAssetMutationVariables,
  TConfirmFileMutation,
  TConfirmFileMutationVariables,
  TCreateAssetMutation,
  TCreateAssetMutationVariables,
  TGetAssetForAddressesQuery,
  TGetAssetForAddressesQueryVariables,
  TGetAssetForStatusQuery,
  TGetAssetForStatusQueryVariables,
  TJobStatus,
  TJobType,
  TProcessingStatus,
} from '../../graphql/gen/graphql';
import PartQueue from '@digitalreality/libupload/part-queue';
import {LayerAddress} from '../../util/LayerAddressLoader';

/**
 * Creates an empty grouped asset in the given folder and returns the id of the created asset.
 */
export async function createAsset(
  client: ApolloClient<object>,
  name: string,
  folderId: string
): Promise<string> {
  const {data, errors} = await client.mutate<
    TCreateAssetMutation,
    TCreateAssetMutationVariables
  >({
    mutation: CreateAssetDocument,
    variables: {
      name,
      folderId,
    },
  });
  if (errors) {
    throw errors;
  } else if (data!.createAssetV2!.__typename !== 'GroupedAssetOutput') {
    throw new Error('Could not create asset: ' + data!.createAssetV2!.message);
  } else {
    return data!.createAssetV2!.id;
  }
}

/**
 * Creates a file id that you can use to upload a file to the given grouped asset.
 */
export async function addFile(
  client: ApolloClient<object>,
  groupedAssetId: string,
  file: File
): Promise<string> {
  const {data, errors} = await client.mutate<
    TAddFileMutation,
    TAddFileMutationVariables
  >({
    mutation: AddFileDocument,
    variables: {
      groupedAssetId,
      name: file.name,
      size: file.size,
    },
  });
  if (errors) {
    throw errors;
  } else {
    return data!.addFile!.id;
  }
}

/**
 * Confirms that all the chunks of the file with given fileId have been updated and pass the finished parts as
 * confirmation.
 */
export async function confirmFile(
  client: ApolloClient<object>,
  fileId: string,
  finishedParts: ReturnType<PartQueue['getFinishedParts']>
) {
  const {data, errors} = await client.mutate<
    TConfirmFileMutation,
    TConfirmFileMutationVariables
  >({
    mutation: ConfirmFileDocument,
    variables: {
      fileId,
      finishedETags: finishedParts.map(({chunkIndex, etag}) => ({
        part: chunkIndex + 1,
        etag,
      })),
    },
  });
  if (errors) {
    throw errors;
  } else {
    if (!data!.completeMultipartUpload!.executed) {
      throw new Error(
        'Could not confirm asset: ' + data!.completeMultipartUpload!.message
      );
    }
  }
}

/**
 * Confirms that all files of the given asset have been uploaded and HxDR can start processing the files.
 */
export async function confirmAsset(
  client: ApolloClient<object>,
  groupedAssetId: string
): Promise<void> {
  const {data, errors} = await client.mutate<
    TConfirmAssetMutation,
    TConfirmAssetMutationVariables
  >({
    mutation: ConfirmAssetDocument,
    variables: {
      groupedAssetId,
    },
  });
  if (errors) {
    throw errors;
  } else {
    if (!data!.completeAssetFileList!.executed) {
      throw new Error(
        'Could not confirm asset: ' + data!.completeAssetFileList!.message
      );
    }
  }
}

export enum ProcessingState {
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN PROGRESS',
  READY = 'READY',
}

export type ProcessingSubStates = {
  [key in TArtifactTypeEnum | TJobType]?: ProcessingState;
};

function combineSubStates(...substates: ProcessingState[]): ProcessingState {
  return substates.reduce((currentState, nextState) => {
    if (
      currentState === ProcessingState.FAILED ||
      nextState === ProcessingState.FAILED
    ) {
      return ProcessingState.FAILED;
    } else if (
      currentState === ProcessingState.IN_PROGRESS ||
      nextState === ProcessingState.IN_PROGRESS
    ) {
      return ProcessingState.IN_PROGRESS;
    } else {
      return ProcessingState.READY;
    }
  }, ProcessingState.READY);
}

const PROCESSING_STATUS_TO_PROCESSING_STATE: {
  [key in TProcessingStatus]: ProcessingState;
} = {
  [TProcessingStatus.CANCELED]: ProcessingState.FAILED,
  [TProcessingStatus.FAILED]: ProcessingState.FAILED,
  [TProcessingStatus.CREATED]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.RUNNING]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.MANUAL]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.PENDING]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.SCHEDULED]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.PREPARING]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.WAITING_FOR_RESOURCE]: ProcessingState.IN_PROGRESS,
  [TProcessingStatus.SKIPPED]: ProcessingState.READY,
  [TProcessingStatus.SUCCESS]: ProcessingState.READY,
};

const JOB_TO_PROCESSING_STATE: {[key in TJobStatus]: ProcessingState} = {
  [TJobStatus.CANCELLED]: ProcessingState.FAILED,
  [TJobStatus.FAILED]: ProcessingState.FAILED,
  [TJobStatus.TOO_MANY_RETRIES]: ProcessingState.FAILED,
  [TJobStatus.IN_PROGRESS]: ProcessingState.IN_PROGRESS,
  [TJobStatus.QUEUED]: ProcessingState.IN_PROGRESS,
  [TJobStatus.SUBMITTED]: ProcessingState.IN_PROGRESS,
  [TJobStatus.STARTED]: ProcessingState.IN_PROGRESS,
  [TJobStatus.NOTHING_TO_DO]: ProcessingState.READY,
  [TJobStatus.COMPLETED]: ProcessingState.READY,
};

//since getting state through jobSummary will get removed, we can hardcode which types are relevant to us.
const RELEVANT_JOB_TYPES = [
  TJobType.MESH,
  TJobType.PANORAMIC,
  TJobType.POINT_CLOUD,
];

/**
 * Returns the processing state of the asset with given grouped asset id.
 */
export async function getAssetProcessingState(
  client: ApolloClient<object>,
  groupedAssetId: string
): Promise<{state: ProcessingState; subStates: ProcessingSubStates}> {
  const {data, errors} = await client.query<
    TGetAssetForStatusQuery,
    TGetAssetForStatusQueryVariables
  >({
    query: GetAssetForStatusDocument,
    variables: {
      groupedAssetId,
    },
    fetchPolicy: 'no-cache',
  });
  if (errors) {
    throw errors;
  } else if (data.asset?.__typename !== 'GroupedAssetOutput') {
    throw new Error('Could not get asset: ' + data.asset!.message);
  } else {
    const subStates: {[key in TArtifactTypeEnum | TJobType]?: ProcessingState} =
      {};

    if (data.asset!.asset.jobSummary?.jobs) {
      for (const job of data.asset!.asset.jobSummary?.jobs) {
        if (RELEVANT_JOB_TYPES.indexOf(job.type) >= 0) {
          subStates[job.type] = combineSubStates(
            JOB_TO_PROCESSING_STATE[job.state],
            subStates[job.type] ?? ProcessingState.READY
          );
        }
      }
    }

    for (const artifact of data.asset!.asset.artifacts!.contents) {
      for (const layerAddress of artifact.addresses.contents) {
        if (
          layerAddress.type &&
          layerAddress.type !== TAddressTypeEnum.DOWNLOAD &&
          (layerAddress as any).processingPipelineInfo
        ) {
          const address = layerAddress as {
            processingPipelineInfo: {status?: TProcessingStatus; name?: string};
          };
          if (address.processingPipelineInfo.status) {
            subStates[artifact.type] = combineSubStates(
              PROCESSING_STATUS_TO_PROCESSING_STATE[
                address.processingPipelineInfo.status
              ],
              subStates[artifact.type] ?? ProcessingState.READY
            );
          }
        }
      }
    }

    return {
      state:
        Object.keys(subStates).length === 0
          ? ProcessingState.IN_PROGRESS
          : combineSubStates(
              ...Object.values(subStates).map(
                (subState) => subState ?? ProcessingState.READY
              )
            ),
      subStates,
    };
  }
}

/**
 * Returns all layer addresses associated with the given grouped asset id that can be rendered on the map
 */
export async function getAssetLayerRenderableAddresses(
  client: ApolloClient<object>,
  groupedAssetId: string
): Promise<{georeferenced: boolean; addresses: LayerAddress[]}> {
  const {data, errors} = await client.query<
    TGetAssetForAddressesQuery,
    TGetAssetForAddressesQueryVariables
  >({
    query: GetAssetForAddressesDocument,
    variables: {
      groupedAssetId,
    },
  });
  if (errors) {
    throw errors;
  } else if (data.asset?.__typename !== 'GroupedAssetOutput') {
    throw new Error('Could not get asset: ' + data.asset!.message);
  } else {
    const addresses: LayerAddress[] = [];
    for (const artifact of data.asset!.asset.artifacts!.contents) {
      for (const address of artifact.addresses.contents) {
        if (address.type !== TAddressTypeEnum.DOWNLOAD) {
          addresses.push(address as LayerAddress);
        }
      }
    }

    return {
      georeferenced: !!data.asset!.asset.withEmbeddedGeoreference,
      addresses,
    };
  }
}
