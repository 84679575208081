import { Point } from '@luciad/ria/shape/Point'
import { Handle } from '@luciad/ria/util/Evented'
import { EventedSupport } from '@luciad/ria/util/EventedSupport'
import { Controller } from '@luciad/ria/view/controller/Controller'
import { HandleEventResult } from '@luciad/ria/view/controller/HandleEventResult'
import { GestureEvent } from '@luciad/ria/view/input/GestureEvent'
import { GestureEventType } from '@luciad/ria/view/input/GestureEventType'
import { Map } from '@luciad/ria/view/Map'
import { PcToolSupport } from '../alignment/PcToolSupport'
import { PcTool } from '../dts/Alignment'

export const ROTATE_START_EVENT = 'RotateStartEvent'
export const ROTATE_EVENT = 'RotateEvent'
export const ROTATE_CONFIRMED_EVENT = 'RotateConfirmedEvent'

export class ParallelRotationController extends Controller {
  private readonly _eventedSupport: EventedSupport = new EventedSupport(
    [ROTATE_START_EVENT, ROTATE_EVENT, ROTATE_CONFIRMED_EVENT],
    true
  )
  private readonly _support: PcToolSupport

  private _startViewPoint: Point | null = null

  constructor(support: PcToolSupport) {
    super()
    this._support = support
  }

  onGestureEvent(gestureEvent: GestureEvent): HandleEventResult {
    if (this._support.checkDisabled(PcTool.ROTATE))
      return HandleEventResult.EVENT_IGNORED
    // TODO: this should be disabled for tablets, or based on the selected tool
    // if (gestureEvent.modifier !== ModifierType.CTRL) {
    //   return HandleEventResult.EVENT_IGNORED
    // }

    if (gestureEvent.type == GestureEventType.DOWN) {
      this._startViewPoint = gestureEvent.viewPoint

      this._eventedSupport.emit(ROTATE_START_EVENT)
    }
    if (this._startViewPoint) {
      const rotation =
        (this._startViewPoint.x - gestureEvent.viewPoint.x) *
        (720.0 / screen.width)
      if (gestureEvent.type == GestureEventType.UP) {
        this._eventedSupport.emit(ROTATE_CONFIRMED_EVENT, rotation)
      }
      if (gestureEvent.type == GestureEventType.DRAG) {
        this._eventedSupport.emit(ROTATE_EVENT, rotation)
      }
    }

    return HandleEventResult.EVENT_HANDLED
  }

  on(
    event:
      | 'Activated'
      | 'Deactivated'
      | 'Invalidated'
      | typeof ROTATE_START_EVENT
      | typeof ROTATE_EVENT
      | typeof ROTATE_CONFIRMED_EVENT,
    callback: ((map: Map) => void) | ((rotation: number) => void)
  ): Handle {
    if (
      event === ROTATE_START_EVENT ||
      event === ROTATE_EVENT ||
      event === ROTATE_CONFIRMED_EVENT
    ) {
      return this._eventedSupport.on(event, callback)
    } else {
      // @ts-ignore
      return super.on(event, callback)
    }
  }
}
