import {Layer} from '@luciad/ria/view/Layer';
import {Point} from '@luciad/ria/shape/Point';
import {TileSet3DLayer} from '@luciad/ria/view/tileset/TileSet3DLayer';
import {createTransformationFromGeoLocation} from '@luciad/ria/transformation/Affine3DTransformation';
import {LayerAddress, loadLayerAddress} from '../util/LayerAddressLoader';
import {getResolvedBearerToken} from '../authentication/AuthenticationUtil';
import {
  AssetGeolocation,
  GeolocationArtifact,
  GeolocationAsset,
  isGeolocationArtifactType,
} from './GeolocationPersistanceUtil';
import {createTransformation} from '@luciad/ria/transformation/TransformationFactory';
import {getReference} from '@luciad/ria/reference/ReferenceProvider';
import {isString} from '../common/util/Lang';
import {LonLatPointFormat} from '@luciad/ria/shape/format/LonLatPointFormat';

const COORDINATE_FORMATTER = new LonLatPointFormat();

/**
 * Returns all addresses of the given address that can be used to load layers.
 */
export function getGeolocationAssetAddresses(asset: GeolocationAsset) {
  return asset.asset
    .artifacts!.contents.filter(({type}) => isGeolocationArtifactType(type))
    .flatMap((artifact) =>
      artifact.addresses.contents.filter(({type}) =>
        isValidGeolocationLayerAddressType(type)
      )
    );
}

/**
 * Returns all addresses of the given geolocation that can be used to load layers.
 */
export function getGeolocationAddresses(geolocation: AssetGeolocation) {
  return geolocation.artifacts.flatMap(getGeolocationArtifactAddresses);
}

/**
 * Returns all addresses of the given geolocation artifact that can be used to load layers.
 */
export function getGeolocationArtifactAddresses({
  artifact,
}: GeolocationArtifact) {
  return artifact.addresses.contents.filter(({type}) =>
    isValidGeolocationLayerAddressType(type)
  );
}

function isValidGeolocationLayerAddressType(type: string) {
  return type !== 'DOWNLOAD';
}

/**
 * Geolocates a layer using the given position, anchor-point and (horizontal) rotation.
 */
export function geolocateLayer(
  layer: Layer,
  position: Point,
  anchorPoint?: Point,
  rotation?: number
) {
  if (layer instanceof TileSet3DLayer) {
    layer.transformation = createTransformationFromGeoLocation(position, {
      anchorPoint,
      azimuth: rotation,
    });
  } else {
    throw new Error(
      'Can not geolocate layers of class other than TileSet3DLayer'
    );
  }
}

/**
 * Returns the id that corresponds to the combination of given geolocation and layer address id.
 */
export function getGeolocationLayerId(
  geolocationId: string,
  layerAddressId: string
) {
  return geolocationId + '_' + layerAddressId;
}

/**
 * Returns a RIA layer corresponding to the given geolocation layer address
 */
export async function loadGeolocationAddress(
  geolocationId: string,
  assetName: string,
  address: LayerAddress
) {
  const layer = await loadLayerAddress(address, getResolvedBearerToken(), {
    id: getGeolocationLayerId(geolocationId, address.id),
    label: address.type,
  });
  if (layer instanceof TileSet3DLayer) {
    //partOfTerrain needs to be false since we use getViewToMapTransformation(LocationMode.TERRAIN) to geolocate
    layer.isPartOfTerrain = false;
  }
  return layer;
}

/**
 * Returns a default name for a geolocation located at the given position.
 * If you have configured a HERE-maps key, their reverse-geocoding service will be used, otherwise this will default to
 * coordinates in WGS84.
 */
export async function getDefaultGeolocationName(
  position: Point
): Promise<string> {
  const wgs84Pos = createTransformation(
    position.reference!,
    getReference('CRS:84')
  ).transform(position);
  try {
    if (
      isString(process.env.HERE_MAPS_KEY) &&
      process.env.HERE_MAPS_KEY.length > 0
    ) {
      const response = await (
        await fetch(
          `https://revgeocode.search.hereapi.com/v1/revgeocode?apiKey=${process.env.HERE_MAPS_KEY}&at=${wgs84Pos.y},${wgs84Pos.x}`
        )
      ).json();
      if (response.items?.length > 0) {
        return response.items[0].title;
      }
    }
  } catch {}
  return COORDINATE_FORMATTER.format(wgs84Pos);
}
