import {Map} from '@luciad/ria/view/Map';
import {Point} from '@luciad/ria/shape/Point';
import {getReference} from '@luciad/ria/reference/ReferenceProvider';
import {createCartesianGeodesy, createEllipsoidalGeodesy,} from '@luciad/ria/geodesy/GeodesyFactory';
import {LocationMode} from '@luciad/ria/transformation/LocationMode';
import {OutOfBoundsError} from '@luciad/ria/error/OutOfBoundsError';
import {createPoint} from '@luciad/ria/shape/ShapeFactory';
import {add, normalize, scale} from '../../util/Vector3Util';
import {calculatePointingDirection} from '../../common/util/PerspectiveCameraUtil';
import {clamp} from '../../common/util/Math';

const refLLH = getReference('CRS:84');
const model3D = getReference('EPSG:4978');
const geodesy3D = createCartesianGeodesy(model3D);
const geodesyLLH = createEllipsoidalGeodesy(refLLH);

function getTouchedSurfacePoint(map: Map, viewPoint: Point): Point {
  return map
      .getViewToMapTransformation(LocationMode.CLOSEST_SURFACE)
      .transform(viewPoint);
}

/**
 * Returns a world point that corresponds to the given view point, that is
 * either on a touched mesh or on a virtual sphere around the camera eye (when touching void).
 */
export function toWorldPoint(
    map: Map,
    viewPoint: Point,
    virtualSphereRadius = 10
): Point {
  try {
    return getTouchedSurfacePoint(map, viewPoint);
  } catch (e) {
    if (!(e instanceof OutOfBoundsError)) {
      throw e;
    }
  }
  return getVirtualSpherePoint(map, viewPoint, virtualSphereRadius);
}

/**
 * Gets a touched point on a virtual sphere with the center of the camera eye and the given radius.
 * @param map 3D map
 * @param viewPoint point in pixels
 * @param radius virtual sphere radius in meters
 */
function getVirtualSpherePoint(
    map: Map,
    viewPoint: Point,
    radius: number
): Point {
  const newForward = normalize(calculatePointingDirection(map, viewPoint));

  const {x, y, z} = add(map.camera.eye, scale(newForward, radius));
  return createPoint(map.reference, [x, y, z]);
}

export function computePitchBetween(startLLH: Point, targetLLH: Point): number {
  const h = targetLLH.z - startLLH.z;
  const distance = geodesy3D.distance3D(startLLH, targetLLH);
  if (h + 0.00001 >= distance) {
    return 0;
  }
  const rad = Math.asin(h / distance);
  return clamp(rad * (180 / Math.PI), -85, 85);
}

export function computeYawBetween(startLLH: Point, targetLLH: Point): number {
  return geodesyLLH.forwardAzimuth(startLLH, targetLLH);
}
