import {
  Absolute,
  Box,
  Center,
  Fixed,
  Inline,
  LoadingLayout,
  Navbar,
  NavbarBottomContent,
  NavbarButton,
  NavbarLogo,
  NavbarTopContent,
  Relative,
  SolidToggle,
  SolidToggleGroup,
  Stack,
  ThemeProvider,
} from '@digitalreality/ui'
import {
  DrLogoIcon,
  LayersIcon,
  MoveIcon,
  RotateIcon,
  UserAvatarIcon,
} from '@digitalreality/ui-icons'
import { createHSPCTilesModel } from '@luciad/ria/model/tileset/HSPCTilesModel'
import { getReference } from '@luciad/ria/reference/ReferenceProvider'
import { createPoint } from '@luciad/ria/shape/ShapeFactory'
import { createTransformationFromGeoLocation } from '@luciad/ria/transformation/Affine3DTransformation'
import { number } from '@luciad/ria/util/expression/ExpressionFactory'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import { ScalingMode } from '@luciad/ria/view/style/ScalingMode'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { useSnackbar } from 'notistack'
import React, { useEffect, useRef, useState } from 'react'
import { PcToolSupport } from '../alignment/PcToolSupport'
import { SliceSupport } from '../alignment/SliceSupport'
import {
  getResolvedBearerToken,
  logOut,
} from '../authentication/AuthenticationUtil'
import { Iurl } from '../common/context/AppContext'
import { useCurrentProject } from '../common/context/useCurrentProject'
import { useProjectLoad } from '../common/hooks/useProjectLoad'
import { useUndo } from '../common/hooks/useUndo'
import { getFitBounds } from '../common/util/FitBoundsUtil'
import { AlignmentHelper } from '../components/AlignmentHelper'
import { MainMapSliders } from '../components/MainMapSliders'
import { MapOptionsToolbar } from '../components/MapOptionsToolbar'
import { Layout, PcTool, View } from '../dts/Alignment'
import {
  ASSETS_CHANGED_EVENT,
  GeolocationSupport,
} from '../geolocation/GeolocationSupport'
import { LayerTreeDrawer } from '../layers/LayerTreeDrawer'
import { AddDataManager } from '../layers/assets/AddDataManager'
import {
  ProcessingState,
  getAssetLayerRenderableAddresses,
} from '../layers/assets/AssetPersistanceUtil'
import { ProcessingAsset } from '../layers/assets/useProcessingAssets'
import { loadLayerAddress } from '../util/LayerAddressLoader'
import { getApolloClient } from '../util/PersistanceUtil'
import { initControllers } from '../util/controllers'
import { addGrid } from '../util/grid'
import { addMapSettings, setMapCamera, setMapFovY } from '../util/mapSettings'
import { setPointcloudSettings } from '../util/pointcloudSettings'
import {
  downloadFile,
  getResultTransformationJson,
} from '../util/exportAlignment'

const GEODETIC_REF = getReference('EPSG:4978')
const REFERENCE_POINT = [0.1, 0.1, 0]
const FOVY_VIEW = 1

export const PointCloudAlignment = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [activeSidePanel, setActiveSidePanel] = useState<Layout>(Layout.NONE)
  const topViewSupport = useRef<PcToolSupport>(new PcToolSupport())
  const sideViewSupport = useRef<PcToolSupport>(new PcToolSupport())
  const sliceSupport = useRef<SliceSupport>(new SliceSupport())
  const mapRef = useRef<HTMLDivElement>()
  const topMapRef = useRef<HTMLDivElement>(null)
  const sideMapRef = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<WebGLMap | null>(null)
  const [topMap, setTopMap] = useState<WebGLMap | null>(null)
  const [sideMap, setSideMap] = useState<WebGLMap | null>(null)
  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false)
  const [dataManager, setDataManager] = useState<AddDataManager>()
  const pointCloud1Ref = useRef<TileSet3DLayer[]>([])
  const pointCloud2Ref = useRef<TileSet3DLayer[]>([])
  const [topPcTool, setTopPcTool] = useState<PcTool | null>(null)
  const [sidePcTool, setSidePcTool] = useState<PcTool | null>(null)
  const [geolocationSupport, setGeolocationSupport] =
    useState<GeolocationSupport | null>(null)
  // const apiKey = useHereKey();
  const { currentProject, manualLoad, manualUrl } = useCurrentProject()
  useProjectLoad()

  const { transformationCallback, undo, redo, reset, buttonActive } = useUndo(
    pointCloud2Ref.current
  )

  const finalize = () => {
    // retrieve the last transformation and console.log the json
    if (pointCloud2Ref.current[0].transformation && map) {
      const json = getResultTransformationJson(
        pointCloud2Ref.current[0].transformation,
        map
      )
      const filename = 'FinalAlignment.json'
      console.log('Automatic file download - FinalAlignment.json')
      downloadFile(filename, json)
    }
  }

  /* Create maps for Main, Top and Side */
  useEffect(() => {
    if (!mapRef.current) return

    /** 3D main map  */
    const mainMap = new WebGLMap(mapRef.current, {
      reference: GEODETIC_REF,
    })
    setMapCamera(mainMap, -35)
    addMapSettings(mainMap)
    setMap(mainMap)

    /** TOP view map  */
    const topMap = new WebGLMap(topMapRef.current!, {
      reference: GEODETIC_REF,
    })
    setMapCamera(topMap, -89.9)
    setMapFovY(topMap, FOVY_VIEW)
    addMapSettings(topMap)
    setTopMap(topMap)

    /** SIDE view map  */
    const sideMap = new WebGLMap(sideMapRef.current!, {
      reference: GEODETIC_REF,
    })
    setMapCamera(sideMap, 0)
    setMapFovY(sideMap, FOVY_VIEW)
    addMapSettings(sideMap)
    setSideMap(sideMap)

    return () => {
      mainMap.destroy()
      topMap.destroy()
      sideMap.destroy()
    }
  }, [])

  /** adds geolocation support for the sidebar */
  useEffect(() => {
    if (!map) return
    const geolocateSupport = new GeolocationSupport(map)
    setGeolocationSupport(geolocateSupport)
    geolocateSupport.on(ASSETS_CHANGED_EVENT, () =>
      setActiveSidePanel(Layout.GEOLOCATION)
    )
    return () => {
      if (!geolocateSupport) return
      geolocateSupport.destroy()
    }
  }, [map])

  /** adds data manager for the sidebar */
  useEffect(() => {
    if (!map) return
    setDataManager(
      new AddDataManager(map, getApolloClient(getResolvedBearerToken()))
    )
  }, [map])

  /** loads the assets of the project */
  useEffect(() => {
    if (!map) {
      return
    }

    const loadAssets = async () => {
      if (assetsLoaded) return
      if (!currentProject.hspc1 || !currentProject.hspc2) return
      try {
        console.info('PointCloudAlignment.tsx: Loading the 1st asset')
        await addAssetToMaps(currentProject.hspc1, 'rgb(200,255,200)')
        console.info('PointCloudAlignment.tsx: Loading the 2nd asset')
        await addAssetToMaps(currentProject.hspc2, 'rgb(255,200,200)', true)
        console.info('PointCloudAlignment.tsx: Adding the grid')
        await addGridToMaps()
        const grid = map!.layerTree.children.find(
          (child) => child.label === 'Grid-layer'
        )
        if (!grid) {
          enqueueSnackbar('Missing grid', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          })
        }
        setAssetsLoaded(true)
      } catch (error) {
        enqueueSnackbar(
          'Could not load assets: ' + (error as Error[])[0]?.message,
          {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        )
      }
    }

    loadAssets()
  }, [currentProject.hspc1, currentProject.hspc2, map])

  /** initializes the controllers */
  useEffect(() => {
    if (!assetsLoaded) return
    if (!map) return
    if (!sideMap) return
    if (!topMap) return
    try {
      initControllers(
        map,
        sideMap,
        topMap,
        pointCloud2Ref.current,
        transformationCallback,
        topViewSupport.current,
        sideViewSupport.current,
        sliceSupport.current
      )
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      })
    }
  }, [assetsLoaded, map, sideMap, topMap])

  /** enable / disable controllers based on the tool */
  useEffect(() => {
    if (!assetsLoaded) return
    topViewSupport.current.tool = topPcTool
    sideViewSupport.current.tool = sidePcTool
  }, [assetsLoaded, topPcTool, sidePcTool])

  /** open the drawer on manual load */
  useEffect(() => {
    if (!manualLoad) return
    setActiveSidePanel(Layout.LAYERS)
  }, [manualLoad])

  const changeActiveSidePanel = (layout: Layout) => {
    if (activeSidePanel === layout) {
      setActiveSidePanel(Layout.NONE)
    } else if (map) {
      setActiveSidePanel(layout)
    }
  }

  /** load the model from the url, check for georeference and load the layer */
  async function loadHSPCUrlAddress(
    address: string,
    lastAsset?: boolean
  ): Promise<TileSet3DLayer> {
    const model = await createHSPCTilesModel(address)
    if (model.reference.referenceType !== 3) {
      throw new Error('Georeferenced assets are not supported')
      /* enqueueSnackbar('Georeferenced assets are not supported', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      }) */
    }

    return new TileSet3DLayer(model, {
      id: lastAsset ? 'hspc_url_02' : 'hspc_url_01',
      isPartOfTerrain: true,
      fadingTime: 150,
      offsetTerrain: false,
      pointCloudStyle: {
        scalingMode: ScalingMode.PIXEL_SIZE,
        scaleExpression: number(0.5),
      },
      qualityFactor: 0.5,
    })
  }

  const addLayerToMap = async (
    map: WebGLMap,
    url: Iurl,
    accentColor?: string,
    lastAsset?: boolean
  ) => {
    const { type, address } = url
    const geoLocation = createTransformationFromGeoLocation(
      createPoint(getReference('EPSG:4326'), REFERENCE_POINT),
      { destinationReference: map.reference }
    )
    const addLayer = (layer: TileSet3DLayer) => {
      layer.label = `URL_ADDED_${Date.now()}`
      setPointcloudSettings(layer, geoLocation, accentColor)
      if (lastAsset) {
        pointCloud2Ref.current.push(layer)
      } else {
        pointCloud1Ref.current.push(layer)
      }
      map.layerTree.addChild(layer)
      getFitBounds(layer).then((bounds) => {
        if (bounds) {
          map.mapNavigator.fit({ bounds })
        }
      })
    }
    /* const promises: Promise<void>[] = []
    if (type === 'hspc') {
      promises.push(
        HSPCTilesModel.create(address).then(function (model) {
          //Create a layer for the model
          const layer = new TileSet3DLayer(model)
          addLayer(layer)
        })
      )
    }
    if (type === 'json') {
      promises.push(
        OGC3DTilesModel.create(address).then(function (model) {
          //Create a layer for the model
          const layer = new TileSet3DLayer(model)
          addLayer(layer)
        })
      )
    }
    await Promise.all(promises) */
    const promises: Promise<void>[] = []
    if (type === 'hspc') {
      promises.push(
        loadHSPCUrlAddress(address, lastAsset).then(function (layer) {
          addLayer(layer)
        })
      )
    }
    await Promise.all(promises)
  }

  const addLayersToMaps = async (
    url: Iurl,
    accentColor?: string,
    lastAsset?: boolean
  ) => {
    /* try {
      await addLayerToMap(map!, url, accentColor, lastAsset)
      await addLayerToMap(sideMap!, url, accentColor, lastAsset)
      await addLayerToMap(topMap!, url, accentColor, lastAsset)
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      })
    } */
    await addLayerToMap(map!, url, accentColor, lastAsset)
    await addLayerToMap(sideMap!, url, accentColor, lastAsset)
    await addLayerToMap(topMap!, url, accentColor, lastAsset)
  }

  /** add every single layer to the map */
  const addAssetToMap = async (
    map: WebGLMap,
    asset: ProcessingAsset,
    accentColor?: string,
    lastAsset?: boolean
  ) => {
    const geoLocation = createTransformationFromGeoLocation(
      createPoint(getReference('EPSG:4326'), REFERENCE_POINT),
      { destinationReference: map.reference }
    )
    if (asset.state !== ProcessingState.READY) {
      throw new Error('Only fully ready assets can be loaded')
    }
    const { addresses } = await getAssetLayerRenderableAddresses(
      getApolloClient(getResolvedBearerToken()),
      asset.groupedAssetId
    )

    const promises: Promise<void>[] = []
    for (const address of addresses) {
      if (address.type === 'HSPC') {
        promises.push(
          loadLayerAddress(address, getResolvedBearerToken(), {
            label: asset.name + '_' + address.type,
          }).then((layer) => {
            const newLayer = layer as TileSet3DLayer
            setPointcloudSettings(newLayer, geoLocation, accentColor)
            if (lastAsset) {
              pointCloud2Ref.current.push(newLayer)
            } else {
              pointCloud1Ref.current.push(newLayer)
            }
            map.layerTree.addChild(newLayer)
            getFitBounds(newLayer).then((bounds) => {
              if (bounds) {
                map.mapNavigator.fit({ bounds })
              }
            })
          })
        )
      }
    }
    await Promise.all(promises)
  }

  /** Add assets to the map */
  const addAssetToMaps = async (
    asset: ProcessingAsset,
    accentColor?: string,
    lastAsset?: boolean
  ) => {
    try {
      await addAssetToMap(map!, asset, accentColor, lastAsset)
      await addAssetToMap(sideMap!, asset, accentColor, lastAsset)
      await addAssetToMap(topMap!, asset, accentColor, lastAsset)
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      })
    }
  }

  /** Create the Grid for each map */
  const addGridToMaps = async () => {
    console.info('Creating the grids for the 3d and Top view')
    try {
      await addGrid(map!, map!.layerTree.children[0] as TileSet3DLayer)
      await addGrid(topMap!, topMap!.layerTree.children[0] as TileSet3DLayer)
      // await addGrid(sideMap!, sideMap!.layerTree.children[0] as TileSet3DLayer)
    } catch (error) {
      enqueueSnackbar((error as Error).message, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      })
    }
  }

  /** add necessary info to slice supports */
  useEffect(() => {
    if (!assetsLoaded || !sideMap || !topMap) {
      return
    }
    sliceSupport.current.pointCloud1Layers = pointCloud1Ref.current
    sliceSupport.current.pointCloud2Layers = pointCloud2Ref.current
  }, [assetsLoaded])

  const closePanel = () => setActiveSidePanel(Layout.NONE)

  const handlePcToolChange = (pcTool: PcTool | null, view: View) => {
    if (view === View.TOP)
      topPcTool === pcTool ? setTopPcTool(null) : setTopPcTool(pcTool)
    if (view === View.SIDE)
      sidePcTool === pcTool ? setSidePcTool(null) : setSidePcTool(pcTool)
  }

  /** loads the layers with the manual url */
  useEffect(() => {
    if (!map) return
    if (assetsLoaded) return
    if (!manualLoad) return
    if (!manualUrl.url1 || !manualUrl.url2) return
    const { url1, url2 } = manualUrl

    const loadAssets = async () => {
      try {
        console.info('PointCloudAlignment.tsx: Loading the 1st asset')
        await addLayersToMaps(url1, 'rgb(200,255,200)')
        console.info('PointCloudAlignment.tsx: Loading the 2nd asset')
        await addLayersToMaps(url2, 'rgb(255,200,200)', true)
        console.info('PointCloudAlignment.tsx: Adding the grid')
        await addGridToMaps()
        const grid = map!.layerTree.children.find(
          (child) => child.label === 'Grid-layer'
        )
        if (!grid) {
          enqueueSnackbar('Missing grid', {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          })
        }
        setAssetsLoaded(true)
      } catch (error) {
        console.log(error)
        enqueueSnackbar('Could not load assets: ' + (error as Error).message, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        })
      }
    }

    loadAssets()
  }, [map, manualLoad, manualUrl, assetsLoaded])

  return (
    <ThemeProvider>
      {/* <CssBaseline /> */}
      <Absolute
        sx={{
          top: 0,
          left: 0,
          width: '100%',
          height: 'inherit',
        }}
      >
        <Inline height="100%" alignItems="center" gap={0}>
          <Navbar sx={{ height: '100%' }}>
            <NavbarLogo>
              <Box display="flex" alignItems="center" justifyContent="center">
                <DrLogoIcon />
              </Box>
            </NavbarLogo>
            <NavbarTopContent>
              <NavbarButton
                title="Layer Control"
                active={activeSidePanel === Layout.LAYERS}
                onClick={() => changeActiveSidePanel(Layout.LAYERS)}
              >
                <LayersIcon />
              </NavbarButton>
            </NavbarTopContent>
            <NavbarBottomContent>
              <Stack gap={0}>
                <NavbarButton title="Log Out" onClick={logOut}>
                  <UserAvatarIcon />
                </NavbarButton>
              </Stack>
            </NavbarBottomContent>
          </Navbar>
          <Absolute width="100%" height="100%">
            <Inline width="100%" height="100%">
              <Box id="map" ref={mapRef} width="50%" height="100%">
                <Relative>
                  {map && assetsLoaded && (
                    <>
                      <Absolute
                        sx={{ width: '100%', zIndex: 1, marginTop: '4px' }}
                      >
                        <Center>
                          <MainMapSliders sliceSupport={sliceSupport.current} />
                        </Center>
                      </Absolute>
                      <Absolute
                        sx={{ zIndex: 1, top: 0, right: 0, marginTop: '4px' }}
                      >
                        <MapOptionsToolbar
                          mapName="3D"
                          map={map}
                          pointCloud1Ref={pointCloud1Ref.current}
                          pointCloud2Ref={pointCloud2Ref.current}
                          sliceSupport={sliceSupport.current}
                        />
                      </Absolute>
                    </>
                  )}
                </Relative>
              </Box>
              <Stack width="50%" height="100%">
                <Box id="map2" ref={topMapRef} width="100%" height="50%">
                  <Relative>
                    {topMap && assetsLoaded && (
                      <>
                        <Absolute
                          sx={{ width: '100%', zIndex: 1, marginTop: '4px' }}
                        >
                          <Stack alignItems="center">
                            {/* TODO change and move this component */}
                            <SolidToggleGroup
                              sx={{ maxWidth: 'fit-content' }}
                              value={topPcTool}
                              onChange={undefined}
                              size="small"
                            >
                              <SolidToggle
                                value={PcTool.MOVE}
                                onClick={() =>
                                  handlePcToolChange(PcTool.MOVE, View.TOP)
                                }
                              >
                                <MoveIcon fontSize="small" />
                              </SolidToggle>
                              <SolidToggle
                                value={PcTool.ROTATE}
                                onClick={() =>
                                  handlePcToolChange(PcTool.ROTATE, View.TOP)
                                }
                              >
                                <RotateIcon fontSize="small" />
                              </SolidToggle>
                            </SolidToggleGroup>
                          </Stack>
                        </Absolute>
                        <Absolute
                          sx={{ zIndex: 1, top: 0, right: 0, marginTop: '4px' }}
                        >
                          <MapOptionsToolbar
                            mapName="TOP"
                            map={topMap}
                            pointCloud1Ref={pointCloud1Ref.current}
                            pointCloud2Ref={pointCloud2Ref.current}
                            sliceSupport={sliceSupport.current}
                          />
                        </Absolute>
                      </>
                    )}
                  </Relative>
                </Box>
                <Box id="map3" ref={sideMapRef} width="100%" height="50%">
                  <Relative>
                    {sideMap && assetsLoaded && (
                      <>
                        <Absolute
                          sx={{ width: '100%', zIndex: 1, marginTop: '4px' }}
                        >
                          <Stack alignItems="center">
                            {/* TODO change and move this component */}
                            <SolidToggleGroup
                              sx={{ maxWidth: 'fit-content' }}
                              value={sidePcTool}
                              onChange={undefined}
                              size="small"
                            >
                              <SolidToggle
                                value={PcTool.MOVE}
                                onClick={() =>
                                  handlePcToolChange(PcTool.MOVE, View.SIDE)
                                }
                              >
                                <MoveIcon fontSize="small" />
                              </SolidToggle>
                              <SolidToggle
                                value={PcTool.ROTATE}
                                onClick={() =>
                                  handlePcToolChange(PcTool.ROTATE, View.SIDE)
                                }
                              >
                                <RotateIcon fontSize="small" />
                              </SolidToggle>
                            </SolidToggleGroup>
                          </Stack>
                        </Absolute>
                        <Absolute
                          sx={{ zIndex: 1, top: 0, right: 0, marginTop: '4px' }}
                        >
                          <MapOptionsToolbar
                            mapName="SIDE"
                            map={sideMap}
                            pointCloud1Ref={pointCloud1Ref.current}
                            pointCloud2Ref={pointCloud2Ref.current}
                            sliceSupport={sliceSupport.current}
                          />
                        </Absolute>
                      </>
                    )}
                  </Relative>
                </Box>
              </Stack>
            </Inline>
          </Absolute>
        </Inline>
      </Absolute>
      {map && topMap && sideMap && dataManager && geolocationSupport && (
        <LayerTreeDrawer
          open={activeSidePanel === Layout.LAYERS}
          map={map}
          topMap={topMap}
          sideMap={sideMap}
          dataManager={dataManager}
          geolocationSupport={geolocationSupport}
          onClose={closePanel}
          showAddBtn={!assetsLoaded}
        />
      )}
      {assetsLoaded && (
        <Fixed sx={{ zIndex: 2, bottom: '4px', width: '100%' }}>
          <Center>
            <AlignmentHelper
              undo={undo}
              redo={redo}
              reset={reset}
              finalize={finalize}
              buttonActive={buttonActive}
            />
          </Center>
        </Fixed>
      )}
      {!assetsLoaded && (
        <Fixed
          sx={{ top: 0, left: 0, zIndex: 9, width: '100vw', height: '100vh' }}
        >
          <LoadingLayout text="Loading layout" />
        </Fixed>
      )}
    </ThemeProvider>
  )
}
