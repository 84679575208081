import {
  ActionButton,
  Box,
  ClickAwayListener,
  ContentSlider,
  Divider,
  DrawerActions,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  DrawerTitle,
  Inline,
  Paper,
  Relative,
  Stack,
  Switch,
  Text,
} from '@digitalreality/ui'
import {
  CrossSectionIcon,
  GridIcon,
  LocateIcon,
  ResetIcon,
  SettingsAdjustIcon,
  ZoomResetIcon,
} from '@digitalreality/ui-icons'
import { ScalingMode } from '@luciad/ria/view/style/ScalingMode'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import React, { useEffect, useMemo, useState } from 'react'
import { SliceSupport } from '../alignment/SliceSupport'
import { getFitBounds } from '../common/util/FitBoundsUtil'

interface IMapHelperProps {
  mapName: '3D' | 'TOP' | 'SIDE'
  map: WebGLMap
  pointCloud1Ref: TileSet3DLayer[]
  pointCloud2Ref: TileSet3DLayer[]
  sliceSupport: SliceSupport
}

interface PointSize {
  minimumPixelSize: number
  worldSize: number
}

enum MapName {
  '3D' = 0,
  'TOP' = 2,
  'SIDE' = 1,
}

const DEFAULT_PC_SIZE = {
  minimumPixelSize: 2,
  worldSize: 0.02,
}

const DEFAULT_GAP = 0

export const MapOptionsToolbar = ({
  mapName,
  map,
  pointCloud1Ref,
  pointCloud2Ref,
  sliceSupport,
}: IMapHelperProps) => {
  const [sliceIsActive, setSliceIsActive] = useState<boolean>(true)
  const [zoomSliceIsActive, setZoomSliceIsActive] = useState<boolean>(true)
  const [optionIsActive, setOptionIsActive] = useState<boolean>(false)
  const [colorizationIsActive, setColorizationIsActive] =
    useState<boolean>(true)
  const node = map.layerTree.children[0]
  const [pcPxSize, setPcPxSize] = useState<PointSize>({
    minimumPixelSize: 2,
    worldSize: 0.02,
  })
  const [gapFill, setGapFill] = useState<number>(DEFAULT_GAP)
  const initialColorValues = useMemo(() => {
    return {
      pc1color:
        pointCloud1Ref[MapName[mapName]].pointCloudStyle.colorExpression,
      pc2color:
        pointCloud2Ref[MapName[mapName]].pointCloudStyle.colorExpression,
    }
  }, [])

  const handleFit = async () => {
    const bounds = await getFitBounds(node)
    if (bounds) {
      await map.mapNavigator.fit({ bounds, animate: true })
    }
  }

  const handleOptions = () => {
    setOptionIsActive(!optionIsActive)
  }

  const handleSliceVisibilty = () => {
    setSliceIsActive(!sliceIsActive)
  }

  const handleZoomSliceVisibility = () => {
    setZoomSliceIsActive(!zoomSliceIsActive)
  }

  const handlePixelSize = ({ minimumPixelSize, worldSize }: PointSize) => {
    setPcPxSize({
      minimumPixelSize,
      worldSize,
    })
    const newStyle = {
      mode: ScalingMode.WORLD_SIZE,
      minimumPixelSize,
      worldSize,
    }
    pointCloud1Ref[MapName[mapName]].pointCloudStyle.pointSize = newStyle
    pointCloud2Ref[MapName[mapName]].pointCloudStyle.pointSize = newStyle
  }

  const handleGapFill = (gap: number) => {
    setGapFill(gap)
    pointCloud1Ref[MapName[mapName]].pointCloudStyle.gapFill = gap
    pointCloud2Ref[MapName[mapName]].pointCloudStyle.gapFill = gap
  }

  const handleColorization = () => {
    if (colorizationIsActive) {
      setColorizationIsActive(false)
      pointCloud1Ref[MapName[mapName]].pointCloudStyle.colorExpression = null
      pointCloud2Ref[MapName[mapName]].pointCloudStyle.colorExpression = null
    } else {
      setColorizationIsActive(true)
      pointCloud1Ref[MapName[mapName]].pointCloudStyle.colorExpression =
        initialColorValues.pc1color
      pointCloud2Ref[MapName[mapName]].pointCloudStyle.colorExpression =
        initialColorValues.pc2color
    }
  }

  const handleClickAway = () => {
    setOptionIsActive(false)
  }

  useEffect(() => {
    if (mapName === 'SIDE') {
      sliceSupport.sideSliceEnabled = sliceIsActive
    }
    if (mapName === 'TOP') {
      sliceSupport.topSliceEnabled = sliceIsActive
    }
    if (mapName === '3D') {
      sliceSupport.mainSliceEnabled = sliceIsActive
    }
  }, [sliceIsActive, mapName])

  useEffect(() => {
    sliceSupport.mainZoomSliceEnabled = zoomSliceIsActive
  }, [zoomSliceIsActive])

  return (
    <Inline flexDirection={'row-reverse'} alignItems="flex-start">
      <Stack
        direction="column"
        alignItems="center"
        borderRadius={2}
        padding={2}
        margin={1}
        bgcolor="background.paper"
        gap={1}
      >
        <Text color={'secondary.main'} variant="small" marginBottom={2}>
          {mapName}
        </Text>
        <ActionButton
          tooltip="Fit pointcloud on the map"
          icon={<LocateIcon />}
          onClick={handleFit}
          size="small"
        />
        <ActionButton
          tooltip="Change pointclouds options"
          icon={<SettingsAdjustIcon />}
          onClick={handleOptions}
          size="small"
          active={optionIsActive}
        />
        <ActionButton
          tooltip="Toggle slice On/Off"
          icon={<CrossSectionIcon />}
          onClick={handleSliceVisibilty}
          size="small"
          active={sliceIsActive}
          color="secondary"
        />
        {mapName === '3D' && (
          <ActionButton
            tooltip="Toggle zoom slice On/Off"
            icon={<ZoomResetIcon />}
            onClick={handleZoomSliceVisibility}
            size="small"
            active={zoomSliceIsActive}
            color="secondary"
          />
        )}
      </Stack>
      {optionIsActive && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            sx={{
              width: '400px',
              mt: '54px',
            }}
            elevation={0}
          >
            <DrawerHeader>
              <DrawerTitle icon={<GridIcon />}>PointCloud Settings</DrawerTitle>
              <DrawerActions>
                {/* <DrawerMinimizeButton /> */}
                <DrawerCloseButton onClick={handleOptions} />
              </DrawerActions>
            </DrawerHeader>
            <DrawerBody p={3} pt={5}>
              <Relative width="100%" height="100%">
                <Stack direction="row" alignItems="center">
                  <Box width="100%">
                    <Box sx={{ height: '60px', p: '24px' }}>
                      <ContentSlider
                        step={1}
                        min={1}
                        max={10}
                        marks
                        value={pcPxSize.minimumPixelSize}
                        onSliderChange={(newValue: number | number[]) =>
                          handlePixelSize({
                            ...pcPxSize,
                            minimumPixelSize: newValue as number,
                          })
                        }
                        startContent={
                          <Text size={0} sx={{ userSelect: 'none' }}>
                            Min pixel size
                          </Text>
                        }
                        endContent={
                          <Text size={0} sx={{ userSelect: 'none' }}>
                            {pcPxSize.minimumPixelSize}px
                          </Text>
                        }
                        size="small"
                      />
                    </Box>
                    <Box sx={{ height: '60px', p: '24px' }}>
                      <ContentSlider
                        step={0.005}
                        min={0}
                        max={0.15}
                        marks
                        value={pcPxSize.worldSize}
                        onSliderChange={(newValue: number | number[]) =>
                          handlePixelSize({
                            ...pcPxSize,
                            worldSize: newValue as number,
                          })
                        }
                        startContent={
                          <Text size={0} sx={{ userSelect: 'none' }}>
                            World Size
                          </Text>
                        }
                        endContent={
                          <Text size={0} sx={{ userSelect: 'none' }}>
                            {(pcPxSize.worldSize * 100).toFixed(1)}cm
                          </Text>
                        }
                        size="small"
                      />
                    </Box>
                  </Box>
                  <Box width="auto">
                    <ActionButton
                      tooltip="Reset dimensions"
                      icon={<ResetIcon />}
                      onClick={() => handlePixelSize({ ...DEFAULT_PC_SIZE })}
                      size="small"
                    />
                  </Box>
                </Stack>
                <Divider sx={{ py: '8px' }} />
                <Box width="100%" p="16px" sx={{ height: '60px', p: '24px' }}>
                  <ContentSlider
                    step={1}
                    min={0}
                    max={3}
                    marks
                    value={gapFill}
                    onSliderChange={(newValue: number | number[]) =>
                      handleGapFill(newValue as number)
                    }
                    startContent={
                      <Text size={0} sx={{ userSelect: 'none' }}>
                        Gap Fill
                      </Text>
                    }
                    endContent={
                      <Text size={0} sx={{ userSelect: 'none' }}>
                        {gapFill}
                      </Text>
                    }
                    size="small"
                  />
                </Box>
                <Divider sx={{ py: '8px' }} />
                <Box width="100%" p="16px">
                  <Inline alignItems="center" justifyContent="flex-start">
                    <Switch
                      onChange={handleColorization}
                      checked={colorizationIsActive}
                      size="small"
                    />
                    <Text ml={2} size={0}>{`Color expression is ${
                      colorizationIsActive ? 'on' : 'off'
                    }`}</Text>
                  </Inline>
                </Box>
              </Relative>
            </DrawerBody>
            {/* <DrawerFooter>
              <Button variant="contained" size="large">
                Save
              </Button>
              <Button variant="outlined" size="large">
                Cancel
              </Button>
            </DrawerFooter> */}
          </Paper>
        </ClickAwayListener>
      )}
    </Inline>
  )
}
