import { getReference } from '@luciad/ria/reference/ReferenceProvider'
import { OrientedBox } from '@luciad/ria/shape/OrientedBox'
import { Polygon } from '@luciad/ria/shape/Polygon'
import { createPolygon } from '@luciad/ria/shape/ShapeFactory'
import { createScaleTransformation } from '@luciad/ria/transformation/Affine3DTransformation'
import { EventedSupport } from '@luciad/ria/util/EventedSupport'
import {
  isInside,
  orientedBox, ParameterExpression,
} from '@luciad/ria/util/expression/ExpressionFactory'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import {
  calculateIntersection,
  calculatePointingDirections,
  calculatePolygonPoints,
} from '../util/mapSettings'

const BOX_SCALE: number = 1.4
const INVISIBLE_BOX_SCALE: number = 10
export const OPTIONS_CHANGED = 'OPTIONS_CHANGED'
const GEODETIC_REF = getReference('EPSG:4978')

export class SliceSupport {
  private readonly _eventedSupport: EventedSupport = new EventedSupport(
    [OPTIONS_CHANGED],
    true
  )

  /** reference pointcloud layers */
  private _pointCloud1Layers: TileSet3DLayer[] = []
  public get pointCloud1Layers(): TileSet3DLayer[] {
    return this._pointCloud1Layers
  }
  public set pointCloud1Layers(v: TileSet3DLayer[]) {
    this._pointCloud1Layers = v
    this.updateBoxes()
  }

  /** editable pointcloud layers */
  private _pointCloud2Layers: TileSet3DLayer[] = []
  public get pointCloud2Layers(): TileSet3DLayer[] {
    return this._pointCloud2Layers
  }
  public set pointCloud2Layers(v: TileSet3DLayer[]) {
    this._pointCloud2Layers = v
    this.updateBoxes()
  }

  /** enable slice on top map */
  private _topSliceEnabled: boolean = true
  public get topSliceEnabled(): boolean {
    return this._topSliceEnabled
  }
  public set topSliceEnabled(v: boolean) {
    this._topSliceEnabled = v
    this.invalidate()
  }

  /** enable slice on side map */
  private _sideSliceEnabled: boolean = true
  public get sideSliceEnabled(): boolean {
    return this._sideSliceEnabled
  }
  public set sideSliceEnabled(v: boolean) {
    this._sideSliceEnabled = v
    this.invalidate()
  }

  /** enable slice on 3d map */
  private _mainSliceEnabled: boolean = true
  public get mainSliceEnabled(): boolean {
    return this._mainSliceEnabled
  }
  public set mainSliceEnabled(v: boolean) {
    this._mainSliceEnabled = v
    this.invalidate()
  }

  /** enable zoom slice on 3d map */
  private _mainZoomSliceEnabled: boolean = true
  public get mainZoomSliceEnabled(): boolean {
    return this._mainZoomSliceEnabled
  }
  public set mainZoomSliceEnabled(v: boolean) {
    this._mainZoomSliceEnabled = v
    this.invalidate()
  }

  /** get the top slice box */
  private _topOrientedBox: OrientedBox | null
  private _topOrientedBoxParameter: ParameterExpression<OrientedBox> | null = null;
  public get topOrientedBox(): OrientedBox | null {
    return this._topOrientedBox
  }

  /** get the top invisible slice box */
  private _topInvisibleBox: OrientedBox | null
  public get topInvisibleBox(): OrientedBox | null {
    return this._topInvisibleBox
  }

  /** get the top zoom slice box */
  private _topZoomBox: Polygon | null
  public get topZoomBox(): Polygon | null {
    return this._topZoomBox
  }

  /** get the side slice box */
  private _sideOrientedBox: OrientedBox | null
  private _sideOrientedBoxParameter: ParameterExpression<OrientedBox>|null = null;
  public get sideOrientedBox(): OrientedBox | null {
    return this._sideOrientedBox
  }

  /** get the side invisible slice box */
  private _sideInvisibleBox: OrientedBox | null
  public get sideInvisibleBox(): OrientedBox | null {
    return this._sideInvisibleBox
  }

  /** get the top zoom slice box */
  private _sideZoomBox: Polygon | null
  public get sideZoomBox(): Polygon | null {
    return this._sideZoomBox
  }

  /** position in 0-1 for top slice */
  private _topSlicePos01: number = 0.1
  public get topSlicePos01(): number {
    return this._topSlicePos01
  }
  public set topSlicePos01(v: number) {
    this._topSlicePos01 = v
    this.updateBoxes()
  }

  /** position in 0-1 for side slice */
  private _sideSlicePos01: number = 0.5
  public get sideSlicePos01(): number {
    return this._sideSlicePos01
  }
  public set sideSlicePos01(v: number) {
    this._sideSlicePos01 = v
    this.updateBoxes()
  }

  /** thickness in 0-1 for top slice */
  private _topSliceThickness01: number = 0.1
  public get topSliceThickness01(): number {
    return this._topSliceThickness01
  }
  public set topSliceThickness01(v: number) {
    this._topSliceThickness01 = v
    this.updateBoxes()
  }

  /** thickness in meters for top slice */
  public get topSliceThicknessMeter(): number {
    if (!this._topOrientedBox) return 0
    return this._topSliceThickness01 * this._topOrientedBox.bounds.width
  }
  public set topSliceThicknessMeter(v: number) {
    if (!this._topOrientedBox) return
    this._topSliceThickness01 = v / this._topOrientedBox.bounds.width
    this.updateBoxes()
  }

  /** thickness in 0-1 for side slice */
  private _sideSliceThickness01: number = 0.05
  public get sideSliceThickness01(): number {
    return this._sideSliceThickness01
  }
  public set sideSliceThickness01(v: number) {
    this._sideSliceThickness01 = v
    this.updateBoxes()
  }

  /** thickness in meters for side slice */
  public get sideSliceThicknessMeter(): number {
    if (!this._sideOrientedBox) return 0
    return this._sideSliceThickness01 * this._sideOrientedBox.bounds.height
  }
  public set sideSliceThicknessMeter(v: number) {
    if (!this._sideOrientedBox) return
    this._sideSliceThickness01 = v / this._sideOrientedBox.bounds.height
    this.updateBoxes()
  }

  private _maps: { top: WebGLMap; side: WebGLMap } | null
  public set maps(m: { top: WebGLMap; side: WebGLMap }) {
    this._maps = m
    this._maps.top.on('MapChange', () => this.updateMapZoomSlice())
    this._maps.side.on('MapChange', () => this.updateMapZoomSlice())
  }

  constructor() {
    this._topOrientedBox = null
    this._sideOrientedBox = null
    this._topInvisibleBox = null
    this._sideInvisibleBox = null
    this._topZoomBox = null
    this._sideZoomBox = null
    this._maps = null
  }

  public invalidate() {
    if (!this._pointCloud1Layers.length || !this._pointCloud2Layers.length)
      return
    if (this._topSliceEnabled && this._topOrientedBox) {
      if (!this._topOrientedBoxParameter) {
        this._topOrientedBoxParameter = orientedBox(this._topOrientedBox);
        this.applySlice(this._pointCloud1Layers[2], this._topOrientedBoxParameter) // top base pointcloud
        this.applySlice(this._pointCloud2Layers[2], this._topOrientedBoxParameter) // top editable pointcloud
      }
      this._topOrientedBoxParameter!.value = this._topOrientedBox;
    } else {
      this._topOrientedBoxParameter = null;
      this.removeSlice(this._pointCloud1Layers[2])
      this.removeSlice(this._pointCloud2Layers[2])
    }
    if (this._sideSliceEnabled && this._sideOrientedBox) {
      if (!this._sideOrientedBoxParameter) {
        this._sideOrientedBoxParameter = orientedBox(this._sideOrientedBox);
        this.applySlice(this._pointCloud1Layers[1], this._sideOrientedBoxParameter) // side base pointcloud}
        this.applySlice(this._pointCloud2Layers[1], this._sideOrientedBoxParameter) // side editable pointcloud
      }
      this._sideOrientedBoxParameter!.value = this._sideOrientedBox;
    } else {
      this._sideOrientedBoxParameter = null;
      this.removeSlice(this._pointCloud1Layers[1])
      this.removeSlice(this._pointCloud2Layers[1])
    }
    this._eventedSupport.emit(OPTIONS_CHANGED)
  }

  updateBoxes() {
    if (this._pointCloud1Layers.length == 0) return
    this._topOrientedBox = this._pointCloud1Layers[0].orientedBox.copy()
    this._sideOrientedBox = this._topOrientedBox.copy()
    this._topInvisibleBox = this._topOrientedBox.copy()
    this._sideInvisibleBox = this._topOrientedBox.copy()

    const bb = this._topOrientedBox.bounds

    this._topOrientedBox.transform(
      createScaleTransformation({
        centerX: bb.x + bb.width * this._topSlicePos01,
        centerY: bb.y + bb.height * 0.5,
        centerZ: bb.z + bb.depth * 0.5,
        scaleX: this._topSliceThickness01,
        scaleY: BOX_SCALE,
        scaleZ: BOX_SCALE,
        destinationReference: GEODETIC_REF,
      })
    )

    this._topInvisibleBox.transform(
      createScaleTransformation({
        centerX: bb.x + bb.width * this._topSlicePos01,
        centerY: bb.y + bb.height * 0.5,
        centerZ: bb.z + bb.depth * 0.5,
        scaleX: this._topSliceThickness01,
        scaleY: INVISIBLE_BOX_SCALE,
        scaleZ: INVISIBLE_BOX_SCALE,
        destinationReference: GEODETIC_REF,
      })
    )

    this._sideOrientedBox.transform(
      createScaleTransformation({
        centerX: bb.x + bb.width * 0.5,
        centerY: bb.y + bb.height * 0.5,
        centerZ: bb.z + bb.depth * this._sideSlicePos01,
        scaleX: BOX_SCALE,
        scaleY: BOX_SCALE,
        scaleZ: this._sideSliceThickness01,
        destinationReference: GEODETIC_REF,
      })
    )

    this._sideInvisibleBox.transform(
      createScaleTransformation({
        centerX: bb.x + bb.width * 0.5,
        centerY: bb.y + bb.height * 0.5,
        centerZ: bb.z + bb.depth * this._sideSlicePos01,
        scaleX: INVISIBLE_BOX_SCALE,
        scaleY: INVISIBLE_BOX_SCALE,
        scaleZ: this._sideSliceThickness01,
        destinationReference: GEODETIC_REF,
      })
    )
    this.updateMapZoomSlice()
    this.invalidate()
  }

  updateMapZoomSlice() {
    if (!this._maps) return
    if (this._pointCloud1Layers.length == 0) return
    if (!this._topInvisibleBox || !this._sideInvisibleBox) return

    // TOP MAP SLICE
    const topIntersectionCorners = calculateIntersection(
      calculatePointingDirections(this._maps.top),
      this._topInvisibleBox,
      this._maps.top.camera
    )

    if (!topIntersectionCorners) return
    const topPoints = calculatePolygonPoints(topIntersectionCorners)
    this._topZoomBox = createPolygon(GEODETIC_REF, topPoints)

    // SIDE MAP SLICE
    const sideIntersectionCorners = calculateIntersection(
      calculatePointingDirections(this._maps.side),
      this._sideInvisibleBox,
      this._maps.side.camera
    )

    if (!sideIntersectionCorners) return
    const sidePoints = calculatePolygonPoints(sideIntersectionCorners)
    this._sideZoomBox = createPolygon(GEODETIC_REF, sidePoints)

    this._eventedSupport.emit(OPTIONS_CHANGED)
  }

  private applySlice = (
    layer: TileSet3DLayer,
    sliceOrientedBox: ParameterExpression<OrientedBox>
  ) => {
    // the slice is infinite in the directions not involved in the slice
    //const bigNumber: number = 1000000
    // the slice is a little bigger than the bouns of the point cloud in the directions not involved in the slice

    //const sliceThickness = 1
    // this is a number in [0, 1] that rapresent the position of the center of the slice
    // in respect to the point cloud (0.5 -> in the middle of the point cloud)
    //const sliceYPos01 = 0.5
    //const sliceZPos01 = 0.1

    layer.pointCloudStyle.visibilityExpression = isInside(sliceOrientedBox)
  }

  private removeSlice = (layer: TileSet3DLayer) => {
    layer.pointCloudStyle.visibilityExpression = null
  }

  on(event: typeof OPTIONS_CHANGED, callback: () => void) {
    return this._eventedSupport.on(event, callback)
  }
}
