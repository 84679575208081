import { OrientedBox } from '@luciad/ria/shape/OrientedBox'
import { Camera } from '@luciad/ria/view/camera/Camera'
import { PerspectiveCamera } from '@luciad/ria/view/camera/PerspectiveCamera'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import { createFacePolygons } from '../common/util/AdvancedShapeFactory'
import { calculatePointingDirection } from '../common/util/PerspectiveCameraUtil'
import { Vector3 } from '@luciad/ria/util/Vector3'
import { rayPlaneIntersection } from './Vector3Util'
import { Point } from '@luciad/ria/shape/Point'
import { createPoint } from '@luciad/ria/shape/ShapeFactory'
import { getReference } from '@luciad/ria/reference/ReferenceProvider'

const GLOBE_COLOR = 'rgba(0,0,0,0)'
const GEODETIC_REF = getReference('EPSG:4978')

export const addMapSettings = (map: WebGLMap) => {
  return (
    (map.effects.atmosphere = false),
    (map.effects.starfield = false),
    (map.globeColor = GLOBE_COLOR),
    (map.mapNavigator.constraints.above = null)
  )
}

export const setMapCamera = (map: WebGLMap, pitch: number) => {
  return (map.camera = (map.camera as PerspectiveCamera).lookFrom({
    eye: map.camera.eye,
    yaw: 0,
    pitch,
    roll: 0,
  }))
}

export const setMapFovY = (map: WebGLMap, fovY: number) => {
  return (map.camera = map.camera.copyAndSet({ fovY }))
}

export const calculatePointingDirections = (map: WebGLMap): Vector3[] => {
  const corners = [
    [0, 0],
    [0, map.viewSize[1]],
    [map.viewSize[0], map.viewSize[1]],
    [map.viewSize[0], 0],
  ]
  const calculatedDirections = corners.map((corner) => {
    const value = calculatePointingDirection(map, {
      x: corner[0],
      y: corner[1],
    })
    return value
  })
  return calculatedDirections
}

export const calculateIntersection = (
  calculatedDirections: Vector3[],
  orientedBox: OrientedBox,
  camera: Camera
): Vector3[] => {
  const boxSide = createFacePolygons(orientedBox)
  /* const firstPoint: Vector3 = boxSide[0].getPoint[0]
  const depth = scalar(camera.forward, cornerBox) */
  const intersectionCorners = calculatedDirections.map((direction) => {
    const rayIntersection = rayPlaneIntersection(
      camera.eye,
      direction,
      camera.forward,
      boxSide[0].getPoint(0)
    )
    return rayIntersection ? rayIntersection : boxSide[0].getPoint(0)
  })
  return intersectionCorners
}

export const calculatePolygonPoints = (intersectionCorners: Vector3[]) => {
  const points: Point[] = intersectionCorners.map((corner) => {
    const point: Point = createPoint(GEODETIC_REF, [
      corner!.x,
      corner!.y,
      corner!.z,
    ])
    return point
  })
  return points
}
