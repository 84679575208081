import {TileSet3DLayer} from '@luciad/ria/view/tileset/TileSet3DLayer';
import {createOGC3DTilesModel} from '@luciad/ria/model/tileset/OGC3DTilesModel';
import {FusionTileSetModel} from '@luciad/ria/model/tileset/FusionTileSetModel';
import {RasterTileSetLayer} from '@luciad/ria/view/tileset/RasterTileSetLayer';
import {WMSTileSetModel} from '@luciad/ria/model/tileset/WMSTileSetModel';
import {LayerType} from '@luciad/ria/view/LayerType';
import {WMSTileSetLayer} from '@luciad/ria/view/tileset/WMSTileSetLayer';
import {Layer} from '@luciad/ria/view/Layer';
import {HXDR_DOMAIN} from './PersistanceUtil';
import {WMTSTileSetModel} from '@luciad/ria/model/tileset/WMTSTileSetModel';
import {ScalingMode} from '@luciad/ria/view/style/ScalingMode';
import {number} from '@luciad/ria/util/expression/ExpressionFactory';
import {createHSPCTilesModel} from '@luciad/ria/model/tileset/HSPCTilesModel';
import {createOffsetTransformation} from '@luciad/ria/transformation/Affine3DTransformation';
import {getVerticalLayerOffset} from './hardcoded';

function createRequestHeaders(bearerToken: string) {
  return {Authorization: bearerToken};
}

export type LayerAddressType =
  | 'WMS'
  | 'OGC_3D_TILES'
  | 'WMTS'
  | 'HSPC'
  | 'PANORAMIC'
  | 'LTS';

interface BaseLayerAddress {
  id: string;
  type: LayerAddressType;
}

interface LTSLayerAddress extends BaseLayerAddress {
  endpoint: string;
  datasetId: string;
}

interface WMSLayerAddress extends BaseLayerAddress {
  endpoint: string;
  datasetId: string;
  imageFormat?: string | null;
}

interface WMTSLayerAddress extends BaseLayerAddress {
  endpoint: string;
  datasetId: string;
  imageFormat?: string | null;
}

interface Ogc3DTilesAddress extends BaseLayerAddress {
  endpoint: string;
  qualityFactor: number;
}

interface HSPCLayerAddress extends BaseLayerAddress {
  endpoint: string;
}

export type LayerAddress =
  | LTSLayerAddress
  | WMSLayerAddress
  | WMTSLayerAddress
  | Ogc3DTilesAddress
  | HSPCLayerAddress;

interface LayerAddressOptions {
  id?: string;
  label?: string;
}

/**
 * Returns a RIA layer corresponding to the given layer address
 * @param address the HxDR layer fragment that contains information to load a layer in RIA
 * @param bearerToken the token to be used in the authorization of all layer requests
 * @param options the (optional) layer options
 */
export async function loadLayerAddress(
  address: LayerAddress,
  bearerToken: string,
  options?: LayerAddressOptions
): Promise<Layer> {
  if (address.type === 'OGC_3D_TILES') {
    return load3DTilesAddress(
      address as Ogc3DTilesAddress,
      bearerToken,
      options
    );
  } else if (address.type === 'HSPC') {
    return loadHSPCAddress(address as HSPCLayerAddress, bearerToken, options);
  } else if (address.type === 'WMS') {
    return loadWMSAddress(address as WMSLayerAddress, bearerToken, options);
  } else if (address.type === 'WMTS') {
    return loadWMTSAddress(address as WMTSLayerAddress, bearerToken, options);
  } else if (address.type === 'LTS') {
    return loadLTSAddress(address as LTSLayerAddress, bearerToken, options);
  } else {
    throw new Error(
      `Can not create layer from address with type "${address.type}"`
    );
  }
}

async function load3DTilesAddress(
  address: Ogc3DTilesAddress,
  bearerToken: string,
  options?: LayerAddressOptions
): Promise<TileSet3DLayer> {
  const model = await createOGC3DTilesModel(HXDR_DOMAIN + address.endpoint, {
    requestHeaders: createRequestHeaders(bearerToken),
  });

  return new TileSet3DLayer(model, {
    id: options?.id ?? address.id,
    isPartOfTerrain: true,
    fadingTime: 800,
    qualityFactor: address.qualityFactor,
    offsetTerrain: false,
    label: options?.label,
  });
}

async function loadHSPCAddress(
  address: HSPCLayerAddress,
  bearerToken: string,
  options?: LayerAddressOptions
): Promise<TileSet3DLayer> {
  const model = await createHSPCTilesModel(HXDR_DOMAIN + address.endpoint, {
    requestHeaders: createRequestHeaders(bearerToken),
  });

  return new TileSet3DLayer(model, {
    id: options?.id ?? address.id,
    isPartOfTerrain: true,
    fadingTime: 150,
    offsetTerrain: false,
    pointCloudStyle: {
      scalingMode: ScalingMode.PIXEL_SIZE,
      scaleExpression: number(0.5),
    },
    label: options?.label,
    qualityFactor: 0.5,
  });
}

export async function loadWMSAddress(
  address: WMSLayerAddress,
  bearerToken: string,
  options?: LayerAddressOptions
): Promise<WMSTileSetLayer> {
  const model = await WMSTileSetModel.createFromURL(
    HXDR_DOMAIN + address.endpoint,
    [
      {
        layer: address.datasetId,
      },
    ],
    {
      imageFormat: address.imageFormat ?? undefined,
      credentials: true,
      requestHeaders: createRequestHeaders(bearerToken),
      swapAxes: ['EPSG:4326'],
    }
  );

  return new WMSTileSetLayer(model, {
    id: options?.id ?? address.id,
    label: options?.label,
    layerType: LayerType.STATIC,
  });
}

export async function loadWMTSAddress(
  address: WMTSLayerAddress,
  bearerToken: string,
  options?: LayerAddressOptions
): Promise<RasterTileSetLayer> {
  const model = await WMTSTileSetModel.createFromURL(
    HXDR_DOMAIN + address.endpoint,
    {
      layer: address.datasetId,
    },
    {
      format: address.imageFormat ?? undefined,
      credentials: true,
      requestHeaders: createRequestHeaders(bearerToken),
    }
  );

  return new RasterTileSetLayer(model, {
    id: options?.id ?? address.id,
    label: options?.label,
    layerType: LayerType.STATIC,
  });
}

export async function loadLTSAddress(
  address: LTSLayerAddress,
  bearerToken: string,
  options?: LayerAddressOptions
): Promise<RasterTileSetLayer> {
  const url = HXDR_DOMAIN + address.endpoint;
  const baseUrl = url.split('?')[0];

  const queryParameters: {[param: string]: string} = {};
  for (const elem of url.split('?')[1].split('&')) {
    const index = elem.indexOf('=');
    queryParameters[elem.substring(0, index)] = elem.substring(index + 1);
  }

  const model = await FusionTileSetModel.createFromURL(
    baseUrl,
    address.datasetId,
    {
      credentials: true,
      requestHeaders: createRequestHeaders(bearerToken),
      requestParameters: queryParameters,
    }
  );

  return new RasterTileSetLayer(model, {
    id: options?.id ?? address.id,
    label: options?.label,
  });
}

/**
 * Adjusts the height of the given layer such that it would fit better on the globes surface
 */
export function adjustLayer(layer: TileSet3DLayer, layerAddressId: string) {
  layer.transformation = createOffsetTransformation(
    {
      x: 0,
      y: 0,
      z: getVerticalLayerOffset(layerAddressId),
    },
    layer.model.bounds.focusPoint
  );
}
