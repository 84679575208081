import {FolderInfo} from './useFolder';
import React, {ReactNode} from 'react';
import {
  Absolute,
  CircularProgress,
  IconButton,
  Inline,
  Stack,
  Text,
} from '@digitalreality/ui';
import {ProcessingAsset} from './useProcessingAssets';
import {ProcessingStateIcon} from './ProcessingStateIcon';
import {
  Asset3dIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  FolderIcon,
  PageFirstIcon,
  PageLastIcon,
} from '@digitalreality/ui-icons';
import {ProcessingState} from './AssetPersistanceUtil';

interface Props {
  title: string;
  pageNumber: number;
  pagesAmount: number;
  onSelectPage: (page: number) => void;
  subFolders: FolderInfo[];
  assets?: ProcessingAsset[];
  selectedAsset?: ProcessingAsset | null;
  onSelectAsset?: (asset: ProcessingAsset) => void;
  onDoubleClickAsset?: (asset: ProcessingAsset) => void;
  selectedFolder?: FolderInfo | null;
  onSelectFolder?: (asset: FolderInfo) => void;
  onEnterSubFolder: (subFolder: FolderInfo) => void;
  onGoBack?: () => void;
  busy?: boolean;
}

/**
 * Reusable component that represents a list of HxDR assets and folders in a filesystem-like explorer.
 */
export const HxDRAssetList = ({
  title,
  pageNumber,
  pagesAmount,
  onSelectPage,
  subFolders,
  assets = [],
  selectedAsset,
  onSelectAsset,
  onDoubleClickAsset,
  selectedFolder,
  onSelectFolder,
  onEnterSubFolder,
  onGoBack,
  busy,
}: Props) => {
  return (
    <Stack width="100%" gap="4px" sx={{flex: 1}}>
      {onGoBack ? (
        <Inline
          width="fit-content"
          padding="7px"
          paddingRight="15px"
          onClick={onGoBack}
          borderRadius="8px"
          sx={(theme) => ({
            ':hover': {
              backgroundColor: theme.colors.ui.menuSecondary,
              cursor: 'pointer',
            },
          })}
        >
          <ChevronLeftIcon />
          <Text size={1} noWrap sx={{userSelect: 'none'}}>
            {title}
          </Text>
        </Inline>
      ) : (
        <Text size={1} padding="8px" sx={{userSelect: 'none'}}>
          {title}
        </Text>
      )}
      <Stack width="100%" marginLeft="8px" sx={{flex: 1, position: 'relative'}}>
        {busy && (
          <Absolute top="8px" left="8px">
            <CircularProgress size={32} />
          </Absolute>
        )}
        {subFolders.map((folder) => (
          <HxDRAssetListRow
            key={folder.id}
            onClick={() => onSelectFolder?.(folder)}
            onDoubleClick={() => onEnterSubFolder(folder)}
            icon={<FolderIcon />}
            label={folder.name}
            highlighted={folder.id === selectedFolder?.id}
          />
        ))}
        {assets.map((asset) => (
          <HxDRAssetListRow
            key={asset.groupedAssetId}
            onClick={() => onSelectAsset?.(asset)}
            onDoubleClick={() => onDoubleClickAsset?.(asset)}
            icon={<Asset3dIcon />}
            label={asset.name}
            highlighted={asset.groupedAssetId === selectedAsset?.groupedAssetId}
            endNode={
              asset.state === ProcessingState.READY ? null : (
                <ProcessingStateIcon
                  state={asset.state}
                  subStates={asset.subStates}
                />
              )
            }
          />
        ))}
      </Stack>
      <PaginationLine
        pageNumber={pageNumber}
        pagesAmount={pagesAmount}
        onSelectPage={onSelectPage}
      />
    </Stack>
  );
};

interface RowProps {
  onClick: () => void;
  onDoubleClick?: () => void;
  icon: ReactNode;
  label: string;
  highlighted?: boolean;
  endNode?: ReactNode;
}

const HxDRAssetListRow = ({
  onClick,
  onDoubleClick,
  icon,
  label,
  highlighted = false,
  endNode = null,
}: RowProps) => {
  function handleClick(e: React.MouseEvent) {
    if (e.detail < 2) {
      onClick();
    } else {
      onDoubleClick?.();
    }
  }

  return (
    <Inline
      onClick={handleClick}
      alignItems="center"
      width="100%"
      borderRadius="2px"
      padding="4px"
      bgcolor={highlighted ? 'grey.600' : undefined}
      sx={{
        userSelect: 'none',
        ':hover': {
          bgcolor: 'grey.600',
        },
      }}
    >
      {icon}
      <Text noWrap size={1} sx={{flex: 1}}>
        {label}
      </Text>
      {endNode}
    </Inline>
  );
};

interface PaginationProps {
  pageNumber: number;
  pagesAmount: number;
  onSelectPage: (page: number) => void;
}

const PaginationLine = ({
  pageNumber,
  pagesAmount,
  onSelectPage,
}: PaginationProps) => {
  return (
    <Inline width="100%" alignItems="center" justifyContent="center">
      <IconButton
        size="small"
        disabled={pageNumber === 0}
        onClick={() => onSelectPage(0)}
      >
        <PageFirstIcon />
      </IconButton>
      <IconButton
        size="small"
        disabled={pageNumber === 0}
        onClick={() => onSelectPage(pageNumber - 1)}
      >
        <ChevronLeftIcon />
      </IconButton>
      <Text size={1}>
        Page {pageNumber + 1} of {pagesAmount}
      </Text>
      <IconButton
        size="small"
        disabled={pageNumber >= pagesAmount - 1}
        onClick={() => onSelectPage(pageNumber + 1)}
      >
        <ChevronRightIcon />
      </IconButton>
      <IconButton
        size="small"
        disabled={pageNumber >= pagesAmount - 1}
        onClick={() => onSelectPage(pagesAmount - 1)}
      >
        <PageLastIcon />
      </IconButton>
    </Inline>
  );
};
