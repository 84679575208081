import React, { useEffect, useState } from 'react'
import { Box, ContentSlider, Stack, Text } from '@digitalreality/ui'
import { SliceSupport } from '../alignment/SliceSupport'

interface IMainMapSlidersProps {
  sliceSupport: SliceSupport
}

export const MainMapSliders = ({ sliceSupport }: IMainMapSlidersProps) => {
  const [topSlider, setTopSlider] = useState<number | number[]>(
    sliceSupport.topSlicePos01
  )
  const [sideSlider, setSideSlider] = useState<number | number[]>(
    sliceSupport.sideSlicePos01
  )
  const [enabled, setEnabled] = useState<boolean>(sliceSupport.mainSliceEnabled)

  useEffect(() => {
    sliceSupport.topSlicePos01 = topSlider as number
  }, [topSlider, sliceSupport])

  useEffect(() => {
    sliceSupport.sideSlicePos01 = sideSlider as number
  }, [sideSlider, sliceSupport])

  useEffect(() => {
    sliceSupport.on('OPTIONS_CHANGED', () => {
      setEnabled(sliceSupport.mainSliceEnabled)
    })
  }, [])

  if (!enabled) return null

  return (
    <Stack
      alignItems="center"
      width="calc(100% - 160px)"
      maxWidth="420px"
      borderRadius={2}
      padding={2}
      margin={1}
      bgcolor="background.paper"
      gap={1}
    >
      <Box sx={{ width: '100%', height: '48px', p: '24px' }}>
        <ContentSlider
          step={0.01}
          min={0}
          max={1}
          value={topSlider}
          onSliderChange={(newValue: number | number[]) =>
            setTopSlider(newValue)
          }
          startContent={
            <Text size={0} sx={{ userSelect: 'none' }}>
              Top view slice position
            </Text>
          }
          endContent={
            <Text size={0} sx={{ userSelect: 'none' }}>
              {Math.round((topSlider as number) * 100)}%
            </Text>
          }
          size="small"
        />
      </Box>
      <Box sx={{ width: '100%', height: '48px', p: '24px' }}>
        <ContentSlider
          step={0.01}
          min={0}
          max={1}
          value={sideSlider}
          onSliderChange={(newValue: number | number[]) =>
            setSideSlider(newValue)
          }
          startContent={
            <Text size={0} sx={{ userSelect: 'none' }}>
              Side view slice position
            </Text>
          }
          endContent={
            <Text size={0} sx={{ userSelect: 'none' }}>
              {Math.round((sideSlider as number) * 100)}%
            </Text>
          }
          size="small"
        />
      </Box>
    </Stack>
  )
}
