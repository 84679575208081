import React, { useState } from 'react'
import { Map } from '@luciad/ria/view/Map'
import { Button, Stack, Text } from '@digitalreality/ui'
import { UrlInput } from './UrlInput'
import { useCurrentProject } from '../../common/context/useCurrentProject'

interface Props {
  map: Map
  isOpen: boolean
  onClose: () => void
}

export const UrlAssetsTab = ({ map, isOpen, onClose }: Props) => {
  const [url1, setUrl1] = useState('')
  const [url2, setUrl2] = useState('')
  const [unsupportedPC, setUnsupportedPC] = useState(false)
  const { setManualUrl1, setManualUrl2 } = useCurrentProject()

  const returnFormat = (url: string) => {
    if (url.toLowerCase().includes('hspc')) return 'hspc'
    // we are going to support HSPC only
    // if (url.toLowerCase().includes('json')) return 'json'
    return 'unsupported'
  }

  const handlePCload = async () => {
    const url1format = returnFormat(url1)
    const url2format = returnFormat(url2)
    if (url1format === 'unsupported' || url2format === 'unsupported') {
      setUnsupportedPC(true)
      return
    }
    console.log(`URL 1 is : ${url1} [${url1format}]`)
    console.log(`URL 2 is : ${url2} [${url2format}]`)
    setManualUrl1({ address: url1, type: url1format })
    setManualUrl2({ address: url2, type: url2format })
    onClose()
  }

  const validateUrl = (inputUrl: string) => {
    const urlPattern =
      /^(https?:\/\/)?(([\w.-]+)|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(?::(\d{1,5}))?([\/\w.-]*)*\/?$/i
    setUnsupportedPC(false)
    return urlPattern.test(inputUrl)
  }

  return (
    <Stack height="100%" width="100%" paddingTop="16px" spacing={5}>
      <UrlInput
        label="URL 1"
        value={url1}
        onChange={setUrl1}
        validateUrl={validateUrl}
      />
      <UrlInput
        label="URL 2"
        value={url2}
        onChange={setUrl2}
        validateUrl={validateUrl}
      />
      {url1 && url2 && (
        <Button disabled={unsupportedPC} onClick={handlePCload}>
          Load Pointclouds
        </Button>
      )}
      {unsupportedPC && (
        <Text variant="caption" color="error">
          The supported format is HSPC
        </Text>
      )}
    </Stack>
  )
}
