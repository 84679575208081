import { Stack } from '@digitalreality/ui';
import React, { useEffect, useRef, useState } from 'react';
import { FolderInfo } from './useFolder';
import {
  HxDRProjectsBrowser,
  HxDRProjectsBrowserRef,
} from './HxDRProjectsBrowser';
import { AddDataFooter } from './AddDataFooter';
import { ProcessingAsset } from './useProcessingAssets';
import { ProcessingState } from './AssetPersistanceUtil';
import { Map } from '@luciad/ria/view/Map';
import { addAssetToMap } from './AssetLayerUtil';
import { GeolocationSupport } from '../../geolocation/GeolocationSupport';
import { useCurrentProject } from '../../common/context/useCurrentProject';

const PAGE_SIZE = 8;

interface Props {
  map: Map;
  isOpen: boolean;
  onClose: () => void;
}

/**
 * Tab component that allows users to select which HxDR asset they want to load on the map.
 */
export const HxDRAssetsTab = ({
  map,
  isOpen,
  onClose,
}: Props) => {
  const [confirmButtonName, setConfirmButtonName] = useState<string>('Add');
  const [confirmButtonEnabled, setConfirmButtonEnabled] =
    useState<boolean>(false);
  const [itemToAdd, setItemToAdd] = useState<ProcessingAsset | null>(null);
  const [selectedFolder, setSelectedFolder] = useState<FolderInfo | null>(null);
  const browserRef = useRef<HxDRProjectsBrowserRef>(null);
  const { currentProject, setHspc1, setHspc2 } = useCurrentProject();

  function addAsset(asset: ProcessingAsset) {
    if (currentProject?.hspc1) {
      setHspc2(asset);
    } else {
      setHspc1(asset);
    }
    if (asset.state !== ProcessingState.READY) {
      console.warn('Only fully ready assets can be loaded');
      return;
    }
    onClose()
    // addAssetToMap(map, asset).then(onClose);
  }

  function handleConfirm() {
    if (itemToAdd) {
      addAsset(itemToAdd);
    } else if (selectedFolder) {
      browserRef.current?.enterFolder(selectedFolder);
    }
  }

  function handleSelectAsset(asset: ProcessingAsset | null) {
    if (!asset) {
      setItemToAdd(null);
    } else if (asset.state === ProcessingState.READY) {
      setItemToAdd(asset);
      if (selectedFolder) {
        setSelectedFolder(null);
      }
    }
  }

  function handleSelectFolder(folder: FolderInfo | null) {
    setSelectedFolder(folder);
    if (folder && itemToAdd) {
      setItemToAdd(null);
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setItemToAdd(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setConfirmButtonName(selectedFolder ? 'Open' : 'Add');
      setConfirmButtonEnabled(!!itemToAdd || !!selectedFolder);
    }
  }, [isOpen, itemToAdd, selectedFolder]);

  return (
    <Stack height='100%' width='100%' paddingTop='8px'>
      <HxDRProjectsBrowser
        ref={browserRef}
        pageSize={PAGE_SIZE}
        selectedAsset={itemToAdd}
        onSelectAsset={handleSelectAsset}
        onDoubleClickAsset={addAsset}
        selectedFolder={selectedFolder}
        onSelectFolder={handleSelectFolder}
      />
      <AddDataFooter
        onClose={onClose}
        onConfirm={handleConfirm}
        confirmButtonName={confirmButtonName}
        confirmButtonEnabled={confirmButtonEnabled}
      />
    </Stack>
  );
};
