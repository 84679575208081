import {KeyEvent} from '@luciad/ria/view/input/KeyEvent';
import {HandleEventResult} from '@luciad/ria/view/controller/HandleEventResult';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {KeyEventType} from '@luciad/ria/view/input/KeyEventType';
import {Map as RIAMap} from '@luciad/ria/view/Map';
import {AssetDimension} from './AssetDimension';
import {computeSpeedChange, SpeedChange} from './MoveSpeedSupport';
import {Vector3} from '@luciad/ria/util/Vector3';
import {add, cross, negate, normalize, scale} from '../../util/Vector3Util';
import {clamp} from '../../common/util/Math';

const verticalUp = (camera: PerspectiveCamera) => normalize(camera.eye);
const verticalDown = (camera: PerspectiveCamera) => negate(verticalUp(camera));
const horizontalRight = (camera: PerspectiveCamera) =>
  normalize(cross(camera.forward, verticalUp(camera)));
const horizontalLeft = (camera: PerspectiveCamera) =>
  negate(horizontalRight(camera));
const horizontalForward = (camera: PerspectiveCamera) =>
  normalize(cross(verticalUp(camera), horizontalRight(camera)));
const horizontalBack = (camera: PerspectiveCamera) =>
  negate(horizontalForward(camera));

const forward = (camera: PerspectiveCamera) => camera.forward;
const back = (camera: PerspectiveCamera) => negate(camera.forward);
const right = (camera: PerspectiveCamera) => cross(camera.forward, camera.up);
const left = (camera: PerspectiveCamera) => negate(right(camera));
const up = (camera: PerspectiveCamera) => camera.up;
const down = (camera: PerspectiveCamera) => negate(camera.up);

type ActionFPV = (camera: PerspectiveCamera) => Vector3;
type KeyMapping = Map<string, ActionFPV>;

const mappingModeForward: KeyMapping = new Map([
  ['ArrowUp', forward],
  ['ArrowDown', back],
  ['ArrowRight', right],
  ['ArrowLeft', left],
  ['KeyW', forward],
  ['KeyS', back],
  ['KeyD', right],
  ['KeyA', left],
  ['KeyE', up],
  ['KeyQ', down],
]);

const mappingModeTangent: KeyMapping = new Map([
  ['ArrowUp', horizontalForward],
  ['ArrowDown', horizontalBack],
  ['ArrowRight', horizontalRight],
  ['ArrowLeft', horizontalLeft],
  ['KeyW', horizontalForward],
  ['KeyS', horizontalBack],
  ['KeyD', horizontalRight],
  ['KeyA', horizontalLeft],
  ['KeyE', verticalUp],
  ['KeyQ', verticalDown],
]);

// All speeds are expressed in meters / millisecond
const MAX_MOVEMENT_SPEED = 1;
const MIN_MOVEMENT_SPEED = 0.005;
const DEFAULT_MOVEMENT_SPEED = 0.005; // 5 mm / millisecond => 18 km/h

export enum NavigationKeysMode {
  /**
   * The forward translation along the camera's forward vector.
   */
  CAMERA_FORWARD,
  /**
   * The forward translation is along the camera's forward vector cast on the earth tangent plane.
   */
  TANGENT_FORWARD,
}

interface NavigationKeysOptions {
  mappingMode: NavigationKeysMode;
  defaultSpeed?: number;
}

/**
 * First person controller that operates fully in cartesian space (it does not know anything about the globe).
 */
export class NavigationKeys {
  private readonly _downKeys = new Set<string>();
  private readonly _assetDimension: AssetDimension;
  private readonly _keyMapping: KeyMapping;
  private readonly _movementSpeed: number;
  private _speedChange: SpeedChange;
  private _map: RIAMap | null = null;
  private _timeStamp = 0;

  constructor(
    assetDimension: AssetDimension,
    { defaultSpeed, mappingMode }: NavigationKeysOptions
  ) {
    this._assetDimension = assetDimension;
    this._speedChange = SpeedChange.NORMAL;
    this._movementSpeed = clamp(
      defaultSpeed || DEFAULT_MOVEMENT_SPEED,
      MIN_MOVEMENT_SPEED,
      MAX_MOVEMENT_SPEED
    );
    this._keyMapping =
      mappingMode === NavigationKeysMode.CAMERA_FORWARD
        ? mappingModeForward
        : mappingModeTangent;
  }

  activate(map: RIAMap) {
    this._timeStamp = performance.now();
    this._map = map;
    this.update();
  }

  deactivate() {
    this._map = null;
    this._downKeys.clear();
  }

  onKeyEvent(keyEvent: KeyEvent): HandleEventResult {
    const event = keyEvent.domEvent;
    if (!event || !this._map) {
      return HandleEventResult.EVENT_IGNORED;
    }

    const isFaster = event.shiftKey;
    const isSlower =
      event.code === 'Space' && keyEvent.type === KeyEventType.DOWN;
    this._speedChange = computeSpeedChange(isFaster, isSlower);

    if (!this._keyMapping.has(event.code)) {
      return HandleEventResult.EVENT_IGNORED;
    }

    if (keyEvent.type === KeyEventType.DOWN) {
      if (!this._downKeys.has(event.code)) {
        this._downKeys.add(event.code);
        return HandleEventResult.EVENT_HANDLED;
      }
    } else if (keyEvent.type === KeyEventType.UP) {
      if (this._downKeys.has(event.code)) {
        this._downKeys.delete(event.code);
        return HandleEventResult.EVENT_HANDLED;
      }
    }
    return HandleEventResult.EVENT_IGNORED;
  }

  private update() {
    // End looping when the map has no layers. It is required because of the RIA bug:
    // the map on destroy should de-activate its controller (RIA-4243)
    if (!this._map || this._map.layerTree.children.length === 0) {
      return;
    }
    const newTimeStamp = performance.now();
    const deltaTime = newTimeStamp - this._timeStamp;
    this._timeStamp = newTimeStamp;

    this.updateMove(this._map, deltaTime);
    requestAnimationFrame(() => this.update());
  }

  private updateMove(map: RIAMap, deltaTime: number): void {
    // This check is important - updating camera will make the map never trigger the "idle" event.
    if (this._downKeys.size === 0) {
      return;
    }

    let camera = map.camera as PerspectiveCamera;
    for (const key of this._downKeys.keys()) {
      const translateFunc = this._keyMapping.get(key);
      if (translateFunc) {
        const newCamera = this.move(camera, translateFunc(camera), deltaTime);
        if (newCamera) {
          camera = newCamera;
        }
      }
    }

    map.camera = camera;
  }

  private move(
    camera: PerspectiveCamera,
    translation: Vector3,
    deltaTime: number
  ): PerspectiveCamera | null {
    const speedFactor =
      this._speedChange === SpeedChange.FASTER
        ? 3
        : this._speedChange === SpeedChange.SLOWER
        ? 0.25
        : 1.0;
    const distance = this._movementSpeed * speedFactor * deltaTime;
    const translationVector = scale(normalize(translation), distance);
    const newEye = add(camera.eye, translationVector);
    if (this._assetDimension.isInAssetWorld(newEye)) {
      return camera.copyAndSet({ eye: newEye });
    }
    return null;
  }
}
