import {Codec} from "@luciad/ria/model/codec/Codec";
import {Cursor} from "@luciad/ria/model/Cursor";

/**
 * A {@link @luciad/ria/model/Store} that reads content from a browser-local HTML5 File object.
 */
export class LocalFileStore {
  private _codec: Codec;
  private _reading: Promise<string | ArrayBuffer | null>;

  constructor(file: File, codec: Codec) {
    this._codec = codec;
    this._reading = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (): void => {
        resolve(reader.result);
      };
      reader.onerror = (): void => {
        reject(`Cannot load file ${file.name}`);
      };
    });
  }

  query(): Promise<Cursor> {
    const self = this;
    return this._reading.then(result => self._codec.decode({
      content: result!
    }));
  }
}