import { Controller } from '@luciad/ria/view/controller/Controller'
import { GeoCanvas } from '@luciad/ria/view/style/GeoCanvas'
import { OPTIONS_CHANGED, SliceSupport } from '../../alignment/SliceSupport'
import { createFacePolygons } from '../util/AdvancedShapeFactory'

export class DrawZoomSliceController extends Controller {
  private _sliceSupport: SliceSupport

  constructor(sliceSupport: SliceSupport) {
    super()
    this._sliceSupport = sliceSupport
    const handleBox = this._sliceSupport.on(OPTIONS_CHANGED, () =>
      this.invalidate()
    )
  }

  onDraw(geoCanvas: GeoCanvas) {
    if (!this._sliceSupport.topZoomBox || !this._sliceSupport.sideZoomBox)
      return
    if (!this._sliceSupport.mainZoomSliceEnabled) return

    geoCanvas.drawShape(this._sliceSupport.topZoomBox, {
      fill: { color: 'rgba(0,0,255, 0.05)' },
      stroke: { color: 'rgba(0,0,255, 0.8)' },
    })

    geoCanvas.drawShape(this._sliceSupport.sideZoomBox, {
      fill: { color: 'rgba(0,0,255, 0.05)' },
      stroke: { color: 'rgba(0,0,255, 0.8)' },
    })
  }
}
