import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/projects/demo');
  }, []);
  return (
    <div>
      <p>
        <Link to='/projects/demo'>Go to the demo project</Link>
      </p>
    </div>
  );
};
