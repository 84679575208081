import {GestureEvent} from '@luciad/ria/view/input/GestureEvent';
import {GestureEventType} from '@luciad/ria/view/input/GestureEventType';
import {ModifierType} from '@luciad/ria/view/input/ModifierType';
import {ScrollEvent} from '@luciad/ria/view/input/ScrollEvent';
import {PinchEvent} from '@luciad/ria/view/input/PinchEvent';
import {KeyEvent} from '@luciad/ria/view/input/KeyEvent';

export enum NavigationType {
  NONE,
  ROTATION,
  PAN,
  ZOOM,
  FIRST_PERSON_ROTATION,
}

/**
 * Checks if the gesture events are for the rotation, orbit, pan, or zoom interactions.
 */
export class GestureHelper {
  private readonly _allowedActions: NavigationType[];
  // Describes the current navigation type that is currently being handled
  private _actionType: NavigationType = NavigationType.NONE;
  private _actionStarted = false;
  private _actionFinished = false;
  private _modifier: ModifierType = ModifierType.NO_MOD;

  constructor(allowedActions: NavigationType[]) {
    this._allowedActions = allowedActions;
  }

  isIdle(): boolean {
    return this._actionType === NavigationType.NONE;
  }
  isRotating(): boolean {
    return this._actionType === NavigationType.ROTATION;
  }
  isFirstPersonRotation(): boolean {
    return this._actionType === NavigationType.FIRST_PERSON_ROTATION;
  }
  isPanning(): boolean {
    return this._actionType === NavigationType.PAN;
  }
  isZooming(): boolean {
    return this._actionType === NavigationType.ZOOM;
  }

  isModifierCtrl(): boolean {
    return !!(this._modifier & ModifierType.CTRL);
  }
  isModifierShift(): boolean {
    return !!(this._modifier & ModifierType.SHIFT);
  }
  isModifierAlt(): boolean {
    return !!(this._modifier & ModifierType.ALT);
  }

  /**
   * Resets the navigation action (nullify any navigation)
   */
  public reset() {
    this._actionType = NavigationType.NONE;
    this._modifier = ModifierType.NO_MOD;
    this._actionStarted = false;
  }

  /**
   * Tells if the navigation action has just started.
   * The status is reset on each execution of 'onGestureEvent'.
   */
  get actionStarted(): boolean {
    return this._actionStarted;
  }

  /**
   * Tells if the navigation action has just finished.
   * The status is reset on each execution of 'onGestureEvent'.
   */
  get actionFinished(): boolean {
    return this._actionFinished;
  }

  private setModifier(domEvent: UIEvent) {
    let modifier = ModifierType.NO_MOD;
    if (domEvent instanceof MouseEvent || domEvent instanceof TouchEvent) {
      if (domEvent.altKey) {
        modifier += ModifierType.ALT;
      }
      if (domEvent.ctrlKey) {
        modifier += ModifierType.CTRL;
      }
      if (domEvent.shiftKey) {
        modifier += ModifierType.SHIFT;
      }
    }
    this._modifier = modifier;
  }

  /**
   * Checks the gesture events to identify the current navigation type.
   * Return true if gestures result with a navigation interaction or when the navigation stops.
   * The returning value is used to invalidate the navigation controller.
   */
  onGestureEvent({ type, domEvent }: GestureEvent): boolean {
    this._actionStarted = false;
    this._actionFinished = false;
    this.setModifier(domEvent);

    // Check if the zooming action is finished.
    if (
      this.isZooming() &&
      type !== GestureEventType.SCROLL &&
      type !== GestureEventType.PINCH
    ) {
      this.reset(); // we're not zooming anymore
      return true;
    }

    // Handle the rotation, orbit and pan actions
    if (
      type === GestureEventType.DRAG ||
      type === GestureEventType.TWO_FINGER_DRAG
    ) {
      if (
        this.shouldHandleAction(NavigationType.ROTATION) ||
        this.shouldHandleAction(NavigationType.PAN)
      ) {
        const stateBefore = this.actionType;

        if (domEvent instanceof MouseEvent) {
          const { buttons } = domEvent;
          this.actionType =
            buttons === 1 // left button pressed
              ? NavigationType.ROTATION
              : buttons === 2 // right button pressed
              ? NavigationType.PAN
              : buttons === 3 // left & right buttons pressed
              ? NavigationType.FIRST_PERSON_ROTATION
              : NavigationType.NONE;
        } else if (window.TouchEvent && domEvent instanceof TouchEvent) {
          const { touches } = domEvent;
          this.actionType =
            touches.length === 1
              ? NavigationType.ROTATION
              : touches.length === 2
              ? NavigationType.PAN
              : touches.length === 3
              ? NavigationType.FIRST_PERSON_ROTATION
              : NavigationType.NONE;
        } else {
          this.reset();
        }

        if (
          this.actionType !== NavigationType.NONE &&
          this.actionType !== stateBefore
        ) {
          this._actionStarted = true;
        }

        return true;
      }
      return false;
    }

    // Handle the zoom action
    if (type === GestureEventType.SCROLL || type === GestureEventType.PINCH) {
      if (this.shouldHandleAction(NavigationType.ZOOM)) {
        if (!this.isZooming()) {
          this.actionType = NavigationType.ZOOM;
          this._actionStarted = true;
        }
        return true;
      }
      return false;
    }

    // Check if the rotation, orbit or pan action is finished
    if (
      type === GestureEventType.DRAG_END ||
      type === GestureEventType.TWO_FINGER_DRAG_END
    ) {
      this.reset();
      this._actionFinished = true;
      return true;
    }

    return false;
  }

  /**
   * Checks if a key event is triggered on the map. Events on input targets are disregarded.
   * @param event
   */
  isKeyEventAccepted({ domEvent }: KeyEvent): boolean {
    const target = domEvent?.target;
    return !(
      target instanceof HTMLInputElement ||
      target instanceof HTMLTextAreaElement
    );
  }

  /**
   * Returns the zoom fraction. If the resulting zoom fraction value is greater than 0 then we're zooming in.
   * If the value is smaller than 0 then we're zooming out.
   */
  getZoomFraction(event: GestureEvent, mouseZoomMultiplier = 0.1): number {
    return event.type === GestureEventType.SCROLL
      ? (event as ScrollEvent).amount * mouseZoomMultiplier
      : event.type === GestureEventType.PINCH
      ? (event as PinchEvent).scaleFactor - 1
      : 0;
  }

  /**
   * Returns the speed multiplier factor.
   */
  getSpeedMultiplier(): number {
    if (this.isModifierShift()) {
      return 2; // faster zoom
    }
    if (this.isModifierAlt()) {
      return 0.5; // slower zoom
    }
    return 1;
  }

  get actionType(): NavigationType {
    return this._actionType;
  }

  private set actionType(type: NavigationType) {
    if (type !== NavigationType.NONE && this.shouldHandleAction(type)) {
      this._actionType = type;
    } else {
      this.reset();
    }
  }

  private shouldHandleAction(action: NavigationType): boolean {
    return this._allowedActions.indexOf(action) >= 0;
  }
}
