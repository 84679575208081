import { useEffect } from 'react'
import { ProcessingAsset } from '../../layers/assets/useProcessingAssets'
import { IProject } from '../context/AppContext'
import { useCurrentProject } from '../context/useCurrentProject'
import { useSnackbar } from 'notistack'
import React from 'react'
import Button from '@mui/material/Button'

export const useProjectLoad = () => {
  const { setHspc1, setHspc2 } = useCurrentProject()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { setManualLoad } = useCurrentProject()

  useEffect(() => {
    console.log(process.env.REDIRECT_URI)
    let timeoutid: any
    fetch(`${process.env.REDIRECT_URI}/resources/project_fail.json`)
      .then((res) => res.json())
      .then(
        (result) => {
          const newProject: IProject = {
            hspc1: {
              ...result.hspc1,
              state: 'READY',
              subStates: {
                POINT_CLOUD: 'READY',
                MESH: 'READY',
                PANORAMIC: 'READY',
              },
            },
            hspc2: {
              ...result.hspc2,
              state: 'READY',
              subStates: {
                POINT_CLOUD: 'READY',
                MESH: 'READY',
                PANORAMIC: 'READY',
              },
            },
          }
          // setCurrentProject(newProject);
          setHspc1(newProject.hspc1 as ProcessingAsset)
          // // TODO: remove timeout
          // timeoutid = setTimeout(() => {
          setHspc2(newProject.hspc2 as ProcessingAsset)
          // }, 1000)
        },
        (error) => {
          console.error('Error loading default project', error)
          const action = (snackbarId: string) => (
            <>
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  closeSnackbar(snackbarId)
                }}
              >
                Ok
              </Button>
            </>
          )
          enqueueSnackbar(
            'No default datasets, you can load two pointclouds from your HxDR account or from a URL (HSPC files only).',
            {
              persist: true,
              variant: 'warning',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              action,
              onClose: () => {
                setManualLoad(true)
              },
            }
          )
        }
      )
    return () => {
      if (timeoutid) clearTimeout(timeoutid)
    }
  }, [])
}
