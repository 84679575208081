import {distance, interpolateVectors} from '../../util/Vector3Util';
import {Map} from '@luciad/ria/view/Map';
import {Point} from '@luciad/ria/shape/Point';
import {AssetDimension} from './AssetDimension';

export class NavigationZoom {
  private readonly _minDistanceToSurface = 0.5;

  private readonly _assetDimension: AssetDimension;

  constructor(assetDimension: AssetDimension) {
    this._assetDimension = assetDimension;
  }

  /**
   * Performs zooming to (scaleFactor > 0) or from anchor (zoomFactor < 0).
   * @param map
   * @param anchor determines the zooming direction
   * @param fraction greater than 0 we're zooming to the anchor, otherwise we're zooming out.
   * @param ghostMode states if it is allowed to cross the anchor (surface)
   * @return true if the surface was crossed (in ghost mode), otherwise false.
   */
  zoomToAnchor(
    map: Map,
    anchor: Point,
    fraction: number,
    ghostMode: boolean
  ): boolean {
    // The scaling multiplier is used on the distance to anchor.
    // When > 0 zooming to the anchor, otherwise zooming out.

    const { eye } = map.camera;
    const distanceToAnchor = distance(anchor, eye);
    const newDistance = distanceToAnchor * (1 - fraction);
    let surfaceCrossed = false;

    if (fraction > 0 && newDistance < this._minDistanceToSurface) {
      if (ghostMode) {
        fraction = 2; // fly through the anchor (surface)
        surfaceCrossed = true;
      } else {
        // refuse to cross surface - move forward to the min distance
        fraction = 1 - this._minDistanceToSurface / distanceToAnchor;
      }
    }

    const newEye = interpolateVectors(eye, anchor, fraction);
    if (this._assetDimension.isInAssetWorld(newEye)) {
      map.camera = map.camera.copyAndSet({ eye: newEye });
    }
    return surfaceCrossed;
  }
}