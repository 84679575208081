import { OutOfBoundsError } from '@luciad/ria/error/OutOfBoundsError'
import { LocationMode } from '@luciad/ria/transformation/LocationMode'
import { Vector3 as IVector3 } from '@luciad/ria/util/Vector3'
import { Controller } from '@luciad/ria/view/controller/Controller'
import { HandleEventResult } from '@luciad/ria/view/controller/HandleEventResult'
import { GestureEvent } from '@luciad/ria/view/input/GestureEvent'
import { GestureEventType } from '@luciad/ria/view/input/GestureEventType'
import { PcToolSupport } from '../alignment/PcToolSupport'
import { is_touch_device } from '../util/device'
import { AnchorHelper } from './asset/AnchorHelper'
import { GestureHelper, NavigationType } from './asset/GestureHelper'
import { NavigationPan } from './asset/NavigationPan'

export class SubViewPanController extends Controller {
  private _anchor: IVector3 | null = null
  private _panningHelper: NavigationPan = new NavigationPan()

  private readonly _support: PcToolSupport
  private readonly _anchorHelper: AnchorHelper
  private readonly _gestureHelper: GestureHelper

  constructor(support: PcToolSupport, anchorHelper: AnchorHelper) {
    super()
    this._support = support
    this._anchorHelper = anchorHelper
    this._gestureHelper = new GestureHelper([
      NavigationType.PAN,
      NavigationType.ZOOM,
    ])
  }

  onGestureEvent(gestureEvent: GestureEvent): HandleEventResult {
    if (this._support.checkDisabled(null))
      return HandleEventResult.EVENT_IGNORED
    const viewPoint = gestureEvent.viewPoint
    const map = this.map!

    const canDrag =
      (gestureEvent.domEvent as MouseEvent).button === 0 || is_touch_device()
    const isDrag = [
      GestureEventType.DRAG,
      GestureEventType.TWO_FINGER_DRAG,
    ].includes(gestureEvent.type)

    if (isDrag && canDrag) {
      if (this._anchor === null) {
        try {
          const { actionType } = this._gestureHelper
          /* old behaviour was working on TOP view but not on the SIDE one */
          /* this._anchor = map
            .getViewToMapTransformation(LocationMode.CLOSEST_SURFACE)
            .transform(viewPoint) */
          this._anchorHelper.computeAnchor(viewPoint, actionType)
          this._anchor = this._anchorHelper.anchor
        } catch (e) {
          if (!(e instanceof OutOfBoundsError)) {
            throw e
          }
        }
        return HandleEventResult.EVENT_HANDLED
      } else {
        this._panningHelper.panCameraOverOrthogonalPlane(
          map,
          this._anchor,
          viewPoint
        )
      }
    }

    if (gestureEvent.type === GestureEventType.DRAG_END) {
      console.log('setting to null')
      this._anchor = null
      this._panningHelper.reset()
    }

    return HandleEventResult.EVENT_IGNORED
  }
}
