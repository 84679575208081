import {Controller} from '@luciad/ria/view/controller/Controller';
import {GestureEvent} from '@luciad/ria/view/input/GestureEvent';
import {HandleEventResult} from '@luciad/ria/view/controller/HandleEventResult';
import {GestureEventType} from '@luciad/ria/view/input/GestureEventType';
import {LayerAddress} from '../util/LayerAddressLoader';
import {Map} from '@luciad/ria/view/Map';
import {Layer} from '@luciad/ria/view/Layer';
import {ANNOTATION_LAYER_GROUP_ID} from '../layers/LayerUtil';
import {Point} from '@luciad/ria/shape/Point';
import {TileSet3DLayer} from '@luciad/ria/view/tileset/TileSet3DLayer';
import {LocationMode} from '@luciad/ria/transformation/LocationMode';
import {Bounds} from '@luciad/ria/shape/Bounds';
import {createPoint} from '@luciad/ria/shape/ShapeFactory';
import {getReference} from '@luciad/ria/reference/ReferenceProvider';
import {OutOfBoundsError} from '@luciad/ria/error/OutOfBoundsError';
import {distance} from '../common/util/Vector3Util';
import {EventedSupport} from '@luciad/ria/util/EventedSupport';
import {Handle} from '@luciad/ria/util/Evented';
import {Vector3} from '@luciad/ria/util/Vector3';
import {geolocateLayer, loadGeolocationAddress} from './GeolocationUtil';

/**
 * Event emitted when the user confirmed that the asset should be placed on the current location.
 */
export const GEOLOCATION_CONFIRMED_EVENT = 'GeolocationConfirmedEvent';

//smallest ratio of asset size to distance to asset for which it is allowed to place the asset
const PLACEMENT_TRESHOLD = 0.03;

const DEFAULT_GEOLOCATION = createPoint(
  getReference('CRS:84'),
  [4.6695063, 50.8650954, 10]
);

/**
 * Controller that can be used to geolocate layer addresses on the map.
 */
export class AssetPlaceController extends Controller {
  private readonly _eventedSupport: EventedSupport;
  private _layers: Layer[] = [];
  private _anchorPoint: Point | null = null;
  private _geolocation: Point | null = null;
  private _size: number | null = null; //used to find out whether a user is close enough to geolocate the layers.
  private _confirmed: boolean = false;

  constructor() {
    super();
    this._eventedSupport = new EventedSupport(
      [GEOLOCATION_CONFIRMED_EVENT],
      true
    );
  }

  onActivate(map: Map) {
    map.domNode.style.cursor = 'crosshair';
    this.loadLayers(map);
    this._confirmed = false;
    super.onActivate(map);
  }

  onDeactivate(map: Map): any {
    map.domNode.style.cursor = 'default';
    if (!this._confirmed) {
      this.clearLayers();
    }
    this._geolocation = null;
    return super.onDeactivate(map);
  }

  private loadLayers(map: Map) {
    for (const layer of this._layers) {
      map.layerTree
        .findLayerGroupById(ANNOTATION_LAYER_GROUP_ID)
        .addChild(layer);
    }
  }

  private clearLayers() {
    for (const layer of this._layers) {
      layer.parent?.removeChild(layer);
    }
  }

  /**
   * Loads the given addresses as layers that will get geolocated by this controller.
   * @param geolocationId the unique identifier for these geolocated layers
   * @param assetName the name that will be included in the label of the geolocated layers
   * @param addresses the addresses that this controller will create layers from to geolocate
   */
  loadAddresses(
    geolocationId: string,
    assetName: string,
    addresses: LayerAddress[]
  ) {
    const promises: Promise<Layer>[] = [];
    for (const address of addresses) {
      if (address.type === 'PANORAMIC') {
        console.log(
          'Ignoring panoramic address, support for these addresses will be added later'
        );
        continue;
      }
      promises.push(loadGeolocationAddress(geolocationId, assetName, address));
    }
    Promise.all(promises).then((layers) => {
      for (const layer of layers) {
        geolocateLayer(layer, this._geolocation ?? DEFAULT_GEOLOCATION);
      }

      if (this.map) {
        this.clearLayers();
      }

      this._layers = layers;
      this.updateAnchorAndSize();

      if (this.map) {
        this.loadLayers(this.map);
      }
    });
  }

  /**
   * Updates the anchor point and size of the layers.
   * This should be called when the layers of this controller have been modified.
   */
  private updateAnchorAndSize() {
    let maxBounds: Bounds | null = null;
    for (const layer of this._layers) {
      if (layer instanceof TileSet3DLayer) {
        const bounds = layer.model.bounds;
        if (
          !maxBounds ||
          bounds.width * bounds.height > maxBounds.width * maxBounds.height
        ) {
          maxBounds = bounds;
        }
      }
    }
    if (!maxBounds) {
      throw new Error(
        'Could not update anchor point based on the current layers'
      );
    } else {
      this._anchorPoint = createPoint(getReference('LUCIAD:XYZ'), [
        maxBounds.x + maxBounds.width / 2,
        maxBounds.y + maxBounds.height / 2,
        maxBounds.z,
      ]);
      this._size = Math.max(maxBounds.width, maxBounds.height, maxBounds.depth);
    }
  }

  onGestureEvent(gestureEvent: GestureEvent): HandleEventResult {
    if (gestureEvent.type === GestureEventType.MOVE) {
      return this.handleMove(gestureEvent);
    } else if (gestureEvent.type === GestureEventType.SINGLE_CLICK_UP) {
      return this.handleClick();
    }
    return HandleEventResult.EVENT_IGNORED;
  }

  private handleMove(gestureEvent: GestureEvent): HandleEventResult {
    if (this._anchorPoint) {
      try {
        //LocationMode.TERRAIN works since the 3D layers on the map have been added as part of terrain.
        this._geolocation = this.map!.getViewToMapTransformation(
          LocationMode.TERRAIN
        ).transform(gestureEvent.viewPoint);
        for (const layer of this._layers) {
          geolocateLayer(layer, this._geolocation, this._anchorPoint);
        }
      } catch (e) {
        if (e instanceof OutOfBoundsError) {
          return HandleEventResult.EVENT_IGNORED;
        } else {
          throw e;
        }
      }
    }
    return HandleEventResult.EVENT_IGNORED;
  }

  private handleClick(): HandleEventResult {
    if (!this._anchorPoint) {
      //TODO: use notifications
      console.warn(
        'Can not place an asset before any of its layers are loaded'
      );
    } else if (
      !this._geolocation ||
      this._size! / distance(this.map!.camera.eye, this._geolocation) <
        PLACEMENT_TRESHOLD
    ) {
      //TODO: use notifications
      console.warn('Can not place this asset that far, try zooming in more');
    } else {
      this._confirmed = true;
      this._eventedSupport.emit(
        GEOLOCATION_CONFIRMED_EVENT,
        this._layers,
        this._geolocation,
        this._anchorPoint
      );
      return HandleEventResult.EVENT_HANDLED;
    }
    return HandleEventResult.EVENT_IGNORED;
  }

  on(
    event:
      | 'Activated'
      | 'Deactivated'
      | 'Invalidated'
      | typeof GEOLOCATION_CONFIRMED_EVENT,
    callback:
      | ((map: Map) => void)
      | ((
          geolocatedLayers: Layer[],
          geolocation: Point,
          anchor: Vector3
        ) => void)
  ): Handle {
    if (event === GEOLOCATION_CONFIRMED_EVENT) {
      return this._eventedSupport.on(event, callback);
    } else if (event === 'Invalidated') {
      return super.on(event, callback as () => void);
    } else if (event === 'Activated') {
      return super.on(event, callback as (map: Map) => void);
    }
    return super.on(event, callback as (map: Map) => void);
  }
}
