import { Map } from '@luciad/ria/view/Map'
import { LayerTreeNode } from '@luciad/ria/view/LayerTreeNode'
import { useLayerVisible } from '../common/hooks/useLayerVisible'
import { useLayerLabel } from '../common/hooks/useLayerLabel'
import { getFitBounds } from '../common/util/FitBoundsUtil'
import React, { useState } from 'react'
import { Box, IconButton, Inline, Stack, Text, Theme } from '@digitalreality/ui'
import { Draggable, DraggedOverPart } from '../common/components/Draggable'
import { LayerTreeNodeType } from '@luciad/ria/view/LayerTreeNodeType'
import { LayerGroup } from '@luciad/ria/view/LayerGroup'
import {
  LocateIcon,
  ViewFilledIcon,
  ViewOffFilledIcon,
} from '@digitalreality/ui-icons'

function border(theme: Theme) {
  return `solid 1px ${theme.palette.secondary.main}`
}

interface Props {
  map: Map
  topMap: Map
  sideMap: Map
  node: LayerTreeNode
}

/**
 * Component that shows the given node (and its children) and allows users
 * to toggle the node's visibility and to move it and its children.
 */
export const LayerTreeNodeControl = ({ map, topMap, sideMap, node }: Props) => {
  const [draggedOverPart, setDraggedOverPart] =
    useState<DraggedOverPart>('NONE')
  const visible = useLayerVisible(node)
  const label = useLayerLabel(node)
  const maps = [map, topMap, sideMap]

  async function fit() {
    const bounds = await getFitBounds(node)
    if (bounds) {
      await maps.forEach((view) =>
        view.mapNavigator.fit({ bounds, animate: true })
      )
    }
  }

  const handleDrop = (droppedLayerTreeNodeId: string) => {
    const nodeToMove = map.layerTree.findLayerTreeNodeById(
      droppedLayerTreeNodeId
    )
    try {
      if (
        draggedOverPart === 'BOTTOM' &&
        node.treeNodeType === LayerTreeNodeType.LAYER_GROUP
      ) {
        ;(node as LayerGroup).moveChild(nodeToMove, 'top')
      } else {
        node.parent?.moveChild(
          nodeToMove,
          draggedOverPart === 'TOP' ? 'above' : 'below',
          node
        )
      }
    } catch (e) {
      console.error(e)
    }
  }

  const handleNodeVisibility = () => {
    maps.forEach(
      (view) => (view.layerTree.findLayerById(node.id).visible = !visible)
    )
  }

  return (
    /*  <Draggable
      style={{width: '100%'}}
      id={node.id}
      onDragOverPartChange={setDraggedOverPart}
      onDrop={handleDrop}
    > */
    <Stack
      width="calc(100% - 20px)"
      position="relative"
      sx={(theme) => ({
        borderTop: draggedOverPart === 'TOP' ? border(theme) : undefined,
        borderBottom: draggedOverPart === 'BOTTOM' ? border(theme) : undefined,
      })}
    >
      <Inline
        position="relative"
        width="100%"
        height="20px"
        alignItems="center"
      >
        <Text
          noWrap
          width="calc(100% - 75px)"
          paddingTop="4px"
          paddingBottom="4px"
          size={0}
        >
          {label}
        </Text>
        <Inline
          position="absolute"
          right="0"
          top="50%"
          sx={{ transform: 'translate(0, -50%)' }}
        >
          <IconButton size="small" onClick={fit}>
            <LocateIcon fontSize="small" />
          </IconButton>
          <IconButton size="small" onClick={handleNodeVisibility}>
            {visible ? (
              <ViewFilledIcon fontSize="small" />
            ) : (
              <ViewOffFilledIcon fontSize="small" />
            )}
          </IconButton>
        </Inline>
      </Inline>
      <Box marginLeft="20px" width="100%">
        {node.children.reverse().map((child) => (
          <LayerTreeNodeControl
            map={map}
            topMap={topMap}
            sideMap={sideMap}
            key={child.id}
            node={child}
          />
        ))}
      </Box>
    </Stack>
    /* </Draggable> */
  )
}
