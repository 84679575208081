import {useEffect, useState} from 'react';
import {
  GetMyAssetsProjectDocument,
  GetProjectsDocument,
  TGetMyAssetsProjectQuery,
  TGetProjectsQuery,
  TGetProjectsQueryVariables,
  TProjectOrderEnum,
} from '../../graphql/gen/graphql';
import {getApolloClient} from '../../util/PersistanceUtil';
import {getResolvedBearerToken} from '../../authentication/AuthenticationUtil';

export interface ProjectInfo {
  readonly name: string;
  readonly id: string;
  readonly rootFolder: {
    readonly id: string;
    readonly name: string;
  };
}

/**
 * React hook that returns the projects of the current users with the given pagination.
 * We treat the "my assets project" project of a user as the last project instead of the first,
 * as there is currently no pageOffset parameter in the getProjects query.
 */
export function useProjects(pageNumber: number, pageSize: number) {
  const [normalProjects, setNormalProjects] = useState<readonly ProjectInfo[]>(
    []
  );
  const [myAssetsProject, setMyAssetsProject] = useState<ProjectInfo>();
  const [projects, setProjects] = useState<readonly ProjectInfo[]>([]);
  const [fetchedNormalProjects, setFetchedNormalProjects] = useState(false);
  const [fetchedMyAssetProject, setfetchedMyAssetProject] = useState(false);
  const [pagesAmount, setPagesAmount] = useState<number>(1);
  const [errors, setErrors] = useState<readonly Error[]>();

  const client = getApolloClient(getResolvedBearerToken());

  useEffect(() => {
    client
      .query<TGetMyAssetsProjectQuery>({query: GetMyAssetsProjectDocument})
      .then(({data, errors}) => {
        if (errors) {
          setErrors(errors);
        } else if (data.myAssetsProject?.__typename !== 'ProjectOutput') {
          setErrors([
            new Error(
              'Could not get your default project: ' +
                data.myAssetsProject!.message
            ),
          ]);
        } else {
          setMyAssetsProject(data.myAssetsProject);
          setfetchedMyAssetProject(true);
        }
      });
  }, []);

  useEffect(() => {
    setFetchedNormalProjects(false);
    setNormalProjects([]);
    client
      .query<TGetProjectsQuery, TGetProjectsQueryVariables>({
        query: GetProjectsDocument,
        variables: {
          orderBy: TProjectOrderEnum.CREATED_AT_ASC,
          pageSize,
          pageNumber,
        },
        fetchPolicy: 'no-cache',
      })
      .then(({data, errors}) => {
        if (errors) {
          setErrors(errors);
        } else if (data.projects?.__typename !== 'ProjectResultOutput') {
          setErrors([
            new Error('Could not get your projects: ' + data.projects!.message),
          ]);
        } else {
          setPagesAmount(
            Math.max(Math.ceil((data.projects.total + 1) / pageSize), 1)
          );
          setNormalProjects(data.projects.contents as ProjectInfo[]);
          setFetchedNormalProjects(true);
        }
      });
  }, [pageNumber, pageSize]);

  useEffect(() => {
    if (pageNumber === pagesAmount - 1 && myAssetsProject) {
      setProjects([...normalProjects, myAssetsProject]);
    } else {
      setProjects(normalProjects);
    }
  }, [normalProjects, myAssetsProject, pagesAmount, pageNumber]);

  return {
    projects,
    busy: !fetchedMyAssetProject || !fetchedNormalProjects,
    pagesAmount: pagesAmount,
    errors,
  };
}
