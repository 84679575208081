import {KMLModel} from "@luciad/ria/model/kml/KMLModel";
import {KMLLayer} from "@luciad/ria/view/kml/KMLLayer";
import {LayerGroup} from "@luciad/ria/view/LayerGroup";
import {manageKMLLayer} from "./KMLLayerManager";

export const KMLDataLoader = {
  /**
   * Creates a layer for the given URL, assuming it is KML content.
   *
   * Summary:
   * - Create a @luciad/ria/model/kml/KMLModel to load the data
   * - Create a @luciad/ria/view/kml/KMLLayer
   */
  createLayer: function (layerName: string, url: string): LayerGroup {
    const layerGroup = new LayerGroup({
      label: layerName + " (KML)"
    });
    const layer = new KMLLayer(new KMLModel(url), {
      label: layerName + " (KML)",
      selectable: true
    });
    layerGroup.addChild(layer);
    manageKMLLayer(layer);
    return layerGroup;
  }
};