import {Icon3DStyle} from '@luciad/ria/view/style/Icon3DStyle';
import {Map} from '@luciad/ria/view/Map';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {DEG2RAD} from '../../common/util/Math';
import {Point} from '@luciad/ria/shape/Point';
import {distanceAlongDirection} from '../../util/Vector3Util';

/**
 * The gizmo 3D object should be defined with the origin that is in the center of the icon.
 * The real size represents the perimeter (twice the radius) of the sphere that encircles the gizmo object.
 */
export interface GizmoOptions {
  // Expected size of the gizmo visualization in pixels. Default value is 90 pixels
  sizeInPixels?: number;

  // The real size of the gizmo 3D icon in meters. Default value is 2 meters.
  realSize?: number;
}

export class NavigationGizmo {
  private readonly _style: Icon3DStyle;
  private readonly _gizmoSizeInPixels: number;
  private readonly _realGizmoSize: number; // meters

  constructor(meshUrl: string, { sizeInPixels, realSize }: GizmoOptions = {}) {
    this._style = getGizmoStyle(meshUrl);
    this._gizmoSizeInPixels = sizeInPixels ?? 90;
    this._realGizmoSize = realSize ?? 2;
  }

  get style(): Icon3DStyle {
    return this._style;
  }

  rescaleForFixedViewSize(map: Map, gizmoCenter: Point) {
    const { camera, viewSize } = map;

    const orthogonalDistance = distanceAlongDirection(
      gizmoCenter,
      camera.eye,
      camera.forward
    );

    const sizeInMeters = computeSizeInMeters(
      this._gizmoSizeInPixels,
      (camera as PerspectiveCamera).fovY,
      viewSize[1],
      orthogonalDistance
    );
    this.applyScaleFactor(sizeInMeters / this._realGizmoSize);
  }

  applyScaleFactor(scaleFactor: number) {
    this._style.scale = {
      x: scaleFactor,
      y: scaleFactor,
      z: scaleFactor,
    };
  }
}

/**
 * Returns the size in meters that a circular object has at the center of the
 * screen with given size in pixels at the given distance.
 */
function computeSizeInMeters(
  sizeInPixels: number,
  fovY: number,
  viewHeight: number,
  distance: number
): number {
  const halfAngle = (sizeInPixels / 2 / viewHeight) * fovY;
  const halfSize = Math.tan(halfAngle * DEG2RAD) * distance;
  return halfSize * 2;
}

function getGizmoStyle(meshUrl: string): Icon3DStyle {
  return {
    meshUrl,
    rotation: {
      x: 0,
      y: 0,
      z: -90,
    },
    orientation: {
      roll: 0,
    },
    translation: {
      x: 0,
      y: 0,
      z: 0,
    },
    scale: {
      x: 1,
      y: 1,
      z: 1,
    },
    transparency: true,
    pbrSettings: {
      lightIntensity: 0.5,
      material: {
        metallicFactor: 1,
        roughnessFactor: 0.5,
      },
    },
  };
}
