import {easeInOutCubic} from '../../common/util/Easing';
import {bezier} from './BezierEasing';

export type EasingFunction = (f: number) => number;

export class MoveSpeedSupport {
  private readonly _totalDistance: number;
  private readonly _totalTime: number;

  private _currentSpeed: number;
  private _lastTimeFraction: number;
  private _lastDistanceFraction: number;

  constructor(totalDistance: number, totalTime: number) {
    this._totalDistance = totalDistance; // m
    this._totalTime = totalTime / 1000; // ms => s
    this._currentSpeed = 0;
    this._lastTimeFraction = 0;
    this._lastDistanceFraction = 0;
  }

  update(timeFraction: number, distanceFraction: number): void {
    const timeDelta = (timeFraction - this._lastTimeFraction) * this._totalTime;
    const distanceDelta =
      (distanceFraction - this._lastDistanceFraction) * this._totalDistance;
    this._currentSpeed = timeDelta
      ? distanceDelta / timeDelta
      : this._currentSpeed;
    this._lastTimeFraction = timeFraction;
    this._lastDistanceFraction = distanceFraction;
  }

  getSpeed(): number {
    return this._currentSpeed;
  }

  createVelocityEasing(startSpeed: number): EasingFunction {
    if (startSpeed > 0) {
      const dTime = 0.1;
      const dDistance =
        (dTime * startSpeed * this._totalTime) / this._totalDistance;
      return bezier(dTime, dDistance, 0.8, 1);
    }
    return easeInOutCubic;
  }

  stop() {
    this._currentSpeed = 0;
  }
}

// Other utilities associated with the navigation speed

export enum SpeedChange {
  NORMAL,
  FASTER,
  SLOWER,
}

export function computeSpeedChange(
  isFaster: boolean,
  isSlower: boolean
): SpeedChange {
  if (isFaster && !isSlower) {
    return SpeedChange.FASTER;
  } else if (isSlower && !isFaster) {
    return SpeedChange.SLOWER;
  }
  return SpeedChange.NORMAL;
}
