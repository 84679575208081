
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AccountDetailUnion": [
      "AccountDetailOutput",
      "AcsErrorOperationNotAllowedOutput"
    ],
    "AccountInfoUpdateUnion": [
      "AccountInfoAccountAddressIsNotValidOutput",
      "AccountInfoAccountCompanyNameIsNotValidOutput",
      "AccountInfoAccountEmailIsNotValidOutput",
      "AccountInfoAccountLegalEntityCouldNotBeChangedOutput",
      "AccountInfoErrorOperationNotAllowedOutput",
      "AccountInfoOutput"
    ],
    "AccountMembersResultUnion": [
      "AccountMembersResultOutput",
      "AcsErrorOperationNotAllowedOutput"
    ],
    "AddFileUnion": [
      "AssetErrorNameBlacklistedCharactersOutput",
      "AssetErrorNameBlankOutput",
      "AssetErrorNameForbiddenOutput",
      "AssetErrorNameLeadingOrTrailingSpaceOutput",
      "AssetErrorNameTrailingDotOutput",
      "AssetErrorNameTwoDotsInRowOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorStorageLimitReachedOutput",
      "FileOutput"
    ],
    "AddLabelsUnion": [
      "LabelErrorOperationNotAllowedOutput",
      "LabelErrorTooManyLabelsOutput",
      "LabelsOutput"
    ],
    "AddressOutput": [
      "AddressAssetFileDownloadOutput",
      "AddressDownloadOutput",
      "AddressHspcOutput",
      "AddressLtsOutput",
      "AddressOgc3DOutput",
      "AddressPanoramicOutput",
      "AddressWfsOutput",
      "AddressWmsOutput",
      "AddressWmtsOutput"
    ],
    "AnnotationThumbnailUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "AnnotationThumbnailOutput"
    ],
    "ApplicationTemplateUnion": [
      "ApplicationErrorOperationNotAllowedOutput",
      "ApplicationErrorResourceAccessDeniedOutput",
      "ApplicationErrorTooManyRequestsOutput",
      "ApplicationTemplateOutput"
    ],
    "ApplicationTemplatesUnion": [
      "ApplicationErrorOperationNotAllowedOutput",
      "ApplicationErrorTooManyRequestsOutput",
      "ApplicationTemplateResultOutput"
    ],
    "AssetUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "AssetsInAccountUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "GroupedAssetResultOutput"
    ],
    "AssetsInFolderUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "FolderOutput"
    ],
    "AutoRegisterAssetsUnion": [
      "AssetErrorCoarseRegistrationInputNotFound",
      "AssetErrorCoarseRegistrationLimitOutput",
      "AssetErrorDuplicateNameOutput",
      "AssetErrorFeatureNotAvailableOutput",
      "AssetErrorGroupedAssetRegistrationLimitOutput",
      "AssetErrorGroupedAssetsNotUnderSameFolderOutput",
      "AssetErrorNameBlacklistedCharactersOutput",
      "AssetErrorNameBlankOutput",
      "AssetErrorNameForbiddenOutput",
      "AssetErrorNameForwardSlashOutput",
      "AssetErrorNameLeadingOrTrailingSpaceOutput",
      "AssetErrorNameTrailingDotOutput",
      "AssetErrorNameTwoDotsInRowOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorProcessingLimitReachedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorStorageLimitReachedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "BaseAccountInfoErrorInterface": [
      "AccountInfoAccountAddressIsNotValidOutput",
      "AccountInfoAccountCompanyNameIsNotValidOutput",
      "AccountInfoAccountEmailIsNotValidOutput",
      "AccountInfoAccountLegalEntityCouldNotBeChangedOutput",
      "AccountInfoErrorOperationNotAllowedOutput"
    ],
    "BaseAcsErrorInterface": [
      "AcsErrorEntityNotFoundOutput",
      "AcsErrorInvalidInvitationSignatureOutput",
      "AcsErrorInvalidVoucherOutput",
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorOperationNotPossibleOutput",
      "AcsErrorProjectNotFoundOutput",
      "AcsErrorSeatLimitReachedOutput",
      "AcsErrorTenantNotFoundOutput",
      "AcsErrorTooManyRequestsOutput",
      "AcsErrorUserAlreadyExistsInAccountOutput",
      "AcsErrorUserAlreadyExistsInOtherAccountOutput",
      "AcsErrorUserAlreadyExistsOutput",
      "AcsErrorUserNotFoundOutput",
      "AcsErrorVoucherAlreadyUsedOutput"
    ],
    "BaseAnnotationErrorInterface": [
      "AnnotationErrorOperationNotAllowedOutput"
    ],
    "BaseApplicationErrorInterface": [
      "ApplicationErrorInvalidArgumentOutput",
      "ApplicationErrorOperationNotAllowedOutput",
      "ApplicationErrorResourceAccessDeniedOutput",
      "ApplicationErrorTooManyRequestsOutput"
    ],
    "BaseAssetErrorInterface": [
      "AssetErrorCoarseRegistrationInputNotFound",
      "AssetErrorCoarseRegistrationLimitOutput",
      "AssetErrorDownloadLimitReachedOutput",
      "AssetErrorDuplicateNameOutput",
      "AssetErrorFeatureNotAvailableOutput",
      "AssetErrorGroupedAssetRegistrationLimitOutput",
      "AssetErrorGroupedAssetsNotUnderSameFolderOutput",
      "AssetErrorNameBlacklistedCharactersOutput",
      "AssetErrorNameBlankOutput",
      "AssetErrorNameForbiddenOutput",
      "AssetErrorNameForwardSlashOutput",
      "AssetErrorNameLeadingOrTrailingSpaceOutput",
      "AssetErrorNameTrailingDotOutput",
      "AssetErrorNameTwoDotsInRowOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorProcessingLimitReachedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorStorageLimitReachedOutput",
      "AssetErrorTooManyRequestsOutput"
    ],
    "BaseFolderErrorInterface": [
      "FolderErrorDuplicateNameOutput",
      "FolderErrorNameBlacklistedCharactersOutput",
      "FolderErrorNameBlankOutput",
      "FolderErrorNameForbiddenOutput",
      "FolderErrorNameForwardSlashOutput",
      "FolderErrorNameLeadingOrTrailingSpaceOutput",
      "FolderErrorNameTrailingDotOutput",
      "FolderErrorNameTwoDotsInRowOutput",
      "FolderErrorOperationNotAllowedOutput",
      "FolderErrorResourceAccessDeniedOutput",
      "FolderErrorResourceNotFoundOutput",
      "FolderErrorTooManyRequestsOutput"
    ],
    "BaseLabelErrorInterface": [
      "LabelErrorOperationNotAllowedOutput",
      "LabelErrorTooManyLabelsOutput"
    ],
    "BaseLicenseErrorInterface": [
      "LicenseErrorInvalidArgumentOutput",
      "LicenseErrorLicenseNotAvailableOutput",
      "LicenseErrorOperationNotAllowedOutput",
      "LicenseErrorTooManyRequestsOutput"
    ],
    "BaseProcessingErrorInterface": [
      "ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput",
      "ProcessingErrorInternalServerErrorOutput",
      "ProcessingErrorInvalidInputForProcessingPaymentErrorOutput",
      "ProcessingErrorOperationNotAllowedOutput",
      "ProcessingErrorProcessingLimitReachedOutput",
      "ProcessingErrorResourceAccessDeniedOutput",
      "ProcessingErrorResourceNotFoundOutput",
      "ProcessingErrorStorageLimitReachedOutput"
    ],
    "BaseProjectErrorInterface": [
      "ProjectErrorDuplicateNameOutput",
      "ProjectErrorInvalidInputOutput",
      "ProjectErrorNameBlacklistedCharactersOutput",
      "ProjectErrorNameBlankOutput",
      "ProjectErrorNameForbiddenOutput",
      "ProjectErrorNameForwardSlashOutput",
      "ProjectErrorNameLeadingOrTrailingSpaceOutput",
      "ProjectErrorNameTrailingDotOutput",
      "ProjectErrorNameTwoDotsInRowOutput",
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedForTargetUserOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput"
    ],
    "BaseQuotationErrorInterface": [
      "QuotationErrorProductNotFoundOutput"
    ],
    "BaseShopErrorInterface": [
      "ShopErrorBackendFailedOutput",
      "ShopErrorNoOverlapWithProductOutput",
      "ShopErrorOperationNotAllowedOutput",
      "ShopErrorProductNotFoundOutput",
      "ShopErrorProductPricingNotFoundOutput"
    ],
    "BaseSubscriptionErrorInterface": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorBadRequestOutput",
      "SubscriptionErrorDuplicateWithSameAddonOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput"
    ],
    "BaselayerOutput": [
      "BaselayerHspcOutput",
      "BaselayerLtsOutput",
      "BaselayerOgc3DOutput",
      "BaselayerPanoramicOutput",
      "BaselayerUrlOutput",
      "BaselayerWfsOutput",
      "BaselayerWmsOutput",
      "BaselayerWmtsOutput"
    ],
    "CancelCurrentSubscriptionUnion": [
      "CancelCurrentSubscriptionOutput",
      "SubscriptionErrorFeatureNotAvailableOutput"
    ],
    "ChangeProjectRoleUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorOperationNotPossibleOutput",
      "AcsErrorSeatLimitReachedOutput",
      "ChangeProjectRoleOutput"
    ],
    "CommentPayload": [
      "AccessDeniedError",
      "Comment",
      "NotFoundError",
      "ServerError",
      "UnauthenticatedError",
      "ValidationError"
    ],
    "CommentsPayload": [
      "AccessDeniedError",
      "CommentConnection",
      "ServerError",
      "UnauthenticatedError",
      "ValidationError"
    ],
    "ConfirmSubscriptionUnion": [
      "ConfirmSubscriptionOutput"
    ],
    "Connection": [
      "CommentConnection"
    ],
    "CopyAssetToFolderUnion": [
      "AssetErrorDuplicateNameOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorStorageLimitReachedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "CoreBaselayer": [
      "BaselayerHspcOutput",
      "BaselayerLtsOutput",
      "BaselayerOgc3DOutput",
      "BaselayerPanoramicOutput",
      "BaselayerUrlOutput",
      "BaselayerWfsOutput",
      "BaselayerWmsOutput",
      "BaselayerWmtsOutput"
    ],
    "CoreLayer": [
      "LayerAddressHspc",
      "LayerAddressLts",
      "LayerAddressOgc3DTiles",
      "LayerAddressWms",
      "LayerAddressWmts"
    ],
    "CreateApplicationTemplateUnion": [
      "ApplicationErrorInvalidArgumentOutput",
      "ApplicationErrorOperationNotAllowedOutput",
      "ApplicationErrorTooManyRequestsOutput",
      "ApplicationTemplateOutput"
    ],
    "CreateAssetThumbnailUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorTooManyRequestsOutput",
      "AssetThumbnailOutput"
    ],
    "CreateAssetUnion": [
      "AssetErrorDuplicateNameOutput",
      "AssetErrorNameBlacklistedCharactersOutput",
      "AssetErrorNameBlankOutput",
      "AssetErrorNameForbiddenOutput",
      "AssetErrorNameForwardSlashOutput",
      "AssetErrorNameLeadingOrTrailingSpaceOutput",
      "AssetErrorNameTrailingDotOutput",
      "AssetErrorNameTwoDotsInRowOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "CreateFolderUnion": [
      "FolderErrorDuplicateNameOutput",
      "FolderErrorNameBlacklistedCharactersOutput",
      "FolderErrorNameBlankOutput",
      "FolderErrorNameForbiddenOutput",
      "FolderErrorNameForwardSlashOutput",
      "FolderErrorNameLeadingOrTrailingSpaceOutput",
      "FolderErrorNameTrailingDotOutput",
      "FolderErrorNameTwoDotsInRowOutput",
      "FolderErrorOperationNotAllowedOutput",
      "FolderErrorResourceAccessDeniedOutput",
      "FolderErrorTooManyRequestsOutput",
      "FolderOutput"
    ],
    "CreateLabelAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "LabelAnnotationOutput"
    ],
    "CreateLimitingBoxAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "LimitingBoxAnnotationOutput"
    ],
    "CreateMeasurementAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "MeasurementAnnotationOutput"
    ],
    "CreateProjectUnion": [
      "ProjectErrorDuplicateNameOutput",
      "ProjectErrorInvalidInputOutput",
      "ProjectErrorNameBlacklistedCharactersOutput",
      "ProjectErrorNameBlankOutput",
      "ProjectErrorNameForbiddenOutput",
      "ProjectErrorNameForwardSlashOutput",
      "ProjectErrorNameLeadingOrTrailingSpaceOutput",
      "ProjectErrorNameTrailingDotOutput",
      "ProjectErrorNameTwoDotsInRowOutput",
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "ProjectOutput"
    ],
    "DeleteAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "DeleteAnnotationOutput"
    ],
    "DeleteApplicationTemplateUnion": [
      "ApplicationErrorOperationNotAllowedOutput",
      "ApplicationErrorResourceAccessDeniedOutput",
      "ApplicationErrorTooManyRequestsOutput",
      "DeleteApplicationTemplateOutput"
    ],
    "DeleteAssetThumbnailUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorTooManyRequestsOutput",
      "DeleteAssetThumbnailOutput"
    ],
    "DeleteAssetUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorTooManyRequestsOutput",
      "DeleteAssetOutput"
    ],
    "DeleteFolderUnion": [
      "DeleteFolderOutput",
      "FolderErrorOperationNotAllowedOutput",
      "FolderErrorResourceAccessDeniedOutput",
      "FolderErrorResourceNotFoundOutput",
      "FolderErrorTooManyRequestsOutput"
    ],
    "DeleteProjectThumbnailUnion": [
      "DeleteProjectThumbnailOutput",
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput"
    ],
    "DeleteProjectUnion": [
      "DeleteProjectOutput",
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput"
    ],
    "Edge": [
      "CommentEdge"
    ],
    "Error": [
      "AccessDeniedError",
      "NotFoundError",
      "ServerError",
      "UnauthenticatedError",
      "ValidationError"
    ],
    "ExternalAccountMembersResultUnion": [
      "AccountMembersResultOutput",
      "AcsErrorOperationNotAllowedOutput"
    ],
    "FolderUnion": [
      "FolderErrorOperationNotAllowedOutput",
      "FolderErrorResourceAccessDeniedOutput",
      "FolderErrorResourceNotFoundOutput",
      "FolderErrorTooManyRequestsOutput",
      "FolderOutput"
    ],
    "GenerateInvitationUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorSeatLimitReachedOutput",
      "AcsErrorUserAlreadyExistsInAccountOutput",
      "AcsErrorUserAlreadyExistsInOtherAccountOutput",
      "InvitationOutput"
    ],
    "GenerateMultiAssetDownloadLinkUnion": [
      "AssetErrorDownloadLimitReachedOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "MultiAssetDownloadLinkOutput"
    ],
    "HXDRProductOutput": [
      "PointCloudSourceLidarOutput",
      "Product2DAerialOutput"
    ],
    "LayerAddress": [
      "LayerAddressHspc",
      "LayerAddressLts",
      "LayerAddressOgc3DTiles",
      "LayerAddressWms",
      "LayerAddressWmts"
    ],
    "LuciadRIALicenseUnion": [
      "LicenseErrorInvalidArgumentOutput",
      "LicenseErrorLicenseNotAvailableOutput",
      "LicenseErrorOperationNotAllowedOutput",
      "LuciadRIALicenseOutput"
    ],
    "MoveAssetToFolderUnion": [
      "AssetErrorDuplicateNameOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorStorageLimitReachedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "MultipartUploadUrlUnion": [
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorStorageLimitReachedOutput",
      "MultipartUploadUrlOutput"
    ],
    "MyAssetsProjectUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "ProjectOutput"
    ],
    "Node": [
      "Comment",
      "ProjectChangeFolderCopyOperation",
      "ProjectChangeFolderCreateOperation",
      "ProjectChangeFolderDeleteOperation",
      "ProjectChangeFolderMoveOperation",
      "ProjectChangeFolderUpdateOperation",
      "ProjectChangeGroupedAssetCopyOperation",
      "ProjectChangeGroupedAssetCreateOperation",
      "ProjectChangeGroupedAssetDeleteOperation",
      "ProjectChangeGroupedAssetMoveOperation",
      "ProjectChangeGroupedAssetUpdateOperation",
      "ProjectChangeOutput",
      "ProjectChangeProjectUpdateOperation",
      "ProjectChangeUserAccessGrantedOperation",
      "ProjectChangeUserAccessRevokedOperation"
    ],
    "PersistAssetUnion": [
      "AssetErrorDuplicateNameOutput",
      "AssetErrorNameBlacklistedCharactersOutput",
      "AssetErrorNameBlankOutput",
      "AssetErrorNameForbiddenOutput",
      "AssetErrorNameForwardSlashOutput",
      "AssetErrorNameLeadingOrTrailingSpaceOutput",
      "AssetErrorNameTrailingDotOutput",
      "AssetErrorNameTwoDotsInRowOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "ProductOutput": [
      "PointCloudSourceLidarOutput",
      "Product2DAerialOutput"
    ],
    "ProductUnion": [
      "QuotationErrorProductNotFoundOutput",
      "QuotationProductOutput"
    ],
    "ProjectChangeCopyOperation": [
      "ProjectChangeFolderCopyOperation",
      "ProjectChangeGroupedAssetCopyOperation"
    ],
    "ProjectChangeCreateOperation": [
      "ProjectChangeFolderCreateOperation",
      "ProjectChangeGroupedAssetCreateOperation"
    ],
    "ProjectChangeDeleteOperation": [
      "ProjectChangeFolderDeleteOperation",
      "ProjectChangeGroupedAssetDeleteOperation"
    ],
    "ProjectChangeFolderOperation": [
      "ProjectChangeFolderCopyOperation",
      "ProjectChangeFolderCreateOperation",
      "ProjectChangeFolderDeleteOperation",
      "ProjectChangeFolderMoveOperation",
      "ProjectChangeFolderUpdateOperation"
    ],
    "ProjectChangeGroupedAssetOperation": [
      "ProjectChangeGroupedAssetCopyOperation",
      "ProjectChangeGroupedAssetCreateOperation",
      "ProjectChangeGroupedAssetDeleteOperation",
      "ProjectChangeGroupedAssetMoveOperation",
      "ProjectChangeGroupedAssetUpdateOperation"
    ],
    "ProjectChangeMoveOperation": [
      "ProjectChangeFolderMoveOperation",
      "ProjectChangeGroupedAssetMoveOperation"
    ],
    "ProjectChangeOperation": [
      "ProjectChangeFolderCopyOperation",
      "ProjectChangeFolderCreateOperation",
      "ProjectChangeFolderDeleteOperation",
      "ProjectChangeFolderMoveOperation",
      "ProjectChangeFolderUpdateOperation",
      "ProjectChangeGroupedAssetCopyOperation",
      "ProjectChangeGroupedAssetCreateOperation",
      "ProjectChangeGroupedAssetDeleteOperation",
      "ProjectChangeGroupedAssetMoveOperation",
      "ProjectChangeGroupedAssetUpdateOperation",
      "ProjectChangeProjectUpdateOperation",
      "ProjectChangeUserAccessGrantedOperation",
      "ProjectChangeUserAccessRevokedOperation"
    ],
    "ProjectChangePayload": [
      "ProjectChangeOutput",
      "ServerError",
      "UnauthenticatedError"
    ],
    "ProjectChangeProjectOperation": [
      "ProjectChangeOutput",
      "ProjectChangeProjectUpdateOperation"
    ],
    "ProjectChangeUpdateOperation": [
      "ProjectChangeFolderUpdateOperation",
      "ProjectChangeGroupedAssetUpdateOperation",
      "ProjectChangeProjectUpdateOperation"
    ],
    "ProjectChangeUserAccessOperation": [
      "ProjectChangeUserAccessGrantedOperation",
      "ProjectChangeUserAccessRevokedOperation"
    ],
    "ProjectUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "ProjectOutput"
    ],
    "ProjectsUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "ProjectResultOutput"
    ],
    "QuotationUnion": [
      "QuoteOutput",
      "ShopErrorBackendFailedOutput",
      "ShopErrorNoOverlapWithProductOutput",
      "ShopErrorOperationNotAllowedOutput",
      "ShopErrorProductNotFoundOutput",
      "ShopErrorProductPricingNotFoundOutput"
    ],
    "RemoveLabelsUnion": [
      "LabelErrorOperationNotAllowedOutput",
      "LabelsOutput"
    ],
    "Renderable": [
      "AddressAssetFileDownloadOutput",
      "AddressDownloadOutput",
      "AddressHspcOutput",
      "AddressLtsOutput",
      "AddressOgc3DOutput",
      "AddressPanoramicOutput",
      "AddressWfsOutput",
      "AddressWmsOutput",
      "AddressWmtsOutput"
    ],
    "SearchLabelsUnion": [
      "LabelErrorOperationNotAllowedOutput",
      "LabelsResultOutput"
    ],
    "SearchMyAssetsProjectUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "SearchOutput"
    ],
    "SearchProjectUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "SearchOutput"
    ],
    "SharedGroupedAssetUnion": [
      "AcsErrorEntityNotFoundOutput",
      "AcsErrorOperationNotPossibleOutput",
      "SharedGroupedAssetOutput"
    ],
    "SharingCodeUnion": [
      "AcsErrorEntityNotFoundOutput",
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorOperationNotPossibleOutput",
      "SharingCodeOutput"
    ],
    "SignupFromInviteUnion": [
      "AcsErrorInvalidInvitationSignatureOutput",
      "AcsErrorSeatLimitReachedOutput",
      "AcsErrorTooManyRequestsOutput",
      "AcsErrorUserAlreadyExistsOutput",
      "SignupOutput"
    ],
    "SignupUnion": [
      "AcsErrorInvalidVoucherOutput",
      "AcsErrorTooManyRequestsOutput",
      "AcsErrorUserAlreadyExistsOutput",
      "AcsErrorVoucherAlreadyUsedOutput",
      "SignupOutput"
    ],
    "SimpleResultUnion": [
      "AcsErrorEntityNotFoundOutput",
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorOperationNotPossibleOutput",
      "SimpleResultOutput"
    ],
    "SimpleUserProfileUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedForTargetUserOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "SimpleUserProfileOutput"
    ],
    "SubscriptionInvoicesUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionInvoicesOutput",
      "SubscriptionNoValidIsoCountryFoundOutput"
    ],
    "SubscriptionOrdersUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "SubscriptionOrdersOutput"
    ],
    "SubscriptionQuotationUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "SubscriptionQuotationOutput"
    ],
    "SubscriptionTiersUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "SubscriptionTiersOutput"
    ],
    "SubscriptionUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorDuplicateWithSameAddonOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "SubscriptionOutput"
    ],
    "SubscriptionsUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "SubscriptionsOutput"
    ],
    "TenantDeletionUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "TenantDeletionOutput"
    ],
    "TenantUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorOperationNotPossibleOutput",
      "AcsErrorTenantNotFoundOutput",
      "TenantOutput"
    ],
    "TenantsUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "TenantsOutput"
    ],
    "TokensTransactionsUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "TokensTransactionsOutput"
    ],
    "Tour": [
      "GroupedAssetTourOutput"
    ],
    "TourKeyframe": [
      "GroupedAssetTourKeyframeOutput"
    ],
    "TriggerProcessingUnion": [
      "ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput",
      "ProcessingErrorInternalServerErrorOutput",
      "ProcessingErrorInvalidInputForProcessingPaymentErrorOutput",
      "ProcessingErrorOperationNotAllowedOutput",
      "ProcessingErrorProcessingLimitReachedOutput",
      "ProcessingErrorResourceAccessDeniedOutput",
      "ProcessingErrorResourceNotFoundOutput",
      "ProcessingErrorStorageLimitReachedOutput",
      "TriggerProcessingOutput"
    ],
    "UnsharedGroupedAssetUnion": [
      "AcsErrorEntityNotFoundOutput",
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorOperationNotPossibleOutput",
      "UnsharedGroupedAssetOutput"
    ],
    "UpdateApplicationTemplateUnion": [
      "ApplicationErrorInvalidArgumentOutput",
      "ApplicationErrorOperationNotAllowedOutput",
      "ApplicationErrorResourceAccessDeniedOutput",
      "ApplicationErrorTooManyRequestsOutput",
      "ApplicationTemplateOutput"
    ],
    "UpdateAssetUnion": [
      "AssetErrorDuplicateNameOutput",
      "AssetErrorNameBlacklistedCharactersOutput",
      "AssetErrorNameBlankOutput",
      "AssetErrorNameForbiddenOutput",
      "AssetErrorNameForwardSlashOutput",
      "AssetErrorNameLeadingOrTrailingSpaceOutput",
      "AssetErrorNameTrailingDotOutput",
      "AssetErrorNameTwoDotsInRowOutput",
      "AssetErrorOperationNotAllowedOutput",
      "AssetErrorResourceAccessDeniedOutput",
      "AssetErrorTooManyRequestsOutput",
      "GroupedAssetOutput"
    ],
    "UpdateFolderUnion": [
      "FolderErrorDuplicateNameOutput",
      "FolderErrorNameBlacklistedCharactersOutput",
      "FolderErrorNameBlankOutput",
      "FolderErrorNameForbiddenOutput",
      "FolderErrorNameForwardSlashOutput",
      "FolderErrorNameLeadingOrTrailingSpaceOutput",
      "FolderErrorNameTrailingDotOutput",
      "FolderErrorNameTwoDotsInRowOutput",
      "FolderErrorOperationNotAllowedOutput",
      "FolderErrorResourceAccessDeniedOutput",
      "FolderErrorTooManyRequestsOutput",
      "FolderOutput"
    ],
    "UpdateLabelAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "LabelAnnotationOutput"
    ],
    "UpdateLimitingBoxAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "LimitingBoxAnnotationOutput"
    ],
    "UpdateMeasurementAnnotationUnion": [
      "AnnotationErrorOperationNotAllowedOutput",
      "MeasurementAnnotationOutput"
    ],
    "UpdateProjectUnion": [
      "ProjectErrorDuplicateNameOutput",
      "ProjectErrorInvalidInputOutput",
      "ProjectErrorNameBlacklistedCharactersOutput",
      "ProjectErrorNameBlankOutput",
      "ProjectErrorNameForbiddenOutput",
      "ProjectErrorNameForwardSlashOutput",
      "ProjectErrorNameLeadingOrTrailingSpaceOutput",
      "ProjectErrorNameTrailingDotOutput",
      "ProjectErrorNameTwoDotsInRowOutput",
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "ProjectOutput"
    ],
    "UploadThumbnailToProjectUnion": [
      "ProjectErrorOperationNotAllowedOutput",
      "ProjectErrorResourceAccessDeniedOutput",
      "ProjectErrorTooManyRequestsOutput",
      "ProjectThumbnailOutput"
    ],
    "UsageAccountUnion": [
      "SubscriptionErrorOperationNotAllowedOutput",
      "UsageOutput"
    ],
    "UsageTenantUnion": [
      "SubscriptionErrorOperationNotAllowedOutput",
      "UsageTenantOutput"
    ],
    "UserDeletionUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorUserNotFoundOutput",
      "UserDeletionOutput"
    ],
    "UserProjectRoleUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorProjectNotFoundOutput",
      "ListOfUserProjectRoleOutput"
    ],
    "VoucherUnion": [
      "AcsErrorOperationNotAllowedOutput",
      "AcsErrorSeatLimitReachedOutput",
      "AcsErrorUserAlreadyExistsInAccountOutput",
      "AcsErrorUserAlreadyExistsInOtherAccountOutput",
      "VoucherOutput"
    ],
    "WalletRefundUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorBadRequestOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "TokensRefundOutput"
    ],
    "WalletUnion": [
      "SubscriptionErrorAccountNotSyncedOutput",
      "SubscriptionErrorFeatureNotAvailableOutput",
      "SubscriptionErrorOperationNotAllowedOutput",
      "SubscriptionNoValidIsoCountryFoundOutput",
      "TokenWalletOutput"
    ],
    "_Entity": [
      "AccountInfoOutput",
      "AccountUsageOutput",
      "AddressDownloadOutput",
      "AddressHspcOutput",
      "AddressLtsOutput",
      "AddressOgc3DOutput",
      "AddressPageOutput",
      "AddressPanoramicOutput",
      "ApplicationTemplateOutput",
      "AssetOutput",
      "FileOutput",
      "FolderOutput",
      "GeoreferenceArtifactResultOutput",
      "GroupedAssetOutput",
      "JobPage",
      "JobSummaryGQL",
      "LabelAnnotationOutput",
      "LimitingBoxAnnotationOutput",
      "LuciadRIALicenseOutput",
      "MeasurementAnnotationOutput",
      "OrderGQL",
      "OrderItemGQL",
      "ProjectMemberOutput",
      "ProjectOutput",
      "SearchOutput",
      "SimpleAccountInfoOutput",
      "SimpleUserProfileOutput",
      "UsageOutput",
      "UsageTenantOutput",
      "UserProfileOutput"
    ]
  }
};
      export default result;
    