import { PcTool } from '../dts/Alignment'

export class PcToolSupport {
  private _tool: PcTool | null = null

  public get tool() {
    return this._tool
  }

  public set tool(v: PcTool | null) {
    this._tool = v
  }

  constructor() {}

  public checkDisabled(t: PcTool | null): boolean {
    return t !== this._tool
  }

  public checkEnabled(t: PcTool | null): boolean {
    return t === this._tool
  }
}
