import {Button, Inline} from '@digitalreality/ui';
import React from 'react';

interface Props {
  onClose: () => void;
  onConfirm: () => void;
  confirmButtonName: string;
  confirmButtonEnabled: boolean;
}

/**
 * Modal footer with cancelling and confirmation capabilities
 */
export const AddDataFooter = ({
  onClose,
  onConfirm,
  confirmButtonName,
  confirmButtonEnabled,
}: Props) => {
  return (
    <Inline gap="8px" justifyContent="end" width="100%">
      <Button onClick={onClose}>Cancel</Button>
      <Button disabled={!confirmButtonEnabled} onClick={onConfirm}>
        {confirmButtonName}
      </Button>
    </Inline>
  );
};
