import { GeoCanvas } from '@luciad/ria/view/style/GeoCanvas'
import { LocationMode } from '@luciad/ria/transformation/LocationMode'
import { Transformation } from '@luciad/ria/transformation/Transformation'
import { Point } from '@luciad/ria/shape/Point'
import { createPoint } from '@luciad/ria/shape/ShapeFactory'
import { Map as RIAMap } from '@luciad/ria/view/Map'
import { add, distance, normalize, scale, sub } from '../../util/Vector3Util'
import { Vector3 } from '@luciad/ria/util/Vector3'
import { toWorldPoint } from './NavigationHelper'
import { NavigationGizmo } from './NavigationGizmo'
import { NavigationType } from './GestureHelper'
import { AssetDimension } from './AssetDimension'
import { clamp } from '../../common/util/Math'

const MAX_STICKY_RADIUS = 400 // pixels

const GIZMOS = new Map<NavigationType, NavigationGizmo>([
  [
    NavigationType.ROTATION,
    new NavigationGizmo(
      `${process.env.REDIRECT_URI}/resources/gizmo_circles.glb`
    ),
  ],
  [
    NavigationType.PAN,
    new NavigationGizmo(
      `${process.env.REDIRECT_URI}/resources/gizmo_arrows.glb`
    ),
  ],
  [
    NavigationType.ZOOM,
    new NavigationGizmo(
      `${process.env.REDIRECT_URI}/resources/gizmo_octhedron.glb`,
      {
        sizeInPixels: 40,
      }
    ),
  ],
])

function getGizmo(type: NavigationType): NavigationGizmo | null {
  return GIZMOS.get(type) ?? null
}

/**
 * The helper responsibilities:
 * - checks if the gesture events are for the rotation, panning, or zooming navigations
 * - computes the anchor point, that is on surface or hovered in 3D, for the above navigations
 * - paints a corresponding gizmo representation at the computed anchor point
 */
export class AnchorHelper {
  private readonly _map: RIAMap
  private readonly _tx: Transformation
  private readonly _txEllipsoid: Transformation
  private readonly _anchor: Point
  private readonly _tmpPoint: Point
  private readonly _assetDimension: AssetDimension

  constructor(map: RIAMap, assetDimension: AssetDimension) {
    this._map = map
    this._assetDimension = assetDimension
    this._tx = map.getViewToMapTransformation(LocationMode.CLOSEST_SURFACE)
    this._txEllipsoid = map.getViewToMapTransformation(LocationMode.ELLIPSOID)
    this._anchor = createPoint(map.reference, [0, 0, 0])
    this._tmpPoint = createPoint(map.reference, [0, 0, 0])
  }

  get anchor(): Point {
    return this._anchor
  }

  /**
   * Paints the gizmo of the current navigation type.
   */
  paint(geoCanvas: GeoCanvas, actionType: NavigationType): boolean {
    const gizmo = getGizmo(actionType)
    if (gizmo) {
      geoCanvas.drawIcon3D(this._anchor, gizmo.style)
    }
    return !!gizmo
  }

  private setAnchor({ x, y, z }: Vector3): void {
    this._anchor.move3D(x, y, z)
  }

  private distanceToEye(v: Vector3): number {
    return distance(v, this._map.camera.eye)
  }

  /**
   * Computes the anchor associated with the given viewPoint.
   * This world point is guaranteed to be under the given viewPoint.
   * If there is no painted object under the given viewPoint, an approximation
   * is made by looking at nearby painted objects or the asset center.
   */
  computeAnchor(viewPoint: Point, navigationType: NavigationType) {
    const { eye } = this._map.camera

    const anchor = this.getAnchorAtViewPoint(viewPoint)
    if (anchor) {
      // anchor on the surface
      this.setAnchor(anchor)
    } else {
      const stickyPoint =
        this.findStickyPoint(viewPoint) ||
        this.findFallbackStickyPoint(navigationType)

      const worldStickyPoint = toWorldPoint(this._map, viewPoint)
      const directionToSticky = normalize(sub(worldStickyPoint, eye))
      const distanceToSticky = this.distanceToEye(stickyPoint)
      const anchor = add(eye, scale(directionToSticky, distanceToSticky))
      this.setAnchor(anchor)
    }

    const gizmo = getGizmo(navigationType)
    gizmo?.rescaleForFixedViewSize(this._map, this._anchor)
  }

  /**
   * Returns the world point painted under the given viewPoint.
   * If no point could be found or point is on the ellipsoid, null is returned.
   */
  private getAnchorAtViewPoint(viewPoint: Point): Vector3 | null {
    try {
      this._tx.transform(viewPoint, this._tmpPoint)
    } catch (e) {
      return null
    }

    const surfacePoint: Vector3 = pointToVector(this._tmpPoint)

    // Check if touched pixel is inside the asset domain
    if (!this._assetDimension.isInAssetWorld(this._tmpPoint)) {
      return null
    }

    try {
      // Check if the touched point is on the ellipsoid
      this._txEllipsoid.transform(viewPoint, this._tmpPoint)
      if (distance(surfacePoint, this._tmpPoint) < 1) {
        return null
      }
    } catch (e) {
      // Don't care if above the horizon
    }

    return surfacePoint
  }

  /**
   * Tries to find an anchor point close to the given viewPoint.
   * This function starts looking for pixels at given startRadius distance and
   * iteratively widens the search until MAX_STICKY_RADIUS is reached.
   * If no point could be found, null is returned.
   */
  private findStickyPoint(viewPoint: Point, startRadius = 1): Vector3 | null {
    const { viewSize } = this._map
    const nearPixels = getSurroundingCoordinates(
      viewPoint,
      startRadius,
      viewSize
    )

    let nearest = Number.MAX_VALUE
    let best: Vector3 | null = null

    const delta = clamp(Math.round(startRadius / 40), 1, 7)
    for (let i = 0; i < nearPixels.length; i += delta) {
      const [x, y] = nearPixels[i]
      this._tmpPoint.move3D(x, y, 0)
      const anchorCandidate = this.getAnchorAtViewPoint(this._tmpPoint.copy())
      if (anchorCandidate) {
        const d = this.distanceToEye(anchorCandidate)
        if (d < nearest) {
          nearest = d
          best = anchorCandidate
        }
      }
    }
    if (best) {
      return best
    }

    if (startRadius < MAX_STICKY_RADIUS) {
      const radiusDelta =
        startRadius < 7 ? 1 : Math.max(1, Math.ceil(0.15 * startRadius))
      return this.findStickyPoint(viewPoint, startRadius + radiusDelta)
    }

    return null
  }

  /**
   * Returns a fallback point for findStickyPoint.
   * If the asset center is in front of the camera, a point in the center of
   * the screen close to the asset center is returned.
   * Otherwise, a point in the center of the screen is returned,
   * relatively close to the camera.
   */
  private findFallbackStickyPoint(navigationType: NavigationType) {
    const { eye, forward } = this._map.camera
    const { center } = this._assetDimension

    const eyeToCenter = this.distanceToEye(center)
    try {
      if (this._assetDimension.isCenterInView(this._map)) {
        // fallback 1 - place anchor at the eye-to-center distance
        return add(eye, scale(forward, eyeToCenter))
      }
    } catch (e) {
      // Don't care if the asset center is outside the view
    }
    // fallback 2 - asset behind the camera or is barely seen
    // place the rotation anchor just in front of the camera eye
    // place zoom or pan anchor a fraction of the distance to the asset limits
    const unitScaleFactor =
      navigationType === NavigationType.ZOOM ||
      navigationType === NavigationType.PAN
        ? 0.25 * (this._assetDimension.maxDistance - eyeToCenter)
        : 1
    return add(eye, scale(forward, unitScaleFactor))
  }
}

/**
 * Returns view coordinates around the given view point at given pixelDistance,
 * which is interpreted as manhattan distance (measured at right angles).
 * View coordinates outside the given viewSize are not returned
 */
function getSurroundingCoordinates(
  { x, y }: Point,
  pixelDistance: number,
  viewSize: [number, number]
): [number, number][] {
  const result: [number, number][] = []

  function toResult(x: number, y: number) {
    if (isInView(x, y, viewSize)) {
      result.push([x, y])
    }
  }

  for (let i = -pixelDistance; i <= pixelDistance; i++) {
    toResult(x + i, y - pixelDistance)
    toResult(x + i, y + pixelDistance)
    if (i !== -pixelDistance && i !== pixelDistance) {
      toResult(x - pixelDistance, y + i)
      toResult(x + pixelDistance, y + i)
    }
  }
  return result
}

function isInView(x: number, y: number, [w, h]: [number, number]): boolean {
  return x > 0 && x < w && y > 0 && y < h
}

function pointToVector({ x, y, z }: Point): Vector3 {
  return { x, y, z }
}
