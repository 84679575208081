import {Controller} from '@luciad/ria/view/controller/Controller';
import {GestureEvent} from '@luciad/ria/view/input/GestureEvent';
import {HandleEventResult} from '@luciad/ria/view/controller/HandleEventResult';
import {GestureEventType} from '@luciad/ria/view/input/GestureEventType';
import {Map} from '@luciad/ria/view/Map';
import {createTransformation} from '@luciad/ria/transformation/TransformationFactory';
import {GeoCanvas} from '@luciad/ria/view/style/GeoCanvas';
import {Vector3} from '@luciad/ria/util/Vector3';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {
  ALTITUDE_CHANGED_EVENT,
  CRS_84,
  GeolocateHandleSupport,
  MOVED_EVENT,
  POSITION_UPDATED_EVENT,
  ROTATED_EVENT,
  STYLE_UPDATED_EVENT,
} from '../common/geolocation/GeolocateHandleSupport';
import {LabelCanvas} from '@luciad/ria/view/style/LabelCanvas';
import {rayRectangleIntersection, toPoint} from '../common/util/Vector3Util';
import {Layer} from '@luciad/ria/view/Layer';
import {calculatePointingDirection} from '../common/util/PerspectiveCameraUtil';
import {createFacePolygons} from '../common/util/AdvancedShapeFactory';
import {EventedSupport} from '@luciad/ria/util/EventedSupport';
import {getReference} from '@luciad/ria/reference/ReferenceProvider';
import {geolocateLayer} from './GeolocationUtil';
import {Point} from '@luciad/ria/shape/Point';
import {Handle} from '@luciad/ria/util/Evented';
import {TileSet3DLayer} from '@luciad/ria/view/tileset/TileSet3DLayer';

/**
 * Event that gets emitted when a user confirmed the edit of the layers.
 */
export const MOVE_CONFIRMED_EVENT = 'MoveConfirmedEvent';
const WORLD_REF = getReference('EPSG:4978');
const WORLD_TO_LLH = createTransformation(WORLD_REF, CRS_84);

/**
 * Returns the max width and depth of the given layers in meters
 * @param layers
 */
function getDimensions(layers: Layer[]) {
  let width = 1;
  let depth = 1;
  for (const layer of layers) {
    if (!(layer instanceof TileSet3DLayer)) {
      continue;
    }
    const bounds = layer.model.bounds;
    if (bounds.width > width) {
      width = bounds.width;
    }
    if (bounds.height > depth) {
      depth = bounds.height;
    }
  }
  return [width, depth];
}

/**
 * Controller used to translate and rotate geolocated layers using geolocation handles.
 */
export class GeolocationMoveController extends Controller {
  private readonly _handleSupport: GeolocateHandleSupport =
    new GeolocateHandleSupport();
  private readonly _eventedSupport = new EventedSupport(
    [MOVE_CONFIRMED_EVENT],
    true
  );
  private readonly _layers: Layer[];
  private readonly _anchor: Point;
  private readonly _width: number;
  private readonly _depth: number;

  private _position: Point;
  private _rotationAtDragStart: number;
  private _rotationFromDrag: number = 0;

  constructor(
    layers: Layer[],
    anchor: Vector3,
    geolocation: Point,
    rotation: number
  ) {
    super();

    this._layers = layers;
    this._anchor = toPoint(getReference('LUCIAD:XYZ'), anchor);

    [this._width, this._depth] = getDimensions(layers);
    this._position = createTransformation(
      geolocation.reference!,
      WORLD_REF
    ).transform(geolocation);
    this._rotationAtDragStart = rotation;

    this._handleSupport.on(STYLE_UPDATED_EVENT, () => this.invalidate());
    this._handleSupport.on(POSITION_UPDATED_EVENT, () => this.updateHandles());
    this._handleSupport.on(MOVED_EVENT, (translation) =>
      this.translate(translation)
    );
    this._handleSupport.on(ROTATED_EVENT, (absoluteRotation) =>
      this.rotate(absoluteRotation)
    );
    this._handleSupport.on(ALTITUDE_CHANGED_EVENT, (translation) =>
      this.translate(translation)
    );
  }

  onActivate(map: Map) {
    super.onActivate(map);
    this.updateHandles();
  }

  onDeactivate(map: Map): any {
    this._handleSupport.resetHandles();
    return super.onDeactivate(map);
  }

  private translate({x, y, z}: Vector3) {
    this._position.translate3D(x, y, z);
    this.updateTransformations();
  }

  private rotate(rotation: number) {
    this._rotationFromDrag = rotation;
    this.updateTransformations();
  }

  private updateTransformations() {
    for (const layer of this._layers) {
      geolocateLayer(
        layer,
        this._position,
        this._anchor,
        this._rotationAtDragStart + this._rotationFromDrag
      );
    }
  }

  /**
   * Update the geolocation handles' shape and interaction functions to fit this controller's layers.
   */
  private updateHandles() {
    const bottomCenterLLH = WORLD_TO_LLH.transform(
      toPoint(WORLD_REF, this._position)
    );
    this._handleSupport.updateHandles(
      this.map!,
      bottomCenterLLH,
      this._width,
      this._depth
    );
    this.invalidate();
  }

  onGestureEvent(event: GestureEvent): HandleEventResult {
    if (
      event.type === GestureEventType.SINGLE_CLICK_UP &&
      !this.isViewPointInBox(event.viewPoint)
    ) {
      this._eventedSupport.emit(
        MOVE_CONFIRMED_EVENT,
        WORLD_TO_LLH.transform(this._position),
        this._rotationAtDragStart
      );
      return HandleEventResult.EVENT_HANDLED;
    } else if (event.type === GestureEventType.DRAG_END) {
      this._rotationAtDragStart += this._rotationFromDrag;
      this._rotationFromDrag = 0;
    }
    const bottomCenter = toPoint(this.map!.reference, this._position);
    return this._handleSupport.handleGestureEvent(
      this.map!,
      event,
      bottomCenter
    );
  }

  onDraw(geoCanvas: GeoCanvas) {
    this._handleSupport.drawHandles(geoCanvas);
  }

  onDrawLabel(labelCanvas: LabelCanvas) {
    this._handleSupport.drawHandleLabels(labelCanvas);
  }

  private isViewPointInBox(viewPoint: Vector3) {
    const eye = (this.map!.camera as PerspectiveCamera).eye;
    const pointingDirection = calculatePointingDirection(this.map!, viewPoint);

    for (const layer of this._layers) {
      if (layer instanceof TileSet3DLayer) {
        for (const rectangle of createFacePolygons(layer.orientedBox)) {
          if (rayRectangleIntersection(eye, pointingDirection, rectangle)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  on(
    event:
      | 'Activated'
      | 'Deactivated'
      | 'Invalidated'
      | typeof MOVE_CONFIRMED_EVENT,
    callback:
      | ((map: Map) => void)
      | ((geolocation: Point, rotation: number) => void)
  ): Handle {
    if (event === MOVE_CONFIRMED_EVENT) {
      return this._eventedSupport.on(event, callback);
    } else if (event === 'Invalidated') {
      return super.on(event, callback as () => void);
    } else if (event === 'Activated') {
      return super.on(event, callback as (map: Map) => void);
    }
    return super.on(event, callback as (map: Map) => void);
  }
}
