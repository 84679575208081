import { Box, ContentModal, Tab, Tabs } from '@digitalreality/ui'
import React, { useState } from 'react'
import { HxDRAssetsTab } from './HxDRAssetsTab'
import { DataUploaderTab } from './DataUploaderTab'
import { AddDataManager } from './AddDataManager'
import { Map } from '@luciad/ria/view/Map'
import { GeolocationSupport } from '../../geolocation/GeolocationSupport'
import { UrlAssetsTab } from './UrlAssetsTab'

interface Props {
  onClose: () => void
  map: Map
  dataManager: AddDataManager
}

/**
 * Modal that can be used to add data to the map.
 * This data can either come from your HxDR assets or local files.
 */
export const AddDataModal = ({ map, dataManager, onClose }: Props) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)

  return (
    <ContentModal open title="Add data from">
      <Box width="100%" height="100%">
        <Tabs
          value={selectedTab}
          onChange={(e, value) => setSelectedTab(value)}
        >
          <Tab label="HxDR" />
          <Tab label="Your URL" />
          {/* <Tab label="Local file" /> */}
        </Tabs>
        <Box width="100%" height="390px">
          <Box hidden={selectedTab !== 0} height="100%">
            <HxDRAssetsTab
              isOpen={selectedTab === 0}
              onClose={onClose}
              map={map}
            />
          </Box>
          <Box hidden={selectedTab !== 1} height="100%">
            <UrlAssetsTab
              map={map}
              isOpen={selectedTab === 1}
              onClose={onClose}
            />
          </Box>
          {/* <Box hidden={selectedTab !== 1} height="100%">
            <DataUploaderTab
              isOpen={selectedTab === 1}
              onClose={onClose}
              dataManager={dataManager}
            />
          </Box> */}
        </Box>
      </Box>
    </ContentModal>
  )
}
