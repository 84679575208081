import {AssetDimension} from './AssetDimension';
import {Map} from '@luciad/ria/view/Map';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {Point} from '@luciad/ria/shape/Point';
import {AnimationManager} from '@luciad/ria/view/animation/AnimationManager';
import {Vector3} from '@luciad/ria/util/Vector3';
import {add, addArray, cross, Length, normalize, projectOnVector, scale, sub,} from '../../util/Vector3Util';
import {DEG2RAD} from '../../common/util/Math';
import {calculatePointingAngles} from "../../common/util/PerspectiveCameraUtil";

export class NavigationPan {
  private readonly _assetDimension: AssetDimension | null;
  private _panStart: Vector3 | null;
  private _distanceToPanPlane: number;

  constructor(assetDimension: AssetDimension | null = null) {
    this._assetDimension = assetDimension;
    this._panStart = null;
    this._distanceToPanPlane = 0;
  }

  public reset() {
    this._panStart = null;
    this._distanceToPanPlane = 0;
  }

  public hasStarted(): boolean {
    return this._panStart !== null;
  }


  /**
   * Panning by shifting the camera over the camera's orthogonal plane.
   */
  public panCameraOverOrthogonalPlane(
    map: Map,
    anchor: Vector3,
    viewPoint: Point
  ): void {
    const { eye, forward, up } = map.camera;

    if (!this._panStart) {
      this._panStart = anchor;
      this._distanceToPanPlane = Length(
        projectOnVector(sub(anchor, eye), forward)
      );
    }

    if (this._distanceToPanPlane && this._panStart) {
      const { angleX: yaw, angleY: pitch } = calculatePointingAngles(map, viewPoint);

      const right = normalize(cross(forward, up));
      const rightFactor = Math.tan(yaw * DEG2RAD) * this._distanceToPanPlane;
      const upFactor = Math.tan(pitch * DEG2RAD) * this._distanceToPanPlane;

      const newPoint3D = addArray([
        eye,
        scale(forward, this._distanceToPanPlane),
        scale(right, rightFactor),
        scale(up, upFactor),
      ]);

      const translation = sub(this._panStart, newPoint3D);
      const newEye = add(eye, translation);
      this.moveCamera(map, newEye);
    }
  }

  private moveCamera(map: Map, newEye: Vector3): void {
    const shouldMove = this._assetDimension
      ? this._assetDimension.isInAssetWorld(newEye)
      : true;
    if (shouldMove) {
      const camera = map.camera as PerspectiveCamera;
      AnimationManager.removeAnimation(map.cameraAnimationKey);
      map.camera = camera.copyAndSet({ eye: newEye });
    }
  }
}
