import { generateLoginRedirectEndPoint } from './AuthenticationUtil'
import React, { useEffect, useState } from 'react'
import {
  CenteredLayout,
  CircularProgress,
  Fixed,
  Paper,
  Stack,
  SubmitButton,
  Text,
} from '@digitalreality/ui'
import { CredentialsIcon, UserAvatarIcon } from '@digitalreality/ui-icons'

/**
 * Page component to redirect users to the Cognito login page.
 */
export const LoginPage = ({}) => {
  const [loginRedirectEndpoint, setLoginRedirectEndpoint] = useState<string>()

  useEffect(() => {
    generateLoginRedirectEndPoint().then((endpoint) =>
      setLoginRedirectEndpoint(endpoint)
    )
  }, [])

  return (
    <Fixed
      justifyContent="center"
      alignItems="center"
      height="100vh"
      width="100%"
      sx={{ m: 0, p: 0, top: 0, left: 0 }}
    >
      {loginRedirectEndpoint ? (
        <CenteredLayout
          sx={{ m: 0, p: 0 }}
          imagePath="https://images.unsplash.com/photo-1495954380655-01609180eda3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=634&q=80"
        >
          <Stack
            alignItems="center"
            borderRadius={3}
            padding={5}
            gap={3}
            boxShadow={2}
            sx={{ backgroundColor: '#121212', color: '#ffffff' }}
          >
            <Text>You aren't logged into HxDR yet, please log in first.</Text>
            <a href={loginRedirectEndpoint} style={{ textDecoration: 'none' }}>
              <SubmitButton
                variant="contained"
                endIcon={<UserAvatarIcon />}
                size="small"
                sx={{
                  backgroundColor: '#5098A1',
                  '&:hover': { backgroundColor: '#26656d' },
                }}
              >
                Log in
              </SubmitButton>
            </a>
          </Stack>
        </CenteredLayout>
      ) : (
        <CircularProgress size={32} />
      )}
    </Fixed>
  )
}
