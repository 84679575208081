import { Field } from '@digitalreality/ui'
import React, { ChangeEvent, useState } from 'react'

interface URLInputProps {
  label: string
  value: string
  onChange: (value: string) => void
  validateUrl: (value: string) => boolean
}

export const UrlInput: React.FC<URLInputProps> = ({
  label,
  value,
  onChange,
  validateUrl,
}) => {
  const [error, setError] = useState(false)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    onChange(inputValue)
    setError(!validateUrl(inputValue))
  }

  return (
    <Field
      label={label}
      value={value}
      onChange={handleInputChange}
      error={error}
      helperText={error ? 'Invalid URL' : ''}
    />
  )
}
