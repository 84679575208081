import { Vector3 } from '@luciad/ria/util/Vector3';
import { CoordinateReference } from '@luciad/ria/reference/CoordinateReference';
import { Point } from '@luciad/ria/shape/Point';
import { createPoint, createPolyline } from '@luciad/ria/shape/ShapeFactory';
import { Polyline } from '@luciad/ria/shape/Polyline';
import { Polygon } from '@luciad/ria/shape/Polygon';

export const DEG2RAD = Math.PI / 180;
export const RAD2DEG = 180 / Math.PI;

export function copy(v: Vector3): Vector3 {
    return { x: v.x, y: v.y, z: v.z };
}
export function interpolate(a: number, b: number, t: number) {
    return a * (1 - t) + b * t;
}
export function add(v1: Vector3, v2: Vector3): Vector3 {
    return {
        x: v2.x + v1.x,
        y: v2.y + v1.y,
        z: v2.z + v1.z,
    };
}

/**
 * Returns the element wise addition of an array of vectors
 * @param array An array of vectors
 */
export function addArray(array: Vector3[]): Vector3 {
    return array.reduce((result, a) => {
        if (!result) {
            return a;
        }
        return {
            x: result.x + a.x,
            y: result.y + a.y,
            z: result.z + a.z,
        };
    });
}

export function sub(v1: Vector3, v2: Vector3): Vector3 {
    return {
        x: v1.x - v2.x,
        y: v1.y - v2.y,
        z: v1.z - v2.z,
    };
}

/**
 * Subtracts an array of vectors from the first vector in the array.
 * @param array An array of vectors
 */
export function subArray(array: Vector3[]): Vector3 {
    return array.reduce((result, a) => {
        if (!result) {
            return a;
        }
        return {
            x: result.x - a.x,
            y: result.y - a.y,
            z: result.z - a.z,
        };
    });
}

export const average = (array: Vector3[]) => {
    return scale(addArray(array), 1 / array.length);
};

/**
 * Linear interpolation between two vectors.
 */
export function interpolateVectors(
    begin: Vector3,
    end: Vector3,
    ratio: number
): Vector3 {
    return {
        x: interpolate(begin.x, end.x, ratio),
        y: interpolate(begin.y, end.y, ratio),
        z: interpolate(begin.z, end.z, ratio),
    };
}

export function scale(v: Vector3, scalar: number): Vector3 {
    return {
        x: v.x * scalar,
        y: v.y * scalar,
        z: v.z * scalar,
    };
}

/**
 * Cross product of two vectors
 */
export function cross(v1: Vector3, v2: Vector3): Vector3 {
    return {
        x: v1.y * v2.z - v1.z * v2.y,
        y: v1.z * v2.x - v1.x * v2.z,
        z: v1.x * v2.y - v1.y * v2.x,
    };
}

/**
 * Scalar product of two vectors
 */
export function scalar(v1: Vector3, v2: Vector3): number {
    return v1.x * v2.x + v1.y * v2.y + v1.z * v2.z;
}

export function negate(v: Vector3): Vector3 {
    return {
        x: -v.x,
        y: -v.y,
        z: -v.z,
    };
}

/**
 * Gets the magnitude of a vector (without applying square root). Slightly faster than length()
 * @returns the magnitude of the vector
 */
export function length2(a: Vector3) {
    return a.x * a.x + a.y * a.y + a.z * a.z;
}

export function Length(v: Vector3): number {
    return Math.sqrt(length2(v));
}

export function normalize(v: Vector3): Vector3 {
    return scale(v, 1 / (Length(v) || 1));
}

export function distance(a: Vector3, b: Vector3): number {
    return Length(sub(a, b));
}

export function absoluteAngle(a: Vector3, b: Vector3): number {
    return Math.acos(scalar(a, b) / (Length(a) * Length(b))) * RAD2DEG;
}

/**
 * Returns the angle from a to b, using the given axis to determine whether the angle is positive or not.
 * We assume that the given axis is either equal (or very close to) to the cross product of a & b or b & a.
 */
export function angle(a: Vector3, b: Vector3, axis: Vector3): number {
    const absAngle = absoluteAngle(a, b);

    const crossProduct = scalar(axis, cross(a, b));
    if (crossProduct < 0) {
        return -absAngle;
    } else {
        return absAngle;
    }
}

/**
 * Returns the given vector rotated with given angle (in degrees) around the given axis.
 */
export function rotateAroundAxis(
    vector: Vector3,
    axis: Vector3,
    angleInDegrees: number
): Vector3 {
    //Rodrigues formula
    const angle = angleInDegrees * DEG2RAD;
    const unitAxis = normalize(axis);
    return addArray([
        scale(vector, Math.cos(angle)),
        scale(cross(unitAxis, vector), Math.sin(angle)),
        scale(unitAxis, scalar(unitAxis, vector) * (1 - Math.cos(angle))),
    ]);
}

/**
 * Calculates the closest point on the given line to the given point
 * @param point the point to find the closest point of on a line segment
 * @param lineStart the first point of the line
 * @param lineEnd the second point of the line
 * @param clipToSegment if true, will limit the result to a point on the line, otherwise any point will be
 *                      allowed in the extension the line
 * @param pointOnLineSFCT a point that is projected onto the line.
 * @returns the distance to the line
 */
export const closestPointOnLine = (
    point: Vector3,
    lineStart: Vector3,
    lineEnd: Vector3,
    clipToSegment: boolean,
    pointOnLineSFCT?: Vector3
) => {
    const aX0 = lineStart.x;
    const aY0 = lineStart.y;
    const aZ0 = lineStart.z;
    const aX1 = lineEnd.x;
    const aY1 = lineEnd.y;
    const aZ1 = lineEnd.z;
    const aX2 = point.x;
    const aY2 = point.y;
    const aZ2 = point.z;
    const dx20 = aX2 - aX0;
    const dx10 = aX1 - aX0;
    const dy20 = aY2 - aY0;
    const dy10 = aY1 - aY0;
    const dz20 = aZ2 - aZ0;
    const dz10 = aZ1 - aZ0;
    const denominator = dx10 * dx10 + dy10 * dy10 + dz10 * dz10;
    let s;

    if (denominator !== 0) {
        s = (dx20 * dx10 + dy20 * dy10 + dz20 * dz10) / denominator;
    } else {
        s = 0;
    }

    if (clipToSegment) {
        // closest point should be on line segment
        if (s < 0) {
            s = 0;
        } else if (s > 1) {
            s = 1;
        }
    }

    const x = aX0 + s * dx10;
    const y = aY0 + s * dy10;
    const z = aZ0 + s * dz10;
    const dx = aX2 - x;
    const dy = aY2 - y;
    const dz = aZ2 - z;
    if (pointOnLineSFCT) {
        pointOnLineSFCT.x = x;
        pointOnLineSFCT.y = y;
        pointOnLineSFCT.z = z;
    }
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
};

/**
 * Returns the distance between the given origin and the orthogonal projection of the given point on the line starting
 * at the origin, with given direction
 */
export function distanceAlongDirection(
    point: Vector3,
    origin: Vector3,
    direction: Vector3
): number {
    const originToPoint = sub(point, origin);
    return scalar(originToPoint, normalize(direction));
}

/**
 * Returns the orthogonal projection of the vector a on the vector b.
 */
export function projectOnVector(a: Vector3, b: Vector3) {
    return scale(normalize(b), scalar(a, b) / Length(b));
}

/**
 * Returns the orthogonal projection of the given point on the infinite line defined by the given direction and point
 * on that line.
 */
export function projectPointOnLine(
    point: Vector3,
    pointOnLine: Vector3,
    lineDirection: Vector3
): Vector3 {
    return add(
        projectOnVector(sub(point, pointOnLine), lineDirection),
        pointOnLine
    );
}

/**
 * Returns the orthogonal projection of the given point on the plane defined by the given normal and point on plane.
 */
export function projectPointOnPlane(
    point: Vector3,
    planeNormal: Vector3,
    pointOnPlane: Vector3
): Vector3 {
    const subi = sub(point, pointOnPlane);
    const projection = projectOnVector(subi, planeNormal);
    return sub(point, projection);
}

/**
 * Returns the orthogonal projection of the given vector on the plane defined by the given normal.
 */
export function projectVectorOnPlane(
    vector: Vector3,
    planeNormal: Vector3
): Vector3 {
    return sub(vector, projectOnVector(vector, planeNormal));
}

/**
 * Returns the intersection point (if any) between the given ray and plane.
 */
export function rayPlaneIntersection(
    rayOrigin: Vector3,
    rayDirection: Vector3,
    planeNormal: Vector3,
    pointOnPlane: Vector3
): Vector3 | null {
    const numerator = scalar(sub(pointOnPlane, rayOrigin), planeNormal);
    const denominator = scalar(rayDirection, planeNormal);
    if (denominator !== 0) {
        //the plane and ray are not parallel
        const rayToPlaneDistance = numerator / denominator;
        if (rayToPlaneDistance < 0) {
            return null; //the intersection is behind the ray
        }
        return add(rayOrigin, scale(rayDirection, rayToPlaneDistance));
    } else if (numerator === 0) {
        //the origin of the ray is on the plane
        return copy(rayOrigin);
    } else {
        return null;
    }
}

/**
 * Returns the intersection point (if any) between the given ray and rectangle.
 * The given rectangle is assumed to be in the same (cartesian) reference as the given ray.
 */
export function rayRectangleIntersection(
    rayOrigin: Vector3,
    rayDirection: Vector3,
    rectangle: Polygon
): Vector3 | null {
    const edge1 = sub(rectangle.getPoint(1), rectangle.getPoint(0));
    const edge2 = sub(rectangle.getPoint(3), rectangle.getPoint(0));
    const intersectionPoint = rayPlaneIntersection(
        rayOrigin,
        rayDirection,
        cross(edge1, edge2),
        rectangle.getPoint(0)
    );
    if (intersectionPoint) {
        //check that the ray plane intersection is inside the rectangle
        const dotDir1 = scalar(
            edge1,
            sub(intersectionPoint, rectangle.getPoint(0))
        );
        const dotDir2 = scalar(
            edge2,
            sub(intersectionPoint, rectangle.getPoint(0))
        );
        if (
            0 <= dotDir1 &&
            dotDir1 <= scalar(edge1, edge1) &&
            0 <= dotDir2 &&
            dotDir2 <= scalar(edge2, edge2)
        ) {
            return intersectionPoint;
        }
    }
    return null;
}

export const toPoint = (
    reference: CoordinateReference | null,
    vec: Vector3
): Point => createPoint(reference, [vec.x, vec.y, vec.z]);

export function toPolyline(
    reference: CoordinateReference | null,
    vectors: Vector3[]
): Polyline {
    return createPolyline(
        reference,
        vectors.map(vec => toPoint(reference, vec))
    );
}
