import { getReference } from '@luciad/ria/reference/ReferenceProvider'
import {
  Affine3DTransformation,
  createChainedTransformation,
  createRotationTransformation,
  createTranslationTransformation,
} from '@luciad/ria/transformation/Affine3DTransformation'
import { Vector3 as IVector3 } from '@luciad/ria/util/Vector3'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'
import { PerspectiveCamera } from '@luciad/ria/view/camera/PerspectiveCamera'
import { ZoomController } from '@luciad/ria/view/controller/ZoomController'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { PcToolSupport } from '../alignment/PcToolSupport'
import { SliceSupport } from '../alignment/SliceSupport'
import { CompositeController } from '../common/controller/CompositeController'
import { DrawSliceController } from '../common/controller/DrawSliceController'
import { DrawZoomSliceController } from '../common/controller/DrawZoomSliceController'
import { NoopController } from '../common/controller/NoopController'
import { View } from '../dts/Alignment'
import { MOVE_CONFIRMED_EVENT } from '../geolocation/GeolocationMoveController'
import { SubViewPanController } from '../navigation/SubViewPanController'
import { AnchorHelper } from '../navigation/asset/AnchorHelper'
import { AssetDimension } from '../navigation/asset/AssetDimension'
import { AssetNavigationController } from '../navigation/asset/AssetNavigationController'
import {
  MOVE_EVENT,
  MOVE_START_EVENT,
  ParallelMoveController,
} from '../pages/ParallelMoveController'
import {
  ParallelRotationController,
  ROTATE_CONFIRMED_EVENT,
  ROTATE_EVENT,
  ROTATE_START_EVENT,
} from '../pages/ParallelRotateController'
import { Vector3 as Vector3Class } from '../util/Vector3'
import { negate, scale } from './Vector3Util'

const GEODETIC_REF = getReference('EPSG:4978')

export const applyTransformation = function (
  pointCloudLayers: TileSet3DLayer[],
  transformation_: Affine3DTransformation
) {
  pointCloudLayers.forEach((layer) => {
    layer.transformation = transformation_
  })
}
type OutputControllers = {}

export const initControllers = (
  map: WebGLMap,
  sideMap: WebGLMap,
  topMap: WebGLMap,
  pointCloudLayers: TileSet3DLayer[],
  transformationCallback: (t: Affine3DTransformation | null) => void,
  topViewSupport: PcToolSupport,
  sideViewSupport: PcToolSupport,
  sliceSupport: SliceSupport
) => {
  if (!map || !sideMap || !topMap || pointCloudLayers.length < 1) {
    throw Error(
      'Can not init controllers since the prerequisites are not yet loaded'
    )
  }

  sliceSupport.maps = { top: topMap, side: sideMap }

  const output: OutputControllers = {
    top: [],
    side: [],
  }

  let lastConfirmedTransformation: Affine3DTransformation | null = null
  let lastConfirmedOrigin: IVector3
  let lastConfirmedDirection: IVector3

  const assetDimension = new AssetDimension()
  assetDimension.recomputeDimension(pointCloudLayers[0].bounds)

  transformationCallback(pointCloudLayers[0].transformation!)

  const createSideController = (view: View) => {
    const currentMap = view === View.TOP ? topMap : sideMap
    const support = view === View.TOP ? topViewSupport : sideViewSupport
    const anchorHelper = new AnchorHelper(currentMap, assetDimension)
    const navigationController = new SubViewPanController(support, anchorHelper)
    const moveLayerController = new ParallelMoveController(support)

    moveLayerController.on(MOVE_START_EVENT, () => {
      lastConfirmedTransformation = pointCloudLayers[0].transformation!
    })
    moveLayerController.on(MOVE_EVENT, (movementVector: IVector3) => {
      if (lastConfirmedTransformation !== null) {
        applyTransformation(
          pointCloudLayers,
          createChainedTransformation(
            lastConfirmedTransformation,
            createTranslationTransformation(movementVector, {
              sourceReference: GEODETIC_REF,
            })
          )
        )
      }
    })
    moveLayerController.on(MOVE_CONFIRMED_EVENT, () => {
      transformationCallback(pointCloudLayers[0].transformation!)
      //console.log('Commit translation ', movementVector);
      //TODO: transform to model coordinates
      lastConfirmedTransformation = null
    })

    const rotateLayerController = new ParallelRotationController(support)

    rotateLayerController.on(ROTATE_START_EVENT, () => {
      lastConfirmedTransformation = pointCloudLayers[0].transformation!
      const camera = rotateLayerController.map!.camera as PerspectiveCamera
      lastConfirmedOrigin = camera.eye
      // This assumes that the view is one of the six view +-TOP, +-FRONT, +- RIGHT
      // The TOP view seems to be almost a top view so we adjust this here
      if (Math.abs(camera.forward.x) > 0.5)
        lastConfirmedDirection = new Vector3Class(1, 0, 0) // normalize(camera.forward);
      if (Math.abs(camera.forward.y) > 0.5)
        lastConfirmedDirection = new Vector3Class(0, 1, 0) // normalize(camera.forward);
      if (Math.abs(camera.forward.z) > 0.5)
        lastConfirmedDirection = new Vector3Class(0, 0, 1) // normalize(camera.forward);
    })
    rotateLayerController.on(ROTATE_EVENT, (rotation: number) => {
      if (lastConfirmedTransformation !== null) {
        const originNeg = negate(lastConfirmedOrigin)
        applyTransformation(
          pointCloudLayers,
          createChainedTransformation(
            lastConfirmedTransformation,
            createTranslationTransformation(originNeg, {
              sourceReference: GEODETIC_REF,
            }),
            createRotationTransformation(
              scale(lastConfirmedDirection, rotation),
              { sourceReference: GEODETIC_REF }
            ),
            createTranslationTransformation(lastConfirmedOrigin, {
              sourceReference: GEODETIC_REF,
            })
          )
        )

        //
        //console.log({"lastConfirmedOrigin": lastConfirmedOrigin});
        //console.log({"lastConfirmedDirection": lastConfirmedDirection});
        //console.log(camera.forward);
        //console.log({"originNeg": originNeg});
        //console.log({"rotation": rotation});
      }
    })
    rotateLayerController.on(ROTATE_CONFIRMED_EVENT, (rotation: number) => {
      transformationCallback(pointCloudLayers[0].transformation!)
      console.log('Commit rotation ', rotation)
      //TODO: transform to model coordinates & format as vector
      lastConfirmedTransformation = null
    })

    const controller = new CompositeController()
    controller.appendController(new ZoomController())
    controller.appendController(moveLayerController)
    controller.appendController(rotateLayerController)
    controller.appendController(navigationController)
    // this draws a slice for the zoom tracker
    /* controller.appendController(
      new DrawInvisibleSliceController(sliceSupport, view)
    ) */
    controller.appendController(new NoopController()) //to avoid the default RIA navigation behavior
    return controller
  }

  const mapcontroller = new CompositeController()
  const drawController = new DrawSliceController(sliceSupport)

  mapcontroller.appendController(new AssetNavigationController(assetDimension))
  mapcontroller.appendController(drawController)
  mapcontroller.appendController(new DrawZoomSliceController(sliceSupport))

  map.controller = mapcontroller
  topMap.controller = createSideController(View.TOP)
  sideMap.controller = createSideController(View.SIDE)

  return output
}
