import {Vector3} from '@luciad/ria/util/Vector3';
import {PointCoordinates} from '@luciad/ria/shape/PointCoordinate';
import {PerspectiveCamera} from '@luciad/ria/view/camera/PerspectiveCamera';
import {AssetDimension} from './AssetDimension';
import {add, cross, normalize, rotateAroundAxis, sub,} from '../../util/Vector3Util';
import {Map} from '@luciad/ria/view/Map';
import {clamp} from "../../common/util/Math";

/**
 * Implements various rotation strategies
 * - rotateAroundPivot
 * - rotateAroundCameraEye
 */
export class NavigationRotation {
  private readonly _mouseVerticalOrbitScaling = 0.15;
  private readonly _mouseHorizontalOrbitScaling = 0.1;
  private readonly _mouseRotationScaling = 0.1;
  private readonly _assetDimension: AssetDimension;
  private _mouseStart: PointCoordinates | null;

  constructor(assetDimension: AssetDimension) {
    this._assetDimension = assetDimension;
    this._mouseStart = null;
  }

  /**
   * Rotate around the pivot point.
   */
  rotateAroundPivot(map: Map, pivot: Vector3, viewPosition: number[]): void {
    if (this._mouseStart === null) {
      this._mouseStart = viewPosition as PointCoordinates;
    }

    const [x, y] = viewPosition;
    const [x0, y0] = this._mouseStart;

    const yawDelta = this._mouseHorizontalOrbitScaling * (x - x0);
    let pitchDelta = this._mouseVerticalOrbitScaling * (y0 - y);

    this._mouseStart = [x, y];

    const camera = map.camera as PerspectiveCamera;
    const { forward } = camera;
    const { eye, pitch, yaw } = camera.asLookFrom();

    const newPitch = pitch + pitchDelta;
    if (newPitch <= -88 || newPitch >= 88) {
      // prevent the camera from somersaulting
      pitchDelta = 0;
    }

    const diffVec = sub(eye, pivot);
    const vertical = normalize(eye);
    const horizontal = cross(vertical, forward);
    const rotatedHVector = rotateAroundAxis(diffVec, vertical, -yawDelta);
    const rotatedVVector = rotateAroundAxis(
      rotatedHVector,
      horizontal,
      -pitchDelta
    );
    const newEye = add(pivot, rotatedVVector);

    if (this._assetDimension.isInAssetWorld(newEye)) {
      map.camera = camera.lookFrom({
        eye: newEye,
        yaw: yaw + yawDelta,
        pitch: pitch + pitchDelta,
        roll: 0,
      });
    }
  }

  /**
   * First person rotation.
   */
  rotateAroundCameraEye(map: Map, viewPosition: number[]): void {
    if (this._mouseStart === null) {
      this._mouseStart = viewPosition as PointCoordinates;
    }

    const [x, y] = viewPosition;
    const [x0, y0] = this._mouseStart;
    const dx = (x0 - x) * this._mouseRotationScaling;
    const dy = (y0 - y) * this._mouseRotationScaling;

    const camera = map.camera as PerspectiveCamera;

    const lookFromCamera = camera.asLookFrom();
    lookFromCamera.yaw = (lookFromCamera.yaw + dx) % 360;
    lookFromCamera.pitch = clamp(lookFromCamera.pitch - dy, -89, 89);
    map.camera = camera.lookFrom(lookFromCamera);

    this._mouseStart = [x, y];
  }

  reset() {
    this._mouseStart = null;
  }
}
