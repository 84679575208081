import {GMLCodec} from "@luciad/ria/model/codec/GMLCodec";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {Store} from "@luciad/ria/model/store/Store";
import {UrlStore} from "@luciad/ria/model/store/UrlStore";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {isString} from "../util/Lang";
import {LocalFileStore} from "../store/LocalFileStore";

export const GMLDataLoader = {

  /**
   * Creates a layer for the given URL of File, assuming it is GML content.
   *
   * You can style GML data using a FeaturePainter.
   * See for example the "EarthquakesPainter" in the <i>Data Formats<i> sample.
   *
   * Summary:
   * - Create a store to access the data, for example @luciad/ria/model/store/UrlStore
   * - Use @luciad/ria/model/codec/GMLCodec in the store
   * - Create a @luciad/ria/model/feature/FeatureModel for the store
   * - Create a @luciad/ria/view/feature/FeatureLayer
   * - Create a @luciad/ria/view/feature/FeaturePainter to style the layer (optional)
   */
  createLayer: (layerName: string, urlOrFile: string | File, painter?: FeaturePainter,
                swapAxes?: string[]): FeatureLayer => {
    let store: Store;
    if (isString(urlOrFile)) {
      store = new UrlStore({
        target: urlOrFile,
        codec: new GMLCodec({swapAxes: swapAxes})
      });
    } else {
      store = new LocalFileStore(urlOrFile, new GMLCodec({swapAxes: swapAxes}));
    }
    const featureModel = new FeatureModel(store);
    return new FeatureLayer(featureModel, {
      label: layerName + " (GML)",
      selectable: true,
      painter: painter
    });
  }
};
