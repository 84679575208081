import {GeoJsonCodec} from "@luciad/ria/model/codec/GeoJsonCodec";
import {FeatureModel} from "@luciad/ria/model/feature/FeatureModel";
import {UrlStore} from "@luciad/ria/model/store/UrlStore";
import {FeatureLayer} from "@luciad/ria/view/feature/FeatureLayer";
import {FeaturePainter} from "@luciad/ria/view/feature/FeaturePainter";
import {isString} from "../util/Lang";
import {LocalFileStore} from "../store/LocalFileStore";

export const GeoJsonDataLoader = {
  /**
   * Creates a layer for the given URL of File, assuming it is GeoJson content.
   *
   * You can style GeoJson data using a FeaturePainter.
   * See for example the <i>Vector Data<i> sample.
   *
   * Summary:
   * - Create a store to access the data, for example @luciad/ria/model/store/UrlStore
   * - Use @luciad/ria/model/codec/GeoJsonCodec in the store (this is the default)
   * - Create a @luciad/ria/model/feature/FeatureModel for the store
   * - Create a @luciad/ria/view/feature/FeatureLayer
   * - Create a @luciad/ria/view/feature/FeaturePainter to style the layer (optional)
   */
  createLayer: (layerName: string, urlOrFile: string | File, painter?: FeaturePainter): FeatureLayer => {
    let store: UrlStore | LocalFileStore;
    if (isString(urlOrFile)) {
      store = new UrlStore({
        target: urlOrFile,
        codec: new GeoJsonCodec({
          generateIDs: true
        })
      });
    } else {
      store = new LocalFileStore(urlOrFile, new GeoJsonCodec({
        generateIDs: true
      }));
    }
    const featureModel = new FeatureModel(store);
    return new FeatureLayer(featureModel, {
      label: layerName + " (JSON)",
      selectable: true,
      painter: painter
    });
  }
};