import {
  AddDataManager,
  TRACKED_ASSET_UPDATED_EVENT,
  TrackedAsset,
} from './AddDataManager';
import React, {useEffect, useState} from 'react';
import {IconButton, Inline, Text, Tooltip} from '@digitalreality/ui';
import {ProcessingAsset, useProcessingAssets} from './useProcessingAssets';
import {ProcessingStateIcon} from './ProcessingStateIcon';
import {ProcessingState} from './AssetPersistanceUtil';
import {addAssetToMap} from './AssetLayerUtil';
import {Map} from '@luciad/ria/view/Map';
import {GeolocationSupport} from '../../geolocation/GeolocationSupport';
import {AddAltIcon} from '@digitalreality/ui-icons';
import {CircularProgressWithLabel} from '../../components/CircularProgressWithLabel';

interface Props {
  map: Map;
  manager: AddDataManager;
  geolocationSupport: GeolocationSupport;
}

/**
 * Component that lists the upload and processing progress of uploaded files
 */
export const TrackedAssetsList = ({
  map,
  manager,
  geolocationSupport,
}: Props) => {
  const [uploadingAssets, setUploadingAssets] = useState<TrackedAsset[]>([]);
  const [uploadedAssets, setUploadedAssets] = useState<TrackedAsset[]>([]);
  const processingStates = useProcessingAssets(
    uploadedAssets.map((trackedAsset) => ({
      groupedAssetId: trackedAsset.asset.groupedAssetId,
      name: trackedAsset.asset.assetName,
    }))
  );

  useEffect(() => {
    function updateAssets() {
      setUploadingAssets(
        Object.values(manager.trackedAssets).filter(({confirmed}) => !confirmed)
      );
      setUploadedAssets(
        Object.values(manager.trackedAssets).filter(({confirmed}) => confirmed)
      );
    }

    manager.on(TRACKED_ASSET_UPDATED_EVENT, updateAssets);
    updateAssets();
  }, [manager]);

  function loadAsset(asset: ProcessingAsset) {
    addAssetToMap(map, asset, geolocationSupport).then(() =>
      manager.untrack(asset.groupedAssetId)
    );
  }

  return (
    <>
      {uploadingAssets.length > 0 && (
        <>
          <Text size={0} color="text.secondary">
            Currently Uploading
          </Text>
          {uploadingAssets.map((asset) => (
            <Inline
              key={asset.asset.groupedAssetId}
              width="calc(100% - 10px)"
              alignItems="center"
            >
              <Text noWrap size={1} sx={{flex: 1}}>
                {asset.asset.assetName}
              </Text>
              <CircularProgressWithLabel
                size={16}
                value={asset.progress * 100}
              />
            </Inline>
          ))}
        </>
      )}
      {processingStates.length > 0 && (
        <>
          <Text size={0} color="text.secondary">
            Being processed on HxDR
          </Text>
          {processingStates
            .sort(({groupedAssetId: assetId1}, {groupedAssetId: assetId2}) =>
              assetId1.localeCompare(assetId2)
            )
            .map((asset) => (
              <Inline
                key={asset.groupedAssetId}
                width="calc(100% - 10px)"
                alignItems="center"
              >
                <Text noWrap size={1} sx={{flex: 1}}>
                  {asset.name}
                </Text>
                {asset.state === ProcessingState.READY ? (
                  <Tooltip title="Add to map">
                    <IconButton onClick={() => loadAsset(asset)}>
                      <AddAltIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <ProcessingStateIcon
                    state={asset.state}
                    subStates={asset.subStates}
                  />
                )}
              </Inline>
            ))}
        </>
      )}
    </>
  );
};
