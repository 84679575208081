import {
  getAssetLayerRenderableAddresses,
  ProcessingState,
} from './AssetPersistanceUtil';
import { getApolloClient } from '../../util/PersistanceUtil';
import { getResolvedBearerToken } from '../../authentication/AuthenticationUtil';
import { LayerGroup } from '@luciad/ria/view/LayerGroup';
import { loadLayerAddress } from '../../util/LayerAddressLoader';
import { ASSET_LAYER_GROUP_ID } from '../LayerUtil';
import { getFitBounds } from '../../common/util/FitBoundsUtil';
import { ProcessingAsset } from './useProcessingAssets';
import { Map } from '@luciad/ria/view/Map';
import { GeolocationSupport } from '../../geolocation/GeolocationSupport';
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer';
import { createTransformationFromGeoLocation } from '@luciad/ria/transformation/Affine3DTransformation';
import { createPoint } from '@luciad/ria/shape/ShapeFactory';
import { getReference } from '@luciad/ria/reference/ReferenceProvider';
import { addGrid } from '../../util/grid';
import { WebGLMap } from '@luciad/ria/view/WebGLMap';
import { HSPCTilesModel } from '@luciad/ria/model/tileset/HSPCTilesModel';
import {
  multiply,
  defaultColor,
  color,
} from '@luciad/ria/util/expression/ExpressionFactory';
import { PointCloudPointShape } from '@luciad/ria/view/style/PointCloudPointShape';
import { ScalingMode } from '@luciad/ria/view/style/ScalingMode';

/**
 * Adds a given asset to the map.
 * If the asset is georeferenced, it will just be added as is.
 * Otherwise, it will be added to the geolocationSupport, which will load all existing geolocations and allow users to
 * create and edit the geolocations of the given asset.
 */
/*export async function addAssetToMap(
  map: Map,
  asset: ProcessingAsset,
  geolocationSupport: GeolocationSupport
) {
  if (asset.state !== ProcessingState.READY) {
    throw new Error('Only fully ready assets can be loaded');
  }
  const {georeferenced, addresses} = await getAssetLayerRenderableAddresses(
    getApolloClient(getResolvedBearerToken()),
    asset.groupedAssetId
  );
  console.log('GEOREF: ', georeferenced)

   if (!georeferenced) {
    geolocationSupport.addAsset(asset.groupedAssetId);
  } else {
    const promises: Promise<void>[] = [];
    const layerGroup = new LayerGroup({label: asset.name});
    for (const address of addresses) {
      if (address.type === 'PANORAMIC') {
        console.log(
          'Ignoring panoramic address, support for these addresses will be added later'
        );
        continue;
      }
      promises.push(
        loadLayerAddress(address, getResolvedBearerToken(), {
          label: asset.name + '_' + address.type,
        }).then((layer) => {
          layerGroup.addChild(layer);
        })
      );
    }
    await Promise.all(promises);
    map.layerTree.findLayerGroupById(ASSET_LAYER_GROUP_ID).addChild(layerGroup);
    getFitBounds(layerGroup).then((bounds) => {
      if (bounds) {
        map.mapNavigator.fit({bounds});
      }
    });
  } 
} */

export async function addAssetToMap(
  map: WebGLMap | Map,
  asset: ProcessingAsset
) {
  if (asset.state !== ProcessingState.READY) {
    throw new Error('Only fully ready assets can be loaded');
  }
  const { addresses } = await getAssetLayerRenderableAddresses(
    getApolloClient(getResolvedBearerToken()),
    asset.groupedAssetId,
  );

  const promises: Promise<void>[] = [];
  for (const address of addresses) {
    if (address.type === 'HSPC') {
      console.log('AssetLayerUtil.ts (84) # address', address);
      promises.push(
        loadLayerAddress(address, getResolvedBearerToken(), {
          label: asset.name + '_' + address.type,
        }).then((layer) => {
          const newLayer = layer as TileSet3DLayer;
          newLayer.transformation = createTransformationFromGeoLocation(
            createPoint(getReference('EPSG:4326'), [0, 0, 100]),
          );
          map.layerTree.addChild(newLayer);
          getFitBounds(newLayer).then((bounds) => {
            if (bounds) {
              map.mapNavigator.fit({ bounds });
            }
          });
        })
      );
    }
  }
  await Promise.all(promises);
}

export const retriveHspcAsset = async (
  asset: ProcessingAsset,
  color_: string
) => {
  if (asset.state !== ProcessingState.READY) {
    throw new Error('Only fully ready assets can be loaded');
  }
  const { addresses } = await getAssetLayerRenderableAddresses(
    getApolloClient(getResolvedBearerToken()),
    asset.groupedAssetId
  );
  for (const address of addresses) {
    if (address.type === 'HSPC') {
      loadLayerAddress(address, getResolvedBearerToken(), {
        label: asset.name + '_' + address.type,
      }).then((layer) => {
        /* const newLayer = layer as TileSet3DLayer;
        newLayer.transformation = createTransformationFromGeoLocation(
          createPoint(getReference('EPSG:4326'), [0, 0, 100])
        );
        return newLayer; */
        const newLayer = {
          ...layer,
          qualityFactor: 0.3,
          transformation: createTransformationFromGeoLocation(
            createPoint(getReference('EPSG:4326'), [0, 0, 100])
          ),
          pointCloudStyle: {
            pointShape: PointCloudPointShape.DISC,
            pointSize: {
              mode: ScalingMode.WORLD_SIZE,
              minimumPixelSize: 2,
              worldSize: 0.02,
            },
            gapFill: 0,
            colorExpression: multiply(defaultColor(), color(color_)),
          },
        };
        console.log('NewLayer: ', newLayer);
        return layer as TileSet3DLayer;
      });
    }
  }
};

/* export const makeLayer = (model: HSPCTilesModel, color_: string) => {
  return new TileSet3DLayer(model, {
    qualityFactor: 0.3,
    transformation: createTransformationFromGeoLocation(
      createPoint(getReference('EPSG:4326'), [0, 0, 100])
    ),
    pointCloudStyle: {
      pointShape: PointCloudPointShape.DISC,
      pointSize: {
        mode: ScalingMode.WORLD_SIZE,
        minimumPixelSize: 2,
        worldSize: 0.02,
      },
      gapFill: 0,
      colorExpression: multiply(defaultColor(), color(color_)),
    },
  });
}; */

export const addLayerToMap = (map: WebGLMap, layer: TileSet3DLayer) => {
  map.layerTree.addChild(layer);
  getFitBounds(layer).then((bounds) => {
    if (bounds) {
      map.mapNavigator.fit({ bounds });
    }
  });
};
