import React, { useState } from 'react'

import { ProcessingAsset } from '../../layers/assets/useProcessingAssets'
// src/layers/assets/useProcessingAssets.ts

export interface IProject {
  hspc1?: ProcessingAsset
  hspc2?: ProcessingAsset
}

export interface Iurl {
  address: string
  type: string
}

export interface IManualUrl {
  url1?: Iurl
  url2?: Iurl
}

export interface IAppData {
  currentProject?: IProject
  manualLoad?: boolean
  manualUrl?: IManualUrl
}

export interface IAppContext {
  data?: IAppData
  setData: (d: IAppData) => void
}

export const AppContext = React.createContext<IAppContext>(undefined!)

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [data, setData] = useState<IAppData>({})
  return (
    <AppContext.Provider value={{ data, setData }}>
      {children}
    </AppContext.Provider>
  )
}
