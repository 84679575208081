import { Affine3DTransformation } from '@luciad/ria/transformation/Affine3DTransformation'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { useRef, useState } from 'react'
import { applyTransformation } from '../../util/controllers'
import { useKeyboard } from '../../util/react-keyboard-event'

// https://github.com/vgaborabs/react-keyboard-event
export const useUndo = (pointCloud2: TileSet3DLayer[]) => {
  const history = useRef<Affine3DTransformation[]>([])
  const undone = useRef<Affine3DTransformation[]>([])
  // TODO find a better way to handle the button visibility
  const [buttonActive, setButtonActive] = useState<{
    undo: boolean
    redo: boolean
    reset: boolean
  }>({
    undo: false,
    redo: false,
    reset: false,
  })

  const buttonActivation = () => {
    const undo = history.current.length > 1
    const redo = undone.current.length > 0
    const reset = undo || redo
    setButtonActive({
      undo,
      redo,
      reset,
    })
    console.log('BUTTON CURRENT: ', buttonActive)
  }

  const undo = () => {
    console.info('[UNDO] length', history.current.length)
    if (history.current.length === 1) return
    const removedState = history.current.pop()
    const newState = history.current[history.current.length - 1]
    // console.info('[UNDO] apply previous state', !!newSqtate)
    if (!newState) return
    applyTransformation(pointCloud2, newState)
    if (removedState) {
      undone.current.push(removedState)
      // console.info('[REDO] length', undone.current.length)
    }
    buttonActivation()
  }

  const redo = () => {
    console.info('[REDO] length', undone.current.length)
    const undoneState = undone.current.pop()
    // console.info('apply undone state', !!undoneState)
    if (undoneState) {
      applyTransformation(pointCloud2, undoneState)
      history.current.push(undoneState)
      // console.info('[REDO] history length', history.current.length)
      // console.info('[REDO] undone length', undone.current.length)
    }
    buttonActivation()
  }

  useKeyboard({
    listeners: [
      {
        key: { key: 'z', meta: true },
        callback: undo,
      },
      {
        key: { key: 'z', ctrl: true },
        callback: undo,
      },
      {
        key: { key: 'z', meta: true, shift: true },
        callback: redo,
      },
      {
        key: { key: 'z', ctrl: true, shift: true },
        callback: redo,
      },
    ],
    shouldListen: true,
  })

  const transformationCallback = (t: Affine3DTransformation | null) => {
    if (!t) return
    history.current.push(t)
    undone.current = []
    console.log('commit translation', history.current.length)
    buttonActivation()
  }

  const reset = () => {
    console.info('[RESET] initial position')
    history.current = [history.current[0]]
    undone.current = []
    // transformationCallback(history.current[0])
    applyTransformation(pointCloud2, history.current[0])
    buttonActivation()
  }

  return {
    transformationCallback,
    undo,
    redo,
    reset,
    buttonActive,
  }
}
