import {Map} from '@luciad/ria/view/Map';
import {GeoJsonDataLoader} from '../../common/layer/GeoJsonDataLoader';
import {GMLDataLoader} from '../../common/layer/GMLDataLoader';
import {ASSET_LAYER_GROUP_ID} from '../LayerUtil';
import {KMLDataLoader} from '../../common/layer/KMLDataLoader';
import {LayerTreeNode} from '@luciad/ria/view/LayerTreeNode';
import {
  LibUpload,
  Metadata,
  PathResolverOptions,
  UploaderOptions,
} from '@digitalreality/libupload';
import {ApolloClient} from '@apollo/client';
import {
  PresignedUrlDocument,
  TPresignedUrlQuery,
  TPresignedUrlQueryVariables,
} from '../../graphql/gen/graphql';
import {
  addFile,
  confirmAsset,
  confirmFile,
  createAsset,
} from './AssetPersistanceUtil';
import {EventedSupport} from '@luciad/ria/util/EventedSupport';

const GEOJSON_FORMATS = ['.json', '.geojson'];
const KML_FORMATS = ['.kml', '.kmz'];
const GML_FORMATS = ['.gml', '.gml2', '.gml31', '.gml32', '.xml'];
export const LOCAL_FORMATS = [
  ...GEOJSON_FORMATS,
  ...KML_FORMATS,
  ...GML_FORMATS,
];
export const HXDR_FORMATS = ['.e57', '.ifc', '.b2g', '.glb'];
export const ALL_FORMATS = [...new Set([...LOCAL_FORMATS, ...HXDR_FORMATS])];

/**
 * Metadata that defines what file currently is being uploaded
 */
export type UploadMetadata = {
  fileId: string;
  groupedAssetId: string;
  assetName: string;
};

export function isOfFormat(fileName: string, formats: string[]): boolean {
  let match = false;
  for (const format of formats) {
    if (fileName.endsWith(format)) {
      match = true;
      break;
    }
  }
  return match;
}

/**
 * Metadata and current upload progress of an asset that is getting uploaded.
 */
export interface TrackedAsset {
  asset: UploadMetadata;
  progress: number;
  confirmed: boolean;
}

async function resolvePreSignedUrl(
  apolloClient: ApolloClient<object>,
  options: PathResolverOptions<UploadMetadata>
): Promise<string> {
  const res = await apolloClient.query<
    TPresignedUrlQuery,
    TPresignedUrlQueryVariables
  >({
    query: PresignedUrlDocument,
    variables: {
      chunkIndex: options.chunkIndex + 1,
      fileId: options.metadata?.fileId || '',
    },
    fetchPolicy: 'no-cache',
  });
  if (!res.data.getMultipartUploadURL?.uploadUrl) {
    throw Error();
  }
  return res.data.getMultipartUploadURL.uploadUrl;
}

async function uploadFileChunk(
  options: UploaderOptions<Metadata>
): Promise<string> {
  const res = await fetch(options.url, {
    signal: options.abortController.signal,
    method: 'PUT',
    body: options.blob,
    headers: {
      'Content-Type': options.file.type,
      'X-Multipart-Chunk': `${options.chunkIndex + 1}`,
    },
  });

  return res.headers.get('ETag')?.replace(/"/g, '') ?? '';
}

export const TRACKED_ASSET_UPDATED_EVENT = 'TrackedAssetUpdate';

/**
 * Support class that helps with adding local files to the map.
 * Either by loading them directly as a layer or by uploading them to HxDR so that they can be processed into layer
 * services that can later be connected to with LuciadRIA.
 */
export class AddDataManager {
  private readonly _map: Map;
  private readonly _apolloClient: ApolloClient<object>;
  private readonly _uploadClient: LibUpload<UploadMetadata>;
  private readonly _eventedSupport: EventedSupport = new EventedSupport(
    [TRACKED_ASSET_UPDATED_EVENT],
    true
  );
  private _trackedAssets: {[groupedAssetId: string]: TrackedAsset} = {};

  constructor(map: Map, apolloClient: ApolloClient<object>) {
    this._map = map;
    this._apolloClient = apolloClient;

    this._uploadClient = new LibUpload({
      resolver: (options) => resolvePreSignedUrl(this._apolloClient, options),
      uploader: (options) => uploadFileChunk(options),
    });

    this._uploadClient.on('upload-progress', (eventData) => {
      const groupedAssetId = this._uploadClient
        .getUploadQueue()
        .getUpload(eventData.handle)!
        .getMetadata().groupedAssetId;
      this._trackedAssets[groupedAssetId].progress = eventData.percentage;
      this._eventedSupport.emit(
        TRACKED_ASSET_UPDATED_EVENT,
        this._trackedAssets[groupedAssetId]
      );
    });
  }

  addLocalFile(file: File) {
    if (!isOfFormat(file.name, LOCAL_FORMATS)) {
      throw new Error(
        `"${
          file.name
        }" does not end with a supported format extension for local loading. Supported extensions are [${LOCAL_FORMATS.join(
          ', '
        )}]`
      );
    }

    let layer: LayerTreeNode;
    if (isOfFormat(file.name, GEOJSON_FORMATS)) {
      layer = GeoJsonDataLoader.createLayer(file.name, file);
    } else if (isOfFormat(file.name, GML_FORMATS)) {
      layer = GMLDataLoader.createLayer(file.name, file, undefined, [
        'EPSG:4326',
      ]);
    } else if (isOfFormat(file.name, KML_FORMATS)) {
      layer = KMLDataLoader.createLayer(file.name, URL.createObjectURL(file));
    } else {
      throw new Error(
        `${file.name} has an extension that will be supported but is not implemented yet`
      );
    }

    this._map.layerTree
      .findLayerGroupById(ASSET_LAYER_GROUP_ID)
      .addChild(layer);
  }

  async uploadHxDRFile(file: File, folderId: string) {
    if (!isOfFormat(file.name, HXDR_FORMATS)) {
      throw new Error(
        `"${
          file.name
        }" does not end with a supported format extension for uploading to HxDR. Supported extensions are [${LOCAL_FORMATS.join(
          ', '
        )}]`
      );
    }
    const groupedAssetId = await createAsset(
      this._apolloClient,
      file.name,
      folderId
    );
    const fileId = await addFile(this._apolloClient, groupedAssetId, file);
    const metadata = {fileId, assetName: file.name, groupedAssetId};
    const handle = this._uploadClient.upload(file, metadata);
    const trackedAsset = {asset: metadata, progress: 0, confirmed: false};
    this._eventedSupport.emit(TRACKED_ASSET_UPDATED_EVENT, trackedAsset);
    this._trackedAssets[groupedAssetId] = trackedAsset;
    await this._uploadClient.awaitFinishedUpload(handle);
    const uploadedChunks = this._uploadClient
      .getUploadQueue()
      .getUpload(handle)!
      .getParts()
      .getFinishedParts();
    await confirmFile(this._apolloClient, fileId, uploadedChunks);
    await confirmAsset(this._apolloClient, groupedAssetId);
    this._trackedAssets[groupedAssetId].confirmed = true;
    this._eventedSupport.emit(
      TRACKED_ASSET_UPDATED_EVENT,
      this._trackedAssets[groupedAssetId]
    );
  }

  get trackedAssets() {
    return this._trackedAssets;
  }

  untrack(groupedAssetId: string) {
    delete this._trackedAssets[groupedAssetId];
    this._eventedSupport.emit(TRACKED_ASSET_UPDATED_EVENT, null);
  }

  on(
    event: typeof TRACKED_ASSET_UPDATED_EVENT,
    callback: (trackedAsset: TrackedAsset | null) => void
  ) {
    return this._eventedSupport.on(event, callback);
  }
}
