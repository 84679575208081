import { v4 as uuidv4 } from 'uuid'

/**
 * When this file is imported, it tries to authenticate to HxDR.
 * If authentication was successful, the `getBearerToken` and `getResolvedBearerToken` functions will return a valid bearer token.
 * Otherwise, you should redirect the user to the URL generated by `generateLoginRedirectEndPoint`.
 */

const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN'
const CODE_VERIFIER_PREFIX = 'CODE_VERIFIER_'

const CLIENT_ID = process.env['CLIENT_ID'] as string
const REDIRECT_URI = process.env['REDIRECT_URI'] as string
const LOGOUT_URI = REDIRECT_URI //this is a single-page app

const AUTH_DOMAIN = 'hxdr-uat-dr-userpool-cgn.auth.eu-west-1.amazoncognito.com'
const AUTHORIZATION_ENDPOINT = `https://${AUTH_DOMAIN}/oauth2/authorize`
const TOKEN_ENDPOINT = `https://${AUTH_DOMAIN}/oauth2/token`
const LOGOUT_ENDPOINT = `https://${AUTH_DOMAIN}/logout`

function base64UrlEncode(str: ArrayBuffer) {
  return btoa(String.fromCharCode(...new Uint8Array(str)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '')
}

async function sha256(str: string): Promise<ArrayBuffer> {
  if (!crypto.subtle)
    alert('This application should run in a secure context (HTTPS)')
  return await crypto.subtle.digest('SHA-256', new TextEncoder().encode(str))
}

/**
 * Returns a URL to which you should redirect the user if they are currently not authenticated to HxDR.
 * On the page hosted on that URL, the user will be able to authenticate and be redirected to this app with additional
 * query parameters, which will be parsed in order to request a valid bearer token.
 */
export async function generateLoginRedirectEndPoint() {
  const state = uuidv4()
  const codeVerifier = uuidv4()
  const codeChallenge = base64UrlEncode(await sha256(codeVerifier))

  window.sessionStorage.setItem(CODE_VERIFIER_PREFIX + state, codeVerifier)
  return `${AUTHORIZATION_ENDPOINT}?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${state}&code_challenge=${codeChallenge}&code_challenge_method=S256`
}

export function logOut() {
  window.localStorage.removeItem(REFRESH_TOKEN_KEY)
  window.location.replace(
    `${LOGOUT_ENDPOINT}?client_id=${CLIENT_ID}&logout_uri=${LOGOUT_URI}`
  )
}

async function fetchTokensUsingCode(code: string, codeVerifier: string) {
  const response = await fetch(TOKEN_ENDPOINT, {
    method: 'POST',
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: CLIENT_ID,
      code,
      redirect_uri: REDIRECT_URI,
      code_verifier: codeVerifier,
    }),
  })

  if (response.ok) {
    const { access_token: accesToken, refresh_token: refreshToken } =
      await response.json()
    window.localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    return accesToken
  } else {
    console.error('error when fetching tokens')
    throw new Error(JSON.stringify(await response.json(), null, 2))
  }
}

async function fetchTokensUsingRefresh(refreshToken: string) {
  const response = await fetch(TOKEN_ENDPOINT, {
    method: 'POST',
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: CLIENT_ID,
      refresh_token: refreshToken,
    }),
  })

  if (response.ok) {
    return (await response.json()).access_token
  } else {
    console.error('error when refreshing token')
    throw new Error(JSON.stringify(await response.json(), null, 2))
  }
}

let idTokenPromise: Promise<string | null>
let resolvedBearerToken: string | null = null
const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY)
const urlParams = new URLSearchParams(window.location.search)
const codeParam = urlParams.get('code')
const stateParam = urlParams.get('state')
const codeVerifier = window.sessionStorage.getItem(
  CODE_VERIFIER_PREFIX + stateParam
)

if (refreshToken) {
  // user has authenticated in the past => use the saved refresh token to fetch new tokens
  idTokenPromise = fetchTokensUsingRefresh(refreshToken).catch((e) => {
    console.error(e)
    window.localStorage.removeItem(REFRESH_TOKEN_KEY)
    return null
  })
} else if (codeParam && codeVerifier) {
  // user has authenticated using cognito and is redirected back to the application => use the code to fetch tokens
  idTokenPromise = fetchTokensUsingCode(codeParam, codeVerifier)
  window.history.replaceState(null, document.title, window.location.pathname)
} else {
  // no authentication data was found
  idTokenPromise = Promise.resolve(null)
}

/**
 * Returns a promise that either resolves to a bearer token or null, depending on whether you are logged in or not.
 * The returned promise uses the local storage and url parameters of the current page to define whether the user was
 * previously already logged in or was just redirected from the Cognito login page.
 */
export async function getBearerToken(): Promise<string | null> {
  const result = await idTokenPromise
  if (result) {
    const token = 'Bearer ' + result
    resolvedBearerToken = token
    return token
  } else {
    return result
  }
}

/**
 * Returns the bearer token that was resolved by the promise returned by {@link getBearerToken}.
 * If the bearer token is not yet resolved or the promise resolved to null, this method throws an error.
 */
export function getResolvedBearerToken(): string {
  if (!resolvedBearerToken) {
    throw new Error(
      'getResolvedBearerToken should only be called once the bearer token has been previously resolved'
    )
  }
  return resolvedBearerToken
}
