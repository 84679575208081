import { useCallback, useContext } from 'react'
import { ProcessingAsset } from '../../layers/assets/useProcessingAssets'
import { AppContext, IProject, Iurl } from './AppContext'

export const useCurrentProject = () => {
  const { data, setData } = useContext(AppContext)

  const currentProject = { ...data?.currentProject }
  const manualLoad = data?.manualLoad
  const manualUrl = { ...data?.manualUrl }
  const setCurrentProject = useCallback(
    (p: IProject) => {
      setData({
        ...data,
        currentProject: p,
      })
    },
    [setData, data]
  )
  const setHspc1 = useCallback(
    (a: ProcessingAsset) => {
      const p = currentProject || {}
      p.hspc1 = a
      setData({
        ...data,
        currentProject: p,
      })
    },
    [setData, currentProject, data]
  )
  const setHspc2 = useCallback(
    (a: ProcessingAsset) => {
      const p = currentProject || {}
      p.hspc2 = a
      setData({
        ...data,
        currentProject: p,
      })
    },
    [setData, currentProject, data]
  )
  const setManualLoad = useCallback(
    (isManual: boolean) => {
      setData({
        ...data,
        manualLoad: isManual,
      })
    },
    [setData, currentProject, data]
  )

  const setManualUrl1 = useCallback(
    (a: Iurl) => {
      const p = manualUrl || {}
      p.url1 = a
      setData({
        ...data,
        manualUrl: p,
      })
    },
    [setData, manualUrl, data]
  )

  const setManualUrl2 = useCallback(
    (a: Iurl) => {
      const p = manualUrl || {}
      p.url2 = a
      setData({
        ...data,
        manualUrl: p,
      })
    },
    [setData, manualUrl, data]
  )

  return {
    currentProject,
    manualLoad,
    manualUrl,
    setCurrentProject,
    setHspc1,
    setHspc2,
    setManualLoad,
    setManualUrl1,
    setManualUrl2,
  }
}
