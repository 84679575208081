import { Map } from '@luciad/ria/view/Map';
import { LayerGroup } from '@luciad/ria/view/LayerGroup';

export const CATALOG_LAYER_GROUP_ID = 'Catalog Layers';
export const ASSET_LAYER_GROUP_ID = 'Asset Layers';
export const ANNOTATION_LAYER_GROUP_ID = 'Annotation Layers';
export const BACKGROUND_LAYER_GROUP_ID = 'Background Layers';

export const LAYER_GROUP_ORDER = [
  BACKGROUND_LAYER_GROUP_ID,
  CATALOG_LAYER_GROUP_ID,
  ASSET_LAYER_GROUP_ID,
  ANNOTATION_LAYER_GROUP_ID,
];

/**
 * Initialize layer groups according to the {@link LAYER_GROUP_ORDER} object on the given map
 */
export function initializeLayerGroups(map: Map): void {
  for (const id of LAYER_GROUP_ORDER) {
    const group = new LayerGroup({ id, label: id });
    map.layerTree.addChild(group, 'top');
  }
}
