import { Point } from '@luciad/ria/shape/Point'
import { LocationMode } from '@luciad/ria/transformation/LocationMode'
import { Handle } from '@luciad/ria/util/Evented'
import { EventedSupport } from '@luciad/ria/util/EventedSupport'
import { Vector3 as IVector3 } from '@luciad/ria/util/Vector3'
import { PerspectiveCamera } from '@luciad/ria/view/camera/PerspectiveCamera'
import { Controller } from '@luciad/ria/view/controller/Controller'
import { HandleEventResult } from '@luciad/ria/view/controller/HandleEventResult'
import { GestureEvent } from '@luciad/ria/view/input/GestureEvent'
import { GestureEventType } from '@luciad/ria/view/input/GestureEventType'
import { Map } from '@luciad/ria/view/Map'
import { PcToolSupport } from '../alignment/PcToolSupport'
import { calculatePointingDirection } from '../common/util/PerspectiveCameraUtil'
import { sub } from '../common/util/Vector3Util'
import { PcTool } from '../dts/Alignment'
import { Vector3 } from '../util/Vector3'
import { rayPlaneIntersection } from '../util/Vector3Util'

export const MOVE_START_EVENT = 'MoveStartEvent'
export const MOVE_EVENT = 'MoveEvent'
export const MOVE_CONFIRMED_EVENT = 'MoveConfirmedEvent'

export class ParallelMoveController extends Controller {
  private readonly _eventedSupport: EventedSupport = new EventedSupport(
    [MOVE_START_EVENT, MOVE_EVENT, MOVE_CONFIRMED_EVENT],
    true
  )
  private readonly _support: PcToolSupport

  private _planeNormal: IVector3 = new Vector3(0, 0, 0)
  private _startWorldPoint: IVector3 | null = null

  constructor(support: PcToolSupport) {
    super()
    this._support = support
  }

  tryGetStartWorldPoint(viewPoint: Point): IVector3 | null {
    if (this.map === null) return null
    let p: IVector3 | null
    try {
      p = this.map
        .getViewToMapTransformation(LocationMode.CLOSEST_SURFACE)
        .transform(viewPoint)
    } catch (error) {
      p = null
    }
    return p
  }

  onGestureEvent(gestureEvent: GestureEvent): HandleEventResult {
    if (this._support.checkDisabled(PcTool.MOVE))
      return HandleEventResult.EVENT_IGNORED
    // TODO: this should be disabled for tablets, or based on the selected tool
    // if (gestureEvent.modifier != ModifierType.SHIFT) {
    //   return HandleEventResult.EVENT_IGNORED
    // }

    const viewPoint = gestureEvent.viewPoint
    const map = this.map!
    const camera = this.map!.camera as PerspectiveCamera

    if (gestureEvent.type == GestureEventType.DOWN) {
      this._eventedSupport.emit(MOVE_START_EVENT)
      this._planeNormal = camera.forward
      this._startWorldPoint = this.tryGetStartWorldPoint(viewPoint)
      console.log(this._startWorldPoint)
      console.log('Start translation')
    }

    if (gestureEvent.type == GestureEventType.DRAG) {
      if (this._startWorldPoint === null) {
        this._startWorldPoint = this.tryGetStartWorldPoint(viewPoint)
      }
      if (this._startWorldPoint !== null) {
        const pointingDir = calculatePointingDirection(map, viewPoint)
        const worldPoint = rayPlaneIntersection(
          camera.eye,
          pointingDir,
          this._planeNormal,
          this._startWorldPoint
        )!
        const movementVector = sub(worldPoint, this._startWorldPoint)
        this._eventedSupport.emit(MOVE_EVENT, movementVector)
      }
    }

    if (gestureEvent.type == GestureEventType.UP) {
      this._startWorldPoint = null
      this._eventedSupport.emit(MOVE_CONFIRMED_EVENT)
    }
    return HandleEventResult.EVENT_HANDLED
  }

  on(
    event:
      | 'Activated'
      | 'Deactivated'
      | 'Invalidated'
      | typeof MOVE_START_EVENT
      | typeof MOVE_EVENT
      | typeof MOVE_CONFIRMED_EVENT,
    callback: ((map: Map) => void) | ((move: Vector3) => void)
  ): Handle {
    if (
      event === MOVE_START_EVENT ||
      event === MOVE_EVENT ||
      event === MOVE_CONFIRMED_EVENT
    ) {
      return this._eventedSupport.on(event, callback)
    } else {
      // @ts-ignore
      return super.on(event, callback)
    }
  }
}
