import {Map} from '@luciad/ria/view/Map';
import {
  ArtifactType,
  AssetGeolocation,
  createGeolocation,
  deleteGeolocation,
  GeolocationAsset,
  getGeolocationAsset,
  isGeolocationArtifactType,
  updateGeolocation,
} from './GeolocationPersistanceUtil';
import {getApolloClient} from '../util/PersistanceUtil';
import {getResolvedBearerToken} from '../authentication/AuthenticationUtil';
import {EventedSupport} from '@luciad/ria/util/EventedSupport';
import {Handle} from '@luciad/ria/util/Evented';
import {
  AssetPlaceController,
  GEOLOCATION_CONFIRMED_EVENT,
} from './AssetPlaceController';
import {Layer} from '@luciad/ria/view/Layer';
import {LayerGroup} from '@luciad/ria/view/LayerGroup';
import {ASSET_LAYER_GROUP_ID} from '../layers/LayerUtil';
import {Point} from '@luciad/ria/shape/Point';
import {getReference} from '@luciad/ria/reference/ReferenceProvider';
import {createTransformation} from '@luciad/ria/transformation/TransformationFactory';
import {Vector3} from '@luciad/ria/util/Vector3';
import {
  geolocateLayer,
  getDefaultGeolocationName,
  getGeolocationAddresses,
  getGeolocationArtifactAddresses,
  getGeolocationAssetAddresses,
  getGeolocationLayerId,
  loadGeolocationAddress,
} from './GeolocationUtil';
import {createPoint} from '@luciad/ria/shape/ShapeFactory';
import {getFitBounds} from '../common/util/FitBoundsUtil';
import {
  GeolocationMoveController,
  MOVE_CONFIRMED_EVENT,
} from './GeolocationMoveController';
import {Controller} from '@luciad/ria/view/controller/Controller';
import {v4 as uuidv4} from 'uuid';

/**
 * Event emitted when an asset was added or removed.
 */
export const ASSETS_CHANGED_EVENT = 'AssetsChangedEvent';

/**
 * Event emitted when an asset was updated.
 */
export const ASSETS_UPDATED_EVENT = 'AssetUpdatedEvent';

/**
 * Event emitted when the currently edited geolocation changes.
 */
export const EDITED_GEOLOCATION_CHANGED_EVENT = 'EditedGeolocationChangedEvent';

const LUCIAD_XYZ = getReference('LUCIAD:XYZ');
const CRS_84 = getReference('CRS:84');
const UPDATE_INTERVAL = 5000; //ms

/**
 * Support class that can be used to handle geolocation of assets on the given map.
 */
export class GeolocationSupport {
  private _map: Map;
  private _assets: GeolocationAsset[] = [];
  private _eventedSupport: EventedSupport;
  private _updateHandle: number = 0;
  private _idMap: {[hxdrID: string]: string} = {}; //maps HxDR geolocation ids to our ids used to create layers
  private _editedGeolocationId: string | null = null;

  constructor(map: Map) {
    this._map = map;
    this._eventedSupport = new EventedSupport(
      [
        ASSETS_CHANGED_EVENT,
        ASSETS_UPDATED_EVENT,
        EDITED_GEOLOCATION_CHANGED_EVENT,
      ],
      true
    );
    this._updateHandle = window.setTimeout(
      () => this.fetchAndUpdate(),
      UPDATE_INTERVAL
    );
  }

  destroy() {
    clearTimeout(this._updateHandle);
    this._updateHandle = 0;
  }

  /**
   * Adds the asset with given grouped asset id to the list of managed assets of this object.
   * Once the asset is loaded from the back-end, any already existing geolocations will be loaded on the map.
   */
  addAsset(groupedAssetId: string) {
    getGeolocationAsset(
      getApolloClient(getResolvedBearerToken()),
      groupedAssetId
    ).then((asset) => {
      this._assets.push(asset);
      this._eventedSupport.emit(ASSETS_CHANGED_EVENT);

      for (const geolocation of asset.groupedAssetGeoreferences.contents) {
        this._idMap[geolocation.id] = geolocation.id;
        const group = this.createGeolocationLayerGroup(asset, geolocation);
        for (const address of getGeolocationAddresses(geolocation)) {
          if (address.type === 'PANORAMIC') {
            console.log(
              'Ignoring panoramic address, support for these addresses will be added later'
            );
            continue;
          }
          loadGeolocationAddress(geolocation.id, asset.name, address).then(
            (layer) => {
              geolocateLayer(
                layer,
                createPoint(CRS_84, [
                  geolocation.longitude,
                  geolocation.latitude,
                  geolocation.altitude,
                ]),
                createPoint(LUCIAD_XYZ, [
                  geolocation.anchorX,
                  geolocation.anchorY,
                  geolocation.anchorZ,
                ]),
                geolocation.yaw
              );
              layer.visible = geolocation.artifacts.find(
                ({artifact}) =>
                  artifact.addresses.contents.indexOf(address) >= 0
              )!.visible;
              group.addChild(layer);
            }
          );
        }
      }
    });
  }

  /**
   * Removes an asset from this object.
   * This will also remove all geolocated layers corresponding to the asset from the map.
   */
  removeAsset(groupedAssetId: string) {
    const i = this._assets.findIndex(({id}) => id === groupedAssetId);
    if (i >= 0) {
      for (const geolocation of this._assets[i].groupedAssetGeoreferences
        .contents) {
        this.removeLayers(geolocation);
      }
      this._assets.splice(i, 1);
      this._eventedSupport.emit(ASSETS_CHANGED_EVENT);
    }
  }

  /**
   * Enables the controller that allows a user to place the given asset on the map.
   * Once the asset is placed, a geolocation is created and persisted.
   */
  startGeolocationCreation(asset: GeolocationAsset) {
    const controller = new AssetPlaceController();
    this._map.controller = controller;
    const geolocationLayerGroupId = uuidv4();

    controller.on(
      GEOLOCATION_CONFIRMED_EVENT,
      (layers: Layer[], geolocationPoint: Point, anchor: Vector3) => {
        this._map.controller = null;
        const crs84Geolocation = createTransformation(
          geolocationPoint.reference!,
          CRS_84
        ).transform(geolocationPoint);
        getDefaultGeolocationName(geolocationPoint).then((name) => {
          createGeolocation(
            getApolloClient(getResolvedBearerToken()),
            asset.id,
            name,
            crs84Geolocation,
            anchor,
            asset.asset.artifacts.contents
              .map(({type}) => type)
              .filter(isGeolocationArtifactType)
          ).then((geolocation) => {
            this._idMap[geolocation.id] = geolocationLayerGroupId;
            const group = this.createGeolocationLayerGroup(asset, geolocation);
            for (const layer of layers) {
              group.moveChild(layer);
            }
            const i = this._assets.findIndex(({id}) => id === asset.id);
            if (i >= 0) {
              this._assets[i].groupedAssetGeoreferences.contents.push(
                geolocation
              );
              this._eventedSupport.emit(ASSETS_UPDATED_EVENT);
            }
          });
        });
      }
    );

    controller.loadAddresses(
      geolocationLayerGroupId,
      asset.name,
      getGeolocationAssetAddresses(asset)
    );
  }

  /**
   * Creates a layer group for geolocation layers and adds it to the map to insure the following structure:
   * <ul>
   *   <li> root </li>
   *   <ul>
   *     <li> assets group </li>
   *     <ul>
   *       <li> <asset name> (one group per asset) </li>
   *       <ul>
   *         <li> <geolocation name> (one group per geolocation, these are returned by this method) </li>
   *         <ul>
   *           <li> <artifact name> (one layer per artifact) </li>
   *         </ul>
   *       </ul>
   *     </ul>
   *   </ul>
   * </ul>
   */
  private createGeolocationLayerGroup(
    asset: GeolocationAsset,
    geolocation: AssetGeolocation
  ): LayerGroup {
    const group = new LayerGroup({
      id: this.getGeolocationLayerGroupId(geolocation.id),
      label: geolocation.name,
    });
    const allAssetsGroup =
      this._map.layerTree.findLayerGroupById(ASSET_LAYER_GROUP_ID);
    let currentAssetGroup = allAssetsGroup.findLayerGroupById(asset.id);
    if (!currentAssetGroup) {
      currentAssetGroup = new LayerGroup({id: asset.id, label: asset.name});
      allAssetsGroup.addChild(currentAssetGroup);
    }
    currentAssetGroup.addChild(group);
    return group;
  }

  /**
   * Enables the controller that allows a user to move and rotate the given geolocation on the map.
   * Once the move was confirmed, the updated geolocation will be persisted.
   */
  startGeolocationMoveInteraction(geolocation: AssetGeolocation) {
    const asset = this.getAssetByGeolocationId(geolocation.id);
    if (!asset) {
      throw new Error("Can not move geolocation if it isn't tracked");
    }
    const layers = getGeolocationAddresses(geolocation)
      .map((address) =>
        this._map.layerTree.findLayerById(
          getGeolocationLayerId(
            this.getGeolocationLayerGroupId(geolocation.id),
            address.id
          )
        )
      )
      .filter((layer) => !!layer);
    if (layers.length === 0) {
      throw new Error('Can not move a geolocation without layers');
    }
    const controller = new GeolocationMoveController(
      layers,
      {x: geolocation.anchorX, y: geolocation.anchorY, z: geolocation.anchorZ},
      createPoint(CRS_84, [
        geolocation.longitude,
        geolocation.latitude,
        geolocation.altitude,
      ]),
      geolocation.yaw
    );
    controller.on(MOVE_CONFIRMED_EVENT, ({x, y, z}, rotation) => {
      this._map.controller = null;
      this.updateGeolocation(asset.id, geolocation.id, {
        longitude: x,
        latitude: y,
        altitude: z,
        yaw: rotation,
      });
    });
    controller.on('Deactivated', () => {
      if (this._editedGeolocationId === geolocation.id) {
        this._editedGeolocationId = null;
        this._eventedSupport.emit(EDITED_GEOLOCATION_CHANGED_EVENT, null);
      }
    });
    this._map.controller = controller;
    this._editedGeolocationId = geolocation.id;
    this._eventedSupport.emit(EDITED_GEOLOCATION_CHANGED_EVENT, geolocation.id);
    this.deactivateControllerOnGeolocationRemoval(controller, geolocation.id);
  }

  /**
   * Disables the given controller when the given geolocation is removed from the tracked list
   */
  private deactivateControllerOnGeolocationRemoval(
    controller: Controller,
    geolocationId: string
  ) {
    const handles: Handle[] = [];
    const removeHandles = () => {
      for (const handle of handles) {
        handle.remove();
      }
    };
    controller.on('Deactivated', removeHandles);
    const checkForDeactivation = () => {
      const asset = this.getAssetByGeolocationId(geolocationId);
      if (
        (!asset ||
          !asset.groupedAssetGeoreferences.contents.find(
            (geolocation) => geolocation.id === geolocationId
          )) &&
        this._map.controller === controller
      ) {
        this._map.controller = null;
        removeHandles();
      }
    };
    handles.push(this.on(ASSETS_CHANGED_EVENT, checkForDeactivation));
    handles.push(this.on(ASSETS_UPDATED_EVENT, checkForDeactivation));
  }

  /**
   * Returns the id of the geolocation that is currently being edited.
   * If there is no geolocation being currently edited, this returns null.
   */
  get editedGeolocationId(): string | null {
    return this._editedGeolocationId;
  }

  /**
   * Stops the current Geolocation map interaction if there is any currently present.
   */
  stopInteraction() {
    if (
      this._map.controller instanceof GeolocationMoveController ||
      this._map.controller instanceof AssetPlaceController
    ) {
      this._map.controller = null;
    }
  }

  /**
   * Updates the given geolocation with the given partial geolocation object.
   * All undefined update fields will be assumed to be unchanged from the already existing geolocation.
   */
  private updateGeolocation(
    assetId: string,
    geolocationId: string,
    update: Partial<AssetGeolocation>
  ) {
    const asset = this._assets.find(({id}) => id === assetId);
    if (asset) {
      const geolocationToUpdate = asset.groupedAssetGeoreferences.contents.find(
        ({id}) => id === geolocationId
      );
      if (geolocationToUpdate) {
        const fullUpdate: AssetGeolocation = {
          ...geolocationToUpdate,
          ...update,
        };
        return updateGeolocation(
          getApolloClient(getResolvedBearerToken()),
          geolocationId,
          fullUpdate
        ).then((updatedGeolocation) => {
          const geolocations = this._assets.find(({id}) => id === assetId)
            ?.groupedAssetGeoreferences.contents;
          if (geolocations) {
            const geolocationIndex = geolocations.findIndex(
              (geolocation) => geolocation.id === geolocationId
            );
            if (geolocationIndex >= 0) {
              geolocations[geolocationIndex] = updatedGeolocation;
              this._eventedSupport.emit(ASSETS_UPDATED_EVENT);
            }
          }
        });
      }
    }
  }

  /**
   * Removes the given geolocation from the support, map and back-end.
   */
  async deleteGeolocation(geolocation: AssetGeolocation) {
    const result = await deleteGeolocation(
      getApolloClient(getResolvedBearerToken()),
      geolocation.id
    );
    if (!result) {
      console.warn(`could not delete "${geolocation.name}"`); //TODO: use notifications
    } else {
      const geolocations = this.getAssetByGeolocationId(geolocation.id)
        ?.groupedAssetGeoreferences.contents;
      if (geolocations) {
        this.removeLayers(geolocation);
        geolocations.splice(
          geolocations.findIndex(({id}) => id === geolocation.id),
          1
        );
        this._eventedSupport.emit(ASSETS_UPDATED_EVENT);
      }
    }
  }

  private removeLayers(geolocation: AssetGeolocation) {
    for (const address of getGeolocationAddresses(geolocation)) {
      const layer = this._map.layerTree.findLayerById(
        getGeolocationLayerId(
          this.getGeolocationLayerGroupId(geolocation.id),
          address.id
        )
      );
      if (layer) {
        layer.parent!.removeChild(layer);
      }
    }
    const geolocationGroup = this._map.layerTree.findLayerGroupById(
      this.getGeolocationLayerGroupId(geolocation.id)
    );
    if (geolocationGroup && geolocationGroup.children.length === 0) {
      geolocationGroup.parent!.removeChild(geolocationGroup);
    }
    const asset = this.getAssetByGeolocationId(geolocation.id);
    if (asset) {
      const assetGroup = this._map.layerTree.findLayerGroupById(asset.id);
      if (assetGroup && assetGroup.children.length === 0) {
        assetGroup.parent!.removeChild(assetGroup);
      }
    }

    delete this._idMap[geolocation.id];
  }

  get assets(): GeolocationAsset[] {
    return this._assets;
  }

  /**
   * Updates the name of the geolocation with given ID to the given new name.
   * This update will also be persisted on the back-end.
   */
  async updateName(geolocationId: string, newName: string) {
    const asset = this.getAssetByGeolocationId(geolocationId);
    if (asset) {
      await this.updateGeolocation(asset.id, geolocationId, {name: newName});
      this._map.layerTree.findLayerGroupById(
        this.getGeolocationLayerGroupId(geolocationId)
      ).label = newName;
      this._eventedSupport.emit(ASSETS_UPDATED_EVENT);
    }
  }

  /**
   * Updates the visibility of the layers corresponding to the given artifact types of the geolocation with given id.
   * This update will also be persisted on the back-end.
   */
  async updateVisibility(
    geolocationId: string,
    artifactTypes: ArtifactType[],
    visible: boolean
  ) {
    const asset = this.getAssetByGeolocationId(geolocationId);
    if (asset) {
      const artifacts = asset?.groupedAssetGeoreferences.contents.find(
        ({id}) => id === geolocationId
      )!.artifacts;
      for (const type of artifactTypes) {
        const artifact = artifacts.find(({artifact}) => artifact.type === type);
        if (artifact) {
          artifact.visible = visible;
          for (const address of getGeolocationArtifactAddresses(artifact)) {
            const layer = this._map.layerTree.findLayerById(
              getGeolocationLayerId(
                this.getGeolocationLayerGroupId(geolocationId),
                address.id
              )
            );
            if (layer) {
              layer.visible = visible;
            }
          }
        }
      }
      await this.updateGeolocation(asset.id, geolocationId, {artifacts});
      this._eventedSupport.emit(ASSETS_UPDATED_EVENT);
    }
  }

  /**
   * Fits the map on the layers of the given geolocation.
   */
  async fit(geolocation: AssetGeolocation) {
    const group = this._map.layerTree.findLayerGroupById(
      this.getGeolocationLayerGroupId(geolocation.id)
    );
    if (group) {
      const bounds = await getFitBounds(group);
      if (bounds) {
        this._map.mapNavigator.fit({bounds, animate: true});
      }
    }
  }

  /**
   * Re-fetches the tracked assets from the back-end to update the tracked list with the latest information.
   */
  private fetchAndUpdate() {
    const updatedAssets: GeolocationAsset[] = [];
    const removedAssets: GeolocationAsset[] = [];
    const promises: Promise<any>[] = [];
    for (const asset of this._assets) {
      promises.push(
        getGeolocationAsset(getApolloClient(getResolvedBearerToken()), asset.id)
          .then((newAsset) => updatedAssets.push(newAsset))
          .catch(() => {
            //we assume here that a failed "get asset" request means that the asset was removed
            removedAssets.push(asset);
          })
      );
    }
    Promise.all(promises).then(() => {
      if (updatedAssets.length > 0 || removedAssets.length > 0) {
        const assets = [...this._assets];

        for (const asset of removedAssets) {
          const i = assets.findIndex(({id}) => id === asset.id);
          if (i >= 0) {
            assets.splice(i, 1);
          }
        }

        for (const asset of removedAssets) {
          const i = assets.findIndex(({id}) => id === asset.id);
          if (i >= 0) {
            assets[i] = asset;
          }
        }

        this._assets = assets;
        this._eventedSupport.emit(ASSETS_UPDATED_EVENT);
      }

      this._updateHandle = window.setTimeout(
        () => this.fetchAndUpdate(),
        UPDATE_INTERVAL
      );
    });
  }

  private getAssetByGeolocationId(geolocationId: string) {
    return this._assets.find(
      ({groupedAssetGeoreferences}) =>
        groupedAssetGeoreferences.contents.findIndex(
          ({id}) => id === geolocationId
        ) >= 0
    );
  }

  /**
   * Returns the layer group id associated with the given geolocation id.
   * This is mainly used because geoloation layers can be created before the geolocation
   * was actually created on HxDR and thus the HxDR geolocation id could not be used as layer id.
   */
  private getGeolocationLayerGroupId(geolocationId: string): string {
    return this._idMap[geolocationId];
  }

  on(
    event:
      | typeof ASSETS_CHANGED_EVENT
      | typeof ASSETS_UPDATED_EVENT
      | typeof EDITED_GEOLOCATION_CHANGED_EVENT,
    callback: (() => void) | ((geolocationId: string) => void)
  ): Handle {
    return this._eventedSupport.on(event, callback);
  }
}
