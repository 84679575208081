import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  BigInteger: number;
  DateTime: string;
  JSON: any;
  _Any: any;
};

/** User has insufficient rights to access the requested resource. */
export type TAccessDeniedError = TError & {
  message: Scalars['String'];
};

export type TAccountAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type TAccountAddressOutput = {
  additionalInfo?: Maybe<Scalars['String']>;
  /** City can be short form or long form in US addresses */
  city?: Maybe<Scalars['String']>;
  /**
   * Country expect the iso code, referr to 'countries' query to get the right country iso code)
   *
   * No all countries are supported in the shop check the 'supportedcountries' query to see which one are supported
   *
   * Having an unsupported country in this address only prevents the use of the shop not of the entire app.
   */
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  /** State is also the region in not US addresses */
  state?: Maybe<Scalars['String']>;
  /** The intern is not necessary */
  street?: Maybe<Scalars['String']>;
  streetNumber?: Maybe<Scalars['String']>;
};

export type TAccountDetailOutput = {
  accountInfo?: Maybe<TAccountInfoOutput>;
  users: Array<Maybe<TUserProfileOutput>>;
};

/** Result of requesting a specific account. */
export type TAccountDetailUnion = TAccountDetailOutput | TAcsErrorOperationNotAllowedOutput;

/** The account address is not valid. */
export type TAccountInfoAccountAddressIsNotValidOutput = TBaseAccountInfoErrorInterface & {
  message: Scalars['String'];
};

/** The account company name is not valid. */
export type TAccountInfoAccountCompanyNameIsNotValidOutput = TBaseAccountInfoErrorInterface & {
  message: Scalars['String'];
};

/** The account email is not valid. */
export type TAccountInfoAccountEmailIsNotValidOutput = TBaseAccountInfoErrorInterface & {
  message: Scalars['String'];
};

/** The account legal entity could not be changed. */
export type TAccountInfoAccountLegalEntityCouldNotBeChangedOutput = TBaseAccountInfoErrorInterface & {
  message: Scalars['String'];
};

/** The action can not be performed. */
export type TAccountInfoErrorOperationNotAllowedOutput = TBaseAccountInfoErrorInterface & {
  message: Scalars['String'];
};

export type TAccountInfoOutput = {
  accountBillingEmail?: Maybe<Scalars['String']>;
  accountUsageOutput?: Maybe<TAccountUsageOutput>;
  address?: Maybe<TAccountAddressOutput>;
  addressValid?: Maybe<TAddressValidOutput>;
  company?: Maybe<Scalars['String']>;
  discountRate?: Maybe<Scalars['Float']>;
  financialDetailsSynced?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isReseller?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  originalExternalAccount?: Maybe<Scalars['String']>;
  paymentMethods?: Maybe<Array<Maybe<TPaymentMethodEnum>>>;
  paymentSystemIds?: Maybe<Array<TPaymentSystemOutput>>;
  phonenumber?: Maybe<Scalars['String']>;
  referrer?: Maybe<Scalars['String']>;
  sapAccountNumber?: Maybe<Scalars['String']>;
  taxExempt?: Maybe<Scalars['Boolean']>;
  tenantId?: Maybe<Scalars['ID']>;
  vatNumber?: Maybe<Scalars['String']>;
};

/**
 * Result of nutation changeProjectRoleOfUserV2.
 * Of type ChangeProjectRoleOutput in case of success, otherwise one of the errors.
 */
export type TAccountInfoUpdateUnion = TAccountInfoAccountAddressIsNotValidOutput | TAccountInfoAccountCompanyNameIsNotValidOutput | TAccountInfoAccountEmailIsNotValidOutput | TAccountInfoAccountLegalEntityCouldNotBeChangedOutput | TAccountInfoErrorOperationNotAllowedOutput | TAccountInfoOutput;

export enum TAccountLimitEnum {
  SEATS = 'SEATS',
  STORAGE = 'STORAGE',
  STREAMING = 'STREAMING'
}

export type TAccountMemberInput = {
  newUserRole: TAccountRoleEnum;
  userToAssignUUID: Scalars['ID'];
};

export enum TAccountMembersOrderEnum {
  ACCOUNT_ROLE_ASC = 'ACCOUNT_ROLE_ASC',
  ACCOUNT_ROLE_DESC = 'ACCOUNT_ROLE_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC'
}

export type TAccountMembersPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TAccountMembersResultOutput = {
  appliedFilters?: Maybe<TFilterAccountMembersOutput>;
  appliedPagination?: Maybe<TAccountMembersPageOutput>;
  members: Array<Maybe<TUserProfileOutput>>;
  orderBy?: Maybe<TAccountMembersOrderEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Result of requesting all the members of a certain account. */
export type TAccountMembersResultUnion = TAccountMembersResultOutput | TAcsErrorOperationNotAllowedOutput;

export type TAccountProfileAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum TAccountProfileOrderEnum {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export type TAccountProfilePageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TAccountProfileResultOutput = {
  appliedFilters?: Maybe<TFilterAccountProfileAppliedOutput>;
  appliedPagination?: Maybe<TAccountProfileAppliedPageOutput>;
  contents: Array<Maybe<TAccountInfoOutput>>;
  orderBy?: Maybe<TAccountProfileOrderEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TAccountPropertiesInput = {
  accountId: Scalars['ID'];
  discountRate?: InputMaybe<Scalars['Float']>;
  isReseller?: InputMaybe<Scalars['Boolean']>;
  paymentMethods?: InputMaybe<Array<InputMaybe<TPaymentMethodEnum>>>;
  referrerId?: InputMaybe<Scalars['ID']>;
  sapAccountNumber?: InputMaybe<Scalars['String']>;
  taxExempt?: InputMaybe<Scalars['Boolean']>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export enum TAccountRoleEnum {
  ACCOUNT_ROLE_ADMIN = 'ACCOUNT_ROLE_ADMIN',
  ACCOUNT_ROLE_EMPLOYEE = 'ACCOUNT_ROLE_EMPLOYEE',
  ACCOUNT_ROLE_EXTERNAL = 'ACCOUNT_ROLE_EXTERNAL',
  ACCOUNT_ROLE_MAINTAINER = 'ACCOUNT_ROLE_MAINTAINER'
}

export type TAccountUpdateInput = {
  accountBillingEmail?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<TAccountAddressInput>;
  company: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  phonenumber?: InputMaybe<Scalars['String']>;
  referrerId?: InputMaybe<Scalars['ID']>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type TAccountUsageInput = {
  accountID?: InputMaybe<Scalars['ID']>;
};

/** The usage Account Output federated */
export type TAccountUsageOutput = {
  /** Account UUID */
  accountID: Scalars['ID'];
  /** Processing Usage Outputs */
  processing: Array<Maybe<TProcessingUsageOutput>>;
  /** Number of Projects */
  projects: Scalars['Int'];
  /** Seats */
  seats: Scalars['Int'];
  /** Storage Artifacts */
  storageArtifacts: Scalars['BigInteger'];
  /** Storage Asset */
  storageAssets: Scalars['BigInteger'];
  /** Streaming Usage Output */
  streaming: TStreamingUsageOutput;
};


/** The usage Account Output federated */
export type TAccountUsageOutputProcessingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


/** The usage Account Output federated */
export type TAccountUsageOutputStreamingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

/** One of the entities identified with a UUID could not be found. */
export type TAcsErrorEntityNotFoundOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The provided invitation signature is not valid. */
export type TAcsErrorInvalidInvitationSignatureOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The provided voucher is not valid. */
export type TAcsErrorInvalidVoucherOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The user is not allowed to perform this operation. */
export type TAcsErrorOperationNotAllowedOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The action can not be performed. */
export type TAcsErrorOperationNotPossibleOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The project with given UUID could not be found. */
export type TAcsErrorProjectNotFoundOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because it would result in the seats limit being exceeded for the account. */
export type TAcsErrorSeatLimitReachedOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The tenant with given UUID could not be found. */
export type TAcsErrorTenantNotFoundOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The system is receiving too many requests. Try again later. */
export type TAcsErrorTooManyRequestsOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** A user with the given email already exists in this account. */
export type TAcsErrorUserAlreadyExistsInAccountOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** A user with the given email already exists in another account. */
export type TAcsErrorUserAlreadyExistsInOtherAccountOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** A user with the given email already exists. */
export type TAcsErrorUserAlreadyExistsOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The user with given UUID could not be found. */
export type TAcsErrorUserNotFoundOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

/** The provided voucher is already used. */
export type TAcsErrorVoucherAlreadyUsedOutput = TBaseAcsErrorInterface & {
  message: Scalars['String'];
};

export type TAddCartItemInput = {
  coordinateSystem: Scalars['String'];
  imageFormat: Scalars['String'];
  quotationItemUUID: Scalars['ID'];
};

/**
 * fileName can be either:
 * * the name of the file, e.g. SomeFile.e57
 * * a relative path with file name, e.g. /folder1/folder2/<folders>/SomeFile.e57
 * Relative paths and special characthers are not supported
 */
export type TAddFileInput = {
  fileName: Scalars['String'];
  fileSize: Scalars['BigInteger'];
  groupedAssetId: Scalars['ID'];
};

/**
 * Result of mutation addFile.
 * Of type MultiAssetDownloadLinkOutput in case of success, otherwise one of the errors.
 */
export type TAddFileUnion = TAssetErrorNameBlacklistedCharactersOutput | TAssetErrorNameBlankOutput | TAssetErrorNameForbiddenOutput | TAssetErrorNameLeadingOrTrailingSpaceOutput | TAssetErrorNameTrailingDotOutput | TAssetErrorNameTwoDotsInRowOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorStorageLimitReachedOutput | TFileOutput;

export type TAddLabelsInput = {
  entityId: Scalars['ID'];
  entityType: TEntityTypeEnum;
  labels: Array<InputMaybe<TLabelInput>>;
};

/**
 * Result of mutation addLabels.
 * Of type LabelsOutput in case of success, otherwise one of the errors.
 */
export type TAddLabelsUnion = TLabelErrorOperationNotAllowedOutput | TLabelErrorTooManyLabelsOutput | TLabelsOutput;

export type TAddressAssetFileDownloadOutput = TRenderable & {
  downloadType: TDownloadTypeEnum;
  endpoint: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
};

export enum TAddressContentTypeEnum {
  ELEVATION = 'ELEVATION',
  IMAGE = 'IMAGE'
}

export type TAddressDownloadOutput = TRenderable & {
  downloadType: TDownloadTypeEnum;
  endpoint: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  processingPipelineInfo?: Maybe<TProcessingPipelineInfoOutput>;
  type: TAddressTypeEnum;
};

export type TAddressHspcOutput = TRenderable & {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  processingPipelineInfo?: Maybe<TProcessingPipelineInfoOutput>;
  type: TAddressTypeEnum;
};

export type TAddressLtsOutput = TRenderable & {
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  processingPipelineInfo?: Maybe<TProcessingPipelineInfoOutput>;
  type: TAddressTypeEnum;
};

export type TAddressOgc3DOutput = TRenderable & {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  processingPipelineInfo?: Maybe<TProcessingPipelineInfoOutput>;
  qualityFactor: Scalars['Float'];
  type: TAddressTypeEnum;
};

export type TAddressOutput = TAddressAssetFileDownloadOutput | TAddressDownloadOutput | TAddressHspcOutput | TAddressLtsOutput | TAddressOgc3DOutput | TAddressPanoramicOutput | TAddressWfsOutput | TAddressWmsOutput | TAddressWmtsOutput;

export type TAddressPageOutput = {
  appliedPagination?: Maybe<TArtifactAppliedPageOutput>;
  contents: Array<TAddressOutput>;
  filterType?: Maybe<TAddressTypeEnum>;
  groupedAssetId?: Maybe<Scalars['ID']>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  parentId: Scalars['ID'];
  total: Scalars['Int'];
};

export type TAddressPanoramicOutput = TRenderable & {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  processingPipelineInfo?: Maybe<TProcessingPipelineInfoOutput>;
  type: TAddressTypeEnum;
};

export enum TAddressSamplingModeEnum {
  AREA = 'AREA',
  POINT = 'POINT'
}

export enum TAddressTypeEnum {
  DOWNLOAD = 'DOWNLOAD',
  DOWNLOAD_ASSET_FILE = 'DOWNLOAD_ASSET_FILE',
  HSPC = 'HSPC',
  JSON = 'JSON',
  LTS = 'LTS',
  OGC_3D_TILES = 'OGC_3D_TILES',
  PANORAMIC = 'PANORAMIC',
  UNDEFINED = 'UNDEFINED',
  URL = 'URL',
  WFS = 'WFS',
  WMS = 'WMS',
  WMTS = 'WMTS',
  ZIP = 'ZIP'
}

export type TAddressValidOutput = {
  /** Describe the reason why the address is not valid, if it is valid the string is empty */
  reason?: Maybe<Scalars['String']>;
  taxable?: Maybe<Scalars['Boolean']>;
  valid?: Maybe<Scalars['Boolean']>;
};

export enum TAddressViewingModeEnum {
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  REALISTIC = 'REALISTIC',
  WHITE = 'WHITE',
  WIREFRAME = 'WIREFRAME'
}

export type TAddressWfsOutput = TRenderable & {
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  styleUrl?: Maybe<Scalars['String']>;
  type: TAddressTypeEnum;
};

export type TAddressWmsOutput = TRenderable & {
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
};

export type TAddressWmtsOutput = TRenderable & {
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
};

/**
 * Access to the requested action or resource denied.
 * You do not have the required rights or your authentication token is incorrect.
 */
export type TAnnotationErrorOperationNotAllowedOutput = TBaseAnnotationErrorInterface & {
  message: Scalars['String'];
};

/**
 * A camera position, defined by the yaw, pitch, roll, a reference point and the distance between the camera and that
 * reference point.
 */
export type TAnnotationOrientationInput = {
  distance: Scalars['Float'];
  pitch: Scalars['Float'];
  ref: TAnnotationPointInput;
  roll: Scalars['Float'];
  yaw: Scalars['Float'];
};

/**
 * A camera position, defined by the yaw, pitch, roll, a reference point and the distance between the camera and that
 * reference point.
 */
export type TAnnotationOrientationOutput = {
  distance: Scalars['Float'];
  pitch: Scalars['Float'];
  ref: TAnnotationPointOutput;
  roll: Scalars['Float'];
  yaw: Scalars['Float'];
};

/** A point with an X, Y and Z coordinate. */
export type TAnnotationPointInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

/** A point with an X, Y and Z coordinate. */
export type TAnnotationPointOutput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

/** A rotation defined in degrees clockwise around the X, Y and Z axes. */
export type TAnnotationRotationInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

/** A rotation defined in degrees clockwise around the X, Y and Z axes. */
export type TAnnotationRotationOutput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type TAnnotationThumbnailOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

/**
 * Result of mutation uploadThumbnailToAnnotationV2.
 * Of type AnnotationThumbnailOutput in case of success, otherwise one of the errors.
 */
export type TAnnotationThumbnailUnion = TAnnotationErrorOperationNotAllowedOutput | TAnnotationThumbnailOutput;

export type TAppClientOutput = {
  appClientId: Scalars['String'];
  callbackUrls: Array<Scalars['String']>;
  clientSecret?: Maybe<Scalars['String']>;
  logoutUrls: Array<Scalars['String']>;
  oAuthFlows: Array<TOAuthFlowTypeEnum>;
};

/** One or more of the input properties are invalid */
export type TApplicationErrorInvalidArgumentOutput = TBaseApplicationErrorInterface & {
  message: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TApplicationErrorOperationNotAllowedOutput = TBaseApplicationErrorInterface & {
  message: Scalars['String'];
};

/** The specified application was not found or is not accessible. */
export type TApplicationErrorResourceAccessDeniedOutput = TBaseApplicationErrorInterface & {
  message: Scalars['String'];
};

/** The system is receiving too many requests. Try again later. */
export type TApplicationErrorTooManyRequestsOutput = TBaseApplicationErrorInterface & {
  message: Scalars['String'];
};

export enum TApplicationOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export type TApplicationTemplateAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TApplicationTemplateOutput = {
  artifact?: Maybe<TArtifactItemOutput>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  developmentAppClient: TAppClientOutput;
  developmentHereMapsKey?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  productionAppClient: TAppClientOutput;
  productionHereMapsKey?: Maybe<Scalars['String']>;
  templateVersion?: Maybe<Scalars['String']>;
};

export type TApplicationTemplatePageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TApplicationTemplateResultOutput = {
  appliedFilters: TFilterApplicationAppliedOutput;
  appliedPagination: TApplicationTemplateAppliedPageOutput;
  contents: Array<Maybe<TApplicationTemplateOutput>>;
  orderBy: TApplicationOrderEnum;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/**
 * Result of query applicationTemplate.
 * Of type ApplicationTemplateOutput in case of success, otherwise one of the errors.
 */
export type TApplicationTemplateUnion = TApplicationErrorOperationNotAllowedOutput | TApplicationErrorResourceAccessDeniedOutput | TApplicationErrorTooManyRequestsOutput | TApplicationTemplateOutput;

export type TApplicationTemplatesInput = {
  filter?: InputMaybe<TFilterApplicationInput>;
  orderBy?: InputMaybe<TApplicationOrderEnum>;
  paging?: InputMaybe<TApplicationTemplatePageInput>;
};

/**
 * Result of query applicationTemplates.
 * Of type ApplicationTemplatesOutput in case of success, otherwise one of the errors.
 */
export type TApplicationTemplatesUnion = TApplicationErrorOperationNotAllowedOutput | TApplicationErrorTooManyRequestsOutput | TApplicationTemplateResultOutput;

export type TArtifactAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TArtifactItemOutput = {
  addresses: TAddressPageOutput;
  countsForStorage?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  dataCategory?: Maybe<TDataCategoryEnum>;
  filePath?: Maybe<Scalars['String']>;
  groupedAssetId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  jobDetail?: Maybe<TJobPage>;
  modifiedAt: Scalars['DateTime'];
  savedFormat?: Maybe<TSavedFormatEnum>;
  /**
   * @deprecated
   * reason: "Use dataCategory and savedFormat combination instead"
   * @deprecated Use dataCategory and savedFormat combination instead
   */
  type: TArtifactTypeEnum;
};


export type TArtifactItemOutputAddressesArgs = {
  filter?: InputMaybe<TAddressTypeEnum>;
  paging?: InputMaybe<TArtifactPageableGql>;
};


export type TArtifactItemOutputJobDetailArgs = {
  paging?: InputMaybe<TArtifactPageableGql>;
};

export type TArtifactPageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TArtifactPageableGql = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum TArtifactParentTypeEnum {
  APPLICATION = 'APPLICATION',
  ASSET = 'ASSET',
  FOLDER = 'FOLDER'
}

export type TArtifactResultOutput = {
  appliedPagination?: Maybe<TArtifactAppliedPageOutput>;
  contents: Array<TArtifactItemOutput>;
  filterType?: Maybe<TArtifactTypeEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  parentId: Scalars['ID'];
  total: Scalars['Int'];
};

export type TArtifactResultOutputV2 = {
  appliedPagination?: Maybe<TArtifactAppliedPageOutput>;
  contents: Array<TArtifactItemOutput>;
  filterType?: Maybe<TDataCategoryEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  parentId: Scalars['ID'];
  total: Scalars['Int'];
};

export enum TArtifactTypeEnum {
  IMAGE_2D_ORTHO = 'IMAGE_2D_ORTHO',
  LIDAR = 'LIDAR',
  MESH = 'MESH',
  PANORAMIC = 'PANORAMIC',
  POINT_CLOUD = 'POINT_CLOUD',
  UNDEFINED = 'UNDEFINED',
  VECTOR_OR_RASTER = 'VECTOR_OR_RASTER',
  ZIP = 'ZIP'
}

export type TAssetAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageOffset?: Maybe<Scalars['Int']>;
  pageSize: Scalars['Int'];
};

export type TAssetBBoxInput = {
  height: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  width: Scalars['Float'];
};

export type TAssetBBoxOutput = {
  height: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  width: Scalars['Float'];
};

export type TAssetCountOutput = {
  all: Scalars['Int'];
  unassigned: Scalars['Int'];
};

/** The action is not possible because there is no coarse registration json present. */
export type TAssetErrorCoarseRegistrationInputNotFound = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because there are too many coarse registration jsons. */
export type TAssetErrorCoarseRegistrationLimitOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because the download limit has been reached. */
export type TAssetErrorDownloadLimitReachedOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** An asset with the given name already exists at the same level. */
export type TAssetErrorDuplicateNameOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The requested feature is not available. */
export type TAssetErrorFeatureNotAvailableOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because the asset number for autoregistration exceeds limit. */
export type TAssetErrorGroupedAssetRegistrationLimitOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because grouped assets are not under the same folder. */
export type TAssetErrorGroupedAssetsNotUnderSameFolderOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/**
 * The given name contains one or more blacklisted characters.
 * Blacklisted characters are:
 *
 * <,>,,:,\,|,?,*, ,#,$,+,%,!,`,&,',{,},",= and @
 */
export type TAssetErrorNameBlacklistedCharactersOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The given name is blank (empty, only spaces or null). */
export type TAssetErrorNameBlankOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/**
 * The given name is a forbidden name with any capitalisation and any extension.
 * Forbidden names are:
 *
 *   "CON", "PRN", "AUX", "NUL",
 *   "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
 *   "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9"
 */
export type TAssetErrorNameForbiddenOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a forward slash. */
export type TAssetErrorNameForwardSlashOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a leading or trailing space. */
export type TAssetErrorNameLeadingOrTrailingSpaceOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a trailing dot. */
export type TAssetErrorNameTrailingDotOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains two dots in a row. */
export type TAssetErrorNameTwoDotsInRowOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TAssetErrorOperationNotAllowedOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because the processing limit has been reached. */
export type TAssetErrorProcessingLimitReachedOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** Access to a requested resource denied. */
export type TAssetErrorResourceAccessDeniedOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The action is not possible because the storage limit has been reached. */
export type TAssetErrorStorageLimitReachedOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

/** The system is receiving too many requests. Try again later. */
export type TAssetErrorTooManyRequestsOutput = TBaseAssetErrorInterface & {
  message: Scalars['String'];
};

export enum TAssetOrderEnum {
  ASSET_TYPE_ASC = 'ASSET_TYPE_ASC',
  ASSET_TYPE_DESC = 'ASSET_TYPE_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  SIZE_ASC = 'SIZE_ASC',
  SIZE_DESC = 'SIZE_DESC'
}

export type TAssetOutput = {
  anchorPoint?: Maybe<TPoint3DOutput>;
  /** @deprecated Use artifactsV2 instead */
  artifacts?: Maybe<TArtifactResultOutput>;
  artifactsV2?: Maybe<TArtifactResultOutputV2>;
  files: TFileResultOutput;
  groupedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  jobDetail?: Maybe<TJobPage>;
  jobSummary?: Maybe<TJobSummaryGql>;
  referencedBounds?: Maybe<TReferencedBoundsOutput>;
  withEmbeddedGeoreference?: Maybe<Scalars['Boolean']>;
};


export type TAssetOutputArtifactsArgs = {
  filter?: InputMaybe<TArtifactTypeEnum>;
  paging?: InputMaybe<TArtifactPageInput>;
};


export type TAssetOutputArtifactsV2Args = {
  filter?: InputMaybe<TDataCategoryEnum>;
  paging?: InputMaybe<TArtifactPageInput>;
};


export type TAssetOutputFilesArgs = {
  filter?: InputMaybe<TFilterFileInput>;
  orderBy?: InputMaybe<TFileOrderEnum>;
  paging?: InputMaybe<TFilePageInput>;
};


export type TAssetOutputJobDetailArgs = {
  paging?: InputMaybe<TJobDetailPageableGql>;
};

export type TAssetPageInput = {
  pageNumber: Scalars['Int'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize: Scalars['Int'];
};

export enum TAssetStatusEnum {
  ASSET_COMPLETE = 'ASSET_COMPLETE',
  ASSET_EMPTY = 'ASSET_EMPTY',
  ASSET_INCOMPLETE = 'ASSET_INCOMPLETE'
}

export type TAssetThumbnailOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export enum TAssetTypeEnum {
  AUTOREGISTRATION_RESULT = 'AUTOREGISTRATION_RESULT',
  /** @deprecated In favour of AUTOREGISTRATION_RESULT */
  B2G_AUTO_REGISTRATION = 'B2G_AUTO_REGISTRATION',
  B2G_UPLOAD = 'B2G_UPLOAD',
  COARSE_REGISTRATION_UPLOAD = 'COARSE_REGISTRATION_UPLOAD',
  E57_UPLOAD = 'E57_UPLOAD',
  GENERIC_MULTI_FILE_UPLOAD = 'GENERIC_MULTI_FILE_UPLOAD',
  GENERIC_SINGLE_FILE_UPLOAD = 'GENERIC_SINGLE_FILE_UPLOAD',
  GLB_UPLOAD = 'GLB_UPLOAD',
  GPR_SURVEY_UPLOAD = 'GPR_SURVEY_UPLOAD',
  HSPC_UPLOAD = 'HSPC_UPLOAD',
  HXCP_PURCHASE = 'HXCP_PURCHASE',
  IFC_UPLOAD = 'IFC_UPLOAD',
  ISLE_FILE_UPLOAD = 'ISLE_FILE_UPLOAD',
  ISLE_METADATA_UPLOAD = 'ISLE_METADATA_UPLOAD',
  LAS_UPLOAD = 'LAS_UPLOAD',
  LGSX_UPLOAD = 'LGSX_UPLOAD',
  LGS_UPLOAD = 'LGS_UPLOAD',
  /** @deprecated In favour of GENERIC_SINGLE_FILE_UPLOAD or GENERIC_MULTI_FILE_UPLOAD */
  NOT_RECOGNIZED = 'NOT_RECOGNIZED',
  NOT_SET = 'NOT_SET',
  OBJ_UPLOAD = 'OBJ_UPLOAD',
  VECTOR_OR_RASTER_UPLOAD = 'VECTOR_OR_RASTER_UPLOAD'
}

/**
 * Result of query asset.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TAssetUnion = TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

/**
 * Result of query assetsInAccount.
 * Of type GroupedAssetResultOutput in case of success, otherwise one of the errors.
 */
export type TAssetsInAccountUnion = TAssetErrorOperationNotAllowedOutput | TGroupedAssetResultOutput;

/**
 * Result of query assetsInFolder.
 * Of type FolderOutput in case of success, otherwise one of the errors.
 */
export type TAssetsInFolderUnion = TAssetErrorOperationNotAllowedOutput | TFolderOutput;

export type TAssignAccountToTenantInput = {
  accountId: Scalars['ID'];
  tenantId?: InputMaybe<Scalars['ID']>;
};

export type TAssignTenantRoleToUserInput = {
  tenantRole?: InputMaybe<TTenantRoleEnum>;
  userId: Scalars['ID'];
};

/**
 * Result of mutation autoRegisterAssets.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TAutoRegisterAssetsUnion = TAssetErrorCoarseRegistrationInputNotFound | TAssetErrorCoarseRegistrationLimitOutput | TAssetErrorDuplicateNameOutput | TAssetErrorFeatureNotAvailableOutput | TAssetErrorGroupedAssetRegistrationLimitOutput | TAssetErrorGroupedAssetsNotUnderSameFolderOutput | TAssetErrorNameBlacklistedCharactersOutput | TAssetErrorNameBlankOutput | TAssetErrorNameForbiddenOutput | TAssetErrorNameForwardSlashOutput | TAssetErrorNameLeadingOrTrailingSpaceOutput | TAssetErrorNameTrailingDotOutput | TAssetErrorNameTwoDotsInRowOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorProcessingLimitReachedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorStorageLimitReachedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

export type TAutoRegistrationContentInput = {
  id: Scalars['ID'];
};

/**
 * AUTO_REGISTRATION TRIGGERING
 * * type:POINT_CLOUD
 * * inputs: list of grouped Asset UUIDS
 */
export type TAutoRegistrationInput = {
  inputs: Array<InputMaybe<TAutoRegistrationContentInput>>;
  type: TAutoRegistrationTypeEnum;
};

/**
 * AUTO_REGISTRATION TRIGGERING V2
 * * inputs: list of grouped Asset UUIDS
 */
export type TAutoRegistrationInputV2 = {
  inputs: Array<InputMaybe<TAutoRegistrationContentInput>>;
};

export enum TAutoRegistrationTypeEnum {
  POINT_CLOUD = 'POINT_CLOUD'
}

/** Basic error interface all soft errors are implementing */
export type TBaseAccountInfoErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseAcsErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseAnnotationErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseApplicationErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseAssetErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseFolderErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseLabelErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseLicenseErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseProcessingErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseProjectErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseQuotationErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseShopErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

/** Basic error interface all soft errors are implementing */
export type TBaseSubscriptionErrorInterface = {
  /**
   * A pseudo-user friendly explanation on what happened and how to fix the issue.
   * This will usually not be presented to the end user, but could be used as a fallback for backwards
   * compatibility in case the error __typename is not implemented yet on consumers.
   */
  message: Scalars['String'];
};

export type TBaselayerAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TBaselayerHspcOutput = TCoreBaselayer & {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export type TBaselayerLtsOutput = TCoreBaselayer & {
  bounds: Array<Scalars['Float']>;
  contentType: TAddressContentTypeEnum;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  level0Columns: Scalars['Int'];
  level0Rows: Scalars['Int'];
  levelCount: Scalars['Int'];
  reference: Scalars['String'];
  samplingMode: TAddressSamplingModeEnum;
  tileHeight: Scalars['Int'];
  tileWidth: Scalars['Int'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export type TBaselayerOgc3DOutput = TCoreBaselayer & {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  qualityFactor: Scalars['Float'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export enum TBaselayerOriginEnum {
  CATALOG = 'CATALOG',
  EXTERNAL_URL = 'EXTERNAL_URL',
  HXDR = 'HXDR'
}

export type TBaselayerOutput = TBaselayerHspcOutput | TBaselayerLtsOutput | TBaselayerOgc3DOutput | TBaselayerPanoramicOutput | TBaselayerUrlOutput | TBaselayerWfsOutput | TBaselayerWmsOutput | TBaselayerWmtsOutput;

export type TBaselayerPageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TBaselayerPanoramicOutput = TCoreBaselayer & {
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  imageServerPrefix: Scalars['String'];
  label: Scalars['String'];
  reference: Scalars['String'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export type TBaselayerResultOutput = {
  appliedPagination?: Maybe<TBaselayerAppliedPageOutput>;
  contents: Array<TBaselayerOutput>;
  filterOrigin?: Maybe<TBaselayerOriginEnum>;
  filterType?: Maybe<TAddressViewingModeEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TBaselayerUrlOutput = TCoreBaselayer & {
  boundsEndpoint: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export type TBaselayerWfsOutput = TCoreBaselayer & {
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  reference: Scalars['String'];
  styleUrl?: Maybe<Scalars['String']>;
  type: TAddressTypeEnum;
  versions: Array<Scalars['String']>;
  viewingMode: TAddressViewingModeEnum;
};

export type TBaselayerWmsOutput = TCoreBaselayer & {
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  imageFormat: Scalars['String'];
  label: Scalars['String'];
  reference: Scalars['String'];
  type: TAddressTypeEnum;
  versions: Array<Scalars['String']>;
  viewingMode: TAddressViewingModeEnum;
};

export type TBaselayerWmtsOutput = TCoreBaselayer & {
  bounds: Array<Scalars['Float']>;
  contentType: TAddressContentTypeEnum;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  imageFormat: Scalars['String'];
  label: Scalars['String'];
  level0Columns: Scalars['Int'];
  level0Rows: Scalars['Int'];
  levelCount: Scalars['Int'];
  reference: Scalars['String'];
  samplingMode: TAddressSamplingModeEnum;
  tileHeight: Scalars['Int'];
  tileMatrices: Array<Scalars['Int']>;
  tileMatrixSet: Scalars['String'];
  tileWidth: Scalars['Int'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export enum TBillingIntervalEnum {
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export type TCancelCurrentSubscriptionOutput = {
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Result of mutation cancelSubscription.
 * Of type CancelSubscriptionOutput in case of success, otherwise one of the errors.
 */
export type TCancelCurrentSubscriptionUnion = TCancelCurrentSubscriptionOutput | TSubscriptionErrorFeatureNotAvailableOutput;

export type TCartItemOutput = {
  amount: Scalars['BigDecimal'];
  bbox?: Maybe<Array<Scalars['Float']>>;
  cartId: Scalars['ID'];
  cartItemId: Scalars['ID'];
  coordinateSystem: Scalars['String'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  dataType: TDataTypeEnum;
  discountedAmount: Scalars['BigDecimal'];
  imageFormat: Scalars['String'];
  modifiedAt: Scalars['DateTime'];
  polygonDefType: TPolygonType;
  polygonPath?: Maybe<Scalars['String']>;
  productIdentifier: Scalars['String'];
  resolutionUnit: Scalars['String'];
  resolutionValue: Scalars['String'];
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type TCartItemThumbnailInput = {
  cartItemUUID: Scalars['ID'];
};

export type TCartItemThumbnailOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type TCartOutput = {
  amount: Scalars['BigDecimal'];
  cartId: Scalars['ID'];
  cartItems: Array<TCartItemOutput>;
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  discountedAmount: Scalars['BigDecimal'];
  modifiedAt: Scalars['DateTime'];
  orderId: Scalars['String'];
  paymentPublicKey: Scalars['String'];
  state: Scalars['String'];
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
  userId: Scalars['ID'];
};

export type TCartesianBoundsOutput = {
  dimensions: TReferencedBoundsDimensionsOutput;
  origin: TPoint3DOutput;
};

export type TChangeProjectRoleOutput = {
  result: Scalars['Boolean'];
};

/**
 * Result of nutation changeProjectRoleOfUserV2.
 * Of type ChangeProjectRoleOutput in case of success, otherwise one of the errors.
 */
export type TChangeProjectRoleUnion = TAcsErrorOperationNotAllowedOutput | TAcsErrorOperationNotPossibleOutput | TAcsErrorSeatLimitReachedOutput | TChangeProjectRoleOutput;

export type TCheckoutOutput = {
  amount: Scalars['BigDecimal'];
  cartId: Scalars['ID'];
  currency: Scalars['String'];
  discountedAmount: Scalars['BigDecimal'];
  paymentId: Scalars['ID'];
  paymentPublicKey: Scalars['ID'];
  paymentSessionId: Scalars['ID'];
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
};

/**
 * Information about the feature collection with id 'collectionId'.
 *
 * The response contains a link to the items in the collection
 * (path '/collections/{collectionId}/items', link relation 'items')
 * as well as key information about the collection. This information
 * includes:
 *
 * * A local identifier for the collection that is unique for the dataset;
 * * A list of coordinate reference systems (CRS) in which geometries may be returned by the server. The first CRS is the default coordinate reference system (the default is always WGS 84 with axis order longitude/latitude);
 * * An optional title and description for the collection;
 * * An optional extent that can be used to provide an indication of the spatial and temporal extent of the collection - typically derived from the data;
 * * An optional indicator about the type of the items in the collection (the default value, if the indicator is not provided, is 'feature').
 * *
 */
export type TCollection = {
  /** the list of coordinate reference systems supported by the service */
  crs?: Maybe<Array<Scalars['String']>>;
  /**
   * Detailed multi-line description to fully explain the catalog or
   *     collection.
   *
   *     [CommonMark 0.29](http://commonmark.org/) syntax MAY be used for rich
   *     text representation.
   */
  description: Scalars['String'];
  /**
   * The extent of the features in the collection. In the Core only spatial and temporal
   *   extents are specified. Extensions may add additional members to represent other
   *   extents, for example, thermal or pressure ranges.
   */
  extent: TExtent;
  features: TFeature;
  /** identifier of the collection used, for example, in URIs */
  id: Scalars['ID'];
  /** indicator about the type of the items in the collection (the default value is "feature"). */
  itemType: Scalars['String'];
  /** List of keywords describing the collection. */
  keywords: Array<Maybe<Scalars['String']>>;
  /**
   * License(s) of the data as a SPDX
   *   [License identifier](https://spdx.org/licenses/). Alternatively, use
   *   'proprietary' if the license is not on the SPDX license list or
   *   'various' if multiple licenses apply. In these two cases links to the
   *   license texts SHOULD be added, see the 'license' link relation type.
   *
   *   Non-SPDX licenses SHOULD add a link to the license text with the
   *   'license' relation in the links section. The license text MUST NOT be
   *   provided as a value of this field. If there is no public license URL
   *   available, it is RECOMMENDED to host the license text and
   *   link to it.
   */
  license: Scalars['String'];
  /** A list of providers, which may include all organizations capturing or processing the data or the hosting provider. Providers should be listed in chronological order with the most recent provider being the last element of the list. */
  providers?: Maybe<Array<TProvider>>;
  /** STAC version */
  stacVersion: Scalars['String'];
  /**
   * Summaries are either a unique set of all available values *or*
   * statistics. Statistics by default only specify the range (minimum
   * and maximum values), but can optionally be accompanied by additional
   * statistical values. The range can specify the potential range of
   * values, but it is recommended to be as precise as possible. The set
   * of values must contain at least one element and it is strongly
   * recommended to list all values. It is recommended to list as many
   * properties as reasonable so that consumers get a full overview of
   * the Collection. Properties that are covered by the Collection
   * specification (e.g. 'providers' and 'license') may not be repeated
   * in the summaries.
   */
  summaries?: Maybe<Array<TSummary>>;
  /** human readable title of the collection */
  title?: Maybe<Scalars['String']>;
};


/**
 * Information about the feature collection with id 'collectionId'.
 *
 * The response contains a link to the items in the collection
 * (path '/collections/{collectionId}/items', link relation 'items')
 * as well as key information about the collection. This information
 * includes:
 *
 * * A local identifier for the collection that is unique for the dataset;
 * * A list of coordinate reference systems (CRS) in which geometries may be returned by the server. The first CRS is the default coordinate reference system (the default is always WGS 84 with axis order longitude/latitude);
 * * An optional title and description for the collection;
 * * An optional extent that can be used to provide an indication of the spatial and temporal extent of the collection - typically derived from the data;
 * * An optional indicator about the type of the items in the collection (the default value, if the indicator is not provided, is 'feature').
 * *
 */
export type TCollectionFeaturesArgs = {
  bbox?: InputMaybe<Array<Scalars['Float']>>;
  dateTime?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/**
 * A comment made by an user on a specific item (or anchor). A comment itself
 * can have sub-comments in it children field.
 */
export type TComment = TNode & {
  anchor: TCommentAnchor;
  children: TCommentConnection;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<TSimpleUserProfileOutput>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  parentId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
};


/**
 * A comment made by an user on a specific item (or anchor). A comment itself
 * can have sub-comments in it children field.
 */
export type TCommentChildrenArgs = {
  pagination?: InputMaybe<TCommentPaginationInput>;
};

/**
 * The anchor the comment is attached on. Not that in case of commetn answers
 * the acnhor refers to the root object the answer is attached on, not the
 * parent comment itslef.
 */
export type TCommentAnchor = {
  id: Scalars['ID'];
  type: TCommentAnchorType;
};

export type TCommentAnchorInput = {
  id: Scalars['ID'];
  type: TCommentAnchorType;
};

/** Type of anchor the comment is attched to. */
export enum TCommentAnchorType {
  PROJECT = 'PROJECT'
}

export type TCommentConnection = TConnection & {
  edges: Array<TCommentEdge>;
  nodes: Array<TComment>;
  pageInfo: TPageInfo;
  totalCount: Scalars['Int'];
};

export type TCommentEdge = TEdge & {
  cursor: Scalars['String'];
  node: TComment;
};

export type TCommentPaginationInput = {
  after?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type TCommentPayload = TAccessDeniedError | TComment | TNotFoundError | TServerError | TUnauthenticatedError | TValidationError;

export type TCommentsPayload = TAccessDeniedError | TCommentConnection | TServerError | TUnauthenticatedError | TValidationError;

export type TConfirmSubscriptionOutput = {
  success?: Maybe<Scalars['Boolean']>;
};

/**
 * Result of mutation confirmSubscriptionOrder.
 * Of type ConfirmSubscriptionOutput in case of success, otherwise one of the errors.
 */
export type TConfirmSubscriptionUnion = TConfirmSubscriptionOutput;

/**
 * Cursor based pagination connection
 * @see https://dev.to/mandiwise/graphql-pagination-primer-offset-vs-cursor-vs-relay-style-pagination-1a60
 */
export type TConnection = {
  edges?: Maybe<Array<TEdge>>;
  nodes?: Maybe<Array<TNode>>;
  pageInfo: TPageInfo;
  totalCount: Scalars['Int'];
};

export type TCoordinateSystemOutput = {
  datum: Scalars['String'];
  epsg: Scalars['String'];
  projection?: Maybe<Scalars['String']>;
};

export type TCopyAssetInput = {
  folderId: Scalars['ID'];
  groupedAssetId: Scalars['ID'];
};

/**
 * Result of mutation copyAssetToFolder.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TCopyAssetToFolderUnion = TAssetErrorDuplicateNameOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorStorageLimitReachedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

export type TCoreBaselayer = {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
  viewingMode: TAddressViewingModeEnum;
};

export type TCoreLayer = {
  endpoint: Scalars['String'];
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  signatureExpiration?: Maybe<Scalars['DateTime']>;
  sourceEndpoint: Scalars['String'];
  type: Scalars['String'];
  viewingMode: Scalars['String'];
};

export type TCountryOutput = {
  /** true when an address is set with this country */
  addressesRequireRegion: Scalars['Boolean'];
  /** country iso code 3 letters */
  alpha3Code: Scalars['String'];
  /** Country iso code */
  countryIsoCode: Scalars['String'];
  countryName: Scalars['String'];
  isEuropeanUnion: Scalars['Boolean'];
  localizedNames: Array<Maybe<TLocalizedNameOutput>>;
};

export type TCreateAppClientInput = {
  callbackUrls: Array<Scalars['String']>;
  generateClientSecret: Scalars['Boolean'];
  logoutUrls: Array<Scalars['String']>;
  oAuthFlows: Array<TOAuthFlowTypeEnum>;
};

export type TCreateApplicationTemplateInput = {
  description?: InputMaybe<Scalars['String']>;
  developmentAppClient: TCreateAppClientInput;
  developmentHereMapsKey?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  productionAppClient: TCreateAppClientInput;
  productionHereMapsKey?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation createApplicationTemplate.
 * Of type ApplicationTemplateOutput in case of success, otherwise one of the errors.
 */
export type TCreateApplicationTemplateUnion = TApplicationErrorInvalidArgumentOutput | TApplicationErrorOperationNotAllowedOutput | TApplicationErrorTooManyRequestsOutput | TApplicationTemplateOutput;

export type TCreateAssetInput = {
  assetType?: InputMaybe<TAssetTypeEnum>;
  bbox?: InputMaybe<TAssetBBoxInput>;
  description?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type TCreateAssetInputV3 = {
  assetType?: InputMaybe<TAssetTypeEnum>;
  bbox?: InputMaybe<TAssetBBoxInput>;
  description?: InputMaybe<Scalars['String']>;
  folderId: Scalars['ID'];
  name: Scalars['String'];
};

/**
 * Result of mutation createAssetThumbnail.
 * Of type AssetThumbnailOutput in case of success, otherwise one of the errors.
 */
export type TCreateAssetThumbnailUnion = TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorTooManyRequestsOutput | TAssetThumbnailOutput;

/**
 * Result of mutation createAsset.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TCreateAssetUnion = TAssetErrorDuplicateNameOutput | TAssetErrorNameBlacklistedCharactersOutput | TAssetErrorNameBlankOutput | TAssetErrorNameForbiddenOutput | TAssetErrorNameForwardSlashOutput | TAssetErrorNameLeadingOrTrailingSpaceOutput | TAssetErrorNameTrailingDotOutput | TAssetErrorNameTwoDotsInRowOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

export type TCreateCartInput = {
  userUUID: Scalars['ID'];
};

export type TCreateCommentInput = {
  anchor: TCommentAnchorInput;
  content: Scalars['String'];
  parentCommentId?: InputMaybe<Scalars['ID']>;
};

export type TCreateFolderInput = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentFolderId: Scalars['ID'];
  projectId: Scalars['ID'];
};

/**
 * Result of mutation createFolder.
 * Of type FolderOutput in case of success, otherwise one of the errors.
 */
export type TCreateFolderUnion = TFolderErrorDuplicateNameOutput | TFolderErrorNameBlacklistedCharactersOutput | TFolderErrorNameBlankOutput | TFolderErrorNameForbiddenOutput | TFolderErrorNameForwardSlashOutput | TFolderErrorNameLeadingOrTrailingSpaceOutput | TFolderErrorNameTrailingDotOutput | TFolderErrorNameTwoDotsInRowOutput | TFolderErrorOperationNotAllowedOutput | TFolderErrorResourceAccessDeniedOutput | TFolderErrorTooManyRequestsOutput | TFolderOutput;

export type TCreateGeoreferenceInput = {
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: InputMaybe<Scalars['Float']>;
  groupedAssetId: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  visible: Array<TGeoreferenceVisibilityInput>;
  yaw: Scalars['Float'];
};

export type TCreateGroupedAssetGeoreferenceInput = {
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: InputMaybe<Scalars['Float']>;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  parentId: Scalars['ID'];
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  visible: Array<TGroupedAssetGeoreferenceVisibilityInput>;
  yaw: Scalars['Float'];
};

export type TCreateGroupedAssetTourInput = {
  /** Closed */
  closed?: InputMaybe<Scalars['Boolean']>;
  /** Description */
  description?: InputMaybe<Scalars['String']>;
  /** Grouped asset ID */
  groupedAssetId: Scalars['ID'];
  /** Keyframes */
  keyframes: Array<TGroupedAssetTourKeyframeCreateTourInput>;
  /** Name */
  name: Scalars['String'];
  /** Path tension */
  tension: Scalars['Float'];
};

export type TCreateGroupedAssetTourKeyframeInput = {
  /** Grouped asset ID */
  groupedAssetId: Scalars['ID'];
  /** Keyframe */
  keyframe: TGroupedAssetTourKeyframeInput;
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TCreateGroupedAssetTourThumbnailInput = {
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TCreateLabelAnnotationInput = {
  data?: InputMaybe<TLabelAnnotationDataInput>;
  description?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ID'];
  title: Scalars['String'];
};

/** The input for creating a new label annotation. */
export type TCreateLabelAnnotationInputV2 = {
  /** The label's data. */
  data: TLabelAnnotationDataInputV2;
  /** A description of the label. */
  description?: InputMaybe<Scalars['String']>;
  /** The label's parent ID. For example, the ID of the GroupedAsset. */
  parentId: Scalars['ID'];
  /** The label's title. */
  title: Scalars['String'];
};

/**
 * Result of mutation createLabelAnnotationV2.
 * Of type LabelAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TCreateLabelAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TLabelAnnotationOutput;

export type TCreateLimitingBoxAnnotationInput = {
  data?: InputMaybe<TLimitingBoxAnnotationDataInput>;
  description?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ID'];
  title: Scalars['String'];
};

/** The input for creating a new limiting box annotation. */
export type TCreateLimitingBoxAnnotationInputV2 = {
  /** The limiting box's data. */
  data: TLimitingBoxAnnotationDataInputV2;
  /** A description of the limiting box. */
  description?: InputMaybe<Scalars['String']>;
  /** The limiting box's parent ID. For example, the ID of the GroupedAsset. */
  parentId: Scalars['ID'];
  /** The limiting box's title. */
  title: Scalars['String'];
};

/**
 * Result of mutation createLimitingBoxAnnotationV2.
 * Of type LimitingBoxAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TCreateLimitingBoxAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TLimitingBoxAnnotationOutput;

export type TCreateMeasurementAnnotationInput = {
  data?: InputMaybe<TMeasurementAnnotationDataInput>;
  description?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ID'];
  title: Scalars['String'];
};

/** The input for creating a new measurement annotation. */
export type TCreateMeasurementAnnotationInputV2 = {
  /** The measurement's data. */
  data: TMeasurementAnnotationDataInputV2;
  /** A description of the measurement. */
  description?: InputMaybe<Scalars['String']>;
  /** The measurement's parent ID. For example, the ID of the GroupedAsset. */
  parentId: Scalars['ID'];
  /** The measurement's title. */
  title: Scalars['String'];
};

/**
 * Result of mutation createMeasurementAnnotationV2.
 * Of type MeasurementAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TCreateMeasurementAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TMeasurementAnnotationOutput;

export type TCreateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
};

/**
 * Result of mutation createProject.
 * Of type ProjectOutput in case of success, otherwise one of the errors.
 *
 * ProjectErrorDuplicateNameOutput is no longer used
 */
export type TCreateProjectUnion = TProjectErrorDuplicateNameOutput | TProjectErrorInvalidInputOutput | TProjectErrorNameBlacklistedCharactersOutput | TProjectErrorNameBlankOutput | TProjectErrorNameForbiddenOutput | TProjectErrorNameForwardSlashOutput | TProjectErrorNameLeadingOrTrailingSpaceOutput | TProjectErrorNameTrailingDotOutput | TProjectErrorNameTwoDotsInRowOutput | TProjectErrorOperationNotAllowedOutput | TProjectErrorTooManyRequestsOutput | TProjectOutput;

export type TCreateTenantInput = {
  defaultTenant?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type TCreationSubscriptionInput = {
  addonPriceIds: Array<InputMaybe<Scalars['ID']>>;
  billingInterval: TBillingIntervalEnum;
  seats: Scalars['Int'];
  tierPriceId: Scalars['ID'];
};

export type TCustomMetricEventGql = {
  details: Scalars['String'];
  key: Scalars['String'];
};

export type TCustomMetricGql = {
  event: TCustomMetricEventGql;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  userUUID?: InputMaybe<Scalars['ID']>;
};

export enum TDataCategoryEnum {
  APPLICATION_TEMPLATE = 'APPLICATION_TEMPLATE',
  FOLDER = 'FOLDER',
  IMAGE_2D_ORTHO = 'IMAGE_2D_ORTHO',
  INTERMEDIATE_RESULT = 'INTERMEDIATE_RESULT',
  LIDAR = 'LIDAR',
  MESH = 'MESH',
  PANORAMIC = 'PANORAMIC',
  POINT_CLOUD = 'POINT_CLOUD',
  PURCHASED_DATA = 'PURCHASED_DATA',
  VECTOR_OR_RASTER = 'VECTOR_OR_RASTER'
}

export enum TDataReadyStatusGql {
  ERROR = 'ERROR',
  OK = 'OK',
  REFUSED = 'REFUSED'
}

export enum TDataTypeEnum {
  LAND_COVER = 'LAND_COVER',
  LIDAR = 'LIDAR',
  MESH = 'MESH',
  ORTHO_2D = 'ORTHO_2D',
  POINT_CLOUD = 'POINT_CLOUD'
}

export type TDeleteAnnotationOutput = {
  success: Scalars['Boolean'];
};

/**
 * Result of mutation deleteAnnotationV2.
 * Of type DeleteAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TDeleteAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TDeleteAnnotationOutput;

export type TDeleteApplicationTemplateOutput = {
  success: Scalars['Boolean'];
};

/**
 * Result from mutation deleteApplicationTemplate.
 * Of type DeleteApplicationTemplateOutput in case of expected execution, otherwise one of the errors.
 */
export type TDeleteApplicationTemplateUnion = TApplicationErrorOperationNotAllowedOutput | TApplicationErrorResourceAccessDeniedOutput | TApplicationErrorTooManyRequestsOutput | TDeleteApplicationTemplateOutput;

export type TDeleteAssetOutput = {
  success: Scalars['Boolean'];
};

export type TDeleteAssetThumbnailOutput = {
  success: Scalars['Boolean'];
};

/**
 * Result of mutation deleteAssetThumbnail.
 * Of type DeleteAssetThumbnailOutput in case of success, otherwise one of the errors.
 */
export type TDeleteAssetThumbnailUnion = TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorTooManyRequestsOutput | TDeleteAssetThumbnailOutput;

/**
 * Result of mutation deleteAsset.
 * Of type DeleteAssetOutput in case of success, otherwise one of the errors.
 */
export type TDeleteAssetUnion = TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorTooManyRequestsOutput | TDeleteAssetOutput;

export type TDeleteCommentInput = {
  id: Scalars['ID'];
};

export type TDeleteFolderOutput = {
  success: Scalars['Boolean'];
};

/**
 * Result from mutation deleteFolder.
 * Of type DeleteFolderOutput in case of expected execution, otherwise one of the errors.
 */
export type TDeleteFolderUnion = TDeleteFolderOutput | TFolderErrorOperationNotAllowedOutput | TFolderErrorResourceAccessDeniedOutput | TFolderErrorResourceNotFoundOutput | TFolderErrorTooManyRequestsOutput;

export type TDeleteGroupedAssetTourInput = {
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TDeleteGroupedAssetTourKeyframeInput = {
  /** Tour ID */
  groupedAssetId: Scalars['ID'];
  /** Keyframe ID */
  keyframeId: Scalars['ID'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TDeleteGroupedAssetTourThumbnailInput = {
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TDeleteProjectOutput = {
  success: Scalars['Boolean'];
};

export type TDeleteProjectThumbnailOutput = {
  success: Scalars['Boolean'];
};

/**
 * Result of mutation deleteProjectThumbnail.
 * Of type DeleteProjectThumbnailOutput in case of expected behaviour, otherwise one of the errors.
 */
export type TDeleteProjectThumbnailUnion = TDeleteProjectThumbnailOutput | TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedOutput | TProjectErrorTooManyRequestsOutput;

/**
 * Result of mutation deleteProject.
 * Of type DeleteProjectOutput in case of expected behaviour, otherwise one of the errors.
 */
export type TDeleteProjectUnion = TDeleteProjectOutput | TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedOutput | TProjectErrorTooManyRequestsOutput;

export type TDeleteTenantInput = {
  id: Scalars['ID'];
  migrationTenantId?: InputMaybe<Scalars['ID']>;
};

export type TDeletedPaymentCardInput = {
  externalPaymentCardId: Scalars['String'];
};

export type TDeletedPaymentCardOutput = {
  deleted: Scalars['Boolean'];
  externalPaymentCardId: Scalars['String'];
};

export type TDeviceAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum TDeviceOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC'
}

export type TDeviceOutput = {
  createdAt: Scalars['DateTime'];
  deviceType: TDeviceTypeEnum;
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  provisionId?: Maybe<Scalars['ID']>;
  user?: Maybe<TUserProfileOutput>;
};

export enum TDeviceRegistrationStatusGql {
  CONFIRMED = 'CONFIRMED',
  ERROR = 'ERROR',
  NEW = 'NEW',
  REGISTERED = 'REGISTERED'
}

export type TDeviceResultOutput = {
  accountUUID: Scalars['ID'];
  contents: Array<TDeviceOutput>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TDeviceTypeEnum {
  BLK2FLY = 'BLK2FLY',
  BLK2GO = 'BLK2GO',
  BLK360 = 'BLK360',
  BLKARC = 'BLKARC',
  UNKNOWN = 'UNKNOWN'
}

export enum TDeviceTypeGql {
  BLK2GO = 'BLK2GO',
  BLK360 = 'BLK360',
  UNKNOWN = 'UNKNOWN'
}

export enum TDownloadTypeEnum {
  B2G = 'B2G',
  E57 = 'E57',
  GENERIC = 'GENERIC',
  IMAGES = 'IMAGES',
  JSON = 'JSON',
  LAS = 'LAS',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  OBJ = 'OBJ',
  PTS = 'PTS',
  ZIP = 'ZIP'
}

export type TEdge = {
  cursor: Scalars['String'];
  node: TNode;
};

export type TEntityReferenceInput = {
  entityId: Scalars['ID'];
  entityType: TEntityTypeEnum;
};

export type TEntityReferenceOutput = {
  entityId: Scalars['ID'];
  entityType: TEntityTypeEnum;
};

export enum TEntityTypeEnum {
  ASSET = 'ASSET',
  PROJECT = 'PROJECT'
}

export type TError = {
  /** A developer friendly error message on what happened and how to rectify it. */
  message: Scalars['String'];
};

export type TExceededLimitOutput = {
  addonAvailable: Scalars['Boolean'];
  currentValue: Scalars['BigInteger'];
  name: TSubscriptionLimitEnum;
  value: Scalars['BigInteger'];
};

export type TExecutedOutput = {
  executed: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export type TExtent = {
  /** The spatial extent of the features in the collection. */
  spatial: TPropertySpatial;
  /** The temporal extent of the features in the collection. */
  temporal: TPropertyTemporal;
};

export type TExternalAccountMembersResultUnion = TAccountMembersResultOutput | TAcsErrorOperationNotAllowedOutput;

export type TExternalProjectMemberInput = {
  email?: InputMaybe<Scalars['String']>;
  projectUUID?: InputMaybe<Scalars['ID']>;
};

export type TFeature = {
  contents: Array<Maybe<TFeatureItem>>;
  returned?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type TFeatureAsset = {
  /**
   * Multi-line description to explain the asset.
   *
   * [CommonMark 0.29](http://commonmark.org/) syntax MAY be used for
   * rich text representation.
   */
  description?: Maybe<Scalars['String']>;
  /** Link to the asset object */
  href?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Purposes of the asset */
  roles?: Maybe<Array<Scalars['String']>>;
  /** Displayed title */
  title?: Maybe<Scalars['String']>;
  /** Media type of the asset */
  type?: Maybe<Scalars['String']>;
};

export type TFeatureAssets = {
  /** Asset samples */
  samples?: Maybe<Array<TFeatureAsset>>;
  /** Asset shapefile */
  shapefile?: Maybe<TFeatureAsset>;
  /** Asset thumbnail */
  thumbnail: TFeatureAsset;
};

export type TFeatureItem = {
  assets: TFeatureAssets;
  /**
   * Each bounding box is provided as four or six numbers, depending on
   * whether the coordinate reference system includes a vertical axis
   * (height or depth):
   *
   * * Lower left corner, coordinate axis 1
   * * Lower left corner, coordinate axis 2
   * * Minimum value, coordinate axis 3 (optional)
   * * Upper right corner, coordinate axis 1
   * * Upper right corner, coordinate axis 2
   * * Maximum value, coordinate axis 3 (optional)
   *
   * The coordinate reference system of the values is WGS 84 longitude/latitude
   * (http://www.opengis.net/def/crs/OGC/1.3/CRS84) unless a different coordinate
   * reference system is specified in 'crs'.
   *
   * For WGS 84 longitude/latitude the values are in most cases the sequence of
   * minimum longitude, minimum latitude, maximum longitude and maximum latitude.
   * However, in cases where the box spans the antimeridian the first value
   * (west-most box edge) is larger than the third value (east-most box edge).
   *
   * If the vertical axis is included, the third and the sixth number are
   * the bottom and the top of the 3-dimensional bounding box.
   *
   * If a feature has multiple spatial geometry properties, it is the decision of the
   * server whether only a single spatial geometry property is used to determine
   * the extent or all relevant geometries.
   */
  bbox: Array<Scalars['Float']>;
  geometry: TGeoJsonShape;
  /** Provider identifier, a unique ID, potentially a link to a file. */
  id: Scalars['String'];
  properties: TFeatureProperties;
  /** STAC version */
  stacVersion: Scalars['String'];
  /** The GeoJSON type */
  type: TItemType;
};

export type TFeatureProperties = {
  /** Data type */
  data_type?: Maybe<Scalars['String']>;
  /**
   * Either a date-time or an interval, open or closed. Date and time
   * expressions adhere to RFC 3339. Open intervals are expressed using
   * double-dots.
   *
   * Examples:
   *
   * * A date-time: "2018-02-12T23:20:50Z"
   *
   * Only features that have a temporal property that intersects the value of
   * 'datetime' are selected.
   *
   * If a feature has multiple temporal properties, it is the decision of the
   * server whether only a single temporal property is used to determine
   * the extent or all relevant temporal properties.
   */
  dateTime?: Maybe<Scalars['DateTime']>;
  /** Detailed Geometry */
  detailedGeometry?: Maybe<TGeoJsonShape>;
  /**
   * In case the dateTime is empty, the startDateTime
   *     and the endDateTime need to provide a valid range
   */
  endDateTime?: Maybe<Scalars['DateTime']>;
  /** Data format */
  format?: Maybe<Scalars['String']>;
  /** Layer information for visual representation */
  layerAddress?: Maybe<TLayerAddress>;
  /** Product Code */
  productCode?: Maybe<Scalars['String']>;
  /** Provider */
  provider?: Maybe<Scalars['String']>;
  /** Reference to raw output */
  referenceToRawOutput?: Maybe<Array<Scalars['String']>>;
  /** Sensor Type */
  sensorType?: Maybe<Scalars['String']>;
  /** srs */
  srs?: Maybe<Scalars['String']>;
  /**
   * In case the dateTime is empty, the startDateTime
   *     and the endDateTime need to provide a valid range
   */
  startDateTime?: Maybe<Scalars['DateTime']>;
  /** Title */
  title?: Maybe<Scalars['String']>;
  /** Year */
  year?: Maybe<Scalars['Int']>;
};

export type TFileAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TFileFilterAppliedOutput = {
  byFileName?: Maybe<Scalars['String']>;
  byUploadStatus?: Maybe<Array<Maybe<TUploadStatusEnum>>>;
};

export enum TFileOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  SIZE_ASC = 'SIZE_ASC',
  SIZE_DESC = 'SIZE_DESC'
}

export type TFileOutput = {
  asset?: Maybe<TAssetOutput>;
  assetPath: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy: TSimpleUserProfileOutput;
  downloadLink: Scalars['String'];
  fileSize: Scalars['BigInteger'];
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  parts?: Maybe<TMultipartUploadPartsOutput>;
  path: Scalars['String'];
  status: TUploadStatusEnum;
  uploadExpiration?: Maybe<Scalars['DateTime']>;
};

export type TFilePageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TFileResultOutput = {
  appliedFilters?: Maybe<TFileFilterAppliedOutput>;
  appliedPagination?: Maybe<TFileAppliedPageOutput>;
  asset?: Maybe<TAssetOutput>;
  contents: Array<Maybe<TFileStatusOutput>>;
  orderBy?: Maybe<TFileOrderEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TFileStatusOutput = {
  asset?: Maybe<TAssetOutput>;
  files?: Maybe<Array<TFileOutput>>;
  total: Scalars['Int'];
  uploadStatus?: Maybe<TUploadStatusEnum>;
};

export type TFilterAccountMembersInput = {
  byNameOrEmail?: InputMaybe<Scalars['String']>;
};

export type TFilterAccountMembersOutput = {
  byNameOrEmail?: Maybe<Scalars['String']>;
};

export type TFilterAccountProfileAppliedOutput = {
  byCompanyName?: Maybe<Scalars['String']>;
  tenantUUID?: Maybe<Scalars['ID']>;
};

export type TFilterAccountProfileInput = {
  byCompanyName?: InputMaybe<Scalars['String']>;
  tenantUUID?: InputMaybe<Scalars['ID']>;
};

export type TFilterApplicationAppliedOutput = {
  byCreatedAt?: Maybe<Scalars['DateTime']>;
  byName?: Maybe<Scalars['String']>;
};

export type TFilterApplicationInput = {
  byCreatedAt?: InputMaybe<Scalars['DateTime']>;
  byName?: InputMaybe<Scalars['String']>;
};

export type TFilterAssetAppliedOutput = {
  byAssetName?: Maybe<Scalars['String']>;
  byAssetStatus?: Maybe<TAssetStatusEnum>;
  byAssetType?: Maybe<TAssetTypeEnum>;
  byGeoreferenceFlag?: Maybe<Scalars['Boolean']>;
  byLabels?: Maybe<Array<Scalars['ID']>>;
  not?: Maybe<TFilterAssetNotOutput>;
  or?: Maybe<TFilterAssetOrOutput>;
};

export type TFilterAssetInput = {
  byAssetName?: InputMaybe<Scalars['String']>;
  byAssetStatus?: InputMaybe<TAssetStatusEnum>;
  byAssetType?: InputMaybe<TAssetTypeEnum>;
  byGeoreferenceFlag?: InputMaybe<Scalars['Boolean']>;
  byLabels?: InputMaybe<Array<Scalars['ID']>>;
  not?: InputMaybe<TFilterAssetNotInput>;
  or?: InputMaybe<TFilterAssetOrInput>;
};

export type TFilterAssetNotInput = {
  byAssetStatus?: InputMaybe<TAssetStatusEnum>;
  byAssetType?: InputMaybe<TAssetTypeEnum>;
  not?: InputMaybe<TFilterAssetNotInput>;
};

export type TFilterAssetNotOutput = {
  byAssetStatus?: Maybe<TAssetStatusEnum>;
  byAssetType?: Maybe<TAssetTypeEnum>;
  not?: Maybe<TFilterAssetNotOutput>;
};

export type TFilterAssetOrInput = {
  byAssetStatus?: InputMaybe<TAssetStatusEnum>;
  byAssetType?: InputMaybe<TAssetTypeEnum>;
  or?: InputMaybe<TFilterAssetOrInput>;
};

export type TFilterAssetOrOutput = {
  byAssetStatus?: Maybe<TAssetStatusEnum>;
  byAssetType?: Maybe<TAssetTypeEnum>;
  or?: Maybe<TFilterAssetOrOutput>;
};

export type TFilterFileInput = {
  byFileId?: InputMaybe<Scalars['ID']>;
  byFileName?: InputMaybe<Scalars['String']>;
  byUploadStatus?: InputMaybe<Array<InputMaybe<TUploadStatusEnum>>>;
};

export type TFilterFolderAppliedOutput = {
  byFolderName?: Maybe<Scalars['String']>;
};

export type TFilterFolderInput = {
  byFolderName?: InputMaybe<Scalars['String']>;
};

export type TFilterProjectAppliedOutput = {
  byCreatedAt?: Maybe<Scalars['DateTime']>;
  byOwnedBy?: Maybe<Scalars['ID']>;
  byProjectName?: Maybe<Scalars['String']>;
};

export type TFilterProjectInput = {
  byCreatedAt?: InputMaybe<Scalars['DateTime']>;
  byOwnedBy?: InputMaybe<Scalars['ID']>;
  byProjectName?: InputMaybe<Scalars['String']>;
};

export type TFilterUserProfileAppliedOutput = {
  bySearchTerm?: Maybe<Scalars['String']>;
  tenantUUID?: Maybe<Scalars['ID']>;
};

export type TFilterUserProfileInput = {
  bySearchTerm?: InputMaybe<Scalars['String']>;
  byTenantRole?: InputMaybe<TTenantRoleEnum>;
  tenantUUID?: InputMaybe<Scalars['ID']>;
};

export type TFolderAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageOffset: Scalars['Int'];
  pageSize: Scalars['Int'];
};

/** A folder with the given name already exists at the same level. */
export type TFolderErrorDuplicateNameOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/**
 * The given name contains one or more blacklisted characters.
 * Blacklisted characters are:
 *
 * <,>,,:,\,|,?,*, ,#,$,+,%,!,`,&,',{,},",= and @
 */
export type TFolderErrorNameBlacklistedCharactersOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The given name is blank (empty, only spaces or null). */
export type TFolderErrorNameBlankOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/**
 * The given name is a forbidden name with any capitalisation and any extension.
 * Forbidden names are:
 *
 *   "CON", "PRN", "AUX", "NUL",
 *   "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
 *   "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9"
 */
export type TFolderErrorNameForbiddenOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a forward slash. */
export type TFolderErrorNameForwardSlashOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a leading or trailing space. */
export type TFolderErrorNameLeadingOrTrailingSpaceOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a trailing dot. */
export type TFolderErrorNameTrailingDotOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains two dots in a row. */
export type TFolderErrorNameTwoDotsInRowOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TFolderErrorOperationNotAllowedOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** Access to a requested resource denied. */
export type TFolderErrorResourceAccessDeniedOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The resource was not found in the system. */
export type TFolderErrorResourceNotFoundOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

/** The system is receiving too many requests. Try again later. */
export type TFolderErrorTooManyRequestsOutput = TBaseFolderErrorInterface & {
  message: Scalars['String'];
};

export enum TFolderOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export type TFolderOutput = {
  assets: TGroupedAssetResultOutput;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<TSimpleUserProfileOutput>;
  description?: Maybe<Scalars['String']>;
  folders: TFolderResultOutput;
  id: Scalars['ID'];
  isNestingLevelReached?: Maybe<Scalars['Boolean']>;
  isRootFolder?: Maybe<Scalars['Boolean']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  modifiedBy?: Maybe<TSimpleUserProfileOutput>;
  name: Scalars['String'];
  nestingLevel?: Maybe<Scalars['Int']>;
  parentFolder?: Maybe<TFolderOutput>;
  project?: Maybe<TProjectOutput>;
  version?: Maybe<Scalars['Int']>;
};


export type TFolderOutputAssetsArgs = {
  filter?: InputMaybe<TFilterAssetInput>;
  orderBy?: InputMaybe<TAssetOrderEnum>;
  paging?: InputMaybe<TAssetPageInput>;
};


export type TFolderOutputFoldersArgs = {
  filter?: InputMaybe<TFilterFolderInput>;
  orderBy?: InputMaybe<TFolderOrderEnum>;
  paging?: InputMaybe<TFolderPageInput>;
};

export type TFolderPageInput = {
  pageNumber: Scalars['Int'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize: Scalars['Int'];
};

export type TFolderResultOutput = {
  appliedFilters?: Maybe<TFilterFolderAppliedOutput>;
  appliedPagination?: Maybe<TFolderAppliedPageOutput>;
  contents: Array<Maybe<TFolderOutput>>;
  orderBy?: Maybe<TFolderOrderEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/**
 * Result of query folder.
 * Of type FolderOutput in case of success, otherwise one of the errors.
 */
export type TFolderUnion = TFolderErrorOperationNotAllowedOutput | TFolderErrorResourceAccessDeniedOutput | TFolderErrorResourceNotFoundOutput | TFolderErrorTooManyRequestsOutput | TFolderOutput;

export type TGatewayInfoOutput = {
  version: Scalars['String'];
};

/**
 * Result of nutation generateInvitation.
 * Of type InvitationOutput in case of success, otherwise one of the errors.
 */
export type TGenerateInvitationUnion = TAcsErrorOperationNotAllowedOutput | TAcsErrorSeatLimitReachedOutput | TAcsErrorUserAlreadyExistsInAccountOutput | TAcsErrorUserAlreadyExistsInOtherAccountOutput | TInvitationOutput;

export type TGenerateInviteInput = {
  accountRole: TAccountRoleEnum;
  email: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation GenerateMultiAssetDownloadLink.
 * Of type MultiAssetDownloadLinkOutput in case of success, otherwise one of the errors.
 */
export type TGenerateMultiAssetDownloadLinkUnion = TAssetErrorDownloadLimitReachedOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TMultiAssetDownloadLinkOutput;

export type TGenerateVoucherInput = {
  expireAt: Scalars['DateTime'];
  limitsToApply?: InputMaybe<Array<InputMaybe<TLimitToApplyInput>>>;
  reusable?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['ID']>;
  tenantRole?: InputMaybe<TTenantRoleEnum>;
  voucherType: TVoucherTypeEnum;
};

export enum TGeoJsonCoordinateType {
  LINESTRING = 'LineString',
  MULTILINESTRING = 'MultiLineString',
  MULTIPOINT = 'MultiPoint',
  MULTIPOLYGON = 'MultiPolygon',
  POINT = 'Point',
  POLYGON = 'Polygon'
}

export type TGeoJsonShape = {
  bbox?: Maybe<Array<Scalars['Float']>>;
  coordinates: Scalars['JSON'];
  type: TGeoJsonCoordinateType;
};

export type TGeoJsonShapeInput = {
  bbox?: InputMaybe<Array<Scalars['Float']>>;
  coordinates: Scalars['JSON'];
  type: TGeoJsonCoordinateType;
};

export type TGeoreferenceAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TGeoreferenceAppliedPaginationOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TGeoreferenceArtifactResultOutput = {
  artifact: TArtifactItemOutput;
  id: Scalars['ID'];
  visible: Scalars['Boolean'];
};

export type TGeoreferenceOutput = {
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  visible: Array<TGeoreferenceVisibilityOutput>;
  yaw: Scalars['Float'];
};

export type TGeoreferencePageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TGeoreferencePaginationInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TGeoreferenceResultsOutput = {
  appliedPagination?: Maybe<TGeoreferenceAppliedPageOutput>;
  contents: Array<Maybe<TGeoreferenceOutput>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

/** Deprecated */
export type TGeoreferenceVisibilityInput = {
  type: TArtifactTypeEnum;
  visible: Scalars['Boolean'];
};

/** Deprecated */
export type TGeoreferenceVisibilityOutput = {
  type: TArtifactTypeEnum;
  visible: Scalars['Boolean'];
};

export enum TGeoreferencedArtifactTypeEnum {
  IMAGE_2D_ORTHO = 'IMAGE_2D_ORTHO',
  LIDAR = 'LIDAR',
  MESH = 'MESH',
  PANORAMIC = 'PANORAMIC',
  POINT_CLOUD = 'POINT_CLOUD'
}

export type TGetGroupedAssetTourInput = {
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TGetProjectsInput = {
  filter?: InputMaybe<TFilterProjectInput>;
  orderBy?: InputMaybe<TProjectOrderEnum>;
  paging?: InputMaybe<TProjectPageInput>;
};

export type TGroupedAssetGeoreferenceOutput = {
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  artifacts: Array<TGeoreferenceArtifactResultOutput>;
  createdAt: Scalars['DateTime'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
  parentId: Scalars['ID'];
  parentType: TParentTypeEnum;
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  yaw: Scalars['Float'];
};

export type TGroupedAssetGeoreferenceResultsOutput = {
  appliedPagination?: Maybe<TGeoreferenceAppliedPaginationOutput>;
  contents: Array<Maybe<TGroupedAssetGeoreferenceOutput>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TGroupedAssetGeoreferenceVisibilityInput = {
  type: TGeoreferencedArtifactTypeEnum;
  visible: Scalars['Boolean'];
};

export type TGroupedAssetOutput = {
  asset: TAssetOutput;
  assetSize: Scalars['BigInteger'];
  assetStatus?: Maybe<TAssetStatusEnum>;
  assetType: TAssetTypeEnum;
  bbox?: Maybe<TAssetBBoxOutput>;
  createdAt: Scalars['DateTime'];
  createdBy: TSimpleUserProfileOutput;
  description?: Maybe<Scalars['String']>;
  downloadLink: Scalars['String'];
  folder: TFolderOutput;
  /** @deprecated use groupedAssetGeoreferences */
  georeferences?: Maybe<TGeoreferenceResultsOutput>;
  groupedAssetGeoreferences?: Maybe<TGroupedAssetGeoreferenceResultsOutput>;
  id: Scalars['ID'];
  labelAnnotations?: Maybe<Array<TLabelAnnotationOutput>>;
  labels: TLabelsOutput;
  limitingBoxAnnotations?: Maybe<Array<TLimitingBoxAnnotationOutput>>;
  linkedGroupedAssets?: Maybe<Array<Scalars['String']>>;
  measurementAnnotations?: Maybe<Array<TMeasurementAnnotationOutput>>;
  modifiedAt: Scalars['DateTime'];
  modifiedBy?: Maybe<TSimpleUserProfileOutput>;
  name: Scalars['String'];
  sharingCode?: Maybe<Scalars['ID']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  thumbnailSignatureValidUntil?: Maybe<Scalars['DateTime']>;
  tours: TGroupedAssetTourResultOutput;
};


export type TGroupedAssetOutputGeoreferencesArgs = {
  paging?: InputMaybe<TGeoreferencePageInput>;
};


export type TGroupedAssetOutputGroupedAssetGeoreferencesArgs = {
  paging?: InputMaybe<TGeoreferencePaginationInput>;
};


export type TGroupedAssetOutputToursArgs = {
  params?: InputMaybe<TTourInput>;
};

export type TGroupedAssetPropertiesCreateTourInput = {
  /** Visible limiting box annotations */
  selectedLimitingBoxAnnotation?: InputMaybe<Scalars['ID']>;
  /** Visible artifacts */
  visibleArtifacts?: InputMaybe<Array<Scalars['ID']>>;
  /** Visible label annotations */
  visibleLabelAnnotations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Visible measurement annotations */
  visibleMeasurementAnnotations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TGroupedAssetPropertiesInput = {
  /** Visible limiting box annotations */
  selectedLimitingBoxAnnotation?: InputMaybe<Scalars['ID']>;
  /** Visible artifacts */
  visibleArtifacts?: InputMaybe<Array<Scalars['ID']>>;
  /** Visible label annotations */
  visibleLabelAnnotations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Visible measurement annotations */
  visibleMeasurementAnnotations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TGroupedAssetPropertiesOutput = {
  selectedLimitingBoxAnnotation?: Maybe<Scalars['ID']>;
  visibleArtifacts?: Maybe<Array<Scalars['ID']>>;
  visibleLabelAnnotations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  visibleMeasurementAnnotations?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type TGroupedAssetPropertiesUpdateInput = {
  /** Selected limiting box annotations */
  selectedLimitingBoxAnnotation?: InputMaybe<Scalars['ID']>;
  /** Visible artifacts */
  visibleArtifacts?: InputMaybe<Array<Scalars['ID']>>;
  /** Visible label annotations */
  visibleLabelAnnotations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Visible measurement annotations */
  visibleMeasurementAnnotations?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type TGroupedAssetResultOutput = {
  appliedFilters?: Maybe<TFilterAssetAppliedOutput>;
  appliedPagination?: Maybe<TAssetAppliedPageOutput>;
  contents: Array<TGroupedAssetOutput>;
  orderBy?: Maybe<TAssetOrderEnum>;
  pageNumber: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  total: Scalars['Int'];
};

export type TGroupedAssetTourKeyframeCreateTourInput = {
  /** Camera viewpoint */
  cameraViewpoint: TTourCameraViewpointCreateTourInput;
  /** Duration from tour start */
  durationFromTourStart: Scalars['BigInteger'];
  /** Properties */
  properties: TGroupedAssetPropertiesCreateTourInput;
  /** Path tension */
  tension: Scalars['Float'];
};

export type TGroupedAssetTourKeyframeInput = {
  /** Camera viewpoint */
  cameraViewpoint: TTourCameraViewpointInput;
  /** Duration from tour start */
  durationFromTourStart: Scalars['BigInteger'];
  /** Properties */
  properties?: InputMaybe<TGroupedAssetPropertiesInput>;
  /** Path tension */
  tension: Scalars['Float'];
};

export type TGroupedAssetTourKeyframeOutput = TTourKeyframe & {
  /** Camera viewpoint */
  cameraViewpoint: TTourCameraViewpointOutput;
  /** Duration from tour start */
  durationFromTourStart: Scalars['BigInteger'];
  /** Output tour keyframe ID */
  id: Scalars['ID'];
  /** Properties */
  properties?: Maybe<TGroupedAssetPropertiesOutput>;
  /** Path tension */
  tension: Scalars['Float'];
};

export type TGroupedAssetTourKeyframeUpdateInput = {
  /** Camera viewpoint */
  cameraViewpoint: TTourCameraViewpointUpdateInput;
  /** Duration from tour start */
  durationFromTourStart: Scalars['BigInteger'];
  /** Properties */
  properties?: InputMaybe<TGroupedAssetPropertiesUpdateInput>;
  /** Path tension */
  tension: Scalars['Float'];
};

export type TGroupedAssetTourOutput = TTour & {
  /** Is true if the user can edit the tour */
  canEdit: Scalars['Boolean'];
  /** Closed */
  closed?: Maybe<Scalars['Boolean']>;
  /** Date and time the tour was created at */
  createdAt: Scalars['DateTime'];
  /** Name of the user that created the tour */
  createdBy: TSimpleUserProfileOutput;
  /** Tour description */
  description?: Maybe<Scalars['String']>;
  /** Duration of the tour */
  duration: Scalars['Float'];
  /** Grouped asset tour ID */
  id: Scalars['ID'];
  /** Tour keyframes */
  keyframes?: Maybe<Array<TGroupedAssetTourKeyframeOutput>>;
  /** Date and time the tour was last modified at */
  modifiedAt: Scalars['DateTime'];
  /** Tour name */
  name: Scalars['String'];
  /** Path tension */
  tension: Scalars['Float'];
  /** Thumbnail path */
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type TGroupedAssetTourResultOutput = {
  /** Applied filters */
  appliedFilters: TTourFilterAppliedOutput;
  /** Applied pagination */
  appliedPagination: TTourAppliedPageOutput;
  /** Contents */
  contents?: Maybe<Array<TGroupedAssetTourOutput>>;
  /** Property by which tour result is ordered by */
  orderBy: TTourOrderEnum;
  /** Page number */
  pageNumber: Scalars['Int'];
  /** Page size */
  pageSize: Scalars['Int'];
  /** Total */
  total: Scalars['Int'];
};

export type THspcInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  endpoint: Scalars['String'];
  label: Scalars['String'];
  viewingMode: TAddressViewingModeEnum;
};

export type THxdrProductOutput = TPointCloudSourceLidarOutput | TProduct2DAerialOutput;

export enum TImplementedPipeline {
  MELOWN_VEF_TO_VEF_CLIP = 'MELOWN_VEF_TO_VEF_CLIP',
  MYVR_HSPC_TO_LAS = 'MYVR_HSPC_TO_LAS'
}

export type TInfoGql = {
  activeActivities: Array<Maybe<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type TInfoPage = {
  _pageNumber: Scalars['Int'];
  _pageSize: Scalars['Int'];
  contents: Array<TInfoGql>;
};

export type TInvitationOutput = {
  registrationLink: Scalars['String'];
};

export enum TInvoiceStatusEnum {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  VOID = 'VOID'
}

/** A GeoJSON FeatureCollection augmented with foreign members that contain values relevant to a STAC entity */
export type TItemCollection = {
  contents: Array<Maybe<TFeatureItem>>;
  returned?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  type: TItemCollectionType;
};

export enum TItemCollectionType {
  FEATURECOLLECTION = 'FeatureCollection'
}

export enum TItemType {
  FEATURE = 'Feature'
}

export type TJobDetailPageableGql = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TJobPage = {
  _pageNumber: Scalars['Int'];
  _pageSize: Scalars['Int'];
  contents: Array<TSubProcessGql>;
  parentId: Scalars['ID'];
  total: Scalars['Int'];
};

export enum TJobStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOTHING_TO_DO = 'NOTHING_TO_DO',
  QUEUED = 'QUEUED',
  STARTED = 'STARTED',
  SUBMITTED = 'SUBMITTED',
  TOO_MANY_RETRIES = 'TOO_MANY_RETRIES'
}

export enum TJobStatusGql {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  FAILURE = 'FAILURE',
  IN_PROGRESS = 'IN_PROGRESS',
  STARTED = 'STARTED',
  SUBMITTED = 'SUBMITTED'
}

export type TJobSummaryGql = {
  jobs: Array<TSummaryItemGql>;
  parentId: Scalars['ID'];
};

export enum TJobType {
  B2G = 'B2G',
  CONVERT_TO_PTS = 'CONVERT_TO_PTS',
  MESH = 'MESH',
  PANORAMIC = 'PANORAMIC',
  POINT_CLOUD = 'POINT_CLOUD',
  UNDEFINED = 'UNDEFINED'
}

export enum TJobTypeGql {
  FILE_UPLOAD = 'FILE_UPLOAD',
  FUSION_REGISTER = 'FUSION_REGISTER',
  PROCESS = 'PROCESS'
}

export type TLtsInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  bounds: Array<Scalars['Float']>;
  contentType: TAddressContentTypeEnum;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  label: Scalars['String'];
  level0Columns: Scalars['Int'];
  level0Rows: Scalars['Int'];
  levelCount: Scalars['Int'];
  reference: Scalars['String'];
  samplingMode: TAddressSamplingModeEnum;
  tileHeight: Scalars['Int'];
  tileWidth: Scalars['Int'];
  viewingMode: TAddressViewingModeEnum;
};

export type TLabelAnnotationDataInput = {
  lookAt?: InputMaybe<TAnnotationOrientationInput>;
  position?: InputMaybe<TAnnotationPointInput>;
};

export type TLabelAnnotationDataInputV2 = {
  /** The camera point of view to look at this label. */
  lookAt: TAnnotationOrientationInput;
  /** The label position. Usually this is defined in the CRS of the asset. */
  position: TAnnotationPointInput;
};

export type TLabelAnnotationDataOutput = {
  /** The camera point of view to look at this label. */
  lookAt: TAnnotationOrientationOutput;
  /** The label position. Usually this is defined in the CRS of the asset. */
  position: TAnnotationPointOutput;
};

export type TLabelAnnotationOutput = {
  /** The label's creation time. */
  createdAt: Scalars['DateTime'];
  /** The user who created this label. */
  createdBy: TSimpleUserProfileOutput;
  /** The label's data. */
  data: TLabelAnnotationDataOutput;
  /** The label's description. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The label's latest modification time. */
  modifiedAt: Scalars['DateTime'];
  /** The latest user to modify this label. */
  modifiedBy: TSimpleUserProfileOutput;
  /** The label's thumbnail path. */
  thumbnailPath?: Maybe<Scalars['String']>;
  /** The label's title. */
  title: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TLabelErrorOperationNotAllowedOutput = TBaseLabelErrorInterface & {
  message: Scalars['String'];
};

/** A project with the given name already exists at the same level. */
export type TLabelErrorTooManyLabelsOutput = TBaseLabelErrorInterface & {
  message: Scalars['String'];
};

export type TLabelInput = {
  name: Scalars['String'];
};

export type TLabelOutput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type TLabelsOutput = {
  content: Array<Maybe<TLabelOutput>>;
};

export type TLabelsResultOutput = {
  content: Array<Maybe<TLabelOutput>>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TLayerAddress = TLayerAddressHspc | TLayerAddressLts | TLayerAddressOgc3DTiles | TLayerAddressWms | TLayerAddressWmts;

export type TLayerAddressHspc = TCoreLayer & {
  endpoint: Scalars['String'];
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  signatureExpiration?: Maybe<Scalars['DateTime']>;
  sourceEndpoint: Scalars['String'];
  type: Scalars['String'];
  viewingMode: Scalars['String'];
};

export type TLayerAddressLts = TCoreLayer & {
  bounds: Array<Scalars['Float']>;
  contentType: Scalars['Float'];
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  level0Columns: Scalars['Float'];
  level0Rows: Scalars['Float'];
  levelCount: Scalars['Float'];
  reference: Scalars['String'];
  samplingMode: Scalars['Float'];
  signatureExpiration?: Maybe<Scalars['DateTime']>;
  sourceEndpoint: Scalars['String'];
  tileHeight: Scalars['Float'];
  tileWidth: Scalars['Float'];
  type: Scalars['String'];
  viewingMode: Scalars['String'];
};

export type TLayerAddressOgc3DTiles = TCoreLayer & {
  bounds: Array<Scalars['Float']>;
  boundsLLH: Array<Scalars['Float']>;
  endpoint: Scalars['String'];
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  qualityFactor: Scalars['Float'];
  signatureExpiration?: Maybe<Scalars['DateTime']>;
  sourceEndpoint: Scalars['String'];
  type: Scalars['String'];
  viewingMode: Scalars['String'];
};

export type TLayerAddressWms = TCoreLayer & {
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['String'];
  imageFormat?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  reference: Scalars['String'];
  signatureExpiration?: Maybe<Scalars['DateTime']>;
  sourceEndpoint: Scalars['String'];
  type: Scalars['String'];
  versions?: Maybe<Array<Scalars['String']>>;
  viewingMode: Scalars['String'];
};

export type TLayerAddressWmts = TCoreLayer & {
  bounds: Array<Scalars['Float']>;
  contentType: Scalars['Float'];
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['String'];
  imageFormat?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  level0Columns: Scalars['Float'];
  level0Rows: Scalars['Float'];
  levelCount: Scalars['Float'];
  reference: Scalars['String'];
  samplingMode: Scalars['Float'];
  signatureExpiration?: Maybe<Scalars['DateTime']>;
  sourceEndpoint: Scalars['String'];
  tileHeight: Scalars['Float'];
  tileMatrices: Array<Scalars['Float']>;
  tileMatrixSet: Scalars['String'];
  tileWidth: Scalars['Float'];
  type: Scalars['String'];
  viewingMode: Scalars['String'];
};

/** One or more of the input properties are invalid */
export type TLicenseErrorInvalidArgumentOutput = TBaseLicenseErrorInterface & {
  message: Scalars['String'];
};

/** The license could not be retrieved. */
export type TLicenseErrorLicenseNotAvailableOutput = TBaseLicenseErrorInterface & {
  message: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TLicenseErrorOperationNotAllowedOutput = TBaseLicenseErrorInterface & {
  message: Scalars['String'];
};

/** The system is receiving too many requests. Try again later. */
export type TLicenseErrorTooManyRequestsOutput = TBaseLicenseErrorInterface & {
  message: Scalars['String'];
};

export type TLimitToApplyInput = {
  limit: TAccountLimitEnum;
  limitValidity: TLimitValidityInput;
  value: Scalars['BigInteger'];
};

export type TLimitToApplyOutput = {
  limit: TAccountLimitEnum;
  limitExpirationDate: Scalars['DateTime'];
  value: Scalars['Int'];
};

/**
 * The limits in this voucher will be valid
 * for (years, months, days) after being activated.
 */
export type TLimitValidityInput = {
  days: Scalars['Int'];
  months: Scalars['Int'];
  years: Scalars['Int'];
};

export type TLimitingBoxAnnotationDataInput = {
  lookAt?: InputMaybe<TAnnotationOrientationInput>;
  maxX?: InputMaybe<Scalars['Float']>;
  maxY?: InputMaybe<Scalars['Float']>;
  maxZ?: InputMaybe<Scalars['Float']>;
  minX?: InputMaybe<Scalars['Float']>;
  minY?: InputMaybe<Scalars['Float']>;
  minZ?: InputMaybe<Scalars['Float']>;
  rotation?: InputMaybe<TAnnotationRotationInput>;
};

export type TLimitingBoxAnnotationDataInputV2 = {
  /** The camera point of view to look at this limiting box. */
  lookAt: TAnnotationOrientationInput;
  /** The limiting box's maximum X coordinate. */
  maxX: Scalars['Float'];
  /** The limiting box's maximum Y coordinate. */
  maxY: Scalars['Float'];
  /** The limiting box's maximum Z coordinate. */
  maxZ: Scalars['Float'];
  /** The limiting box's minimum X coordinate. */
  minX: Scalars['Float'];
  /** The limiting box's minimum Y coordinate. */
  minY: Scalars['Float'];
  /** The limiting box's minimum Z coordinate. */
  minZ: Scalars['Float'];
  /**
   * The limiting box's offset.  The offset is applied after the rotation.
   * When undefined, the offset is set to 0 on all axes.
   */
  offset?: InputMaybe<TAnnotationPointInput>;
  /**
   * The limiting box's rotation, around the asset's center point. The rotation is applied before the offset.
   * When undefined, the rotation is set to 0 on all axes.
   */
  rotation?: InputMaybe<TAnnotationRotationInput>;
};

export type TLimitingBoxAnnotationDataOutput = {
  /** The camera point of view to look at this limiting box. */
  lookAt: TAnnotationOrientationOutput;
  /** The limiting box's maximum X coordinate. */
  maxX: Scalars['Float'];
  /** The limiting box's maximum Y coordinate. */
  maxY: Scalars['Float'];
  /** The limiting box's maximum Z coordinate. */
  maxZ: Scalars['Float'];
  /** The limiting box's minimum X coordinate. */
  minX: Scalars['Float'];
  /** The limiting box's minimum Y coordinate. */
  minY: Scalars['Float'];
  /** The limiting box's minimum Z coordinate. */
  minZ: Scalars['Float'];
  /** The limiting box's offset. The offset is applied after the rotation. */
  offset: TAnnotationPointOutput;
  /** The limiting box's rotation, around the asset's center point. The rotation is applied before the offset. */
  rotation: TAnnotationRotationOutput;
};

export type TLimitingBoxAnnotationOutput = {
  /** The limiting box's creation time. */
  createdAt: Scalars['DateTime'];
  /** The user who created this limiting box. */
  createdBy: TSimpleUserProfileOutput;
  /** The limiting box's data. */
  data: TLimitingBoxAnnotationDataOutput;
  /** The limiting box's description. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The limiting box's latest modification time. */
  modifiedAt: Scalars['DateTime'];
  /** The latest user to modify this limiting box. */
  modifiedBy: TSimpleUserProfileOutput;
  /** The limiting box's thumbnail path. */
  thumbnailPath?: Maybe<Scalars['String']>;
  /** The limiting box's title. */
  title: Scalars['String'];
};

export type TLinkDeviceInput = {
  deviceSN: Scalars['ID'];
  deviceType: TDeviceTypeEnum;
  name?: InputMaybe<Scalars['String']>;
  provisioningId: Scalars['ID'];
};

export type TListOfUserProjectRoleOutput = {
  users: Array<TUserProjectRoleOutput>;
};

export type TLocalizedNameOutput = {
  languageAlpha2Code?: Maybe<Scalars['String']>;
  languageAlpha3Code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TLuciadRiaLicenseOutput = {
  appClientId: Scalars['String'];
  contents: Scalars['String'];
  luciadRIAVersion: Scalars['String'];
};

/**
 * Result of query LuciadRIA license.
 * Of type LuciadRIALicenseOutput in case of success, otherwise one of the errors.
 */
export type TLuciadRiaLicenseUnion = TLicenseErrorInvalidArgumentOutput | TLicenseErrorLicenseNotAvailableOutput | TLicenseErrorOperationNotAllowedOutput | TLuciadRiaLicenseOutput;

export type TMeasurementAnnotationDataInput = {
  lookAt?: InputMaybe<TAnnotationOrientationInput>;
  measurementType?: InputMaybe<TMeasurementAnnotationEnum>;
  points?: InputMaybe<Array<InputMaybe<TAnnotationPointInput>>>;
};

export type TMeasurementAnnotationDataInputV2 = {
  /** The camera point of view to look at this measurement. */
  lookAt: TAnnotationOrientationInput;
  /** The measurement type. */
  measurementType: TMeasurementAnnotationEnum;
  /** The measurement points. Usually these are defined in the CRS of the asset. */
  points: Array<TAnnotationPointInput>;
};

export type TMeasurementAnnotationDataOutput = {
  /** The camera point of view to look at this measurement. */
  lookAt: TAnnotationOrientationOutput;
  /** The measurement type. */
  measurementType: TMeasurementAnnotationEnum;
  /** The measurement points. Usually these are defined in the CRS of the asset. */
  points: Array<TAnnotationPointOutput>;
};

export enum TMeasurementAnnotationEnum {
  AREA = 'AREA',
  ORTHOGONAL = 'ORTHOGONAL',
  PLANAR_AREA = 'PLANAR_AREA'
}

export type TMeasurementAnnotationOutput = {
  /** The measurement's creation time. */
  createdAt: Scalars['DateTime'];
  /** The user who created this measurement. */
  createdBy: TSimpleUserProfileOutput;
  /** The measurement's data. */
  data: TMeasurementAnnotationDataOutput;
  /** The measurement's description. */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** The measurement's latest modification time. */
  modifiedAt: Scalars['DateTime'];
  /** The latest user to modify this measurement. */
  modifiedBy: TSimpleUserProfileOutput;
  /** The measurement's thumbnail path. */
  thumbnailPath?: Maybe<Scalars['String']>;
  /** The measurement's title. */
  title: Scalars['String'];
};

export type TMoveAssetInput = {
  folderId: Scalars['ID'];
  groupedAssetId: Scalars['ID'];
};

/**
 * Result of mutation moveAssetToFolder.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TMoveAssetToFolderUnion = TAssetErrorDuplicateNameOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorStorageLimitReachedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

export type TMultiAssetDownloadLinkInput = {
  groupedAssetUUIDList: Array<Scalars['ID']>;
  resultFileName: Scalars['String'];
};

export type TMultiAssetDownloadLinkOutput = {
  multiAssetDownloadLink: Scalars['String'];
};

export type TMultipartUploadCompleteInput = {
  fileId: Scalars['ID'];
  multipartUploadsETags?: InputMaybe<Array<TMultipartUploadETagInput>>;
};

export type TMultipartUploadETagInput = {
  etag: Scalars['String'];
  part: Scalars['Int'];
};

export type TMultipartUploadETagOutput = {
  etag: Scalars['String'];
  partNumber: Scalars['Int'];
  size: Scalars['BigInteger'];
};

export type TMultipartUploadPartsOutput = {
  multipartUploadsETags: Array<Maybe<TMultipartUploadETagOutput>>;
  totalUploadedSize: Scalars['BigInteger'];
  uploadExpirationDate?: Maybe<Scalars['DateTime']>;
};

export type TMultipartUploadUrlInput = {
  fileId: Scalars['ID'];
  md5?: InputMaybe<Scalars['String']>;
  partNumber: Scalars['Int'];
};

export type TMultipartUploadUrlOutput = {
  expirationDate: Scalars['DateTime'];
  fileId: Scalars['ID'];
  partNumber: Scalars['Int'];
  uploadUrl: Scalars['String'];
};

/**
 * Result of mutation getMultipartUploadURL.
 * Of type MultipartUploadUrlOutput in case of success, otherwise one of the errors.
 */
export type TMultipartUploadUrlUnion = TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorStorageLimitReachedOutput | TMultipartUploadUrlOutput;

export type TMutation = {
  _noop?: Maybe<Scalars['Boolean']>;
  /** Add user to project via email address */
  addExternalUserToProject?: Maybe<Scalars['Boolean']>;
  /**
   * **DEPRECATED in favor of mutation `addFileV2`**
   *
   * Given a file and its groupedAssetId it belongs to, this method will generate the metadata
   * for it, the remote path on the remote bucket, add it to the connected Asset and return
   * the file information with its remote path and expiration date.
   * @deprecated No longer supported, in favor of mutation `addFileV2`, termination planed for 2023-07-31
   */
  addFile?: Maybe<TFileOutput>;
  /**
   * Given a file and its groupedAssetId it belongs to, this method will generate the metadata
   * for it, the remote path on the remote bucket, add it to the connected Asset and return
   * the file information with its remote path and expiration date.
   */
  addFileV2?: Maybe<TAddFileUnion>;
  /** Add an item to the cart */
  addItemToCart: TCartOutput;
  /** Adds labels to the referred entity */
  addLabels: TAddLabelsUnion;
  /**
   * **DEPRECATED in favour of mutation addUserToProjectV2**
   *
   * Adds a user to the project as project member.
   * The user must belong to the same account as the project owner.
   * @deprecated No longer supported, in favour of mutation addUserToProjectV2, termination planned for 2023-07-31
   */
  addUserToProject: Scalars['Boolean'];
  /**
   * Adds a user to the project as project member.
   * The user must belong to the same account as the project owner.
   */
  addUserToProjectV2: TSimpleResultUnion;
  /**
   * **DEPRECATED in favour of mutation assignAccountToTenantV2**
   *
   * Assigns an account to a tenant.
   * @deprecated No longer supported, in favour of mutation assignAccountToTenantV2, termination planned for 2023-07-31
   */
  assignAccountToTenant: Scalars['Boolean'];
  /** Assigns an account to a tenant. */
  assignAccountToTenantV2: TSimpleResultUnion;
  /**
   * **DEPRECATED in favour of mutation assignTenantRoleToUserV2**
   *
   * Assigns an account to a tenant.
   * @deprecated No longer supported, in favour of mutation assignTenantRoleToUserV2, termination planned for 2023-07-31
   */
  assignTenantRoleToUser: Scalars['Boolean'];
  /** Assigns an account to a tenant. */
  assignTenantRoleToUserV2: TSimpleResultUnion;
  /**
   * **DEPRECATED in favor of mutation `autoRegisterAssetsV2`**
   *
   * Given a list of asset uuids, this method returns the new asset created in the folder specified by the user,
   * with the name specified by the user; The result will contain the point-cloud generated by auto-registering the
   * assets given as an input
   * @deprecated No longer supported, in favor of mutation `autoRegisterAssetsV2`, termination planed for 2023-07-31
   */
  autoRegisterAssets: TGroupedAssetOutput;
  /**
   * Given a list of asset uuids, this method returns the new asset created in the folder specified by the user,
   * with the name specified by the user; The result will contain the point-cloud generated by auto-registering the
   * assets given as an input
   */
  autoRegisterAssetsV2?: Maybe<TAutoRegisterAssetsUnion>;
  /**
   * **DEPRECATED in favor of mutation `buySubscriptionV2`**
   *
   * Buy a subscription by create it into payment system.
   * @deprecated No longer supported, in favor of mutation `buySubscriptionV2`, termination planed for 2023-07-31
   */
  buySubscription: TSubscriptionOutput;
  /** Buy a subscription by create it into payment system. */
  buySubscriptionV2: TSubscriptionUnion;
  /**
   * **DEPRECATED in favor of mutation `cancelCurrentSubscriptionV2`**
   * @deprecated No longer supported, in favor of mutation `cancelSubscriptionV2`, termination planed for 2023-07-31
   */
  cancelCurrentSubscription: Scalars['Boolean'];
  /** Cancel current subscription from payment system. */
  cancelCurrentSubscriptionV2: TCancelCurrentSubscriptionUnion;
  /**
   * **DEPRECATED in favour of mutation changeAccountRoleOfUserV2**
   *
   * Changes the role of another user within an account.
   * The acting user must have the CAN_CHANGE_ACCOUNT_ROLE capability
   * and the mutation must target a user from the same account.
   * @deprecated No longer supported, in favour of mutation changeAccountRoleOfUserV2, termination planned for 2023-07-31
   */
  changeAccountRoleOfUser: Scalars['Boolean'];
  /**
   * Changes the role of another user within an account.
   * The acting user must have the CAN_CHANGE_ACCOUNT_ROLE capability
   * and the mutation must target a user from the same account.
   */
  changeAccountRoleOfUserV2: TSimpleResultUnion;
  /**
   * **DEPRECATED in favour of mutation changeProjectRoleOfUserV2**
   *
   * Changes the role of another user within a project.
   * Can only target members of the project. Use `addUserToProject` if you want
   * to add a new member with a specific role.
   *
   * Changing a project role is restricted to project owners / editors, and
   * the account admin. If a user is promoted to Project Owner this way,
   * the previous owner gets demoted to Project Editor.
   * @deprecated No longer supported, in favour of mutation changeProjectRoleOfUserV2, termination planned for 2023-07-31
   */
  changeProjectRoleOfUser: Scalars['Boolean'];
  /**
   * Changes the role of another user within a project.
   * Can only target members of the project. Use `addUserToProject` if you want
   * to add a new member with a specific role.
   *
   * Changing a project role is restricted to project owners / editors, and
   * the account admin. If a user is promoted to Project Owner this way,
   * the previous owner gets demoted to Project Editor.
   */
  changeProjectRoleOfUserV2: TChangeProjectRoleUnion;
  /**
   * Given the groupedAssetId, this method marks the asset as complete.
   * It returns a boolean indicating if all the files are in complete status
   */
  completeAssetFileList?: Maybe<TExecutedOutput>;
  /**
   * This method allows the front end to flag all the multiparts of the given file to be flagged
   * as confirmed. The method will return a boolean to flag if the operation was carried out
   * successfully.
   */
  completeMultipartUpload?: Maybe<TExecutedOutput>;
  /**
   * **DEPRECATED in favor of mutation `confirmSubscriptionOrderV2`**
   * @deprecated No longer supported, in favor of mutation `confirmSubscriptionOrderV2`, termination planed for 2023-07-31
   */
  confirmSubscriptionOrder: Scalars['Boolean'];
  /** **DEPRECATED in favor of mutation `buySubscriptionV2`** */
  confirmSubscriptionOrderV2: TConfirmSubscriptionUnion;
  /**
   * **DEPRECATED in favor of mutation `copyAssetToFolderV2`**
   *
   * Copies the specified asset to a folder, provided both exist and the user has the capability
   * to modify both folders. The copied asset has a new ID.
   *
   * Returns true if the operation was successful.
   * @deprecated No longer supported, in favor of mutation `copyAssetToFolderV2`, termination planed for 2023-07-31
   */
  copyAssetToFolder: Scalars['Boolean'];
  /**
   * Copies the specified asset to a folder, provided both exist and the user has the capability
   * to modify both folders. The copied asset has a new ID.
   *
   * Returns true if the operation was successful.
   */
  copyAssetToFolderV2?: Maybe<TCopyAssetToFolderUnion>;
  /**
   * Creates an application template. Returns the newly created application template if the operation was successful,
   * null otherwise.
   */
  createApplicationTemplate: TCreateApplicationTemplateUnion;
  /**
   * **DEPRECATED in favor of mutation `createAssetV3`, termination planed for 2023-08-31**
   *
   * Creates an asset in the specified folder.
   *
   * The folderId is optional, if not provided then the asset will be created in the
   * root folder of the MyAssetsProject.
   * @deprecated No longer supported, in favor of mutation `createAssetV2`, termination planed for 2023-08-31
   */
  createAsset?: Maybe<TGroupedAssetOutput>;
  /**
   * **DEPRECATED in favor of mutation `createAssetThumbnailV2`**
   *
   * Creates a thumbnail on the asset, provided it exists and the user has access to it.
   *
   * Returns the asset thumbnail if the operation was successful.
   * @deprecated No longer supported, in favor of mutation `createAssetThumbnailV2`, termination planed for 2023-07-31
   */
  createAssetThumbnail?: Maybe<TAssetThumbnailOutput>;
  /**
   * Creates a thumbnail on the asset, provided it exists and the user has access to it.
   *
   * Returns the asset thumbnail if the operation was successful.
   */
  createAssetThumbnailV2?: Maybe<TCreateAssetThumbnailUnion>;
  /**
   * **DEPRECATED in favor of mutation `createAssetV3`**
   *
   * Creates an asset in the specified folder.
   *
   * The folderId is optional, if not provided then the asset will be created in the
   * root folder of the MyAssetsProject.
   * @deprecated No longer supported, in favor of mutation `createAssetV2`, termination planed for 2023-07-31
   */
  createAssetV2?: Maybe<TCreateAssetUnion>;
  /**
   * Creates an asset in the specified folder.
   *
   * The folderId is mandatory, no fallback is provided.
   */
  createAssetV3?: Maybe<TCreateAssetUnion>;
  createComment: TCommentPayload;
  /**
   * **DEPRECATED in favor of mutation `createFolderV2`**
   *
   * Creates a folder on the specified parent folder (either project root folder
   * or any other folder), provided that the user has the capability to edit
   * this project.
   *
   * Returns the newly created folder if the operation was successful, null
   * otherwise.
   * @deprecated No longer supported, in favour of mutation `createFolderV2`, termination planed for 2023-07-31
   */
  createFolder?: Maybe<TFolderOutput>;
  /**
   * Creates a folder on the specified parent folder (either project root folder
   * or any other folder), provided that the user has the capability to edit
   * this project.
   *
   * Returns the newly created folder if the operation was successful, null
   * otherwise.
   */
  createFolderV2?: Maybe<TCreateFolderUnion>;
  /**
   * @deprecated
   * reason: "Use createGroupedAssetGeoreference instead"
   * @deprecated Use createGroupedAssetGeoreference instead
   */
  createGeoreference: TGeoreferenceOutput;
  createGroupedAssetGeoreference: TGroupedAssetGeoreferenceOutput;
  createGroupedAssetTour: TGroupedAssetTourOutput;
  createGroupedAssetTourKeyframe: TGroupedAssetTourKeyframeOutput;
  createGroupedAssetTourThumbnail: TThumbnailOutput;
  createHspcBaselayer: TBaselayerOutput;
  /**
   * *DEPRECATED in favour of mutation createLabelAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `createLabelAnnotationV2`, termination planned for 2023-07-31
   */
  createLabelAnnotation: TLabelAnnotationOutput;
  /** Creates a new label annotation with the given properties. */
  createLabelAnnotationV2: TCreateLabelAnnotationUnion;
  /**
   * *DEPRECATED in favour of mutation createLimitingBoxAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `createLimitingBoxAnnotationV2`, termination planned for 2023-07-31
   */
  createLimitingBoxAnnotation: TLimitingBoxAnnotationOutput;
  /** Creates a new limiting box annotation with the given properties. */
  createLimitingBoxAnnotationV2: TCreateLimitingBoxAnnotationUnion;
  createLtsBaselayer: TBaselayerOutput;
  /**
   * *DEPRECATED in favour of mutation createMeasurementAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `createMeasurementAnnotationV2`, termination planned for 2023-07-31
   */
  createMeasurementAnnotation: TMeasurementAnnotationOutput;
  /** Creates a new measurement annotation with the given properties. */
  createMeasurementAnnotationV2: TCreateMeasurementAnnotationUnion;
  createOgc3dBaselayer: TBaselayerOutput;
  createPanoramicBaselayer: TBaselayerOutput;
  /**
   * **DEPRECATED in favor of mutation `createProjectV2`**
   *
   * Creates a project for the user, returns the project if project creation
   * was successful, null otherwise.
   * @deprecated No longer supported, in favour of mutation `createProjectV2`, termination planed for 2023-08-31
   */
  createProject?: Maybe<TProjectOutput>;
  /**
   * Creates a project for the user, returns the project if project creation
   * was successful, null otherwise.
   */
  createProjectV2?: Maybe<TCreateProjectUnion>;
  /**
   * **DEPRECATED in favour of mutation createTenantV2**
   *
   * Creates a new tenant. Only accessible to application admins
   * @deprecated No longer supported, in favour of mutation createTenantV2, termination planned for 2023-07-31
   */
  createTenant: TTenantOutput;
  /** Creates a new tenant. Only accessible to application admins */
  createTenantV2: TTenantUnion;
  createUrlBaselayer: TBaselayerOutput;
  createWfsBaselayer: TBaselayerOutput;
  createWmsBaselayer: TBaselayerOutput;
  createWmtsBaselayer: TBaselayerOutput;
  customMetric: Scalars['Boolean'];
  /**
   * *DEPRECATED in favour of mutation deleteAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `deleteAnnotationV2`, termination planned for 2023-07-31
   */
  deleteAnnotation?: Maybe<Scalars['Boolean']>;
  /** Deletes the annotation with the given ID. */
  deleteAnnotationV2: TDeleteAnnotationUnion;
  /** Deletes an application template. Returns true if the operation was successful. */
  deleteApplicationTemplate: TDeleteApplicationTemplateUnion;
  /**
   * **DEPRECATED in favor of mutation `deleteAssetV2`**
   *
   * Deletes the asset from the folder, provided it exists and the user has the capability.
   * If the asset was copied to any other folders or projects, it will not be affected by this deletion.
   * If it is the last instance of the asset, it will be deleted entirely.
   *
   * Returns true if the operation was successful.
   * @deprecated No longer supported, in favor of mutation `deleteAssetV2`, termination planed for 2023-07-31
   */
  deleteAsset: Scalars['Boolean'];
  /**
   * Removes an asset thumbnail if one exists, provided the user has access to it.
   *
   * Returns true if the operation was successful.
   */
  deleteAssetThumbnail: TDeleteAssetThumbnailUnion;
  /**
   * Deletes the asset from the folder, provided it exists and the user has the capability.
   * If the asset was copied to any other folders or projects, it will not be affected by this deletion.
   * If it is the last instance of the asset, it will be deleted entirely.
   *
   * Returns true if the operation was successful.
   */
  deleteAssetV2: TDeleteAssetUnion;
  /** Delete the cart entirely */
  deleteCart: Scalars['Boolean'];
  deleteComment: TCommentPayload;
  /**
   * Allows a user to delete a device if it is a device that belongs to their account.
   *
   * The deviceId is the serial number of the device.
   */
  deleteDevice: Scalars['Boolean'];
  /**
   *  **DEPRECATED in favor of mutation `deleteFolderV2`**
   *
   * Deletes a folder from a project, provided that the user has the capability to
   * edit this project.
   *
   * This deletion will propagate a deletion to all nested subfolders and all assets
   * contained in them.
   *
   * Returns true if the operation was successful.
   * @deprecated No longer supported, in favour of mutation `deleteFolderV2`, termination planed for 2023-07-31
   */
  deleteFolder: Scalars['Boolean'];
  /**
   * Deletes a folder from a project, provided that the user has the capability to
   * edit this project.
   *
   * This deletion will propagate a deletion to all nested subfolders and all assets
   * contained in them.
   *
   * Returns true if the operation was successful.
   */
  deleteFolderV2: TDeleteFolderUnion;
  /**
   * @deprecated
   * reason: "Use deleteGroupedAssetGeoreference instead"
   * @deprecated Use deleteGroupedAssetGeoreference instead
   */
  deleteGeoreference: Scalars['Boolean'];
  deleteGroupedAssetGeoreference: Scalars['Boolean'];
  deleteGroupedAssetTour: Scalars['Boolean'];
  deleteGroupedAssetTourKeyframe: Scalars['Boolean'];
  deleteGroupedAssetTourThumbnail: Scalars['Boolean'];
  /**
   * Delete card in external payment system
   *
   * This Mutation can be called only with a bearer token.
   */
  deletePaymentCard: TDeletedPaymentCardOutput;
  /**
   * **DEPRECATED in favor of mutation `deleteProjectV2`**
   *
   * Deletes the specified project.
   *
   * This deletion will trigger a deletion of all nested folders and any assets
   * they may contain.
   *
   * Returns true if the project was successfully deleted.
   * @deprecated No longer supported, in favour of mutation `deleteProjectV2`, termination planed for 2023-07-31
   */
  deleteProject: Scalars['Boolean'];
  /**
   * Removes the specified project's thumbnail, if it exists.
   *
   * Returns true if the operation was successful.
   */
  deleteProjectThumbnail?: Maybe<TDeleteProjectThumbnailUnion>;
  /**
   * Deletes the specified project.
   *
   * This deletion will trigger a deletion of all nested folders and any assets
   * they may contain.
   *
   * Returns true if the project was successfully deleted.
   */
  deleteProjectV2?: Maybe<TDeleteProjectUnion>;
  /**
   * **DEPRECATED in favour of mutation deleteTenantV2**
   *
   * Deletes the tenant from the system.
   * If the migration tenant is supplied, all the accounts of the deleted tenant will be migrated to that tenant.
   * Otherwise the default tenant will be used.
   * @deprecated No longer supported, in favour of mutation deleteTenantV2, termination planned for 2023-07-31
   */
  deleteTenant: TTenantDeletionOutput;
  /**
   * Deletes the tenant from the system.
   * If the migration tenant is supplied, all the accounts of the deleted tenant will be migrated to that tenant.
   * Otherwise the default tenant will be used.
   */
  deleteTenantV2: TTenantDeletionUnion;
  /**
   * **DEPRECATED in favour of mutation deleteUserV2**
   *
   * Deletes the user from the system.
   * @deprecated No longer supported, in favour of mutation deleteUserV2, termination planned for 2023-07-31
   */
  deleteUser?: Maybe<TUserDeletionOutput>;
  /** Deletes the user from the system. */
  deleteUserV2: TUserDeletionUnion;
  /**
   * Call back for the Front end that the payment with stripe was successful
   * and the cart now needs to wait for the call back from stripe
   */
  finalizePayment?: Maybe<TCartOutput>;
  /**
   * **DEPRECATED in favour of mutation generateInvitationV2**
   *
   * Generates an invitation containing a signed URL that can be used to signup a user to an existing account.
   * The user will receive the specified account user role.
   * @deprecated No longer supported, in favour of mutation generateInvitationV2, termination planned for 2023-07-31
   */
  generateInvitation?: Maybe<TInvitationOutput>;
  /**
   * Generates an invitation containing a signed URL that can be used to signup a user to an existing account.
   * The user will receive the specified account user role.
   */
  generateInvitationV2: TGenerateInvitationUnion;
  /**
   * **DEPRECATED in favor of mutation `generateMultiAssetDownloadLinkV2`**
   *
   * Generates a download link for the multiple grouped asset uuids received as input
   * The result will be a zip package
   * @deprecated No longer supported, in favor of mutation `generateMultiAssetDownloadLinkV2`, termination planed for 2023-07-31
   */
  generateMultiAssetDownloadLink?: Maybe<TMultiAssetDownloadLinkOutput>;
  /**
   * Generates a download link for the multiple grouped asset uuids received as input
   * The result will be a zip package
   */
  generateMultiAssetDownloadLinkV2?: Maybe<TGenerateMultiAssetDownloadLinkUnion>;
  /** Trigger sales report generation */
  generateReport: TReportGenerationOutput;
  /**
   * **DEPRECATED in favour of mutation generateVoucherV2**
   *
   * Generates a voucher containing a signed URL that can be used to signup a new user in a new account.
   * The user will receive the account ADMIN user role.
   * @deprecated No longer supported, in favour of mutation generateVoucherV2, termination planned for 2023-07-31
   */
  generateVoucher?: Maybe<TVoucherOutput>;
  /**
   * Generates a voucher containing a signed URL that can be used to signup a new user in a new account.
   * The user will receive the account ADMIN user role.
   */
  generateVoucherV2: TVoucherUnion;
  /**
   * **DEPRECATED in favour of mutation leaveProjectV2**
   *
   * Allows a user to leave a project.
   *
   * The project owner cannot leave the project; they must first call changeProjectRoleOfUser
   * and promote another member of the project to owner before calling this endpoint.
   *
   * Throws a 403 if the user has no access to this project, and 400 if the user is an owner
   * or not a member of the project.
   * @deprecated No longer supported, in favour of mutation leaveProjectV2, termination planned for 2023-07-31
   */
  leaveProject: Scalars['Boolean'];
  /**
   * Allows a user to leave a project.
   *
   * The project owner cannot leave the project; they must first call changeProjectRoleOfUser
   * and promote another member of the project to owner before calling this endpoint.
   *
   * Throws a 403 if the user has no access to this project, and 400 if the user is an owner
   * or not a member of the project.
   */
  leaveProjectV2: TSimpleResultUnion;
  /**
   * Allows any user to register a new device or link an existing device to their account.
   *
   * Throws a 400 Bad Request if you try to link an existing device with a different type than
   * it is registered with.
   */
  linkDevice?: Maybe<TDeviceOutput>;
  /**
   * **DEPRECATED in favor of mutation `moveAssetToFolderV2`**
   *
   * Movies the specified asset to a folder, provided both exist and the user has the capability
   * to modify both folders. The moved asset retains the same ID as before.
   *
   * Returns true if the operation was successful.
   * @deprecated No longer supported, in favor of mutation `moveAssetToFolderV2`, termination planed for 2023-07-31
   */
  moveAssetToFolder: Scalars['Boolean'];
  /**
   * Movies the specified asset to a folder, provided both exist and the user has the capability
   * to modify both folders. The moved asset retains the same ID as before.
   *
   * Returns true if the operation was successful.
   */
  moveAssetToFolderV2?: Maybe<TMoveAssetToFolderUnion>;
  persistArtifact: TArtifactItemOutput;
  /**
   * **DEPRECATED in favor of mutation `persistAssetV2`**
   *
   * Application-Admin endpoint to persist an Asset. Is mostly used for testing purposes.
   * @deprecated No longer supported, in favor of mutation `persistAssetV2`, termination planed for 2023-07-31
   */
  persistAsset?: Maybe<TGroupedAssetOutput>;
  /** Application-Admin endpoint to persist an Asset. Is mostly used for testing purposes. */
  persistAssetV2?: Maybe<TPersistAssetUnion>;
  /**
   * Initiate the payment of the items in the cart
   * If the payment fails the cart is perserved
   */
  placeOrder: TCheckoutOutput;
  publishAccountMetrics: Scalars['Boolean'];
  publishUserMetrics: Scalars['Boolean'];
  recalculateGroupedAssetTourKeyframes: TGroupedAssetTourOutput;
  /** Recalculates the used storage for the requested users, all users if none specified */
  recalculateUsedStorage: Scalars['Boolean'];
  /** Refund tokens for an account */
  refundTokens: TWalletRefundUnion;
  /**
   * **DEPRECATED in favor of mutation `deleteAssetThumbnail`**
   *
   * Removes an asset thumbnail if one exists, provided the user has access to it.
   *
   * Returns true if the operation was successful.
   * @deprecated No longer supported, in favor of mutation `deleteAssetThumbnail`, termination planed for 2023-07-31
   */
  removeAssetThumbnail: Scalars['Boolean'];
  /** Remove an item from the cart */
  removeItemFromCart: TCartOutput;
  /** Removes labels from the referred entity */
  removeLabels: TRemoveLabelsUnion;
  /** Removes the profile picture of the currently logged in user. */
  removeProfilePicture: Scalars['Boolean'];
  /**
   * **DEPRECATED in favor of mutation `deleteProjectThumbnail`**
   *
   * Removes the specified project's thumbnail, if it exists.
   *
   * Returns true if the operation was successful.
   * @deprecated No longer supported, in favour of mutation `deleteProjectThumbnail`, termination planed for 2023-07-31
   */
  removeProjectThumbnail: Scalars['Boolean'];
  /** Remove consent from the users list of consents */
  removeUserFlag?: Maybe<Scalars['Boolean']>;
  /**
   * **DEPRECATED in favour of mutation removeUserFromProjectV2**
   *
   * Removes a user from a project.
   * Their uploaded assets, folders, and other contributions to the project
   * remain unchanged on the project.
   * @deprecated No longer supported, in favour of mutation removeUserFromProjectV2, termination planned for 2023-07-31
   */
  removeUserFromProject: Scalars['Boolean'];
  /**
   * Removes a user from a project.
   * Their uploaded assets, folders, and other contributions to the project
   * remain unchanged on the project.
   */
  removeUserFromProjectV2: TSimpleResultUnion;
  /** put back the cart from payment pending to Active state */
  resetPayment?: Maybe<Scalars['Boolean']>;
  sampleAccountMetrics: Scalars['Boolean'];
  sampleUserMetrics: Scalars['Boolean'];
  /**
   * Update the  account info adding additional information to it.
   * This Mutation can be called only with a bearer token.
   *
   * Application Admin only.
   */
  setAccountProperties: TAccountInfoOutput;
  /**
   * Set active payment card for subscription
   *
   * This Mutation can be called only with a bearer token.
   */
  setActivePaymentCard: TSetActivePaymentCardOutput;
  /**
   * **DEPRECATED in favour of mutation setDefaultTenantV2**
   *
   * Sets a tenant as the default tenant. Only accessible to application admins
   * @deprecated No longer supported, in favour of mutation setDefaultTenantV2, termination planned for 2023-07-31
   */
  setDefaultTenant: TTenantOutput;
  /** Sets a tenant as the default tenant. Only accessible to application admins */
  setDefaultTenantV2: TTenantUnion;
  /** Updates the time for the last consent */
  setUploadConsent?: Maybe<Scalars['DateTime']>;
  /** Adds consent to the users list of consents */
  setUserFlag?: Maybe<Scalars['Boolean']>;
  /**
   * Create a setup intent so that to create card in external payment system
   *
   * This Mutation can be called only with a bearer token.
   */
  setupPaymentCardCreation: TSetupPaymentCardCreationOutput;
  /** Generates a code for sharing the grouped asset */
  shareGroupedAsset: TSharingCodeUnion;
  /**
   * **DEPRECATED in favour of mutation signupV2**
   *
   * Creates a new account with a new account admin, using the provided details.
   * If the signature is provided, the information inside the signature will be used.
   *
   * Current version of HxDR only allows signature-based sign ups.
   * @deprecated No longer supported, in favour of mutation signupV2, termination planned for 2023-07-31
   */
  signup?: Maybe<TSignupOutput>;
  /**
   * **DEPRECATED in favour of mutation signupFromInviteV2**
   * Creates the user with the provided details based on a sent out invite, protected by a provided signature.
   *
   * The user is created within the account specified in the SignupFromInviteInput.
   * @deprecated No longer supported, in favour of mutation signupFromInviteV2, termination planned for 2023-07-31
   */
  signupFromInvite?: Maybe<TSignupOutput>;
  /**
   * Creates the user with the provided details based on a sent out invite, protected by a provided signature.
   *
   * The user is created within the account specified in the SignupFromInviteInput.
   */
  signupFromInviteV2: TSignupFromInviteUnion;
  /**
   * Creates a new account with a new account admin, using the provided details.
   * If the signature is provided, the information inside the signature will be used.
   *
   * Current version of HxDR only allows signature-based sign ups.
   */
  signupV2: TSignupUnion;
  stopPendingSubscriptionCancellation: Scalars['Boolean'];
  stopPendingSubscriptionDowngrade: Scalars['Boolean'];
  /**
   * This graphql mutation allows a user to trigger MYVR_HSPC_TO_LAS pipeline
   *
   * Additionally a prerequisite to trigger this pipeline, is for the user to perform the `uploadPolygon` mutation,
   * and upload the geojson/shape file via the returned presigned S3 url. The path returned by the above mutation, needs to be
   * used to execute the `triggerHSPCClipperProcessingPipeline` mutation.
   *
   * Successfully triggered pipeline will have the following status: "CREATED"
   */
  triggerHSPCClipperProcessingPipeline?: Maybe<TProcessingPipelineTriggerOutput>;
  /**
   * *DEPRECATED in favor of mutation `triggerMeshingV2`*
   *
   * Manually trigger the meshing pipeline
   *
   * Input: only groupedAssetUUID required
   * @deprecated No longer supported, in favor of mutation `triggerMeshingV2`, termination planed for 2023-07-31
   */
  triggerMeshing: TTriggerProcessingUnion;
  /**
   * Manually trigger the meshing pipeline v2
   *
   * Input: TriggerMeshingInput where grouped assetUUID and ScanningDeviceType are required
   */
  triggerMeshingV2: TTriggerProcessingUnion;
  /**
   * *DEPRECATED in favour of mutation triggerProcessingV2*
   *
   * Manually trigger the processing pipeline defined in the input parameter
   * @deprecated No longer supported, in favor of mutation `triggerProcessingV2`, termination planned for 2023-07-31
   */
  triggerProcessing: Scalars['Boolean'];
  /** Manually trigger the processing pipeline defined in the input parameter */
  triggerProcessingV2: TTriggerProcessingUnion;
  /**
   * This graphql mutation allows a user to trigger shop processing pipelines.
   * Currently there is only one pipeline that is implemented, this is the MELOWN_VEF_TO_VEF_CLIP pipeline.
   *
   * To trigger this pipeline an Cyclomedia bearer token is required.
   *
   * Additionally a prerequisite to trigger this pipeline, is for the user to perform the `uploadPolygon` mutation,
   * and upload the geojson via the returned presigned S3 url. The path returned by the above mutation, needs to be
   * used to execute the `triggerShopProcessingPipeline` mutation.
   *
   * Successfully triggered pipeline will have the following status: "CREATED"
   */
  triggerShopProcessingPipeline?: Maybe<TProcessingPipelineTriggerOutput>;
  /** Removes the access to a shared grouped asset */
  unshareGroupedAsset: TUnsharedGroupedAssetUnion;
  /**
   * Updates the details of the account the logged in user belongs to.
   * should guarantee that the return values are already reflected in the cache
   *
   * This Mutation can be called only with a bearer token.
   */
  updateAccount: TAccountInfoOutput;
  /**
   * Updates the details of the account the logged in user belongs to.
   * should guarantee that the return values are already reflected in the cache
   *
   * This Mutation can be called only with a bearer token.
   */
  updateAccountV2: TAccountInfoUpdateUnion;
  /**
   * **DEPRECATED in favour of mutation updateAccountWithTenantInfoV2**
   *
   * Assigns all the accounts in dr-account-info to a tenant. Only available for Application Admins.
   * @deprecated No longer supported, in favour of mutation updateAccountWithTenantInfoV2, termination planned for 2023-07-31
   */
  updateAccountWithTenantInfo: Scalars['Boolean'];
  /** Assigns all the accounts in dr-account-info to a tenant. Only available for Application Admins. */
  updateAccountWithTenantInfoV2: TSimpleResultUnion;
  /**
   * Updates a specified application template. Returns the updated application template if the operation was successful,
   * null otherwise.
   */
  updateApplicationTemplate: TUpdateApplicationTemplateUnion;
  /**
   * **DEPRECATED in favor of mutation `updateAssetV2`**
   *
   * Updates the specified asset, provided it exists and the user has the capability.
   *
   * Returns the updated asset if the operation was successful.
   * @deprecated No longer supported, in favor of mutation `updateAssetV2`, termination planed for 2023-07-31
   */
  updateAsset?: Maybe<TGroupedAssetOutput>;
  /**
   * Updates the specified asset, provided it exists and the user has the capability.
   *
   * Returns the updated asset if the operation was successful.
   */
  updateAssetV2?: Maybe<TUpdateAssetUnion>;
  updateComment: TCommentPayload;
  /** Allows a user to update the device display name if it is a device that belongs to their account. */
  updateDevice?: Maybe<TDeviceOutput>;
  /**
   *  **DEPRECATED in favor of mutation `updateFolderV2`**
   *
   * Updates a specified folder, provided that the user has the capability to edit
   * this project.
   *
   * Returns the newly updated folder if the operation was successful, null
   * otherwise.
   * @deprecated No longer supported, in favour of mutation `updateFolderV2`, termination planed for 2023-07-31
   */
  updateFolder?: Maybe<TFolderOutput>;
  /**
   * Updates a specified folder, provided that the user has the capability to edit
   * this project.
   *
   * Returns the newly updated folder if the operation was successful, null
   * otherwise.
   */
  updateFolderV2?: Maybe<TUpdateFolderUnion>;
  /**
   * @deprecated
   * reason: "Use updateGroupedAssetGeoreference instead"
   * @deprecated Use updateGroupedAssetGeoreference instead
   */
  updateGeoreference: TGeoreferenceOutput;
  updateGroupedAssetGeoreference: TGroupedAssetGeoreferenceOutput;
  updateGroupedAssetTour: TGroupedAssetTourOutput;
  updateGroupedAssetTourKeyframe: TGroupedAssetTourKeyframeOutput;
  /**
   * *DEPRECATED in favour of mutation updateLabelAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `updateLabelAnnotationV2`, termination planned for 2023-07-31
   */
  updateLabelAnnotation: TLabelAnnotationOutput;
  /** Updates a given label annotation with the given properties. */
  updateLabelAnnotationV2: TUpdateLabelAnnotationUnion;
  /**
   * *DEPRECATED in favour of mutation updateLimitingBoxAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `updateLimitingBoxAnnotationV2`, termination planned for 2023-07-31
   */
  updateLimitingBoxAnnotation: TLimitingBoxAnnotationOutput;
  /** Updates a given limiting box annotation with the given properties. */
  updateLimitingBoxAnnotationV2: TUpdateLimitingBoxAnnotationUnion;
  /**
   * *DEPRECATED in favour of mutation updateMeasurementAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `updateMeasurementAnnotationV2`, termination planned for 2023-07-31
   */
  updateMeasurementAnnotation: TMeasurementAnnotationOutput;
  /** Updates a given measurement annotation with the given properties. */
  updateMeasurementAnnotationV2: TUpdateMeasurementAnnotationUnion;
  /**
   * Update payment card in external payment system
   *
   * This Mutation can be called only with a bearer token.
   */
  updatePaymentCard: TPaymentCardOutput;
  /**
   * **DEPRECATED in favor of mutation `updateProjectV2`**
   *
   * Updates the specified project's metadata and returns the updated project.
   * @deprecated No longer supported, in favour of mutation `updateProjectV2`, termination planed for 2023-07-31
   */
  updateProject?: Maybe<TProjectOutput>;
  /** Updates the specified project's metadata and returns the updated project. */
  updateProjectV2?: Maybe<TUpdateProjectUnion>;
  /**
   * **DEPRECATED in favour of mutation updateTenantV2**
   *
   * Updates an existing tenant. Only accessible to application admins
   * @deprecated No longer supported, in favour of mutation updateTenantV2, termination planned for 2023-07-31
   */
  updateTenant: TTenantOutput;
  /** Updates an existing tenant. Only accessible to application admins */
  updateTenantV2: TTenantUnion;
  /** Updates the profile details of the currently logged in user. */
  updateUser: TUserProfileOutput;
  /** Updates the user settings for the currently logged in user */
  updateUserSettings: TUserSettingsOutput;
  /**
   * **DEPRECATED in favour of mutation updateUserWithTenantInfoV2**
   *
   * Assigns all the accounts in dr-user-info to a tenant. Only available for Application Admins.
   * @deprecated No longer supported, in favour of mutation updateUserWithTenantInfoV2, termination planned for 2023-07-31
   */
  updateUserWithTenantInfo: Scalars['Boolean'];
  /** Assigns all the accounts in dr-user-info to a tenant. Only available for Application Admins. */
  updateUserWithTenantInfoV2: TSimpleResultUnion;
  /**
   * **DEPRECATED in favour of mutation updateUserWithTenantRoleV2**
   *
   * Sync tenant roles of user from dr-acs to dr-user-info. Only available for Application Admins.
   * @deprecated No longer supported, in favour of mutation updateUserWithTenantRoleV2, termination planned for 2023-07-31
   */
  updateUserWithTenantRole: Scalars['Boolean'];
  /** Sync tenant roles of user from dr-acs to dr-user-info. Only available for Application Admins. */
  updateUserWithTenantRoleV2: TSimpleResultUnion;
  /**
   * Generates a presigned URL for uploading the polygon, returns the URL
   * and the path to the upload location.
   */
  uploadPolygon: TPolygonOutput;
  /** Updates the profile picture of the currently logged in user. */
  uploadProfilePicture: TProfilePictureOutput;
  /**
   * *DEPRECATED in favour of mutation uploadThumbnailToAnnotationV2*
   * @deprecated No longer supported, in favour of mutation `uploadThumbnailToAnnotationV2`, termination planned for 2023-07-31
   */
  uploadThumbnailToAnnotation?: Maybe<TAnnotationThumbnailOutput>;
  /** Upload a thumbnail image for the annotation with the given ID. */
  uploadThumbnailToAnnotationV2: TAnnotationThumbnailUnion;
  uploadThumbnailToCartItem: TCartItemThumbnailOutput;
  /**
   * **DEPRECATED in favor of mutation `uploadThumbnailToProjectV2`**
   *
   * Uploads a thumbnail to the specified project if it exists.
   *
   * Returns the project thumbnail if the operation was successful, null otherwise.
   * @deprecated No longer supported, in favour of mutation `uploadThumbnailToProjectV2`, termination planed for 2023-07-31
   */
  uploadThumbnailToProject?: Maybe<TProjectThumbnailOutput>;
  /**
   * Uploads a thumbnail to the specified project if it exists.
   *
   * Returns the project thumbnail if the operation was successful, null otherwise.
   */
  uploadThumbnailToProjectV2?: Maybe<TUploadThumbnailToProjectUnion>;
};


export type TMutationAddExternalUserToProjectArgs = {
  externalProjectMemberInput?: InputMaybe<TExternalProjectMemberInput>;
};


export type TMutationAddFileArgs = {
  params: TAddFileInput;
};


export type TMutationAddFileV2Args = {
  params: TAddFileInput;
};


export type TMutationAddItemToCartArgs = {
  params: TAddCartItemInput;
};


export type TMutationAddLabelsArgs = {
  input: TAddLabelsInput;
};


export type TMutationAddUserToProjectArgs = {
  params?: InputMaybe<TProjectMemberInput>;
};


export type TMutationAddUserToProjectV2Args = {
  params?: InputMaybe<TProjectMemberInput>;
};


export type TMutationAssignAccountToTenantArgs = {
  params: TAssignAccountToTenantInput;
};


export type TMutationAssignAccountToTenantV2Args = {
  params: TAssignAccountToTenantInput;
};


export type TMutationAssignTenantRoleToUserArgs = {
  params: TAssignTenantRoleToUserInput;
};


export type TMutationAssignTenantRoleToUserV2Args = {
  params: TAssignTenantRoleToUserInput;
};


export type TMutationAutoRegisterAssetsArgs = {
  input: TAutoRegistrationInput;
  newAsset: TCreateAssetInput;
};


export type TMutationAutoRegisterAssetsV2Args = {
  input: TAutoRegistrationInputV2;
  newAsset: TCreateAssetInput;
};


export type TMutationBuySubscriptionArgs = {
  params?: InputMaybe<TCreationSubscriptionInput>;
};


export type TMutationBuySubscriptionV2Args = {
  params?: InputMaybe<TCreationSubscriptionInput>;
};


export type TMutationChangeAccountRoleOfUserArgs = {
  params?: InputMaybe<TAccountMemberInput>;
};


export type TMutationChangeAccountRoleOfUserV2Args = {
  params?: InputMaybe<TAccountMemberInput>;
};


export type TMutationChangeProjectRoleOfUserArgs = {
  params?: InputMaybe<TProjectMemberInput>;
};


export type TMutationChangeProjectRoleOfUserV2Args = {
  params?: InputMaybe<TProjectMemberInput>;
};


export type TMutationCompleteAssetFileListArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationCompleteMultipartUploadArgs = {
  params: TMultipartUploadCompleteInput;
};


export type TMutationCopyAssetToFolderArgs = {
  params: TCopyAssetInput;
};


export type TMutationCopyAssetToFolderV2Args = {
  params: TCopyAssetInput;
};


export type TMutationCreateApplicationTemplateArgs = {
  params: TCreateApplicationTemplateInput;
};


export type TMutationCreateAssetArgs = {
  params: TCreateAssetInput;
};


export type TMutationCreateAssetThumbnailArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationCreateAssetThumbnailV2Args = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationCreateAssetV2Args = {
  params: TCreateAssetInput;
};


export type TMutationCreateAssetV3Args = {
  params: TCreateAssetInputV3;
};


export type TMutationCreateCommentArgs = {
  input: TCreateCommentInput;
};


export type TMutationCreateFolderArgs = {
  params: TCreateFolderInput;
};


export type TMutationCreateFolderV2Args = {
  params: TCreateFolderInput;
};


export type TMutationCreateGeoreferenceArgs = {
  georeference: TCreateGeoreferenceInput;
};


export type TMutationCreateGroupedAssetGeoreferenceArgs = {
  georeference: TCreateGroupedAssetGeoreferenceInput;
};


export type TMutationCreateGroupedAssetTourArgs = {
  params: TCreateGroupedAssetTourInput;
};


export type TMutationCreateGroupedAssetTourKeyframeArgs = {
  params: TCreateGroupedAssetTourKeyframeInput;
};


export type TMutationCreateGroupedAssetTourThumbnailArgs = {
  params: TCreateGroupedAssetTourThumbnailInput;
};


export type TMutationCreateHspcBaselayerArgs = {
  props?: InputMaybe<THspcInput>;
};


export type TMutationCreateLabelAnnotationArgs = {
  createLabelAnnotationInput: TCreateLabelAnnotationInput;
};


export type TMutationCreateLabelAnnotationV2Args = {
  createLabelAnnotationInput: TCreateLabelAnnotationInputV2;
};


export type TMutationCreateLimitingBoxAnnotationArgs = {
  createLimitingBoxAnnotationInput: TCreateLimitingBoxAnnotationInput;
};


export type TMutationCreateLimitingBoxAnnotationV2Args = {
  createLimitingBoxAnnotationInput: TCreateLimitingBoxAnnotationInputV2;
};


export type TMutationCreateLtsBaselayerArgs = {
  props?: InputMaybe<TLtsInput>;
};


export type TMutationCreateMeasurementAnnotationArgs = {
  createMeasurementAnnotationInput: TCreateMeasurementAnnotationInput;
};


export type TMutationCreateMeasurementAnnotationV2Args = {
  createMeasurementAnnotationInput: TCreateMeasurementAnnotationInputV2;
};


export type TMutationCreateOgc3dBaselayerArgs = {
  props?: InputMaybe<TOgc3DInput>;
};


export type TMutationCreatePanoramicBaselayerArgs = {
  props?: InputMaybe<TPanoramicInput>;
};


export type TMutationCreateProjectArgs = {
  params: TCreateProjectInput;
};


export type TMutationCreateProjectV2Args = {
  params: TCreateProjectInput;
};


export type TMutationCreateTenantArgs = {
  params: TCreateTenantInput;
};


export type TMutationCreateTenantV2Args = {
  params: TCreateTenantInput;
};


export type TMutationCreateUrlBaselayerArgs = {
  props?: InputMaybe<TUrlInput>;
};


export type TMutationCreateWfsBaselayerArgs = {
  props?: InputMaybe<TWfsInput>;
};


export type TMutationCreateWmsBaselayerArgs = {
  props?: InputMaybe<TWmsInput>;
};


export type TMutationCreateWmtsBaselayerArgs = {
  props?: InputMaybe<TWmtsInput>;
};


export type TMutationCustomMetricArgs = {
  params: TCustomMetricGql;
};


export type TMutationDeleteAnnotationArgs = {
  annotationId: Scalars['ID'];
};


export type TMutationDeleteAnnotationV2Args = {
  annotationId: Scalars['ID'];
};


export type TMutationDeleteApplicationTemplateArgs = {
  id: Scalars['ID'];
};


export type TMutationDeleteAssetArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationDeleteAssetThumbnailArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationDeleteAssetV2Args = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationDeleteCommentArgs = {
  input: TDeleteCommentInput;
};


export type TMutationDeleteDeviceArgs = {
  deviceId: Scalars['ID'];
};


export type TMutationDeleteFolderArgs = {
  folderId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type TMutationDeleteFolderV2Args = {
  folderId: Scalars['ID'];
  projectId: Scalars['ID'];
};


export type TMutationDeleteGeoreferenceArgs = {
  id: Scalars['ID'];
};


export type TMutationDeleteGroupedAssetGeoreferenceArgs = {
  id: Scalars['ID'];
};


export type TMutationDeleteGroupedAssetTourArgs = {
  params: TDeleteGroupedAssetTourInput;
};


export type TMutationDeleteGroupedAssetTourKeyframeArgs = {
  params: TDeleteGroupedAssetTourKeyframeInput;
};


export type TMutationDeleteGroupedAssetTourThumbnailArgs = {
  params: TDeleteGroupedAssetTourThumbnailInput;
};


export type TMutationDeletePaymentCardArgs = {
  params?: InputMaybe<TDeletedPaymentCardInput>;
};


export type TMutationDeleteProjectArgs = {
  projectId: Scalars['ID'];
};


export type TMutationDeleteProjectThumbnailArgs = {
  projectId: Scalars['ID'];
};


export type TMutationDeleteProjectV2Args = {
  projectId: Scalars['ID'];
};


export type TMutationDeleteTenantArgs = {
  params: TDeleteTenantInput;
};


export type TMutationDeleteTenantV2Args = {
  params: TDeleteTenantInput;
};


export type TMutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type TMutationDeleteUserV2Args = {
  userId: Scalars['ID'];
};


export type TMutationGenerateInvitationArgs = {
  params: TGenerateInviteInput;
};


export type TMutationGenerateInvitationV2Args = {
  params: TGenerateInviteInput;
};


export type TMutationGenerateMultiAssetDownloadLinkArgs = {
  input: TMultiAssetDownloadLinkInput;
};


export type TMutationGenerateMultiAssetDownloadLinkV2Args = {
  input: TMultiAssetDownloadLinkInput;
};


export type TMutationGenerateReportArgs = {
  params: TReportGenerationInput;
};


export type TMutationGenerateVoucherArgs = {
  params: TGenerateVoucherInput;
};


export type TMutationGenerateVoucherV2Args = {
  params: TGenerateVoucherInput;
};


export type TMutationLeaveProjectArgs = {
  projectId: Scalars['ID'];
};


export type TMutationLeaveProjectV2Args = {
  projectId: Scalars['ID'];
};


export type TMutationLinkDeviceArgs = {
  params: TLinkDeviceInput;
};


export type TMutationMoveAssetToFolderArgs = {
  params: TMoveAssetInput;
};


export type TMutationMoveAssetToFolderV2Args = {
  params: TMoveAssetInput;
};


export type TMutationPersistArtifactArgs = {
  params: TPersistArtifactInput;
  parentId: Scalars['ID'];
};


export type TMutationPersistAssetArgs = {
  params: TPersistAssetInput;
};


export type TMutationPersistAssetV2Args = {
  params: TPersistAssetInput;
};


export type TMutationPlaceOrderArgs = {
  params: TPlaceOrderInput;
};


export type TMutationPublishAccountMetricsArgs = {
  accountIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type TMutationPublishUserMetricsArgs = {
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type TMutationRecalculateGroupedAssetTourKeyframesArgs = {
  params: TRecalculateGroupedAssetTourKeyframesInput;
};


export type TMutationRecalculateUsedStorageArgs = {
  syncWithStorage: Scalars['Boolean'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type TMutationRefundTokensArgs = {
  params?: InputMaybe<TTokensRefundInput>;
};


export type TMutationRemoveAssetThumbnailArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationRemoveItemFromCartArgs = {
  params: TRemoveCartItemInput;
};


export type TMutationRemoveLabelsArgs = {
  input: TRemoveLabelsInput;
};


export type TMutationRemoveProjectThumbnailArgs = {
  projectId: Scalars['ID'];
};


export type TMutationRemoveUserFlagArgs = {
  userFlag?: InputMaybe<TUserFlagEnum>;
};


export type TMutationRemoveUserFromProjectArgs = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type TMutationRemoveUserFromProjectV2Args = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type TMutationSampleAccountMetricsArgs = {
  accountIds?: InputMaybe<Array<Scalars['ID']>>;
  samplingTimestamp?: InputMaybe<Scalars['DateTime']>;
};


export type TMutationSampleUserMetricsArgs = {
  samplingTimestamp?: InputMaybe<Scalars['DateTime']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type TMutationSetAccountPropertiesArgs = {
  param: TAccountPropertiesInput;
};


export type TMutationSetActivePaymentCardArgs = {
  params?: InputMaybe<TSetActivePaymentCardInput>;
};


export type TMutationSetDefaultTenantArgs = {
  tenantId: Scalars['ID'];
};


export type TMutationSetDefaultTenantV2Args = {
  tenantId: Scalars['ID'];
};


export type TMutationSetUserFlagArgs = {
  userFlag?: InputMaybe<TUserFlagInput>;
};


export type TMutationShareGroupedAssetArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationSignupArgs = {
  params: TSignupInput;
};


export type TMutationSignupFromInviteArgs = {
  params: TSignupFromInviteInput;
};


export type TMutationSignupFromInviteV2Args = {
  params: TSignupFromInviteInput;
};


export type TMutationSignupV2Args = {
  params: TSignupInput;
};


export type TMutationTriggerHspcClipperProcessingPipelineArgs = {
  triggerObject?: InputMaybe<TProcessingPipelineTriggerInput>;
};


export type TMutationTriggerMeshingArgs = {
  params: TTriggerInput;
};


export type TMutationTriggerMeshingV2Args = {
  params: TTriggerMeshingInput;
};


export type TMutationTriggerProcessingArgs = {
  params: TTriggerInput;
};


export type TMutationTriggerProcessingV2Args = {
  params: TTriggerInput;
};


export type TMutationTriggerShopProcessingPipelineArgs = {
  triggerObject?: InputMaybe<TProcessingPipelineTriggerInput>;
};


export type TMutationUnshareGroupedAssetArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TMutationUpdateAccountArgs = {
  update: TAccountUpdateInput;
};


export type TMutationUpdateAccountV2Args = {
  update: TAccountUpdateInput;
};


export type TMutationUpdateApplicationTemplateArgs = {
  params: TUpdateApplicationTemplateInput;
};


export type TMutationUpdateAssetArgs = {
  params: TUpdateAssetInput;
};


export type TMutationUpdateAssetV2Args = {
  params: TUpdateAssetInput;
};


export type TMutationUpdateCommentArgs = {
  input: TUpdateCommentInput;
};


export type TMutationUpdateDeviceArgs = {
  params: TUpdateDeviceInput;
};


export type TMutationUpdateFolderArgs = {
  params: TUpdateFolderInput;
};


export type TMutationUpdateFolderV2Args = {
  params: TUpdateFolderInput;
};


export type TMutationUpdateGeoreferenceArgs = {
  georeference: TUpdateGeoreferenceInput;
};


export type TMutationUpdateGroupedAssetGeoreferenceArgs = {
  georeference: TUpdateGroupedAssetGeoreferenceInput;
};


export type TMutationUpdateGroupedAssetTourArgs = {
  params: TUpdateGroupedAssetTourInput;
};


export type TMutationUpdateGroupedAssetTourKeyframeArgs = {
  params: TUpdateGroupedAssetTourKeyframeInput;
};


export type TMutationUpdateLabelAnnotationArgs = {
  updateLabelAnnotationInput: TUpdateLabelAnnotationInput;
};


export type TMutationUpdateLabelAnnotationV2Args = {
  updateLabelAnnotationInput: TUpdateLabelAnnotationInputV2;
};


export type TMutationUpdateLimitingBoxAnnotationArgs = {
  updateLimitingBoxAnnotationInput: TUpdateLimitingBoxAnnotationInput;
};


export type TMutationUpdateLimitingBoxAnnotationV2Args = {
  updateLimitingBoxAnnotationInput: TUpdateLimitingBoxAnnotationInputV2;
};


export type TMutationUpdateMeasurementAnnotationArgs = {
  updateMeasurementAnnotationInput: TUpdateMeasurementAnnotationInput;
};


export type TMutationUpdateMeasurementAnnotationV2Args = {
  updateMeasurementAnnotationInput: TUpdateMeasurementAnnotationInputV2;
};


export type TMutationUpdatePaymentCardArgs = {
  params?: InputMaybe<TPaymentCardInput>;
};


export type TMutationUpdateProjectArgs = {
  params: TUpdateProjectInput;
};


export type TMutationUpdateProjectV2Args = {
  params: TUpdateProjectInput;
};


export type TMutationUpdateTenantArgs = {
  params: TUpdateTenantInput;
};


export type TMutationUpdateTenantV2Args = {
  params: TUpdateTenantInput;
};


export type TMutationUpdateUserArgs = {
  update: TUserUpdateInput;
};


export type TMutationUpdateUserSettingsArgs = {
  userSettings: TUserSettingsInput;
};


export type TMutationUploadThumbnailToAnnotationArgs = {
  annotationId: Scalars['ID'];
};


export type TMutationUploadThumbnailToAnnotationV2Args = {
  annotationId: Scalars['ID'];
};


export type TMutationUploadThumbnailToCartItemArgs = {
  params: TCartItemThumbnailInput;
};


export type TMutationUploadThumbnailToProjectArgs = {
  params: TProjectThumbnailInput;
};


export type TMutationUploadThumbnailToProjectV2Args = {
  params: TProjectThumbnailInput;
};

/**
 * Result of query myAssetsProject.
 * Of type ProjectOutput in case of success, otherwise one of the errors.
 */
export type TMyAssetsProjectUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorTooManyRequestsOutput | TProjectOutput;

export type TNode = {
  id: Scalars['ID'];
};

/** Requested resource could not be found. */
export type TNotFoundError = TError & {
  message: Scalars['String'];
};

export enum TOAuthFlowTypeEnum {
  CLIENT_CREDENTIALS = 'CLIENT_CREDENTIALS',
  CODE = 'CODE',
  IMPLICIT = 'IMPLICIT'
}

export type TOgc3DInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  endpoint: Scalars['String'];
  label: Scalars['String'];
  qualityFactor: Scalars['Float'];
  viewingMode: TAddressViewingModeEnum;
};

export type TOrderAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  postcode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
};

export type TOrderGql = {
  amount: Scalars['BigDecimal'];
  cartId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  discountedAmount: Scalars['BigDecimal'];
  id?: Maybe<Scalars['ID']>;
  modifiedAt?: Maybe<Scalars['DateTime']>;
  orderId: Scalars['String'];
  orderItems: Array<TOrderItemGql>;
  status: TOrderStatusGql;
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
  userId: Scalars['ID'];
};

export type TOrderItemGql = {
  accountUUID?: Maybe<Scalars['ID']>;
  amount: Scalars['BigDecimal'];
  artifactFilePath?: Maybe<Scalars['String']>;
  artifactUUID?: Maybe<Scalars['ID']>;
  assetUUID?: Maybe<Scalars['ID']>;
  bbox?: Maybe<Array<Scalars['Float']>>;
  cartId: Scalars['ID'];
  cartItemId: Scalars['ID'];
  coordinateSystem: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  currency: Scalars['String'];
  dataType: TDataTypeEnum;
  discountedAmount: Scalars['BigDecimal'];
  folderUUID?: Maybe<Scalars['ID']>;
  groupedAssetUUID?: Maybe<Scalars['ID']>;
  id?: Maybe<Scalars['ID']>;
  imageFormat: Scalars['String'];
  modifiedAt?: Maybe<Scalars['DateTime']>;
  polygonDefType: TPolygonType;
  polygonPath?: Maybe<Scalars['String']>;
  productIdentifier: Scalars['String'];
  projectUUID?: Maybe<Scalars['ID']>;
  resolutionUnit: Scalars['String'];
  resolutionValue: Scalars['Float'];
  status: TOrderItemStatusGql;
  supplierOrderId?: Maybe<Scalars['String']>;
  thumbnailFilePath?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
  userUUID?: Maybe<Scalars['ID']>;
};

export enum TOrderItemStatusGql {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING'
}

export enum TOrderStatusGql {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  STARTED = 'STARTED'
}

export enum TOrderTypeEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC'
}

export enum TOrderTypeGql {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC'
}

export enum TOutputFormat {
  E57 = 'E57',
  LAS = 'LAS',
  OGC3D_TILES = 'OGC3D_TILES',
  PTS = 'PTS',
  ZIP = 'ZIP'
}

/**
 * Cursor based pagination metadata
 * @see https://dev.to/mandiwise/graphql-pagination-primer-offset-vs-cursor-vs-relay-style-pagination-1a60
 */
export type TPageInfo = {
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type TPanoramicInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  imageServerPrefix: Scalars['String'];
  label: Scalars['String'];
  reference: Scalars['String'];
  viewingMode: TAddressViewingModeEnum;
};

export enum TParentTypeEnum {
  GROUPED_ASSET = 'GROUPED_ASSET'
}

export type TPaymentCardAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type TPaymentCardAddressOutput = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type TPaymentCardInput = {
  address?: InputMaybe<TPaymentCardAddressInput>;
  expirationMonth?: InputMaybe<Scalars['String']>;
  expirationYear?: InputMaybe<Scalars['String']>;
  externalPaymentCardId: Scalars['String'];
  fullName?: InputMaybe<Scalars['String']>;
};

export type TPaymentCardOutput = {
  active: Scalars['Boolean'];
  address?: Maybe<TPaymentCardAddressOutput>;
  brand: Scalars['String'];
  expirationMonth: Scalars['Int'];
  expirationYear: Scalars['Int'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastDigits: Scalars['String'];
  stripeCustomer: Scalars['String'];
};

export enum TPaymentMethodEnum {
  DEMO = 'DEMO',
  PAY_BY_CARD = 'PAY_BY_CARD',
  PAY_BY_INVOICE = 'PAY_BY_INVOICE'
}

export enum TPaymentMethodShopEnum {
  DEMO = 'DEMO',
  PAY_BY_CARD = 'PAY_BY_CARD',
  PAY_BY_INVOICE = 'PAY_BY_INVOICE'
}

export enum TPaymentSystemEnum {
  LICENSE = 'LICENSE',
  STRIPE = 'STRIPE'
}

export type TPaymentSystemOutput = {
  paymentSystem: TPaymentSystemEnum;
  paymentSystemId: Scalars['ID'];
};

export type TPersistArtifactInput = {
  anchorPoint?: InputMaybe<TPoint3DInput>;
  /** @deprecated artifactType, use dataCategory and savedFormat combination instead */
  artifactType: TArtifactTypeEnum;
  createdBy: Scalars['ID'];
  dataCategory?: InputMaybe<TDataCategoryEnum>;
  fileSize: Scalars['BigInteger'];
  ownerAccount: Scalars['ID'];
  parentType?: InputMaybe<TArtifactParentTypeEnum>;
  savedFormat?: InputMaybe<TSavedFormatEnum>;
  withEmbeddedGeoreference?: InputMaybe<Scalars['Boolean']>;
};

export type TPersistAssetInput = {
  bbox?: InputMaybe<TAssetBBoxInput>;
  description?: InputMaybe<Scalars['String']>;
  folderId: Scalars['ID'];
  groupedAssetOutput: TAssetTypeEnum;
  name: Scalars['String'];
  thumbnailPath?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation persistAsset.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TPersistAssetUnion = TAssetErrorDuplicateNameOutput | TAssetErrorNameBlacklistedCharactersOutput | TAssetErrorNameBlankOutput | TAssetErrorNameForbiddenOutput | TAssetErrorNameForwardSlashOutput | TAssetErrorNameLeadingOrTrailingSpaceOutput | TAssetErrorNameTrailingDotOutput | TAssetErrorNameTwoDotsInRowOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

export enum TPipelineStatus {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  MANUAL_TRIGGER = 'MANUAL_TRIGGER',
  PENDING = 'PENDING',
  PREPARING = 'PREPARING',
  RUNNING = 'RUNNING',
  SCHEDULED = 'SCHEDULED',
  SKIPPED = 'SKIPPED',
  SUCCESS = 'SUCCESS',
  WAITING_FOR_RESOURCE = 'WAITING_FOR_RESOURCE'
}

export type TPlaceOrderInput = {
  paymentMethod?: InputMaybe<TPaymentMethodShopEnum>;
};

export type TPoint3DInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type TPoint3DOutput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
  z: Scalars['Float'];
};

export type TPointCloudSourceLidarOutput = TProductOutput & {
  accuracy?: Maybe<Scalars['String']>;
  captureSensor?: Maybe<Scalars['String']>;
  coordinateSystem?: Maybe<Scalars['String']>;
  coverage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dataProvider?: Maybe<Scalars['String']>;
  dataSampleHR?: Maybe<Scalars['String']>;
  dateCaptured?: Maybe<Scalars['DateTime']>;
  deliveryType?: Maybe<Scalars['String']>;
  downloadFormat?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  minPriceApplies?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  offeredBundleDiscounts?: Maybe<Scalars['String']>;
  pricePerArea?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
  sapArticleNumber?: Maybe<Scalars['String']>;
  streamingFormat?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type TPolygonOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export enum TPolygonType {
  BBOX = 'BBOX',
  GEOJSON = 'GEOJSON',
  SHAPEFILE = 'SHAPEFILE'
}

/** The processing can not be started the account is not synced or the address is not valid */
export type TProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

/** The processing can not be started because there was an error with the server */
export type TProcessingErrorInternalServerErrorOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

/** The processing can not be started because the processing payment failed because of invalid input */
export type TProcessingErrorInvalidInputForProcessingPaymentErrorOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

export enum TProcessingErrorLevel {
  ERROR = 'ERROR',
  WARNING = 'WARNING'
}

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TProcessingErrorOperationNotAllowedOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

export type TProcessingErrorOutput = {
  level?: Maybe<TProcessingErrorLevel>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<TProcessingErrorType>;
};

/** The processing time limitation has been reached. */
export type TProcessingErrorProcessingLimitReachedOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

/** Access to a requested resource denied. */
export type TProcessingErrorResourceAccessDeniedOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

/** The processing can not be started because ome of the resources needed for the processing was not found */
export type TProcessingErrorResourceNotFoundOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

/** The processing can not be started because the storage limit has been reached. */
export type TProcessingErrorStorageLimitReachedOutput = TBaseProcessingErrorInterface & {
  message: Scalars['String'];
};

export enum TProcessingErrorType {
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  INVALID_ACCOUNT_OR_ADDRESS = 'INVALID_ACCOUNT_OR_ADDRESS',
  INVALID_INPUT = 'INVALID_INPUT',
  MISSING_ARTIFACTS = 'MISSING_ARTIFACTS',
  TOKENS_LIMIT_EXCEEDED = 'TOKENS_LIMIT_EXCEEDED',
  UNDEFINED = 'UNDEFINED'
}

export type TProcessingPipelineInfoOutput = {
  errors?: Maybe<Array<Maybe<TProcessingErrorOutput>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<TProcessingStatus>;
};

export enum TProcessingPipelineName {
  MYVR_HSPC_TO_E57 = 'MYVR_HSPC_TO_E57',
  MYVR_HSPC_TO_LAS = 'MYVR_HSPC_TO_LAS',
  MYVR_HSPC_TO_PTS = 'MYVR_HSPC_TO_PTS',
  TECHNODIGIT_HSPC_TO_OGC3DTILES = 'TECHNODIGIT_HSPC_TO_OGC3DTILES'
}

/**
 * The `ProcessingPipelineTriggerInput` object required to execute the `triggerShopProcessingPipeline` mutation
 * requires the below fields:
 * 1)   pipelineName: ImplementedPipelines!    -  The pipeline name to be triggered. Currently only MELOWN_VEF_TO_VEF_CLIP
 * 2)   pathToUploadedAoi: String!             -  The `path` returned by the `uploadPolygon` graphql mutation
 * 3)   catalogFeatureIds: [String]!           -  The featureIds of the catalog datasets. These are linked to the s3 buckets.
 * 4)   mailResultTo: String                   -  The email address where to send the pipeline completion email.
 * 5)   callbackUrl: String                    -  The API callback URL, to which to perform pipeline completion api callback.
 *
 * Example query values:
 * {
 *     catalogFeatureIds: ["NOB22", "NOB21", "f7a9d635-4587-4369-a731-a7af535033a4"],
 *     mailResultTo:"test.user@hexagon.com",
 *     pathToUploadedAoi: "shop/polygons/currentQuotation/14bbb1a0-29dd-3d1c-a45b-9fe8042630c5/9e18fcdb-f560-461f-b3ce-f8f5df3ec17e",
 *     pipelineName: MELOWN_VEF_TO_VEF_CLIP,
 *     callbackUrl:"https://myserver.com/signature?rfwetbdsgghgfb"
 * }
 */
export type TProcessingPipelineTriggerInput = {
  aoiType?: InputMaybe<TPolygonType>;
  callbackUrl?: InputMaybe<Scalars['String']>;
  catalogFeatureIds: Array<InputMaybe<Scalars['String']>>;
  mailResultTo?: InputMaybe<Scalars['String']>;
  pathToUploadedAoi: Scalars['String'];
  pipelineName: TImplementedPipeline;
};

/**
 * The return object is a `ProcessingPipelineTriggerOutput` and contains a single status field:
 * 1)   status: PipelineStatus                  -  The status of the pipeline, i.e. CREATED/FAILED/SKIPPED/etc.
 * 2)   errorMessage: String                    -  An error message in case the pipeline was not triggered
 *
 * Successfully triggered pipeline will have the following status: "CREATED"
 */
export type TProcessingPipelineTriggerOutput = {
  errorMessage?: Maybe<Scalars['String']>;
  status: TPipelineStatus;
};

export enum TProcessingStatus {
  CANCELED = 'CANCELED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  MANUAL = 'MANUAL',
  PENDING = 'PENDING',
  PREPARING = 'PREPARING',
  RUNNING = 'RUNNING',
  SCHEDULED = 'SCHEDULED',
  SKIPPED = 'SKIPPED',
  SUCCESS = 'SUCCESS',
  WAITING_FOR_RESOURCE = 'WAITING_FOR_RESOURCE'
}

export type TProcessingTypeOutput = {
  outputFormat: TOutputFormat;
  processingPipelineName: TProcessingPipelineName;
};

/** The ProcessingUsage Output */
export type TProcessingUsageOutput = {
  /** Duration in Seconds */
  duration: Scalars['Int'];
  /** EndDate */
  endDate: Scalars['DateTime'];
  /** Pipeline Name */
  name: Scalars['String'];
  /** StartDate */
  startDate: Scalars['DateTime'];
};

export type TProduct2DAerialOutput = TProductOutput & {
  accuracy?: Maybe<Scalars['String']>;
  captureSensor?: Maybe<Scalars['String']>;
  coordinateSystem?: Maybe<Scalars['String']>;
  coverage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dataProvider?: Maybe<Scalars['String']>;
  dataSampleHR?: Maybe<Scalars['String']>;
  dateCaptured?: Maybe<Scalars['DateTime']>;
  deliveryType?: Maybe<Scalars['String']>;
  downloadFormat?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  minPriceApplies?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  offeredBundleDiscounts?: Maybe<Scalars['String']>;
  pricePerArea?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
  sapArticleNumber?: Maybe<Scalars['String']>;
  streamingFormat?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type TProductCodeInput = {
  productCodeFromCatalog: Scalars['String'];
};

export type TProductInput = {
  productCode: Scalars['String'];
};

export type TProductOutput = {
  captureSensor?: Maybe<Scalars['String']>;
  coordinateSystem?: Maybe<Scalars['String']>;
  coverage?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dataProvider?: Maybe<Scalars['String']>;
  dateCaptured?: Maybe<Scalars['DateTime']>;
  deliveryType?: Maybe<Scalars['String']>;
  downloadFormat?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  offeredBundleDiscounts?: Maybe<Scalars['String']>;
  pricePerArea?: Maybe<Scalars['String']>;
  productDescription?: Maybe<Scalars['String']>;
  streamingFormat?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type TProductUnion = TQuotationErrorProductNotFoundOutput | TQuotationProductOutput;

export type TProfilePictureOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type TProjectAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TProjectChangeCopyOperation = {
  /** ID of the node */
  id: Scalars['ID'];
  sourceFolderId: Scalars['ID'];
  targetFolderId: Scalars['ID'];
};

export type TProjectChangeCreateOperation = {
  /** ID of the node */
  id: Scalars['ID'];
};

export type TProjectChangeDeleteOperation = {
  /** ID of the node */
  id: Scalars['ID'];
};

export type TProjectChangeFolderCopyOperation = TNode & TProjectChangeCopyOperation & TProjectChangeFolderOperation & {
  folder: TFolderUnion;
  folderId: Scalars['ID'];
  id: Scalars['ID'];
  sourceFolder: TFolderUnion;
  sourceFolderId: Scalars['ID'];
  targetFolder: TFolderUnion;
  targetFolderId: Scalars['ID'];
};

export type TProjectChangeFolderCreateOperation = TNode & TProjectChangeCreateOperation & TProjectChangeFolderOperation & {
  folder: TFolderUnion;
  folderId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TProjectChangeFolderDeleteOperation = TNode & TProjectChangeDeleteOperation & TProjectChangeFolderOperation & {
  folderId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TProjectChangeFolderMoveOperation = TNode & TProjectChangeFolderOperation & TProjectChangeMoveOperation & {
  folder: TFolderUnion;
  folderId: Scalars['ID'];
  id: Scalars['ID'];
  sourceFolder: TFolderUnion;
  sourceFolderId: Scalars['ID'];
  targetFolder: TFolderUnion;
  targetFolderId: Scalars['ID'];
};

export type TProjectChangeFolderOperation = {
  folderId: Scalars['ID'];
};

export type TProjectChangeFolderUpdateOperation = TNode & TProjectChangeFolderOperation & TProjectChangeUpdateOperation & {
  folder: TFolderUnion;
  folderId: Scalars['ID'];
  id: Scalars['ID'];
  /**
   * JSON Paths to the updated fields relative to the folder
   * Example: '/name'
   */
  paths: Array<Scalars['String']>;
};

export type TProjectChangeGroupedAssetCopyOperation = TNode & TProjectChangeCopyOperation & TProjectChangeGroupedAssetOperation & {
  asset: TAssetUnion;
  groupedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  sourceFolder: TFolderUnion;
  sourceFolderId: Scalars['ID'];
  targetFolder: TFolderUnion;
  targetFolderId: Scalars['ID'];
};

export type TProjectChangeGroupedAssetCreateOperation = TNode & TProjectChangeCreateOperation & TProjectChangeGroupedAssetOperation & {
  asset: TAssetUnion;
  groupedAssetId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TProjectChangeGroupedAssetDeleteOperation = TNode & TProjectChangeDeleteOperation & TProjectChangeGroupedAssetOperation & {
  groupedAssetId: Scalars['ID'];
  id: Scalars['ID'];
};

export type TProjectChangeGroupedAssetMoveOperation = TNode & TProjectChangeGroupedAssetOperation & TProjectChangeMoveOperation & {
  asset: TAssetUnion;
  groupedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  sourceFolder: TFolderUnion;
  sourceFolderId: Scalars['ID'];
  targetFolder: TFolderUnion;
  targetFolderId: Scalars['ID'];
};

export type TProjectChangeGroupedAssetOperation = {
  groupedAssetId: Scalars['ID'];
};

export type TProjectChangeGroupedAssetUpdateOperation = TNode & TProjectChangeGroupedAssetOperation & TProjectChangeUpdateOperation & {
  asset: TAssetUnion;
  /** Tells if the "binary content" of the grouped asset was changed. */
  didBinaryContentChange: Scalars['Boolean'];
  groupedAssetId: Scalars['ID'];
  id: Scalars['ID'];
  /**
   * JSON Paths to the updated fields relative to the grouped asset
   * Example: '/name'
   */
  paths: Array<Scalars['String']>;
};

export type TProjectChangeMoveOperation = {
  /** ID of the node */
  id: Scalars['ID'];
  sourceFolderId: Scalars['ID'];
  targetFolderId: Scalars['ID'];
};

export type TProjectChangeOperation = TProjectChangeFolderCopyOperation | TProjectChangeFolderCreateOperation | TProjectChangeFolderDeleteOperation | TProjectChangeFolderMoveOperation | TProjectChangeFolderUpdateOperation | TProjectChangeGroupedAssetCopyOperation | TProjectChangeGroupedAssetCreateOperation | TProjectChangeGroupedAssetDeleteOperation | TProjectChangeGroupedAssetMoveOperation | TProjectChangeGroupedAssetUpdateOperation | TProjectChangeProjectUpdateOperation | TProjectChangeUserAccessGrantedOperation | TProjectChangeUserAccessRevokedOperation;

export type TProjectChangeOutput = TNode & TProjectChangeProjectOperation & {
  /** ID of the node */
  id: Scalars['ID'];
  /**
   * Timestamp for when this message occured in RFC-3339 format
   * @see https://the-guild.dev/graphql/scalars/docs/scalars/date-time
   */
  notifiedAt: Scalars['DateTime'];
  /** One of the deifined operations that cn happen on the project */
  operation: TProjectChangeOperation;
  project: TProjectUnion;
  /** ID of the project that has been changed */
  projectId: Scalars['ID'];
  /** The (new) version of the file system. Can be omitted if the operation did not change the filesystem, like a renamed project. */
  rootVersion?: Maybe<Scalars['Int']>;
};

export type TProjectChangePayload = TProjectChangeOutput | TServerError | TUnauthenticatedError;

export type TProjectChangeProjectOperation = {
  projectId: Scalars['ID'];
};

export type TProjectChangeProjectUpdateOperation = TNode & TProjectChangeProjectOperation & TProjectChangeUpdateOperation & {
  id: Scalars['ID'];
  /**
   * JSON Paths to the updated fields relative to the project
   * Example: '/name'
   */
  paths: Array<Scalars['String']>;
  project: TProjectUnion;
  projectId: Scalars['ID'];
};

export type TProjectChangeUpdateOperation = {
  /** ID of the node */
  id: Scalars['ID'];
  /**
   * JSON Paths to the updated fields relative to the grouped asset
   * Example: '/name'
   */
  paths: Array<Scalars['String']>;
};

export type TProjectChangeUserAccessGrantedOperation = TNode & TProjectChangeUserAccessOperation & {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type TProjectChangeUserAccessOperation = {
  /** ID of the node */
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type TProjectChangeUserAccessRevokedOperation = TNode & TProjectChangeUserAccessOperation & {
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

/** NO LONGER USED */
export type TProjectErrorDuplicateNameOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/**
 * Invalid input.
 * This is used when there is no more descriptive ProjectError that can be used to describe the invalid input.
 */
export type TProjectErrorInvalidInputOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/**
 * The given name contains one or more blacklisted characters.
 * Blacklisted characters are:
 *
 * <,>,,:,\,|,?,*, ,#,$,+,%,!,`,&,',{,},",= and @
 */
export type TProjectErrorNameBlacklistedCharactersOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** The given name is blank (empty, only spaces or null). */
export type TProjectErrorNameBlankOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/**
 * The given name is a forbidden name with any capitalisation and any extension.
 * Forbidden names are:
 *
 *   "CON", "PRN", "AUX", "NUL",
 *   "COM1", "COM2", "COM3", "COM4", "COM5", "COM6", "COM7", "COM8", "COM9",
 *   "LPT1", "LPT2", "LPT3", "LPT4", "LPT5", "LPT6", "LPT7", "LPT8", "LPT9"
 */
export type TProjectErrorNameForbiddenOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a forward slash. */
export type TProjectErrorNameForwardSlashOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a leading or trailing space. */
export type TProjectErrorNameLeadingOrTrailingSpaceOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains a trailing dot. */
export type TProjectErrorNameTrailingDotOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** The given name contains two dots in a row. */
export type TProjectErrorNameTwoDotsInRowOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TProjectErrorOperationNotAllowedOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** Access to a requested resource denied for the target user. */
export type TProjectErrorResourceAccessDeniedForTargetUserOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** Access to a requested resource denied. */
export type TProjectErrorResourceAccessDeniedOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

/** The system is receiving too many requests. Try again later. */
export type TProjectErrorTooManyRequestsOutput = TBaseProjectErrorInterface & {
  message: Scalars['String'];
};

export type TProjectMemberInput = {
  newUserRole: TProjectRoleEnum;
  projectUUID: Scalars['ID'];
  userToAssignUUID: Scalars['ID'];
};

export enum TProjectMemberOrderEnum {
  PROJECT_ROLE_ASC = 'PROJECT_ROLE_ASC',
  PROJECT_ROLE_DESC = 'PROJECT_ROLE_DESC'
}

export type TProjectMemberOutput = {
  id: Scalars['ID'];
  invitedBy?: Maybe<TSimpleUserProfileOutput>;
  invitedOn?: Maybe<Scalars['DateTime']>;
  projectAccountRole: TAccountRoleEnum;
  projectRole: TProjectRoleEnum;
  userDetails: TSimpleUserProfileOutput;
};

export type TProjectMembersResultOutput = {
  contents: Array<TProjectMemberOutput>;
  orderBy?: Maybe<TProjectMemberOrderEnum>;
};

export enum TProjectOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export type TProjectOutput = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<TSimpleUserProfileOutput>;
  deletable: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: TLabelsOutput;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  modifiedAt: Scalars['DateTime'];
  modifiedBy?: Maybe<TSimpleUserProfileOutput>;
  name: Scalars['String'];
  ownedBy: TSimpleUserProfileOutput;
  projectMembers: TProjectMembersResultOutput;
  rootFolder: TFolderOutput;
  thumbnailFullImagePath?: Maybe<Scalars['String']>;
  thumbnailPath?: Maybe<Scalars['String']>;
  totalAssets: Scalars['Int'];
  totalUploadingAssets: Scalars['Int'];
};


export type TProjectOutputProjectMembersArgs = {
  orderBy?: InputMaybe<TProjectMemberOrderEnum>;
};

export type TProjectPageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TProjectResultOutput = {
  appliedFilters: TFilterProjectAppliedOutput;
  appliedPagination: TProjectAppliedPageOutput;
  contents: Array<Maybe<TProjectOutput>>;
  orderBy: TProjectOrderEnum;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export enum TProjectRoleEnum {
  PROJECT_ROLE_COLLABORATOR = 'PROJECT_ROLE_COLLABORATOR',
  PROJECT_ROLE_EDITOR = 'PROJECT_ROLE_EDITOR',
  PROJECT_ROLE_OWNER = 'PROJECT_ROLE_OWNER',
  PROJECT_ROLE_VIEWER = 'PROJECT_ROLE_VIEWER'
}

export type TProjectThumbnailInput = {
  projectUUID: Scalars['ID'];
};

export type TProjectThumbnailOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

/**
 * Result of query project.
 * Of type ProjectOutput in case of success, otherwise one of the errors.
 */
export type TProjectUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedOutput | TProjectErrorTooManyRequestsOutput | TProjectOutput;

/**
 * Result of query projects.
 * Of type ProjectResultOutput in case of success, otherwise one of the errors.
 */
export type TProjectsUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorTooManyRequestsOutput | TProjectResultOutput;

export type TPropertySpatial = {
  /**
   * Each bounding box is provided as four or six numbers, depending on
   * whether the coordinate reference system includes a vertical axis
   * (height or depth):
   *
   * * Lower left corner, coordinate axis 1
   * * Lower left corner, coordinate axis 2
   * * Minimum value, coordinate axis 3 (optional)
   * * Upper right corner, coordinate axis 1
   * * Upper right corner, coordinate axis 2
   * * Maximum value, coordinate axis 3 (optional)
   *
   * The coordinate reference system of the values is WGS 84 longitude/latitude
   * (http://www.opengis.net/def/crs/OGC/1.3/CRS84) unless a different coordinate
   * reference system is specified in 'crs'.
   *
   * For WGS 84 longitude/latitude the values are in most cases the sequence of
   * minimum longitude, minimum latitude, maximum longitude and maximum latitude.
   * However, in cases where the box spans the antimeridian the first value
   * (west-most box edge) is larger than the third value (east-most box edge).
   *
   * If the vertical axis is included, the third and the sixth number are
   * the bottom and the top of the 3-dimensional bounding box.
   *
   * If a feature has multiple spatial geometry properties, it is the decision of the
   * server whether only a single spatial geometry property is used to determine
   * the extent or all relevant geometries.
   */
  bbox: Array<Array<Scalars['Float']>>;
  /**
   * Coordinate reference system of the coordinates in the spatial extent
   * (property 'bbox'). The default reference system is WGS 84 longitude/latitude.
   * In the Core this is the only supported coordinate reference system.
   * Extensions may support additional coordinate reference systems and add
   * additional enum values.
   */
  crs?: Maybe<Scalars['String']>;
};

export type TPropertyTemporal = {
  /**
   * One or more time intervals that describe the temporal extent of the dataset.
   * The value 'null' is supported and indicates an open time intervall.
   * In the Core only a single time interval is supported. Extensions may support
   * multiple intervals. If multiple intervals are provided, the union of the
   * intervals describes the temporal extent.
   *
   * The timestamps
   * are in the coordinate reference system specified in 'trs'. By default
   * this is the Gregorian calendar.
   */
  interval: Array<Maybe<Array<Maybe<Scalars['DateTime']>>>>;
  /**
   * Coordinate reference system of the coordinates in the temporal extent
   * (property 'interval'). The default reference system is the Gregorian calendar.
   * In the Core this is the only supported temporal reference system.
   * Extensions may support additional temporal reference systems and add
   * additional enum values.
   */
  trs: Scalars['String'];
};

export type TProvider = {
  /**
   * Multi-line description to add further provider information such as processing
   * details for processors and producers, hosting details for hosts or basic contact information.
   *
   * CommonMark 0.29 syntax MAY be used for rich text representation.
   */
  description?: Maybe<Scalars['String']>;
  /** The name of the organization or the individual. */
  name: Scalars['String'];
  /**
   * Roles of the provider.
   *
   * The provider's role(s) can be one or more of the following
   * elements:
   *
   * * licensor: The organization that is licensing the dataset under
   *   the license specified in the collection's license field.
   * * producer: The producer of the data is the provider that
   *   initially captured and processed the source data, e.g. ESA for
   *   Sentinel-2 data.
   * * processor: A processor is any provider who processed data to a
   *   derived product.
   * * host: The host is the actual provider offering the data on their
   *   storage. There should be no more than one host, specified as last
   *   element of the list..
   */
  roles?: Maybe<Array<TProviderRole>>;
  /** Homepage on which the provider describes the dataset and publishes contact information. */
  url?: Maybe<Scalars['String']>;
};

export enum TProviderRole {
  HOST = 'HOST',
  LICENSOR = 'LICENSOR',
  PROCESSOR = 'PROCESSOR',
  PRODUCER = 'PRODUCER'
}

export type TQuery = {
  _entities: Array<Maybe<T_Entity>>;
  _getDummyAddressPage?: Maybe<TAddressPageOutput>;
  _getInfo?: Maybe<TInfoPage>;
  _noop?: Maybe<Scalars['Boolean']>;
  _returnDummyJobPage?: Maybe<TJobPage>;
  _returnDummyJobSummaryGQL?: Maybe<TJobSummaryGql>;
  /** Returns an overview over the account to which the currently logged in user belongs to. */
  accountInformation: TAccountDetailUnion;
  /** Returns an overview over the account members. */
  accountMembers: TAccountMembersResultUnion;
  /**
   * Retrieve all the countries that an address can have.
   * This query can be called anonymously.
   */
  allCountries: Array<Maybe<TCountryOutput>>;
  /** Retrieves the specified application template. */
  applicationTemplate: TApplicationTemplateUnion;
  /** Retrieves the specified application templates. */
  applicationTemplates: TApplicationTemplatesUnion;
  /** Retrieves the specified asset, provided the user has access to it. */
  asset?: Maybe<TAssetUnion>;
  /** Retrieve all the assets contained in an account and filter and paginate on them. */
  assetsInAccount?: Maybe<TAssetsInAccountUnion>;
  /**
   * Retrieve all the assets contained in a Folder, this method is out of federation
   * mostly used for testing purposes
   * it is suggested to use getFolders()
   */
  assetsInFolder?: Maybe<TAssetsInFolderUnion>;
  /**
   * Gets all the processing pipelines  that HXDR can trigger for the current groupedAssetId
   * The already generated artifacts (e.g HSPC) will be used for deciding the available processing pipelines
   */
  availableProcessingPipelines: Array<TProcessingTypeOutput>;
  /** the feature collection by id */
  collection?: Maybe<TCollection>;
  /** the feature collections in the dataset */
  collections: Array<Maybe<TCollection>>;
  comment: TCommentPayload;
  comments: TCommentsPayload;
  dummyQuery?: Maybe<Scalars['String']>;
  /** Returns an overview of the external account members. */
  externalAccountMembers: TExternalAccountMembersResultUnion;
  /** Retrieves the specified folder, provided the user has access to it. */
  folder?: Maybe<TFolderUnion>;
  gatewayInfo: TGatewayInfoOutput;
  /**
   * Returns the details of the acount the logged in user belongs to.
   * This query can be called only with a bearer token.
   */
  getAccount?: Maybe<TAccountInfoOutput>;
  /**
   * Returns an overview over the account to which the currently logged in user belongs to.
   * **DEPRECATED in favor of query `accountInformation`**
   * @deprecated No longer supported, in favor of query `accountInformation`, termination planed for 2023-07-31
   */
  getAccountInformation?: Maybe<TAccountDetailOutput>;
  /**
   * Returns an overview over the account members.
   * **DEPRECATED in favor of query `accountMembers`**
   * @deprecated No longer supported, in favor of query `accountMembers`, termination planed for 2023-07-31
   */
  getAccountMembers?: Maybe<TAccountMembersResultOutput>;
  /**
   * **DEPRECATED in favor of mutation `getAccountUsageV2`**
   *
   * Retrieve usage of an account
   * @deprecated No longer supported, in favor of mutation `getAccountUsageV2`, termination planed for 2023-07-31
   */
  getAccountUsage: TUsageOutput;
  /** Retrieve usage of an account */
  getAccountUsageV2: TUsageAccountUnion;
  /**
   * Retrieve all the accounts within a tenant.
   * This query can be called only with a bearer token.
   */
  getAccounts?: Maybe<TAccountProfileResultOutput>;
  /**
   * Retrieve the active payment card for an account.
   * This query can be called only with a bearer token.
   */
  getActivePaymentCard?: Maybe<TPaymentCardOutput>;
  getAllBaselayers: TBaselayerResultOutput;
  /**
   * @deprecated
   * reason: "Use getAllGroupedAssetGeoreferences instead"
   * @deprecated Use getAllGroupedAssetGeoreferences instead
   */
  getAllGeoreferences: TGeoreferenceResultsOutput;
  getAllGroupedAssetGeoreferences: TGroupedAssetGeoreferenceResultsOutput;
  getAllOrders: Array<TOrderGql>;
  /**
   * Retrieve all the payment cards for an account.
   * This query can be called only with a bearer token.
   */
  getAllPaymentCards: Array<TPaymentCardOutput>;
  /**
   * **DEPRECATED in favor of mutation `getAllSubscriptionInvoicesV2`**
   *
   * Load all invoices for a given payment system account related to its subscriptions
   * @deprecated No longer supported, in favor of mutation `getAllSubscriptionInvoicesV2`, termination planed for 2023-07-31
   */
  getAllSubscriptionInvoices: Array<Maybe<TSubscriptionInvoiceOutput>>;
  /** Load all invoices for a given payment system account related to its subscriptions */
  getAllSubscriptionInvoicesV2: TSubscriptionInvoicesUnion;
  /**
   * **DEPRECATED in favor of mutation `getAllSubscriptionOrdersV2`**
   *
   * Load all invoice for an account (SHOULD BE REMOVED ONCE FE)
   * @deprecated No longer supported, in favor of mutation `getAllSubscriptionOrdersV2`, termination planed for 2023-07-31
   */
  getAllSubscriptionOrders: Array<Maybe<TSubscriptionOrderOutput>>;
  /** Load all invoice for an account (SHOULD BE REMOVED ONCE FE) */
  getAllSubscriptionOrdersV2: TSubscriptionOrdersUnion;
  /**
   * **DEPRECATED in favor of mutation `getAllSubscriptionsV2`**
   *
   * Load all subscriptions for an account.
   * @deprecated No longer supported, in favor of mutation `getAllSubscriptionsV2`, termination planed for 2023-07-31
   */
  getAllSubscriptions: Array<TSubscriptionOutput>;
  /** Load all subscriptions for an account. */
  getAllSubscriptionsV2: TSubscriptionsUnion;
  /**
   * Returns all tenants in the system
   * **DEPRECATED in favor of query `tenants`**
   * @deprecated No longer supported, in favor of query `tenants`, termination planed for 2023-07-31
   */
  getAllTenants: Array<TTenantOutput>;
  /**
   * Retrieve all the artifacts contained in an asset,
   * this method is out of federation
   * mostly used for testing purposes
   * it is suggested to use getAsset()
   */
  getArtifactsInAsset?: Maybe<TArtifactResultOutput>;
  /**
   * **DEPRECATED in favor of query `asset`, termination planed for 2023-08-31**
   *
   * Retrieves the specified asset, provided the user has access to it.
   * @deprecated No longer supported, in favor of query `asset`, termination planed for 2023-08-31
   */
  getAsset?: Maybe<TGroupedAssetOutput>;
  /**
   * **DEPRECATED in favor of query `assetsInFolder`, termination planed for 2023-07-31"**
   *
   * Retrieve all the assets contained in a Folder, this method is out of federation
   * mostly used for testing purposes
   * it is suggested to use getFolders()
   * @deprecated No longer supported, in favor of query `assetsInFolder`, termination planed for 2023-07-31
   */
  getAssetsInFolder?: Maybe<TFolderOutput>;
  /**
   * Retrieve a Cart for the current logged in user, if the cart is expired, the old one is deleted and a new one is provided
   *
   * The Life time of a Cart is 6 hours
   */
  getCart?: Maybe<TCartOutput>;
  /**
   * **DEPRECATED in favor of mutation `getCurrentSubscriptionV2`**
   *
   * Load current active subscription for an account.
   * @deprecated No longer supported, in favor of mutation `getCurrentSubscriptionV2`, termination planed for 2023-07-31
   */
  getCurrentSubscription?: Maybe<TSubscriptionOutput>;
  /** Load current active subscription for an account. */
  getCurrentSubscriptionV2: TSubscriptionUnion;
  /** Gets the device specified by the device serial number if it is linked to the account of the calling user. */
  getDevice?: Maybe<TDeviceOutput>;
  /** Gets all devices which are linked to the account of the calling user. */
  getDevices?: Maybe<TDeviceResultOutput>;
  /** Given a fileId, return the file information if it exists. */
  getFileStatus?: Maybe<TFileStatusOutput>;
  /**
   * Given the Id of the assetout call and filters, orderBy clause and paging,
   * it returns the files according to the filters in input grouped by their status
   */
  getFiles?: Maybe<TFileResultOutput>;
  /**
   * **DEPRECATED in favor of query `folder`**
   *
   * Retrieves the specified folder, provided the user has access to it.
   * @deprecated No longer supported, in favour of query `folder`, termination planed for 2023-07-31
   */
  getFolder?: Maybe<TFolderOutput>;
  /**
   * Retrieve the Georeference with ID
   * @deprecated
   * reason: "Use getGroupedAssetGeoreference instead"
   * @deprecated Use getGroupedAssetGeoreference instead
   */
  getGeoreference: TGeoreferenceOutput;
  /** Retrieve the Georeference with ID */
  getGroupedAssetGeoreference: TGroupedAssetGeoreferenceOutput;
  getGroupedAssetTour?: Maybe<TGroupedAssetTourOutput>;
  getLastOrder?: Maybe<TOrderGql>;
  /** Retrieves the contents of a currently valid license for the given LuciadRIA version. */
  getLuciadRIALicense: TLuciadRiaLicenseUnion;
  /**
   * **DEPRECATED in favor of query `multipartUploadURL`, termination planed for 2023-07-31"**
   *
   * In order to understand how to use pre-sign functionality have a look at
   * [url](https://docs.aws.amazon.com/AmazonS3/latest/userguide/mpuoverview.html)
   *
   * Whenever you upload a part, Amazon S3 returns an ETag header in its response.
   * For each part upload, you must record the part number and the ETag value.
   * You must include these values in the subsequent request to complete the multipart upload.
   * @deprecated No longer supported, in favor of query `multipartUploadURL`, termination planed for 2023-07-31
   */
  getMultipartUploadURL?: Maybe<TMultipartUploadUrlOutput>;
  /**
   * **DEPRECATED There will be no specific my assets project in future
   *
   * Retrieves the MyAssetsProject for the user.
   * @deprecated No longer supported, in favour of query `myAssetProject`, termination planed for 2023-07-31
   */
  getMyAssetsProject?: Maybe<TProjectOutput>;
  getOrder?: Maybe<TOrderGql>;
  getOrderItemsForAsset: Array<TOrderItemGql>;
  getPaymentMethods: Array<Maybe<TPaymentMethodShopEnum>>;
  /**
   * **DEPRECATED in favor of query `project`**
   *
   * Retrieves the specified project if it exists, null otherwise.
   * @deprecated No longer supported, in favour of query `project`, termination planed for 2023-07-31
   */
  getProject?: Maybe<TProjectOutput>;
  /** Retrieves the specified user profile if both that user and the authenticated user are members of the given project */
  getProjectMember?: Maybe<TSimpleUserProfileUnion>;
  /**
   * **DEPRECATED in favor of query `projects` **
   *
   * Retrieves all projects the user has access to, filtered and ordered by
   * as specified.
   * @deprecated No longer supported, in favour of query `projects`, termination planed for 2023-08-31
   */
  getProjects?: Maybe<TProjectResultOutput>;
  /**
   * Obtain a quotation over a selection
   * **DEPRECATED in favor of query `quotation`**
   * @deprecated No longer supported, in favor of query `quotation`, termination planed for 2023-07-31
   */
  getQuotation?: Maybe<TQuotationOutput>;
  /**
   * Retrieve all the assignable referrers.
   * This query can be called only with a bearer token.
   */
  getReferrers?: Maybe<Array<Maybe<TReferrerOutput>>>;
  /**
   * Returns the roles of all users in the project with given id
   * **DEPRECATED in favor of query `rolesOfProjectUsers`**
   * @deprecated No longer supported, in favor of query `rolesOfProjectUsers`, termination planed for 2023-07-31
   */
  getRolesOfAllUsersInProject: Array<Maybe<TUserProjectRoleOutput>>;
  /** Return the Stripe public key. */
  getStripePublicKey: Scalars['String'];
  /**
   * **DEPRECATED in favor of mutation `getSubscriptionQuotationV2`**
   *
   * Load quotation for a specific subscription tier
   * @deprecated No longer supported, in favor of mutation `getSubscriptionQuotationV2`, termination planed for 2023-07-31
   */
  getSubscriptionQuotation: TSubscriptionQuotationOutput;
  /** Load quotation for a specific subscription tier */
  getSubscriptionQuotationV2: TSubscriptionQuotationUnion;
  /**
   * **DEPRECATED in favor of mutation `getSubscriptionTiersV2`**
   *
   * Load all available subscription tiers
   * @deprecated No longer supported, in favor of mutation `getSubscriptionTiersV2`, termination planed for 2023-07-31
   */
  getSubscriptionTiers: Array<Maybe<TSubscriptionTierOutput>>;
  /** Load all available subscription tiers */
  getSubscriptionTiersV2: TSubscriptionTiersUnion;
  /**
   * Returns the tenant for the given id
   * **DEPRECATED in favor of query `tenant`**
   * @deprecated No longer supported, in favor of query `tenant`, termination planed for 2023-07-31
   */
  getTenant: TTenantOutput;
  /**
   * Given a fileId, this method will give back a list of all its multipart parts
   * with the Amazon ETag, part number and their size, if the file exists.
   */
  getUploadedParts?: Maybe<TMultipartUploadPartsOutput>;
  /** Returns the profile details of the currently logged in user. */
  getUser: TUserProfileOutput;
  /**
   * If user is tenant admin and tenantRole filter is set, it will only returns the users that have the
   * specified tenant role
   */
  getUsers?: Maybe<TUserProfileResultOutput>;
  /** This validates whether the current country assigned to this account (extracted from cache) is supported from HxDR shop perspective */
  isAddressSupportedByShop: TShopSupportedCountryValidationOutput;
  /** Returns if last upload consent is still valid or needed to be renewed. */
  isLastUploadConsentValid?: Maybe<Scalars['Boolean']>;
  item: TFeatureItem;
  /**
   * The latest available application template version. This can be used for checking if there's an update available for
   * your application templates. In order to update one of your application templates, simply call
   * `updateApplicationTemplate` for it.
   */
  latestApplicationTemplateVersion: Scalars['String'];
  /** Load a specific user cache into the system, Admin only, it uses the token user as reference */
  loadUserCacheAsMine?: Maybe<Scalars['Boolean']>;
  /**
   * In order to understand how to use pre-sign functionality have a look at
   * [url](https://docs.aws.amazon.com/AmazonS3/latest/userguide/mpuoverview.html)
   *
   * Whenever you upload a part, Amazon S3 returns an ETag header in its response.
   * For each part upload, you must record the part number and the ETag value.
   * You must include these values in the subsequent request to complete the multipart upload.
   */
  multipartUploadURL?: Maybe<TMultipartUploadUrlUnion>;
  /**
   * **DEPRECATED There will be no specific my assets project in future
   *
   * Retrieves the MyAssetsProject for the user.
   * @deprecated No longer supported, my assets will become a normal project`, termination planed for 2023-07-31
   */
  myAssetsProject?: Maybe<TMyAssetsProjectUnion>;
  noop?: Maybe<Scalars['String']>;
  /** Returns the product with the given code. */
  product: TProductUnion;
  /** Returns all active products. */
  products: Array<Scalars['String']>;
  /** Retrieves the specified project if it exists, null otherwise. */
  project?: Maybe<TProjectUnion>;
  /**
   * Retrieves all projects the user has access to, filtered and ordered by
   * as specified.
   */
  projects?: Maybe<TProjectsUnion>;
  /** Obtain a quotation over a selection */
  quotation?: Maybe<TQuotationUnion>;
  /** Returns the roles of all users in the project with given id */
  rolesOfProjectUsers: TUserProjectRoleUnion;
  /** Retrieve items matching filters. Intended as the standard, full-featured query API. */
  search: TItemCollection;
  /** Retrieves all the labels available to the user and according to the input criteria */
  searchLabels: TSearchLabelsUnion;
  /**
   * **DEPRECATED There will be no specific my assets project in future
   *
   * Retrieves all folders and assets the user has, filtered and ordered by
   * as specified.
   * @deprecated No longer supported, in favour of query `searchMyAssetsProjectV2`, termination planed for 2023-07-31
   */
  searchMyAssetsProject?: Maybe<TSearchOutput>;
  /**
   * **DEPRECATED There will be no specific my assets project in future
   *
   * Retrieves all folders and assets the user has, filtered and ordered by
   * as specified.
   * @deprecated No longer supported, my assets will become a normal project`, termination planed for 2023-07-31
   */
  searchMyAssetsProjectV2?: Maybe<TSearchMyAssetsProjectUnion>;
  /**
   * **DEPRECATED in favor of query `searchProjectV2`**
   *
   * Retrieves all folders and assets the user has access to in a project, filtered and ordered by
   * as specified.
   * @deprecated No longer supported, in favour of query `searchProjectV2`, termination planed for 2023-07-31
   */
  searchProject?: Maybe<TSearchOutput>;
  /**
   * Retrieves all folders and assets the user has access to in a project, filtered and ordered by
   * as specified.
   */
  searchProjectV2?: Maybe<TSearchProjectUnion>;
  /** Returns shared grouped asset id and authentication */
  sharedGroupedAsset: TSharedGroupedAssetUnion;
  /** This method retrieves all the countries supported by HxDR */
  supportedCountries: Array<Maybe<TSupportedCountryOutput>>;
  /** Returns the tenant for the given id */
  tenant: TTenantUnion;
  /**
   * **DEPRECATED in favor of mutation `getAccountUsageV2`**
   *
   * Retrieve tenant usage of an account
   * @deprecated No longer supported, in favor of mutation `tenantUsageV2`, termination planed for 2023-07-31
   */
  tenantUsage: TUsageTenantOutput;
  /** Retrieve tenant usage of an account */
  tenantUsageV2: TUsageTenantUnion;
  /** Returns all tenants in the system */
  tenants: TTenantsUnion;
  tokenWallet: TWalletUnion;
  tokensTransactions: TTokensTransactionsUnion;
  /**
   * Validate an address
   * This query can be called anonymously.
   */
  validateAddress?: Maybe<TAddressValidOutput>;
  /**
   * ** DEPRECATED in favor of query `products` **
   * @deprecated No longer supported, in favor of query `products`, termination planned for 2023-07-31
   */
  viewAllProducts?: Maybe<Array<Maybe<THxdrProductOutput>>>;
  viewCart?: Maybe<TCartOutput>;
  /**
   * ** DEPRECATED in favor of query `product` **
   * @deprecated No longer supported, in favor of query `product`, termination planned for 2023-07-31
   */
  viewProduct?: Maybe<THxdrProductOutput>;
};


export type TQuery_EntitiesArgs = {
  representations: Array<Scalars['_Any']>;
};


export type TQuery_GetInfoArgs = {
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type TQueryAccountMembersArgs = {
  orderBy?: InputMaybe<TAccountMembersOrderEnum>;
};


export type TQueryApplicationTemplateArgs = {
  id: Scalars['ID'];
};


export type TQueryApplicationTemplatesArgs = {
  params?: InputMaybe<TApplicationTemplatesInput>;
};


export type TQueryAssetArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TQueryAssetsInAccountArgs = {
  filter?: InputMaybe<TFilterAssetInput>;
  orderBy?: InputMaybe<TAssetOrderEnum>;
  paging?: InputMaybe<TAssetPageInput>;
};


export type TQueryAssetsInFolderArgs = {
  folderId: Scalars['ID'];
};


export type TQueryAvailableProcessingPipelinesArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TQueryCollectionArgs = {
  id: Scalars['String'];
};


export type TQueryCommentArgs = {
  id: Scalars['ID'];
};


export type TQueryCommentsArgs = {
  onAnchorId: Scalars['ID'];
  onAnchorType: TCommentAnchorType;
  pagination?: InputMaybe<TCommentPaginationInput>;
  parentId?: InputMaybe<Scalars['ID']>;
};


export type TQueryFolderArgs = {
  folderId: Scalars['ID'];
};


export type TQueryGetAccountMembersArgs = {
  orderBy?: InputMaybe<TAccountMembersOrderEnum>;
};


export type TQueryGetAccountUsageArgs = {
  params: TUsageInput;
};


export type TQueryGetAccountUsageV2Args = {
  params: TAccountUsageInput;
};


export type TQueryGetAccountsArgs = {
  filter?: InputMaybe<TFilterAccountProfileInput>;
  orderBy?: InputMaybe<TAccountProfileOrderEnum>;
  paging?: InputMaybe<TAccountProfilePageInput>;
};


export type TQueryGetAllBaselayersArgs = {
  filter?: InputMaybe<TAddressViewingModeEnum>;
  paging?: InputMaybe<TBaselayerPageInput>;
};


export type TQueryGetAllGeoreferencesArgs = {
  paging?: InputMaybe<TGeoreferencePageInput>;
};


export type TQueryGetAllGroupedAssetGeoreferencesArgs = {
  paging?: InputMaybe<TGeoreferencePaginationInput>;
};


export type TQueryGetArtifactsInAssetArgs = {
  assetId: Scalars['ID'];
};


export type TQueryGetAssetArgs = {
  groupedAssetId: Scalars['ID'];
};


export type TQueryGetAssetsInFolderArgs = {
  folderId: Scalars['ID'];
};


export type TQueryGetDeviceArgs = {
  deviceId?: InputMaybe<Scalars['ID']>;
};


export type TQueryGetDevicesArgs = {
  orderBy?: InputMaybe<TDeviceOrderEnum>;
  pagination?: InputMaybe<TDeviceAppliedPageOutput>;
};


export type TQueryGetFileStatusArgs = {
  assetId: Scalars['ID'];
  fileId: Scalars['ID'];
};


export type TQueryGetFilesArgs = {
  assetId: Scalars['ID'];
  filter?: InputMaybe<TFilterFileInput>;
  orderBy?: InputMaybe<TFileOrderEnum>;
  paging?: InputMaybe<TFilePageInput>;
};


export type TQueryGetFolderArgs = {
  folderId: Scalars['ID'];
};


export type TQueryGetGeoreferenceArgs = {
  georeferenceId: Scalars['ID'];
};


export type TQueryGetGroupedAssetGeoreferenceArgs = {
  georeferenceId: Scalars['ID'];
  parentId?: InputMaybe<Scalars['ID']>;
};


export type TQueryGetGroupedAssetTourArgs = {
  params: TGetGroupedAssetTourInput;
};


export type TQueryGetLuciadRiaLicenseArgs = {
  appClientId: Scalars['String'];
  luciadRIAVersion: Scalars['String'];
};


export type TQueryGetMultipartUploadUrlArgs = {
  params: TMultipartUploadUrlInput;
};


export type TQueryGetOrderArgs = {
  cartId: Scalars['ID'];
};


export type TQueryGetOrderItemsForAssetArgs = {
  assetId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type TQueryGetProjectArgs = {
  projectId: Scalars['ID'];
};


export type TQueryGetProjectMemberArgs = {
  projectId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type TQueryGetProjectsArgs = {
  params?: InputMaybe<TGetProjectsInput>;
};


export type TQueryGetQuotationArgs = {
  selection: TSelectionInput;
};


export type TQueryGetRolesOfAllUsersInProjectArgs = {
  projectUUID: Scalars['ID'];
};


export type TQueryGetSubscriptionQuotationArgs = {
  params?: InputMaybe<TSubscriptionQuotationInput>;
};


export type TQueryGetSubscriptionQuotationV2Args = {
  params?: InputMaybe<TSubscriptionQuotationInput>;
};


export type TQueryGetTenantArgs = {
  tenantId?: InputMaybe<Scalars['ID']>;
};


export type TQueryGetUploadedPartsArgs = {
  fileId: Scalars['ID'];
};


export type TQueryGetUsersArgs = {
  filter?: InputMaybe<TFilterUserProfileInput>;
  orderBy?: InputMaybe<TUserProfileOrderEnum>;
  paging?: InputMaybe<TUserProfilePageInput>;
};


export type TQueryItemArgs = {
  collectionId: Scalars['String'];
  itemId: Scalars['String'];
};


export type TQueryLoadUserCacheAsMineArgs = {
  params?: InputMaybe<TUserInput>;
};


export type TQueryMultipartUploadUrlArgs = {
  params: TMultipartUploadUrlInput;
};


export type TQueryProductArgs = {
  params: TProductInput;
};


export type TQueryProjectArgs = {
  projectId: Scalars['ID'];
};


export type TQueryProjectsArgs = {
  params?: InputMaybe<TGetProjectsInput>;
};


export type TQueryQuotationArgs = {
  selection: TSelectionInput;
};


export type TQueryRolesOfProjectUsersArgs = {
  projectUUID: Scalars['ID'];
};


export type TQuerySearchArgs = {
  bbox?: InputMaybe<Array<Scalars['Float']>>;
  collections?: InputMaybe<Array<Scalars['String']>>;
  dateTime?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  intersection?: InputMaybe<TGeoJsonShapeInput>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type TQuerySearchLabelsArgs = {
  input: TSearchLabelsInput;
};


export type TQuerySearchProjectArgs = {
  projectId: Scalars['ID'];
};


export type TQuerySearchProjectV2Args = {
  projectId: Scalars['ID'];
};


export type TQuerySharedGroupedAssetArgs = {
  sharingCodeId: Scalars['ID'];
};


export type TQueryTenantArgs = {
  tenantId?: InputMaybe<Scalars['ID']>;
};


export type TQueryTenantUsageArgs = {
  params: TUsageTenantInput;
};


export type TQueryTenantUsageV2Args = {
  params: TUsageTenantInput;
};


export type TQueryTokensTransactionsArgs = {
  params?: InputMaybe<TTokensTransactionsPageInput>;
};


export type TQueryValidateAddressArgs = {
  params?: InputMaybe<TAccountAddressInput>;
};


export type TQueryViewCartArgs = {
  cartId: Scalars['ID'];
};


export type TQueryViewProductArgs = {
  params: TProductCodeInput;
};

/** The product is not found. */
export type TQuotationErrorProductNotFoundOutput = TBaseQuotationErrorInterface & {
  message: Scalars['String'];
};

export type TQuotationItemOutput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['String'];
  discountRate?: Maybe<Scalars['BigDecimal']>;
  discountedAmount?: Maybe<Scalars['BigDecimal']>;
  quotationItemId: Scalars['ID'];
  resolutionUnit: Scalars['String'];
  resolutionValue: Scalars['Float'];
};

/**
 * Obtain a quotation over a selection
 * **DEPRECATED in favor of query `QuoteOutput`**
 */
export type TQuotationOutput = {
  coordinateSystems: Array<TCoordinateSystemOutput>;
  dataType: TDataTypeEnum;
  formats: Array<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  polygonDefType: Scalars['String'];
  productIdentifier: Scalars['String'];
  quotationId: Scalars['ID'];
  quotationItems: Array<Maybe<TQuotationItemOutput>>;
};

export type TQuotationProductOutput = {
  productCode: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  sapArticleNumber?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type TQuotationUnion = TQuoteOutput | TShopErrorBackendFailedOutput | TShopErrorNoOverlapWithProductOutput | TShopErrorOperationNotAllowedOutput | TShopErrorProductNotFoundOutput | TShopErrorProductPricingNotFoundOutput;

export type TQuoteOutput = {
  coordinateSystems: Array<TCoordinateSystemOutput>;
  coveragePercentage?: Maybe<Scalars['BigDecimal']>;
  dataType: TDataTypeEnum;
  formats: Array<Scalars['String']>;
  polygonDefType: TPolygonType;
  productIdentifier: Scalars['String'];
  quotationId: Scalars['ID'];
  quotationItems: Array<Maybe<TQuotationItemOutput>>;
};

export type TRecalculateGroupedAssetTourKeyframesInput = {
  /** Distribute equally */
  distributeEqually?: InputMaybe<Scalars['Boolean']>;
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** New duration */
  newDuration?: InputMaybe<Scalars['Float']>;
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TReferencedBoundsDimensionsOutput = {
  depth: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
};

export type TReferencedBoundsGeolocationOutput = {
  height: Scalars['Float'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type TReferencedBoundsOutput = {
  cartesianBounds: TCartesianBoundsOutput;
  originGeolocation: TReferencedBoundsGeolocationOutput;
};

export type TReferrerOutput = {
  referrerId: Scalars['ID'];
  referrerName: Scalars['String'];
};

export type TRemoteDeviceIdentifiersGql = {
  deviceSN: Scalars['ID'];
  deviceType: TDeviceTypeGql;
  provisioningId: Scalars['ID'];
  userID: Scalars['ID'];
};

export type TRemoteJobIdentifierGql = {
  jobId: Scalars['ID'];
  provisioningId: Scalars['ID'];
};

export type TRemoveCartItemInput = {
  cartItemUUID: Scalars['ID'];
};

export type TRemoveLabelsInput = {
  entityId: Scalars['ID'];
  entityType: TEntityTypeEnum;
  labels: Array<InputMaybe<TLabelInput>>;
};

/**
 * Result of mutation removeLabels.
 * Of type LabelsOutput in case of success, otherwise one of the errors.
 */
export type TRemoveLabelsUnion = TLabelErrorOperationNotAllowedOutput | TLabelsOutput;

export type TRenderable = {
  endpoint: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  type: TAddressTypeEnum;
};

export type TReportGenerationInput = {
  accountId: Scalars['String'];
  emailBcc?: InputMaybe<Scalars['String']>;
  emailCc?: InputMaybe<Scalars['String']>;
  emailTo: Scalars['String'];
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type TReportGenerationOutput = {
  message: Scalars['String'];
  statusCode: Scalars['Int'];
};

export enum TSavedFormatEnum {
  ASCII_XYZ_GRID_ZIPPED = 'ASCII_XYZ_GRID_ZIPPED',
  B2G = 'B2G',
  BINARY_MAP_FILE = 'BINARY_MAP_FILE',
  /** @deprecated In favour of LUCIAD_PANORAMIC */
  E57_PANO = 'E57_PANO',
  E57_POINT_CLOUD = 'E57_POINT_CLOUD',
  E57_REFERENCE = 'E57_REFERENCE',
  E57_REFERENCE_BOUNDS = 'E57_REFERENCE_BOUNDS',
  ER_MAPPER_BIN_GRID_ZIPPED = 'ER_MAPPER_BIN_GRID_ZIPPED',
  ESRI_ARCGRID_ZIPPED = 'ESRI_ARCGRID_ZIPPED',
  FOLDER_STRUCTURE = 'FOLDER_STRUCTURE',
  FOLDER_ZIPPED = 'FOLDER_ZIPPED',
  GEOTIFF = 'GEOTIFF',
  GEOTIFF_ZIPPED = 'GEOTIFF_ZIPPED',
  GLB = 'GLB',
  HSPC = 'HSPC',
  IMAGES = 'IMAGES',
  IMMERSAL_COARSE_REGISTRATION = 'IMMERSAL_COARSE_REGISTRATION',
  JPG_ZIPPED = 'JPG_ZIPPED',
  LABEL_ANNOTATION = 'LABEL_ANNOTATION',
  LAS = 'LAS',
  LGS = 'LGS',
  LGSX = 'LGSX',
  LUCIAD_PANORAMIC = 'LUCIAD_PANORAMIC',
  METADATA = 'METADATA',
  METADATA_JSON = 'METADATA_JSON',
  OBJ = 'OBJ',
  OBJ_MTL_ZIPPED = 'OBJ_MTL_ZIPPED',
  OGC3D_TILES = 'OGC3D_TILES',
  PANORAMA_IMAGE_TILES = 'PANORAMA_IMAGE_TILES',
  PANORAMA_IMAGE_TILES_ZIPPED = 'PANORAMA_IMAGE_TILES_ZIPPED',
  PNG_ZIPPED = 'PNG_ZIPPED',
  PROJECTION = 'PROJECTION',
  PTS = 'PTS',
  RECTIFIED_GRAYSCALED_IMAGES_WITH_METADATA = 'RECTIFIED_GRAYSCALED_IMAGES_WITH_METADATA',
  SAN_RAMON_FINE_COARSE = 'SAN_RAMON_FINE_COARSE',
  SAN_RAMON_INTERMEDIATE_RESULT_ZIPPED = 'SAN_RAMON_INTERMEDIATE_RESULT_ZIPPED',
  SURFER_BINARY_GRID_ZIPPED = 'SURFER_BINARY_GRID_ZIPPED',
  TEXTURING_IMAGE = 'TEXTURING_IMAGE',
  TRAJECTORY = 'TRAJECTORY',
  UNSPECIFIED = 'UNSPECIFIED',
  WMS = 'WMS'
}

export enum TScanningDeviceType {
  BLK2FLY = 'BLK2FLY',
  BLK2GO = 'BLK2GO',
  BLK360 = 'BLK360',
  BLK360_G1 = 'BLK360_G1',
  BLKARC = 'BLKARC',
  GENERIC = 'GENERIC',
  RTC360 = 'RTC360'
}

export type TSearchLabelsInput = {
  entityId?: InputMaybe<Scalars['ID']>;
  entityType?: InputMaybe<TEntityTypeEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchTerm?: InputMaybe<Scalars['String']>;
};

/**
 * Result of query labels.
 * Of type LabelsResultOutput in case of success, otherwise one of the errors.
 */
export type TSearchLabelsUnion = TLabelErrorOperationNotAllowedOutput | TLabelsResultOutput;

/**
 * Result of query SearchMyAssetsProjectUnion.
 * Of type SearchOutput in case of success, otherwise one of the errors.
 */
export type TSearchMyAssetsProjectUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorTooManyRequestsOutput | TSearchOutput;

export type TSearchOutput = {
  assets?: Maybe<TGroupedAssetResultOutput>;
  folders?: Maybe<TFolderResultOutput>;
  projectId?: Maybe<Scalars['ID']>;
};


export type TSearchOutputAssetsArgs = {
  filter?: InputMaybe<TFilterAssetInput>;
  orderBy?: InputMaybe<TAssetOrderEnum>;
  paging?: InputMaybe<TAssetPageInput>;
};


export type TSearchOutputFoldersArgs = {
  filter?: InputMaybe<TFilterFolderInput>;
  orderBy?: InputMaybe<TFolderOrderEnum>;
  paging?: InputMaybe<TFolderPageInput>;
};

/**
 * Result of query searchProject.
 * Of type SearchOutput in case of success, otherwise one of the errors.
 */
export type TSearchProjectUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedOutput | TProjectErrorTooManyRequestsOutput | TSearchOutput;

export type TSelectedSubscriptionTierOutput = {
  directUploads: Scalars['Boolean'];
  id: Scalars['ID'];
  limits: Array<TSubscriptionLimitOutput>;
  name: Scalars['String'];
  price?: Maybe<TSubscriptionPriceOutput>;
};

export type TSelectionInput = {
  bbox?: InputMaybe<Array<Scalars['Float']>>;
  dataType: TDataTypeEnum;
  polygonDefType: TPolygonType;
  polygonPath?: InputMaybe<Scalars['String']>;
  productIdentifier: Scalars['String'];
};

/** Something went wrong on the server. */
export type TServerError = TError & {
  message: Scalars['String'];
};

export type TSetActivePaymentCardInput = {
  externalPaymentCardId: Scalars['String'];
};

export type TSetActivePaymentCardOutput = {
  active: Scalars['Boolean'];
  externalPaymentCardId: Scalars['String'];
};

export type TSetupPaymentCardCreationOutput = {
  clientSecret: Scalars['String'];
  externalPaymentCardIntentId: Scalars['String'];
  publicKey: Scalars['String'];
};

export type TSharedGroupedAssetOutput = {
  auth: Scalars['String'];
  id: Scalars['ID'];
};

export type TSharedGroupedAssetUnion = TAcsErrorEntityNotFoundOutput | TAcsErrorOperationNotPossibleOutput | TSharedGroupedAssetOutput;

export type TSharingCodeOutput = {
  sharingCodeId: Scalars['ID'];
};

export type TSharingCodeUnion = TAcsErrorEntityNotFoundOutput | TAcsErrorOperationNotAllowedOutput | TAcsErrorOperationNotPossibleOutput | TSharingCodeOutput;

/** The backend to calculate the quotation failed. */
export type TShopErrorBackendFailedOutput = TBaseShopErrorInterface & {
  message: Scalars['String'];
};

/** There is no overlap between the area of interest and the area the product covers. */
export type TShopErrorNoOverlapWithProductOutput = TBaseShopErrorInterface & {
  message: Scalars['String'];
};

/** The user is not allowed to perform this operation. */
export type TShopErrorOperationNotAllowedOutput = TBaseShopErrorInterface & {
  message: Scalars['String'];
};

/** The product is not found. */
export type TShopErrorProductNotFoundOutput = TBaseShopErrorInterface & {
  message: Scalars['String'];
};

/** The pricing of the product is not configured correctly. */
export type TShopErrorProductPricingNotFoundOutput = TBaseShopErrorInterface & {
  message: Scalars['String'];
};

export type TShopSupportedCountryValidationOutput = {
  supported: Scalars['Boolean'];
};

export type TSignUpUserAccountAddressInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  postCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
  streetNumber?: InputMaybe<Scalars['String']>;
};

export type TSignUpUserAccountProfileInput = {
  accountAddress: TSignUpUserAccountAddressInput;
  accountPhone: Scalars['String'];
  companyName: Scalars['String'];
  referrerId?: InputMaybe<Scalars['ID']>;
  vatNumber?: InputMaybe<Scalars['String']>;
};

export type TSignupFromInviteInput = {
  accountRole: TAccountRoleEnum;
  accountUUID: Scalars['ID'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  signature: Scalars['String'];
  userFlags?: InputMaybe<Array<TUserFlagSignupInput>>;
  userSettings?: InputMaybe<TUserSettingsSignupInput>;
};

/**
 * Result of nutation signupFromInvite.
 * Of type SignupOutput in case of success, otherwise one of the errors.
 */
export type TSignupFromInviteUnion = TAcsErrorInvalidInvitationSignatureOutput | TAcsErrorSeatLimitReachedOutput | TAcsErrorTooManyRequestsOutput | TAcsErrorUserAlreadyExistsOutput | TSignupOutput;

export type TSignupInput = {
  accountInfo: TSignUpUserAccountProfileInput;
  email: Scalars['String'];
  firstName: Scalars['String'];
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  signature: Scalars['String'];
  userFlags?: InputMaybe<Array<TUserFlagSignupInput>>;
  userSettings?: InputMaybe<TUserSettingsSignupInput>;
};

export type TSignupOutput = {
  user?: Maybe<TUserProfileOutput>;
};

/**
 * Result of mutation signup.
 * Of type SignupOutput in case of success, otherwise one of the errors.
 */
export type TSignupUnion = TAcsErrorInvalidVoucherOutput | TAcsErrorTooManyRequestsOutput | TAcsErrorUserAlreadyExistsOutput | TAcsErrorVoucherAlreadyUsedOutput | TSignupOutput;

export type TSimpleAccountInfoOutput = {
  company?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  logo?: Maybe<Scalars['String']>;
  tenantId?: Maybe<Scalars['ID']>;
};

export type TSimpleResultOutput = {
  result: Scalars['Boolean'];
};

export type TSimpleResultUnion = TAcsErrorEntityNotFoundOutput | TAcsErrorOperationNotAllowedOutput | TAcsErrorOperationNotPossibleOutput | TSimpleResultOutput;

export type TSimpleUserProfileOutput = {
  accountRole?: Maybe<TAccountRoleEnum>;
  applicationRoles: Array<Scalars['String']>;
  companyInfo?: Maybe<TSimpleAccountInfoOutput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  signedUpAt: Scalars['DateTime'];
  tenantRole?: Maybe<TTenantRoleEnum>;
  userFlags: Array<Maybe<TUserFlagOutput>>;
  userSettings: TUserSettingsOutput;
};

/**
 * Result of query for a member of a project.
 * Of type SimpleUserProfileOutput in case of success, otherwise one of the errors.
 */
export type TSimpleUserProfileUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedForTargetUserOutput | TProjectErrorResourceAccessDeniedOutput | TSimpleUserProfileOutput;

/** The StreamingUsage Output */
export type TStreamingUsageOutput = {
  /** BaseLayer */
  baseLayer?: Maybe<Scalars['BigInteger']>;
  /** BaseLayerStreamer */
  baseLayerStreamer?: Maybe<Scalars['BigInteger']>;
  /** Downloads */
  downloads?: Maybe<Scalars['BigInteger']>;
  /** EndDate */
  endDate: Scalars['DateTime'];
  /** ExternalServices */
  externalServices?: Maybe<Scalars['BigInteger']>;
  /** Files */
  files?: Maybe<Scalars['BigInteger']>;
  /** graphQl */
  graphQl?: Maybe<Scalars['BigInteger']>;
  /** StartDate */
  startDate: Scalars['DateTime'];
};

export type TSubProcessGql = {
  duration: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  lastState: Scalars['String'];
  name: Scalars['String'];
  numberOfRetries: Scalars['Int'];
  start: Scalars['DateTime'];
  state: TJobStatus;
  tasks: Array<TTaskGql>;
};

export type TSubscription = {
  _noop?: Maybe<Scalars['Boolean']>;
  commentAdded: TCommentPayload;
  commentDeleted: TCommentPayload;
  commentUpdated: TCommentPayload;
  countdown: Scalars['Int'];
  /**
   * Received when any project has changed the current user has access to with
   * information regarding what changed on the project.
   */
  projectChanged: TProjectChangePayload;
};


export type TSubscriptionCommentAddedArgs = {
  onAnchor: TCommentAnchorInput;
};


export type TSubscriptionCommentDeletedArgs = {
  onAnchor: TCommentAnchorInput;
};


export type TSubscriptionCommentUpdatedArgs = {
  onAnchor: TCommentAnchorInput;
};


export type TSubscriptionCountdownArgs = {
  from: Scalars['Int'];
};

export type TSubscriptionAddonOutput = {
  addonOptionName: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: TSubscriptionPriceOutput;
  type: TSubscriptionAddonTypeEnum;
  value: Scalars['BigInteger'];
};

export enum TSubscriptionAddonTypeEnum {
  DOWNLOAD = 'DOWNLOAD',
  STORAGE = 'STORAGE'
}

/** Account is not synced with Payment System. Account needs to be updated. */
export type TSubscriptionErrorAccountNotSyncedOutput = TBaseSubscriptionErrorInterface & {
  message: Scalars['String'];
};

/** The input validation failed. */
export type TSubscriptionErrorBadRequestOutput = TBaseSubscriptionErrorInterface & {
  message: Scalars['String'];
};

/** A subscription with the same addons already exists. */
export type TSubscriptionErrorDuplicateWithSameAddonOutput = TBaseSubscriptionErrorInterface & {
  message: Scalars['String'];
};

/** The requested feature is not available. */
export type TSubscriptionErrorFeatureNotAvailableOutput = TBaseSubscriptionErrorInterface & {
  message: Scalars['String'];
};

/** Access to the requested action denied. You do not have the required rights or your authentication token is incorrect. */
export type TSubscriptionErrorOperationNotAllowedOutput = TBaseSubscriptionErrorInterface & {
  message: Scalars['String'];
};

export type TSubscriptionInvoiceOutput = {
  billingPeriodEndDate: Scalars['DateTime'];
  billingPeriodStartDate: Scalars['DateTime'];
  currency: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  invoiceNumber: Scalars['ID'];
  pdfLink: Scalars['String'];
  status: TInvoiceStatusEnum;
  totalAmount: Scalars['BigDecimal'];
};

export type TSubscriptionInvoicesOutput = {
  subscriptionInvoiceOutputs: Array<Maybe<TSubscriptionInvoiceOutput>>;
};

/**
 * Result of query getAllSubscriptionInvoices.
 * Of type SubscriptionInvoicesOutput in case of success, otherwise one of the errors.
 */
export type TSubscriptionInvoicesUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionInvoicesOutput | TSubscriptionNoValidIsoCountryFoundOutput;

export enum TSubscriptionLimitEnum {
  DOWNLOAD = 'DOWNLOAD',
  HAS_EXTERNAL_COLLABORATION = 'HAS_EXTERNAL_COLLABORATION',
  HAS_PUBLIC_SHARING = 'HAS_PUBLIC_SHARING',
  IS_DEVICE_UPLOAD_LIMITED = 'IS_DEVICE_UPLOAD_LIMITED',
  SEATS = 'SEATS',
  STORAGE = 'STORAGE',
  STREAMING = 'STREAMING',
  TOKENS = 'TOKENS'
}

export type TSubscriptionLimitOutput = {
  name: TSubscriptionLimitEnum;
  value: Scalars['BigInteger'];
};

export enum TSubscriptionLimitsChangeTypeEnum {
  CURRENT_LIMITS = 'CURRENT_LIMITS',
  DECREASE_LIMITS = 'DECREASE_LIMITS',
  INCREASE_LIMITS = 'INCREASE_LIMITS',
  MIXED_LIMITS = 'MIXED_LIMITS'
}

/** There is no valid Iso country found for the account. Account settings need to be updated. */
export type TSubscriptionNoValidIsoCountryFoundOutput = TBaseSubscriptionErrorInterface & {
  message: Scalars['String'];
};

export type TSubscriptionOrderItemOutput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  subscriptionTier: TSubscriptionTierOutput;
  subscriptionTierAddon: TSubscriptionTierAddonOutput;
};

export type TSubscriptionOrderOutput = {
  amount: Scalars['BigDecimal'];
  currency: Scalars['String'];
  id: Scalars['ID'];
  orderId: Scalars['String'];
  orderItems: Array<TSubscriptionOrderItemOutput>;
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
};

export type TSubscriptionOrdersOutput = {
  subscriptionOrderOutputs: Array<Maybe<TSubscriptionOrderOutput>>;
};

/**
 * Result of query getAllSubscriptionOrders.
 * Of type SubscriptionOrdersOutput in case of success, otherwise one of the errors.
 */
export type TSubscriptionOrdersUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TSubscriptionOrdersOutput;

export type TSubscriptionOutput = {
  active: Scalars['Boolean'];
  addons: Array<TSubscriptionAddonOutput>;
  billingPeriodEndDate: Scalars['DateTime'];
  billingPeriodStartDate: Scalars['DateTime'];
  id: Scalars['ID'];
  isPendingCancellation: Scalars['Boolean'];
  isPendingDowngrade: Scalars['Boolean'];
  latestPaymentAttempt?: Maybe<Scalars['DateTime']>;
  nextPaymentAttempt?: Maybe<Scalars['DateTime']>;
  paymentAlreadyAttempted: Scalars['Boolean'];
  paymentAttemptCount: Scalars['Int'];
  paymentStatus: TSubscriptionPaymentStatusEnum;
  selectedSeats: Scalars['BigInteger'];
  status: Scalars['String'];
  tier: TSelectedSubscriptionTierOutput;
  totalLimits: Array<TSubscriptionLimitOutput>;
  totalPrice: TSubscriptionTotalPriceOutput;
};

export enum TSubscriptionPaymentStatusEnum {
  FAILED = 'FAILED',
  PAID = 'PAID',
  PENDING = 'PENDING',
  RETRYING = 'RETRYING'
}

/** Based on Stripe `Price` object https://stripe.com/docs/api/prices */
export type TSubscriptionPriceOutput = {
  amountPerUnit: Scalars['BigDecimal'];
  billingInterval: TBillingIntervalEnum;
  currency: Scalars['String'];
  id: Scalars['ID'];
  subTotal: Scalars['BigDecimal'];
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
};

export enum TSubscriptionProviderTypeEnum {
  LICENSE = 'LICENSE',
  NONE = 'NONE',
  STRIPE = 'STRIPE',
  UNLIMITED = 'UNLIMITED'
}

export type TSubscriptionQuotationAddonInput = {
  id: Scalars['ID'];
  selectedOptionId: Scalars['ID'];
};

export type TSubscriptionQuotationAddonOutput = {
  addonOptionName: Scalars['String'];
  billingInterval: TBillingIntervalEnum;
  currency: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  subTotal: Scalars['BigDecimal'];
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
  type: TSubscriptionAddonTypeEnum;
  value: Scalars['BigInteger'];
};

export type TSubscriptionQuotationInput = {
  addonPriceIds: Array<InputMaybe<Scalars['String']>>;
  billingInterval: TBillingIntervalEnum;
  seats: Scalars['Int'];
  tierPriceId: Scalars['ID'];
};

export type TSubscriptionQuotationOutput = {
  addons: Array<TSubscriptionQuotationAddonOutput>;
  billingPeriodEndDate: Scalars['DateTime'];
  billingPeriodStartDate: Scalars['DateTime'];
  billingPeriodTotal: Scalars['BigDecimal'];
  currency: Scalars['String'];
  exceededLimits: Array<TExceededLimitOutput>;
  immediatePaymentTotal: Scalars['BigDecimal'];
  limitsChangeType: TSubscriptionLimitsChangeTypeEnum;
  nextPaymentTotal: Scalars['BigDecimal'];
  prorationDebit: Scalars['BigDecimal'];
  prorationRebate: Scalars['BigDecimal'];
  subTotal: Scalars['BigDecimal'];
  subscriptionPaidUntil: Scalars['DateTime'];
  tax: Scalars['BigDecimal'];
  tier: TSubscriptionQuotationTierOutput;
  tierChangeType: TSubscriptionTierChangeTypeEnum;
  totalLimits: Array<TSubscriptionLimitOutput>;
};

export type TSubscriptionQuotationTierOutput = {
  currency: Scalars['String'];
  directUploads: Scalars['Boolean'];
  id: Scalars['ID'];
  limits: Array<TSubscriptionLimitOutput>;
  name: Scalars['String'];
  pricePerSeat: Scalars['BigDecimal'];
  selectedSeats: Scalars['Int'];
  subTotal: Scalars['BigDecimal'];
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
};

/**
 * Result of query getSubscriptionQuotation.
 * Of type SubscriptionQuotationOutput in case of success, otherwise one of the errors.
 */
export type TSubscriptionQuotationUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TSubscriptionQuotationOutput;

export type TSubscriptionTierAddonOptionOutput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  prices: Array<Maybe<TSubscriptionPriceOutput>>;
  value: Scalars['BigInteger'];
};

export type TSubscriptionTierAddonOutput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  options: Array<TSubscriptionTierAddonOptionOutput>;
  type: TSubscriptionAddonTypeEnum;
};

export enum TSubscriptionTierChangeTypeEnum {
  CURRENT_TIER = 'CURRENT_TIER',
  DOWNGRADE_TIER = 'DOWNGRADE_TIER',
  UPGRADE_TIER = 'UPGRADE_TIER'
}

/** Lists limits, price and available addons */
export type TSubscriptionTierOutput = {
  availableAddons: Array<TSubscriptionTierAddonOutput>;
  directUploads: Scalars['Boolean'];
  id: Scalars['ID'];
  limits?: Maybe<Array<Maybe<TSubscriptionLimitOutput>>>;
  name: Scalars['String'];
  prices?: Maybe<Array<Maybe<TSubscriptionPriceOutput>>>;
};

/** Lists limits, price and available addons */
export type TSubscriptionTiersOutput = {
  subscriptionTierOutputs: Array<Maybe<TSubscriptionTierOutput>>;
};

/**
 * Result of query getSubscriptionTiers.
 * Of type SubscriptionTiersOutput in case of success, otherwise one of the errors.
 */
export type TSubscriptionTiersUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TSubscriptionTiersOutput;

export type TSubscriptionTotalPriceOutput = {
  billingInterval: TBillingIntervalEnum;
  currency: Scalars['String'];
  subTotal: Scalars['BigDecimal'];
  tax: Scalars['BigDecimal'];
  total: Scalars['BigDecimal'];
};

/**
 * Result of mutation buySubscription.
 * Of type SubscriptionOutput in case of success, otherwise one of the errors.
 */
export type TSubscriptionUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorDuplicateWithSameAddonOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TSubscriptionOutput;

export type TSubscriptionsOutput = {
  subscriptionOutputs: Array<Maybe<TSubscriptionOutput>>;
};

/**
 * Result of query getAllSubscriptions.
 * Of type SubscriptionsOutput in case of success, otherwise one of the errors.
 */
export type TSubscriptionsUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TSubscriptionsOutput;

export type TSummary = {
  /** The type of the summary object. */
  type: Scalars['String'];
  /**
   * By default, only ranges with a minimum and a maximum value can
   * be specified. Ranges can be specified for ordinal values only,
   * which means they need to have a rank order. Therefore, ranges
   * can only be specified for numbers and some special types of
   * strings. Examples: grades (A to F), dates or times.
   * Implementors are free to add other derived statistical values
   * to the object, for example 'mean' or 'stddev'.
   */
  value: Scalars['JSON'];
};

export type TSummaryItemGql = {
  state: TJobStatus;
  type: TJobType;
};

export type TSupportedCountryLocalizedNameOutput = {
  languageAlpha2Code?: Maybe<Scalars['String']>;
  languageAlpha3Code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TSupportedCountryOutput = {
  addressesRequireRegion: Scalars['Boolean'];
  alpha3Code: Scalars['String'];
  /** Country iso code */
  countryIsoCode: Scalars['String'];
  countryName: Scalars['String'];
  isEuropeanUnion: Scalars['Boolean'];
  localizedNames: Array<Maybe<TSupportedCountryLocalizedNameOutput>>;
};

export type TTaskGql = {
  duration: Scalars['String'];
  end?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  start: Scalars['DateTime'];
  state: TJobStatus;
};

export type TTenantDeletionOutput = {
  message: Scalars['String'];
  status: TTenantDeletionStatus;
};

export enum TTenantDeletionStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export type TTenantDeletionUnion = TAcsErrorOperationNotAllowedOutput | TTenantDeletionOutput;

/** Description of a specific tenant. */
export type TTenantOutput = {
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<TSimpleUserProfileOutput>;
  defaultSubscriptionProviderType?: Maybe<TSubscriptionProviderTypeEnum>;
  defaultTenant: Scalars['Boolean'];
  id: Scalars['ID'];
  modifiedAt: Scalars['DateTime'];
  name: Scalars['String'];
};

export enum TTenantRoleEnum {
  TENANT_ROLE_ADMIN = 'TENANT_ROLE_ADMIN',
  TENANT_ROLE_USER = 'TENANT_ROLE_USER'
}

/** Result of requesting a specific tenant. */
export type TTenantUnion = TAcsErrorOperationNotAllowedOutput | TAcsErrorOperationNotPossibleOutput | TAcsErrorTenantNotFoundOutput | TTenantOutput;

export type TTenantsOutput = {
  tenants: Array<TTenantOutput>;
};

/** Result of requesting all tenants of the system. */
export type TTenantsUnion = TAcsErrorOperationNotAllowedOutput | TTenantsOutput;

export type TThumbnailOutput = {
  path: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type TTokenWalletOutput = {
  total: Scalars['Int'];
};

export type TTokensRefundInput = {
  accountID: Scalars['ID'];
  description: Scalars['String'];
  numberOfTokens: Scalars['Int'];
};

export type TTokensRefundOutput = {
  transactionID: Scalars['ID'];
};

export enum TTokensTransactionActionTypeEnum {
  AUTO_REGISTRATION = 'AUTO_REGISTRATION',
  EXPORT = 'EXPORT',
  MESHING = 'MESHING',
  REFUND = 'REFUND',
  UNDEFINED = 'UNDEFINED'
}

export type TTokensTransactionOutput = {
  amount: Scalars['Int'];
  balanceAfter: Scalars['Int'];
  description: Scalars['String'];
  transactionDate: Scalars['DateTime'];
  transactionType: TTokensTransactionActionTypeEnum;
};

export type TTokensTransactionsOutput = {
  tokensTransactions: Array<TTokensTransactionOutput>;
};

export type TTokensTransactionsPageInput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type TTokensTransactionsUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TTokensTransactionsOutput;

export type TTour = {
  /** Can edit */
  canEdit: Scalars['Boolean'];
  /** Created at */
  createdAt: Scalars['DateTime'];
  /** Created by */
  createdBy: TSimpleUserProfileOutput;
  /** Description */
  description?: Maybe<Scalars['String']>;
  /**
   * Duration (in ms)
   * Retrieved from the keyframe with the highest `durationFromTourStart`
   */
  duration: Scalars['Float'];
  /** ID */
  id: Scalars['ID'];
  /** Modified at */
  modifiedAt: Scalars['DateTime'];
  /** Name */
  name: Scalars['String'];
  /** Path tension */
  tension: Scalars['Float'];
  /** Thumbnail path */
  thumbnailPath?: Maybe<Scalars['String']>;
};

export type TTourAppliedPageOutput = {
  /** Page number */
  pageNumber: Scalars['Int'];
  /** Page size */
  pageSize: Scalars['Int'];
};

export type TTourCameraViewpointCreateTourInput = {
  /** Eye position */
  eyePosition: TVector3Input;
  /** Forward */
  forward: TVector3Input;
  /** Up */
  up: TVector3Input;
};

export type TTourCameraViewpointInput = {
  /** Eye position */
  eyePosition: TVector3Input;
  /** Forward */
  forward: TVector3Input;
  /** Up */
  up: TVector3Input;
};

export type TTourCameraViewpointOutput = {
  /** Eye position */
  eyePosition: TVector3Output;
  /** Forward vector */
  forward: TVector3Output;
  /** Up vector */
  up: TVector3Output;
};

export type TTourCameraViewpointUpdateInput = {
  /** Eye position */
  eyePosition: TVector3Input;
  /** Forward */
  forward: TVector3Input;
  /** Up */
  up: TVector3Input;
};

export type TTourFilterAppliedOutput = {
  /** By name */
  byName?: Maybe<Scalars['String']>;
};

export type TTourFilterInput = {
  /** By name */
  byName?: InputMaybe<Scalars['String']>;
};

export type TTourInput = {
  filter?: InputMaybe<TTourFilterInput>;
  orderBy?: InputMaybe<TTourOrderEnum>;
  paging?: InputMaybe<TTourPageInput>;
};

export type TTourKeyframe = {
  /** Camera viewpoint */
  cameraViewpoint: TTourCameraViewpointOutput;
  /** Duration from tour start */
  durationFromTourStart: Scalars['BigInteger'];
  /** ID */
  id: Scalars['ID'];
  /** Path tension */
  tension: Scalars['Float'];
};

export enum TTourOrderEnum {
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  MODIFIED_AT_ASC = 'MODIFIED_AT_ASC',
  MODIFIED_AT_DESC = 'MODIFIED_AT_DESC',
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC'
}

export type TTourPageInput = {
  /** Page number */
  pageNumber: Scalars['Int'];
  /** Page size */
  pageSize: Scalars['Int'];
};

export type TTriggerInput = {
  groupedAssetId: Scalars['ID'];
  outputFormat?: InputMaybe<TOutputFormat>;
  processingPipelineName?: InputMaybe<TProcessingPipelineName>;
};

export type TTriggerMeshingInput = {
  autoCleaning?: InputMaybe<Scalars['Boolean']>;
  groupedAssetId: Scalars['ID'];
  scanningDeviceType?: InputMaybe<TScanningDeviceType>;
  vegetation?: InputMaybe<Scalars['Boolean']>;
};

export type TTriggerProcessingOutput = {
  success: Scalars['Boolean'];
};

/**
 * Result of mutation triggerProcessing.
 * Of type TriggerProcessingOutput in case of success, otherwise one of the errors.
 */
export type TTriggerProcessingUnion = TProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput | TProcessingErrorInternalServerErrorOutput | TProcessingErrorInvalidInputForProcessingPaymentErrorOutput | TProcessingErrorOperationNotAllowedOutput | TProcessingErrorProcessingLimitReachedOutput | TProcessingErrorResourceAccessDeniedOutput | TProcessingErrorResourceNotFoundOutput | TProcessingErrorStorageLimitReachedOutput | TTriggerProcessingOutput;

/** User is not authenticated */
export type TUnauthenticatedError = TError & {
  message: Scalars['String'];
};

export type TUnsharedGroupedAssetOutput = {
  result: Scalars['Boolean'];
};

export type TUnsharedGroupedAssetUnion = TAcsErrorEntityNotFoundOutput | TAcsErrorOperationNotAllowedOutput | TAcsErrorOperationNotPossibleOutput | TUnsharedGroupedAssetOutput;

export type TUpdateAppClientInput = {
  callbackUrls?: InputMaybe<Array<Scalars['String']>>;
  logoutUrls?: InputMaybe<Array<Scalars['String']>>;
  oAuthFlows?: InputMaybe<Array<TOAuthFlowTypeEnum>>;
};

export type TUpdateApplicationTemplateInput = {
  description?: InputMaybe<Scalars['String']>;
  developmentAppClient?: InputMaybe<TUpdateAppClientInput>;
  developmentHereMapsKey?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  productionAppClient?: InputMaybe<TUpdateAppClientInput>;
  productionHereMapsKey?: InputMaybe<Scalars['String']>;
};

/**
 * Result from mutation updateApplicationTemplate.
 * Of type ApplicationTemplateOutput in case of success, otherwise one of the errors.
 */
export type TUpdateApplicationTemplateUnion = TApplicationErrorInvalidArgumentOutput | TApplicationErrorOperationNotAllowedOutput | TApplicationErrorResourceAccessDeniedOutput | TApplicationErrorTooManyRequestsOutput | TApplicationTemplateOutput;

export type TUpdateAssetInput = {
  assetType?: InputMaybe<TAssetTypeEnum>;
  bbox?: InputMaybe<TAssetBBoxInput>;
  description?: InputMaybe<Scalars['String']>;
  groupedAssetId: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation updateAsset.
 * Of type GroupedAssetOutput in case of success, otherwise one of the errors.
 */
export type TUpdateAssetUnion = TAssetErrorDuplicateNameOutput | TAssetErrorNameBlacklistedCharactersOutput | TAssetErrorNameBlankOutput | TAssetErrorNameForbiddenOutput | TAssetErrorNameForwardSlashOutput | TAssetErrorNameLeadingOrTrailingSpaceOutput | TAssetErrorNameTrailingDotOutput | TAssetErrorNameTwoDotsInRowOutput | TAssetErrorOperationNotAllowedOutput | TAssetErrorResourceAccessDeniedOutput | TAssetErrorTooManyRequestsOutput | TGroupedAssetOutput;

export type TUpdateCommentInput = {
  content: Scalars['String'];
  id: Scalars['ID'];
};

export type TUpdateDeviceInput = {
  deviceSN: Scalars['ID'];
  name: Scalars['String'];
};

export type TUpdateFolderInput = {
  description?: InputMaybe<Scalars['String']>;
  folderId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  projectId: Scalars['ID'];
};

/**
 * Result from mutation updateFolder.
 * Of type FolderOutput in case of success, otherwise one of the errors.
 */
export type TUpdateFolderUnion = TFolderErrorDuplicateNameOutput | TFolderErrorNameBlacklistedCharactersOutput | TFolderErrorNameBlankOutput | TFolderErrorNameForbiddenOutput | TFolderErrorNameForwardSlashOutput | TFolderErrorNameLeadingOrTrailingSpaceOutput | TFolderErrorNameTrailingDotOutput | TFolderErrorNameTwoDotsInRowOutput | TFolderErrorOperationNotAllowedOutput | TFolderErrorResourceAccessDeniedOutput | TFolderErrorTooManyRequestsOutput | TFolderOutput;

export type TUpdateGeoreferenceInput = {
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: InputMaybe<Scalars['Float']>;
  georeferenceId: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  visible: Array<TGeoreferenceVisibilityInput>;
  yaw: Scalars['Float'];
};

export type TUpdateGroupedAssetGeoreferenceInput = {
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: InputMaybe<Scalars['Float']>;
  georeferenceId: Scalars['ID'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['ID']>;
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  visible: Array<TGroupedAssetGeoreferenceVisibilityInput>;
  yaw: Scalars['Float'];
};

export type TUpdateGroupedAssetTourInput = {
  /** Closed */
  closed?: InputMaybe<Scalars['Boolean']>;
  /** Description */
  description?: InputMaybe<Scalars['String']>;
  /** Duration */
  duration?: InputMaybe<Scalars['Float']>;
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** Name */
  name?: InputMaybe<Scalars['String']>;
  /** Path tension */
  tension: Scalars['Float'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TUpdateGroupedAssetTourKeyframeInput = {
  /** Asset ID */
  groupedAssetId: Scalars['ID'];
  /** Keyframe */
  keyframe: TGroupedAssetTourKeyframeUpdateInput;
  /** Keyframe ID */
  keyframeId: Scalars['ID'];
  /** Tour ID */
  tourId: Scalars['ID'];
};

export type TUpdateLabelAnnotationInput = {
  annotationId: Scalars['ID'];
  data?: InputMaybe<TLabelAnnotationDataInput>;
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** The input for updating an existing label annotation. */
export type TUpdateLabelAnnotationInputV2 = {
  /** The ID of the label to update. */
  annotationId: Scalars['ID'];
  /** The new data of the label. When undefined, the data will remain unchanged. */
  data?: InputMaybe<TLabelAnnotationDataInputV2>;
  /** The new description of the label. When undefined, the description will remain unchanged. */
  description?: InputMaybe<Scalars['String']>;
  /** The new title of the label. When undefined, the title will remain unchanged. */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation updateLabelAnnotationV2.
 * Of type LabelAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TUpdateLabelAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TLabelAnnotationOutput;

export type TUpdateLimitingBoxAnnotationInput = {
  annotationId: Scalars['ID'];
  data?: InputMaybe<TLimitingBoxAnnotationDataInput>;
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** The input for updating an existing limiting box annotation. */
export type TUpdateLimitingBoxAnnotationInputV2 = {
  /** The ID of the limiting box to update. */
  annotationId: Scalars['ID'];
  /** The new data of the limiting box. When undefined, the data will remain unchanged. */
  data?: InputMaybe<TLimitingBoxAnnotationDataInputV2>;
  /** The new description of the limiting box. When undefined, the description will remain unchanged. */
  description?: InputMaybe<Scalars['String']>;
  /** The new title of the limiting box. When undefined, the title will remain unchanged. */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation updateLimitingBoxAnnotationV2.
 * Of type LimitingBoxAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TUpdateLimitingBoxAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TLimitingBoxAnnotationOutput;

export type TUpdateMeasurementAnnotationInput = {
  annotationId: Scalars['ID'];
  data?: InputMaybe<TMeasurementAnnotationDataInput>;
  description?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** The input for updating an existing measurement annotation. */
export type TUpdateMeasurementAnnotationInputV2 = {
  /** The ID of the measurement to update. */
  annotationId: Scalars['ID'];
  /** The new data of the measurement. When undefined, the data will remain unchanged. */
  data?: InputMaybe<TMeasurementAnnotationDataInputV2>;
  /** The new description of the measurement. When undefined, the description will remain unchanged. */
  description?: InputMaybe<Scalars['String']>;
  /** The new title of the measurement. When undefined, the title will remain unchanged. */
  title?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation updateMeasurementAnnotationV2.
 * Of type MeasurementAnnotationOutput in case of success, otherwise one of the errors.
 */
export type TUpdateMeasurementAnnotationUnion = TAnnotationErrorOperationNotAllowedOutput | TMeasurementAnnotationOutput;

export type TUpdateProjectInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
};

/**
 * Result of mutation updateProject.
 * Of type ProjectOutput in case of success, otherwise one of the errors.
 *
 * ProjectErrorDuplicateNameOutput is not longer used
 */
export type TUpdateProjectUnion = TProjectErrorDuplicateNameOutput | TProjectErrorInvalidInputOutput | TProjectErrorNameBlacklistedCharactersOutput | TProjectErrorNameBlankOutput | TProjectErrorNameForbiddenOutput | TProjectErrorNameForwardSlashOutput | TProjectErrorNameLeadingOrTrailingSpaceOutput | TProjectErrorNameTrailingDotOutput | TProjectErrorNameTwoDotsInRowOutput | TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedOutput | TProjectErrorTooManyRequestsOutput | TProjectOutput;

export type TUpdateTenantInput = {
  defaultSubscriptionProviderType?: InputMaybe<TSubscriptionProviderTypeEnum>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export enum TUploadStatusEnum {
  UPLOAD_ALL_PARTS_CONFIRMED = 'UPLOAD_ALL_PARTS_CONFIRMED',
  UPLOAD_COMPLETE = 'UPLOAD_COMPLETE',
  UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS',
  UPLOAD_NOT_STARTED = 'UPLOAD_NOT_STARTED',
  UPLOAD_STALLED = 'UPLOAD_STALLED'
}

/**
 * Result of mutation uploadThumbnailToProject.
 * Of type ProjectThumbnailOutput in case of success, otherwise one of the errors.
 */
export type TUploadThumbnailToProjectUnion = TProjectErrorOperationNotAllowedOutput | TProjectErrorResourceAccessDeniedOutput | TProjectErrorTooManyRequestsOutput | TProjectThumbnailOutput;

export type TUploadedPartsInput = {
  fileId: Scalars['ID'];
};

export type TUrlInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  boundsEndpoint: Scalars['String'];
  endpoint: Scalars['String'];
  label: Scalars['String'];
  viewingMode: TAddressViewingModeEnum;
};

/**
 * Result of query getAccountUsage.
 * Of type UsageOutput in case of success, otherwise one of the errors.
 */
export type TUsageAccountUnion = TSubscriptionErrorOperationNotAllowedOutput | TUsageOutput;

export type TUsageInput = {
  accountID?: InputMaybe<Scalars['ID']>;
};

/**
 * The usage Account Output federated
 * @deprecated
 * reason: "Use AccountUsageOutput instead"
 */
export type TUsageOutput = {
  /** Account UUID */
  accountID: Scalars['ID'];
  /** Processing Usage Outputs */
  processing: Array<Maybe<TProcessingUsageOutput>>;
  /** Number of Projects */
  projects: Scalars['Int'];
  /** Seats */
  seats: Scalars['Int'];
  /** Storage Artifacts */
  storageArtifacts: Scalars['BigInteger'];
  /** Storage Asset */
  storageAssets: Scalars['BigInteger'];
  /** Streaming Usage Output */
  streaming: TStreamingUsageOutput;
};


/**
 * The usage Account Output federated
 * @deprecated
 * reason: "Use AccountUsageOutput instead"
 */
export type TUsageOutputProcessingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


/**
 * The usage Account Output federated
 * @deprecated
 * reason: "Use AccountUsageOutput instead"
 */
export type TUsageOutputStreamingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type TUsageTenantInput = {
  tenantID?: InputMaybe<Scalars['ID']>;
};

/** The usage Tenant Output federated */
export type TUsageTenantOutput = {
  /** Processing Usage Outputs */
  processing: Array<Maybe<TProcessingUsageOutput>>;
  /** Number of Projects */
  projects: Scalars['Int'];
  /** Seats */
  seats: Scalars['Int'];
  /** Storage Artifacts */
  storageArtifacts: Scalars['BigInteger'];
  /** Storage Asset */
  storageAssets: Scalars['BigInteger'];
  /** Streaming Usage Output */
  streaming: TStreamingUsageOutput;
  /** Tenant UUID */
  tenantID: Scalars['ID'];
};


/** The usage Tenant Output federated */
export type TUsageTenantOutputProcessingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


/** The usage Tenant Output federated */
export type TUsageTenantOutputStreamingArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

/**
 * Result of query tenantUsage.
 * Of type UsageOutput in case of success, otherwise one of the errors.
 */
export type TUsageTenantUnion = TSubscriptionErrorOperationNotAllowedOutput | TUsageTenantOutput;

export type TUserDeletionOutput = {
  message: Scalars['String'];
  status: TUserDeletionStatusGql;
  user: TUserProfileOutput;
};

export enum TUserDeletionStatusGql {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS'
}

export type TUserDeletionUnion = TAcsErrorOperationNotAllowedOutput | TAcsErrorUserNotFoundOutput | TUserDeletionOutput;

export enum TUserFlagEnum {
  LATEST_TOS_CONSENT = 'LATEST_TOS_CONSENT',
  MARKETING_CONSENT = 'MARKETING_CONSENT'
}

export type TUserFlagInput = {
  flag?: InputMaybe<TUserFlagEnum>;
  valid?: InputMaybe<Scalars['Boolean']>;
};

export type TUserFlagOutput = {
  userFlag?: Maybe<TUserFlagEnum>;
  valid?: Maybe<Scalars['Boolean']>;
};

export type TUserFlagSignupInput = {
  userFlag: TUserFlagEnum;
  valid: Scalars['Boolean'];
};

export type TUserInput = {
  userId: Scalars['ID'];
};

export type TUserProfileAppliedPageOutput = {
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export enum TUserProfileOrderEnum {
  LAST_NAME_ASC = 'LAST_NAME_ASC',
  LAST_NAME_DESC = 'LAST_NAME_DESC'
}

export type TUserProfileOutput = {
  accountRole?: Maybe<TAccountRoleEnum>;
  applicationRoles: Array<Scalars['String']>;
  companyInfo?: Maybe<TAccountInfoOutput>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: Maybe<Scalars['String']>;
  lastLoginAt?: Maybe<Scalars['DateTime']>;
  lastName?: Maybe<Scalars['String']>;
  modifiedAt: Scalars['DateTime'];
  profilePictureUrl?: Maybe<Scalars['String']>;
  signedUpAt: Scalars['DateTime'];
  tenantRole?: Maybe<TTenantRoleEnum>;
  userFlags: Array<Maybe<TUserFlagOutput>>;
  userSettings: TUserSettingsOutput;
};

export type TUserProfilePageInput = {
  pageNumber: Scalars['Int'];
  pageOffset?: InputMaybe<Scalars['Int']>;
  pageSize: Scalars['Int'];
};

export type TUserProfileResultOutput = {
  appliedFilters?: Maybe<TFilterUserProfileAppliedOutput>;
  appliedPagination?: Maybe<TUserProfileAppliedPageOutput>;
  contents: Array<Maybe<TUserProfileOutput>>;
  orderBy?: Maybe<TUserProfileOrderEnum>;
  pageNumber: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type TUserProjectRoleOutput = {
  userId: Scalars['ID'];
  userRole: TProjectRoleEnum;
};

export type TUserProjectRoleUnion = TAcsErrorOperationNotAllowedOutput | TAcsErrorProjectNotFoundOutput | TListOfUserProjectRoleOutput;

export type TUserSettingsInput = {
  language?: InputMaybe<TUserSettingsLanguageEnum>;
  listLayout?: InputMaybe<TUserSettingsListLayoutEnum>;
  measurementUnit?: InputMaybe<TUserSettingsMeasurementEnum>;
};

export enum TUserSettingsLanguageEnum {
  ENGLISH = 'ENGLISH'
}

export enum TUserSettingsListLayoutEnum {
  LIST = 'LIST',
  TILE = 'TILE'
}

export enum TUserSettingsMeasurementEnum {
  IMPERIAL = 'IMPERIAL',
  METRIC = 'METRIC'
}

export type TUserSettingsOutput = {
  language?: Maybe<TUserSettingsLanguageEnum>;
  listLayout?: Maybe<TUserSettingsListLayoutEnum>;
  measurementUnit?: Maybe<TUserSettingsMeasurementEnum>;
};

export type TUserSettingsSignupInput = {
  language?: InputMaybe<TUserSettingsLanguageEnum>;
  listLayout?: InputMaybe<TUserSettingsListLayoutEnum>;
  measurementUnit?: InputMaybe<TUserSettingsMeasurementEnum>;
};

export type TUserUpdateInput = {
  firstName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

/** The provided input is not valid */
export type TValidationError = TError & {
  issues: Array<TValidationErrorIssue>;
  message: Scalars['String'];
};

/** A subset of the ValidationError type describing every issue in the input */
export type TValidationErrorIssue = {
  code: TValidationErrorIssueCode;
  message: Scalars['String'];
  path: Scalars['String'];
};

/**
 * Validation error code
 * @see https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md
 */
export enum TValidationErrorIssueCode {
  CUSTOM = 'custom',
  INVALID_ARGUMENTS = 'invalid_arguments',
  INVALID_DATE = 'invalid_date',
  INVALID_ENUM_VALUE = 'invalid_enum_value',
  INVALID_INTERSECTION_TYPE = 'invalid_intersection_type',
  INVALID_LITERAL = 'invalid_literal',
  INVALID_RETURN_TYPE = 'invalid_return_type',
  INVALID_STRING = 'invalid_string',
  INVALID_TYPE = 'invalid_type',
  INVALID_UNION = 'invalid_union',
  INVALID_UNION_DESCRIMINATOR = 'invalid_union_descriminator',
  NOT_FINITE = 'not_finite',
  NOT_MULTIPLE_OF = 'not_multiple_of',
  TOO_BIG = 'too_big',
  TOO_SMALL = 'too_small',
  UNRECOGNIZED_KEYS = 'unrecognized_keys'
}

export type TVector3Input = {
  /** x-component */
  x: Scalars['Float'];
  /** y-component */
  y: Scalars['Float'];
  /** z-component */
  z: Scalars['Float'];
};

export type TVector3Output = {
  /** x-component of vector */
  x: Scalars['Float'];
  /** y-component of vector */
  y: Scalars['Float'];
  /** z-component of vector */
  z: Scalars['Float'];
};

export type TVoucherOutput = {
  appliedLimits: Array<Maybe<TLimitToApplyOutput>>;
  expirationDate: Scalars['DateTime'];
  reusable: Scalars['Boolean'];
  signedUrl: Scalars['String'];
  voucherType: TVoucherTypeEnum;
};

export enum TVoucherTypeEnum {
  LIMITS = 'LIMITS',
  SIGNUP = 'SIGNUP'
}

/**
 * Result of nutation generateInvitation.
 * Of type InvitationOutput in case of success, otherwise one of the errors.
 */
export type TVoucherUnion = TAcsErrorOperationNotAllowedOutput | TAcsErrorSeatLimitReachedOutput | TAcsErrorUserAlreadyExistsInAccountOutput | TAcsErrorUserAlreadyExistsInOtherAccountOutput | TVoucherOutput;

export type TWfsInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  label: Scalars['String'];
  reference: Scalars['String'];
  styleString: Scalars['String'];
  versions: Array<Scalars['String']>;
  viewingMode: TAddressViewingModeEnum;
};

export type TWmsInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  bounds: Array<Scalars['Float']>;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  imageFormat: Scalars['String'];
  label: Scalars['String'];
  reference: Scalars['String'];
  versions: Array<Scalars['String']>;
  viewingMode: TAddressViewingModeEnum;
};

export type TWmtsInput = {
  baselayerOrigin: TBaselayerOriginEnum;
  bounds: Array<Scalars['Float']>;
  contentType: TAddressContentTypeEnum;
  datasetId: Scalars['String'];
  endpoint: Scalars['String'];
  imageFormat: Scalars['String'];
  label: Scalars['String'];
  level0Columns: Scalars['Int'];
  level0Rows: Scalars['Int'];
  levelCount: Scalars['Int'];
  reference: Scalars['String'];
  samplingMode: TAddressSamplingModeEnum;
  tileHeight: Scalars['Int'];
  tileMatrices: Array<Scalars['Int']>;
  tileMatrixSet: Scalars['String'];
  tileWidth: Scalars['Int'];
  viewingMode: TAddressViewingModeEnum;
};

export type TWalletRefundUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorBadRequestOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TTokensRefundOutput;

/**
 * Result of query tokenWallet.
 * Of type TokensWalletOutput in case of success, otherwise one of the errors.
 */
export type TWalletUnion = TSubscriptionErrorAccountNotSyncedOutput | TSubscriptionErrorFeatureNotAvailableOutput | TSubscriptionErrorOperationNotAllowedOutput | TSubscriptionNoValidIsoCountryFoundOutput | TTokenWalletOutput;

export type T_Entity = TAccountInfoOutput | TAccountUsageOutput | TAddressDownloadOutput | TAddressHspcOutput | TAddressLtsOutput | TAddressOgc3DOutput | TAddressPageOutput | TAddressPanoramicOutput | TApplicationTemplateOutput | TAssetOutput | TFileOutput | TFolderOutput | TGeoreferenceArtifactResultOutput | TGroupedAssetOutput | TJobPage | TJobSummaryGql | TLabelAnnotationOutput | TLimitingBoxAnnotationOutput | TLuciadRiaLicenseOutput | TMeasurementAnnotationOutput | TOrderGql | TOrderItemGql | TProjectMemberOutput | TProjectOutput | TSearchOutput | TSimpleAccountInfoOutput | TSimpleUserProfileOutput | TUsageOutput | TUsageTenantOutput | TUserProfileOutput;

type TAddress_AddressAssetFileDownloadOutput_Fragment = { id: string, type: TAddressTypeEnum };

type TAddress_AddressDownloadOutput_Fragment = { id: string, type: TAddressTypeEnum };

type TAddress_AddressHspcOutput_Fragment = { id: string, type: TAddressTypeEnum, endpoint: string };

type TAddress_AddressLtsOutput_Fragment = { id: string, type: TAddressTypeEnum };

type TAddress_AddressOgc3DOutput_Fragment = { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number };

type TAddress_AddressPanoramicOutput_Fragment = { id: string, type: TAddressTypeEnum, endpoint: string };

type TAddress_AddressWfsOutput_Fragment = { id: string, type: TAddressTypeEnum };

type TAddress_AddressWmsOutput_Fragment = { id: string, type: TAddressTypeEnum };

type TAddress_AddressWmtsOutput_Fragment = { id: string, type: TAddressTypeEnum };

export type TAddressFragment = TAddress_AddressAssetFileDownloadOutput_Fragment | TAddress_AddressDownloadOutput_Fragment | TAddress_AddressHspcOutput_Fragment | TAddress_AddressLtsOutput_Fragment | TAddress_AddressOgc3DOutput_Fragment | TAddress_AddressPanoramicOutput_Fragment | TAddress_AddressWfsOutput_Fragment | TAddress_AddressWmsOutput_Fragment | TAddress_AddressWmtsOutput_Fragment;

export type TAddressPanoramicOutputFragment = { id: string, endpoint: string };

export type TAddressOgc3DOutputFragment = { id: string, endpoint: string, qualityFactor: number };

export type TAddressHspcOutputFragment = { id: string, endpoint: string };

type TBaseLayer_BaselayerHspcOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string };

type TBaseLayer_BaselayerLtsOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string, endpoint: string, datasetId: string };

type TBaseLayer_BaselayerOgc3DOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string };

type TBaseLayer_BaselayerPanoramicOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string };

type TBaseLayer_BaselayerUrlOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string };

type TBaseLayer_BaselayerWfsOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string };

type TBaseLayer_BaselayerWmsOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string, endpoint: string, datasetId: string, imageFormat: string };

type TBaseLayer_BaselayerWmtsOutput_Fragment = { id: string, type: TAddressTypeEnum, label: string };

export type TBaseLayerFragment = TBaseLayer_BaselayerHspcOutput_Fragment | TBaseLayer_BaselayerLtsOutput_Fragment | TBaseLayer_BaselayerOgc3DOutput_Fragment | TBaseLayer_BaselayerPanoramicOutput_Fragment | TBaseLayer_BaselayerUrlOutput_Fragment | TBaseLayer_BaselayerWfsOutput_Fragment | TBaseLayer_BaselayerWmsOutput_Fragment | TBaseLayer_BaselayerWmtsOutput_Fragment;

export type TBaseLayerLtsFragment = { endpoint: string, datasetId: string };

export type TBaseLayerWmsFragment = { endpoint: string, datasetId: string, imageFormat: string };

export type TCatalogFeatureItemFragment = { id: string, type: TItemType, properties: { title?: string | null, data_type?: string | null, year?: number | null, productCode?: string | null, layerAddress?: { id: string, type: string } | { id: string, type: string, endpoint: string, datasetId: string } | { id: string, type: string, endpoint: string, qualityFactor: number } | { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null } | { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null } | null } };

export type TGeolocationFragment = { id: string, name: string, altitude: number, longitude: number, latitude: number, anchorX: number, anchorY: number, anchorZ: number, pitch: number, yaw: number, roll: number, artifacts: Array<{ id: string, visible: boolean, artifact: { type: TArtifactTypeEnum, addresses: { contents: Array<{ id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum }> } } }> };

type TLayerAddress_LayerAddressHspc_Fragment = { id: string, type: string };

type TLayerAddress_LayerAddressLts_Fragment = { id: string, type: string, endpoint: string, datasetId: string };

type TLayerAddress_LayerAddressOgc3DTiles_Fragment = { id: string, type: string, endpoint: string, qualityFactor: number };

type TLayerAddress_LayerAddressWms_Fragment = { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null };

type TLayerAddress_LayerAddressWmts_Fragment = { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null };

export type TLayerAddressFragment = TLayerAddress_LayerAddressHspc_Fragment | TLayerAddress_LayerAddressLts_Fragment | TLayerAddress_LayerAddressOgc3DTiles_Fragment | TLayerAddress_LayerAddressWms_Fragment | TLayerAddress_LayerAddressWmts_Fragment;

export type TLayerAddressLtsFragment = { id: string, endpoint: string, datasetId: string };

export type TLayerAddressWmsFragment = { id: string, endpoint: string, datasetId: string, imageFormat?: string | null };

export type TLayerAddressWmtsFragment = { id: string, endpoint: string, datasetId: string, imageFormat?: string | null };

export type TLayerAddressOgc3DTilesFragment = { id: string, endpoint: string, qualityFactor: number };

export type TProjectFragment = { name: string, id: string, rootFolder: { id: string, name: string } };

export type TAddFileMutationVariables = Exact<{
  name: Scalars['String'];
  size: Scalars['BigInteger'];
  groupedAssetId: Scalars['ID'];
}>;


export type TAddFileMutation = { addFile?: { id: string } | null };

export type TConfirmAssetMutationVariables = Exact<{
  groupedAssetId: Scalars['ID'];
}>;


export type TConfirmAssetMutation = { completeAssetFileList?: { executed: boolean, message?: string | null } | null };

export type TConfirmFileMutationVariables = Exact<{
  fileId: Scalars['ID'];
  finishedETags: Array<TMultipartUploadETagInput> | TMultipartUploadETagInput;
}>;


export type TConfirmFileMutation = { completeMultipartUpload?: { executed: boolean, message?: string | null } | null };

export type TCreateAssetMutationVariables = Exact<{
  name: Scalars['String'];
  folderId: Scalars['ID'];
}>;


export type TCreateAssetMutation = { createAssetV2?: { __typename: 'AssetErrorDuplicateNameOutput', message: string } | { __typename: 'AssetErrorNameBlacklistedCharactersOutput', message: string } | { __typename: 'AssetErrorNameBlankOutput', message: string } | { __typename: 'AssetErrorNameForbiddenOutput', message: string } | { __typename: 'AssetErrorNameForwardSlashOutput', message: string } | { __typename: 'AssetErrorNameLeadingOrTrailingSpaceOutput', message: string } | { __typename: 'AssetErrorNameTrailingDotOutput', message: string } | { __typename: 'AssetErrorNameTwoDotsInRowOutput', message: string } | { __typename: 'AssetErrorOperationNotAllowedOutput', message: string } | { __typename: 'AssetErrorResourceAccessDeniedOutput', message: string } | { __typename: 'AssetErrorTooManyRequestsOutput', message: string } | { __typename: 'GroupedAssetOutput', id: string } | null };

export type TCreateGeoreferenceMutationVariables = Exact<{
  altitude: Scalars['Float'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  name: Scalars['String'];
  parentId: Scalars['ID'];
  visibilities: Array<TGroupedAssetGeoreferenceVisibilityInput> | TGroupedAssetGeoreferenceVisibilityInput;
}>;


export type TCreateGeoreferenceMutation = { createGroupedAssetGeoreference: { id: string, name: string, altitude: number, longitude: number, latitude: number, anchorX: number, anchorY: number, anchorZ: number, pitch: number, yaw: number, roll: number, artifacts: Array<{ id: string, visible: boolean, artifact: { type: TArtifactTypeEnum, addresses: { contents: Array<{ id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum }> } } }> } };

export type TDeleteGeoreferenceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TDeleteGeoreferenceMutation = { deleteGroupedAssetGeoreference: boolean };

export type TUpdateGeoreferenceMutationVariables = Exact<{
  georeferenceId: Scalars['ID'];
  name: Scalars['String'];
  longitude: Scalars['Float'];
  latitude: Scalars['Float'];
  altitude: Scalars['Float'];
  anchorX: Scalars['Float'];
  anchorY: Scalars['Float'];
  anchorZ: Scalars['Float'];
  flattenEnabled: Scalars['Boolean'];
  flattenScale?: InputMaybe<Scalars['Float']>;
  pitch: Scalars['Float'];
  roll: Scalars['Float'];
  scaleX: Scalars['Float'];
  scaleY: Scalars['Float'];
  scaleZ: Scalars['Float'];
  visible: Array<TGroupedAssetGeoreferenceVisibilityInput> | TGroupedAssetGeoreferenceVisibilityInput;
  yaw: Scalars['Float'];
}>;


export type TUpdateGeoreferenceMutation = { updateGroupedAssetGeoreference: { id: string, name: string, altitude: number, longitude: number, latitude: number, anchorX: number, anchorY: number, anchorZ: number, pitch: number, yaw: number, roll: number, artifacts: Array<{ id: string, visible: boolean, artifact: { type: TArtifactTypeEnum, addresses: { contents: Array<{ id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum }> } } }> } };

export type TGetAllBaseLayersQueryVariables = Exact<{
  pageSize: Scalars['Int'];
  pageNumber: Scalars['Int'];
}>;


export type TGetAllBaseLayersQuery = { getAllBaselayers: { total: number, pageSize: number, contents: Array<{ id: string, type: TAddressTypeEnum, label: string } | { id: string, type: TAddressTypeEnum, label: string, endpoint: string, datasetId: string } | { id: string, type: TAddressTypeEnum, label: string } | { id: string, type: TAddressTypeEnum, label: string } | { id: string, type: TAddressTypeEnum, label: string } | { id: string, type: TAddressTypeEnum, label: string } | { id: string, type: TAddressTypeEnum, label: string, endpoint: string, datasetId: string, imageFormat: string } | { id: string, type: TAddressTypeEnum, label: string }> } };

export type TGetAssetForAddressesQueryVariables = Exact<{
  groupedAssetId: Scalars['ID'];
}>;


export type TGetAssetForAddressesQuery = { asset?: { __typename: 'AssetErrorOperationNotAllowedOutput', message: string } | { __typename: 'AssetErrorResourceAccessDeniedOutput', message: string } | { __typename: 'AssetErrorTooManyRequestsOutput', message: string } | { __typename: 'GroupedAssetOutput', asset: { withEmbeddedGeoreference?: boolean | null, artifacts?: { contents: Array<{ type: TArtifactTypeEnum, addresses: { contents: Array<{ id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum }> } }> } | null } } | null };

export type TGetAssetForGeolocationQueryVariables = Exact<{
  groupedAssetId: Scalars['ID'];
}>;


export type TGetAssetForGeolocationQuery = { asset?: { __typename: 'AssetErrorOperationNotAllowedOutput', message: string } | { __typename: 'AssetErrorResourceAccessDeniedOutput', message: string } | { __typename: 'AssetErrorTooManyRequestsOutput', message: string } | { __typename: 'GroupedAssetOutput', id: string, name: string, thumbnailPath?: string | null, createdAt: string, asset: { withEmbeddedGeoreference?: boolean | null, artifacts?: { contents: Array<{ type: TArtifactTypeEnum, addresses: { contents: Array<{ id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum }> } }> } | null }, groupedAssetGeoreferences?: { contents: Array<{ id: string, name: string, altitude: number, longitude: number, latitude: number, anchorX: number, anchorY: number, anchorZ: number, pitch: number, yaw: number, roll: number, artifacts: Array<{ id: string, visible: boolean, artifact: { type: TArtifactTypeEnum, addresses: { contents: Array<{ id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum, endpoint: string, qualityFactor: number } | { id: string, type: TAddressTypeEnum, endpoint: string } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum } | { id: string, type: TAddressTypeEnum }> } } }> } | null> } | null } | null };

export type TGetAssetForStatusQueryVariables = Exact<{
  groupedAssetId: Scalars['ID'];
}>;


export type TGetAssetForStatusQuery = { asset?: { __typename: 'AssetErrorOperationNotAllowedOutput', message: string } | { __typename: 'AssetErrorResourceAccessDeniedOutput', message: string } | { __typename: 'AssetErrorTooManyRequestsOutput', message: string } | { __typename: 'GroupedAssetOutput', asset: { jobSummary?: { jobs: Array<{ state: TJobStatus, type: TJobType }> } | null, artifacts?: { contents: Array<{ type: TArtifactTypeEnum, addresses: { contents: Array<{ type: TAddressTypeEnum } | { type: TAddressTypeEnum, processingPipelineInfo?: { status?: TProcessingStatus | null, name?: string | null } | null } | { type: TAddressTypeEnum, processingPipelineInfo?: { status?: TProcessingStatus | null, name?: string | null } | null } | { type: TAddressTypeEnum, processingPipelineInfo?: { status?: TProcessingStatus | null, name?: string | null } | null } | { type: TAddressTypeEnum, processingPipelineInfo?: { status?: TProcessingStatus | null, name?: string | null } | null } | { type: TAddressTypeEnum, processingPipelineInfo?: { status?: TProcessingStatus | null, name?: string | null } | null } | { type: TAddressTypeEnum } | { type: TAddressTypeEnum } | { type: TAddressTypeEnum }> } }> } | null } } | null };

export type TGetCatalogFeatureItemsQueryVariables = Exact<{
  collectionIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type TGetCatalogFeatureItemsQuery = { search: { total?: number | null, returned?: number | null, contents: Array<{ id: string, type: TItemType, properties: { title?: string | null, data_type?: string | null, year?: number | null, productCode?: string | null, layerAddress?: { id: string, type: string } | { id: string, type: string, endpoint: string, datasetId: string } | { id: string, type: string, endpoint: string, qualityFactor: number } | { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null } | { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null } | null } } | null> } };

export type TGetCollectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type TGetCollectionsQuery = { collections: Array<{ id: string } | null> };

export type TGetFolderQueryVariables = Exact<{
  id: Scalars['ID'];
  orderFoldersBy: TFolderOrderEnum;
  orderAssetsBy: TAssetOrderEnum;
  foldersPageSize: Scalars['Int'];
  assetsPageSize: Scalars['Int'];
  foldersPageNumber: Scalars['Int'];
  assetsPageNumber: Scalars['Int'];
  foldersPageOffset?: InputMaybe<Scalars['Int']>;
  assetsPageOffset?: InputMaybe<Scalars['Int']>;
}>;


export type TGetFolderQuery = { folder?: { __typename: 'FolderErrorOperationNotAllowedOutput', message: string } | { __typename: 'FolderErrorResourceAccessDeniedOutput', message: string } | { __typename: 'FolderErrorResourceNotFoundOutput', message: string } | { __typename: 'FolderErrorTooManyRequestsOutput', message: string } | { __typename: 'FolderOutput', folders: { total: number, contents: Array<{ id: string, name: string } | null> }, assets: { total: number, contents: Array<{ id: string, name: string }> } } | null };

export type TGetMyAssetsProjectQueryVariables = Exact<{ [key: string]: never; }>;


export type TGetMyAssetsProjectQuery = { myAssetsProject?: { __typename: 'ProjectErrorOperationNotAllowedOutput', message: string } | { __typename: 'ProjectErrorTooManyRequestsOutput', message: string } | { __typename: 'ProjectOutput', name: string, id: string, rootFolder: { id: string, name: string } } | null };

export type TGetProductMetadataQueryVariables = Exact<{
  productCode: Scalars['String'];
}>;


export type TGetProductMetadataQuery = { viewProduct?: { resolution?: string | null } | { resolution?: string | null } | null };

export type TGetProjectsQueryVariables = Exact<{
  orderBy: TProjectOrderEnum;
  pageSize: Scalars['Int'];
  pageNumber: Scalars['Int'];
}>;


export type TGetProjectsQuery = { projects?: { __typename: 'ProjectErrorOperationNotAllowedOutput', message: string } | { __typename: 'ProjectErrorTooManyRequestsOutput', message: string } | { __typename: 'ProjectResultOutput', total: number, contents: Array<{ name: string, id: string, rootFolder: { id: string, name: string } } | null> } | null };

export type TPresignedUrlQueryVariables = Exact<{
  fileId: Scalars['ID'];
  chunkIndex: Scalars['Int'];
}>;


export type TPresignedUrlQuery = { getMultipartUploadURL?: { uploadUrl: string } | null };

export type TSearchCatalogFeatureItemsQueryVariables = Exact<{
  collectionIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  featureItemIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type TSearchCatalogFeatureItemsQuery = { search: { total?: number | null, returned?: number | null, contents: Array<{ id: string, type: TItemType, properties: { title?: string | null, data_type?: string | null, year?: number | null, productCode?: string | null, layerAddress?: { id: string, type: string } | { id: string, type: string, endpoint: string, datasetId: string } | { id: string, type: string, endpoint: string, qualityFactor: number } | { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null } | { id: string, type: string, endpoint: string, datasetId: string, imageFormat?: string | null } | null } } | null> } };

export const BaseLayerLtsFragmentDoc = gql`
    fragment BaseLayerLts on BaselayerLtsOutput {
  endpoint
  datasetId
}
    `;
export const BaseLayerWmsFragmentDoc = gql`
    fragment BaseLayerWms on BaselayerWmsOutput {
  endpoint
  datasetId
  imageFormat
}
    `;
export const BaseLayerFragmentDoc = gql`
    fragment BaseLayer on BaselayerOutput {
  ... on CoreBaselayer {
    id
    type
    label
  }
  ...BaseLayerLts
  ...BaseLayerWms
}
    ${BaseLayerLtsFragmentDoc}
${BaseLayerWmsFragmentDoc}`;
export const LayerAddressLtsFragmentDoc = gql`
    fragment LayerAddressLts on LayerAddressLts {
  id
  endpoint
  datasetId
}
    `;
export const LayerAddressWmsFragmentDoc = gql`
    fragment LayerAddressWms on LayerAddressWms {
  id
  endpoint
  datasetId
  imageFormat
}
    `;
export const LayerAddressWmtsFragmentDoc = gql`
    fragment LayerAddressWmts on LayerAddressWmts {
  id
  endpoint
  datasetId
  imageFormat
}
    `;
export const LayerAddressOgc3DTilesFragmentDoc = gql`
    fragment LayerAddressOgc3DTiles on LayerAddressOgc3DTiles {
  id
  endpoint
  qualityFactor
}
    `;
export const LayerAddressFragmentDoc = gql`
    fragment LayerAddress on LayerAddress {
  ... on CoreLayer {
    id
    type
  }
  ...LayerAddressLts
  ...LayerAddressWms
  ...LayerAddressWmts
  ...LayerAddressOgc3DTiles
}
    ${LayerAddressLtsFragmentDoc}
${LayerAddressWmsFragmentDoc}
${LayerAddressWmtsFragmentDoc}
${LayerAddressOgc3DTilesFragmentDoc}`;
export const CatalogFeatureItemFragmentDoc = gql`
    fragment CatalogFeatureItem on FeatureItem {
  id
  type
  properties {
    title
    data_type
    year
    productCode
    layerAddress {
      ...LayerAddress
    }
  }
}
    ${LayerAddressFragmentDoc}`;
export const AddressPanoramicOutputFragmentDoc = gql`
    fragment AddressPanoramicOutput on AddressPanoramicOutput {
  id
  endpoint
}
    `;
export const AddressOgc3DOutputFragmentDoc = gql`
    fragment AddressOgc3DOutput on AddressOgc3DOutput {
  id
  endpoint
  qualityFactor
}
    `;
export const AddressHspcOutputFragmentDoc = gql`
    fragment AddressHspcOutput on AddressHspcOutput {
  id
  endpoint
}
    `;
export const AddressFragmentDoc = gql`
    fragment Address on AddressOutput {
  ... on Renderable {
    id
    type
  }
  ...AddressPanoramicOutput
  ...AddressOgc3DOutput
  ...AddressHspcOutput
}
    ${AddressPanoramicOutputFragmentDoc}
${AddressOgc3DOutputFragmentDoc}
${AddressHspcOutputFragmentDoc}`;
export const GeolocationFragmentDoc = gql`
    fragment Geolocation on GroupedAssetGeoreferenceOutput {
  id
  name
  altitude
  longitude
  latitude
  anchorX
  anchorY
  anchorZ
  pitch
  yaw
  roll
  artifacts {
    id
    visible
    artifact {
      addresses {
        contents {
          ...Address
        }
      }
      type
    }
  }
}
    ${AddressFragmentDoc}`;
export const ProjectFragmentDoc = gql`
    fragment Project on ProjectOutput {
  name
  id
  rootFolder {
    id
    name
  }
}
    `;
export const AddFileDocument = gql`
    mutation addFile($name: String!, $size: BigInteger!, $groupedAssetId: ID!) {
  addFile(
    params: {fileName: $name, fileSize: $size, groupedAssetId: $groupedAssetId}
  ) {
    id
  }
}
    `;
export type AddFileMutationResult = Apollo.MutationResult<TAddFileMutation>;
export type AddFileMutationOptions = Apollo.BaseMutationOptions<TAddFileMutation, TAddFileMutationVariables>;
export const ConfirmAssetDocument = gql`
    mutation confirmAsset($groupedAssetId: ID!) {
  completeAssetFileList(groupedAssetId: $groupedAssetId) {
    executed
    message
  }
}
    `;
export type ConfirmAssetMutationResult = Apollo.MutationResult<TConfirmAssetMutation>;
export type ConfirmAssetMutationOptions = Apollo.BaseMutationOptions<TConfirmAssetMutation, TConfirmAssetMutationVariables>;
export const ConfirmFileDocument = gql`
    mutation confirmFile($fileId: ID!, $finishedETags: [MultipartUploadETagInput!]!) {
  completeMultipartUpload(
    params: {fileId: $fileId, multipartUploadsETags: $finishedETags}
  ) {
    executed
    message
  }
}
    `;
export type ConfirmFileMutationResult = Apollo.MutationResult<TConfirmFileMutation>;
export type ConfirmFileMutationOptions = Apollo.BaseMutationOptions<TConfirmFileMutation, TConfirmFileMutationVariables>;
export const CreateAssetDocument = gql`
    mutation createAsset($name: String!, $folderId: ID!) {
  createAssetV2(params: {name: $name, folderId: $folderId}) {
    __typename
    ... on GroupedAssetOutput {
      id
    }
    ... on BaseAssetErrorInterface {
      message
    }
  }
}
    `;
export type CreateAssetMutationResult = Apollo.MutationResult<TCreateAssetMutation>;
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<TCreateAssetMutation, TCreateAssetMutationVariables>;
export const CreateGeoreferenceDocument = gql`
    mutation createGeoreference($altitude: Float!, $longitude: Float!, $latitude: Float!, $anchorX: Float!, $anchorY: Float!, $anchorZ: Float!, $name: String!, $parentId: ID!, $visibilities: [GroupedAssetGeoreferenceVisibilityInput!]!) {
  createGroupedAssetGeoreference(
    georeference: {parentId: $parentId, name: $name, longitude: $longitude, latitude: $latitude, altitude: $altitude, anchorX: $anchorX, anchorY: $anchorY, anchorZ: $anchorZ, flattenEnabled: false, flattenScale: 1, pitch: 0, yaw: 0, roll: 0, scaleX: 1, scaleY: 1, scaleZ: 1, visible: $visibilities}
  ) {
    ...Geolocation
  }
}
    ${GeolocationFragmentDoc}`;
export type CreateGeoreferenceMutationResult = Apollo.MutationResult<TCreateGeoreferenceMutation>;
export type CreateGeoreferenceMutationOptions = Apollo.BaseMutationOptions<TCreateGeoreferenceMutation, TCreateGeoreferenceMutationVariables>;
export const DeleteGeoreferenceDocument = gql`
    mutation deleteGeoreference($id: ID!) {
  deleteGroupedAssetGeoreference(id: $id)
}
    `;
export type DeleteGeoreferenceMutationResult = Apollo.MutationResult<TDeleteGeoreferenceMutation>;
export type DeleteGeoreferenceMutationOptions = Apollo.BaseMutationOptions<TDeleteGeoreferenceMutation, TDeleteGeoreferenceMutationVariables>;
export const UpdateGeoreferenceDocument = gql`
    mutation updateGeoreference($georeferenceId: ID!, $name: String!, $longitude: Float!, $latitude: Float!, $altitude: Float!, $anchorX: Float!, $anchorY: Float!, $anchorZ: Float!, $flattenEnabled: Boolean!, $flattenScale: Float, $pitch: Float!, $roll: Float!, $scaleX: Float!, $scaleY: Float!, $scaleZ: Float!, $visible: [GroupedAssetGeoreferenceVisibilityInput!]!, $yaw: Float!) {
  updateGroupedAssetGeoreference(
    georeference: {georeferenceId: $georeferenceId, altitude: $altitude, anchorX: $anchorX, anchorY: $anchorY, anchorZ: $anchorZ, flattenEnabled: $flattenEnabled, flattenScale: $flattenScale, latitude: $latitude, longitude: $longitude, name: $name, pitch: $pitch, yaw: $yaw, roll: $roll, scaleX: $scaleX, scaleY: $scaleY, scaleZ: $scaleZ, visible: $visible}
  ) {
    ...Geolocation
  }
}
    ${GeolocationFragmentDoc}`;
export type UpdateGeoreferenceMutationResult = Apollo.MutationResult<TUpdateGeoreferenceMutation>;
export type UpdateGeoreferenceMutationOptions = Apollo.BaseMutationOptions<TUpdateGeoreferenceMutation, TUpdateGeoreferenceMutationVariables>;
export const GetAllBaseLayersDocument = gql`
    query getAllBaseLayers($pageSize: Int!, $pageNumber: Int!) {
  getAllBaselayers(
    paging: {pageNumber: $pageNumber, pageSize: $pageSize}
    filter: REALISTIC
  ) {
    total
    pageSize
    contents {
      ...BaseLayer
    }
  }
}
    ${BaseLayerFragmentDoc}`;
export type GetAllBaseLayersQueryResult = Apollo.QueryResult<TGetAllBaseLayersQuery, TGetAllBaseLayersQueryVariables>;
export const GetAssetForAddressesDocument = gql`
    query getAssetForAddresses($groupedAssetId: ID!) {
  asset(groupedAssetId: $groupedAssetId) {
    __typename
    ... on GroupedAssetOutput {
      asset {
        withEmbeddedGeoreference
        artifacts {
          contents {
            type
            addresses {
              contents {
                ...Address
              }
            }
          }
        }
      }
    }
    ... on BaseAssetErrorInterface {
      message
    }
  }
}
    ${AddressFragmentDoc}`;
export type GetAssetForAddressesQueryResult = Apollo.QueryResult<TGetAssetForAddressesQuery, TGetAssetForAddressesQueryVariables>;
export const GetAssetForGeolocationDocument = gql`
    query getAssetForGeolocation($groupedAssetId: ID!) {
  asset(groupedAssetId: $groupedAssetId) {
    __typename
    ... on GroupedAssetOutput {
      id
      name
      thumbnailPath
      createdAt
      asset {
        withEmbeddedGeoreference
        artifacts {
          contents {
            addresses {
              contents {
                ...Address
              }
            }
            type
          }
        }
      }
      groupedAssetGeoreferences(paging: {pageNumber: 0, pageSize: 100}) {
        contents {
          ...Geolocation
        }
      }
    }
    ... on BaseAssetErrorInterface {
      message
    }
  }
}
    ${AddressFragmentDoc}
${GeolocationFragmentDoc}`;
export type GetAssetForGeolocationQueryResult = Apollo.QueryResult<TGetAssetForGeolocationQuery, TGetAssetForGeolocationQueryVariables>;
export const GetAssetForStatusDocument = gql`
    query getAssetForStatus($groupedAssetId: ID!) {
  asset(groupedAssetId: $groupedAssetId) {
    __typename
    ... on GroupedAssetOutput {
      asset {
        jobSummary {
          jobs {
            state
            type
          }
        }
        artifacts {
          contents {
            type
            addresses {
              contents {
                ... on Renderable {
                  type
                }
                ... on AddressOgc3DOutput {
                  processingPipelineInfo {
                    status
                    name
                  }
                }
                ... on AddressLtsOutput {
                  processingPipelineInfo {
                    status
                    name
                  }
                }
                ... on AddressHspcOutput {
                  processingPipelineInfo {
                    status
                    name
                  }
                }
                ... on AddressDownloadOutput {
                  processingPipelineInfo {
                    status
                    name
                  }
                }
                ... on AddressPanoramicOutput {
                  processingPipelineInfo {
                    status
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    ... on BaseAssetErrorInterface {
      message
    }
  }
}
    `;
export type GetAssetForStatusQueryResult = Apollo.QueryResult<TGetAssetForStatusQuery, TGetAssetForStatusQueryVariables>;
export const GetCatalogFeatureItemsDocument = gql`
    query getCatalogFeatureItems($collectionIds: [String!], $limit: Int, $offset: Int) {
  search(collections: $collectionIds, limit: $limit, offset: $offset) {
    total
    returned
    contents {
      ...CatalogFeatureItem
    }
  }
}
    ${CatalogFeatureItemFragmentDoc}`;
export type GetCatalogFeatureItemsQueryResult = Apollo.QueryResult<TGetCatalogFeatureItemsQuery, TGetCatalogFeatureItemsQueryVariables>;
export const GetCollectionsDocument = gql`
    query getCollections {
  collections {
    id
  }
}
    `;
export type GetCollectionsQueryResult = Apollo.QueryResult<TGetCollectionsQuery, TGetCollectionsQueryVariables>;
export const GetFolderDocument = gql`
    query getFolder($id: ID!, $orderFoldersBy: FolderOrderEnum!, $orderAssetsBy: AssetOrderEnum!, $foldersPageSize: Int!, $assetsPageSize: Int!, $foldersPageNumber: Int!, $assetsPageNumber: Int!, $foldersPageOffset: Int, $assetsPageOffset: Int) {
  folder(folderId: $id) {
    __typename
    ... on FolderOutput {
      folders(
        orderBy: $orderFoldersBy
        paging: {pageNumber: $foldersPageNumber, pageSize: $foldersPageSize, pageOffset: $foldersPageOffset}
      ) {
        total
        contents {
          id
          name
        }
      }
      assets(
        orderBy: $orderAssetsBy
        paging: {pageNumber: $assetsPageNumber, pageSize: $assetsPageSize, pageOffset: $assetsPageOffset}
      ) {
        total
        contents {
          id
          name
        }
      }
    }
    ... on BaseFolderErrorInterface {
      message
    }
  }
}
    `;
export type GetFolderQueryResult = Apollo.QueryResult<TGetFolderQuery, TGetFolderQueryVariables>;
export const GetMyAssetsProjectDocument = gql`
    query getMyAssetsProject {
  myAssetsProject {
    __typename
    ... on ProjectOutput {
      ...Project
    }
    ... on BaseProjectErrorInterface {
      message
    }
  }
}
    ${ProjectFragmentDoc}`;
export type GetMyAssetsProjectQueryResult = Apollo.QueryResult<TGetMyAssetsProjectQuery, TGetMyAssetsProjectQueryVariables>;
export const GetProductMetadataDocument = gql`
    query getProductMetadata($productCode: String!) {
  viewProduct(params: {productCodeFromCatalog: $productCode}) {
    ... on PointCloudSourceLidarOutput {
      resolution
    }
    ... on Product2DAerialOutput {
      resolution
    }
  }
}
    `;
export type GetProductMetadataQueryResult = Apollo.QueryResult<TGetProductMetadataQuery, TGetProductMetadataQueryVariables>;
export const GetProjectsDocument = gql`
    query getProjects($orderBy: ProjectOrderEnum!, $pageSize: Int!, $pageNumber: Int!) {
  projects(
    params: {orderBy: $orderBy, paging: {pageNumber: $pageNumber, pageSize: $pageSize}}
  ) {
    __typename
    ... on ProjectResultOutput {
      total
      contents {
        ...Project
      }
    }
    ... on BaseProjectErrorInterface {
      message
    }
  }
}
    ${ProjectFragmentDoc}`;
export type GetProjectsQueryResult = Apollo.QueryResult<TGetProjectsQuery, TGetProjectsQueryVariables>;
export const PresignedUrlDocument = gql`
    query presignedUrl($fileId: ID!, $chunkIndex: Int!) {
  getMultipartUploadURL(params: {fileId: $fileId, partNumber: $chunkIndex}) {
    uploadUrl
  }
}
    `;
export type PresignedUrlQueryResult = Apollo.QueryResult<TPresignedUrlQuery, TPresignedUrlQueryVariables>;
export const SearchCatalogFeatureItemsDocument = gql`
    query searchCatalogFeatureItems($collectionIds: [String!], $featureItemIds: [String!], $limit: Int, $offset: Int) {
  search(
    collections: $collectionIds
    ids: $featureItemIds
    limit: $limit
    offset: $offset
  ) {
    total
    returned
    contents {
      ...CatalogFeatureItem
    }
  }
}
    ${CatalogFeatureItemFragmentDoc}`;
export type SearchCatalogFeatureItemsQueryResult = Apollo.QueryResult<TSearchCatalogFeatureItemsQuery, TSearchCatalogFeatureItemsQueryVariables>;


export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type TResolversTypes = {
  AccessDeniedError: ResolverTypeWrapper<TAccessDeniedError>;
  AccountAddressInput: TAccountAddressInput;
  AccountAddressOutput: ResolverTypeWrapper<TAccountAddressOutput>;
  AccountDetailOutput: ResolverTypeWrapper<TAccountDetailOutput>;
  AccountDetailUnion: TResolversTypes['AccountDetailOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'];
  AccountInfoAccountAddressIsNotValidOutput: ResolverTypeWrapper<TAccountInfoAccountAddressIsNotValidOutput>;
  AccountInfoAccountCompanyNameIsNotValidOutput: ResolverTypeWrapper<TAccountInfoAccountCompanyNameIsNotValidOutput>;
  AccountInfoAccountEmailIsNotValidOutput: ResolverTypeWrapper<TAccountInfoAccountEmailIsNotValidOutput>;
  AccountInfoAccountLegalEntityCouldNotBeChangedOutput: ResolverTypeWrapper<TAccountInfoAccountLegalEntityCouldNotBeChangedOutput>;
  AccountInfoErrorOperationNotAllowedOutput: ResolverTypeWrapper<TAccountInfoErrorOperationNotAllowedOutput>;
  AccountInfoOutput: ResolverTypeWrapper<TAccountInfoOutput>;
  AccountInfoUpdateUnion: TResolversTypes['AccountInfoAccountAddressIsNotValidOutput'] | TResolversTypes['AccountInfoAccountCompanyNameIsNotValidOutput'] | TResolversTypes['AccountInfoAccountEmailIsNotValidOutput'] | TResolversTypes['AccountInfoAccountLegalEntityCouldNotBeChangedOutput'] | TResolversTypes['AccountInfoErrorOperationNotAllowedOutput'] | TResolversTypes['AccountInfoOutput'];
  AccountLimitEnum: TAccountLimitEnum;
  AccountMemberInput: TAccountMemberInput;
  AccountMembersOrderEnum: TAccountMembersOrderEnum;
  AccountMembersPageOutput: ResolverTypeWrapper<TAccountMembersPageOutput>;
  AccountMembersResultOutput: ResolverTypeWrapper<TAccountMembersResultOutput>;
  AccountMembersResultUnion: TResolversTypes['AccountMembersResultOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'];
  AccountProfileAppliedPageOutput: ResolverTypeWrapper<TAccountProfileAppliedPageOutput>;
  AccountProfileOrderEnum: TAccountProfileOrderEnum;
  AccountProfilePageInput: TAccountProfilePageInput;
  AccountProfileResultOutput: ResolverTypeWrapper<TAccountProfileResultOutput>;
  AccountPropertiesInput: TAccountPropertiesInput;
  AccountRoleEnum: TAccountRoleEnum;
  AccountUpdateInput: TAccountUpdateInput;
  AccountUsageInput: TAccountUsageInput;
  AccountUsageOutput: ResolverTypeWrapper<TAccountUsageOutput>;
  AcsErrorEntityNotFoundOutput: ResolverTypeWrapper<TAcsErrorEntityNotFoundOutput>;
  AcsErrorInvalidInvitationSignatureOutput: ResolverTypeWrapper<TAcsErrorInvalidInvitationSignatureOutput>;
  AcsErrorInvalidVoucherOutput: ResolverTypeWrapper<TAcsErrorInvalidVoucherOutput>;
  AcsErrorOperationNotAllowedOutput: ResolverTypeWrapper<TAcsErrorOperationNotAllowedOutput>;
  AcsErrorOperationNotPossibleOutput: ResolverTypeWrapper<TAcsErrorOperationNotPossibleOutput>;
  AcsErrorProjectNotFoundOutput: ResolverTypeWrapper<TAcsErrorProjectNotFoundOutput>;
  AcsErrorSeatLimitReachedOutput: ResolverTypeWrapper<TAcsErrorSeatLimitReachedOutput>;
  AcsErrorTenantNotFoundOutput: ResolverTypeWrapper<TAcsErrorTenantNotFoundOutput>;
  AcsErrorTooManyRequestsOutput: ResolverTypeWrapper<TAcsErrorTooManyRequestsOutput>;
  AcsErrorUserAlreadyExistsInAccountOutput: ResolverTypeWrapper<TAcsErrorUserAlreadyExistsInAccountOutput>;
  AcsErrorUserAlreadyExistsInOtherAccountOutput: ResolverTypeWrapper<TAcsErrorUserAlreadyExistsInOtherAccountOutput>;
  AcsErrorUserAlreadyExistsOutput: ResolverTypeWrapper<TAcsErrorUserAlreadyExistsOutput>;
  AcsErrorUserNotFoundOutput: ResolverTypeWrapper<TAcsErrorUserNotFoundOutput>;
  AcsErrorVoucherAlreadyUsedOutput: ResolverTypeWrapper<TAcsErrorVoucherAlreadyUsedOutput>;
  AddCartItemInput: TAddCartItemInput;
  AddFileInput: TAddFileInput;
  AddFileUnion: TResolversTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversTypes['AssetErrorNameBlankOutput'] | TResolversTypes['AssetErrorNameForbiddenOutput'] | TResolversTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['AssetErrorNameTrailingDotOutput'] | TResolversTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorStorageLimitReachedOutput'] | TResolversTypes['FileOutput'];
  AddLabelsInput: TAddLabelsInput;
  AddLabelsUnion: TResolversTypes['LabelErrorOperationNotAllowedOutput'] | TResolversTypes['LabelErrorTooManyLabelsOutput'] | TResolversTypes['LabelsOutput'];
  AddressAssetFileDownloadOutput: ResolverTypeWrapper<TAddressAssetFileDownloadOutput>;
  AddressContentTypeEnum: TAddressContentTypeEnum;
  AddressDownloadOutput: ResolverTypeWrapper<TAddressDownloadOutput>;
  AddressHspcOutput: ResolverTypeWrapper<TAddressHspcOutput>;
  AddressLtsOutput: ResolverTypeWrapper<TAddressLtsOutput>;
  AddressOgc3DOutput: ResolverTypeWrapper<TAddressOgc3DOutput>;
  AddressOutput: TResolversTypes['AddressAssetFileDownloadOutput'] | TResolversTypes['AddressDownloadOutput'] | TResolversTypes['AddressHspcOutput'] | TResolversTypes['AddressLtsOutput'] | TResolversTypes['AddressOgc3DOutput'] | TResolversTypes['AddressPanoramicOutput'] | TResolversTypes['AddressWfsOutput'] | TResolversTypes['AddressWmsOutput'] | TResolversTypes['AddressWmtsOutput'];
  AddressPageOutput: ResolverTypeWrapper<Omit<TAddressPageOutput, 'contents'> & { contents: Array<TResolversTypes['AddressOutput']> }>;
  AddressPanoramicOutput: ResolverTypeWrapper<TAddressPanoramicOutput>;
  AddressSamplingModeEnum: TAddressSamplingModeEnum;
  AddressTypeEnum: TAddressTypeEnum;
  AddressValidOutput: ResolverTypeWrapper<TAddressValidOutput>;
  AddressViewingModeEnum: TAddressViewingModeEnum;
  AddressWfsOutput: ResolverTypeWrapper<TAddressWfsOutput>;
  AddressWmsOutput: ResolverTypeWrapper<TAddressWmsOutput>;
  AddressWmtsOutput: ResolverTypeWrapper<TAddressWmtsOutput>;
  AnnotationErrorOperationNotAllowedOutput: ResolverTypeWrapper<TAnnotationErrorOperationNotAllowedOutput>;
  AnnotationOrientationInput: TAnnotationOrientationInput;
  AnnotationOrientationOutput: ResolverTypeWrapper<TAnnotationOrientationOutput>;
  AnnotationPointInput: TAnnotationPointInput;
  AnnotationPointOutput: ResolverTypeWrapper<TAnnotationPointOutput>;
  AnnotationRotationInput: TAnnotationRotationInput;
  AnnotationRotationOutput: ResolverTypeWrapper<TAnnotationRotationOutput>;
  AnnotationThumbnailOutput: ResolverTypeWrapper<TAnnotationThumbnailOutput>;
  AnnotationThumbnailUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['AnnotationThumbnailOutput'];
  AppClientOutput: ResolverTypeWrapper<TAppClientOutput>;
  ApplicationErrorInvalidArgumentOutput: ResolverTypeWrapper<TApplicationErrorInvalidArgumentOutput>;
  ApplicationErrorOperationNotAllowedOutput: ResolverTypeWrapper<TApplicationErrorOperationNotAllowedOutput>;
  ApplicationErrorResourceAccessDeniedOutput: ResolverTypeWrapper<TApplicationErrorResourceAccessDeniedOutput>;
  ApplicationErrorTooManyRequestsOutput: ResolverTypeWrapper<TApplicationErrorTooManyRequestsOutput>;
  ApplicationOrderEnum: TApplicationOrderEnum;
  ApplicationTemplateAppliedPageOutput: ResolverTypeWrapper<TApplicationTemplateAppliedPageOutput>;
  ApplicationTemplateOutput: ResolverTypeWrapper<TApplicationTemplateOutput>;
  ApplicationTemplatePageInput: TApplicationTemplatePageInput;
  ApplicationTemplateResultOutput: ResolverTypeWrapper<TApplicationTemplateResultOutput>;
  ApplicationTemplateUnion: TResolversTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversTypes['ApplicationTemplateOutput'];
  ApplicationTemplatesInput: TApplicationTemplatesInput;
  ApplicationTemplatesUnion: TResolversTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversTypes['ApplicationTemplateResultOutput'];
  ArtifactAppliedPageOutput: ResolverTypeWrapper<TArtifactAppliedPageOutput>;
  ArtifactItemOutput: ResolverTypeWrapper<TArtifactItemOutput>;
  ArtifactPageInput: TArtifactPageInput;
  ArtifactPageableGQL: TArtifactPageableGql;
  ArtifactParentTypeEnum: TArtifactParentTypeEnum;
  ArtifactResultOutput: ResolverTypeWrapper<TArtifactResultOutput>;
  ArtifactResultOutputV2: ResolverTypeWrapper<TArtifactResultOutputV2>;
  ArtifactTypeEnum: TArtifactTypeEnum;
  AssetAppliedPageOutput: ResolverTypeWrapper<TAssetAppliedPageOutput>;
  AssetBBoxInput: TAssetBBoxInput;
  AssetBBoxOutput: ResolverTypeWrapper<TAssetBBoxOutput>;
  AssetCountOutput: ResolverTypeWrapper<TAssetCountOutput>;
  AssetErrorCoarseRegistrationInputNotFound: ResolverTypeWrapper<TAssetErrorCoarseRegistrationInputNotFound>;
  AssetErrorCoarseRegistrationLimitOutput: ResolverTypeWrapper<TAssetErrorCoarseRegistrationLimitOutput>;
  AssetErrorDownloadLimitReachedOutput: ResolverTypeWrapper<TAssetErrorDownloadLimitReachedOutput>;
  AssetErrorDuplicateNameOutput: ResolverTypeWrapper<TAssetErrorDuplicateNameOutput>;
  AssetErrorFeatureNotAvailableOutput: ResolverTypeWrapper<TAssetErrorFeatureNotAvailableOutput>;
  AssetErrorGroupedAssetRegistrationLimitOutput: ResolverTypeWrapper<TAssetErrorGroupedAssetRegistrationLimitOutput>;
  AssetErrorGroupedAssetsNotUnderSameFolderOutput: ResolverTypeWrapper<TAssetErrorGroupedAssetsNotUnderSameFolderOutput>;
  AssetErrorNameBlacklistedCharactersOutput: ResolverTypeWrapper<TAssetErrorNameBlacklistedCharactersOutput>;
  AssetErrorNameBlankOutput: ResolverTypeWrapper<TAssetErrorNameBlankOutput>;
  AssetErrorNameForbiddenOutput: ResolverTypeWrapper<TAssetErrorNameForbiddenOutput>;
  AssetErrorNameForwardSlashOutput: ResolverTypeWrapper<TAssetErrorNameForwardSlashOutput>;
  AssetErrorNameLeadingOrTrailingSpaceOutput: ResolverTypeWrapper<TAssetErrorNameLeadingOrTrailingSpaceOutput>;
  AssetErrorNameTrailingDotOutput: ResolverTypeWrapper<TAssetErrorNameTrailingDotOutput>;
  AssetErrorNameTwoDotsInRowOutput: ResolverTypeWrapper<TAssetErrorNameTwoDotsInRowOutput>;
  AssetErrorOperationNotAllowedOutput: ResolverTypeWrapper<TAssetErrorOperationNotAllowedOutput>;
  AssetErrorProcessingLimitReachedOutput: ResolverTypeWrapper<TAssetErrorProcessingLimitReachedOutput>;
  AssetErrorResourceAccessDeniedOutput: ResolverTypeWrapper<TAssetErrorResourceAccessDeniedOutput>;
  AssetErrorStorageLimitReachedOutput: ResolverTypeWrapper<TAssetErrorStorageLimitReachedOutput>;
  AssetErrorTooManyRequestsOutput: ResolverTypeWrapper<TAssetErrorTooManyRequestsOutput>;
  AssetOrderEnum: TAssetOrderEnum;
  AssetOutput: ResolverTypeWrapper<TAssetOutput>;
  AssetPageInput: TAssetPageInput;
  AssetStatusEnum: TAssetStatusEnum;
  AssetThumbnailOutput: ResolverTypeWrapper<TAssetThumbnailOutput>;
  AssetTypeEnum: TAssetTypeEnum;
  AssetUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  AssetsInAccountUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['GroupedAssetResultOutput'];
  AssetsInFolderUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['FolderOutput'];
  AssignAccountToTenantInput: TAssignAccountToTenantInput;
  AssignTenantRoleToUserInput: TAssignTenantRoleToUserInput;
  AutoRegisterAssetsUnion: TResolversTypes['AssetErrorCoarseRegistrationInputNotFound'] | TResolversTypes['AssetErrorCoarseRegistrationLimitOutput'] | TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorFeatureNotAvailableOutput'] | TResolversTypes['AssetErrorGroupedAssetRegistrationLimitOutput'] | TResolversTypes['AssetErrorGroupedAssetsNotUnderSameFolderOutput'] | TResolversTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversTypes['AssetErrorNameBlankOutput'] | TResolversTypes['AssetErrorNameForbiddenOutput'] | TResolversTypes['AssetErrorNameForwardSlashOutput'] | TResolversTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['AssetErrorNameTrailingDotOutput'] | TResolversTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorProcessingLimitReachedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorStorageLimitReachedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  AutoRegistrationContentInput: TAutoRegistrationContentInput;
  AutoRegistrationInput: TAutoRegistrationInput;
  AutoRegistrationInputV2: TAutoRegistrationInputV2;
  AutoRegistrationTypeEnum: TAutoRegistrationTypeEnum;
  BaseAccountInfoErrorInterface: TResolversTypes['AccountInfoAccountAddressIsNotValidOutput'] | TResolversTypes['AccountInfoAccountCompanyNameIsNotValidOutput'] | TResolversTypes['AccountInfoAccountEmailIsNotValidOutput'] | TResolversTypes['AccountInfoAccountLegalEntityCouldNotBeChangedOutput'] | TResolversTypes['AccountInfoErrorOperationNotAllowedOutput'];
  BaseAcsErrorInterface: TResolversTypes['AcsErrorEntityNotFoundOutput'] | TResolversTypes['AcsErrorInvalidInvitationSignatureOutput'] | TResolversTypes['AcsErrorInvalidVoucherOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['AcsErrorProjectNotFoundOutput'] | TResolversTypes['AcsErrorSeatLimitReachedOutput'] | TResolversTypes['AcsErrorTenantNotFoundOutput'] | TResolversTypes['AcsErrorTooManyRequestsOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsInAccountOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsOutput'] | TResolversTypes['AcsErrorUserNotFoundOutput'] | TResolversTypes['AcsErrorVoucherAlreadyUsedOutput'];
  BaseAnnotationErrorInterface: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'];
  BaseApplicationErrorInterface: TResolversTypes['ApplicationErrorInvalidArgumentOutput'] | TResolversTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversTypes['ApplicationErrorTooManyRequestsOutput'];
  BaseAssetErrorInterface: TResolversTypes['AssetErrorCoarseRegistrationInputNotFound'] | TResolversTypes['AssetErrorCoarseRegistrationLimitOutput'] | TResolversTypes['AssetErrorDownloadLimitReachedOutput'] | TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorFeatureNotAvailableOutput'] | TResolversTypes['AssetErrorGroupedAssetRegistrationLimitOutput'] | TResolversTypes['AssetErrorGroupedAssetsNotUnderSameFolderOutput'] | TResolversTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversTypes['AssetErrorNameBlankOutput'] | TResolversTypes['AssetErrorNameForbiddenOutput'] | TResolversTypes['AssetErrorNameForwardSlashOutput'] | TResolversTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['AssetErrorNameTrailingDotOutput'] | TResolversTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorProcessingLimitReachedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorStorageLimitReachedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'];
  BaseFolderErrorInterface: TResolversTypes['FolderErrorDuplicateNameOutput'] | TResolversTypes['FolderErrorNameBlacklistedCharactersOutput'] | TResolversTypes['FolderErrorNameBlankOutput'] | TResolversTypes['FolderErrorNameForbiddenOutput'] | TResolversTypes['FolderErrorNameForwardSlashOutput'] | TResolversTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['FolderErrorNameTrailingDotOutput'] | TResolversTypes['FolderErrorNameTwoDotsInRowOutput'] | TResolversTypes['FolderErrorOperationNotAllowedOutput'] | TResolversTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversTypes['FolderErrorResourceNotFoundOutput'] | TResolversTypes['FolderErrorTooManyRequestsOutput'];
  BaseLabelErrorInterface: TResolversTypes['LabelErrorOperationNotAllowedOutput'] | TResolversTypes['LabelErrorTooManyLabelsOutput'];
  BaseLicenseErrorInterface: TResolversTypes['LicenseErrorInvalidArgumentOutput'] | TResolversTypes['LicenseErrorLicenseNotAvailableOutput'] | TResolversTypes['LicenseErrorOperationNotAllowedOutput'] | TResolversTypes['LicenseErrorTooManyRequestsOutput'];
  BaseProcessingErrorInterface: TResolversTypes['ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput'] | TResolversTypes['ProcessingErrorInternalServerErrorOutput'] | TResolversTypes['ProcessingErrorInvalidInputForProcessingPaymentErrorOutput'] | TResolversTypes['ProcessingErrorOperationNotAllowedOutput'] | TResolversTypes['ProcessingErrorProcessingLimitReachedOutput'] | TResolversTypes['ProcessingErrorResourceAccessDeniedOutput'] | TResolversTypes['ProcessingErrorResourceNotFoundOutput'] | TResolversTypes['ProcessingErrorStorageLimitReachedOutput'];
  BaseProjectErrorInterface: TResolversTypes['ProjectErrorDuplicateNameOutput'] | TResolversTypes['ProjectErrorInvalidInputOutput'] | TResolversTypes['ProjectErrorNameBlacklistedCharactersOutput'] | TResolversTypes['ProjectErrorNameBlankOutput'] | TResolversTypes['ProjectErrorNameForbiddenOutput'] | TResolversTypes['ProjectErrorNameForwardSlashOutput'] | TResolversTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['ProjectErrorNameTrailingDotOutput'] | TResolversTypes['ProjectErrorNameTwoDotsInRowOutput'] | TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedForTargetUserOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'];
  BaseQuotationErrorInterface: TResolversTypes['QuotationErrorProductNotFoundOutput'];
  BaseShopErrorInterface: TResolversTypes['ShopErrorBackendFailedOutput'] | TResolversTypes['ShopErrorNoOverlapWithProductOutput'] | TResolversTypes['ShopErrorOperationNotAllowedOutput'] | TResolversTypes['ShopErrorProductNotFoundOutput'] | TResolversTypes['ShopErrorProductPricingNotFoundOutput'];
  BaseSubscriptionErrorInterface: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorBadRequestOutput'] | TResolversTypes['SubscriptionErrorDuplicateWithSameAddonOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'];
  BaselayerAppliedPageOutput: ResolverTypeWrapper<TBaselayerAppliedPageOutput>;
  BaselayerHspcOutput: ResolverTypeWrapper<TBaselayerHspcOutput>;
  BaselayerLtsOutput: ResolverTypeWrapper<TBaselayerLtsOutput>;
  BaselayerOgc3DOutput: ResolverTypeWrapper<TBaselayerOgc3DOutput>;
  BaselayerOriginEnum: TBaselayerOriginEnum;
  BaselayerOutput: TResolversTypes['BaselayerHspcOutput'] | TResolversTypes['BaselayerLtsOutput'] | TResolversTypes['BaselayerOgc3DOutput'] | TResolversTypes['BaselayerPanoramicOutput'] | TResolversTypes['BaselayerUrlOutput'] | TResolversTypes['BaselayerWfsOutput'] | TResolversTypes['BaselayerWmsOutput'] | TResolversTypes['BaselayerWmtsOutput'];
  BaselayerPageInput: TBaselayerPageInput;
  BaselayerPanoramicOutput: ResolverTypeWrapper<TBaselayerPanoramicOutput>;
  BaselayerResultOutput: ResolverTypeWrapper<Omit<TBaselayerResultOutput, 'contents'> & { contents: Array<TResolversTypes['BaselayerOutput']> }>;
  BaselayerUrlOutput: ResolverTypeWrapper<TBaselayerUrlOutput>;
  BaselayerWfsOutput: ResolverTypeWrapper<TBaselayerWfsOutput>;
  BaselayerWmsOutput: ResolverTypeWrapper<TBaselayerWmsOutput>;
  BaselayerWmtsOutput: ResolverTypeWrapper<TBaselayerWmtsOutput>;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']>;
  BigInteger: ResolverTypeWrapper<Scalars['BigInteger']>;
  BillingIntervalEnum: TBillingIntervalEnum;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']>;
  CancelCurrentSubscriptionOutput: ResolverTypeWrapper<TCancelCurrentSubscriptionOutput>;
  CancelCurrentSubscriptionUnion: TResolversTypes['CancelCurrentSubscriptionOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'];
  CartItemOutput: ResolverTypeWrapper<TCartItemOutput>;
  CartItemThumbnailInput: TCartItemThumbnailInput;
  CartItemThumbnailOutput: ResolverTypeWrapper<TCartItemThumbnailOutput>;
  CartOutput: ResolverTypeWrapper<TCartOutput>;
  CartesianBoundsOutput: ResolverTypeWrapper<TCartesianBoundsOutput>;
  ChangeProjectRoleOutput: ResolverTypeWrapper<TChangeProjectRoleOutput>;
  ChangeProjectRoleUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['AcsErrorSeatLimitReachedOutput'] | TResolversTypes['ChangeProjectRoleOutput'];
  CheckoutOutput: ResolverTypeWrapper<TCheckoutOutput>;
  Collection: ResolverTypeWrapper<TCollection>;
  Comment: ResolverTypeWrapper<TComment>;
  CommentAnchor: ResolverTypeWrapper<TCommentAnchor>;
  CommentAnchorInput: TCommentAnchorInput;
  CommentAnchorType: TCommentAnchorType;
  CommentConnection: ResolverTypeWrapper<TCommentConnection>;
  CommentEdge: ResolverTypeWrapper<TCommentEdge>;
  CommentPaginationInput: TCommentPaginationInput;
  CommentPayload: TResolversTypes['AccessDeniedError'] | TResolversTypes['Comment'] | TResolversTypes['NotFoundError'] | TResolversTypes['ServerError'] | TResolversTypes['UnauthenticatedError'] | TResolversTypes['ValidationError'];
  CommentsPayload: TResolversTypes['AccessDeniedError'] | TResolversTypes['CommentConnection'] | TResolversTypes['ServerError'] | TResolversTypes['UnauthenticatedError'] | TResolversTypes['ValidationError'];
  ConfirmSubscriptionOutput: ResolverTypeWrapper<TConfirmSubscriptionOutput>;
  ConfirmSubscriptionUnion: TResolversTypes['ConfirmSubscriptionOutput'];
  Connection: TResolversTypes['CommentConnection'];
  CoordinateSystemOutput: ResolverTypeWrapper<TCoordinateSystemOutput>;
  CopyAssetInput: TCopyAssetInput;
  CopyAssetToFolderUnion: TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorStorageLimitReachedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  CoreBaselayer: TResolversTypes['BaselayerHspcOutput'] | TResolversTypes['BaselayerLtsOutput'] | TResolversTypes['BaselayerOgc3DOutput'] | TResolversTypes['BaselayerPanoramicOutput'] | TResolversTypes['BaselayerUrlOutput'] | TResolversTypes['BaselayerWfsOutput'] | TResolversTypes['BaselayerWmsOutput'] | TResolversTypes['BaselayerWmtsOutput'];
  CoreLayer: TResolversTypes['LayerAddressHspc'] | TResolversTypes['LayerAddressLts'] | TResolversTypes['LayerAddressOgc3DTiles'] | TResolversTypes['LayerAddressWms'] | TResolversTypes['LayerAddressWmts'];
  CountryOutput: ResolverTypeWrapper<TCountryOutput>;
  CreateAppClientInput: TCreateAppClientInput;
  CreateApplicationTemplateInput: TCreateApplicationTemplateInput;
  CreateApplicationTemplateUnion: TResolversTypes['ApplicationErrorInvalidArgumentOutput'] | TResolversTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversTypes['ApplicationTemplateOutput'];
  CreateAssetInput: TCreateAssetInput;
  CreateAssetInputV3: TCreateAssetInputV3;
  CreateAssetThumbnailUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['AssetThumbnailOutput'];
  CreateAssetUnion: TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversTypes['AssetErrorNameBlankOutput'] | TResolversTypes['AssetErrorNameForbiddenOutput'] | TResolversTypes['AssetErrorNameForwardSlashOutput'] | TResolversTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['AssetErrorNameTrailingDotOutput'] | TResolversTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  CreateCartInput: TCreateCartInput;
  CreateCommentInput: TCreateCommentInput;
  CreateFolderInput: TCreateFolderInput;
  CreateFolderUnion: TResolversTypes['FolderErrorDuplicateNameOutput'] | TResolversTypes['FolderErrorNameBlacklistedCharactersOutput'] | TResolversTypes['FolderErrorNameBlankOutput'] | TResolversTypes['FolderErrorNameForbiddenOutput'] | TResolversTypes['FolderErrorNameForwardSlashOutput'] | TResolversTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['FolderErrorNameTrailingDotOutput'] | TResolversTypes['FolderErrorNameTwoDotsInRowOutput'] | TResolversTypes['FolderErrorOperationNotAllowedOutput'] | TResolversTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversTypes['FolderErrorTooManyRequestsOutput'] | TResolversTypes['FolderOutput'];
  CreateGeoreferenceInput: TCreateGeoreferenceInput;
  CreateGroupedAssetGeoreferenceInput: TCreateGroupedAssetGeoreferenceInput;
  CreateGroupedAssetTourInput: TCreateGroupedAssetTourInput;
  CreateGroupedAssetTourKeyframeInput: TCreateGroupedAssetTourKeyframeInput;
  CreateGroupedAssetTourThumbnailInput: TCreateGroupedAssetTourThumbnailInput;
  CreateLabelAnnotationInput: TCreateLabelAnnotationInput;
  CreateLabelAnnotationInputV2: TCreateLabelAnnotationInputV2;
  CreateLabelAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['LabelAnnotationOutput'];
  CreateLimitingBoxAnnotationInput: TCreateLimitingBoxAnnotationInput;
  CreateLimitingBoxAnnotationInputV2: TCreateLimitingBoxAnnotationInputV2;
  CreateLimitingBoxAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['LimitingBoxAnnotationOutput'];
  CreateMeasurementAnnotationInput: TCreateMeasurementAnnotationInput;
  CreateMeasurementAnnotationInputV2: TCreateMeasurementAnnotationInputV2;
  CreateMeasurementAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['MeasurementAnnotationOutput'];
  CreateProjectInput: TCreateProjectInput;
  CreateProjectUnion: TResolversTypes['ProjectErrorDuplicateNameOutput'] | TResolversTypes['ProjectErrorInvalidInputOutput'] | TResolversTypes['ProjectErrorNameBlacklistedCharactersOutput'] | TResolversTypes['ProjectErrorNameBlankOutput'] | TResolversTypes['ProjectErrorNameForbiddenOutput'] | TResolversTypes['ProjectErrorNameForwardSlashOutput'] | TResolversTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['ProjectErrorNameTrailingDotOutput'] | TResolversTypes['ProjectErrorNameTwoDotsInRowOutput'] | TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['ProjectOutput'];
  CreateTenantInput: TCreateTenantInput;
  CreationSubscriptionInput: TCreationSubscriptionInput;
  CustomMetricEventGQL: TCustomMetricEventGql;
  CustomMetricGQL: TCustomMetricGql;
  DataCategoryEnum: TDataCategoryEnum;
  DataReadyStatusGQL: TDataReadyStatusGql;
  DataTypeEnum: TDataTypeEnum;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']>;
  DeleteAnnotationOutput: ResolverTypeWrapper<TDeleteAnnotationOutput>;
  DeleteAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['DeleteAnnotationOutput'];
  DeleteApplicationTemplateOutput: ResolverTypeWrapper<TDeleteApplicationTemplateOutput>;
  DeleteApplicationTemplateUnion: TResolversTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversTypes['DeleteApplicationTemplateOutput'];
  DeleteAssetOutput: ResolverTypeWrapper<TDeleteAssetOutput>;
  DeleteAssetThumbnailOutput: ResolverTypeWrapper<TDeleteAssetThumbnailOutput>;
  DeleteAssetThumbnailUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['DeleteAssetThumbnailOutput'];
  DeleteAssetUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['DeleteAssetOutput'];
  DeleteCommentInput: TDeleteCommentInput;
  DeleteFolderOutput: ResolverTypeWrapper<TDeleteFolderOutput>;
  DeleteFolderUnion: TResolversTypes['DeleteFolderOutput'] | TResolversTypes['FolderErrorOperationNotAllowedOutput'] | TResolversTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversTypes['FolderErrorResourceNotFoundOutput'] | TResolversTypes['FolderErrorTooManyRequestsOutput'];
  DeleteGroupedAssetTourInput: TDeleteGroupedAssetTourInput;
  DeleteGroupedAssetTourKeyframeInput: TDeleteGroupedAssetTourKeyframeInput;
  DeleteGroupedAssetTourThumbnailInput: TDeleteGroupedAssetTourThumbnailInput;
  DeleteProjectOutput: ResolverTypeWrapper<TDeleteProjectOutput>;
  DeleteProjectThumbnailOutput: ResolverTypeWrapper<TDeleteProjectThumbnailOutput>;
  DeleteProjectThumbnailUnion: TResolversTypes['DeleteProjectThumbnailOutput'] | TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'];
  DeleteProjectUnion: TResolversTypes['DeleteProjectOutput'] | TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'];
  DeleteTenantInput: TDeleteTenantInput;
  DeletedPaymentCardInput: TDeletedPaymentCardInput;
  DeletedPaymentCardOutput: ResolverTypeWrapper<TDeletedPaymentCardOutput>;
  DeviceAppliedPageOutput: TDeviceAppliedPageOutput;
  DeviceOrderEnum: TDeviceOrderEnum;
  DeviceOutput: ResolverTypeWrapper<TDeviceOutput>;
  DeviceRegistrationStatusGQL: TDeviceRegistrationStatusGql;
  DeviceResultOutput: ResolverTypeWrapper<TDeviceResultOutput>;
  DeviceTypeEnum: TDeviceTypeEnum;
  DeviceTypeGQL: TDeviceTypeGql;
  DownloadTypeEnum: TDownloadTypeEnum;
  Edge: TResolversTypes['CommentEdge'];
  EntityReferenceInput: TEntityReferenceInput;
  EntityReferenceOutput: ResolverTypeWrapper<TEntityReferenceOutput>;
  EntityTypeEnum: TEntityTypeEnum;
  Error: TResolversTypes['AccessDeniedError'] | TResolversTypes['NotFoundError'] | TResolversTypes['ServerError'] | TResolversTypes['UnauthenticatedError'] | TResolversTypes['ValidationError'];
  ExceededLimitOutput: ResolverTypeWrapper<TExceededLimitOutput>;
  ExecutedOutput: ResolverTypeWrapper<TExecutedOutput>;
  Extent: ResolverTypeWrapper<TExtent>;
  ExternalAccountMembersResultUnion: TResolversTypes['AccountMembersResultOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'];
  ExternalProjectMemberInput: TExternalProjectMemberInput;
  Feature: ResolverTypeWrapper<TFeature>;
  FeatureAsset: ResolverTypeWrapper<TFeatureAsset>;
  FeatureAssets: ResolverTypeWrapper<TFeatureAssets>;
  FeatureItem: ResolverTypeWrapper<TFeatureItem>;
  FeatureProperties: ResolverTypeWrapper<Omit<TFeatureProperties, 'layerAddress'> & { layerAddress?: Maybe<TResolversTypes['LayerAddress']> }>;
  FileAppliedPageOutput: ResolverTypeWrapper<TFileAppliedPageOutput>;
  FileFilterAppliedOutput: ResolverTypeWrapper<TFileFilterAppliedOutput>;
  FileOrderEnum: TFileOrderEnum;
  FileOutput: ResolverTypeWrapper<TFileOutput>;
  FilePageInput: TFilePageInput;
  FileResultOutput: ResolverTypeWrapper<TFileResultOutput>;
  FileStatusOutput: ResolverTypeWrapper<TFileStatusOutput>;
  FilterAccountMembersInput: TFilterAccountMembersInput;
  FilterAccountMembersOutput: ResolverTypeWrapper<TFilterAccountMembersOutput>;
  FilterAccountProfileAppliedOutput: ResolverTypeWrapper<TFilterAccountProfileAppliedOutput>;
  FilterAccountProfileInput: TFilterAccountProfileInput;
  FilterApplicationAppliedOutput: ResolverTypeWrapper<TFilterApplicationAppliedOutput>;
  FilterApplicationInput: TFilterApplicationInput;
  FilterAssetAppliedOutput: ResolverTypeWrapper<TFilterAssetAppliedOutput>;
  FilterAssetInput: TFilterAssetInput;
  FilterAssetNotInput: TFilterAssetNotInput;
  FilterAssetNotOutput: ResolverTypeWrapper<TFilterAssetNotOutput>;
  FilterAssetOrInput: TFilterAssetOrInput;
  FilterAssetOrOutput: ResolverTypeWrapper<TFilterAssetOrOutput>;
  FilterFileInput: TFilterFileInput;
  FilterFolderAppliedOutput: ResolverTypeWrapper<TFilterFolderAppliedOutput>;
  FilterFolderInput: TFilterFolderInput;
  FilterProjectAppliedOutput: ResolverTypeWrapper<TFilterProjectAppliedOutput>;
  FilterProjectInput: TFilterProjectInput;
  FilterUserProfileAppliedOutput: ResolverTypeWrapper<TFilterUserProfileAppliedOutput>;
  FilterUserProfileInput: TFilterUserProfileInput;
  Float: ResolverTypeWrapper<Scalars['Float']>;
  FolderAppliedPageOutput: ResolverTypeWrapper<TFolderAppliedPageOutput>;
  FolderErrorDuplicateNameOutput: ResolverTypeWrapper<TFolderErrorDuplicateNameOutput>;
  FolderErrorNameBlacklistedCharactersOutput: ResolverTypeWrapper<TFolderErrorNameBlacklistedCharactersOutput>;
  FolderErrorNameBlankOutput: ResolverTypeWrapper<TFolderErrorNameBlankOutput>;
  FolderErrorNameForbiddenOutput: ResolverTypeWrapper<TFolderErrorNameForbiddenOutput>;
  FolderErrorNameForwardSlashOutput: ResolverTypeWrapper<TFolderErrorNameForwardSlashOutput>;
  FolderErrorNameLeadingOrTrailingSpaceOutput: ResolverTypeWrapper<TFolderErrorNameLeadingOrTrailingSpaceOutput>;
  FolderErrorNameTrailingDotOutput: ResolverTypeWrapper<TFolderErrorNameTrailingDotOutput>;
  FolderErrorNameTwoDotsInRowOutput: ResolverTypeWrapper<TFolderErrorNameTwoDotsInRowOutput>;
  FolderErrorOperationNotAllowedOutput: ResolverTypeWrapper<TFolderErrorOperationNotAllowedOutput>;
  FolderErrorResourceAccessDeniedOutput: ResolverTypeWrapper<TFolderErrorResourceAccessDeniedOutput>;
  FolderErrorResourceNotFoundOutput: ResolverTypeWrapper<TFolderErrorResourceNotFoundOutput>;
  FolderErrorTooManyRequestsOutput: ResolverTypeWrapper<TFolderErrorTooManyRequestsOutput>;
  FolderOrderEnum: TFolderOrderEnum;
  FolderOutput: ResolverTypeWrapper<TFolderOutput>;
  FolderPageInput: TFolderPageInput;
  FolderResultOutput: ResolverTypeWrapper<TFolderResultOutput>;
  FolderUnion: TResolversTypes['FolderErrorOperationNotAllowedOutput'] | TResolversTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversTypes['FolderErrorResourceNotFoundOutput'] | TResolversTypes['FolderErrorTooManyRequestsOutput'] | TResolversTypes['FolderOutput'];
  GatewayInfoOutput: ResolverTypeWrapper<TGatewayInfoOutput>;
  GenerateInvitationUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorSeatLimitReachedOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsInAccountOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] | TResolversTypes['InvitationOutput'];
  GenerateInviteInput: TGenerateInviteInput;
  GenerateMultiAssetDownloadLinkUnion: TResolversTypes['AssetErrorDownloadLimitReachedOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['MultiAssetDownloadLinkOutput'];
  GenerateVoucherInput: TGenerateVoucherInput;
  GeoJSONCoordinateType: TGeoJsonCoordinateType;
  GeoJSONShape: ResolverTypeWrapper<TGeoJsonShape>;
  GeoJSONShapeInput: TGeoJsonShapeInput;
  GeoreferenceAppliedPageOutput: ResolverTypeWrapper<TGeoreferenceAppliedPageOutput>;
  GeoreferenceAppliedPaginationOutput: ResolverTypeWrapper<TGeoreferenceAppliedPaginationOutput>;
  GeoreferenceArtifactResultOutput: ResolverTypeWrapper<TGeoreferenceArtifactResultOutput>;
  GeoreferenceOutput: ResolverTypeWrapper<TGeoreferenceOutput>;
  GeoreferencePageInput: TGeoreferencePageInput;
  GeoreferencePaginationInput: TGeoreferencePaginationInput;
  GeoreferenceResultsOutput: ResolverTypeWrapper<TGeoreferenceResultsOutput>;
  GeoreferenceVisibilityInput: TGeoreferenceVisibilityInput;
  GeoreferenceVisibilityOutput: ResolverTypeWrapper<TGeoreferenceVisibilityOutput>;
  GeoreferencedArtifactTypeEnum: TGeoreferencedArtifactTypeEnum;
  GetGroupedAssetTourInput: TGetGroupedAssetTourInput;
  GetProjectsInput: TGetProjectsInput;
  GroupedAssetGeoreferenceOutput: ResolverTypeWrapper<TGroupedAssetGeoreferenceOutput>;
  GroupedAssetGeoreferenceResultsOutput: ResolverTypeWrapper<TGroupedAssetGeoreferenceResultsOutput>;
  GroupedAssetGeoreferenceVisibilityInput: TGroupedAssetGeoreferenceVisibilityInput;
  GroupedAssetOutput: ResolverTypeWrapper<TGroupedAssetOutput>;
  GroupedAssetPropertiesCreateTourInput: TGroupedAssetPropertiesCreateTourInput;
  GroupedAssetPropertiesInput: TGroupedAssetPropertiesInput;
  GroupedAssetPropertiesOutput: ResolverTypeWrapper<TGroupedAssetPropertiesOutput>;
  GroupedAssetPropertiesUpdateInput: TGroupedAssetPropertiesUpdateInput;
  GroupedAssetResultOutput: ResolverTypeWrapper<TGroupedAssetResultOutput>;
  GroupedAssetTourKeyframeCreateTourInput: TGroupedAssetTourKeyframeCreateTourInput;
  GroupedAssetTourKeyframeInput: TGroupedAssetTourKeyframeInput;
  GroupedAssetTourKeyframeOutput: ResolverTypeWrapper<TGroupedAssetTourKeyframeOutput>;
  GroupedAssetTourKeyframeUpdateInput: TGroupedAssetTourKeyframeUpdateInput;
  GroupedAssetTourOutput: ResolverTypeWrapper<TGroupedAssetTourOutput>;
  GroupedAssetTourResultOutput: ResolverTypeWrapper<TGroupedAssetTourResultOutput>;
  HSPCInput: THspcInput;
  HXDRProductOutput: TResolversTypes['PointCloudSourceLidarOutput'] | TResolversTypes['Product2DAerialOutput'];
  ID: ResolverTypeWrapper<Scalars['ID']>;
  ImplementedPipeline: TImplementedPipeline;
  InfoGQL: ResolverTypeWrapper<TInfoGql>;
  InfoPage: ResolverTypeWrapper<TInfoPage>;
  Int: ResolverTypeWrapper<Scalars['Int']>;
  InvitationOutput: ResolverTypeWrapper<TInvitationOutput>;
  InvoiceStatusEnum: TInvoiceStatusEnum;
  ItemCollection: ResolverTypeWrapper<TItemCollection>;
  ItemCollectionType: TItemCollectionType;
  ItemType: TItemType;
  JSON: ResolverTypeWrapper<Scalars['JSON']>;
  JobDetailPageableGQL: TJobDetailPageableGql;
  JobPage: ResolverTypeWrapper<TJobPage>;
  JobStatus: TJobStatus;
  JobStatusGQL: TJobStatusGql;
  JobSummaryGQL: ResolverTypeWrapper<TJobSummaryGql>;
  JobType: TJobType;
  JobTypeGQL: TJobTypeGql;
  LTSInput: TLtsInput;
  LabelAnnotationDataInput: TLabelAnnotationDataInput;
  LabelAnnotationDataInputV2: TLabelAnnotationDataInputV2;
  LabelAnnotationDataOutput: ResolverTypeWrapper<TLabelAnnotationDataOutput>;
  LabelAnnotationOutput: ResolverTypeWrapper<TLabelAnnotationOutput>;
  LabelErrorOperationNotAllowedOutput: ResolverTypeWrapper<TLabelErrorOperationNotAllowedOutput>;
  LabelErrorTooManyLabelsOutput: ResolverTypeWrapper<TLabelErrorTooManyLabelsOutput>;
  LabelInput: TLabelInput;
  LabelOutput: ResolverTypeWrapper<TLabelOutput>;
  LabelsOutput: ResolverTypeWrapper<TLabelsOutput>;
  LabelsResultOutput: ResolverTypeWrapper<TLabelsResultOutput>;
  LayerAddress: TResolversTypes['LayerAddressHspc'] | TResolversTypes['LayerAddressLts'] | TResolversTypes['LayerAddressOgc3DTiles'] | TResolversTypes['LayerAddressWms'] | TResolversTypes['LayerAddressWmts'];
  LayerAddressHspc: ResolverTypeWrapper<TLayerAddressHspc>;
  LayerAddressLts: ResolverTypeWrapper<TLayerAddressLts>;
  LayerAddressOgc3DTiles: ResolverTypeWrapper<TLayerAddressOgc3DTiles>;
  LayerAddressWms: ResolverTypeWrapper<TLayerAddressWms>;
  LayerAddressWmts: ResolverTypeWrapper<TLayerAddressWmts>;
  LicenseErrorInvalidArgumentOutput: ResolverTypeWrapper<TLicenseErrorInvalidArgumentOutput>;
  LicenseErrorLicenseNotAvailableOutput: ResolverTypeWrapper<TLicenseErrorLicenseNotAvailableOutput>;
  LicenseErrorOperationNotAllowedOutput: ResolverTypeWrapper<TLicenseErrorOperationNotAllowedOutput>;
  LicenseErrorTooManyRequestsOutput: ResolverTypeWrapper<TLicenseErrorTooManyRequestsOutput>;
  LimitToApplyInput: TLimitToApplyInput;
  LimitToApplyOutput: ResolverTypeWrapper<TLimitToApplyOutput>;
  LimitValidityInput: TLimitValidityInput;
  LimitingBoxAnnotationDataInput: TLimitingBoxAnnotationDataInput;
  LimitingBoxAnnotationDataInputV2: TLimitingBoxAnnotationDataInputV2;
  LimitingBoxAnnotationDataOutput: ResolverTypeWrapper<TLimitingBoxAnnotationDataOutput>;
  LimitingBoxAnnotationOutput: ResolverTypeWrapper<TLimitingBoxAnnotationOutput>;
  LinkDeviceInput: TLinkDeviceInput;
  ListOfUserProjectRoleOutput: ResolverTypeWrapper<TListOfUserProjectRoleOutput>;
  LocalizedNameOutput: ResolverTypeWrapper<TLocalizedNameOutput>;
  LuciadRIALicenseOutput: ResolverTypeWrapper<TLuciadRiaLicenseOutput>;
  LuciadRIALicenseUnion: TResolversTypes['LicenseErrorInvalidArgumentOutput'] | TResolversTypes['LicenseErrorLicenseNotAvailableOutput'] | TResolversTypes['LicenseErrorOperationNotAllowedOutput'] | TResolversTypes['LuciadRIALicenseOutput'];
  MeasurementAnnotationDataInput: TMeasurementAnnotationDataInput;
  MeasurementAnnotationDataInputV2: TMeasurementAnnotationDataInputV2;
  MeasurementAnnotationDataOutput: ResolverTypeWrapper<TMeasurementAnnotationDataOutput>;
  MeasurementAnnotationEnum: TMeasurementAnnotationEnum;
  MeasurementAnnotationOutput: ResolverTypeWrapper<TMeasurementAnnotationOutput>;
  MoveAssetInput: TMoveAssetInput;
  MoveAssetToFolderUnion: TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorStorageLimitReachedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  MultiAssetDownloadLinkInput: TMultiAssetDownloadLinkInput;
  MultiAssetDownloadLinkOutput: ResolverTypeWrapper<TMultiAssetDownloadLinkOutput>;
  MultipartUploadCompleteInput: TMultipartUploadCompleteInput;
  MultipartUploadETagInput: TMultipartUploadETagInput;
  MultipartUploadETagOutput: ResolverTypeWrapper<TMultipartUploadETagOutput>;
  MultipartUploadPartsOutput: ResolverTypeWrapper<TMultipartUploadPartsOutput>;
  MultipartUploadUrlInput: TMultipartUploadUrlInput;
  MultipartUploadUrlOutput: ResolverTypeWrapper<TMultipartUploadUrlOutput>;
  MultipartUploadUrlUnion: TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorStorageLimitReachedOutput'] | TResolversTypes['MultipartUploadUrlOutput'];
  Mutation: ResolverTypeWrapper<{}>;
  MyAssetsProjectUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['ProjectOutput'];
  Node: TResolversTypes['Comment'] | TResolversTypes['ProjectChangeFolderCopyOperation'] | TResolversTypes['ProjectChangeFolderCreateOperation'] | TResolversTypes['ProjectChangeFolderDeleteOperation'] | TResolversTypes['ProjectChangeFolderMoveOperation'] | TResolversTypes['ProjectChangeFolderUpdateOperation'] | TResolversTypes['ProjectChangeGroupedAssetCopyOperation'] | TResolversTypes['ProjectChangeGroupedAssetCreateOperation'] | TResolversTypes['ProjectChangeGroupedAssetDeleteOperation'] | TResolversTypes['ProjectChangeGroupedAssetMoveOperation'] | TResolversTypes['ProjectChangeGroupedAssetUpdateOperation'] | TResolversTypes['ProjectChangeOutput'] | TResolversTypes['ProjectChangeProjectUpdateOperation'] | TResolversTypes['ProjectChangeUserAccessGrantedOperation'] | TResolversTypes['ProjectChangeUserAccessRevokedOperation'];
  NotFoundError: ResolverTypeWrapper<TNotFoundError>;
  OAuthFlowTypeEnum: TOAuthFlowTypeEnum;
  OGC3DInput: TOgc3DInput;
  OrderAddressInput: TOrderAddressInput;
  OrderGQL: ResolverTypeWrapper<TOrderGql>;
  OrderItemGQL: ResolverTypeWrapper<TOrderItemGql>;
  OrderItemStatusGQL: TOrderItemStatusGql;
  OrderStatusGQL: TOrderStatusGql;
  OrderTypeEnum: TOrderTypeEnum;
  OrderTypeGQL: TOrderTypeGql;
  OutputFormat: TOutputFormat;
  PageInfo: ResolverTypeWrapper<TPageInfo>;
  PanoramicInput: TPanoramicInput;
  ParentTypeEnum: TParentTypeEnum;
  PaymentCardAddressInput: TPaymentCardAddressInput;
  PaymentCardAddressOutput: ResolverTypeWrapper<TPaymentCardAddressOutput>;
  PaymentCardInput: TPaymentCardInput;
  PaymentCardOutput: ResolverTypeWrapper<TPaymentCardOutput>;
  PaymentMethodEnum: TPaymentMethodEnum;
  PaymentMethodShopEnum: TPaymentMethodShopEnum;
  PaymentSystemEnum: TPaymentSystemEnum;
  PaymentSystemOutput: ResolverTypeWrapper<TPaymentSystemOutput>;
  PersistArtifactInput: TPersistArtifactInput;
  PersistAssetInput: TPersistAssetInput;
  PersistAssetUnion: TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversTypes['AssetErrorNameBlankOutput'] | TResolversTypes['AssetErrorNameForbiddenOutput'] | TResolversTypes['AssetErrorNameForwardSlashOutput'] | TResolversTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['AssetErrorNameTrailingDotOutput'] | TResolversTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  PipelineStatus: TPipelineStatus;
  PlaceOrderInput: TPlaceOrderInput;
  Point3DInput: TPoint3DInput;
  Point3DOutput: ResolverTypeWrapper<TPoint3DOutput>;
  PointCloudSourceLidarOutput: ResolverTypeWrapper<TPointCloudSourceLidarOutput>;
  PolygonOutput: ResolverTypeWrapper<TPolygonOutput>;
  PolygonType: TPolygonType;
  ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput: ResolverTypeWrapper<TProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput>;
  ProcessingErrorInternalServerErrorOutput: ResolverTypeWrapper<TProcessingErrorInternalServerErrorOutput>;
  ProcessingErrorInvalidInputForProcessingPaymentErrorOutput: ResolverTypeWrapper<TProcessingErrorInvalidInputForProcessingPaymentErrorOutput>;
  ProcessingErrorLevel: TProcessingErrorLevel;
  ProcessingErrorOperationNotAllowedOutput: ResolverTypeWrapper<TProcessingErrorOperationNotAllowedOutput>;
  ProcessingErrorOutput: ResolverTypeWrapper<TProcessingErrorOutput>;
  ProcessingErrorProcessingLimitReachedOutput: ResolverTypeWrapper<TProcessingErrorProcessingLimitReachedOutput>;
  ProcessingErrorResourceAccessDeniedOutput: ResolverTypeWrapper<TProcessingErrorResourceAccessDeniedOutput>;
  ProcessingErrorResourceNotFoundOutput: ResolverTypeWrapper<TProcessingErrorResourceNotFoundOutput>;
  ProcessingErrorStorageLimitReachedOutput: ResolverTypeWrapper<TProcessingErrorStorageLimitReachedOutput>;
  ProcessingErrorType: TProcessingErrorType;
  ProcessingPipelineInfoOutput: ResolverTypeWrapper<TProcessingPipelineInfoOutput>;
  ProcessingPipelineName: TProcessingPipelineName;
  ProcessingPipelineTriggerInput: TProcessingPipelineTriggerInput;
  ProcessingPipelineTriggerOutput: ResolverTypeWrapper<TProcessingPipelineTriggerOutput>;
  ProcessingStatus: TProcessingStatus;
  ProcessingTypeOutput: ResolverTypeWrapper<TProcessingTypeOutput>;
  ProcessingUsageOutput: ResolverTypeWrapper<TProcessingUsageOutput>;
  Product2DAerialOutput: ResolverTypeWrapper<TProduct2DAerialOutput>;
  ProductCodeInput: TProductCodeInput;
  ProductInput: TProductInput;
  ProductOutput: TResolversTypes['PointCloudSourceLidarOutput'] | TResolversTypes['Product2DAerialOutput'];
  ProductUnion: TResolversTypes['QuotationErrorProductNotFoundOutput'] | TResolversTypes['QuotationProductOutput'];
  ProfilePictureOutput: ResolverTypeWrapper<TProfilePictureOutput>;
  ProjectAppliedPageOutput: ResolverTypeWrapper<TProjectAppliedPageOutput>;
  ProjectChangeCopyOperation: TResolversTypes['ProjectChangeFolderCopyOperation'] | TResolversTypes['ProjectChangeGroupedAssetCopyOperation'];
  ProjectChangeCreateOperation: TResolversTypes['ProjectChangeFolderCreateOperation'] | TResolversTypes['ProjectChangeGroupedAssetCreateOperation'];
  ProjectChangeDeleteOperation: TResolversTypes['ProjectChangeFolderDeleteOperation'] | TResolversTypes['ProjectChangeGroupedAssetDeleteOperation'];
  ProjectChangeFolderCopyOperation: ResolverTypeWrapper<Omit<TProjectChangeFolderCopyOperation, 'folder' | 'sourceFolder' | 'targetFolder'> & { folder: TResolversTypes['FolderUnion'], sourceFolder: TResolversTypes['FolderUnion'], targetFolder: TResolversTypes['FolderUnion'] }>;
  ProjectChangeFolderCreateOperation: ResolverTypeWrapper<Omit<TProjectChangeFolderCreateOperation, 'folder'> & { folder: TResolversTypes['FolderUnion'] }>;
  ProjectChangeFolderDeleteOperation: ResolverTypeWrapper<TProjectChangeFolderDeleteOperation>;
  ProjectChangeFolderMoveOperation: ResolverTypeWrapper<Omit<TProjectChangeFolderMoveOperation, 'folder' | 'sourceFolder' | 'targetFolder'> & { folder: TResolversTypes['FolderUnion'], sourceFolder: TResolversTypes['FolderUnion'], targetFolder: TResolversTypes['FolderUnion'] }>;
  ProjectChangeFolderOperation: TResolversTypes['ProjectChangeFolderCopyOperation'] | TResolversTypes['ProjectChangeFolderCreateOperation'] | TResolversTypes['ProjectChangeFolderDeleteOperation'] | TResolversTypes['ProjectChangeFolderMoveOperation'] | TResolversTypes['ProjectChangeFolderUpdateOperation'];
  ProjectChangeFolderUpdateOperation: ResolverTypeWrapper<Omit<TProjectChangeFolderUpdateOperation, 'folder'> & { folder: TResolversTypes['FolderUnion'] }>;
  ProjectChangeGroupedAssetCopyOperation: ResolverTypeWrapper<Omit<TProjectChangeGroupedAssetCopyOperation, 'asset' | 'sourceFolder' | 'targetFolder'> & { asset: TResolversTypes['AssetUnion'], sourceFolder: TResolversTypes['FolderUnion'], targetFolder: TResolversTypes['FolderUnion'] }>;
  ProjectChangeGroupedAssetCreateOperation: ResolverTypeWrapper<Omit<TProjectChangeGroupedAssetCreateOperation, 'asset'> & { asset: TResolversTypes['AssetUnion'] }>;
  ProjectChangeGroupedAssetDeleteOperation: ResolverTypeWrapper<TProjectChangeGroupedAssetDeleteOperation>;
  ProjectChangeGroupedAssetMoveOperation: ResolverTypeWrapper<Omit<TProjectChangeGroupedAssetMoveOperation, 'asset' | 'sourceFolder' | 'targetFolder'> & { asset: TResolversTypes['AssetUnion'], sourceFolder: TResolversTypes['FolderUnion'], targetFolder: TResolversTypes['FolderUnion'] }>;
  ProjectChangeGroupedAssetOperation: TResolversTypes['ProjectChangeGroupedAssetCopyOperation'] | TResolversTypes['ProjectChangeGroupedAssetCreateOperation'] | TResolversTypes['ProjectChangeGroupedAssetDeleteOperation'] | TResolversTypes['ProjectChangeGroupedAssetMoveOperation'] | TResolversTypes['ProjectChangeGroupedAssetUpdateOperation'];
  ProjectChangeGroupedAssetUpdateOperation: ResolverTypeWrapper<Omit<TProjectChangeGroupedAssetUpdateOperation, 'asset'> & { asset: TResolversTypes['AssetUnion'] }>;
  ProjectChangeMoveOperation: TResolversTypes['ProjectChangeFolderMoveOperation'] | TResolversTypes['ProjectChangeGroupedAssetMoveOperation'];
  ProjectChangeOperation: TResolversTypes['ProjectChangeFolderCopyOperation'] | TResolversTypes['ProjectChangeFolderCreateOperation'] | TResolversTypes['ProjectChangeFolderDeleteOperation'] | TResolversTypes['ProjectChangeFolderMoveOperation'] | TResolversTypes['ProjectChangeFolderUpdateOperation'] | TResolversTypes['ProjectChangeGroupedAssetCopyOperation'] | TResolversTypes['ProjectChangeGroupedAssetCreateOperation'] | TResolversTypes['ProjectChangeGroupedAssetDeleteOperation'] | TResolversTypes['ProjectChangeGroupedAssetMoveOperation'] | TResolversTypes['ProjectChangeGroupedAssetUpdateOperation'] | TResolversTypes['ProjectChangeProjectUpdateOperation'] | TResolversTypes['ProjectChangeUserAccessGrantedOperation'] | TResolversTypes['ProjectChangeUserAccessRevokedOperation'];
  ProjectChangeOutput: ResolverTypeWrapper<Omit<TProjectChangeOutput, 'operation' | 'project'> & { operation: TResolversTypes['ProjectChangeOperation'], project: TResolversTypes['ProjectUnion'] }>;
  ProjectChangePayload: TResolversTypes['ProjectChangeOutput'] | TResolversTypes['ServerError'] | TResolversTypes['UnauthenticatedError'];
  ProjectChangeProjectOperation: TResolversTypes['ProjectChangeOutput'] | TResolversTypes['ProjectChangeProjectUpdateOperation'];
  ProjectChangeProjectUpdateOperation: ResolverTypeWrapper<Omit<TProjectChangeProjectUpdateOperation, 'project'> & { project: TResolversTypes['ProjectUnion'] }>;
  ProjectChangeUpdateOperation: TResolversTypes['ProjectChangeFolderUpdateOperation'] | TResolversTypes['ProjectChangeGroupedAssetUpdateOperation'] | TResolversTypes['ProjectChangeProjectUpdateOperation'];
  ProjectChangeUserAccessGrantedOperation: ResolverTypeWrapper<TProjectChangeUserAccessGrantedOperation>;
  ProjectChangeUserAccessOperation: TResolversTypes['ProjectChangeUserAccessGrantedOperation'] | TResolversTypes['ProjectChangeUserAccessRevokedOperation'];
  ProjectChangeUserAccessRevokedOperation: ResolverTypeWrapper<TProjectChangeUserAccessRevokedOperation>;
  ProjectErrorDuplicateNameOutput: ResolverTypeWrapper<TProjectErrorDuplicateNameOutput>;
  ProjectErrorInvalidInputOutput: ResolverTypeWrapper<TProjectErrorInvalidInputOutput>;
  ProjectErrorNameBlacklistedCharactersOutput: ResolverTypeWrapper<TProjectErrorNameBlacklistedCharactersOutput>;
  ProjectErrorNameBlankOutput: ResolverTypeWrapper<TProjectErrorNameBlankOutput>;
  ProjectErrorNameForbiddenOutput: ResolverTypeWrapper<TProjectErrorNameForbiddenOutput>;
  ProjectErrorNameForwardSlashOutput: ResolverTypeWrapper<TProjectErrorNameForwardSlashOutput>;
  ProjectErrorNameLeadingOrTrailingSpaceOutput: ResolverTypeWrapper<TProjectErrorNameLeadingOrTrailingSpaceOutput>;
  ProjectErrorNameTrailingDotOutput: ResolverTypeWrapper<TProjectErrorNameTrailingDotOutput>;
  ProjectErrorNameTwoDotsInRowOutput: ResolverTypeWrapper<TProjectErrorNameTwoDotsInRowOutput>;
  ProjectErrorOperationNotAllowedOutput: ResolverTypeWrapper<TProjectErrorOperationNotAllowedOutput>;
  ProjectErrorResourceAccessDeniedForTargetUserOutput: ResolverTypeWrapper<TProjectErrorResourceAccessDeniedForTargetUserOutput>;
  ProjectErrorResourceAccessDeniedOutput: ResolverTypeWrapper<TProjectErrorResourceAccessDeniedOutput>;
  ProjectErrorTooManyRequestsOutput: ResolverTypeWrapper<TProjectErrorTooManyRequestsOutput>;
  ProjectMemberInput: TProjectMemberInput;
  ProjectMemberOrderEnum: TProjectMemberOrderEnum;
  ProjectMemberOutput: ResolverTypeWrapper<TProjectMemberOutput>;
  ProjectMembersResultOutput: ResolverTypeWrapper<TProjectMembersResultOutput>;
  ProjectOrderEnum: TProjectOrderEnum;
  ProjectOutput: ResolverTypeWrapper<TProjectOutput>;
  ProjectPageInput: TProjectPageInput;
  ProjectResultOutput: ResolverTypeWrapper<TProjectResultOutput>;
  ProjectRoleEnum: TProjectRoleEnum;
  ProjectThumbnailInput: TProjectThumbnailInput;
  ProjectThumbnailOutput: ResolverTypeWrapper<TProjectThumbnailOutput>;
  ProjectUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['ProjectOutput'];
  ProjectsUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['ProjectResultOutput'];
  PropertySpatial: ResolverTypeWrapper<TPropertySpatial>;
  PropertyTemporal: ResolverTypeWrapper<TPropertyTemporal>;
  Provider: ResolverTypeWrapper<TProvider>;
  ProviderRole: TProviderRole;
  Query: ResolverTypeWrapper<{}>;
  QuotationErrorProductNotFoundOutput: ResolverTypeWrapper<TQuotationErrorProductNotFoundOutput>;
  QuotationItemOutput: ResolverTypeWrapper<TQuotationItemOutput>;
  QuotationOutput: ResolverTypeWrapper<TQuotationOutput>;
  QuotationProductOutput: ResolverTypeWrapper<TQuotationProductOutput>;
  QuotationUnion: TResolversTypes['QuoteOutput'] | TResolversTypes['ShopErrorBackendFailedOutput'] | TResolversTypes['ShopErrorNoOverlapWithProductOutput'] | TResolversTypes['ShopErrorOperationNotAllowedOutput'] | TResolversTypes['ShopErrorProductNotFoundOutput'] | TResolversTypes['ShopErrorProductPricingNotFoundOutput'];
  QuoteOutput: ResolverTypeWrapper<TQuoteOutput>;
  RecalculateGroupedAssetTourKeyframesInput: TRecalculateGroupedAssetTourKeyframesInput;
  ReferencedBoundsDimensionsOutput: ResolverTypeWrapper<TReferencedBoundsDimensionsOutput>;
  ReferencedBoundsGeolocationOutput: ResolverTypeWrapper<TReferencedBoundsGeolocationOutput>;
  ReferencedBoundsOutput: ResolverTypeWrapper<TReferencedBoundsOutput>;
  ReferrerOutput: ResolverTypeWrapper<TReferrerOutput>;
  RemoteDeviceIdentifiersGQL: TRemoteDeviceIdentifiersGql;
  RemoteJobIdentifierGQL: TRemoteJobIdentifierGql;
  RemoveCartItemInput: TRemoveCartItemInput;
  RemoveLabelsInput: TRemoveLabelsInput;
  RemoveLabelsUnion: TResolversTypes['LabelErrorOperationNotAllowedOutput'] | TResolversTypes['LabelsOutput'];
  Renderable: TResolversTypes['AddressAssetFileDownloadOutput'] | TResolversTypes['AddressDownloadOutput'] | TResolversTypes['AddressHspcOutput'] | TResolversTypes['AddressLtsOutput'] | TResolversTypes['AddressOgc3DOutput'] | TResolversTypes['AddressPanoramicOutput'] | TResolversTypes['AddressWfsOutput'] | TResolversTypes['AddressWmsOutput'] | TResolversTypes['AddressWmtsOutput'];
  ReportGenerationInput: TReportGenerationInput;
  ReportGenerationOutput: ResolverTypeWrapper<TReportGenerationOutput>;
  SavedFormatEnum: TSavedFormatEnum;
  ScanningDeviceType: TScanningDeviceType;
  SearchLabelsInput: TSearchLabelsInput;
  SearchLabelsUnion: TResolversTypes['LabelErrorOperationNotAllowedOutput'] | TResolversTypes['LabelsResultOutput'];
  SearchMyAssetsProjectUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['SearchOutput'];
  SearchOutput: ResolverTypeWrapper<TSearchOutput>;
  SearchProjectUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['SearchOutput'];
  SelectedSubscriptionTierOutput: ResolverTypeWrapper<TSelectedSubscriptionTierOutput>;
  SelectionInput: TSelectionInput;
  ServerError: ResolverTypeWrapper<TServerError>;
  SetActivePaymentCardInput: TSetActivePaymentCardInput;
  SetActivePaymentCardOutput: ResolverTypeWrapper<TSetActivePaymentCardOutput>;
  SetupPaymentCardCreationOutput: ResolverTypeWrapper<TSetupPaymentCardCreationOutput>;
  SharedGroupedAssetOutput: ResolverTypeWrapper<TSharedGroupedAssetOutput>;
  SharedGroupedAssetUnion: TResolversTypes['AcsErrorEntityNotFoundOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['SharedGroupedAssetOutput'];
  SharingCodeOutput: ResolverTypeWrapper<TSharingCodeOutput>;
  SharingCodeUnion: TResolversTypes['AcsErrorEntityNotFoundOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['SharingCodeOutput'];
  ShopErrorBackendFailedOutput: ResolverTypeWrapper<TShopErrorBackendFailedOutput>;
  ShopErrorNoOverlapWithProductOutput: ResolverTypeWrapper<TShopErrorNoOverlapWithProductOutput>;
  ShopErrorOperationNotAllowedOutput: ResolverTypeWrapper<TShopErrorOperationNotAllowedOutput>;
  ShopErrorProductNotFoundOutput: ResolverTypeWrapper<TShopErrorProductNotFoundOutput>;
  ShopErrorProductPricingNotFoundOutput: ResolverTypeWrapper<TShopErrorProductPricingNotFoundOutput>;
  ShopSupportedCountryValidationOutput: ResolverTypeWrapper<TShopSupportedCountryValidationOutput>;
  SignUpUserAccountAddressInput: TSignUpUserAccountAddressInput;
  SignUpUserAccountProfileInput: TSignUpUserAccountProfileInput;
  SignupFromInviteInput: TSignupFromInviteInput;
  SignupFromInviteUnion: TResolversTypes['AcsErrorInvalidInvitationSignatureOutput'] | TResolversTypes['AcsErrorSeatLimitReachedOutput'] | TResolversTypes['AcsErrorTooManyRequestsOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsOutput'] | TResolversTypes['SignupOutput'];
  SignupInput: TSignupInput;
  SignupOutput: ResolverTypeWrapper<TSignupOutput>;
  SignupUnion: TResolversTypes['AcsErrorInvalidVoucherOutput'] | TResolversTypes['AcsErrorTooManyRequestsOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsOutput'] | TResolversTypes['AcsErrorVoucherAlreadyUsedOutput'] | TResolversTypes['SignupOutput'];
  SimpleAccountInfoOutput: ResolverTypeWrapper<TSimpleAccountInfoOutput>;
  SimpleResultOutput: ResolverTypeWrapper<TSimpleResultOutput>;
  SimpleResultUnion: TResolversTypes['AcsErrorEntityNotFoundOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['SimpleResultOutput'];
  SimpleUserProfileOutput: ResolverTypeWrapper<TSimpleUserProfileOutput>;
  SimpleUserProfileUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedForTargetUserOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['SimpleUserProfileOutput'];
  StreamingUsageOutput: ResolverTypeWrapper<TStreamingUsageOutput>;
  String: ResolverTypeWrapper<Scalars['String']>;
  SubProcessGQL: ResolverTypeWrapper<TSubProcessGql>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionAddonOutput: ResolverTypeWrapper<TSubscriptionAddonOutput>;
  SubscriptionAddonTypeEnum: TSubscriptionAddonTypeEnum;
  SubscriptionErrorAccountNotSyncedOutput: ResolverTypeWrapper<TSubscriptionErrorAccountNotSyncedOutput>;
  SubscriptionErrorBadRequestOutput: ResolverTypeWrapper<TSubscriptionErrorBadRequestOutput>;
  SubscriptionErrorDuplicateWithSameAddonOutput: ResolverTypeWrapper<TSubscriptionErrorDuplicateWithSameAddonOutput>;
  SubscriptionErrorFeatureNotAvailableOutput: ResolverTypeWrapper<TSubscriptionErrorFeatureNotAvailableOutput>;
  SubscriptionErrorOperationNotAllowedOutput: ResolverTypeWrapper<TSubscriptionErrorOperationNotAllowedOutput>;
  SubscriptionInvoiceOutput: ResolverTypeWrapper<TSubscriptionInvoiceOutput>;
  SubscriptionInvoicesOutput: ResolverTypeWrapper<TSubscriptionInvoicesOutput>;
  SubscriptionInvoicesUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionInvoicesOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'];
  SubscriptionLimitEnum: TSubscriptionLimitEnum;
  SubscriptionLimitOutput: ResolverTypeWrapper<TSubscriptionLimitOutput>;
  SubscriptionLimitsChangeTypeEnum: TSubscriptionLimitsChangeTypeEnum;
  SubscriptionNoValidIsoCountryFoundOutput: ResolverTypeWrapper<TSubscriptionNoValidIsoCountryFoundOutput>;
  SubscriptionOrderItemOutput: ResolverTypeWrapper<TSubscriptionOrderItemOutput>;
  SubscriptionOrderOutput: ResolverTypeWrapper<TSubscriptionOrderOutput>;
  SubscriptionOrdersOutput: ResolverTypeWrapper<TSubscriptionOrdersOutput>;
  SubscriptionOrdersUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['SubscriptionOrdersOutput'];
  SubscriptionOutput: ResolverTypeWrapper<TSubscriptionOutput>;
  SubscriptionPaymentStatusEnum: TSubscriptionPaymentStatusEnum;
  SubscriptionPriceOutput: ResolverTypeWrapper<TSubscriptionPriceOutput>;
  SubscriptionProviderTypeEnum: TSubscriptionProviderTypeEnum;
  SubscriptionQuotationAddonInput: TSubscriptionQuotationAddonInput;
  SubscriptionQuotationAddonOutput: ResolverTypeWrapper<TSubscriptionQuotationAddonOutput>;
  SubscriptionQuotationInput: TSubscriptionQuotationInput;
  SubscriptionQuotationOutput: ResolverTypeWrapper<TSubscriptionQuotationOutput>;
  SubscriptionQuotationTierOutput: ResolverTypeWrapper<TSubscriptionQuotationTierOutput>;
  SubscriptionQuotationUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['SubscriptionQuotationOutput'];
  SubscriptionTierAddonOptionOutput: ResolverTypeWrapper<TSubscriptionTierAddonOptionOutput>;
  SubscriptionTierAddonOutput: ResolverTypeWrapper<TSubscriptionTierAddonOutput>;
  SubscriptionTierChangeTypeEnum: TSubscriptionTierChangeTypeEnum;
  SubscriptionTierOutput: ResolverTypeWrapper<TSubscriptionTierOutput>;
  SubscriptionTiersOutput: ResolverTypeWrapper<TSubscriptionTiersOutput>;
  SubscriptionTiersUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['SubscriptionTiersOutput'];
  SubscriptionTotalPriceOutput: ResolverTypeWrapper<TSubscriptionTotalPriceOutput>;
  SubscriptionUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorDuplicateWithSameAddonOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['SubscriptionOutput'];
  SubscriptionsOutput: ResolverTypeWrapper<TSubscriptionsOutput>;
  SubscriptionsUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['SubscriptionsOutput'];
  Summary: ResolverTypeWrapper<TSummary>;
  SummaryItemGQL: ResolverTypeWrapper<TSummaryItemGql>;
  SupportedCountryLocalizedNameOutput: ResolverTypeWrapper<TSupportedCountryLocalizedNameOutput>;
  SupportedCountryOutput: ResolverTypeWrapper<TSupportedCountryOutput>;
  TaskGQL: ResolverTypeWrapper<TTaskGql>;
  TenantDeletionOutput: ResolverTypeWrapper<TTenantDeletionOutput>;
  TenantDeletionStatus: TTenantDeletionStatus;
  TenantDeletionUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['TenantDeletionOutput'];
  TenantOutput: ResolverTypeWrapper<TTenantOutput>;
  TenantRoleEnum: TTenantRoleEnum;
  TenantUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['AcsErrorTenantNotFoundOutput'] | TResolversTypes['TenantOutput'];
  TenantsOutput: ResolverTypeWrapper<TTenantsOutput>;
  TenantsUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['TenantsOutput'];
  ThumbnailOutput: ResolverTypeWrapper<TThumbnailOutput>;
  TokenWalletOutput: ResolverTypeWrapper<TTokenWalletOutput>;
  TokensRefundInput: TTokensRefundInput;
  TokensRefundOutput: ResolverTypeWrapper<TTokensRefundOutput>;
  TokensTransactionActionTypeEnum: TTokensTransactionActionTypeEnum;
  TokensTransactionOutput: ResolverTypeWrapper<TTokensTransactionOutput>;
  TokensTransactionsOutput: ResolverTypeWrapper<TTokensTransactionsOutput>;
  TokensTransactionsPageInput: TTokensTransactionsPageInput;
  TokensTransactionsUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['TokensTransactionsOutput'];
  Tour: TResolversTypes['GroupedAssetTourOutput'];
  TourAppliedPageOutput: ResolverTypeWrapper<TTourAppliedPageOutput>;
  TourCameraViewpointCreateTourInput: TTourCameraViewpointCreateTourInput;
  TourCameraViewpointInput: TTourCameraViewpointInput;
  TourCameraViewpointOutput: ResolverTypeWrapper<TTourCameraViewpointOutput>;
  TourCameraViewpointUpdateInput: TTourCameraViewpointUpdateInput;
  TourFilterAppliedOutput: ResolverTypeWrapper<TTourFilterAppliedOutput>;
  TourFilterInput: TTourFilterInput;
  TourInput: TTourInput;
  TourKeyframe: TResolversTypes['GroupedAssetTourKeyframeOutput'];
  TourOrderEnum: TTourOrderEnum;
  TourPageInput: TTourPageInput;
  TriggerInput: TTriggerInput;
  TriggerMeshingInput: TTriggerMeshingInput;
  TriggerProcessingOutput: ResolverTypeWrapper<TTriggerProcessingOutput>;
  TriggerProcessingUnion: TResolversTypes['ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput'] | TResolversTypes['ProcessingErrorInternalServerErrorOutput'] | TResolversTypes['ProcessingErrorInvalidInputForProcessingPaymentErrorOutput'] | TResolversTypes['ProcessingErrorOperationNotAllowedOutput'] | TResolversTypes['ProcessingErrorProcessingLimitReachedOutput'] | TResolversTypes['ProcessingErrorResourceAccessDeniedOutput'] | TResolversTypes['ProcessingErrorResourceNotFoundOutput'] | TResolversTypes['ProcessingErrorStorageLimitReachedOutput'] | TResolversTypes['TriggerProcessingOutput'];
  UnauthenticatedError: ResolverTypeWrapper<TUnauthenticatedError>;
  UnsharedGroupedAssetOutput: ResolverTypeWrapper<TUnsharedGroupedAssetOutput>;
  UnsharedGroupedAssetUnion: TResolversTypes['AcsErrorEntityNotFoundOutput'] | TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorOperationNotPossibleOutput'] | TResolversTypes['UnsharedGroupedAssetOutput'];
  UpdateAppClientInput: TUpdateAppClientInput;
  UpdateApplicationTemplateInput: TUpdateApplicationTemplateInput;
  UpdateApplicationTemplateUnion: TResolversTypes['ApplicationErrorInvalidArgumentOutput'] | TResolversTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversTypes['ApplicationTemplateOutput'];
  UpdateAssetInput: TUpdateAssetInput;
  UpdateAssetUnion: TResolversTypes['AssetErrorDuplicateNameOutput'] | TResolversTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversTypes['AssetErrorNameBlankOutput'] | TResolversTypes['AssetErrorNameForbiddenOutput'] | TResolversTypes['AssetErrorNameForwardSlashOutput'] | TResolversTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['AssetErrorNameTrailingDotOutput'] | TResolversTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversTypes['AssetErrorOperationNotAllowedOutput'] | TResolversTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversTypes['AssetErrorTooManyRequestsOutput'] | TResolversTypes['GroupedAssetOutput'];
  UpdateCommentInput: TUpdateCommentInput;
  UpdateDeviceInput: TUpdateDeviceInput;
  UpdateFolderInput: TUpdateFolderInput;
  UpdateFolderUnion: TResolversTypes['FolderErrorDuplicateNameOutput'] | TResolversTypes['FolderErrorNameBlacklistedCharactersOutput'] | TResolversTypes['FolderErrorNameBlankOutput'] | TResolversTypes['FolderErrorNameForbiddenOutput'] | TResolversTypes['FolderErrorNameForwardSlashOutput'] | TResolversTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['FolderErrorNameTrailingDotOutput'] | TResolversTypes['FolderErrorNameTwoDotsInRowOutput'] | TResolversTypes['FolderErrorOperationNotAllowedOutput'] | TResolversTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversTypes['FolderErrorTooManyRequestsOutput'] | TResolversTypes['FolderOutput'];
  UpdateGeoreferenceInput: TUpdateGeoreferenceInput;
  UpdateGroupedAssetGeoreferenceInput: TUpdateGroupedAssetGeoreferenceInput;
  UpdateGroupedAssetTourInput: TUpdateGroupedAssetTourInput;
  UpdateGroupedAssetTourKeyframeInput: TUpdateGroupedAssetTourKeyframeInput;
  UpdateLabelAnnotationInput: TUpdateLabelAnnotationInput;
  UpdateLabelAnnotationInputV2: TUpdateLabelAnnotationInputV2;
  UpdateLabelAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['LabelAnnotationOutput'];
  UpdateLimitingBoxAnnotationInput: TUpdateLimitingBoxAnnotationInput;
  UpdateLimitingBoxAnnotationInputV2: TUpdateLimitingBoxAnnotationInputV2;
  UpdateLimitingBoxAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['LimitingBoxAnnotationOutput'];
  UpdateMeasurementAnnotationInput: TUpdateMeasurementAnnotationInput;
  UpdateMeasurementAnnotationInputV2: TUpdateMeasurementAnnotationInputV2;
  UpdateMeasurementAnnotationUnion: TResolversTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversTypes['MeasurementAnnotationOutput'];
  UpdateProjectInput: TUpdateProjectInput;
  UpdateProjectUnion: TResolversTypes['ProjectErrorDuplicateNameOutput'] | TResolversTypes['ProjectErrorInvalidInputOutput'] | TResolversTypes['ProjectErrorNameBlacklistedCharactersOutput'] | TResolversTypes['ProjectErrorNameBlankOutput'] | TResolversTypes['ProjectErrorNameForbiddenOutput'] | TResolversTypes['ProjectErrorNameForwardSlashOutput'] | TResolversTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] | TResolversTypes['ProjectErrorNameTrailingDotOutput'] | TResolversTypes['ProjectErrorNameTwoDotsInRowOutput'] | TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['ProjectOutput'];
  UpdateTenantInput: TUpdateTenantInput;
  UploadStatusEnum: TUploadStatusEnum;
  UploadThumbnailToProjectUnion: TResolversTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversTypes['ProjectErrorTooManyRequestsOutput'] | TResolversTypes['ProjectThumbnailOutput'];
  UploadedPartsInput: TUploadedPartsInput;
  UrlInput: TUrlInput;
  UsageAccountUnion: TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['UsageOutput'];
  UsageInput: TUsageInput;
  UsageOutput: ResolverTypeWrapper<TUsageOutput>;
  UsageTenantInput: TUsageTenantInput;
  UsageTenantOutput: ResolverTypeWrapper<TUsageTenantOutput>;
  UsageTenantUnion: TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['UsageTenantOutput'];
  UserDeletionOutput: ResolverTypeWrapper<TUserDeletionOutput>;
  UserDeletionStatusGQL: TUserDeletionStatusGql;
  UserDeletionUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorUserNotFoundOutput'] | TResolversTypes['UserDeletionOutput'];
  UserFlagEnum: TUserFlagEnum;
  UserFlagInput: TUserFlagInput;
  UserFlagOutput: ResolverTypeWrapper<TUserFlagOutput>;
  UserFlagSignupInput: TUserFlagSignupInput;
  UserInput: TUserInput;
  UserProfileAppliedPageOutput: ResolverTypeWrapper<TUserProfileAppliedPageOutput>;
  UserProfileOrderEnum: TUserProfileOrderEnum;
  UserProfileOutput: ResolverTypeWrapper<TUserProfileOutput>;
  UserProfilePageInput: TUserProfilePageInput;
  UserProfileResultOutput: ResolverTypeWrapper<TUserProfileResultOutput>;
  UserProjectRoleOutput: ResolverTypeWrapper<TUserProjectRoleOutput>;
  UserProjectRoleUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorProjectNotFoundOutput'] | TResolversTypes['ListOfUserProjectRoleOutput'];
  UserSettingsInput: TUserSettingsInput;
  UserSettingsLanguageEnum: TUserSettingsLanguageEnum;
  UserSettingsListLayoutEnum: TUserSettingsListLayoutEnum;
  UserSettingsMeasurementEnum: TUserSettingsMeasurementEnum;
  UserSettingsOutput: ResolverTypeWrapper<TUserSettingsOutput>;
  UserSettingsSignupInput: TUserSettingsSignupInput;
  UserUpdateInput: TUserUpdateInput;
  ValidationError: ResolverTypeWrapper<TValidationError>;
  ValidationErrorIssue: ResolverTypeWrapper<TValidationErrorIssue>;
  ValidationErrorIssueCode: TValidationErrorIssueCode;
  Vector3Input: TVector3Input;
  Vector3Output: ResolverTypeWrapper<TVector3Output>;
  VoucherOutput: ResolverTypeWrapper<TVoucherOutput>;
  VoucherTypeEnum: TVoucherTypeEnum;
  VoucherUnion: TResolversTypes['AcsErrorOperationNotAllowedOutput'] | TResolversTypes['AcsErrorSeatLimitReachedOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsInAccountOutput'] | TResolversTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] | TResolversTypes['VoucherOutput'];
  WFSInput: TWfsInput;
  WMSInput: TWmsInput;
  WMTSInput: TWmtsInput;
  WalletRefundUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorBadRequestOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['TokensRefundOutput'];
  WalletUnion: TResolversTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversTypes['TokenWalletOutput'];
  _Any: ResolverTypeWrapper<Scalars['_Any']>;
  _Entity: TResolversTypes['AccountInfoOutput'] | TResolversTypes['AccountUsageOutput'] | TResolversTypes['AddressDownloadOutput'] | TResolversTypes['AddressHspcOutput'] | TResolversTypes['AddressLtsOutput'] | TResolversTypes['AddressOgc3DOutput'] | TResolversTypes['AddressPageOutput'] | TResolversTypes['AddressPanoramicOutput'] | TResolversTypes['ApplicationTemplateOutput'] | TResolversTypes['AssetOutput'] | TResolversTypes['FileOutput'] | TResolversTypes['FolderOutput'] | TResolversTypes['GeoreferenceArtifactResultOutput'] | TResolversTypes['GroupedAssetOutput'] | TResolversTypes['JobPage'] | TResolversTypes['JobSummaryGQL'] | TResolversTypes['LabelAnnotationOutput'] | TResolversTypes['LimitingBoxAnnotationOutput'] | TResolversTypes['LuciadRIALicenseOutput'] | TResolversTypes['MeasurementAnnotationOutput'] | TResolversTypes['OrderGQL'] | TResolversTypes['OrderItemGQL'] | TResolversTypes['ProjectMemberOutput'] | TResolversTypes['ProjectOutput'] | TResolversTypes['SearchOutput'] | TResolversTypes['SimpleAccountInfoOutput'] | TResolversTypes['SimpleUserProfileOutput'] | TResolversTypes['UsageOutput'] | TResolversTypes['UsageTenantOutput'] | TResolversTypes['UserProfileOutput'];
};

/** Mapping between all available schema types and the resolvers parents */
export type TResolversParentTypes = {
  AccessDeniedError: TAccessDeniedError;
  AccountAddressInput: TAccountAddressInput;
  AccountAddressOutput: TAccountAddressOutput;
  AccountDetailOutput: TAccountDetailOutput;
  AccountDetailUnion: TResolversParentTypes['AccountDetailOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'];
  AccountInfoAccountAddressIsNotValidOutput: TAccountInfoAccountAddressIsNotValidOutput;
  AccountInfoAccountCompanyNameIsNotValidOutput: TAccountInfoAccountCompanyNameIsNotValidOutput;
  AccountInfoAccountEmailIsNotValidOutput: TAccountInfoAccountEmailIsNotValidOutput;
  AccountInfoAccountLegalEntityCouldNotBeChangedOutput: TAccountInfoAccountLegalEntityCouldNotBeChangedOutput;
  AccountInfoErrorOperationNotAllowedOutput: TAccountInfoErrorOperationNotAllowedOutput;
  AccountInfoOutput: TAccountInfoOutput;
  AccountInfoUpdateUnion: TResolversParentTypes['AccountInfoAccountAddressIsNotValidOutput'] | TResolversParentTypes['AccountInfoAccountCompanyNameIsNotValidOutput'] | TResolversParentTypes['AccountInfoAccountEmailIsNotValidOutput'] | TResolversParentTypes['AccountInfoAccountLegalEntityCouldNotBeChangedOutput'] | TResolversParentTypes['AccountInfoErrorOperationNotAllowedOutput'] | TResolversParentTypes['AccountInfoOutput'];
  AccountMemberInput: TAccountMemberInput;
  AccountMembersPageOutput: TAccountMembersPageOutput;
  AccountMembersResultOutput: TAccountMembersResultOutput;
  AccountMembersResultUnion: TResolversParentTypes['AccountMembersResultOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'];
  AccountProfileAppliedPageOutput: TAccountProfileAppliedPageOutput;
  AccountProfilePageInput: TAccountProfilePageInput;
  AccountProfileResultOutput: TAccountProfileResultOutput;
  AccountPropertiesInput: TAccountPropertiesInput;
  AccountUpdateInput: TAccountUpdateInput;
  AccountUsageInput: TAccountUsageInput;
  AccountUsageOutput: TAccountUsageOutput;
  AcsErrorEntityNotFoundOutput: TAcsErrorEntityNotFoundOutput;
  AcsErrorInvalidInvitationSignatureOutput: TAcsErrorInvalidInvitationSignatureOutput;
  AcsErrorInvalidVoucherOutput: TAcsErrorInvalidVoucherOutput;
  AcsErrorOperationNotAllowedOutput: TAcsErrorOperationNotAllowedOutput;
  AcsErrorOperationNotPossibleOutput: TAcsErrorOperationNotPossibleOutput;
  AcsErrorProjectNotFoundOutput: TAcsErrorProjectNotFoundOutput;
  AcsErrorSeatLimitReachedOutput: TAcsErrorSeatLimitReachedOutput;
  AcsErrorTenantNotFoundOutput: TAcsErrorTenantNotFoundOutput;
  AcsErrorTooManyRequestsOutput: TAcsErrorTooManyRequestsOutput;
  AcsErrorUserAlreadyExistsInAccountOutput: TAcsErrorUserAlreadyExistsInAccountOutput;
  AcsErrorUserAlreadyExistsInOtherAccountOutput: TAcsErrorUserAlreadyExistsInOtherAccountOutput;
  AcsErrorUserAlreadyExistsOutput: TAcsErrorUserAlreadyExistsOutput;
  AcsErrorUserNotFoundOutput: TAcsErrorUserNotFoundOutput;
  AcsErrorVoucherAlreadyUsedOutput: TAcsErrorVoucherAlreadyUsedOutput;
  AddCartItemInput: TAddCartItemInput;
  AddFileInput: TAddFileInput;
  AddFileUnion: TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['AssetErrorNameBlankOutput'] | TResolversParentTypes['AssetErrorNameForbiddenOutput'] | TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['AssetErrorNameTrailingDotOutput'] | TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] | TResolversParentTypes['FileOutput'];
  AddLabelsInput: TAddLabelsInput;
  AddLabelsUnion: TResolversParentTypes['LabelErrorOperationNotAllowedOutput'] | TResolversParentTypes['LabelErrorTooManyLabelsOutput'] | TResolversParentTypes['LabelsOutput'];
  AddressAssetFileDownloadOutput: TAddressAssetFileDownloadOutput;
  AddressDownloadOutput: TAddressDownloadOutput;
  AddressHspcOutput: TAddressHspcOutput;
  AddressLtsOutput: TAddressLtsOutput;
  AddressOgc3DOutput: TAddressOgc3DOutput;
  AddressOutput: TResolversParentTypes['AddressAssetFileDownloadOutput'] | TResolversParentTypes['AddressDownloadOutput'] | TResolversParentTypes['AddressHspcOutput'] | TResolversParentTypes['AddressLtsOutput'] | TResolversParentTypes['AddressOgc3DOutput'] | TResolversParentTypes['AddressPanoramicOutput'] | TResolversParentTypes['AddressWfsOutput'] | TResolversParentTypes['AddressWmsOutput'] | TResolversParentTypes['AddressWmtsOutput'];
  AddressPageOutput: Omit<TAddressPageOutput, 'contents'> & { contents: Array<TResolversParentTypes['AddressOutput']> };
  AddressPanoramicOutput: TAddressPanoramicOutput;
  AddressValidOutput: TAddressValidOutput;
  AddressWfsOutput: TAddressWfsOutput;
  AddressWmsOutput: TAddressWmsOutput;
  AddressWmtsOutput: TAddressWmtsOutput;
  AnnotationErrorOperationNotAllowedOutput: TAnnotationErrorOperationNotAllowedOutput;
  AnnotationOrientationInput: TAnnotationOrientationInput;
  AnnotationOrientationOutput: TAnnotationOrientationOutput;
  AnnotationPointInput: TAnnotationPointInput;
  AnnotationPointOutput: TAnnotationPointOutput;
  AnnotationRotationInput: TAnnotationRotationInput;
  AnnotationRotationOutput: TAnnotationRotationOutput;
  AnnotationThumbnailOutput: TAnnotationThumbnailOutput;
  AnnotationThumbnailUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['AnnotationThumbnailOutput'];
  AppClientOutput: TAppClientOutput;
  ApplicationErrorInvalidArgumentOutput: TApplicationErrorInvalidArgumentOutput;
  ApplicationErrorOperationNotAllowedOutput: TApplicationErrorOperationNotAllowedOutput;
  ApplicationErrorResourceAccessDeniedOutput: TApplicationErrorResourceAccessDeniedOutput;
  ApplicationErrorTooManyRequestsOutput: TApplicationErrorTooManyRequestsOutput;
  ApplicationTemplateAppliedPageOutput: TApplicationTemplateAppliedPageOutput;
  ApplicationTemplateOutput: TApplicationTemplateOutput;
  ApplicationTemplatePageInput: TApplicationTemplatePageInput;
  ApplicationTemplateResultOutput: TApplicationTemplateResultOutput;
  ApplicationTemplateUnion: TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversParentTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversParentTypes['ApplicationTemplateOutput'];
  ApplicationTemplatesInput: TApplicationTemplatesInput;
  ApplicationTemplatesUnion: TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversParentTypes['ApplicationTemplateResultOutput'];
  ArtifactAppliedPageOutput: TArtifactAppliedPageOutput;
  ArtifactItemOutput: TArtifactItemOutput;
  ArtifactPageInput: TArtifactPageInput;
  ArtifactPageableGQL: TArtifactPageableGql;
  ArtifactResultOutput: TArtifactResultOutput;
  ArtifactResultOutputV2: TArtifactResultOutputV2;
  AssetAppliedPageOutput: TAssetAppliedPageOutput;
  AssetBBoxInput: TAssetBBoxInput;
  AssetBBoxOutput: TAssetBBoxOutput;
  AssetCountOutput: TAssetCountOutput;
  AssetErrorCoarseRegistrationInputNotFound: TAssetErrorCoarseRegistrationInputNotFound;
  AssetErrorCoarseRegistrationLimitOutput: TAssetErrorCoarseRegistrationLimitOutput;
  AssetErrorDownloadLimitReachedOutput: TAssetErrorDownloadLimitReachedOutput;
  AssetErrorDuplicateNameOutput: TAssetErrorDuplicateNameOutput;
  AssetErrorFeatureNotAvailableOutput: TAssetErrorFeatureNotAvailableOutput;
  AssetErrorGroupedAssetRegistrationLimitOutput: TAssetErrorGroupedAssetRegistrationLimitOutput;
  AssetErrorGroupedAssetsNotUnderSameFolderOutput: TAssetErrorGroupedAssetsNotUnderSameFolderOutput;
  AssetErrorNameBlacklistedCharactersOutput: TAssetErrorNameBlacklistedCharactersOutput;
  AssetErrorNameBlankOutput: TAssetErrorNameBlankOutput;
  AssetErrorNameForbiddenOutput: TAssetErrorNameForbiddenOutput;
  AssetErrorNameForwardSlashOutput: TAssetErrorNameForwardSlashOutput;
  AssetErrorNameLeadingOrTrailingSpaceOutput: TAssetErrorNameLeadingOrTrailingSpaceOutput;
  AssetErrorNameTrailingDotOutput: TAssetErrorNameTrailingDotOutput;
  AssetErrorNameTwoDotsInRowOutput: TAssetErrorNameTwoDotsInRowOutput;
  AssetErrorOperationNotAllowedOutput: TAssetErrorOperationNotAllowedOutput;
  AssetErrorProcessingLimitReachedOutput: TAssetErrorProcessingLimitReachedOutput;
  AssetErrorResourceAccessDeniedOutput: TAssetErrorResourceAccessDeniedOutput;
  AssetErrorStorageLimitReachedOutput: TAssetErrorStorageLimitReachedOutput;
  AssetErrorTooManyRequestsOutput: TAssetErrorTooManyRequestsOutput;
  AssetOutput: TAssetOutput;
  AssetPageInput: TAssetPageInput;
  AssetThumbnailOutput: TAssetThumbnailOutput;
  AssetUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  AssetsInAccountUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['GroupedAssetResultOutput'];
  AssetsInFolderUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['FolderOutput'];
  AssignAccountToTenantInput: TAssignAccountToTenantInput;
  AssignTenantRoleToUserInput: TAssignTenantRoleToUserInput;
  AutoRegisterAssetsUnion: TResolversParentTypes['AssetErrorCoarseRegistrationInputNotFound'] | TResolversParentTypes['AssetErrorCoarseRegistrationLimitOutput'] | TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorFeatureNotAvailableOutput'] | TResolversParentTypes['AssetErrorGroupedAssetRegistrationLimitOutput'] | TResolversParentTypes['AssetErrorGroupedAssetsNotUnderSameFolderOutput'] | TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['AssetErrorNameBlankOutput'] | TResolversParentTypes['AssetErrorNameForbiddenOutput'] | TResolversParentTypes['AssetErrorNameForwardSlashOutput'] | TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['AssetErrorNameTrailingDotOutput'] | TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorProcessingLimitReachedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  AutoRegistrationContentInput: TAutoRegistrationContentInput;
  AutoRegistrationInput: TAutoRegistrationInput;
  AutoRegistrationInputV2: TAutoRegistrationInputV2;
  BaseAccountInfoErrorInterface: TResolversParentTypes['AccountInfoAccountAddressIsNotValidOutput'] | TResolversParentTypes['AccountInfoAccountCompanyNameIsNotValidOutput'] | TResolversParentTypes['AccountInfoAccountEmailIsNotValidOutput'] | TResolversParentTypes['AccountInfoAccountLegalEntityCouldNotBeChangedOutput'] | TResolversParentTypes['AccountInfoErrorOperationNotAllowedOutput'];
  BaseAcsErrorInterface: TResolversParentTypes['AcsErrorEntityNotFoundOutput'] | TResolversParentTypes['AcsErrorInvalidInvitationSignatureOutput'] | TResolversParentTypes['AcsErrorInvalidVoucherOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['AcsErrorProjectNotFoundOutput'] | TResolversParentTypes['AcsErrorSeatLimitReachedOutput'] | TResolversParentTypes['AcsErrorTenantNotFoundOutput'] | TResolversParentTypes['AcsErrorTooManyRequestsOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsInAccountOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsOutput'] | TResolversParentTypes['AcsErrorUserNotFoundOutput'] | TResolversParentTypes['AcsErrorVoucherAlreadyUsedOutput'];
  BaseAnnotationErrorInterface: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'];
  BaseApplicationErrorInterface: TResolversParentTypes['ApplicationErrorInvalidArgumentOutput'] | TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversParentTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'];
  BaseAssetErrorInterface: TResolversParentTypes['AssetErrorCoarseRegistrationInputNotFound'] | TResolversParentTypes['AssetErrorCoarseRegistrationLimitOutput'] | TResolversParentTypes['AssetErrorDownloadLimitReachedOutput'] | TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorFeatureNotAvailableOutput'] | TResolversParentTypes['AssetErrorGroupedAssetRegistrationLimitOutput'] | TResolversParentTypes['AssetErrorGroupedAssetsNotUnderSameFolderOutput'] | TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['AssetErrorNameBlankOutput'] | TResolversParentTypes['AssetErrorNameForbiddenOutput'] | TResolversParentTypes['AssetErrorNameForwardSlashOutput'] | TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['AssetErrorNameTrailingDotOutput'] | TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorProcessingLimitReachedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'];
  BaseFolderErrorInterface: TResolversParentTypes['FolderErrorDuplicateNameOutput'] | TResolversParentTypes['FolderErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['FolderErrorNameBlankOutput'] | TResolversParentTypes['FolderErrorNameForbiddenOutput'] | TResolversParentTypes['FolderErrorNameForwardSlashOutput'] | TResolversParentTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['FolderErrorNameTrailingDotOutput'] | TResolversParentTypes['FolderErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['FolderErrorOperationNotAllowedOutput'] | TResolversParentTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversParentTypes['FolderErrorResourceNotFoundOutput'] | TResolversParentTypes['FolderErrorTooManyRequestsOutput'];
  BaseLabelErrorInterface: TResolversParentTypes['LabelErrorOperationNotAllowedOutput'] | TResolversParentTypes['LabelErrorTooManyLabelsOutput'];
  BaseLicenseErrorInterface: TResolversParentTypes['LicenseErrorInvalidArgumentOutput'] | TResolversParentTypes['LicenseErrorLicenseNotAvailableOutput'] | TResolversParentTypes['LicenseErrorOperationNotAllowedOutput'] | TResolversParentTypes['LicenseErrorTooManyRequestsOutput'];
  BaseProcessingErrorInterface: TResolversParentTypes['ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput'] | TResolversParentTypes['ProcessingErrorInternalServerErrorOutput'] | TResolversParentTypes['ProcessingErrorInvalidInputForProcessingPaymentErrorOutput'] | TResolversParentTypes['ProcessingErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProcessingErrorProcessingLimitReachedOutput'] | TResolversParentTypes['ProcessingErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProcessingErrorResourceNotFoundOutput'] | TResolversParentTypes['ProcessingErrorStorageLimitReachedOutput'];
  BaseProjectErrorInterface: TResolversParentTypes['ProjectErrorDuplicateNameOutput'] | TResolversParentTypes['ProjectErrorInvalidInputOutput'] | TResolversParentTypes['ProjectErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['ProjectErrorNameBlankOutput'] | TResolversParentTypes['ProjectErrorNameForbiddenOutput'] | TResolversParentTypes['ProjectErrorNameForwardSlashOutput'] | TResolversParentTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['ProjectErrorNameTrailingDotOutput'] | TResolversParentTypes['ProjectErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedForTargetUserOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'];
  BaseQuotationErrorInterface: TResolversParentTypes['QuotationErrorProductNotFoundOutput'];
  BaseShopErrorInterface: TResolversParentTypes['ShopErrorBackendFailedOutput'] | TResolversParentTypes['ShopErrorNoOverlapWithProductOutput'] | TResolversParentTypes['ShopErrorOperationNotAllowedOutput'] | TResolversParentTypes['ShopErrorProductNotFoundOutput'] | TResolversParentTypes['ShopErrorProductPricingNotFoundOutput'];
  BaseSubscriptionErrorInterface: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorBadRequestOutput'] | TResolversParentTypes['SubscriptionErrorDuplicateWithSameAddonOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'];
  BaselayerAppliedPageOutput: TBaselayerAppliedPageOutput;
  BaselayerHspcOutput: TBaselayerHspcOutput;
  BaselayerLtsOutput: TBaselayerLtsOutput;
  BaselayerOgc3DOutput: TBaselayerOgc3DOutput;
  BaselayerOutput: TResolversParentTypes['BaselayerHspcOutput'] | TResolversParentTypes['BaselayerLtsOutput'] | TResolversParentTypes['BaselayerOgc3DOutput'] | TResolversParentTypes['BaselayerPanoramicOutput'] | TResolversParentTypes['BaselayerUrlOutput'] | TResolversParentTypes['BaselayerWfsOutput'] | TResolversParentTypes['BaselayerWmsOutput'] | TResolversParentTypes['BaselayerWmtsOutput'];
  BaselayerPageInput: TBaselayerPageInput;
  BaselayerPanoramicOutput: TBaselayerPanoramicOutput;
  BaselayerResultOutput: Omit<TBaselayerResultOutput, 'contents'> & { contents: Array<TResolversParentTypes['BaselayerOutput']> };
  BaselayerUrlOutput: TBaselayerUrlOutput;
  BaselayerWfsOutput: TBaselayerWfsOutput;
  BaselayerWmsOutput: TBaselayerWmsOutput;
  BaselayerWmtsOutput: TBaselayerWmtsOutput;
  BigDecimal: Scalars['BigDecimal'];
  BigInteger: Scalars['BigInteger'];
  Boolean: Scalars['Boolean'];
  CancelCurrentSubscriptionOutput: TCancelCurrentSubscriptionOutput;
  CancelCurrentSubscriptionUnion: TResolversParentTypes['CancelCurrentSubscriptionOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'];
  CartItemOutput: TCartItemOutput;
  CartItemThumbnailInput: TCartItemThumbnailInput;
  CartItemThumbnailOutput: TCartItemThumbnailOutput;
  CartOutput: TCartOutput;
  CartesianBoundsOutput: TCartesianBoundsOutput;
  ChangeProjectRoleOutput: TChangeProjectRoleOutput;
  ChangeProjectRoleUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['AcsErrorSeatLimitReachedOutput'] | TResolversParentTypes['ChangeProjectRoleOutput'];
  CheckoutOutput: TCheckoutOutput;
  Collection: TCollection;
  Comment: TComment;
  CommentAnchor: TCommentAnchor;
  CommentAnchorInput: TCommentAnchorInput;
  CommentConnection: TCommentConnection;
  CommentEdge: TCommentEdge;
  CommentPaginationInput: TCommentPaginationInput;
  CommentPayload: TResolversParentTypes['AccessDeniedError'] | TResolversParentTypes['Comment'] | TResolversParentTypes['NotFoundError'] | TResolversParentTypes['ServerError'] | TResolversParentTypes['UnauthenticatedError'] | TResolversParentTypes['ValidationError'];
  CommentsPayload: TResolversParentTypes['AccessDeniedError'] | TResolversParentTypes['CommentConnection'] | TResolversParentTypes['ServerError'] | TResolversParentTypes['UnauthenticatedError'] | TResolversParentTypes['ValidationError'];
  ConfirmSubscriptionOutput: TConfirmSubscriptionOutput;
  ConfirmSubscriptionUnion: TResolversParentTypes['ConfirmSubscriptionOutput'];
  Connection: TResolversParentTypes['CommentConnection'];
  CoordinateSystemOutput: TCoordinateSystemOutput;
  CopyAssetInput: TCopyAssetInput;
  CopyAssetToFolderUnion: TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  CoreBaselayer: TResolversParentTypes['BaselayerHspcOutput'] | TResolversParentTypes['BaselayerLtsOutput'] | TResolversParentTypes['BaselayerOgc3DOutput'] | TResolversParentTypes['BaselayerPanoramicOutput'] | TResolversParentTypes['BaselayerUrlOutput'] | TResolversParentTypes['BaselayerWfsOutput'] | TResolversParentTypes['BaselayerWmsOutput'] | TResolversParentTypes['BaselayerWmtsOutput'];
  CoreLayer: TResolversParentTypes['LayerAddressHspc'] | TResolversParentTypes['LayerAddressLts'] | TResolversParentTypes['LayerAddressOgc3DTiles'] | TResolversParentTypes['LayerAddressWms'] | TResolversParentTypes['LayerAddressWmts'];
  CountryOutput: TCountryOutput;
  CreateAppClientInput: TCreateAppClientInput;
  CreateApplicationTemplateInput: TCreateApplicationTemplateInput;
  CreateApplicationTemplateUnion: TResolversParentTypes['ApplicationErrorInvalidArgumentOutput'] | TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversParentTypes['ApplicationTemplateOutput'];
  CreateAssetInput: TCreateAssetInput;
  CreateAssetInputV3: TCreateAssetInputV3;
  CreateAssetThumbnailUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['AssetThumbnailOutput'];
  CreateAssetUnion: TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['AssetErrorNameBlankOutput'] | TResolversParentTypes['AssetErrorNameForbiddenOutput'] | TResolversParentTypes['AssetErrorNameForwardSlashOutput'] | TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['AssetErrorNameTrailingDotOutput'] | TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  CreateCartInput: TCreateCartInput;
  CreateCommentInput: TCreateCommentInput;
  CreateFolderInput: TCreateFolderInput;
  CreateFolderUnion: TResolversParentTypes['FolderErrorDuplicateNameOutput'] | TResolversParentTypes['FolderErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['FolderErrorNameBlankOutput'] | TResolversParentTypes['FolderErrorNameForbiddenOutput'] | TResolversParentTypes['FolderErrorNameForwardSlashOutput'] | TResolversParentTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['FolderErrorNameTrailingDotOutput'] | TResolversParentTypes['FolderErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['FolderErrorOperationNotAllowedOutput'] | TResolversParentTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversParentTypes['FolderErrorTooManyRequestsOutput'] | TResolversParentTypes['FolderOutput'];
  CreateGeoreferenceInput: TCreateGeoreferenceInput;
  CreateGroupedAssetGeoreferenceInput: TCreateGroupedAssetGeoreferenceInput;
  CreateGroupedAssetTourInput: TCreateGroupedAssetTourInput;
  CreateGroupedAssetTourKeyframeInput: TCreateGroupedAssetTourKeyframeInput;
  CreateGroupedAssetTourThumbnailInput: TCreateGroupedAssetTourThumbnailInput;
  CreateLabelAnnotationInput: TCreateLabelAnnotationInput;
  CreateLabelAnnotationInputV2: TCreateLabelAnnotationInputV2;
  CreateLabelAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['LabelAnnotationOutput'];
  CreateLimitingBoxAnnotationInput: TCreateLimitingBoxAnnotationInput;
  CreateLimitingBoxAnnotationInputV2: TCreateLimitingBoxAnnotationInputV2;
  CreateLimitingBoxAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['LimitingBoxAnnotationOutput'];
  CreateMeasurementAnnotationInput: TCreateMeasurementAnnotationInput;
  CreateMeasurementAnnotationInputV2: TCreateMeasurementAnnotationInputV2;
  CreateMeasurementAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['MeasurementAnnotationOutput'];
  CreateProjectInput: TCreateProjectInput;
  CreateProjectUnion: TResolversParentTypes['ProjectErrorDuplicateNameOutput'] | TResolversParentTypes['ProjectErrorInvalidInputOutput'] | TResolversParentTypes['ProjectErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['ProjectErrorNameBlankOutput'] | TResolversParentTypes['ProjectErrorNameForbiddenOutput'] | TResolversParentTypes['ProjectErrorNameForwardSlashOutput'] | TResolversParentTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['ProjectErrorNameTrailingDotOutput'] | TResolversParentTypes['ProjectErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['ProjectOutput'];
  CreateTenantInput: TCreateTenantInput;
  CreationSubscriptionInput: TCreationSubscriptionInput;
  CustomMetricEventGQL: TCustomMetricEventGql;
  CustomMetricGQL: TCustomMetricGql;
  DateTime: Scalars['DateTime'];
  DeleteAnnotationOutput: TDeleteAnnotationOutput;
  DeleteAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['DeleteAnnotationOutput'];
  DeleteApplicationTemplateOutput: TDeleteApplicationTemplateOutput;
  DeleteApplicationTemplateUnion: TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversParentTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversParentTypes['DeleteApplicationTemplateOutput'];
  DeleteAssetOutput: TDeleteAssetOutput;
  DeleteAssetThumbnailOutput: TDeleteAssetThumbnailOutput;
  DeleteAssetThumbnailUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['DeleteAssetThumbnailOutput'];
  DeleteAssetUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['DeleteAssetOutput'];
  DeleteCommentInput: TDeleteCommentInput;
  DeleteFolderOutput: TDeleteFolderOutput;
  DeleteFolderUnion: TResolversParentTypes['DeleteFolderOutput'] | TResolversParentTypes['FolderErrorOperationNotAllowedOutput'] | TResolversParentTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversParentTypes['FolderErrorResourceNotFoundOutput'] | TResolversParentTypes['FolderErrorTooManyRequestsOutput'];
  DeleteGroupedAssetTourInput: TDeleteGroupedAssetTourInput;
  DeleteGroupedAssetTourKeyframeInput: TDeleteGroupedAssetTourKeyframeInput;
  DeleteGroupedAssetTourThumbnailInput: TDeleteGroupedAssetTourThumbnailInput;
  DeleteProjectOutput: TDeleteProjectOutput;
  DeleteProjectThumbnailOutput: TDeleteProjectThumbnailOutput;
  DeleteProjectThumbnailUnion: TResolversParentTypes['DeleteProjectThumbnailOutput'] | TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'];
  DeleteProjectUnion: TResolversParentTypes['DeleteProjectOutput'] | TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'];
  DeleteTenantInput: TDeleteTenantInput;
  DeletedPaymentCardInput: TDeletedPaymentCardInput;
  DeletedPaymentCardOutput: TDeletedPaymentCardOutput;
  DeviceAppliedPageOutput: TDeviceAppliedPageOutput;
  DeviceOutput: TDeviceOutput;
  DeviceResultOutput: TDeviceResultOutput;
  Edge: TResolversParentTypes['CommentEdge'];
  EntityReferenceInput: TEntityReferenceInput;
  EntityReferenceOutput: TEntityReferenceOutput;
  Error: TResolversParentTypes['AccessDeniedError'] | TResolversParentTypes['NotFoundError'] | TResolversParentTypes['ServerError'] | TResolversParentTypes['UnauthenticatedError'] | TResolversParentTypes['ValidationError'];
  ExceededLimitOutput: TExceededLimitOutput;
  ExecutedOutput: TExecutedOutput;
  Extent: TExtent;
  ExternalAccountMembersResultUnion: TResolversParentTypes['AccountMembersResultOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'];
  ExternalProjectMemberInput: TExternalProjectMemberInput;
  Feature: TFeature;
  FeatureAsset: TFeatureAsset;
  FeatureAssets: TFeatureAssets;
  FeatureItem: TFeatureItem;
  FeatureProperties: Omit<TFeatureProperties, 'layerAddress'> & { layerAddress?: Maybe<TResolversParentTypes['LayerAddress']> };
  FileAppliedPageOutput: TFileAppliedPageOutput;
  FileFilterAppliedOutput: TFileFilterAppliedOutput;
  FileOutput: TFileOutput;
  FilePageInput: TFilePageInput;
  FileResultOutput: TFileResultOutput;
  FileStatusOutput: TFileStatusOutput;
  FilterAccountMembersInput: TFilterAccountMembersInput;
  FilterAccountMembersOutput: TFilterAccountMembersOutput;
  FilterAccountProfileAppliedOutput: TFilterAccountProfileAppliedOutput;
  FilterAccountProfileInput: TFilterAccountProfileInput;
  FilterApplicationAppliedOutput: TFilterApplicationAppliedOutput;
  FilterApplicationInput: TFilterApplicationInput;
  FilterAssetAppliedOutput: TFilterAssetAppliedOutput;
  FilterAssetInput: TFilterAssetInput;
  FilterAssetNotInput: TFilterAssetNotInput;
  FilterAssetNotOutput: TFilterAssetNotOutput;
  FilterAssetOrInput: TFilterAssetOrInput;
  FilterAssetOrOutput: TFilterAssetOrOutput;
  FilterFileInput: TFilterFileInput;
  FilterFolderAppliedOutput: TFilterFolderAppliedOutput;
  FilterFolderInput: TFilterFolderInput;
  FilterProjectAppliedOutput: TFilterProjectAppliedOutput;
  FilterProjectInput: TFilterProjectInput;
  FilterUserProfileAppliedOutput: TFilterUserProfileAppliedOutput;
  FilterUserProfileInput: TFilterUserProfileInput;
  Float: Scalars['Float'];
  FolderAppliedPageOutput: TFolderAppliedPageOutput;
  FolderErrorDuplicateNameOutput: TFolderErrorDuplicateNameOutput;
  FolderErrorNameBlacklistedCharactersOutput: TFolderErrorNameBlacklistedCharactersOutput;
  FolderErrorNameBlankOutput: TFolderErrorNameBlankOutput;
  FolderErrorNameForbiddenOutput: TFolderErrorNameForbiddenOutput;
  FolderErrorNameForwardSlashOutput: TFolderErrorNameForwardSlashOutput;
  FolderErrorNameLeadingOrTrailingSpaceOutput: TFolderErrorNameLeadingOrTrailingSpaceOutput;
  FolderErrorNameTrailingDotOutput: TFolderErrorNameTrailingDotOutput;
  FolderErrorNameTwoDotsInRowOutput: TFolderErrorNameTwoDotsInRowOutput;
  FolderErrorOperationNotAllowedOutput: TFolderErrorOperationNotAllowedOutput;
  FolderErrorResourceAccessDeniedOutput: TFolderErrorResourceAccessDeniedOutput;
  FolderErrorResourceNotFoundOutput: TFolderErrorResourceNotFoundOutput;
  FolderErrorTooManyRequestsOutput: TFolderErrorTooManyRequestsOutput;
  FolderOutput: TFolderOutput;
  FolderPageInput: TFolderPageInput;
  FolderResultOutput: TFolderResultOutput;
  FolderUnion: TResolversParentTypes['FolderErrorOperationNotAllowedOutput'] | TResolversParentTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversParentTypes['FolderErrorResourceNotFoundOutput'] | TResolversParentTypes['FolderErrorTooManyRequestsOutput'] | TResolversParentTypes['FolderOutput'];
  GatewayInfoOutput: TGatewayInfoOutput;
  GenerateInvitationUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorSeatLimitReachedOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsInAccountOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] | TResolversParentTypes['InvitationOutput'];
  GenerateInviteInput: TGenerateInviteInput;
  GenerateMultiAssetDownloadLinkUnion: TResolversParentTypes['AssetErrorDownloadLimitReachedOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['MultiAssetDownloadLinkOutput'];
  GenerateVoucherInput: TGenerateVoucherInput;
  GeoJSONShape: TGeoJsonShape;
  GeoJSONShapeInput: TGeoJsonShapeInput;
  GeoreferenceAppliedPageOutput: TGeoreferenceAppliedPageOutput;
  GeoreferenceAppliedPaginationOutput: TGeoreferenceAppliedPaginationOutput;
  GeoreferenceArtifactResultOutput: TGeoreferenceArtifactResultOutput;
  GeoreferenceOutput: TGeoreferenceOutput;
  GeoreferencePageInput: TGeoreferencePageInput;
  GeoreferencePaginationInput: TGeoreferencePaginationInput;
  GeoreferenceResultsOutput: TGeoreferenceResultsOutput;
  GeoreferenceVisibilityInput: TGeoreferenceVisibilityInput;
  GeoreferenceVisibilityOutput: TGeoreferenceVisibilityOutput;
  GetGroupedAssetTourInput: TGetGroupedAssetTourInput;
  GetProjectsInput: TGetProjectsInput;
  GroupedAssetGeoreferenceOutput: TGroupedAssetGeoreferenceOutput;
  GroupedAssetGeoreferenceResultsOutput: TGroupedAssetGeoreferenceResultsOutput;
  GroupedAssetGeoreferenceVisibilityInput: TGroupedAssetGeoreferenceVisibilityInput;
  GroupedAssetOutput: TGroupedAssetOutput;
  GroupedAssetPropertiesCreateTourInput: TGroupedAssetPropertiesCreateTourInput;
  GroupedAssetPropertiesInput: TGroupedAssetPropertiesInput;
  GroupedAssetPropertiesOutput: TGroupedAssetPropertiesOutput;
  GroupedAssetPropertiesUpdateInput: TGroupedAssetPropertiesUpdateInput;
  GroupedAssetResultOutput: TGroupedAssetResultOutput;
  GroupedAssetTourKeyframeCreateTourInput: TGroupedAssetTourKeyframeCreateTourInput;
  GroupedAssetTourKeyframeInput: TGroupedAssetTourKeyframeInput;
  GroupedAssetTourKeyframeOutput: TGroupedAssetTourKeyframeOutput;
  GroupedAssetTourKeyframeUpdateInput: TGroupedAssetTourKeyframeUpdateInput;
  GroupedAssetTourOutput: TGroupedAssetTourOutput;
  GroupedAssetTourResultOutput: TGroupedAssetTourResultOutput;
  HSPCInput: THspcInput;
  HXDRProductOutput: TResolversParentTypes['PointCloudSourceLidarOutput'] | TResolversParentTypes['Product2DAerialOutput'];
  ID: Scalars['ID'];
  InfoGQL: TInfoGql;
  InfoPage: TInfoPage;
  Int: Scalars['Int'];
  InvitationOutput: TInvitationOutput;
  ItemCollection: TItemCollection;
  JSON: Scalars['JSON'];
  JobDetailPageableGQL: TJobDetailPageableGql;
  JobPage: TJobPage;
  JobSummaryGQL: TJobSummaryGql;
  LTSInput: TLtsInput;
  LabelAnnotationDataInput: TLabelAnnotationDataInput;
  LabelAnnotationDataInputV2: TLabelAnnotationDataInputV2;
  LabelAnnotationDataOutput: TLabelAnnotationDataOutput;
  LabelAnnotationOutput: TLabelAnnotationOutput;
  LabelErrorOperationNotAllowedOutput: TLabelErrorOperationNotAllowedOutput;
  LabelErrorTooManyLabelsOutput: TLabelErrorTooManyLabelsOutput;
  LabelInput: TLabelInput;
  LabelOutput: TLabelOutput;
  LabelsOutput: TLabelsOutput;
  LabelsResultOutput: TLabelsResultOutput;
  LayerAddress: TResolversParentTypes['LayerAddressHspc'] | TResolversParentTypes['LayerAddressLts'] | TResolversParentTypes['LayerAddressOgc3DTiles'] | TResolversParentTypes['LayerAddressWms'] | TResolversParentTypes['LayerAddressWmts'];
  LayerAddressHspc: TLayerAddressHspc;
  LayerAddressLts: TLayerAddressLts;
  LayerAddressOgc3DTiles: TLayerAddressOgc3DTiles;
  LayerAddressWms: TLayerAddressWms;
  LayerAddressWmts: TLayerAddressWmts;
  LicenseErrorInvalidArgumentOutput: TLicenseErrorInvalidArgumentOutput;
  LicenseErrorLicenseNotAvailableOutput: TLicenseErrorLicenseNotAvailableOutput;
  LicenseErrorOperationNotAllowedOutput: TLicenseErrorOperationNotAllowedOutput;
  LicenseErrorTooManyRequestsOutput: TLicenseErrorTooManyRequestsOutput;
  LimitToApplyInput: TLimitToApplyInput;
  LimitToApplyOutput: TLimitToApplyOutput;
  LimitValidityInput: TLimitValidityInput;
  LimitingBoxAnnotationDataInput: TLimitingBoxAnnotationDataInput;
  LimitingBoxAnnotationDataInputV2: TLimitingBoxAnnotationDataInputV2;
  LimitingBoxAnnotationDataOutput: TLimitingBoxAnnotationDataOutput;
  LimitingBoxAnnotationOutput: TLimitingBoxAnnotationOutput;
  LinkDeviceInput: TLinkDeviceInput;
  ListOfUserProjectRoleOutput: TListOfUserProjectRoleOutput;
  LocalizedNameOutput: TLocalizedNameOutput;
  LuciadRIALicenseOutput: TLuciadRiaLicenseOutput;
  LuciadRIALicenseUnion: TResolversParentTypes['LicenseErrorInvalidArgumentOutput'] | TResolversParentTypes['LicenseErrorLicenseNotAvailableOutput'] | TResolversParentTypes['LicenseErrorOperationNotAllowedOutput'] | TResolversParentTypes['LuciadRIALicenseOutput'];
  MeasurementAnnotationDataInput: TMeasurementAnnotationDataInput;
  MeasurementAnnotationDataInputV2: TMeasurementAnnotationDataInputV2;
  MeasurementAnnotationDataOutput: TMeasurementAnnotationDataOutput;
  MeasurementAnnotationOutput: TMeasurementAnnotationOutput;
  MoveAssetInput: TMoveAssetInput;
  MoveAssetToFolderUnion: TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  MultiAssetDownloadLinkInput: TMultiAssetDownloadLinkInput;
  MultiAssetDownloadLinkOutput: TMultiAssetDownloadLinkOutput;
  MultipartUploadCompleteInput: TMultipartUploadCompleteInput;
  MultipartUploadETagInput: TMultipartUploadETagInput;
  MultipartUploadETagOutput: TMultipartUploadETagOutput;
  MultipartUploadPartsOutput: TMultipartUploadPartsOutput;
  MultipartUploadUrlInput: TMultipartUploadUrlInput;
  MultipartUploadUrlOutput: TMultipartUploadUrlOutput;
  MultipartUploadUrlUnion: TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] | TResolversParentTypes['MultipartUploadUrlOutput'];
  Mutation: {};
  MyAssetsProjectUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['ProjectOutput'];
  Node: TResolversParentTypes['Comment'] | TResolversParentTypes['ProjectChangeFolderCopyOperation'] | TResolversParentTypes['ProjectChangeFolderCreateOperation'] | TResolversParentTypes['ProjectChangeFolderDeleteOperation'] | TResolversParentTypes['ProjectChangeFolderMoveOperation'] | TResolversParentTypes['ProjectChangeFolderUpdateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCopyOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCreateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetDeleteOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetMoveOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetUpdateOperation'] | TResolversParentTypes['ProjectChangeOutput'] | TResolversParentTypes['ProjectChangeProjectUpdateOperation'] | TResolversParentTypes['ProjectChangeUserAccessGrantedOperation'] | TResolversParentTypes['ProjectChangeUserAccessRevokedOperation'];
  NotFoundError: TNotFoundError;
  OGC3DInput: TOgc3DInput;
  OrderAddressInput: TOrderAddressInput;
  OrderGQL: TOrderGql;
  OrderItemGQL: TOrderItemGql;
  PageInfo: TPageInfo;
  PanoramicInput: TPanoramicInput;
  PaymentCardAddressInput: TPaymentCardAddressInput;
  PaymentCardAddressOutput: TPaymentCardAddressOutput;
  PaymentCardInput: TPaymentCardInput;
  PaymentCardOutput: TPaymentCardOutput;
  PaymentSystemOutput: TPaymentSystemOutput;
  PersistArtifactInput: TPersistArtifactInput;
  PersistAssetInput: TPersistAssetInput;
  PersistAssetUnion: TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['AssetErrorNameBlankOutput'] | TResolversParentTypes['AssetErrorNameForbiddenOutput'] | TResolversParentTypes['AssetErrorNameForwardSlashOutput'] | TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['AssetErrorNameTrailingDotOutput'] | TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  PlaceOrderInput: TPlaceOrderInput;
  Point3DInput: TPoint3DInput;
  Point3DOutput: TPoint3DOutput;
  PointCloudSourceLidarOutput: TPointCloudSourceLidarOutput;
  PolygonOutput: TPolygonOutput;
  ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput: TProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput;
  ProcessingErrorInternalServerErrorOutput: TProcessingErrorInternalServerErrorOutput;
  ProcessingErrorInvalidInputForProcessingPaymentErrorOutput: TProcessingErrorInvalidInputForProcessingPaymentErrorOutput;
  ProcessingErrorOperationNotAllowedOutput: TProcessingErrorOperationNotAllowedOutput;
  ProcessingErrorOutput: TProcessingErrorOutput;
  ProcessingErrorProcessingLimitReachedOutput: TProcessingErrorProcessingLimitReachedOutput;
  ProcessingErrorResourceAccessDeniedOutput: TProcessingErrorResourceAccessDeniedOutput;
  ProcessingErrorResourceNotFoundOutput: TProcessingErrorResourceNotFoundOutput;
  ProcessingErrorStorageLimitReachedOutput: TProcessingErrorStorageLimitReachedOutput;
  ProcessingPipelineInfoOutput: TProcessingPipelineInfoOutput;
  ProcessingPipelineTriggerInput: TProcessingPipelineTriggerInput;
  ProcessingPipelineTriggerOutput: TProcessingPipelineTriggerOutput;
  ProcessingTypeOutput: TProcessingTypeOutput;
  ProcessingUsageOutput: TProcessingUsageOutput;
  Product2DAerialOutput: TProduct2DAerialOutput;
  ProductCodeInput: TProductCodeInput;
  ProductInput: TProductInput;
  ProductOutput: TResolversParentTypes['PointCloudSourceLidarOutput'] | TResolversParentTypes['Product2DAerialOutput'];
  ProductUnion: TResolversParentTypes['QuotationErrorProductNotFoundOutput'] | TResolversParentTypes['QuotationProductOutput'];
  ProfilePictureOutput: TProfilePictureOutput;
  ProjectAppliedPageOutput: TProjectAppliedPageOutput;
  ProjectChangeCopyOperation: TResolversParentTypes['ProjectChangeFolderCopyOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCopyOperation'];
  ProjectChangeCreateOperation: TResolversParentTypes['ProjectChangeFolderCreateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCreateOperation'];
  ProjectChangeDeleteOperation: TResolversParentTypes['ProjectChangeFolderDeleteOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetDeleteOperation'];
  ProjectChangeFolderCopyOperation: Omit<TProjectChangeFolderCopyOperation, 'folder' | 'sourceFolder' | 'targetFolder'> & { folder: TResolversParentTypes['FolderUnion'], sourceFolder: TResolversParentTypes['FolderUnion'], targetFolder: TResolversParentTypes['FolderUnion'] };
  ProjectChangeFolderCreateOperation: Omit<TProjectChangeFolderCreateOperation, 'folder'> & { folder: TResolversParentTypes['FolderUnion'] };
  ProjectChangeFolderDeleteOperation: TProjectChangeFolderDeleteOperation;
  ProjectChangeFolderMoveOperation: Omit<TProjectChangeFolderMoveOperation, 'folder' | 'sourceFolder' | 'targetFolder'> & { folder: TResolversParentTypes['FolderUnion'], sourceFolder: TResolversParentTypes['FolderUnion'], targetFolder: TResolversParentTypes['FolderUnion'] };
  ProjectChangeFolderOperation: TResolversParentTypes['ProjectChangeFolderCopyOperation'] | TResolversParentTypes['ProjectChangeFolderCreateOperation'] | TResolversParentTypes['ProjectChangeFolderDeleteOperation'] | TResolversParentTypes['ProjectChangeFolderMoveOperation'] | TResolversParentTypes['ProjectChangeFolderUpdateOperation'];
  ProjectChangeFolderUpdateOperation: Omit<TProjectChangeFolderUpdateOperation, 'folder'> & { folder: TResolversParentTypes['FolderUnion'] };
  ProjectChangeGroupedAssetCopyOperation: Omit<TProjectChangeGroupedAssetCopyOperation, 'asset' | 'sourceFolder' | 'targetFolder'> & { asset: TResolversParentTypes['AssetUnion'], sourceFolder: TResolversParentTypes['FolderUnion'], targetFolder: TResolversParentTypes['FolderUnion'] };
  ProjectChangeGroupedAssetCreateOperation: Omit<TProjectChangeGroupedAssetCreateOperation, 'asset'> & { asset: TResolversParentTypes['AssetUnion'] };
  ProjectChangeGroupedAssetDeleteOperation: TProjectChangeGroupedAssetDeleteOperation;
  ProjectChangeGroupedAssetMoveOperation: Omit<TProjectChangeGroupedAssetMoveOperation, 'asset' | 'sourceFolder' | 'targetFolder'> & { asset: TResolversParentTypes['AssetUnion'], sourceFolder: TResolversParentTypes['FolderUnion'], targetFolder: TResolversParentTypes['FolderUnion'] };
  ProjectChangeGroupedAssetOperation: TResolversParentTypes['ProjectChangeGroupedAssetCopyOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCreateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetDeleteOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetMoveOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetUpdateOperation'];
  ProjectChangeGroupedAssetUpdateOperation: Omit<TProjectChangeGroupedAssetUpdateOperation, 'asset'> & { asset: TResolversParentTypes['AssetUnion'] };
  ProjectChangeMoveOperation: TResolversParentTypes['ProjectChangeFolderMoveOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetMoveOperation'];
  ProjectChangeOperation: TResolversParentTypes['ProjectChangeFolderCopyOperation'] | TResolversParentTypes['ProjectChangeFolderCreateOperation'] | TResolversParentTypes['ProjectChangeFolderDeleteOperation'] | TResolversParentTypes['ProjectChangeFolderMoveOperation'] | TResolversParentTypes['ProjectChangeFolderUpdateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCopyOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetCreateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetDeleteOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetMoveOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetUpdateOperation'] | TResolversParentTypes['ProjectChangeProjectUpdateOperation'] | TResolversParentTypes['ProjectChangeUserAccessGrantedOperation'] | TResolversParentTypes['ProjectChangeUserAccessRevokedOperation'];
  ProjectChangeOutput: Omit<TProjectChangeOutput, 'operation' | 'project'> & { operation: TResolversParentTypes['ProjectChangeOperation'], project: TResolversParentTypes['ProjectUnion'] };
  ProjectChangePayload: TResolversParentTypes['ProjectChangeOutput'] | TResolversParentTypes['ServerError'] | TResolversParentTypes['UnauthenticatedError'];
  ProjectChangeProjectOperation: TResolversParentTypes['ProjectChangeOutput'] | TResolversParentTypes['ProjectChangeProjectUpdateOperation'];
  ProjectChangeProjectUpdateOperation: Omit<TProjectChangeProjectUpdateOperation, 'project'> & { project: TResolversParentTypes['ProjectUnion'] };
  ProjectChangeUpdateOperation: TResolversParentTypes['ProjectChangeFolderUpdateOperation'] | TResolversParentTypes['ProjectChangeGroupedAssetUpdateOperation'] | TResolversParentTypes['ProjectChangeProjectUpdateOperation'];
  ProjectChangeUserAccessGrantedOperation: TProjectChangeUserAccessGrantedOperation;
  ProjectChangeUserAccessOperation: TResolversParentTypes['ProjectChangeUserAccessGrantedOperation'] | TResolversParentTypes['ProjectChangeUserAccessRevokedOperation'];
  ProjectChangeUserAccessRevokedOperation: TProjectChangeUserAccessRevokedOperation;
  ProjectErrorDuplicateNameOutput: TProjectErrorDuplicateNameOutput;
  ProjectErrorInvalidInputOutput: TProjectErrorInvalidInputOutput;
  ProjectErrorNameBlacklistedCharactersOutput: TProjectErrorNameBlacklistedCharactersOutput;
  ProjectErrorNameBlankOutput: TProjectErrorNameBlankOutput;
  ProjectErrorNameForbiddenOutput: TProjectErrorNameForbiddenOutput;
  ProjectErrorNameForwardSlashOutput: TProjectErrorNameForwardSlashOutput;
  ProjectErrorNameLeadingOrTrailingSpaceOutput: TProjectErrorNameLeadingOrTrailingSpaceOutput;
  ProjectErrorNameTrailingDotOutput: TProjectErrorNameTrailingDotOutput;
  ProjectErrorNameTwoDotsInRowOutput: TProjectErrorNameTwoDotsInRowOutput;
  ProjectErrorOperationNotAllowedOutput: TProjectErrorOperationNotAllowedOutput;
  ProjectErrorResourceAccessDeniedForTargetUserOutput: TProjectErrorResourceAccessDeniedForTargetUserOutput;
  ProjectErrorResourceAccessDeniedOutput: TProjectErrorResourceAccessDeniedOutput;
  ProjectErrorTooManyRequestsOutput: TProjectErrorTooManyRequestsOutput;
  ProjectMemberInput: TProjectMemberInput;
  ProjectMemberOutput: TProjectMemberOutput;
  ProjectMembersResultOutput: TProjectMembersResultOutput;
  ProjectOutput: TProjectOutput;
  ProjectPageInput: TProjectPageInput;
  ProjectResultOutput: TProjectResultOutput;
  ProjectThumbnailInput: TProjectThumbnailInput;
  ProjectThumbnailOutput: TProjectThumbnailOutput;
  ProjectUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['ProjectOutput'];
  ProjectsUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['ProjectResultOutput'];
  PropertySpatial: TPropertySpatial;
  PropertyTemporal: TPropertyTemporal;
  Provider: TProvider;
  Query: {};
  QuotationErrorProductNotFoundOutput: TQuotationErrorProductNotFoundOutput;
  QuotationItemOutput: TQuotationItemOutput;
  QuotationOutput: TQuotationOutput;
  QuotationProductOutput: TQuotationProductOutput;
  QuotationUnion: TResolversParentTypes['QuoteOutput'] | TResolversParentTypes['ShopErrorBackendFailedOutput'] | TResolversParentTypes['ShopErrorNoOverlapWithProductOutput'] | TResolversParentTypes['ShopErrorOperationNotAllowedOutput'] | TResolversParentTypes['ShopErrorProductNotFoundOutput'] | TResolversParentTypes['ShopErrorProductPricingNotFoundOutput'];
  QuoteOutput: TQuoteOutput;
  RecalculateGroupedAssetTourKeyframesInput: TRecalculateGroupedAssetTourKeyframesInput;
  ReferencedBoundsDimensionsOutput: TReferencedBoundsDimensionsOutput;
  ReferencedBoundsGeolocationOutput: TReferencedBoundsGeolocationOutput;
  ReferencedBoundsOutput: TReferencedBoundsOutput;
  ReferrerOutput: TReferrerOutput;
  RemoteDeviceIdentifiersGQL: TRemoteDeviceIdentifiersGql;
  RemoteJobIdentifierGQL: TRemoteJobIdentifierGql;
  RemoveCartItemInput: TRemoveCartItemInput;
  RemoveLabelsInput: TRemoveLabelsInput;
  RemoveLabelsUnion: TResolversParentTypes['LabelErrorOperationNotAllowedOutput'] | TResolversParentTypes['LabelsOutput'];
  Renderable: TResolversParentTypes['AddressAssetFileDownloadOutput'] | TResolversParentTypes['AddressDownloadOutput'] | TResolversParentTypes['AddressHspcOutput'] | TResolversParentTypes['AddressLtsOutput'] | TResolversParentTypes['AddressOgc3DOutput'] | TResolversParentTypes['AddressPanoramicOutput'] | TResolversParentTypes['AddressWfsOutput'] | TResolversParentTypes['AddressWmsOutput'] | TResolversParentTypes['AddressWmtsOutput'];
  ReportGenerationInput: TReportGenerationInput;
  ReportGenerationOutput: TReportGenerationOutput;
  SearchLabelsInput: TSearchLabelsInput;
  SearchLabelsUnion: TResolversParentTypes['LabelErrorOperationNotAllowedOutput'] | TResolversParentTypes['LabelsResultOutput'];
  SearchMyAssetsProjectUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['SearchOutput'];
  SearchOutput: TSearchOutput;
  SearchProjectUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['SearchOutput'];
  SelectedSubscriptionTierOutput: TSelectedSubscriptionTierOutput;
  SelectionInput: TSelectionInput;
  ServerError: TServerError;
  SetActivePaymentCardInput: TSetActivePaymentCardInput;
  SetActivePaymentCardOutput: TSetActivePaymentCardOutput;
  SetupPaymentCardCreationOutput: TSetupPaymentCardCreationOutput;
  SharedGroupedAssetOutput: TSharedGroupedAssetOutput;
  SharedGroupedAssetUnion: TResolversParentTypes['AcsErrorEntityNotFoundOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['SharedGroupedAssetOutput'];
  SharingCodeOutput: TSharingCodeOutput;
  SharingCodeUnion: TResolversParentTypes['AcsErrorEntityNotFoundOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['SharingCodeOutput'];
  ShopErrorBackendFailedOutput: TShopErrorBackendFailedOutput;
  ShopErrorNoOverlapWithProductOutput: TShopErrorNoOverlapWithProductOutput;
  ShopErrorOperationNotAllowedOutput: TShopErrorOperationNotAllowedOutput;
  ShopErrorProductNotFoundOutput: TShopErrorProductNotFoundOutput;
  ShopErrorProductPricingNotFoundOutput: TShopErrorProductPricingNotFoundOutput;
  ShopSupportedCountryValidationOutput: TShopSupportedCountryValidationOutput;
  SignUpUserAccountAddressInput: TSignUpUserAccountAddressInput;
  SignUpUserAccountProfileInput: TSignUpUserAccountProfileInput;
  SignupFromInviteInput: TSignupFromInviteInput;
  SignupFromInviteUnion: TResolversParentTypes['AcsErrorInvalidInvitationSignatureOutput'] | TResolversParentTypes['AcsErrorSeatLimitReachedOutput'] | TResolversParentTypes['AcsErrorTooManyRequestsOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsOutput'] | TResolversParentTypes['SignupOutput'];
  SignupInput: TSignupInput;
  SignupOutput: TSignupOutput;
  SignupUnion: TResolversParentTypes['AcsErrorInvalidVoucherOutput'] | TResolversParentTypes['AcsErrorTooManyRequestsOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsOutput'] | TResolversParentTypes['AcsErrorVoucherAlreadyUsedOutput'] | TResolversParentTypes['SignupOutput'];
  SimpleAccountInfoOutput: TSimpleAccountInfoOutput;
  SimpleResultOutput: TSimpleResultOutput;
  SimpleResultUnion: TResolversParentTypes['AcsErrorEntityNotFoundOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['SimpleResultOutput'];
  SimpleUserProfileOutput: TSimpleUserProfileOutput;
  SimpleUserProfileUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedForTargetUserOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['SimpleUserProfileOutput'];
  StreamingUsageOutput: TStreamingUsageOutput;
  String: Scalars['String'];
  SubProcessGQL: TSubProcessGql;
  Subscription: {};
  SubscriptionAddonOutput: TSubscriptionAddonOutput;
  SubscriptionErrorAccountNotSyncedOutput: TSubscriptionErrorAccountNotSyncedOutput;
  SubscriptionErrorBadRequestOutput: TSubscriptionErrorBadRequestOutput;
  SubscriptionErrorDuplicateWithSameAddonOutput: TSubscriptionErrorDuplicateWithSameAddonOutput;
  SubscriptionErrorFeatureNotAvailableOutput: TSubscriptionErrorFeatureNotAvailableOutput;
  SubscriptionErrorOperationNotAllowedOutput: TSubscriptionErrorOperationNotAllowedOutput;
  SubscriptionInvoiceOutput: TSubscriptionInvoiceOutput;
  SubscriptionInvoicesOutput: TSubscriptionInvoicesOutput;
  SubscriptionInvoicesUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionInvoicesOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'];
  SubscriptionLimitOutput: TSubscriptionLimitOutput;
  SubscriptionNoValidIsoCountryFoundOutput: TSubscriptionNoValidIsoCountryFoundOutput;
  SubscriptionOrderItemOutput: TSubscriptionOrderItemOutput;
  SubscriptionOrderOutput: TSubscriptionOrderOutput;
  SubscriptionOrdersOutput: TSubscriptionOrdersOutput;
  SubscriptionOrdersUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['SubscriptionOrdersOutput'];
  SubscriptionOutput: TSubscriptionOutput;
  SubscriptionPriceOutput: TSubscriptionPriceOutput;
  SubscriptionQuotationAddonInput: TSubscriptionQuotationAddonInput;
  SubscriptionQuotationAddonOutput: TSubscriptionQuotationAddonOutput;
  SubscriptionQuotationInput: TSubscriptionQuotationInput;
  SubscriptionQuotationOutput: TSubscriptionQuotationOutput;
  SubscriptionQuotationTierOutput: TSubscriptionQuotationTierOutput;
  SubscriptionQuotationUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['SubscriptionQuotationOutput'];
  SubscriptionTierAddonOptionOutput: TSubscriptionTierAddonOptionOutput;
  SubscriptionTierAddonOutput: TSubscriptionTierAddonOutput;
  SubscriptionTierOutput: TSubscriptionTierOutput;
  SubscriptionTiersOutput: TSubscriptionTiersOutput;
  SubscriptionTiersUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['SubscriptionTiersOutput'];
  SubscriptionTotalPriceOutput: TSubscriptionTotalPriceOutput;
  SubscriptionUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorDuplicateWithSameAddonOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['SubscriptionOutput'];
  SubscriptionsOutput: TSubscriptionsOutput;
  SubscriptionsUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['SubscriptionsOutput'];
  Summary: TSummary;
  SummaryItemGQL: TSummaryItemGql;
  SupportedCountryLocalizedNameOutput: TSupportedCountryLocalizedNameOutput;
  SupportedCountryOutput: TSupportedCountryOutput;
  TaskGQL: TTaskGql;
  TenantDeletionOutput: TTenantDeletionOutput;
  TenantDeletionUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['TenantDeletionOutput'];
  TenantOutput: TTenantOutput;
  TenantUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['AcsErrorTenantNotFoundOutput'] | TResolversParentTypes['TenantOutput'];
  TenantsOutput: TTenantsOutput;
  TenantsUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['TenantsOutput'];
  ThumbnailOutput: TThumbnailOutput;
  TokenWalletOutput: TTokenWalletOutput;
  TokensRefundInput: TTokensRefundInput;
  TokensRefundOutput: TTokensRefundOutput;
  TokensTransactionOutput: TTokensTransactionOutput;
  TokensTransactionsOutput: TTokensTransactionsOutput;
  TokensTransactionsPageInput: TTokensTransactionsPageInput;
  TokensTransactionsUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['TokensTransactionsOutput'];
  Tour: TResolversParentTypes['GroupedAssetTourOutput'];
  TourAppliedPageOutput: TTourAppliedPageOutput;
  TourCameraViewpointCreateTourInput: TTourCameraViewpointCreateTourInput;
  TourCameraViewpointInput: TTourCameraViewpointInput;
  TourCameraViewpointOutput: TTourCameraViewpointOutput;
  TourCameraViewpointUpdateInput: TTourCameraViewpointUpdateInput;
  TourFilterAppliedOutput: TTourFilterAppliedOutput;
  TourFilterInput: TTourFilterInput;
  TourInput: TTourInput;
  TourKeyframe: TResolversParentTypes['GroupedAssetTourKeyframeOutput'];
  TourPageInput: TTourPageInput;
  TriggerInput: TTriggerInput;
  TriggerMeshingInput: TTriggerMeshingInput;
  TriggerProcessingOutput: TTriggerProcessingOutput;
  TriggerProcessingUnion: TResolversParentTypes['ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput'] | TResolversParentTypes['ProcessingErrorInternalServerErrorOutput'] | TResolversParentTypes['ProcessingErrorInvalidInputForProcessingPaymentErrorOutput'] | TResolversParentTypes['ProcessingErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProcessingErrorProcessingLimitReachedOutput'] | TResolversParentTypes['ProcessingErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProcessingErrorResourceNotFoundOutput'] | TResolversParentTypes['ProcessingErrorStorageLimitReachedOutput'] | TResolversParentTypes['TriggerProcessingOutput'];
  UnauthenticatedError: TUnauthenticatedError;
  UnsharedGroupedAssetOutput: TUnsharedGroupedAssetOutput;
  UnsharedGroupedAssetUnion: TResolversParentTypes['AcsErrorEntityNotFoundOutput'] | TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] | TResolversParentTypes['UnsharedGroupedAssetOutput'];
  UpdateAppClientInput: TUpdateAppClientInput;
  UpdateApplicationTemplateInput: TUpdateApplicationTemplateInput;
  UpdateApplicationTemplateUnion: TResolversParentTypes['ApplicationErrorInvalidArgumentOutput'] | TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] | TResolversParentTypes['ApplicationErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'] | TResolversParentTypes['ApplicationTemplateOutput'];
  UpdateAssetInput: TUpdateAssetInput;
  UpdateAssetUnion: TResolversParentTypes['AssetErrorDuplicateNameOutput'] | TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['AssetErrorNameBlankOutput'] | TResolversParentTypes['AssetErrorNameForbiddenOutput'] | TResolversParentTypes['AssetErrorNameForwardSlashOutput'] | TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['AssetErrorNameTrailingDotOutput'] | TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] | TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] | TResolversParentTypes['AssetErrorTooManyRequestsOutput'] | TResolversParentTypes['GroupedAssetOutput'];
  UpdateCommentInput: TUpdateCommentInput;
  UpdateDeviceInput: TUpdateDeviceInput;
  UpdateFolderInput: TUpdateFolderInput;
  UpdateFolderUnion: TResolversParentTypes['FolderErrorDuplicateNameOutput'] | TResolversParentTypes['FolderErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['FolderErrorNameBlankOutput'] | TResolversParentTypes['FolderErrorNameForbiddenOutput'] | TResolversParentTypes['FolderErrorNameForwardSlashOutput'] | TResolversParentTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['FolderErrorNameTrailingDotOutput'] | TResolversParentTypes['FolderErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['FolderErrorOperationNotAllowedOutput'] | TResolversParentTypes['FolderErrorResourceAccessDeniedOutput'] | TResolversParentTypes['FolderErrorTooManyRequestsOutput'] | TResolversParentTypes['FolderOutput'];
  UpdateGeoreferenceInput: TUpdateGeoreferenceInput;
  UpdateGroupedAssetGeoreferenceInput: TUpdateGroupedAssetGeoreferenceInput;
  UpdateGroupedAssetTourInput: TUpdateGroupedAssetTourInput;
  UpdateGroupedAssetTourKeyframeInput: TUpdateGroupedAssetTourKeyframeInput;
  UpdateLabelAnnotationInput: TUpdateLabelAnnotationInput;
  UpdateLabelAnnotationInputV2: TUpdateLabelAnnotationInputV2;
  UpdateLabelAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['LabelAnnotationOutput'];
  UpdateLimitingBoxAnnotationInput: TUpdateLimitingBoxAnnotationInput;
  UpdateLimitingBoxAnnotationInputV2: TUpdateLimitingBoxAnnotationInputV2;
  UpdateLimitingBoxAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['LimitingBoxAnnotationOutput'];
  UpdateMeasurementAnnotationInput: TUpdateMeasurementAnnotationInput;
  UpdateMeasurementAnnotationInputV2: TUpdateMeasurementAnnotationInputV2;
  UpdateMeasurementAnnotationUnion: TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] | TResolversParentTypes['MeasurementAnnotationOutput'];
  UpdateProjectInput: TUpdateProjectInput;
  UpdateProjectUnion: TResolversParentTypes['ProjectErrorDuplicateNameOutput'] | TResolversParentTypes['ProjectErrorInvalidInputOutput'] | TResolversParentTypes['ProjectErrorNameBlacklistedCharactersOutput'] | TResolversParentTypes['ProjectErrorNameBlankOutput'] | TResolversParentTypes['ProjectErrorNameForbiddenOutput'] | TResolversParentTypes['ProjectErrorNameForwardSlashOutput'] | TResolversParentTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] | TResolversParentTypes['ProjectErrorNameTrailingDotOutput'] | TResolversParentTypes['ProjectErrorNameTwoDotsInRowOutput'] | TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['ProjectOutput'];
  UpdateTenantInput: TUpdateTenantInput;
  UploadThumbnailToProjectUnion: TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] | TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] | TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] | TResolversParentTypes['ProjectThumbnailOutput'];
  UploadedPartsInput: TUploadedPartsInput;
  UrlInput: TUrlInput;
  UsageAccountUnion: TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['UsageOutput'];
  UsageInput: TUsageInput;
  UsageOutput: TUsageOutput;
  UsageTenantInput: TUsageTenantInput;
  UsageTenantOutput: TUsageTenantOutput;
  UsageTenantUnion: TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['UsageTenantOutput'];
  UserDeletionOutput: TUserDeletionOutput;
  UserDeletionUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorUserNotFoundOutput'] | TResolversParentTypes['UserDeletionOutput'];
  UserFlagInput: TUserFlagInput;
  UserFlagOutput: TUserFlagOutput;
  UserFlagSignupInput: TUserFlagSignupInput;
  UserInput: TUserInput;
  UserProfileAppliedPageOutput: TUserProfileAppliedPageOutput;
  UserProfileOutput: TUserProfileOutput;
  UserProfilePageInput: TUserProfilePageInput;
  UserProfileResultOutput: TUserProfileResultOutput;
  UserProjectRoleOutput: TUserProjectRoleOutput;
  UserProjectRoleUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorProjectNotFoundOutput'] | TResolversParentTypes['ListOfUserProjectRoleOutput'];
  UserSettingsInput: TUserSettingsInput;
  UserSettingsOutput: TUserSettingsOutput;
  UserSettingsSignupInput: TUserSettingsSignupInput;
  UserUpdateInput: TUserUpdateInput;
  ValidationError: TValidationError;
  ValidationErrorIssue: TValidationErrorIssue;
  Vector3Input: TVector3Input;
  Vector3Output: TVector3Output;
  VoucherOutput: TVoucherOutput;
  VoucherUnion: TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] | TResolversParentTypes['AcsErrorSeatLimitReachedOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsInAccountOutput'] | TResolversParentTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] | TResolversParentTypes['VoucherOutput'];
  WFSInput: TWfsInput;
  WMSInput: TWmsInput;
  WMTSInput: TWmtsInput;
  WalletRefundUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorBadRequestOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['TokensRefundOutput'];
  WalletUnion: TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] | TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] | TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] | TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] | TResolversParentTypes['TokenWalletOutput'];
  _Any: Scalars['_Any'];
  _Entity: TResolversParentTypes['AccountInfoOutput'] | TResolversParentTypes['AccountUsageOutput'] | TResolversParentTypes['AddressDownloadOutput'] | TResolversParentTypes['AddressHspcOutput'] | TResolversParentTypes['AddressLtsOutput'] | TResolversParentTypes['AddressOgc3DOutput'] | TResolversParentTypes['AddressPageOutput'] | TResolversParentTypes['AddressPanoramicOutput'] | TResolversParentTypes['ApplicationTemplateOutput'] | TResolversParentTypes['AssetOutput'] | TResolversParentTypes['FileOutput'] | TResolversParentTypes['FolderOutput'] | TResolversParentTypes['GeoreferenceArtifactResultOutput'] | TResolversParentTypes['GroupedAssetOutput'] | TResolversParentTypes['JobPage'] | TResolversParentTypes['JobSummaryGQL'] | TResolversParentTypes['LabelAnnotationOutput'] | TResolversParentTypes['LimitingBoxAnnotationOutput'] | TResolversParentTypes['LuciadRIALicenseOutput'] | TResolversParentTypes['MeasurementAnnotationOutput'] | TResolversParentTypes['OrderGQL'] | TResolversParentTypes['OrderItemGQL'] | TResolversParentTypes['ProjectMemberOutput'] | TResolversParentTypes['ProjectOutput'] | TResolversParentTypes['SearchOutput'] | TResolversParentTypes['SimpleAccountInfoOutput'] | TResolversParentTypes['SimpleUserProfileOutput'] | TResolversParentTypes['UsageOutput'] | TResolversParentTypes['UsageTenantOutput'] | TResolversParentTypes['UserProfileOutput'];
};

export type TCanonicalDirectiveArgs = { };

export type TCanonicalDirectiveResolver<Result, Parent, ContextType = any, Args = TCanonicalDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TComputedDirectiveArgs = {
  selectionSet: Scalars['String'];
};

export type TComputedDirectiveResolver<Result, Parent, ContextType = any, Args = TComputedDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TKeyDirectiveArgs = {
  selectionSet: Scalars['String'];
};

export type TKeyDirectiveResolver<Result, Parent, ContextType = any, Args = TKeyDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TLiveDirectiveArgs = {
  if?: Maybe<Scalars['Boolean']>;
  throttle?: Maybe<Scalars['Int']>;
};

export type TLiveDirectiveResolver<Result, Parent, ContextType = any, Args = TLiveDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TMergeDirectiveArgs = {
  additionalArgs?: Maybe<Scalars['String']>;
  argsExpr?: Maybe<Scalars['String']>;
  key?: Maybe<Array<Scalars['String']>>;
  keyArg?: Maybe<Scalars['String']>;
  keyField?: Maybe<Scalars['String']>;
};

export type TMergeDirectiveResolver<Result, Parent, ContextType = any, Args = TMergeDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type TAccessDeniedErrorResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccessDeniedError'] = TResolversParentTypes['AccessDeniedError']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountAddressOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountAddressOutput'] = TResolversParentTypes['AccountAddressOutput']> = {
  additionalInfo?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  postcode?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  streetNumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountDetailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountDetailOutput'] = TResolversParentTypes['AccountDetailOutput']> = {
  accountInfo?: Resolver<Maybe<TResolversTypes['AccountInfoOutput']>, ParentType, ContextType>;
  users?: Resolver<Array<Maybe<TResolversTypes['UserProfileOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountDetailUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountDetailUnion'] = TResolversParentTypes['AccountDetailUnion']> = {
  __resolveType: TypeResolveFn<'AccountDetailOutput' | 'AcsErrorOperationNotAllowedOutput', ParentType, ContextType>;
};

export type TAccountInfoAccountAddressIsNotValidOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoAccountAddressIsNotValidOutput'] = TResolversParentTypes['AccountInfoAccountAddressIsNotValidOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountInfoAccountCompanyNameIsNotValidOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoAccountCompanyNameIsNotValidOutput'] = TResolversParentTypes['AccountInfoAccountCompanyNameIsNotValidOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountInfoAccountEmailIsNotValidOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoAccountEmailIsNotValidOutput'] = TResolversParentTypes['AccountInfoAccountEmailIsNotValidOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountInfoAccountLegalEntityCouldNotBeChangedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoAccountLegalEntityCouldNotBeChangedOutput'] = TResolversParentTypes['AccountInfoAccountLegalEntityCouldNotBeChangedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountInfoErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoErrorOperationNotAllowedOutput'] = TResolversParentTypes['AccountInfoErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountInfoOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoOutput'] = TResolversParentTypes['AccountInfoOutput']> = {
  accountBillingEmail?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  accountUsageOutput?: Resolver<Maybe<TResolversTypes['AccountUsageOutput']>, ParentType, ContextType>;
  address?: Resolver<Maybe<TResolversTypes['AccountAddressOutput']>, ParentType, ContextType>;
  addressValid?: Resolver<Maybe<TResolversTypes['AddressValidOutput']>, ParentType, ContextType>;
  company?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  discountRate?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  financialDetailsSynced?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  isReseller?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  originalExternalAccount?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  paymentMethods?: Resolver<Maybe<Array<Maybe<TResolversTypes['PaymentMethodEnum']>>>, ParentType, ContextType>;
  paymentSystemIds?: Resolver<Maybe<Array<TResolversTypes['PaymentSystemOutput']>>, ParentType, ContextType>;
  phonenumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  referrer?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  sapAccountNumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  taxExempt?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  vatNumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountInfoUpdateUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountInfoUpdateUnion'] = TResolversParentTypes['AccountInfoUpdateUnion']> = {
  __resolveType: TypeResolveFn<'AccountInfoAccountAddressIsNotValidOutput' | 'AccountInfoAccountCompanyNameIsNotValidOutput' | 'AccountInfoAccountEmailIsNotValidOutput' | 'AccountInfoAccountLegalEntityCouldNotBeChangedOutput' | 'AccountInfoErrorOperationNotAllowedOutput' | 'AccountInfoOutput', ParentType, ContextType>;
};

export type TAccountMembersPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountMembersPageOutput'] = TResolversParentTypes['AccountMembersPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountMembersResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountMembersResultOutput'] = TResolversParentTypes['AccountMembersResultOutput']> = {
  appliedFilters?: Resolver<Maybe<TResolversTypes['FilterAccountMembersOutput']>, ParentType, ContextType>;
  appliedPagination?: Resolver<Maybe<TResolversTypes['AccountMembersPageOutput']>, ParentType, ContextType>;
  members?: Resolver<Array<Maybe<TResolversTypes['UserProfileOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['AccountMembersOrderEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountMembersResultUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountMembersResultUnion'] = TResolversParentTypes['AccountMembersResultUnion']> = {
  __resolveType: TypeResolveFn<'AccountMembersResultOutput' | 'AcsErrorOperationNotAllowedOutput', ParentType, ContextType>;
};

export type TAccountProfileAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountProfileAppliedPageOutput'] = TResolversParentTypes['AccountProfileAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountProfileResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountProfileResultOutput'] = TResolversParentTypes['AccountProfileResultOutput']> = {
  appliedFilters?: Resolver<Maybe<TResolversTypes['FilterAccountProfileAppliedOutput']>, ParentType, ContextType>;
  appliedPagination?: Resolver<Maybe<TResolversTypes['AccountProfileAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['AccountInfoOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['AccountProfileOrderEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAccountUsageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AccountUsageOutput'] = TResolversParentTypes['AccountUsageOutput']> = {
  accountID?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  processing?: Resolver<Array<Maybe<TResolversTypes['ProcessingUsageOutput']>>, ParentType, ContextType, Partial<TAccountUsageOutputProcessingArgs>>;
  projects?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  seats?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  storageArtifacts?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  storageAssets?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  streaming?: Resolver<TResolversTypes['StreamingUsageOutput'], ParentType, ContextType, Partial<TAccountUsageOutputStreamingArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorEntityNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorEntityNotFoundOutput'] = TResolversParentTypes['AcsErrorEntityNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorInvalidInvitationSignatureOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorInvalidInvitationSignatureOutput'] = TResolversParentTypes['AcsErrorInvalidInvitationSignatureOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorInvalidVoucherOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorInvalidVoucherOutput'] = TResolversParentTypes['AcsErrorInvalidVoucherOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorOperationNotAllowedOutput'] = TResolversParentTypes['AcsErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorOperationNotPossibleOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorOperationNotPossibleOutput'] = TResolversParentTypes['AcsErrorOperationNotPossibleOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorProjectNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorProjectNotFoundOutput'] = TResolversParentTypes['AcsErrorProjectNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorSeatLimitReachedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorSeatLimitReachedOutput'] = TResolversParentTypes['AcsErrorSeatLimitReachedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorTenantNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorTenantNotFoundOutput'] = TResolversParentTypes['AcsErrorTenantNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorTooManyRequestsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorTooManyRequestsOutput'] = TResolversParentTypes['AcsErrorTooManyRequestsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorUserAlreadyExistsInAccountOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorUserAlreadyExistsInAccountOutput'] = TResolversParentTypes['AcsErrorUserAlreadyExistsInAccountOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorUserAlreadyExistsInOtherAccountOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput'] = TResolversParentTypes['AcsErrorUserAlreadyExistsInOtherAccountOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorUserAlreadyExistsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorUserAlreadyExistsOutput'] = TResolversParentTypes['AcsErrorUserAlreadyExistsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorUserNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorUserNotFoundOutput'] = TResolversParentTypes['AcsErrorUserNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAcsErrorVoucherAlreadyUsedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AcsErrorVoucherAlreadyUsedOutput'] = TResolversParentTypes['AcsErrorVoucherAlreadyUsedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddFileUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddFileUnion'] = TResolversParentTypes['AddFileUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorNameBlacklistedCharactersOutput' | 'AssetErrorNameBlankOutput' | 'AssetErrorNameForbiddenOutput' | 'AssetErrorNameLeadingOrTrailingSpaceOutput' | 'AssetErrorNameTrailingDotOutput' | 'AssetErrorNameTwoDotsInRowOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorStorageLimitReachedOutput' | 'FileOutput', ParentType, ContextType>;
};

export type TAddLabelsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddLabelsUnion'] = TResolversParentTypes['AddLabelsUnion']> = {
  __resolveType: TypeResolveFn<'LabelErrorOperationNotAllowedOutput' | 'LabelErrorTooManyLabelsOutput' | 'LabelsOutput', ParentType, ContextType>;
};

export type TAddressAssetFileDownloadOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressAssetFileDownloadOutput'] = TResolversParentTypes['AddressAssetFileDownloadOutput']> = {
  downloadType?: Resolver<TResolversTypes['DownloadTypeEnum'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressDownloadOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressDownloadOutput'] = TResolversParentTypes['AddressDownloadOutput']> = {
  downloadType?: Resolver<TResolversTypes['DownloadTypeEnum'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  processingPipelineInfo?: Resolver<Maybe<TResolversTypes['ProcessingPipelineInfoOutput']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressHspcOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressHspcOutput'] = TResolversParentTypes['AddressHspcOutput']> = {
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  processingPipelineInfo?: Resolver<Maybe<TResolversTypes['ProcessingPipelineInfoOutput']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressLtsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressLtsOutput'] = TResolversParentTypes['AddressLtsOutput']> = {
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  processingPipelineInfo?: Resolver<Maybe<TResolversTypes['ProcessingPipelineInfoOutput']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressOgc3DOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressOgc3DOutput'] = TResolversParentTypes['AddressOgc3DOutput']> = {
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  processingPipelineInfo?: Resolver<Maybe<TResolversTypes['ProcessingPipelineInfoOutput']>, ParentType, ContextType>;
  qualityFactor?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressOutput'] = TResolversParentTypes['AddressOutput']> = {
  __resolveType: TypeResolveFn<'AddressAssetFileDownloadOutput' | 'AddressDownloadOutput' | 'AddressHspcOutput' | 'AddressLtsOutput' | 'AddressOgc3DOutput' | 'AddressPanoramicOutput' | 'AddressWfsOutput' | 'AddressWmsOutput' | 'AddressWmtsOutput', ParentType, ContextType>;
};

export type TAddressPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressPageOutput'] = TResolversParentTypes['AddressPageOutput']> = {
  appliedPagination?: Resolver<Maybe<TResolversTypes['ArtifactAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['AddressOutput']>, ParentType, ContextType>;
  filterType?: Resolver<Maybe<TResolversTypes['AddressTypeEnum']>, ParentType, ContextType>;
  groupedAssetId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  parentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressPanoramicOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressPanoramicOutput'] = TResolversParentTypes['AddressPanoramicOutput']> = {
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  processingPipelineInfo?: Resolver<Maybe<TResolversTypes['ProcessingPipelineInfoOutput']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressValidOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressValidOutput'] = TResolversParentTypes['AddressValidOutput']> = {
  reason?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  taxable?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressWfsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressWfsOutput'] = TResolversParentTypes['AddressWfsOutput']> = {
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  styleUrl?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressWmsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressWmsOutput'] = TResolversParentTypes['AddressWmsOutput']> = {
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAddressWmtsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AddressWmtsOutput'] = TResolversParentTypes['AddressWmtsOutput']> = {
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAnnotationErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput'] = TResolversParentTypes['AnnotationErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAnnotationOrientationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AnnotationOrientationOutput'] = TResolversParentTypes['AnnotationOrientationOutput']> = {
  distance?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  pitch?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  ref?: Resolver<TResolversTypes['AnnotationPointOutput'], ParentType, ContextType>;
  roll?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  yaw?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAnnotationPointOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AnnotationPointOutput'] = TResolversParentTypes['AnnotationPointOutput']> = {
  x?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  z?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAnnotationRotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AnnotationRotationOutput'] = TResolversParentTypes['AnnotationRotationOutput']> = {
  x?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  z?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAnnotationThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AnnotationThumbnailOutput'] = TResolversParentTypes['AnnotationThumbnailOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAnnotationThumbnailUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AnnotationThumbnailUnion'] = TResolversParentTypes['AnnotationThumbnailUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'AnnotationThumbnailOutput', ParentType, ContextType>;
};

export type TAppClientOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AppClientOutput'] = TResolversParentTypes['AppClientOutput']> = {
  appClientId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  callbackUrls?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  clientSecret?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  logoutUrls?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  oAuthFlows?: Resolver<Array<TResolversTypes['OAuthFlowTypeEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationErrorInvalidArgumentOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationErrorInvalidArgumentOutput'] = TResolversParentTypes['ApplicationErrorInvalidArgumentOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput'] = TResolversParentTypes['ApplicationErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationErrorResourceAccessDeniedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationErrorResourceAccessDeniedOutput'] = TResolversParentTypes['ApplicationErrorResourceAccessDeniedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationErrorTooManyRequestsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationErrorTooManyRequestsOutput'] = TResolversParentTypes['ApplicationErrorTooManyRequestsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationTemplateAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationTemplateAppliedPageOutput'] = TResolversParentTypes['ApplicationTemplateAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationTemplateOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationTemplateOutput'] = TResolversParentTypes['ApplicationTemplateOutput']> = {
  artifact?: Resolver<Maybe<TResolversTypes['ArtifactItemOutput']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  developmentAppClient?: Resolver<TResolversTypes['AppClientOutput'], ParentType, ContextType>;
  developmentHereMapsKey?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  productionAppClient?: Resolver<TResolversTypes['AppClientOutput'], ParentType, ContextType>;
  productionHereMapsKey?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  templateVersion?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationTemplateResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationTemplateResultOutput'] = TResolversParentTypes['ApplicationTemplateResultOutput']> = {
  appliedFilters?: Resolver<TResolversTypes['FilterApplicationAppliedOutput'], ParentType, ContextType>;
  appliedPagination?: Resolver<TResolversTypes['ApplicationTemplateAppliedPageOutput'], ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['ApplicationTemplateOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<TResolversTypes['ApplicationOrderEnum'], ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TApplicationTemplateUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationTemplateUnion'] = TResolversParentTypes['ApplicationTemplateUnion']> = {
  __resolveType: TypeResolveFn<'ApplicationErrorOperationNotAllowedOutput' | 'ApplicationErrorResourceAccessDeniedOutput' | 'ApplicationErrorTooManyRequestsOutput' | 'ApplicationTemplateOutput', ParentType, ContextType>;
};

export type TApplicationTemplatesUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ApplicationTemplatesUnion'] = TResolversParentTypes['ApplicationTemplatesUnion']> = {
  __resolveType: TypeResolveFn<'ApplicationErrorOperationNotAllowedOutput' | 'ApplicationErrorTooManyRequestsOutput' | 'ApplicationTemplateResultOutput', ParentType, ContextType>;
};

export type TArtifactAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ArtifactAppliedPageOutput'] = TResolversParentTypes['ArtifactAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TArtifactItemOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ArtifactItemOutput'] = TResolversParentTypes['ArtifactItemOutput']> = {
  addresses?: Resolver<TResolversTypes['AddressPageOutput'], ParentType, ContextType, Partial<TArtifactItemOutputAddressesArgs>>;
  countsForStorage?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  dataCategory?: Resolver<Maybe<TResolversTypes['DataCategoryEnum']>, ParentType, ContextType>;
  filePath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  groupedAssetId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  jobDetail?: Resolver<Maybe<TResolversTypes['JobPage']>, ParentType, ContextType, Partial<TArtifactItemOutputJobDetailArgs>>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  savedFormat?: Resolver<Maybe<TResolversTypes['SavedFormatEnum']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['ArtifactTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TArtifactResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ArtifactResultOutput'] = TResolversParentTypes['ArtifactResultOutput']> = {
  appliedPagination?: Resolver<Maybe<TResolversTypes['ArtifactAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['ArtifactItemOutput']>, ParentType, ContextType>;
  filterType?: Resolver<Maybe<TResolversTypes['ArtifactTypeEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  parentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TArtifactResultOutputV2Resolvers<ContextType = any, ParentType extends TResolversParentTypes['ArtifactResultOutputV2'] = TResolversParentTypes['ArtifactResultOutputV2']> = {
  appliedPagination?: Resolver<Maybe<TResolversTypes['ArtifactAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['ArtifactItemOutput']>, ParentType, ContextType>;
  filterType?: Resolver<Maybe<TResolversTypes['DataCategoryEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  parentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetAppliedPageOutput'] = TResolversParentTypes['AssetAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageOffset?: Resolver<Maybe<TResolversTypes['Int']>, ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetBBoxOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetBBoxOutput'] = TResolversParentTypes['AssetBBoxOutput']> = {
  height?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  latitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  width?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetCountOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetCountOutput'] = TResolversParentTypes['AssetCountOutput']> = {
  all?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  unassigned?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorCoarseRegistrationInputNotFoundResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorCoarseRegistrationInputNotFound'] = TResolversParentTypes['AssetErrorCoarseRegistrationInputNotFound']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorCoarseRegistrationLimitOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorCoarseRegistrationLimitOutput'] = TResolversParentTypes['AssetErrorCoarseRegistrationLimitOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorDownloadLimitReachedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorDownloadLimitReachedOutput'] = TResolversParentTypes['AssetErrorDownloadLimitReachedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorDuplicateNameOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorDuplicateNameOutput'] = TResolversParentTypes['AssetErrorDuplicateNameOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorFeatureNotAvailableOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorFeatureNotAvailableOutput'] = TResolversParentTypes['AssetErrorFeatureNotAvailableOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorGroupedAssetRegistrationLimitOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorGroupedAssetRegistrationLimitOutput'] = TResolversParentTypes['AssetErrorGroupedAssetRegistrationLimitOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorGroupedAssetsNotUnderSameFolderOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorGroupedAssetsNotUnderSameFolderOutput'] = TResolversParentTypes['AssetErrorGroupedAssetsNotUnderSameFolderOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameBlacklistedCharactersOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput'] = TResolversParentTypes['AssetErrorNameBlacklistedCharactersOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameBlankOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameBlankOutput'] = TResolversParentTypes['AssetErrorNameBlankOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameForbiddenOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameForbiddenOutput'] = TResolversParentTypes['AssetErrorNameForbiddenOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameForwardSlashOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameForwardSlashOutput'] = TResolversParentTypes['AssetErrorNameForwardSlashOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameLeadingOrTrailingSpaceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput'] = TResolversParentTypes['AssetErrorNameLeadingOrTrailingSpaceOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameTrailingDotOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameTrailingDotOutput'] = TResolversParentTypes['AssetErrorNameTrailingDotOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorNameTwoDotsInRowOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput'] = TResolversParentTypes['AssetErrorNameTwoDotsInRowOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorOperationNotAllowedOutput'] = TResolversParentTypes['AssetErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorProcessingLimitReachedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorProcessingLimitReachedOutput'] = TResolversParentTypes['AssetErrorProcessingLimitReachedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorResourceAccessDeniedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorResourceAccessDeniedOutput'] = TResolversParentTypes['AssetErrorResourceAccessDeniedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorStorageLimitReachedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorStorageLimitReachedOutput'] = TResolversParentTypes['AssetErrorStorageLimitReachedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetErrorTooManyRequestsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetErrorTooManyRequestsOutput'] = TResolversParentTypes['AssetErrorTooManyRequestsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetOutput'] = TResolversParentTypes['AssetOutput']> = {
  anchorPoint?: Resolver<Maybe<TResolversTypes['Point3DOutput']>, ParentType, ContextType>;
  artifacts?: Resolver<Maybe<TResolversTypes['ArtifactResultOutput']>, ParentType, ContextType, Partial<TAssetOutputArtifactsArgs>>;
  artifactsV2?: Resolver<Maybe<TResolversTypes['ArtifactResultOutputV2']>, ParentType, ContextType, Partial<TAssetOutputArtifactsV2Args>>;
  files?: Resolver<TResolversTypes['FileResultOutput'], ParentType, ContextType, Partial<TAssetOutputFilesArgs>>;
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  jobDetail?: Resolver<Maybe<TResolversTypes['JobPage']>, ParentType, ContextType, Partial<TAssetOutputJobDetailArgs>>;
  jobSummary?: Resolver<Maybe<TResolversTypes['JobSummaryGQL']>, ParentType, ContextType>;
  referencedBounds?: Resolver<Maybe<TResolversTypes['ReferencedBoundsOutput']>, ParentType, ContextType>;
  withEmbeddedGeoreference?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetThumbnailOutput'] = TResolversParentTypes['AssetThumbnailOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetUnion'] = TResolversParentTypes['AssetUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TAssetsInAccountUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetsInAccountUnion'] = TResolversParentTypes['AssetsInAccountUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'GroupedAssetResultOutput', ParentType, ContextType>;
};

export type TAssetsInFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AssetsInFolderUnion'] = TResolversParentTypes['AssetsInFolderUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'FolderOutput', ParentType, ContextType>;
};

export type TAutoRegisterAssetsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['AutoRegisterAssetsUnion'] = TResolversParentTypes['AutoRegisterAssetsUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorCoarseRegistrationInputNotFound' | 'AssetErrorCoarseRegistrationLimitOutput' | 'AssetErrorDuplicateNameOutput' | 'AssetErrorFeatureNotAvailableOutput' | 'AssetErrorGroupedAssetRegistrationLimitOutput' | 'AssetErrorGroupedAssetsNotUnderSameFolderOutput' | 'AssetErrorNameBlacklistedCharactersOutput' | 'AssetErrorNameBlankOutput' | 'AssetErrorNameForbiddenOutput' | 'AssetErrorNameForwardSlashOutput' | 'AssetErrorNameLeadingOrTrailingSpaceOutput' | 'AssetErrorNameTrailingDotOutput' | 'AssetErrorNameTwoDotsInRowOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorProcessingLimitReachedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorStorageLimitReachedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TBaseAccountInfoErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseAccountInfoErrorInterface'] = TResolversParentTypes['BaseAccountInfoErrorInterface']> = {
  __resolveType: TypeResolveFn<'AccountInfoAccountAddressIsNotValidOutput' | 'AccountInfoAccountCompanyNameIsNotValidOutput' | 'AccountInfoAccountEmailIsNotValidOutput' | 'AccountInfoAccountLegalEntityCouldNotBeChangedOutput' | 'AccountInfoErrorOperationNotAllowedOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseAcsErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseAcsErrorInterface'] = TResolversParentTypes['BaseAcsErrorInterface']> = {
  __resolveType: TypeResolveFn<'AcsErrorEntityNotFoundOutput' | 'AcsErrorInvalidInvitationSignatureOutput' | 'AcsErrorInvalidVoucherOutput' | 'AcsErrorOperationNotAllowedOutput' | 'AcsErrorOperationNotPossibleOutput' | 'AcsErrorProjectNotFoundOutput' | 'AcsErrorSeatLimitReachedOutput' | 'AcsErrorTenantNotFoundOutput' | 'AcsErrorTooManyRequestsOutput' | 'AcsErrorUserAlreadyExistsInAccountOutput' | 'AcsErrorUserAlreadyExistsInOtherAccountOutput' | 'AcsErrorUserAlreadyExistsOutput' | 'AcsErrorUserNotFoundOutput' | 'AcsErrorVoucherAlreadyUsedOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseAnnotationErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseAnnotationErrorInterface'] = TResolversParentTypes['BaseAnnotationErrorInterface']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseApplicationErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseApplicationErrorInterface'] = TResolversParentTypes['BaseApplicationErrorInterface']> = {
  __resolveType: TypeResolveFn<'ApplicationErrorInvalidArgumentOutput' | 'ApplicationErrorOperationNotAllowedOutput' | 'ApplicationErrorResourceAccessDeniedOutput' | 'ApplicationErrorTooManyRequestsOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseAssetErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseAssetErrorInterface'] = TResolversParentTypes['BaseAssetErrorInterface']> = {
  __resolveType: TypeResolveFn<'AssetErrorCoarseRegistrationInputNotFound' | 'AssetErrorCoarseRegistrationLimitOutput' | 'AssetErrorDownloadLimitReachedOutput' | 'AssetErrorDuplicateNameOutput' | 'AssetErrorFeatureNotAvailableOutput' | 'AssetErrorGroupedAssetRegistrationLimitOutput' | 'AssetErrorGroupedAssetsNotUnderSameFolderOutput' | 'AssetErrorNameBlacklistedCharactersOutput' | 'AssetErrorNameBlankOutput' | 'AssetErrorNameForbiddenOutput' | 'AssetErrorNameForwardSlashOutput' | 'AssetErrorNameLeadingOrTrailingSpaceOutput' | 'AssetErrorNameTrailingDotOutput' | 'AssetErrorNameTwoDotsInRowOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorProcessingLimitReachedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorStorageLimitReachedOutput' | 'AssetErrorTooManyRequestsOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseFolderErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseFolderErrorInterface'] = TResolversParentTypes['BaseFolderErrorInterface']> = {
  __resolveType: TypeResolveFn<'FolderErrorDuplicateNameOutput' | 'FolderErrorNameBlacklistedCharactersOutput' | 'FolderErrorNameBlankOutput' | 'FolderErrorNameForbiddenOutput' | 'FolderErrorNameForwardSlashOutput' | 'FolderErrorNameLeadingOrTrailingSpaceOutput' | 'FolderErrorNameTrailingDotOutput' | 'FolderErrorNameTwoDotsInRowOutput' | 'FolderErrorOperationNotAllowedOutput' | 'FolderErrorResourceAccessDeniedOutput' | 'FolderErrorResourceNotFoundOutput' | 'FolderErrorTooManyRequestsOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseLabelErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseLabelErrorInterface'] = TResolversParentTypes['BaseLabelErrorInterface']> = {
  __resolveType: TypeResolveFn<'LabelErrorOperationNotAllowedOutput' | 'LabelErrorTooManyLabelsOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseLicenseErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseLicenseErrorInterface'] = TResolversParentTypes['BaseLicenseErrorInterface']> = {
  __resolveType: TypeResolveFn<'LicenseErrorInvalidArgumentOutput' | 'LicenseErrorLicenseNotAvailableOutput' | 'LicenseErrorOperationNotAllowedOutput' | 'LicenseErrorTooManyRequestsOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseProcessingErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseProcessingErrorInterface'] = TResolversParentTypes['BaseProcessingErrorInterface']> = {
  __resolveType: TypeResolveFn<'ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput' | 'ProcessingErrorInternalServerErrorOutput' | 'ProcessingErrorInvalidInputForProcessingPaymentErrorOutput' | 'ProcessingErrorOperationNotAllowedOutput' | 'ProcessingErrorProcessingLimitReachedOutput' | 'ProcessingErrorResourceAccessDeniedOutput' | 'ProcessingErrorResourceNotFoundOutput' | 'ProcessingErrorStorageLimitReachedOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseProjectErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseProjectErrorInterface'] = TResolversParentTypes['BaseProjectErrorInterface']> = {
  __resolveType: TypeResolveFn<'ProjectErrorDuplicateNameOutput' | 'ProjectErrorInvalidInputOutput' | 'ProjectErrorNameBlacklistedCharactersOutput' | 'ProjectErrorNameBlankOutput' | 'ProjectErrorNameForbiddenOutput' | 'ProjectErrorNameForwardSlashOutput' | 'ProjectErrorNameLeadingOrTrailingSpaceOutput' | 'ProjectErrorNameTrailingDotOutput' | 'ProjectErrorNameTwoDotsInRowOutput' | 'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedForTargetUserOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseQuotationErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseQuotationErrorInterface'] = TResolversParentTypes['BaseQuotationErrorInterface']> = {
  __resolveType: TypeResolveFn<'QuotationErrorProductNotFoundOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseShopErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseShopErrorInterface'] = TResolversParentTypes['BaseShopErrorInterface']> = {
  __resolveType: TypeResolveFn<'ShopErrorBackendFailedOutput' | 'ShopErrorNoOverlapWithProductOutput' | 'ShopErrorOperationNotAllowedOutput' | 'ShopErrorProductNotFoundOutput' | 'ShopErrorProductPricingNotFoundOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaseSubscriptionErrorInterfaceResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaseSubscriptionErrorInterface'] = TResolversParentTypes['BaseSubscriptionErrorInterface']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorBadRequestOutput' | 'SubscriptionErrorDuplicateWithSameAddonOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TBaselayerAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerAppliedPageOutput'] = TResolversParentTypes['BaselayerAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerHspcOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerHspcOutput'] = TResolversParentTypes['BaselayerHspcOutput']> = {
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerLtsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerLtsOutput'] = TResolversParentTypes['BaselayerLtsOutput']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  contentType?: Resolver<TResolversTypes['AddressContentTypeEnum'], ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  level0Columns?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  level0Rows?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  levelCount?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  samplingMode?: Resolver<TResolversTypes['AddressSamplingModeEnum'], ParentType, ContextType>;
  tileHeight?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  tileWidth?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerOgc3DOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerOgc3DOutput'] = TResolversParentTypes['BaselayerOgc3DOutput']> = {
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  qualityFactor?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerOutput'] = TResolversParentTypes['BaselayerOutput']> = {
  __resolveType: TypeResolveFn<'BaselayerHspcOutput' | 'BaselayerLtsOutput' | 'BaselayerOgc3DOutput' | 'BaselayerPanoramicOutput' | 'BaselayerUrlOutput' | 'BaselayerWfsOutput' | 'BaselayerWmsOutput' | 'BaselayerWmtsOutput', ParentType, ContextType>;
};

export type TBaselayerPanoramicOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerPanoramicOutput'] = TResolversParentTypes['BaselayerPanoramicOutput']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  imageServerPrefix?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerResultOutput'] = TResolversParentTypes['BaselayerResultOutput']> = {
  appliedPagination?: Resolver<Maybe<TResolversTypes['BaselayerAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['BaselayerOutput']>, ParentType, ContextType>;
  filterOrigin?: Resolver<Maybe<TResolversTypes['BaselayerOriginEnum']>, ParentType, ContextType>;
  filterType?: Resolver<Maybe<TResolversTypes['AddressViewingModeEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerUrlOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerUrlOutput'] = TResolversParentTypes['BaselayerUrlOutput']> = {
  boundsEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerWfsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerWfsOutput'] = TResolversParentTypes['BaselayerWfsOutput']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  styleUrl?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  versions?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerWmsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerWmsOutput'] = TResolversParentTypes['BaselayerWmsOutput']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  imageFormat?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  versions?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TBaselayerWmtsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['BaselayerWmtsOutput'] = TResolversParentTypes['BaselayerWmtsOutput']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  contentType?: Resolver<TResolversTypes['AddressContentTypeEnum'], ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  imageFormat?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  level0Columns?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  level0Rows?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  levelCount?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  samplingMode?: Resolver<TResolversTypes['AddressSamplingModeEnum'], ParentType, ContextType>;
  tileHeight?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  tileMatrices?: Resolver<Array<TResolversTypes['Int']>, ParentType, ContextType>;
  tileMatrixSet?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tileWidth?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TBigDecimalScalarConfig extends GraphQLScalarTypeConfig<TResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface TBigIntegerScalarConfig extends GraphQLScalarTypeConfig<TResolversTypes['BigInteger'], any> {
  name: 'BigInteger';
}

export type TCancelCurrentSubscriptionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CancelCurrentSubscriptionOutput'] = TResolversParentTypes['CancelCurrentSubscriptionOutput']> = {
  success?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCancelCurrentSubscriptionUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CancelCurrentSubscriptionUnion'] = TResolversParentTypes['CancelCurrentSubscriptionUnion']> = {
  __resolveType: TypeResolveFn<'CancelCurrentSubscriptionOutput' | 'SubscriptionErrorFeatureNotAvailableOutput', ParentType, ContextType>;
};

export type TCartItemOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CartItemOutput'] = TResolversParentTypes['CartItemOutput']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  bbox?: Resolver<Maybe<Array<TResolversTypes['Float']>>, ParentType, ContextType>;
  cartId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  cartItemId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  coordinateSystem?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  dataType?: Resolver<TResolversTypes['DataTypeEnum'], ParentType, ContextType>;
  discountedAmount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  imageFormat?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  polygonDefType?: Resolver<TResolversTypes['PolygonType'], ParentType, ContextType>;
  polygonPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  productIdentifier?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  resolutionUnit?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  resolutionValue?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCartItemThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CartItemThumbnailOutput'] = TResolversParentTypes['CartItemThumbnailOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCartOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CartOutput'] = TResolversParentTypes['CartOutput']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  cartId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  cartItems?: Resolver<Array<TResolversTypes['CartItemOutput']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  discountedAmount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  orderId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  paymentPublicKey?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCartesianBoundsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CartesianBoundsOutput'] = TResolversParentTypes['CartesianBoundsOutput']> = {
  dimensions?: Resolver<TResolversTypes['ReferencedBoundsDimensionsOutput'], ParentType, ContextType>;
  origin?: Resolver<TResolversTypes['Point3DOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TChangeProjectRoleOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ChangeProjectRoleOutput'] = TResolversParentTypes['ChangeProjectRoleOutput']> = {
  result?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TChangeProjectRoleUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ChangeProjectRoleUnion'] = TResolversParentTypes['ChangeProjectRoleUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'AcsErrorOperationNotPossibleOutput' | 'AcsErrorSeatLimitReachedOutput' | 'ChangeProjectRoleOutput', ParentType, ContextType>;
};

export type TCheckoutOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CheckoutOutput'] = TResolversParentTypes['CheckoutOutput']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  cartId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  discountedAmount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  paymentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  paymentPublicKey?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  paymentSessionId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCollectionResolvers<ContextType = any, ParentType extends TResolversParentTypes['Collection'] = TResolversParentTypes['Collection']> = {
  crs?: Resolver<Maybe<Array<TResolversTypes['String']>>, ParentType, ContextType>;
  description?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  extent?: Resolver<TResolversTypes['Extent'], ParentType, ContextType>;
  features?: Resolver<TResolversTypes['Feature'], ParentType, ContextType, Partial<TCollectionFeaturesArgs>>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  itemType?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  keywords?: Resolver<Array<Maybe<TResolversTypes['String']>>, ParentType, ContextType>;
  license?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  providers?: Resolver<Maybe<Array<TResolversTypes['Provider']>>, ParentType, ContextType>;
  stacVersion?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  summaries?: Resolver<Maybe<Array<TResolversTypes['Summary']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCommentResolvers<ContextType = any, ParentType extends TResolversParentTypes['Comment'] = TResolversParentTypes['Comment']> = {
  anchor?: Resolver<TResolversTypes['CommentAnchor'], ParentType, ContextType>;
  children?: Resolver<TResolversTypes['CommentConnection'], ParentType, ContextType, Partial<TCommentChildrenArgs>>;
  content?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  parentId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCommentAnchorResolvers<ContextType = any, ParentType extends TResolversParentTypes['CommentAnchor'] = TResolversParentTypes['CommentAnchor']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['CommentAnchorType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCommentConnectionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CommentConnection'] = TResolversParentTypes['CommentConnection']> = {
  edges?: Resolver<Array<TResolversTypes['CommentEdge']>, ParentType, ContextType>;
  nodes?: Resolver<Array<TResolversTypes['Comment']>, ParentType, ContextType>;
  pageInfo?: Resolver<TResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCommentEdgeResolvers<ContextType = any, ParentType extends TResolversParentTypes['CommentEdge'] = TResolversParentTypes['CommentEdge']> = {
  cursor?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<TResolversTypes['Comment'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCommentPayloadResolvers<ContextType = any, ParentType extends TResolversParentTypes['CommentPayload'] = TResolversParentTypes['CommentPayload']> = {
  __resolveType: TypeResolveFn<'AccessDeniedError' | 'Comment' | 'NotFoundError' | 'ServerError' | 'UnauthenticatedError' | 'ValidationError', ParentType, ContextType>;
};

export type TCommentsPayloadResolvers<ContextType = any, ParentType extends TResolversParentTypes['CommentsPayload'] = TResolversParentTypes['CommentsPayload']> = {
  __resolveType: TypeResolveFn<'AccessDeniedError' | 'CommentConnection' | 'ServerError' | 'UnauthenticatedError' | 'ValidationError', ParentType, ContextType>;
};

export type TConfirmSubscriptionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ConfirmSubscriptionOutput'] = TResolversParentTypes['ConfirmSubscriptionOutput']> = {
  success?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TConfirmSubscriptionUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ConfirmSubscriptionUnion'] = TResolversParentTypes['ConfirmSubscriptionUnion']> = {
  __resolveType: TypeResolveFn<'ConfirmSubscriptionOutput', ParentType, ContextType>;
};

export type TConnectionResolvers<ContextType = any, ParentType extends TResolversParentTypes['Connection'] = TResolversParentTypes['Connection']> = {
  __resolveType: TypeResolveFn<'CommentConnection', ParentType, ContextType>;
  edges?: Resolver<Maybe<Array<TResolversTypes['Edge']>>, ParentType, ContextType>;
  nodes?: Resolver<Maybe<Array<TResolversTypes['Node']>>, ParentType, ContextType>;
  pageInfo?: Resolver<TResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
};

export type TCoordinateSystemOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CoordinateSystemOutput'] = TResolversParentTypes['CoordinateSystemOutput']> = {
  datum?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  epsg?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  projection?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCopyAssetToFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CopyAssetToFolderUnion'] = TResolversParentTypes['CopyAssetToFolderUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorDuplicateNameOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorStorageLimitReachedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TCoreBaselayerResolvers<ContextType = any, ParentType extends TResolversParentTypes['CoreBaselayer'] = TResolversParentTypes['CoreBaselayer']> = {
  __resolveType: TypeResolveFn<'BaselayerHspcOutput' | 'BaselayerLtsOutput' | 'BaselayerOgc3DOutput' | 'BaselayerPanoramicOutput' | 'BaselayerUrlOutput' | 'BaselayerWfsOutput' | 'BaselayerWmsOutput' | 'BaselayerWmtsOutput', ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['AddressViewingModeEnum'], ParentType, ContextType>;
};

export type TCoreLayerResolvers<ContextType = any, ParentType extends TResolversParentTypes['CoreLayer'] = TResolversParentTypes['CoreLayer']> = {
  __resolveType: TypeResolveFn<'LayerAddressHspc' | 'LayerAddressLts' | 'LayerAddressOgc3DTiles' | 'LayerAddressWms' | 'LayerAddressWmts', ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  signatureExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  sourceEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TCountryOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['CountryOutput'] = TResolversParentTypes['CountryOutput']> = {
  addressesRequireRegion?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  alpha3Code?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  countryIsoCode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  countryName?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  isEuropeanUnion?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  localizedNames?: Resolver<Array<Maybe<TResolversTypes['LocalizedNameOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TCreateApplicationTemplateUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateApplicationTemplateUnion'] = TResolversParentTypes['CreateApplicationTemplateUnion']> = {
  __resolveType: TypeResolveFn<'ApplicationErrorInvalidArgumentOutput' | 'ApplicationErrorOperationNotAllowedOutput' | 'ApplicationErrorTooManyRequestsOutput' | 'ApplicationTemplateOutput', ParentType, ContextType>;
};

export type TCreateAssetThumbnailUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateAssetThumbnailUnion'] = TResolversParentTypes['CreateAssetThumbnailUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorTooManyRequestsOutput' | 'AssetThumbnailOutput', ParentType, ContextType>;
};

export type TCreateAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateAssetUnion'] = TResolversParentTypes['CreateAssetUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorDuplicateNameOutput' | 'AssetErrorNameBlacklistedCharactersOutput' | 'AssetErrorNameBlankOutput' | 'AssetErrorNameForbiddenOutput' | 'AssetErrorNameForwardSlashOutput' | 'AssetErrorNameLeadingOrTrailingSpaceOutput' | 'AssetErrorNameTrailingDotOutput' | 'AssetErrorNameTwoDotsInRowOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TCreateFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateFolderUnion'] = TResolversParentTypes['CreateFolderUnion']> = {
  __resolveType: TypeResolveFn<'FolderErrorDuplicateNameOutput' | 'FolderErrorNameBlacklistedCharactersOutput' | 'FolderErrorNameBlankOutput' | 'FolderErrorNameForbiddenOutput' | 'FolderErrorNameForwardSlashOutput' | 'FolderErrorNameLeadingOrTrailingSpaceOutput' | 'FolderErrorNameTrailingDotOutput' | 'FolderErrorNameTwoDotsInRowOutput' | 'FolderErrorOperationNotAllowedOutput' | 'FolderErrorResourceAccessDeniedOutput' | 'FolderErrorTooManyRequestsOutput' | 'FolderOutput', ParentType, ContextType>;
};

export type TCreateLabelAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateLabelAnnotationUnion'] = TResolversParentTypes['CreateLabelAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'LabelAnnotationOutput', ParentType, ContextType>;
};

export type TCreateLimitingBoxAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateLimitingBoxAnnotationUnion'] = TResolversParentTypes['CreateLimitingBoxAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'LimitingBoxAnnotationOutput', ParentType, ContextType>;
};

export type TCreateMeasurementAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateMeasurementAnnotationUnion'] = TResolversParentTypes['CreateMeasurementAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'MeasurementAnnotationOutput', ParentType, ContextType>;
};

export type TCreateProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['CreateProjectUnion'] = TResolversParentTypes['CreateProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorDuplicateNameOutput' | 'ProjectErrorInvalidInputOutput' | 'ProjectErrorNameBlacklistedCharactersOutput' | 'ProjectErrorNameBlankOutput' | 'ProjectErrorNameForbiddenOutput' | 'ProjectErrorNameForwardSlashOutput' | 'ProjectErrorNameLeadingOrTrailingSpaceOutput' | 'ProjectErrorNameTrailingDotOutput' | 'ProjectErrorNameTwoDotsInRowOutput' | 'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'ProjectOutput', ParentType, ContextType>;
};

export interface TDateTimeScalarConfig extends GraphQLScalarTypeConfig<TResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type TDeleteAnnotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteAnnotationOutput'] = TResolversParentTypes['DeleteAnnotationOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteAnnotationUnion'] = TResolversParentTypes['DeleteAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'DeleteAnnotationOutput', ParentType, ContextType>;
};

export type TDeleteApplicationTemplateOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteApplicationTemplateOutput'] = TResolversParentTypes['DeleteApplicationTemplateOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteApplicationTemplateUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteApplicationTemplateUnion'] = TResolversParentTypes['DeleteApplicationTemplateUnion']> = {
  __resolveType: TypeResolveFn<'ApplicationErrorOperationNotAllowedOutput' | 'ApplicationErrorResourceAccessDeniedOutput' | 'ApplicationErrorTooManyRequestsOutput' | 'DeleteApplicationTemplateOutput', ParentType, ContextType>;
};

export type TDeleteAssetOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteAssetOutput'] = TResolversParentTypes['DeleteAssetOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteAssetThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteAssetThumbnailOutput'] = TResolversParentTypes['DeleteAssetThumbnailOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteAssetThumbnailUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteAssetThumbnailUnion'] = TResolversParentTypes['DeleteAssetThumbnailUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorTooManyRequestsOutput' | 'DeleteAssetThumbnailOutput', ParentType, ContextType>;
};

export type TDeleteAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteAssetUnion'] = TResolversParentTypes['DeleteAssetUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorTooManyRequestsOutput' | 'DeleteAssetOutput', ParentType, ContextType>;
};

export type TDeleteFolderOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteFolderOutput'] = TResolversParentTypes['DeleteFolderOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteFolderUnion'] = TResolversParentTypes['DeleteFolderUnion']> = {
  __resolveType: TypeResolveFn<'DeleteFolderOutput' | 'FolderErrorOperationNotAllowedOutput' | 'FolderErrorResourceAccessDeniedOutput' | 'FolderErrorResourceNotFoundOutput' | 'FolderErrorTooManyRequestsOutput', ParentType, ContextType>;
};

export type TDeleteProjectOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteProjectOutput'] = TResolversParentTypes['DeleteProjectOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteProjectThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteProjectThumbnailOutput'] = TResolversParentTypes['DeleteProjectThumbnailOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeleteProjectThumbnailUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteProjectThumbnailUnion'] = TResolversParentTypes['DeleteProjectThumbnailUnion']> = {
  __resolveType: TypeResolveFn<'DeleteProjectThumbnailOutput' | 'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput', ParentType, ContextType>;
};

export type TDeleteProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeleteProjectUnion'] = TResolversParentTypes['DeleteProjectUnion']> = {
  __resolveType: TypeResolveFn<'DeleteProjectOutput' | 'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput', ParentType, ContextType>;
};

export type TDeletedPaymentCardOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeletedPaymentCardOutput'] = TResolversParentTypes['DeletedPaymentCardOutput']> = {
  deleted?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  externalPaymentCardId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeviceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeviceOutput'] = TResolversParentTypes['DeviceOutput']> = {
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  deviceType?: Resolver<TResolversTypes['DeviceTypeEnum'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  provisionId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  user?: Resolver<Maybe<TResolversTypes['UserProfileOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TDeviceResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['DeviceResultOutput'] = TResolversParentTypes['DeviceResultOutput']> = {
  accountUUID?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['DeviceOutput']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TEdgeResolvers<ContextType = any, ParentType extends TResolversParentTypes['Edge'] = TResolversParentTypes['Edge']> = {
  __resolveType: TypeResolveFn<'CommentEdge', ParentType, ContextType>;
  cursor?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<TResolversTypes['Node'], ParentType, ContextType>;
};

export type TEntityReferenceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['EntityReferenceOutput'] = TResolversParentTypes['EntityReferenceOutput']> = {
  entityId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  entityType?: Resolver<TResolversTypes['EntityTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TErrorResolvers<ContextType = any, ParentType extends TResolversParentTypes['Error'] = TResolversParentTypes['Error']> = {
  __resolveType: TypeResolveFn<'AccessDeniedError' | 'NotFoundError' | 'ServerError' | 'UnauthenticatedError' | 'ValidationError', ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
};

export type TExceededLimitOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ExceededLimitOutput'] = TResolversParentTypes['ExceededLimitOutput']> = {
  addonAvailable?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  currentValue?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['SubscriptionLimitEnum'], ParentType, ContextType>;
  value?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TExecutedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ExecutedOutput'] = TResolversParentTypes['ExecutedOutput']> = {
  executed?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  message?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TExtentResolvers<ContextType = any, ParentType extends TResolversParentTypes['Extent'] = TResolversParentTypes['Extent']> = {
  spatial?: Resolver<TResolversTypes['PropertySpatial'], ParentType, ContextType>;
  temporal?: Resolver<TResolversTypes['PropertyTemporal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TExternalAccountMembersResultUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ExternalAccountMembersResultUnion'] = TResolversParentTypes['ExternalAccountMembersResultUnion']> = {
  __resolveType: TypeResolveFn<'AccountMembersResultOutput' | 'AcsErrorOperationNotAllowedOutput', ParentType, ContextType>;
};

export type TFeatureResolvers<ContextType = any, ParentType extends TResolversParentTypes['Feature'] = TResolversParentTypes['Feature']> = {
  contents?: Resolver<Array<Maybe<TResolversTypes['FeatureItem']>>, ParentType, ContextType>;
  returned?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFeatureAssetResolvers<ContextType = any, ParentType extends TResolversParentTypes['FeatureAsset'] = TResolversParentTypes['FeatureAsset']> = {
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  href?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<TResolversTypes['String']>>, ParentType, ContextType>;
  title?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFeatureAssetsResolvers<ContextType = any, ParentType extends TResolversParentTypes['FeatureAssets'] = TResolversParentTypes['FeatureAssets']> = {
  samples?: Resolver<Maybe<Array<TResolversTypes['FeatureAsset']>>, ParentType, ContextType>;
  shapefile?: Resolver<Maybe<TResolversTypes['FeatureAsset']>, ParentType, ContextType>;
  thumbnail?: Resolver<TResolversTypes['FeatureAsset'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFeatureItemResolvers<ContextType = any, ParentType extends TResolversParentTypes['FeatureItem'] = TResolversParentTypes['FeatureItem']> = {
  assets?: Resolver<TResolversTypes['FeatureAssets'], ParentType, ContextType>;
  bbox?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  geometry?: Resolver<TResolversTypes['GeoJSONShape'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  properties?: Resolver<TResolversTypes['FeatureProperties'], ParentType, ContextType>;
  stacVersion?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['ItemType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFeaturePropertiesResolvers<ContextType = any, ParentType extends TResolversParentTypes['FeatureProperties'] = TResolversParentTypes['FeatureProperties']> = {
  data_type?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  dateTime?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  detailedGeometry?: Resolver<Maybe<TResolversTypes['GeoJSONShape']>, ParentType, ContextType>;
  endDateTime?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  format?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  layerAddress?: Resolver<Maybe<TResolversTypes['LayerAddress']>, ParentType, ContextType>;
  productCode?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  provider?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  referenceToRawOutput?: Resolver<Maybe<Array<TResolversTypes['String']>>, ParentType, ContextType>;
  sensorType?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  srs?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  startDateTime?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  title?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  year?: Resolver<Maybe<TResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFileAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FileAppliedPageOutput'] = TResolversParentTypes['FileAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFileFilterAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FileFilterAppliedOutput'] = TResolversParentTypes['FileFilterAppliedOutput']> = {
  byFileName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  byUploadStatus?: Resolver<Maybe<Array<Maybe<TResolversTypes['UploadStatusEnum']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFileOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FileOutput'] = TResolversParentTypes['FileOutput']> = {
  asset?: Resolver<Maybe<TResolversTypes['AssetOutput']>, ParentType, ContextType>;
  assetPath?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  downloadLink?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  fileSize?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  parts?: Resolver<Maybe<TResolversTypes['MultipartUploadPartsOutput']>, ParentType, ContextType>;
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<TResolversTypes['UploadStatusEnum'], ParentType, ContextType>;
  uploadExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFileResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FileResultOutput'] = TResolversParentTypes['FileResultOutput']> = {
  appliedFilters?: Resolver<Maybe<TResolversTypes['FileFilterAppliedOutput']>, ParentType, ContextType>;
  appliedPagination?: Resolver<Maybe<TResolversTypes['FileAppliedPageOutput']>, ParentType, ContextType>;
  asset?: Resolver<Maybe<TResolversTypes['AssetOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['FileStatusOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['FileOrderEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFileStatusOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FileStatusOutput'] = TResolversParentTypes['FileStatusOutput']> = {
  asset?: Resolver<Maybe<TResolversTypes['AssetOutput']>, ParentType, ContextType>;
  files?: Resolver<Maybe<Array<TResolversTypes['FileOutput']>>, ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  uploadStatus?: Resolver<Maybe<TResolversTypes['UploadStatusEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterAccountMembersOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterAccountMembersOutput'] = TResolversParentTypes['FilterAccountMembersOutput']> = {
  byNameOrEmail?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterAccountProfileAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterAccountProfileAppliedOutput'] = TResolversParentTypes['FilterAccountProfileAppliedOutput']> = {
  byCompanyName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  tenantUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterApplicationAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterApplicationAppliedOutput'] = TResolversParentTypes['FilterApplicationAppliedOutput']> = {
  byCreatedAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  byName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterAssetAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterAssetAppliedOutput'] = TResolversParentTypes['FilterAssetAppliedOutput']> = {
  byAssetName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  byAssetStatus?: Resolver<Maybe<TResolversTypes['AssetStatusEnum']>, ParentType, ContextType>;
  byAssetType?: Resolver<Maybe<TResolversTypes['AssetTypeEnum']>, ParentType, ContextType>;
  byGeoreferenceFlag?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  byLabels?: Resolver<Maybe<Array<TResolversTypes['ID']>>, ParentType, ContextType>;
  not?: Resolver<Maybe<TResolversTypes['FilterAssetNotOutput']>, ParentType, ContextType>;
  or?: Resolver<Maybe<TResolversTypes['FilterAssetOrOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterAssetNotOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterAssetNotOutput'] = TResolversParentTypes['FilterAssetNotOutput']> = {
  byAssetStatus?: Resolver<Maybe<TResolversTypes['AssetStatusEnum']>, ParentType, ContextType>;
  byAssetType?: Resolver<Maybe<TResolversTypes['AssetTypeEnum']>, ParentType, ContextType>;
  not?: Resolver<Maybe<TResolversTypes['FilterAssetNotOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterAssetOrOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterAssetOrOutput'] = TResolversParentTypes['FilterAssetOrOutput']> = {
  byAssetStatus?: Resolver<Maybe<TResolversTypes['AssetStatusEnum']>, ParentType, ContextType>;
  byAssetType?: Resolver<Maybe<TResolversTypes['AssetTypeEnum']>, ParentType, ContextType>;
  or?: Resolver<Maybe<TResolversTypes['FilterAssetOrOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterFolderAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterFolderAppliedOutput'] = TResolversParentTypes['FilterFolderAppliedOutput']> = {
  byFolderName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterProjectAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterProjectAppliedOutput'] = TResolversParentTypes['FilterProjectAppliedOutput']> = {
  byCreatedAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  byOwnedBy?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  byProjectName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFilterUserProfileAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FilterUserProfileAppliedOutput'] = TResolversParentTypes['FilterUserProfileAppliedOutput']> = {
  bySearchTerm?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  tenantUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderAppliedPageOutput'] = TResolversParentTypes['FolderAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageOffset?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorDuplicateNameOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorDuplicateNameOutput'] = TResolversParentTypes['FolderErrorDuplicateNameOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameBlacklistedCharactersOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameBlacklistedCharactersOutput'] = TResolversParentTypes['FolderErrorNameBlacklistedCharactersOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameBlankOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameBlankOutput'] = TResolversParentTypes['FolderErrorNameBlankOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameForbiddenOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameForbiddenOutput'] = TResolversParentTypes['FolderErrorNameForbiddenOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameForwardSlashOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameForwardSlashOutput'] = TResolversParentTypes['FolderErrorNameForwardSlashOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameLeadingOrTrailingSpaceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameLeadingOrTrailingSpaceOutput'] = TResolversParentTypes['FolderErrorNameLeadingOrTrailingSpaceOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameTrailingDotOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameTrailingDotOutput'] = TResolversParentTypes['FolderErrorNameTrailingDotOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorNameTwoDotsInRowOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorNameTwoDotsInRowOutput'] = TResolversParentTypes['FolderErrorNameTwoDotsInRowOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorOperationNotAllowedOutput'] = TResolversParentTypes['FolderErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorResourceAccessDeniedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorResourceAccessDeniedOutput'] = TResolversParentTypes['FolderErrorResourceAccessDeniedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorResourceNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorResourceNotFoundOutput'] = TResolversParentTypes['FolderErrorResourceNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderErrorTooManyRequestsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderErrorTooManyRequestsOutput'] = TResolversParentTypes['FolderErrorTooManyRequestsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderOutput'] = TResolversParentTypes['FolderOutput']> = {
  assets?: Resolver<TResolversTypes['GroupedAssetResultOutput'], ParentType, ContextType, Partial<TFolderOutputAssetsArgs>>;
  createdAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  folders?: Resolver<TResolversTypes['FolderResultOutput'], ParentType, ContextType, Partial<TFolderOutputFoldersArgs>>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  isNestingLevelReached?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  isRootFolder?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  nestingLevel?: Resolver<Maybe<TResolversTypes['Int']>, ParentType, ContextType>;
  parentFolder?: Resolver<Maybe<TResolversTypes['FolderOutput']>, ParentType, ContextType>;
  project?: Resolver<Maybe<TResolversTypes['ProjectOutput']>, ParentType, ContextType>;
  version?: Resolver<Maybe<TResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderResultOutput'] = TResolversParentTypes['FolderResultOutput']> = {
  appliedFilters?: Resolver<Maybe<TResolversTypes['FilterFolderAppliedOutput']>, ParentType, ContextType>;
  appliedPagination?: Resolver<Maybe<TResolversTypes['FolderAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['FolderOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['FolderOrderEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['FolderUnion'] = TResolversParentTypes['FolderUnion']> = {
  __resolveType: TypeResolveFn<'FolderErrorOperationNotAllowedOutput' | 'FolderErrorResourceAccessDeniedOutput' | 'FolderErrorResourceNotFoundOutput' | 'FolderErrorTooManyRequestsOutput' | 'FolderOutput', ParentType, ContextType>;
};

export type TGatewayInfoOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GatewayInfoOutput'] = TResolversParentTypes['GatewayInfoOutput']> = {
  version?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGenerateInvitationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['GenerateInvitationUnion'] = TResolversParentTypes['GenerateInvitationUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'AcsErrorSeatLimitReachedOutput' | 'AcsErrorUserAlreadyExistsInAccountOutput' | 'AcsErrorUserAlreadyExistsInOtherAccountOutput' | 'InvitationOutput', ParentType, ContextType>;
};

export type TGenerateMultiAssetDownloadLinkUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['GenerateMultiAssetDownloadLinkUnion'] = TResolversParentTypes['GenerateMultiAssetDownloadLinkUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorDownloadLimitReachedOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'MultiAssetDownloadLinkOutput', ParentType, ContextType>;
};

export type TGeoJsonShapeResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoJSONShape'] = TResolversParentTypes['GeoJSONShape']> = {
  bbox?: Resolver<Maybe<Array<TResolversTypes['Float']>>, ParentType, ContextType>;
  coordinates?: Resolver<TResolversTypes['JSON'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['GeoJSONCoordinateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGeoreferenceAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoreferenceAppliedPageOutput'] = TResolversParentTypes['GeoreferenceAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGeoreferenceAppliedPaginationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoreferenceAppliedPaginationOutput'] = TResolversParentTypes['GeoreferenceAppliedPaginationOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGeoreferenceArtifactResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoreferenceArtifactResultOutput'] = TResolversParentTypes['GeoreferenceArtifactResultOutput']> = {
  artifact?: Resolver<TResolversTypes['ArtifactItemOutput'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  visible?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGeoreferenceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoreferenceOutput'] = TResolversParentTypes['GeoreferenceOutput']> = {
  altitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  anchorX?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  anchorY?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  anchorZ?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  flattenEnabled?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  flattenScale?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  pitch?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  roll?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  scaleX?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  scaleY?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  scaleZ?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  visible?: Resolver<Array<TResolversTypes['GeoreferenceVisibilityOutput']>, ParentType, ContextType>;
  yaw?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGeoreferenceResultsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoreferenceResultsOutput'] = TResolversParentTypes['GeoreferenceResultsOutput']> = {
  appliedPagination?: Resolver<Maybe<TResolversTypes['GeoreferenceAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['GeoreferenceOutput']>>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGeoreferenceVisibilityOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GeoreferenceVisibilityOutput'] = TResolversParentTypes['GeoreferenceVisibilityOutput']> = {
  type?: Resolver<TResolversTypes['ArtifactTypeEnum'], ParentType, ContextType>;
  visible?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetGeoreferenceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetGeoreferenceOutput'] = TResolversParentTypes['GroupedAssetGeoreferenceOutput']> = {
  altitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  anchorX?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  anchorY?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  anchorZ?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  artifacts?: Resolver<Array<TResolversTypes['GeoreferenceArtifactResultOutput']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  flattenEnabled?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  flattenScale?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  latitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  parentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  parentType?: Resolver<TResolversTypes['ParentTypeEnum'], ParentType, ContextType>;
  pitch?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  roll?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  scaleX?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  scaleY?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  scaleZ?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  yaw?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetGeoreferenceResultsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetGeoreferenceResultsOutput'] = TResolversParentTypes['GroupedAssetGeoreferenceResultsOutput']> = {
  appliedPagination?: Resolver<Maybe<TResolversTypes['GeoreferenceAppliedPaginationOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['GroupedAssetGeoreferenceOutput']>>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetOutput'] = TResolversParentTypes['GroupedAssetOutput']> = {
  asset?: Resolver<TResolversTypes['AssetOutput'], ParentType, ContextType>;
  assetSize?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  assetStatus?: Resolver<Maybe<TResolversTypes['AssetStatusEnum']>, ParentType, ContextType>;
  assetType?: Resolver<TResolversTypes['AssetTypeEnum'], ParentType, ContextType>;
  bbox?: Resolver<Maybe<TResolversTypes['AssetBBoxOutput']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  downloadLink?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  folder?: Resolver<TResolversTypes['FolderOutput'], ParentType, ContextType>;
  georeferences?: Resolver<Maybe<TResolversTypes['GeoreferenceResultsOutput']>, ParentType, ContextType, Partial<TGroupedAssetOutputGeoreferencesArgs>>;
  groupedAssetGeoreferences?: Resolver<Maybe<TResolversTypes['GroupedAssetGeoreferenceResultsOutput']>, ParentType, ContextType, Partial<TGroupedAssetOutputGroupedAssetGeoreferencesArgs>>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  labelAnnotations?: Resolver<Maybe<Array<TResolversTypes['LabelAnnotationOutput']>>, ParentType, ContextType>;
  labels?: Resolver<TResolversTypes['LabelsOutput'], ParentType, ContextType>;
  limitingBoxAnnotations?: Resolver<Maybe<Array<TResolversTypes['LimitingBoxAnnotationOutput']>>, ParentType, ContextType>;
  linkedGroupedAssets?: Resolver<Maybe<Array<TResolversTypes['String']>>, ParentType, ContextType>;
  measurementAnnotations?: Resolver<Maybe<Array<TResolversTypes['MeasurementAnnotationOutput']>>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  sharingCode?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  thumbnailSignatureValidUntil?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  tours?: Resolver<TResolversTypes['GroupedAssetTourResultOutput'], ParentType, ContextType, Partial<TGroupedAssetOutputToursArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetPropertiesOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetPropertiesOutput'] = TResolversParentTypes['GroupedAssetPropertiesOutput']> = {
  selectedLimitingBoxAnnotation?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  visibleArtifacts?: Resolver<Maybe<Array<TResolversTypes['ID']>>, ParentType, ContextType>;
  visibleLabelAnnotations?: Resolver<Maybe<Array<Maybe<TResolversTypes['ID']>>>, ParentType, ContextType>;
  visibleMeasurementAnnotations?: Resolver<Maybe<Array<Maybe<TResolversTypes['ID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetResultOutput'] = TResolversParentTypes['GroupedAssetResultOutput']> = {
  appliedFilters?: Resolver<Maybe<TResolversTypes['FilterAssetAppliedOutput']>, ParentType, ContextType>;
  appliedPagination?: Resolver<Maybe<TResolversTypes['AssetAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['GroupedAssetOutput']>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['AssetOrderEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<Maybe<TResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetTourKeyframeOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetTourKeyframeOutput'] = TResolversParentTypes['GroupedAssetTourKeyframeOutput']> = {
  cameraViewpoint?: Resolver<TResolversTypes['TourCameraViewpointOutput'], ParentType, ContextType>;
  durationFromTourStart?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  properties?: Resolver<Maybe<TResolversTypes['GroupedAssetPropertiesOutput']>, ParentType, ContextType>;
  tension?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetTourOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetTourOutput'] = TResolversParentTypes['GroupedAssetTourOutput']> = {
  canEdit?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  closed?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  keyframes?: Resolver<Maybe<Array<TResolversTypes['GroupedAssetTourKeyframeOutput']>>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tension?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TGroupedAssetTourResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['GroupedAssetTourResultOutput'] = TResolversParentTypes['GroupedAssetTourResultOutput']> = {
  appliedFilters?: Resolver<TResolversTypes['TourFilterAppliedOutput'], ParentType, ContextType>;
  appliedPagination?: Resolver<TResolversTypes['TourAppliedPageOutput'], ParentType, ContextType>;
  contents?: Resolver<Maybe<Array<TResolversTypes['GroupedAssetTourOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<TResolversTypes['TourOrderEnum'], ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type THxdrProductOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['HXDRProductOutput'] = TResolversParentTypes['HXDRProductOutput']> = {
  __resolveType: TypeResolveFn<'PointCloudSourceLidarOutput' | 'Product2DAerialOutput', ParentType, ContextType>;
};

export type TInfoGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['InfoGQL'] = TResolversParentTypes['InfoGQL']> = {
  activeActivities?: Resolver<Array<Maybe<TResolversTypes['String']>>, ParentType, ContextType>;
  name?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TInfoPageResolvers<ContextType = any, ParentType extends TResolversParentTypes['InfoPage'] = TResolversParentTypes['InfoPage']> = {
  _pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  _pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['InfoGQL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TInvitationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['InvitationOutput'] = TResolversParentTypes['InvitationOutput']> = {
  registrationLink?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TItemCollectionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ItemCollection'] = TResolversParentTypes['ItemCollection']> = {
  contents?: Resolver<Array<Maybe<TResolversTypes['FeatureItem']>>, ParentType, ContextType>;
  returned?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['ItemCollectionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface TJsonScalarConfig extends GraphQLScalarTypeConfig<TResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type TJobPageResolvers<ContextType = any, ParentType extends TResolversParentTypes['JobPage'] = TResolversParentTypes['JobPage']> = {
  _pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  _pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  contents?: Resolver<Array<TResolversTypes['SubProcessGQL']>, ParentType, ContextType>;
  parentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TJobSummaryGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['JobSummaryGQL'] = TResolversParentTypes['JobSummaryGQL']> = {
  jobs?: Resolver<Array<TResolversTypes['SummaryItemGQL']>, ParentType, ContextType>;
  parentId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelAnnotationDataOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelAnnotationDataOutput'] = TResolversParentTypes['LabelAnnotationDataOutput']> = {
  lookAt?: Resolver<TResolversTypes['AnnotationOrientationOutput'], ParentType, ContextType>;
  position?: Resolver<TResolversTypes['AnnotationPointOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelAnnotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelAnnotationOutput'] = TResolversParentTypes['LabelAnnotationOutput']> = {
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  data?: Resolver<TResolversTypes['LabelAnnotationDataOutput'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelErrorOperationNotAllowedOutput'] = TResolversParentTypes['LabelErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelErrorTooManyLabelsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelErrorTooManyLabelsOutput'] = TResolversParentTypes['LabelErrorTooManyLabelsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelOutput'] = TResolversParentTypes['LabelOutput']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelsOutput'] = TResolversParentTypes['LabelsOutput']> = {
  content?: Resolver<Array<Maybe<TResolversTypes['LabelOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLabelsResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LabelsResultOutput'] = TResolversParentTypes['LabelsResultOutput']> = {
  content?: Resolver<Array<Maybe<TResolversTypes['LabelOutput']>>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLayerAddressResolvers<ContextType = any, ParentType extends TResolversParentTypes['LayerAddress'] = TResolversParentTypes['LayerAddress']> = {
  __resolveType: TypeResolveFn<'LayerAddressHspc' | 'LayerAddressLts' | 'LayerAddressOgc3DTiles' | 'LayerAddressWms' | 'LayerAddressWmts', ParentType, ContextType>;
};

export type TLayerAddressHspcResolvers<ContextType = any, ParentType extends TResolversParentTypes['LayerAddressHspc'] = TResolversParentTypes['LayerAddressHspc']> = {
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  signatureExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  sourceEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLayerAddressLtsResolvers<ContextType = any, ParentType extends TResolversParentTypes['LayerAddressLts'] = TResolversParentTypes['LayerAddressLts']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  contentType?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  level0Columns?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  level0Rows?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  levelCount?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  samplingMode?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  signatureExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  sourceEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tileHeight?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  tileWidth?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLayerAddressOgc3DTilesResolvers<ContextType = any, ParentType extends TResolversParentTypes['LayerAddressOgc3DTiles'] = TResolversParentTypes['LayerAddressOgc3DTiles']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  boundsLLH?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  qualityFactor?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  signatureExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  sourceEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLayerAddressWmsResolvers<ContextType = any, ParentType extends TResolversParentTypes['LayerAddressWms'] = TResolversParentTypes['LayerAddressWms']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  imageFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  signatureExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  sourceEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  versions?: Resolver<Maybe<Array<TResolversTypes['String']>>, ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLayerAddressWmtsResolvers<ContextType = any, ParentType extends TResolversParentTypes['LayerAddressWmts'] = TResolversParentTypes['LayerAddressWmts']> = {
  bounds?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  contentType?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  datasetId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  imageFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  level0Columns?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  level0Rows?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  levelCount?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  reference?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  samplingMode?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  signatureExpiration?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  sourceEndpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tileHeight?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  tileMatrices?: Resolver<Array<TResolversTypes['Float']>, ParentType, ContextType>;
  tileMatrixSet?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tileWidth?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  viewingMode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLicenseErrorInvalidArgumentOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LicenseErrorInvalidArgumentOutput'] = TResolversParentTypes['LicenseErrorInvalidArgumentOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLicenseErrorLicenseNotAvailableOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LicenseErrorLicenseNotAvailableOutput'] = TResolversParentTypes['LicenseErrorLicenseNotAvailableOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLicenseErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LicenseErrorOperationNotAllowedOutput'] = TResolversParentTypes['LicenseErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLicenseErrorTooManyRequestsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LicenseErrorTooManyRequestsOutput'] = TResolversParentTypes['LicenseErrorTooManyRequestsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLimitToApplyOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LimitToApplyOutput'] = TResolversParentTypes['LimitToApplyOutput']> = {
  limit?: Resolver<TResolversTypes['AccountLimitEnum'], ParentType, ContextType>;
  limitExpirationDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  value?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLimitingBoxAnnotationDataOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LimitingBoxAnnotationDataOutput'] = TResolversParentTypes['LimitingBoxAnnotationDataOutput']> = {
  lookAt?: Resolver<TResolversTypes['AnnotationOrientationOutput'], ParentType, ContextType>;
  maxX?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  maxY?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  maxZ?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  minX?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  minY?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  minZ?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  offset?: Resolver<TResolversTypes['AnnotationPointOutput'], ParentType, ContextType>;
  rotation?: Resolver<TResolversTypes['AnnotationRotationOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLimitingBoxAnnotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LimitingBoxAnnotationOutput'] = TResolversParentTypes['LimitingBoxAnnotationOutput']> = {
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  data?: Resolver<TResolversTypes['LimitingBoxAnnotationDataOutput'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TListOfUserProjectRoleOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ListOfUserProjectRoleOutput'] = TResolversParentTypes['ListOfUserProjectRoleOutput']> = {
  users?: Resolver<Array<TResolversTypes['UserProjectRoleOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLocalizedNameOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LocalizedNameOutput'] = TResolversParentTypes['LocalizedNameOutput']> = {
  languageAlpha2Code?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  languageAlpha3Code?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLuciadRiaLicenseOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['LuciadRIALicenseOutput'] = TResolversParentTypes['LuciadRIALicenseOutput']> = {
  appClientId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  contents?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  luciadRIAVersion?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TLuciadRiaLicenseUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['LuciadRIALicenseUnion'] = TResolversParentTypes['LuciadRIALicenseUnion']> = {
  __resolveType: TypeResolveFn<'LicenseErrorInvalidArgumentOutput' | 'LicenseErrorLicenseNotAvailableOutput' | 'LicenseErrorOperationNotAllowedOutput' | 'LuciadRIALicenseOutput', ParentType, ContextType>;
};

export type TMeasurementAnnotationDataOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['MeasurementAnnotationDataOutput'] = TResolversParentTypes['MeasurementAnnotationDataOutput']> = {
  lookAt?: Resolver<TResolversTypes['AnnotationOrientationOutput'], ParentType, ContextType>;
  measurementType?: Resolver<TResolversTypes['MeasurementAnnotationEnum'], ParentType, ContextType>;
  points?: Resolver<Array<TResolversTypes['AnnotationPointOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TMeasurementAnnotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['MeasurementAnnotationOutput'] = TResolversParentTypes['MeasurementAnnotationOutput']> = {
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  data?: Resolver<TResolversTypes['MeasurementAnnotationDataOutput'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TMoveAssetToFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['MoveAssetToFolderUnion'] = TResolversParentTypes['MoveAssetToFolderUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorDuplicateNameOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorStorageLimitReachedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TMultiAssetDownloadLinkOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['MultiAssetDownloadLinkOutput'] = TResolversParentTypes['MultiAssetDownloadLinkOutput']> = {
  multiAssetDownloadLink?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TMultipartUploadETagOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['MultipartUploadETagOutput'] = TResolversParentTypes['MultipartUploadETagOutput']> = {
  etag?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  partNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  size?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TMultipartUploadPartsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['MultipartUploadPartsOutput'] = TResolversParentTypes['MultipartUploadPartsOutput']> = {
  multipartUploadsETags?: Resolver<Array<Maybe<TResolversTypes['MultipartUploadETagOutput']>>, ParentType, ContextType>;
  totalUploadedSize?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  uploadExpirationDate?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TMultipartUploadUrlOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['MultipartUploadUrlOutput'] = TResolversParentTypes['MultipartUploadUrlOutput']> = {
  expirationDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  fileId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  partNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TMultipartUploadUrlUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['MultipartUploadUrlUnion'] = TResolversParentTypes['MultipartUploadUrlUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorStorageLimitReachedOutput' | 'MultipartUploadUrlOutput', ParentType, ContextType>;
};

export type TMutationResolvers<ContextType = any, ParentType extends TResolversParentTypes['Mutation'] = TResolversParentTypes['Mutation']> = {
  _noop?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  addExternalUserToProject?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType, Partial<TMutationAddExternalUserToProjectArgs>>;
  addFile?: Resolver<Maybe<TResolversTypes['FileOutput']>, ParentType, ContextType, RequireFields<TMutationAddFileArgs, 'params'>>;
  addFileV2?: Resolver<Maybe<TResolversTypes['AddFileUnion']>, ParentType, ContextType, RequireFields<TMutationAddFileV2Args, 'params'>>;
  addItemToCart?: Resolver<TResolversTypes['CartOutput'], ParentType, ContextType, RequireFields<TMutationAddItemToCartArgs, 'params'>>;
  addLabels?: Resolver<TResolversTypes['AddLabelsUnion'], ParentType, ContextType, RequireFields<TMutationAddLabelsArgs, 'input'>>;
  addUserToProject?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationAddUserToProjectArgs>>;
  addUserToProjectV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType, Partial<TMutationAddUserToProjectV2Args>>;
  assignAccountToTenant?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationAssignAccountToTenantArgs, 'params'>>;
  assignAccountToTenantV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType, RequireFields<TMutationAssignAccountToTenantV2Args, 'params'>>;
  assignTenantRoleToUser?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationAssignTenantRoleToUserArgs, 'params'>>;
  assignTenantRoleToUserV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType, RequireFields<TMutationAssignTenantRoleToUserV2Args, 'params'>>;
  autoRegisterAssets?: Resolver<TResolversTypes['GroupedAssetOutput'], ParentType, ContextType, RequireFields<TMutationAutoRegisterAssetsArgs, 'input' | 'newAsset'>>;
  autoRegisterAssetsV2?: Resolver<Maybe<TResolversTypes['AutoRegisterAssetsUnion']>, ParentType, ContextType, RequireFields<TMutationAutoRegisterAssetsV2Args, 'input' | 'newAsset'>>;
  buySubscription?: Resolver<TResolversTypes['SubscriptionOutput'], ParentType, ContextType, Partial<TMutationBuySubscriptionArgs>>;
  buySubscriptionV2?: Resolver<TResolversTypes['SubscriptionUnion'], ParentType, ContextType, Partial<TMutationBuySubscriptionV2Args>>;
  cancelCurrentSubscription?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  cancelCurrentSubscriptionV2?: Resolver<TResolversTypes['CancelCurrentSubscriptionUnion'], ParentType, ContextType>;
  changeAccountRoleOfUser?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationChangeAccountRoleOfUserArgs>>;
  changeAccountRoleOfUserV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType, Partial<TMutationChangeAccountRoleOfUserV2Args>>;
  changeProjectRoleOfUser?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationChangeProjectRoleOfUserArgs>>;
  changeProjectRoleOfUserV2?: Resolver<TResolversTypes['ChangeProjectRoleUnion'], ParentType, ContextType, Partial<TMutationChangeProjectRoleOfUserV2Args>>;
  completeAssetFileList?: Resolver<Maybe<TResolversTypes['ExecutedOutput']>, ParentType, ContextType, RequireFields<TMutationCompleteAssetFileListArgs, 'groupedAssetId'>>;
  completeMultipartUpload?: Resolver<Maybe<TResolversTypes['ExecutedOutput']>, ParentType, ContextType, RequireFields<TMutationCompleteMultipartUploadArgs, 'params'>>;
  confirmSubscriptionOrder?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  confirmSubscriptionOrderV2?: Resolver<TResolversTypes['ConfirmSubscriptionUnion'], ParentType, ContextType>;
  copyAssetToFolder?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationCopyAssetToFolderArgs, 'params'>>;
  copyAssetToFolderV2?: Resolver<Maybe<TResolversTypes['CopyAssetToFolderUnion']>, ParentType, ContextType, RequireFields<TMutationCopyAssetToFolderV2Args, 'params'>>;
  createApplicationTemplate?: Resolver<TResolversTypes['CreateApplicationTemplateUnion'], ParentType, ContextType, RequireFields<TMutationCreateApplicationTemplateArgs, 'params'>>;
  createAsset?: Resolver<Maybe<TResolversTypes['GroupedAssetOutput']>, ParentType, ContextType, RequireFields<TMutationCreateAssetArgs, 'params'>>;
  createAssetThumbnail?: Resolver<Maybe<TResolversTypes['AssetThumbnailOutput']>, ParentType, ContextType, RequireFields<TMutationCreateAssetThumbnailArgs, 'groupedAssetId'>>;
  createAssetThumbnailV2?: Resolver<Maybe<TResolversTypes['CreateAssetThumbnailUnion']>, ParentType, ContextType, RequireFields<TMutationCreateAssetThumbnailV2Args, 'groupedAssetId'>>;
  createAssetV2?: Resolver<Maybe<TResolversTypes['CreateAssetUnion']>, ParentType, ContextType, RequireFields<TMutationCreateAssetV2Args, 'params'>>;
  createAssetV3?: Resolver<Maybe<TResolversTypes['CreateAssetUnion']>, ParentType, ContextType, RequireFields<TMutationCreateAssetV3Args, 'params'>>;
  createComment?: Resolver<TResolversTypes['CommentPayload'], ParentType, ContextType, RequireFields<TMutationCreateCommentArgs, 'input'>>;
  createFolder?: Resolver<Maybe<TResolversTypes['FolderOutput']>, ParentType, ContextType, RequireFields<TMutationCreateFolderArgs, 'params'>>;
  createFolderV2?: Resolver<Maybe<TResolversTypes['CreateFolderUnion']>, ParentType, ContextType, RequireFields<TMutationCreateFolderV2Args, 'params'>>;
  createGeoreference?: Resolver<TResolversTypes['GeoreferenceOutput'], ParentType, ContextType, RequireFields<TMutationCreateGeoreferenceArgs, 'georeference'>>;
  createGroupedAssetGeoreference?: Resolver<TResolversTypes['GroupedAssetGeoreferenceOutput'], ParentType, ContextType, RequireFields<TMutationCreateGroupedAssetGeoreferenceArgs, 'georeference'>>;
  createGroupedAssetTour?: Resolver<TResolversTypes['GroupedAssetTourOutput'], ParentType, ContextType, RequireFields<TMutationCreateGroupedAssetTourArgs, 'params'>>;
  createGroupedAssetTourKeyframe?: Resolver<TResolversTypes['GroupedAssetTourKeyframeOutput'], ParentType, ContextType, RequireFields<TMutationCreateGroupedAssetTourKeyframeArgs, 'params'>>;
  createGroupedAssetTourThumbnail?: Resolver<TResolversTypes['ThumbnailOutput'], ParentType, ContextType, RequireFields<TMutationCreateGroupedAssetTourThumbnailArgs, 'params'>>;
  createHspcBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateHspcBaselayerArgs>>;
  createLabelAnnotation?: Resolver<TResolversTypes['LabelAnnotationOutput'], ParentType, ContextType, RequireFields<TMutationCreateLabelAnnotationArgs, 'createLabelAnnotationInput'>>;
  createLabelAnnotationV2?: Resolver<TResolversTypes['CreateLabelAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationCreateLabelAnnotationV2Args, 'createLabelAnnotationInput'>>;
  createLimitingBoxAnnotation?: Resolver<TResolversTypes['LimitingBoxAnnotationOutput'], ParentType, ContextType, RequireFields<TMutationCreateLimitingBoxAnnotationArgs, 'createLimitingBoxAnnotationInput'>>;
  createLimitingBoxAnnotationV2?: Resolver<TResolversTypes['CreateLimitingBoxAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationCreateLimitingBoxAnnotationV2Args, 'createLimitingBoxAnnotationInput'>>;
  createLtsBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateLtsBaselayerArgs>>;
  createMeasurementAnnotation?: Resolver<TResolversTypes['MeasurementAnnotationOutput'], ParentType, ContextType, RequireFields<TMutationCreateMeasurementAnnotationArgs, 'createMeasurementAnnotationInput'>>;
  createMeasurementAnnotationV2?: Resolver<TResolversTypes['CreateMeasurementAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationCreateMeasurementAnnotationV2Args, 'createMeasurementAnnotationInput'>>;
  createOgc3dBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateOgc3dBaselayerArgs>>;
  createPanoramicBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreatePanoramicBaselayerArgs>>;
  createProject?: Resolver<Maybe<TResolversTypes['ProjectOutput']>, ParentType, ContextType, RequireFields<TMutationCreateProjectArgs, 'params'>>;
  createProjectV2?: Resolver<Maybe<TResolversTypes['CreateProjectUnion']>, ParentType, ContextType, RequireFields<TMutationCreateProjectV2Args, 'params'>>;
  createTenant?: Resolver<TResolversTypes['TenantOutput'], ParentType, ContextType, RequireFields<TMutationCreateTenantArgs, 'params'>>;
  createTenantV2?: Resolver<TResolversTypes['TenantUnion'], ParentType, ContextType, RequireFields<TMutationCreateTenantV2Args, 'params'>>;
  createUrlBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateUrlBaselayerArgs>>;
  createWfsBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateWfsBaselayerArgs>>;
  createWmsBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateWmsBaselayerArgs>>;
  createWmtsBaselayer?: Resolver<TResolversTypes['BaselayerOutput'], ParentType, ContextType, Partial<TMutationCreateWmtsBaselayerArgs>>;
  customMetric?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationCustomMetricArgs, 'params'>>;
  deleteAnnotation?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<TMutationDeleteAnnotationArgs, 'annotationId'>>;
  deleteAnnotationV2?: Resolver<TResolversTypes['DeleteAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationDeleteAnnotationV2Args, 'annotationId'>>;
  deleteApplicationTemplate?: Resolver<TResolversTypes['DeleteApplicationTemplateUnion'], ParentType, ContextType, RequireFields<TMutationDeleteApplicationTemplateArgs, 'id'>>;
  deleteAsset?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteAssetArgs, 'groupedAssetId'>>;
  deleteAssetThumbnail?: Resolver<TResolversTypes['DeleteAssetThumbnailUnion'], ParentType, ContextType, RequireFields<TMutationDeleteAssetThumbnailArgs, 'groupedAssetId'>>;
  deleteAssetV2?: Resolver<TResolversTypes['DeleteAssetUnion'], ParentType, ContextType, RequireFields<TMutationDeleteAssetV2Args, 'groupedAssetId'>>;
  deleteCart?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  deleteComment?: Resolver<TResolversTypes['CommentPayload'], ParentType, ContextType, RequireFields<TMutationDeleteCommentArgs, 'input'>>;
  deleteDevice?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteDeviceArgs, 'deviceId'>>;
  deleteFolder?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteFolderArgs, 'folderId' | 'projectId'>>;
  deleteFolderV2?: Resolver<TResolversTypes['DeleteFolderUnion'], ParentType, ContextType, RequireFields<TMutationDeleteFolderV2Args, 'folderId' | 'projectId'>>;
  deleteGeoreference?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteGeoreferenceArgs, 'id'>>;
  deleteGroupedAssetGeoreference?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteGroupedAssetGeoreferenceArgs, 'id'>>;
  deleteGroupedAssetTour?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteGroupedAssetTourArgs, 'params'>>;
  deleteGroupedAssetTourKeyframe?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteGroupedAssetTourKeyframeArgs, 'params'>>;
  deleteGroupedAssetTourThumbnail?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteGroupedAssetTourThumbnailArgs, 'params'>>;
  deletePaymentCard?: Resolver<TResolversTypes['DeletedPaymentCardOutput'], ParentType, ContextType, Partial<TMutationDeletePaymentCardArgs>>;
  deleteProject?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationDeleteProjectArgs, 'projectId'>>;
  deleteProjectThumbnail?: Resolver<Maybe<TResolversTypes['DeleteProjectThumbnailUnion']>, ParentType, ContextType, RequireFields<TMutationDeleteProjectThumbnailArgs, 'projectId'>>;
  deleteProjectV2?: Resolver<Maybe<TResolversTypes['DeleteProjectUnion']>, ParentType, ContextType, RequireFields<TMutationDeleteProjectV2Args, 'projectId'>>;
  deleteTenant?: Resolver<TResolversTypes['TenantDeletionOutput'], ParentType, ContextType, RequireFields<TMutationDeleteTenantArgs, 'params'>>;
  deleteTenantV2?: Resolver<TResolversTypes['TenantDeletionUnion'], ParentType, ContextType, RequireFields<TMutationDeleteTenantV2Args, 'params'>>;
  deleteUser?: Resolver<Maybe<TResolversTypes['UserDeletionOutput']>, ParentType, ContextType, RequireFields<TMutationDeleteUserArgs, 'userId'>>;
  deleteUserV2?: Resolver<TResolversTypes['UserDeletionUnion'], ParentType, ContextType, RequireFields<TMutationDeleteUserV2Args, 'userId'>>;
  finalizePayment?: Resolver<Maybe<TResolversTypes['CartOutput']>, ParentType, ContextType>;
  generateInvitation?: Resolver<Maybe<TResolversTypes['InvitationOutput']>, ParentType, ContextType, RequireFields<TMutationGenerateInvitationArgs, 'params'>>;
  generateInvitationV2?: Resolver<TResolversTypes['GenerateInvitationUnion'], ParentType, ContextType, RequireFields<TMutationGenerateInvitationV2Args, 'params'>>;
  generateMultiAssetDownloadLink?: Resolver<Maybe<TResolversTypes['MultiAssetDownloadLinkOutput']>, ParentType, ContextType, RequireFields<TMutationGenerateMultiAssetDownloadLinkArgs, 'input'>>;
  generateMultiAssetDownloadLinkV2?: Resolver<Maybe<TResolversTypes['GenerateMultiAssetDownloadLinkUnion']>, ParentType, ContextType, RequireFields<TMutationGenerateMultiAssetDownloadLinkV2Args, 'input'>>;
  generateReport?: Resolver<TResolversTypes['ReportGenerationOutput'], ParentType, ContextType, RequireFields<TMutationGenerateReportArgs, 'params'>>;
  generateVoucher?: Resolver<Maybe<TResolversTypes['VoucherOutput']>, ParentType, ContextType, RequireFields<TMutationGenerateVoucherArgs, 'params'>>;
  generateVoucherV2?: Resolver<TResolversTypes['VoucherUnion'], ParentType, ContextType, RequireFields<TMutationGenerateVoucherV2Args, 'params'>>;
  leaveProject?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationLeaveProjectArgs, 'projectId'>>;
  leaveProjectV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType, RequireFields<TMutationLeaveProjectV2Args, 'projectId'>>;
  linkDevice?: Resolver<Maybe<TResolversTypes['DeviceOutput']>, ParentType, ContextType, RequireFields<TMutationLinkDeviceArgs, 'params'>>;
  moveAssetToFolder?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationMoveAssetToFolderArgs, 'params'>>;
  moveAssetToFolderV2?: Resolver<Maybe<TResolversTypes['MoveAssetToFolderUnion']>, ParentType, ContextType, RequireFields<TMutationMoveAssetToFolderV2Args, 'params'>>;
  persistArtifact?: Resolver<TResolversTypes['ArtifactItemOutput'], ParentType, ContextType, RequireFields<TMutationPersistArtifactArgs, 'params' | 'parentId'>>;
  persistAsset?: Resolver<Maybe<TResolversTypes['GroupedAssetOutput']>, ParentType, ContextType, RequireFields<TMutationPersistAssetArgs, 'params'>>;
  persistAssetV2?: Resolver<Maybe<TResolversTypes['PersistAssetUnion']>, ParentType, ContextType, RequireFields<TMutationPersistAssetV2Args, 'params'>>;
  placeOrder?: Resolver<TResolversTypes['CheckoutOutput'], ParentType, ContextType, RequireFields<TMutationPlaceOrderArgs, 'params'>>;
  publishAccountMetrics?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationPublishAccountMetricsArgs>>;
  publishUserMetrics?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationPublishUserMetricsArgs>>;
  recalculateGroupedAssetTourKeyframes?: Resolver<TResolversTypes['GroupedAssetTourOutput'], ParentType, ContextType, RequireFields<TMutationRecalculateGroupedAssetTourKeyframesArgs, 'params'>>;
  recalculateUsedStorage?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationRecalculateUsedStorageArgs, 'syncWithStorage'>>;
  refundTokens?: Resolver<TResolversTypes['WalletRefundUnion'], ParentType, ContextType, Partial<TMutationRefundTokensArgs>>;
  removeAssetThumbnail?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationRemoveAssetThumbnailArgs, 'groupedAssetId'>>;
  removeItemFromCart?: Resolver<TResolversTypes['CartOutput'], ParentType, ContextType, RequireFields<TMutationRemoveItemFromCartArgs, 'params'>>;
  removeLabels?: Resolver<TResolversTypes['RemoveLabelsUnion'], ParentType, ContextType, RequireFields<TMutationRemoveLabelsArgs, 'input'>>;
  removeProfilePicture?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  removeProjectThumbnail?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationRemoveProjectThumbnailArgs, 'projectId'>>;
  removeUserFlag?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType, Partial<TMutationRemoveUserFlagArgs>>;
  removeUserFromProject?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationRemoveUserFromProjectArgs, 'projectId' | 'userId'>>;
  removeUserFromProjectV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType, RequireFields<TMutationRemoveUserFromProjectV2Args, 'projectId' | 'userId'>>;
  resetPayment?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  sampleAccountMetrics?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationSampleAccountMetricsArgs>>;
  sampleUserMetrics?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, Partial<TMutationSampleUserMetricsArgs>>;
  setAccountProperties?: Resolver<TResolversTypes['AccountInfoOutput'], ParentType, ContextType, RequireFields<TMutationSetAccountPropertiesArgs, 'param'>>;
  setActivePaymentCard?: Resolver<TResolversTypes['SetActivePaymentCardOutput'], ParentType, ContextType, Partial<TMutationSetActivePaymentCardArgs>>;
  setDefaultTenant?: Resolver<TResolversTypes['TenantOutput'], ParentType, ContextType, RequireFields<TMutationSetDefaultTenantArgs, 'tenantId'>>;
  setDefaultTenantV2?: Resolver<TResolversTypes['TenantUnion'], ParentType, ContextType, RequireFields<TMutationSetDefaultTenantV2Args, 'tenantId'>>;
  setUploadConsent?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  setUserFlag?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType, Partial<TMutationSetUserFlagArgs>>;
  setupPaymentCardCreation?: Resolver<TResolversTypes['SetupPaymentCardCreationOutput'], ParentType, ContextType>;
  shareGroupedAsset?: Resolver<TResolversTypes['SharingCodeUnion'], ParentType, ContextType, RequireFields<TMutationShareGroupedAssetArgs, 'groupedAssetId'>>;
  signup?: Resolver<Maybe<TResolversTypes['SignupOutput']>, ParentType, ContextType, RequireFields<TMutationSignupArgs, 'params'>>;
  signupFromInvite?: Resolver<Maybe<TResolversTypes['SignupOutput']>, ParentType, ContextType, RequireFields<TMutationSignupFromInviteArgs, 'params'>>;
  signupFromInviteV2?: Resolver<TResolversTypes['SignupFromInviteUnion'], ParentType, ContextType, RequireFields<TMutationSignupFromInviteV2Args, 'params'>>;
  signupV2?: Resolver<TResolversTypes['SignupUnion'], ParentType, ContextType, RequireFields<TMutationSignupV2Args, 'params'>>;
  stopPendingSubscriptionCancellation?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  stopPendingSubscriptionDowngrade?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  triggerHSPCClipperProcessingPipeline?: Resolver<Maybe<TResolversTypes['ProcessingPipelineTriggerOutput']>, ParentType, ContextType, Partial<TMutationTriggerHspcClipperProcessingPipelineArgs>>;
  triggerMeshing?: Resolver<TResolversTypes['TriggerProcessingUnion'], ParentType, ContextType, RequireFields<TMutationTriggerMeshingArgs, 'params'>>;
  triggerMeshingV2?: Resolver<TResolversTypes['TriggerProcessingUnion'], ParentType, ContextType, RequireFields<TMutationTriggerMeshingV2Args, 'params'>>;
  triggerProcessing?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType, RequireFields<TMutationTriggerProcessingArgs, 'params'>>;
  triggerProcessingV2?: Resolver<TResolversTypes['TriggerProcessingUnion'], ParentType, ContextType, RequireFields<TMutationTriggerProcessingV2Args, 'params'>>;
  triggerShopProcessingPipeline?: Resolver<Maybe<TResolversTypes['ProcessingPipelineTriggerOutput']>, ParentType, ContextType, Partial<TMutationTriggerShopProcessingPipelineArgs>>;
  unshareGroupedAsset?: Resolver<TResolversTypes['UnsharedGroupedAssetUnion'], ParentType, ContextType, RequireFields<TMutationUnshareGroupedAssetArgs, 'groupedAssetId'>>;
  updateAccount?: Resolver<TResolversTypes['AccountInfoOutput'], ParentType, ContextType, RequireFields<TMutationUpdateAccountArgs, 'update'>>;
  updateAccountV2?: Resolver<TResolversTypes['AccountInfoUpdateUnion'], ParentType, ContextType, RequireFields<TMutationUpdateAccountV2Args, 'update'>>;
  updateAccountWithTenantInfo?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  updateAccountWithTenantInfoV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType>;
  updateApplicationTemplate?: Resolver<TResolversTypes['UpdateApplicationTemplateUnion'], ParentType, ContextType, RequireFields<TMutationUpdateApplicationTemplateArgs, 'params'>>;
  updateAsset?: Resolver<Maybe<TResolversTypes['GroupedAssetOutput']>, ParentType, ContextType, RequireFields<TMutationUpdateAssetArgs, 'params'>>;
  updateAssetV2?: Resolver<Maybe<TResolversTypes['UpdateAssetUnion']>, ParentType, ContextType, RequireFields<TMutationUpdateAssetV2Args, 'params'>>;
  updateComment?: Resolver<TResolversTypes['CommentPayload'], ParentType, ContextType, RequireFields<TMutationUpdateCommentArgs, 'input'>>;
  updateDevice?: Resolver<Maybe<TResolversTypes['DeviceOutput']>, ParentType, ContextType, RequireFields<TMutationUpdateDeviceArgs, 'params'>>;
  updateFolder?: Resolver<Maybe<TResolversTypes['FolderOutput']>, ParentType, ContextType, RequireFields<TMutationUpdateFolderArgs, 'params'>>;
  updateFolderV2?: Resolver<Maybe<TResolversTypes['UpdateFolderUnion']>, ParentType, ContextType, RequireFields<TMutationUpdateFolderV2Args, 'params'>>;
  updateGeoreference?: Resolver<TResolversTypes['GeoreferenceOutput'], ParentType, ContextType, RequireFields<TMutationUpdateGeoreferenceArgs, 'georeference'>>;
  updateGroupedAssetGeoreference?: Resolver<TResolversTypes['GroupedAssetGeoreferenceOutput'], ParentType, ContextType, RequireFields<TMutationUpdateGroupedAssetGeoreferenceArgs, 'georeference'>>;
  updateGroupedAssetTour?: Resolver<TResolversTypes['GroupedAssetTourOutput'], ParentType, ContextType, RequireFields<TMutationUpdateGroupedAssetTourArgs, 'params'>>;
  updateGroupedAssetTourKeyframe?: Resolver<TResolversTypes['GroupedAssetTourKeyframeOutput'], ParentType, ContextType, RequireFields<TMutationUpdateGroupedAssetTourKeyframeArgs, 'params'>>;
  updateLabelAnnotation?: Resolver<TResolversTypes['LabelAnnotationOutput'], ParentType, ContextType, RequireFields<TMutationUpdateLabelAnnotationArgs, 'updateLabelAnnotationInput'>>;
  updateLabelAnnotationV2?: Resolver<TResolversTypes['UpdateLabelAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationUpdateLabelAnnotationV2Args, 'updateLabelAnnotationInput'>>;
  updateLimitingBoxAnnotation?: Resolver<TResolversTypes['LimitingBoxAnnotationOutput'], ParentType, ContextType, RequireFields<TMutationUpdateLimitingBoxAnnotationArgs, 'updateLimitingBoxAnnotationInput'>>;
  updateLimitingBoxAnnotationV2?: Resolver<TResolversTypes['UpdateLimitingBoxAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationUpdateLimitingBoxAnnotationV2Args, 'updateLimitingBoxAnnotationInput'>>;
  updateMeasurementAnnotation?: Resolver<TResolversTypes['MeasurementAnnotationOutput'], ParentType, ContextType, RequireFields<TMutationUpdateMeasurementAnnotationArgs, 'updateMeasurementAnnotationInput'>>;
  updateMeasurementAnnotationV2?: Resolver<TResolversTypes['UpdateMeasurementAnnotationUnion'], ParentType, ContextType, RequireFields<TMutationUpdateMeasurementAnnotationV2Args, 'updateMeasurementAnnotationInput'>>;
  updatePaymentCard?: Resolver<TResolversTypes['PaymentCardOutput'], ParentType, ContextType, Partial<TMutationUpdatePaymentCardArgs>>;
  updateProject?: Resolver<Maybe<TResolversTypes['ProjectOutput']>, ParentType, ContextType, RequireFields<TMutationUpdateProjectArgs, 'params'>>;
  updateProjectV2?: Resolver<Maybe<TResolversTypes['UpdateProjectUnion']>, ParentType, ContextType, RequireFields<TMutationUpdateProjectV2Args, 'params'>>;
  updateTenant?: Resolver<TResolversTypes['TenantOutput'], ParentType, ContextType, RequireFields<TMutationUpdateTenantArgs, 'params'>>;
  updateTenantV2?: Resolver<TResolversTypes['TenantUnion'], ParentType, ContextType, RequireFields<TMutationUpdateTenantV2Args, 'params'>>;
  updateUser?: Resolver<TResolversTypes['UserProfileOutput'], ParentType, ContextType, RequireFields<TMutationUpdateUserArgs, 'update'>>;
  updateUserSettings?: Resolver<TResolversTypes['UserSettingsOutput'], ParentType, ContextType, RequireFields<TMutationUpdateUserSettingsArgs, 'userSettings'>>;
  updateUserWithTenantInfo?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  updateUserWithTenantInfoV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType>;
  updateUserWithTenantRole?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  updateUserWithTenantRoleV2?: Resolver<TResolversTypes['SimpleResultUnion'], ParentType, ContextType>;
  uploadPolygon?: Resolver<TResolversTypes['PolygonOutput'], ParentType, ContextType>;
  uploadProfilePicture?: Resolver<TResolversTypes['ProfilePictureOutput'], ParentType, ContextType>;
  uploadThumbnailToAnnotation?: Resolver<Maybe<TResolversTypes['AnnotationThumbnailOutput']>, ParentType, ContextType, RequireFields<TMutationUploadThumbnailToAnnotationArgs, 'annotationId'>>;
  uploadThumbnailToAnnotationV2?: Resolver<TResolversTypes['AnnotationThumbnailUnion'], ParentType, ContextType, RequireFields<TMutationUploadThumbnailToAnnotationV2Args, 'annotationId'>>;
  uploadThumbnailToCartItem?: Resolver<TResolversTypes['CartItemThumbnailOutput'], ParentType, ContextType, RequireFields<TMutationUploadThumbnailToCartItemArgs, 'params'>>;
  uploadThumbnailToProject?: Resolver<Maybe<TResolversTypes['ProjectThumbnailOutput']>, ParentType, ContextType, RequireFields<TMutationUploadThumbnailToProjectArgs, 'params'>>;
  uploadThumbnailToProjectV2?: Resolver<Maybe<TResolversTypes['UploadThumbnailToProjectUnion']>, ParentType, ContextType, RequireFields<TMutationUploadThumbnailToProjectV2Args, 'params'>>;
};

export type TMyAssetsProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['MyAssetsProjectUnion'] = TResolversParentTypes['MyAssetsProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'ProjectOutput', ParentType, ContextType>;
};

export type TNodeResolvers<ContextType = any, ParentType extends TResolversParentTypes['Node'] = TResolversParentTypes['Node']> = {
  __resolveType: TypeResolveFn<'Comment' | 'ProjectChangeFolderCopyOperation' | 'ProjectChangeFolderCreateOperation' | 'ProjectChangeFolderDeleteOperation' | 'ProjectChangeFolderMoveOperation' | 'ProjectChangeFolderUpdateOperation' | 'ProjectChangeGroupedAssetCopyOperation' | 'ProjectChangeGroupedAssetCreateOperation' | 'ProjectChangeGroupedAssetDeleteOperation' | 'ProjectChangeGroupedAssetMoveOperation' | 'ProjectChangeGroupedAssetUpdateOperation' | 'ProjectChangeOutput' | 'ProjectChangeProjectUpdateOperation' | 'ProjectChangeUserAccessGrantedOperation' | 'ProjectChangeUserAccessRevokedOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TNotFoundErrorResolvers<ContextType = any, ParentType extends TResolversParentTypes['NotFoundError'] = TResolversParentTypes['NotFoundError']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TOrderGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['OrderGQL'] = TResolversParentTypes['OrderGQL']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  cartId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  discountedAmount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  id?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  orderId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  orderItems?: Resolver<Array<TResolversTypes['OrderItemGQL']>, ParentType, ContextType>;
  status?: Resolver<TResolversTypes['OrderStatusGQL'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TOrderItemGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['OrderItemGQL'] = TResolversParentTypes['OrderItemGQL']> = {
  accountUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  artifactFilePath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  artifactUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  assetUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  bbox?: Resolver<Maybe<Array<TResolversTypes['Float']>>, ParentType, ContextType>;
  cartId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  cartItemId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  coordinateSystem?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  dataType?: Resolver<TResolversTypes['DataTypeEnum'], ParentType, ContextType>;
  discountedAmount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  folderUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  groupedAssetUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  id?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  imageFormat?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  modifiedAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  polygonDefType?: Resolver<TResolversTypes['PolygonType'], ParentType, ContextType>;
  polygonPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  productIdentifier?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  projectUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  resolutionUnit?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  resolutionValue?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  status?: Resolver<TResolversTypes['OrderItemStatusGQL'], ParentType, ContextType>;
  supplierOrderId?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  thumbnailFilePath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  userUUID?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPageInfoResolvers<ContextType = any, ParentType extends TResolversParentTypes['PageInfo'] = TResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPaymentCardAddressOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['PaymentCardAddressOutput'] = TResolversParentTypes['PaymentCardAddressOutput']> = {
  city?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  zip?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPaymentCardOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['PaymentCardOutput'] = TResolversParentTypes['PaymentCardOutput']> = {
  active?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  address?: Resolver<Maybe<TResolversTypes['PaymentCardAddressOutput']>, ParentType, ContextType>;
  brand?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  expirationMonth?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  expirationYear?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  fullName?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  lastDigits?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  stripeCustomer?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPaymentSystemOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['PaymentSystemOutput'] = TResolversParentTypes['PaymentSystemOutput']> = {
  paymentSystem?: Resolver<TResolversTypes['PaymentSystemEnum'], ParentType, ContextType>;
  paymentSystemId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPersistAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['PersistAssetUnion'] = TResolversParentTypes['PersistAssetUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorDuplicateNameOutput' | 'AssetErrorNameBlacklistedCharactersOutput' | 'AssetErrorNameBlankOutput' | 'AssetErrorNameForbiddenOutput' | 'AssetErrorNameForwardSlashOutput' | 'AssetErrorNameLeadingOrTrailingSpaceOutput' | 'AssetErrorNameTrailingDotOutput' | 'AssetErrorNameTwoDotsInRowOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TPoint3DOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['Point3DOutput'] = TResolversParentTypes['Point3DOutput']> = {
  x?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  z?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPointCloudSourceLidarOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['PointCloudSourceLidarOutput'] = TResolversParentTypes['PointCloudSourceLidarOutput']> = {
  accuracy?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  captureSensor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  coordinateSystem?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  coverage?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  dataProvider?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  dataSampleHR?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  dateCaptured?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  deliveryType?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  downloadFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  minPriceApplies?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  offeredBundleDiscounts?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  pricePerArea?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  productDescription?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  resolution?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  sapArticleNumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  streamingFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPolygonOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['PolygonOutput'] = TResolversParentTypes['PolygonOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput'] = TResolversParentTypes['ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorInternalServerErrorOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorInternalServerErrorOutput'] = TResolversParentTypes['ProcessingErrorInternalServerErrorOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorInvalidInputForProcessingPaymentErrorOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorInvalidInputForProcessingPaymentErrorOutput'] = TResolversParentTypes['ProcessingErrorInvalidInputForProcessingPaymentErrorOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorOperationNotAllowedOutput'] = TResolversParentTypes['ProcessingErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorOutput'] = TResolversParentTypes['ProcessingErrorOutput']> = {
  level?: Resolver<Maybe<TResolversTypes['ProcessingErrorLevel']>, ParentType, ContextType>;
  message?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<TResolversTypes['ProcessingErrorType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorProcessingLimitReachedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorProcessingLimitReachedOutput'] = TResolversParentTypes['ProcessingErrorProcessingLimitReachedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorResourceAccessDeniedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorResourceAccessDeniedOutput'] = TResolversParentTypes['ProcessingErrorResourceAccessDeniedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorResourceNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorResourceNotFoundOutput'] = TResolversParentTypes['ProcessingErrorResourceNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingErrorStorageLimitReachedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingErrorStorageLimitReachedOutput'] = TResolversParentTypes['ProcessingErrorStorageLimitReachedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingPipelineInfoOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingPipelineInfoOutput'] = TResolversParentTypes['ProcessingPipelineInfoOutput']> = {
  errors?: Resolver<Maybe<Array<Maybe<TResolversTypes['ProcessingErrorOutput']>>>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<TResolversTypes['ProcessingStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingPipelineTriggerOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingPipelineTriggerOutput'] = TResolversParentTypes['ProcessingPipelineTriggerOutput']> = {
  errorMessage?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<TResolversTypes['PipelineStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingTypeOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingTypeOutput'] = TResolversParentTypes['ProcessingTypeOutput']> = {
  outputFormat?: Resolver<TResolversTypes['OutputFormat'], ParentType, ContextType>;
  processingPipelineName?: Resolver<TResolversTypes['ProcessingPipelineName'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProcessingUsageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProcessingUsageOutput'] = TResolversParentTypes['ProcessingUsageOutput']> = {
  duration?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  endDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  startDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProduct2DAerialOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['Product2DAerialOutput'] = TResolversParentTypes['Product2DAerialOutput']> = {
  accuracy?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  captureSensor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  coordinateSystem?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  coverage?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  dataProvider?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  dataSampleHR?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  dateCaptured?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  deliveryType?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  downloadFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  minPriceApplies?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  offeredBundleDiscounts?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  pricePerArea?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  productDescription?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  resolution?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  sapArticleNumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  streamingFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProductOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProductOutput'] = TResolversParentTypes['ProductOutput']> = {
  __resolveType: TypeResolveFn<'PointCloudSourceLidarOutput' | 'Product2DAerialOutput', ParentType, ContextType>;
  captureSensor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  coordinateSystem?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  coverage?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  dataProvider?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  dateCaptured?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  deliveryType?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  downloadFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  offeredBundleDiscounts?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  pricePerArea?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  productDescription?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  streamingFormat?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
};

export type TProductUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProductUnion'] = TResolversParentTypes['ProductUnion']> = {
  __resolveType: TypeResolveFn<'QuotationErrorProductNotFoundOutput' | 'QuotationProductOutput', ParentType, ContextType>;
};

export type TProfilePictureOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProfilePictureOutput'] = TResolversParentTypes['ProfilePictureOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectAppliedPageOutput'] = TResolversParentTypes['ProjectAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeCopyOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeCopyOperation'] = TResolversParentTypes['ProjectChangeCopyOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderCopyOperation' | 'ProjectChangeGroupedAssetCopyOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  sourceFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  targetFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeCreateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeCreateOperation'] = TResolversParentTypes['ProjectChangeCreateOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderCreateOperation' | 'ProjectChangeGroupedAssetCreateOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeDeleteOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeDeleteOperation'] = TResolversParentTypes['ProjectChangeDeleteOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderDeleteOperation' | 'ProjectChangeGroupedAssetDeleteOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeFolderCopyOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeFolderCopyOperation'] = TResolversParentTypes['ProjectChangeFolderCopyOperation']> = {
  folder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  folderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  sourceFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  sourceFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  targetFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  targetFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeFolderCreateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeFolderCreateOperation'] = TResolversParentTypes['ProjectChangeFolderCreateOperation']> = {
  folder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  folderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeFolderDeleteOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeFolderDeleteOperation'] = TResolversParentTypes['ProjectChangeFolderDeleteOperation']> = {
  folderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeFolderMoveOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeFolderMoveOperation'] = TResolversParentTypes['ProjectChangeFolderMoveOperation']> = {
  folder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  folderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  sourceFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  sourceFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  targetFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  targetFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeFolderOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeFolderOperation'] = TResolversParentTypes['ProjectChangeFolderOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderCopyOperation' | 'ProjectChangeFolderCreateOperation' | 'ProjectChangeFolderDeleteOperation' | 'ProjectChangeFolderMoveOperation' | 'ProjectChangeFolderUpdateOperation', ParentType, ContextType>;
  folderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeFolderUpdateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeFolderUpdateOperation'] = TResolversParentTypes['ProjectChangeFolderUpdateOperation']> = {
  folder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  folderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  paths?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeGroupedAssetCopyOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeGroupedAssetCopyOperation'] = TResolversParentTypes['ProjectChangeGroupedAssetCopyOperation']> = {
  asset?: Resolver<TResolversTypes['AssetUnion'], ParentType, ContextType>;
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  sourceFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  sourceFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  targetFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  targetFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeGroupedAssetCreateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeGroupedAssetCreateOperation'] = TResolversParentTypes['ProjectChangeGroupedAssetCreateOperation']> = {
  asset?: Resolver<TResolversTypes['AssetUnion'], ParentType, ContextType>;
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeGroupedAssetDeleteOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeGroupedAssetDeleteOperation'] = TResolversParentTypes['ProjectChangeGroupedAssetDeleteOperation']> = {
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeGroupedAssetMoveOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeGroupedAssetMoveOperation'] = TResolversParentTypes['ProjectChangeGroupedAssetMoveOperation']> = {
  asset?: Resolver<TResolversTypes['AssetUnion'], ParentType, ContextType>;
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  sourceFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  sourceFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  targetFolder?: Resolver<TResolversTypes['FolderUnion'], ParentType, ContextType>;
  targetFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeGroupedAssetOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeGroupedAssetOperation'] = TResolversParentTypes['ProjectChangeGroupedAssetOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeGroupedAssetCopyOperation' | 'ProjectChangeGroupedAssetCreateOperation' | 'ProjectChangeGroupedAssetDeleteOperation' | 'ProjectChangeGroupedAssetMoveOperation' | 'ProjectChangeGroupedAssetUpdateOperation', ParentType, ContextType>;
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeGroupedAssetUpdateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeGroupedAssetUpdateOperation'] = TResolversParentTypes['ProjectChangeGroupedAssetUpdateOperation']> = {
  asset?: Resolver<TResolversTypes['AssetUnion'], ParentType, ContextType>;
  didBinaryContentChange?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  groupedAssetId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  paths?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeMoveOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeMoveOperation'] = TResolversParentTypes['ProjectChangeMoveOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderMoveOperation' | 'ProjectChangeGroupedAssetMoveOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  sourceFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  targetFolderId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeOperation'] = TResolversParentTypes['ProjectChangeOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderCopyOperation' | 'ProjectChangeFolderCreateOperation' | 'ProjectChangeFolderDeleteOperation' | 'ProjectChangeFolderMoveOperation' | 'ProjectChangeFolderUpdateOperation' | 'ProjectChangeGroupedAssetCopyOperation' | 'ProjectChangeGroupedAssetCreateOperation' | 'ProjectChangeGroupedAssetDeleteOperation' | 'ProjectChangeGroupedAssetMoveOperation' | 'ProjectChangeGroupedAssetUpdateOperation' | 'ProjectChangeProjectUpdateOperation' | 'ProjectChangeUserAccessGrantedOperation' | 'ProjectChangeUserAccessRevokedOperation', ParentType, ContextType>;
};

export type TProjectChangeOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeOutput'] = TResolversParentTypes['ProjectChangeOutput']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  notifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  operation?: Resolver<TResolversTypes['ProjectChangeOperation'], ParentType, ContextType>;
  project?: Resolver<TResolversTypes['ProjectUnion'], ParentType, ContextType>;
  projectId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  rootVersion?: Resolver<Maybe<TResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangePayloadResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangePayload'] = TResolversParentTypes['ProjectChangePayload']> = {
  __resolveType: TypeResolveFn<'ProjectChangeOutput' | 'ServerError' | 'UnauthenticatedError', ParentType, ContextType>;
};

export type TProjectChangeProjectOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeProjectOperation'] = TResolversParentTypes['ProjectChangeProjectOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeOutput' | 'ProjectChangeProjectUpdateOperation', ParentType, ContextType>;
  projectId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeProjectUpdateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeProjectUpdateOperation'] = TResolversParentTypes['ProjectChangeProjectUpdateOperation']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  paths?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  project?: Resolver<TResolversTypes['ProjectUnion'], ParentType, ContextType>;
  projectId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeUpdateOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeUpdateOperation'] = TResolversParentTypes['ProjectChangeUpdateOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeFolderUpdateOperation' | 'ProjectChangeGroupedAssetUpdateOperation' | 'ProjectChangeProjectUpdateOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  paths?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
};

export type TProjectChangeUserAccessGrantedOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeUserAccessGrantedOperation'] = TResolversParentTypes['ProjectChangeUserAccessGrantedOperation']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectChangeUserAccessOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeUserAccessOperation'] = TResolversParentTypes['ProjectChangeUserAccessOperation']> = {
  __resolveType: TypeResolveFn<'ProjectChangeUserAccessGrantedOperation' | 'ProjectChangeUserAccessRevokedOperation', ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
};

export type TProjectChangeUserAccessRevokedOperationResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectChangeUserAccessRevokedOperation'] = TResolversParentTypes['ProjectChangeUserAccessRevokedOperation']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorDuplicateNameOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorDuplicateNameOutput'] = TResolversParentTypes['ProjectErrorDuplicateNameOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorInvalidInputOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorInvalidInputOutput'] = TResolversParentTypes['ProjectErrorInvalidInputOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameBlacklistedCharactersOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameBlacklistedCharactersOutput'] = TResolversParentTypes['ProjectErrorNameBlacklistedCharactersOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameBlankOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameBlankOutput'] = TResolversParentTypes['ProjectErrorNameBlankOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameForbiddenOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameForbiddenOutput'] = TResolversParentTypes['ProjectErrorNameForbiddenOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameForwardSlashOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameForwardSlashOutput'] = TResolversParentTypes['ProjectErrorNameForwardSlashOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameLeadingOrTrailingSpaceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput'] = TResolversParentTypes['ProjectErrorNameLeadingOrTrailingSpaceOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameTrailingDotOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameTrailingDotOutput'] = TResolversParentTypes['ProjectErrorNameTrailingDotOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorNameTwoDotsInRowOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorNameTwoDotsInRowOutput'] = TResolversParentTypes['ProjectErrorNameTwoDotsInRowOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorOperationNotAllowedOutput'] = TResolversParentTypes['ProjectErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorResourceAccessDeniedForTargetUserOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorResourceAccessDeniedForTargetUserOutput'] = TResolversParentTypes['ProjectErrorResourceAccessDeniedForTargetUserOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorResourceAccessDeniedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput'] = TResolversParentTypes['ProjectErrorResourceAccessDeniedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectErrorTooManyRequestsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectErrorTooManyRequestsOutput'] = TResolversParentTypes['ProjectErrorTooManyRequestsOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectMemberOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectMemberOutput'] = TResolversParentTypes['ProjectMemberOutput']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  invitedBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  invitedOn?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  projectAccountRole?: Resolver<TResolversTypes['AccountRoleEnum'], ParentType, ContextType>;
  projectRole?: Resolver<TResolversTypes['ProjectRoleEnum'], ParentType, ContextType>;
  userDetails?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectMembersResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectMembersResultOutput'] = TResolversParentTypes['ProjectMembersResultOutput']> = {
  contents?: Resolver<Array<TResolversTypes['ProjectMemberOutput']>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['ProjectMemberOrderEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectOutput'] = TResolversParentTypes['ProjectOutput']> = {
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  deletable?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  labels?: Resolver<TResolversTypes['LabelsOutput'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<TResolversTypes['Float']>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  modifiedBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  ownedBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  projectMembers?: Resolver<TResolversTypes['ProjectMembersResultOutput'], ParentType, ContextType, Partial<TProjectOutputProjectMembersArgs>>;
  rootFolder?: Resolver<TResolversTypes['FolderOutput'], ParentType, ContextType>;
  thumbnailFullImagePath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  totalAssets?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  totalUploadingAssets?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectResultOutput'] = TResolversParentTypes['ProjectResultOutput']> = {
  appliedFilters?: Resolver<TResolversTypes['FilterProjectAppliedOutput'], ParentType, ContextType>;
  appliedPagination?: Resolver<TResolversTypes['ProjectAppliedPageOutput'], ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['ProjectOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<TResolversTypes['ProjectOrderEnum'], ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectThumbnailOutput'] = TResolversParentTypes['ProjectThumbnailOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectUnion'] = TResolversParentTypes['ProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'ProjectOutput', ParentType, ContextType>;
};

export type TProjectsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['ProjectsUnion'] = TResolversParentTypes['ProjectsUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'ProjectResultOutput', ParentType, ContextType>;
};

export type TPropertySpatialResolvers<ContextType = any, ParentType extends TResolversParentTypes['PropertySpatial'] = TResolversParentTypes['PropertySpatial']> = {
  bbox?: Resolver<Array<Array<TResolversTypes['Float']>>, ParentType, ContextType>;
  crs?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TPropertyTemporalResolvers<ContextType = any, ParentType extends TResolversParentTypes['PropertyTemporal'] = TResolversParentTypes['PropertyTemporal']> = {
  interval?: Resolver<Array<Maybe<Array<Maybe<TResolversTypes['DateTime']>>>>, ParentType, ContextType>;
  trs?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TProviderResolvers<ContextType = any, ParentType extends TResolversParentTypes['Provider'] = TResolversParentTypes['Provider']> = {
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<TResolversTypes['ProviderRole']>>, ParentType, ContextType>;
  url?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TQueryResolvers<ContextType = any, ParentType extends TResolversParentTypes['Query'] = TResolversParentTypes['Query']> = {
  _entities?: Resolver<Array<Maybe<TResolversTypes['_Entity']>>, ParentType, ContextType, RequireFields<TQuery_EntitiesArgs, 'representations'>>;
  _getDummyAddressPage?: Resolver<Maybe<TResolversTypes['AddressPageOutput']>, ParentType, ContextType>;
  _getInfo?: Resolver<Maybe<TResolversTypes['InfoPage']>, ParentType, ContextType, RequireFields<TQuery_GetInfoArgs, 'page' | 'pageSize'>>;
  _noop?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  _returnDummyJobPage?: Resolver<Maybe<TResolversTypes['JobPage']>, ParentType, ContextType>;
  _returnDummyJobSummaryGQL?: Resolver<Maybe<TResolversTypes['JobSummaryGQL']>, ParentType, ContextType>;
  accountInformation?: Resolver<TResolversTypes['AccountDetailUnion'], ParentType, ContextType>;
  accountMembers?: Resolver<TResolversTypes['AccountMembersResultUnion'], ParentType, ContextType, Partial<TQueryAccountMembersArgs>>;
  allCountries?: Resolver<Array<Maybe<TResolversTypes['CountryOutput']>>, ParentType, ContextType>;
  applicationTemplate?: Resolver<TResolversTypes['ApplicationTemplateUnion'], ParentType, ContextType, RequireFields<TQueryApplicationTemplateArgs, 'id'>>;
  applicationTemplates?: Resolver<TResolversTypes['ApplicationTemplatesUnion'], ParentType, ContextType, Partial<TQueryApplicationTemplatesArgs>>;
  asset?: Resolver<Maybe<TResolversTypes['AssetUnion']>, ParentType, ContextType, RequireFields<TQueryAssetArgs, 'groupedAssetId'>>;
  assetsInAccount?: Resolver<Maybe<TResolversTypes['AssetsInAccountUnion']>, ParentType, ContextType, Partial<TQueryAssetsInAccountArgs>>;
  assetsInFolder?: Resolver<Maybe<TResolversTypes['AssetsInFolderUnion']>, ParentType, ContextType, RequireFields<TQueryAssetsInFolderArgs, 'folderId'>>;
  availableProcessingPipelines?: Resolver<Array<TResolversTypes['ProcessingTypeOutput']>, ParentType, ContextType, RequireFields<TQueryAvailableProcessingPipelinesArgs, 'groupedAssetId'>>;
  collection?: Resolver<Maybe<TResolversTypes['Collection']>, ParentType, ContextType, RequireFields<TQueryCollectionArgs, 'id'>>;
  collections?: Resolver<Array<Maybe<TResolversTypes['Collection']>>, ParentType, ContextType>;
  comment?: Resolver<TResolversTypes['CommentPayload'], ParentType, ContextType, RequireFields<TQueryCommentArgs, 'id'>>;
  comments?: Resolver<TResolversTypes['CommentsPayload'], ParentType, ContextType, RequireFields<TQueryCommentsArgs, 'onAnchorId' | 'onAnchorType'>>;
  dummyQuery?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  externalAccountMembers?: Resolver<TResolversTypes['ExternalAccountMembersResultUnion'], ParentType, ContextType>;
  folder?: Resolver<Maybe<TResolversTypes['FolderUnion']>, ParentType, ContextType, RequireFields<TQueryFolderArgs, 'folderId'>>;
  gatewayInfo?: Resolver<TResolversTypes['GatewayInfoOutput'], ParentType, ContextType>;
  getAccount?: Resolver<Maybe<TResolversTypes['AccountInfoOutput']>, ParentType, ContextType>;
  getAccountInformation?: Resolver<Maybe<TResolversTypes['AccountDetailOutput']>, ParentType, ContextType>;
  getAccountMembers?: Resolver<Maybe<TResolversTypes['AccountMembersResultOutput']>, ParentType, ContextType, Partial<TQueryGetAccountMembersArgs>>;
  getAccountUsage?: Resolver<TResolversTypes['UsageOutput'], ParentType, ContextType, RequireFields<TQueryGetAccountUsageArgs, 'params'>>;
  getAccountUsageV2?: Resolver<TResolversTypes['UsageAccountUnion'], ParentType, ContextType, RequireFields<TQueryGetAccountUsageV2Args, 'params'>>;
  getAccounts?: Resolver<Maybe<TResolversTypes['AccountProfileResultOutput']>, ParentType, ContextType, Partial<TQueryGetAccountsArgs>>;
  getActivePaymentCard?: Resolver<Maybe<TResolversTypes['PaymentCardOutput']>, ParentType, ContextType>;
  getAllBaselayers?: Resolver<TResolversTypes['BaselayerResultOutput'], ParentType, ContextType, Partial<TQueryGetAllBaselayersArgs>>;
  getAllGeoreferences?: Resolver<TResolversTypes['GeoreferenceResultsOutput'], ParentType, ContextType, Partial<TQueryGetAllGeoreferencesArgs>>;
  getAllGroupedAssetGeoreferences?: Resolver<TResolversTypes['GroupedAssetGeoreferenceResultsOutput'], ParentType, ContextType, Partial<TQueryGetAllGroupedAssetGeoreferencesArgs>>;
  getAllOrders?: Resolver<Array<TResolversTypes['OrderGQL']>, ParentType, ContextType>;
  getAllPaymentCards?: Resolver<Array<TResolversTypes['PaymentCardOutput']>, ParentType, ContextType>;
  getAllSubscriptionInvoices?: Resolver<Array<Maybe<TResolversTypes['SubscriptionInvoiceOutput']>>, ParentType, ContextType>;
  getAllSubscriptionInvoicesV2?: Resolver<TResolversTypes['SubscriptionInvoicesUnion'], ParentType, ContextType>;
  getAllSubscriptionOrders?: Resolver<Array<Maybe<TResolversTypes['SubscriptionOrderOutput']>>, ParentType, ContextType>;
  getAllSubscriptionOrdersV2?: Resolver<TResolversTypes['SubscriptionOrdersUnion'], ParentType, ContextType>;
  getAllSubscriptions?: Resolver<Array<TResolversTypes['SubscriptionOutput']>, ParentType, ContextType>;
  getAllSubscriptionsV2?: Resolver<TResolversTypes['SubscriptionsUnion'], ParentType, ContextType>;
  getAllTenants?: Resolver<Array<TResolversTypes['TenantOutput']>, ParentType, ContextType>;
  getArtifactsInAsset?: Resolver<Maybe<TResolversTypes['ArtifactResultOutput']>, ParentType, ContextType, RequireFields<TQueryGetArtifactsInAssetArgs, 'assetId'>>;
  getAsset?: Resolver<Maybe<TResolversTypes['GroupedAssetOutput']>, ParentType, ContextType, RequireFields<TQueryGetAssetArgs, 'groupedAssetId'>>;
  getAssetsInFolder?: Resolver<Maybe<TResolversTypes['FolderOutput']>, ParentType, ContextType, RequireFields<TQueryGetAssetsInFolderArgs, 'folderId'>>;
  getCart?: Resolver<Maybe<TResolversTypes['CartOutput']>, ParentType, ContextType>;
  getCurrentSubscription?: Resolver<Maybe<TResolversTypes['SubscriptionOutput']>, ParentType, ContextType>;
  getCurrentSubscriptionV2?: Resolver<TResolversTypes['SubscriptionUnion'], ParentType, ContextType>;
  getDevice?: Resolver<Maybe<TResolversTypes['DeviceOutput']>, ParentType, ContextType, Partial<TQueryGetDeviceArgs>>;
  getDevices?: Resolver<Maybe<TResolversTypes['DeviceResultOutput']>, ParentType, ContextType, Partial<TQueryGetDevicesArgs>>;
  getFileStatus?: Resolver<Maybe<TResolversTypes['FileStatusOutput']>, ParentType, ContextType, RequireFields<TQueryGetFileStatusArgs, 'assetId' | 'fileId'>>;
  getFiles?: Resolver<Maybe<TResolversTypes['FileResultOutput']>, ParentType, ContextType, RequireFields<TQueryGetFilesArgs, 'assetId'>>;
  getFolder?: Resolver<Maybe<TResolversTypes['FolderOutput']>, ParentType, ContextType, RequireFields<TQueryGetFolderArgs, 'folderId'>>;
  getGeoreference?: Resolver<TResolversTypes['GeoreferenceOutput'], ParentType, ContextType, RequireFields<TQueryGetGeoreferenceArgs, 'georeferenceId'>>;
  getGroupedAssetGeoreference?: Resolver<TResolversTypes['GroupedAssetGeoreferenceOutput'], ParentType, ContextType, RequireFields<TQueryGetGroupedAssetGeoreferenceArgs, 'georeferenceId'>>;
  getGroupedAssetTour?: Resolver<Maybe<TResolversTypes['GroupedAssetTourOutput']>, ParentType, ContextType, RequireFields<TQueryGetGroupedAssetTourArgs, 'params'>>;
  getLastOrder?: Resolver<Maybe<TResolversTypes['OrderGQL']>, ParentType, ContextType>;
  getLuciadRIALicense?: Resolver<TResolversTypes['LuciadRIALicenseUnion'], ParentType, ContextType, RequireFields<TQueryGetLuciadRiaLicenseArgs, 'appClientId' | 'luciadRIAVersion'>>;
  getMultipartUploadURL?: Resolver<Maybe<TResolversTypes['MultipartUploadUrlOutput']>, ParentType, ContextType, RequireFields<TQueryGetMultipartUploadUrlArgs, 'params'>>;
  getMyAssetsProject?: Resolver<Maybe<TResolversTypes['ProjectOutput']>, ParentType, ContextType>;
  getOrder?: Resolver<Maybe<TResolversTypes['OrderGQL']>, ParentType, ContextType, RequireFields<TQueryGetOrderArgs, 'cartId'>>;
  getOrderItemsForAsset?: Resolver<Array<TResolversTypes['OrderItemGQL']>, ParentType, ContextType, RequireFields<TQueryGetOrderItemsForAssetArgs, 'assetId' | 'userId'>>;
  getPaymentMethods?: Resolver<Array<Maybe<TResolversTypes['PaymentMethodShopEnum']>>, ParentType, ContextType>;
  getProject?: Resolver<Maybe<TResolversTypes['ProjectOutput']>, ParentType, ContextType, RequireFields<TQueryGetProjectArgs, 'projectId'>>;
  getProjectMember?: Resolver<Maybe<TResolversTypes['SimpleUserProfileUnion']>, ParentType, ContextType, RequireFields<TQueryGetProjectMemberArgs, 'projectId' | 'userId'>>;
  getProjects?: Resolver<Maybe<TResolversTypes['ProjectResultOutput']>, ParentType, ContextType, Partial<TQueryGetProjectsArgs>>;
  getQuotation?: Resolver<Maybe<TResolversTypes['QuotationOutput']>, ParentType, ContextType, RequireFields<TQueryGetQuotationArgs, 'selection'>>;
  getReferrers?: Resolver<Maybe<Array<Maybe<TResolversTypes['ReferrerOutput']>>>, ParentType, ContextType>;
  getRolesOfAllUsersInProject?: Resolver<Array<Maybe<TResolversTypes['UserProjectRoleOutput']>>, ParentType, ContextType, RequireFields<TQueryGetRolesOfAllUsersInProjectArgs, 'projectUUID'>>;
  getStripePublicKey?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  getSubscriptionQuotation?: Resolver<TResolversTypes['SubscriptionQuotationOutput'], ParentType, ContextType, Partial<TQueryGetSubscriptionQuotationArgs>>;
  getSubscriptionQuotationV2?: Resolver<TResolversTypes['SubscriptionQuotationUnion'], ParentType, ContextType, Partial<TQueryGetSubscriptionQuotationV2Args>>;
  getSubscriptionTiers?: Resolver<Array<Maybe<TResolversTypes['SubscriptionTierOutput']>>, ParentType, ContextType>;
  getSubscriptionTiersV2?: Resolver<TResolversTypes['SubscriptionTiersUnion'], ParentType, ContextType>;
  getTenant?: Resolver<TResolversTypes['TenantOutput'], ParentType, ContextType, Partial<TQueryGetTenantArgs>>;
  getUploadedParts?: Resolver<Maybe<TResolversTypes['MultipartUploadPartsOutput']>, ParentType, ContextType, RequireFields<TQueryGetUploadedPartsArgs, 'fileId'>>;
  getUser?: Resolver<TResolversTypes['UserProfileOutput'], ParentType, ContextType>;
  getUsers?: Resolver<Maybe<TResolversTypes['UserProfileResultOutput']>, ParentType, ContextType, Partial<TQueryGetUsersArgs>>;
  isAddressSupportedByShop?: Resolver<TResolversTypes['ShopSupportedCountryValidationOutput'], ParentType, ContextType>;
  isLastUploadConsentValid?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  item?: Resolver<TResolversTypes['FeatureItem'], ParentType, ContextType, RequireFields<TQueryItemArgs, 'collectionId' | 'itemId'>>;
  latestApplicationTemplateVersion?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  loadUserCacheAsMine?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType, Partial<TQueryLoadUserCacheAsMineArgs>>;
  multipartUploadURL?: Resolver<Maybe<TResolversTypes['MultipartUploadUrlUnion']>, ParentType, ContextType, RequireFields<TQueryMultipartUploadUrlArgs, 'params'>>;
  myAssetsProject?: Resolver<Maybe<TResolversTypes['MyAssetsProjectUnion']>, ParentType, ContextType>;
  noop?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  product?: Resolver<TResolversTypes['ProductUnion'], ParentType, ContextType, RequireFields<TQueryProductArgs, 'params'>>;
  products?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  project?: Resolver<Maybe<TResolversTypes['ProjectUnion']>, ParentType, ContextType, RequireFields<TQueryProjectArgs, 'projectId'>>;
  projects?: Resolver<Maybe<TResolversTypes['ProjectsUnion']>, ParentType, ContextType, Partial<TQueryProjectsArgs>>;
  quotation?: Resolver<Maybe<TResolversTypes['QuotationUnion']>, ParentType, ContextType, RequireFields<TQueryQuotationArgs, 'selection'>>;
  rolesOfProjectUsers?: Resolver<TResolversTypes['UserProjectRoleUnion'], ParentType, ContextType, RequireFields<TQueryRolesOfProjectUsersArgs, 'projectUUID'>>;
  search?: Resolver<TResolversTypes['ItemCollection'], ParentType, ContextType, RequireFields<TQuerySearchArgs, 'limit'>>;
  searchLabels?: Resolver<TResolversTypes['SearchLabelsUnion'], ParentType, ContextType, RequireFields<TQuerySearchLabelsArgs, 'input'>>;
  searchMyAssetsProject?: Resolver<Maybe<TResolversTypes['SearchOutput']>, ParentType, ContextType>;
  searchMyAssetsProjectV2?: Resolver<Maybe<TResolversTypes['SearchMyAssetsProjectUnion']>, ParentType, ContextType>;
  searchProject?: Resolver<Maybe<TResolversTypes['SearchOutput']>, ParentType, ContextType, RequireFields<TQuerySearchProjectArgs, 'projectId'>>;
  searchProjectV2?: Resolver<Maybe<TResolversTypes['SearchProjectUnion']>, ParentType, ContextType, RequireFields<TQuerySearchProjectV2Args, 'projectId'>>;
  sharedGroupedAsset?: Resolver<TResolversTypes['SharedGroupedAssetUnion'], ParentType, ContextType, RequireFields<TQuerySharedGroupedAssetArgs, 'sharingCodeId'>>;
  supportedCountries?: Resolver<Array<Maybe<TResolversTypes['SupportedCountryOutput']>>, ParentType, ContextType>;
  tenant?: Resolver<TResolversTypes['TenantUnion'], ParentType, ContextType, Partial<TQueryTenantArgs>>;
  tenantUsage?: Resolver<TResolversTypes['UsageTenantOutput'], ParentType, ContextType, RequireFields<TQueryTenantUsageArgs, 'params'>>;
  tenantUsageV2?: Resolver<TResolversTypes['UsageTenantUnion'], ParentType, ContextType, RequireFields<TQueryTenantUsageV2Args, 'params'>>;
  tenants?: Resolver<TResolversTypes['TenantsUnion'], ParentType, ContextType>;
  tokenWallet?: Resolver<TResolversTypes['WalletUnion'], ParentType, ContextType>;
  tokensTransactions?: Resolver<TResolversTypes['TokensTransactionsUnion'], ParentType, ContextType, Partial<TQueryTokensTransactionsArgs>>;
  validateAddress?: Resolver<Maybe<TResolversTypes['AddressValidOutput']>, ParentType, ContextType, Partial<TQueryValidateAddressArgs>>;
  viewAllProducts?: Resolver<Maybe<Array<Maybe<TResolversTypes['HXDRProductOutput']>>>, ParentType, ContextType>;
  viewCart?: Resolver<Maybe<TResolversTypes['CartOutput']>, ParentType, ContextType, RequireFields<TQueryViewCartArgs, 'cartId'>>;
  viewProduct?: Resolver<Maybe<TResolversTypes['HXDRProductOutput']>, ParentType, ContextType, RequireFields<TQueryViewProductArgs, 'params'>>;
};

export type TQuotationErrorProductNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['QuotationErrorProductNotFoundOutput'] = TResolversParentTypes['QuotationErrorProductNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TQuotationItemOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['QuotationItemOutput'] = TResolversParentTypes['QuotationItemOutput']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  discountRate?: Resolver<Maybe<TResolversTypes['BigDecimal']>, ParentType, ContextType>;
  discountedAmount?: Resolver<Maybe<TResolversTypes['BigDecimal']>, ParentType, ContextType>;
  quotationItemId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  resolutionUnit?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  resolutionValue?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TQuotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['QuotationOutput'] = TResolversParentTypes['QuotationOutput']> = {
  coordinateSystems?: Resolver<Array<TResolversTypes['CoordinateSystemOutput']>, ParentType, ContextType>;
  dataType?: Resolver<TResolversTypes['DataTypeEnum'], ParentType, ContextType>;
  formats?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  message?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  polygonDefType?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  productIdentifier?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  quotationId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  quotationItems?: Resolver<Array<Maybe<TResolversTypes['QuotationItemOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TQuotationProductOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['QuotationProductOutput'] = TResolversParentTypes['QuotationProductOutput']> = {
  productCode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  productDescription?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  productName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  sapArticleNumber?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TQuotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['QuotationUnion'] = TResolversParentTypes['QuotationUnion']> = {
  __resolveType: TypeResolveFn<'QuoteOutput' | 'ShopErrorBackendFailedOutput' | 'ShopErrorNoOverlapWithProductOutput' | 'ShopErrorOperationNotAllowedOutput' | 'ShopErrorProductNotFoundOutput' | 'ShopErrorProductPricingNotFoundOutput', ParentType, ContextType>;
};

export type TQuoteOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['QuoteOutput'] = TResolversParentTypes['QuoteOutput']> = {
  coordinateSystems?: Resolver<Array<TResolversTypes['CoordinateSystemOutput']>, ParentType, ContextType>;
  coveragePercentage?: Resolver<Maybe<TResolversTypes['BigDecimal']>, ParentType, ContextType>;
  dataType?: Resolver<TResolversTypes['DataTypeEnum'], ParentType, ContextType>;
  formats?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  polygonDefType?: Resolver<TResolversTypes['PolygonType'], ParentType, ContextType>;
  productIdentifier?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  quotationId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  quotationItems?: Resolver<Array<Maybe<TResolversTypes['QuotationItemOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TReferencedBoundsDimensionsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ReferencedBoundsDimensionsOutput'] = TResolversParentTypes['ReferencedBoundsDimensionsOutput']> = {
  depth?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  height?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  width?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TReferencedBoundsGeolocationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ReferencedBoundsGeolocationOutput'] = TResolversParentTypes['ReferencedBoundsGeolocationOutput']> = {
  height?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  latitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  longitude?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TReferencedBoundsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ReferencedBoundsOutput'] = TResolversParentTypes['ReferencedBoundsOutput']> = {
  cartesianBounds?: Resolver<TResolversTypes['CartesianBoundsOutput'], ParentType, ContextType>;
  originGeolocation?: Resolver<TResolversTypes['ReferencedBoundsGeolocationOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TReferrerOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ReferrerOutput'] = TResolversParentTypes['ReferrerOutput']> = {
  referrerId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  referrerName?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TRemoveLabelsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['RemoveLabelsUnion'] = TResolversParentTypes['RemoveLabelsUnion']> = {
  __resolveType: TypeResolveFn<'LabelErrorOperationNotAllowedOutput' | 'LabelsOutput', ParentType, ContextType>;
};

export type TRenderableResolvers<ContextType = any, ParentType extends TResolversParentTypes['Renderable'] = TResolversParentTypes['Renderable']> = {
  __resolveType: TypeResolveFn<'AddressAssetFileDownloadOutput' | 'AddressDownloadOutput' | 'AddressHspcOutput' | 'AddressLtsOutput' | 'AddressOgc3DOutput' | 'AddressPanoramicOutput' | 'AddressWfsOutput' | 'AddressWmsOutput' | 'AddressWmtsOutput', ParentType, ContextType>;
  endpoint?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['AddressTypeEnum'], ParentType, ContextType>;
};

export type TReportGenerationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ReportGenerationOutput'] = TResolversParentTypes['ReportGenerationOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  statusCode?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSearchLabelsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SearchLabelsUnion'] = TResolversParentTypes['SearchLabelsUnion']> = {
  __resolveType: TypeResolveFn<'LabelErrorOperationNotAllowedOutput' | 'LabelsResultOutput', ParentType, ContextType>;
};

export type TSearchMyAssetsProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SearchMyAssetsProjectUnion'] = TResolversParentTypes['SearchMyAssetsProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'SearchOutput', ParentType, ContextType>;
};

export type TSearchOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SearchOutput'] = TResolversParentTypes['SearchOutput']> = {
  assets?: Resolver<Maybe<TResolversTypes['GroupedAssetResultOutput']>, ParentType, ContextType, Partial<TSearchOutputAssetsArgs>>;
  folders?: Resolver<Maybe<TResolversTypes['FolderResultOutput']>, ParentType, ContextType, Partial<TSearchOutputFoldersArgs>>;
  projectId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSearchProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SearchProjectUnion'] = TResolversParentTypes['SearchProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'SearchOutput', ParentType, ContextType>;
};

export type TSelectedSubscriptionTierOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SelectedSubscriptionTierOutput'] = TResolversParentTypes['SelectedSubscriptionTierOutput']> = {
  directUploads?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  limits?: Resolver<Array<TResolversTypes['SubscriptionLimitOutput']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<Maybe<TResolversTypes['SubscriptionPriceOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TServerErrorResolvers<ContextType = any, ParentType extends TResolversParentTypes['ServerError'] = TResolversParentTypes['ServerError']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSetActivePaymentCardOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SetActivePaymentCardOutput'] = TResolversParentTypes['SetActivePaymentCardOutput']> = {
  active?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  externalPaymentCardId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSetupPaymentCardCreationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SetupPaymentCardCreationOutput'] = TResolversParentTypes['SetupPaymentCardCreationOutput']> = {
  clientSecret?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  externalPaymentCardIntentId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  publicKey?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSharedGroupedAssetOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SharedGroupedAssetOutput'] = TResolversParentTypes['SharedGroupedAssetOutput']> = {
  auth?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSharedGroupedAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SharedGroupedAssetUnion'] = TResolversParentTypes['SharedGroupedAssetUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorEntityNotFoundOutput' | 'AcsErrorOperationNotPossibleOutput' | 'SharedGroupedAssetOutput', ParentType, ContextType>;
};

export type TSharingCodeOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SharingCodeOutput'] = TResolversParentTypes['SharingCodeOutput']> = {
  sharingCodeId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSharingCodeUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SharingCodeUnion'] = TResolversParentTypes['SharingCodeUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorEntityNotFoundOutput' | 'AcsErrorOperationNotAllowedOutput' | 'AcsErrorOperationNotPossibleOutput' | 'SharingCodeOutput', ParentType, ContextType>;
};

export type TShopErrorBackendFailedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ShopErrorBackendFailedOutput'] = TResolversParentTypes['ShopErrorBackendFailedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TShopErrorNoOverlapWithProductOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ShopErrorNoOverlapWithProductOutput'] = TResolversParentTypes['ShopErrorNoOverlapWithProductOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TShopErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ShopErrorOperationNotAllowedOutput'] = TResolversParentTypes['ShopErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TShopErrorProductNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ShopErrorProductNotFoundOutput'] = TResolversParentTypes['ShopErrorProductNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TShopErrorProductPricingNotFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ShopErrorProductPricingNotFoundOutput'] = TResolversParentTypes['ShopErrorProductPricingNotFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TShopSupportedCountryValidationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ShopSupportedCountryValidationOutput'] = TResolversParentTypes['ShopSupportedCountryValidationOutput']> = {
  supported?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSignupFromInviteUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SignupFromInviteUnion'] = TResolversParentTypes['SignupFromInviteUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorInvalidInvitationSignatureOutput' | 'AcsErrorSeatLimitReachedOutput' | 'AcsErrorTooManyRequestsOutput' | 'AcsErrorUserAlreadyExistsOutput' | 'SignupOutput', ParentType, ContextType>;
};

export type TSignupOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SignupOutput'] = TResolversParentTypes['SignupOutput']> = {
  user?: Resolver<Maybe<TResolversTypes['UserProfileOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSignupUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SignupUnion'] = TResolversParentTypes['SignupUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorInvalidVoucherOutput' | 'AcsErrorTooManyRequestsOutput' | 'AcsErrorUserAlreadyExistsOutput' | 'AcsErrorVoucherAlreadyUsedOutput' | 'SignupOutput', ParentType, ContextType>;
};

export type TSimpleAccountInfoOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SimpleAccountInfoOutput'] = TResolversParentTypes['SimpleAccountInfoOutput']> = {
  company?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  logo?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  tenantId?: Resolver<Maybe<TResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSimpleResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SimpleResultOutput'] = TResolversParentTypes['SimpleResultOutput']> = {
  result?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSimpleResultUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SimpleResultUnion'] = TResolversParentTypes['SimpleResultUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorEntityNotFoundOutput' | 'AcsErrorOperationNotAllowedOutput' | 'AcsErrorOperationNotPossibleOutput' | 'SimpleResultOutput', ParentType, ContextType>;
};

export type TSimpleUserProfileOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SimpleUserProfileOutput'] = TResolversParentTypes['SimpleUserProfileOutput']> = {
  accountRole?: Resolver<Maybe<TResolversTypes['AccountRoleEnum']>, ParentType, ContextType>;
  applicationRoles?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  companyInfo?: Resolver<Maybe<TResolversTypes['SimpleAccountInfoOutput']>, ParentType, ContextType>;
  email?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  lastLoginAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  profilePictureUrl?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  signedUpAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  tenantRole?: Resolver<Maybe<TResolversTypes['TenantRoleEnum']>, ParentType, ContextType>;
  userFlags?: Resolver<Array<Maybe<TResolversTypes['UserFlagOutput']>>, ParentType, ContextType>;
  userSettings?: Resolver<TResolversTypes['UserSettingsOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSimpleUserProfileUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SimpleUserProfileUnion'] = TResolversParentTypes['SimpleUserProfileUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedForTargetUserOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'SimpleUserProfileOutput', ParentType, ContextType>;
};

export type TStreamingUsageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['StreamingUsageOutput'] = TResolversParentTypes['StreamingUsageOutput']> = {
  baseLayer?: Resolver<Maybe<TResolversTypes['BigInteger']>, ParentType, ContextType>;
  baseLayerStreamer?: Resolver<Maybe<TResolversTypes['BigInteger']>, ParentType, ContextType>;
  downloads?: Resolver<Maybe<TResolversTypes['BigInteger']>, ParentType, ContextType>;
  endDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  externalServices?: Resolver<Maybe<TResolversTypes['BigInteger']>, ParentType, ContextType>;
  files?: Resolver<Maybe<TResolversTypes['BigInteger']>, ParentType, ContextType>;
  graphQl?: Resolver<Maybe<TResolversTypes['BigInteger']>, ParentType, ContextType>;
  startDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubProcessGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubProcessGQL'] = TResolversParentTypes['SubProcessGQL']> = {
  duration?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  lastState?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  numberOfRetries?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  start?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  state?: Resolver<TResolversTypes['JobStatus'], ParentType, ContextType>;
  tasks?: Resolver<Array<TResolversTypes['TaskGQL']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionResolvers<ContextType = any, ParentType extends TResolversParentTypes['Subscription'] = TResolversParentTypes['Subscription']> = {
  _noop?: SubscriptionResolver<Maybe<TResolversTypes['Boolean']>, "_noop", ParentType, ContextType>;
  commentAdded?: SubscriptionResolver<TResolversTypes['CommentPayload'], "commentAdded", ParentType, ContextType, RequireFields<TSubscriptionCommentAddedArgs, 'onAnchor'>>;
  commentDeleted?: SubscriptionResolver<TResolversTypes['CommentPayload'], "commentDeleted", ParentType, ContextType, RequireFields<TSubscriptionCommentDeletedArgs, 'onAnchor'>>;
  commentUpdated?: SubscriptionResolver<TResolversTypes['CommentPayload'], "commentUpdated", ParentType, ContextType, RequireFields<TSubscriptionCommentUpdatedArgs, 'onAnchor'>>;
  countdown?: SubscriptionResolver<TResolversTypes['Int'], "countdown", ParentType, ContextType, RequireFields<TSubscriptionCountdownArgs, 'from'>>;
  projectChanged?: SubscriptionResolver<TResolversTypes['ProjectChangePayload'], "projectChanged", ParentType, ContextType>;
};

export type TSubscriptionAddonOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionAddonOutput'] = TResolversParentTypes['SubscriptionAddonOutput']> = {
  addonOptionName?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  price?: Resolver<TResolversTypes['SubscriptionPriceOutput'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['SubscriptionAddonTypeEnum'], ParentType, ContextType>;
  value?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionErrorAccountNotSyncedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput'] = TResolversParentTypes['SubscriptionErrorAccountNotSyncedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionErrorBadRequestOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionErrorBadRequestOutput'] = TResolversParentTypes['SubscriptionErrorBadRequestOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionErrorDuplicateWithSameAddonOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionErrorDuplicateWithSameAddonOutput'] = TResolversParentTypes['SubscriptionErrorDuplicateWithSameAddonOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionErrorFeatureNotAvailableOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput'] = TResolversParentTypes['SubscriptionErrorFeatureNotAvailableOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionErrorOperationNotAllowedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput'] = TResolversParentTypes['SubscriptionErrorOperationNotAllowedOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionInvoiceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionInvoiceOutput'] = TResolversParentTypes['SubscriptionInvoiceOutput']> = {
  billingPeriodEndDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  billingPeriodStartDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  dueDate?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  pdfLink?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<TResolversTypes['InvoiceStatusEnum'], ParentType, ContextType>;
  totalAmount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionInvoicesOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionInvoicesOutput'] = TResolversParentTypes['SubscriptionInvoicesOutput']> = {
  subscriptionInvoiceOutputs?: Resolver<Array<Maybe<TResolversTypes['SubscriptionInvoiceOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionInvoicesUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionInvoicesUnion'] = TResolversParentTypes['SubscriptionInvoicesUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionInvoicesOutput' | 'SubscriptionNoValidIsoCountryFoundOutput', ParentType, ContextType>;
};

export type TSubscriptionLimitOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionLimitOutput'] = TResolversParentTypes['SubscriptionLimitOutput']> = {
  name?: Resolver<TResolversTypes['SubscriptionLimitEnum'], ParentType, ContextType>;
  value?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionNoValidIsoCountryFoundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput'] = TResolversParentTypes['SubscriptionNoValidIsoCountryFoundOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionOrderItemOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionOrderItemOutput'] = TResolversParentTypes['SubscriptionOrderItemOutput']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  subscriptionTier?: Resolver<TResolversTypes['SubscriptionTierOutput'], ParentType, ContextType>;
  subscriptionTierAddon?: Resolver<TResolversTypes['SubscriptionTierAddonOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionOrderOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionOrderOutput'] = TResolversParentTypes['SubscriptionOrderOutput']> = {
  amount?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  orderId?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  orderItems?: Resolver<Array<TResolversTypes['SubscriptionOrderItemOutput']>, ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionOrdersOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionOrdersOutput'] = TResolversParentTypes['SubscriptionOrdersOutput']> = {
  subscriptionOrderOutputs?: Resolver<Array<Maybe<TResolversTypes['SubscriptionOrderOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionOrdersUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionOrdersUnion'] = TResolversParentTypes['SubscriptionOrdersUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'SubscriptionOrdersOutput', ParentType, ContextType>;
};

export type TSubscriptionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionOutput'] = TResolversParentTypes['SubscriptionOutput']> = {
  active?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  addons?: Resolver<Array<TResolversTypes['SubscriptionAddonOutput']>, ParentType, ContextType>;
  billingPeriodEndDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  billingPeriodStartDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  isPendingCancellation?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  isPendingDowngrade?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  latestPaymentAttempt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  nextPaymentAttempt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  paymentAlreadyAttempted?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  paymentAttemptCount?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  paymentStatus?: Resolver<TResolversTypes['SubscriptionPaymentStatusEnum'], ParentType, ContextType>;
  selectedSeats?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  status?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tier?: Resolver<TResolversTypes['SelectedSubscriptionTierOutput'], ParentType, ContextType>;
  totalLimits?: Resolver<Array<TResolversTypes['SubscriptionLimitOutput']>, ParentType, ContextType>;
  totalPrice?: Resolver<TResolversTypes['SubscriptionTotalPriceOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionPriceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionPriceOutput'] = TResolversParentTypes['SubscriptionPriceOutput']> = {
  amountPerUnit?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  billingInterval?: Resolver<TResolversTypes['BillingIntervalEnum'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  subTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionQuotationAddonOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionQuotationAddonOutput'] = TResolversParentTypes['SubscriptionQuotationAddonOutput']> = {
  addonOptionName?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  billingInterval?: Resolver<TResolversTypes['BillingIntervalEnum'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  subTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['SubscriptionAddonTypeEnum'], ParentType, ContextType>;
  value?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionQuotationOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionQuotationOutput'] = TResolversParentTypes['SubscriptionQuotationOutput']> = {
  addons?: Resolver<Array<TResolversTypes['SubscriptionQuotationAddonOutput']>, ParentType, ContextType>;
  billingPeriodEndDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  billingPeriodStartDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  billingPeriodTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  exceededLimits?: Resolver<Array<TResolversTypes['ExceededLimitOutput']>, ParentType, ContextType>;
  immediatePaymentTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  limitsChangeType?: Resolver<TResolversTypes['SubscriptionLimitsChangeTypeEnum'], ParentType, ContextType>;
  nextPaymentTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  prorationDebit?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  prorationRebate?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  subTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  subscriptionPaidUntil?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  tier?: Resolver<TResolversTypes['SubscriptionQuotationTierOutput'], ParentType, ContextType>;
  tierChangeType?: Resolver<TResolversTypes['SubscriptionTierChangeTypeEnum'], ParentType, ContextType>;
  totalLimits?: Resolver<Array<TResolversTypes['SubscriptionLimitOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionQuotationTierOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionQuotationTierOutput'] = TResolversParentTypes['SubscriptionQuotationTierOutput']> = {
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  directUploads?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  limits?: Resolver<Array<TResolversTypes['SubscriptionLimitOutput']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  pricePerSeat?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  selectedSeats?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  subTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionQuotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionQuotationUnion'] = TResolversParentTypes['SubscriptionQuotationUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'SubscriptionQuotationOutput', ParentType, ContextType>;
};

export type TSubscriptionTierAddonOptionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionTierAddonOptionOutput'] = TResolversParentTypes['SubscriptionTierAddonOptionOutput']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  prices?: Resolver<Array<Maybe<TResolversTypes['SubscriptionPriceOutput']>>, ParentType, ContextType>;
  value?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionTierAddonOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionTierAddonOutput'] = TResolversParentTypes['SubscriptionTierAddonOutput']> = {
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  options?: Resolver<Array<TResolversTypes['SubscriptionTierAddonOptionOutput']>, ParentType, ContextType>;
  type?: Resolver<TResolversTypes['SubscriptionAddonTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionTierOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionTierOutput'] = TResolversParentTypes['SubscriptionTierOutput']> = {
  availableAddons?: Resolver<Array<TResolversTypes['SubscriptionTierAddonOutput']>, ParentType, ContextType>;
  directUploads?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  limits?: Resolver<Maybe<Array<Maybe<TResolversTypes['SubscriptionLimitOutput']>>>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  prices?: Resolver<Maybe<Array<Maybe<TResolversTypes['SubscriptionPriceOutput']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionTiersOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionTiersOutput'] = TResolversParentTypes['SubscriptionTiersOutput']> = {
  subscriptionTierOutputs?: Resolver<Array<Maybe<TResolversTypes['SubscriptionTierOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionTiersUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionTiersUnion'] = TResolversParentTypes['SubscriptionTiersUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'SubscriptionTiersOutput', ParentType, ContextType>;
};

export type TSubscriptionTotalPriceOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionTotalPriceOutput'] = TResolversParentTypes['SubscriptionTotalPriceOutput']> = {
  billingInterval?: Resolver<TResolversTypes['BillingIntervalEnum'], ParentType, ContextType>;
  currency?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  subTotal?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  tax?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['BigDecimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionUnion'] = TResolversParentTypes['SubscriptionUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorDuplicateWithSameAddonOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'SubscriptionOutput', ParentType, ContextType>;
};

export type TSubscriptionsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionsOutput'] = TResolversParentTypes['SubscriptionsOutput']> = {
  subscriptionOutputs?: Resolver<Array<Maybe<TResolversTypes['SubscriptionOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSubscriptionsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['SubscriptionsUnion'] = TResolversParentTypes['SubscriptionsUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'SubscriptionsOutput', ParentType, ContextType>;
};

export type TSummaryResolvers<ContextType = any, ParentType extends TResolversParentTypes['Summary'] = TResolversParentTypes['Summary']> = {
  type?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<TResolversTypes['JSON'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSummaryItemGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['SummaryItemGQL'] = TResolversParentTypes['SummaryItemGQL']> = {
  state?: Resolver<TResolversTypes['JobStatus'], ParentType, ContextType>;
  type?: Resolver<TResolversTypes['JobType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSupportedCountryLocalizedNameOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SupportedCountryLocalizedNameOutput'] = TResolversParentTypes['SupportedCountryLocalizedNameOutput']> = {
  languageAlpha2Code?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  languageAlpha3Code?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TSupportedCountryOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['SupportedCountryOutput'] = TResolversParentTypes['SupportedCountryOutput']> = {
  addressesRequireRegion?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  alpha3Code?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  countryIsoCode?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  countryName?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  isEuropeanUnion?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  localizedNames?: Resolver<Array<Maybe<TResolversTypes['SupportedCountryLocalizedNameOutput']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTaskGqlResolvers<ContextType = any, ParentType extends TResolversParentTypes['TaskGQL'] = TResolversParentTypes['TaskGQL']> = {
  duration?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  end?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  start?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  state?: Resolver<TResolversTypes['JobStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTenantDeletionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TenantDeletionOutput'] = TResolversParentTypes['TenantDeletionOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<TResolversTypes['TenantDeletionStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTenantDeletionUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['TenantDeletionUnion'] = TResolversParentTypes['TenantDeletionUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'TenantDeletionOutput', ParentType, ContextType>;
};

export type TTenantOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TenantOutput'] = TResolversParentTypes['TenantOutput']> = {
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<TResolversTypes['SimpleUserProfileOutput']>, ParentType, ContextType>;
  defaultSubscriptionProviderType?: Resolver<Maybe<TResolversTypes['SubscriptionProviderTypeEnum']>, ParentType, ContextType>;
  defaultTenant?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTenantUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['TenantUnion'] = TResolversParentTypes['TenantUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'AcsErrorOperationNotPossibleOutput' | 'AcsErrorTenantNotFoundOutput' | 'TenantOutput', ParentType, ContextType>;
};

export type TTenantsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TenantsOutput'] = TResolversParentTypes['TenantsOutput']> = {
  tenants?: Resolver<Array<TResolversTypes['TenantOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTenantsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['TenantsUnion'] = TResolversParentTypes['TenantsUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'TenantsOutput', ParentType, ContextType>;
};

export type TThumbnailOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['ThumbnailOutput'] = TResolversParentTypes['ThumbnailOutput']> = {
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  uploadUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTokenWalletOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TokenWalletOutput'] = TResolversParentTypes['TokenWalletOutput']> = {
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTokensRefundOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TokensRefundOutput'] = TResolversParentTypes['TokensRefundOutput']> = {
  transactionID?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTokensTransactionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TokensTransactionOutput'] = TResolversParentTypes['TokensTransactionOutput']> = {
  amount?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  balanceAfter?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  description?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  transactionDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  transactionType?: Resolver<TResolversTypes['TokensTransactionActionTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTokensTransactionsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TokensTransactionsOutput'] = TResolversParentTypes['TokensTransactionsOutput']> = {
  tokensTransactions?: Resolver<Array<TResolversTypes['TokensTransactionOutput']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTokensTransactionsUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['TokensTransactionsUnion'] = TResolversParentTypes['TokensTransactionsUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'TokensTransactionsOutput', ParentType, ContextType>;
};

export type TTourResolvers<ContextType = any, ParentType extends TResolversParentTypes['Tour'] = TResolversParentTypes['Tour']> = {
  __resolveType: TypeResolveFn<'GroupedAssetTourOutput', ParentType, ContextType>;
  canEdit?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  createdBy?: Resolver<TResolversTypes['SimpleUserProfileOutput'], ParentType, ContextType>;
  description?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  duration?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  name?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  tension?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  thumbnailPath?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
};

export type TTourAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TourAppliedPageOutput'] = TResolversParentTypes['TourAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTourCameraViewpointOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TourCameraViewpointOutput'] = TResolversParentTypes['TourCameraViewpointOutput']> = {
  eyePosition?: Resolver<TResolversTypes['Vector3Output'], ParentType, ContextType>;
  forward?: Resolver<TResolversTypes['Vector3Output'], ParentType, ContextType>;
  up?: Resolver<TResolversTypes['Vector3Output'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTourFilterAppliedOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TourFilterAppliedOutput'] = TResolversParentTypes['TourFilterAppliedOutput']> = {
  byName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTourKeyframeResolvers<ContextType = any, ParentType extends TResolversParentTypes['TourKeyframe'] = TResolversParentTypes['TourKeyframe']> = {
  __resolveType: TypeResolveFn<'GroupedAssetTourKeyframeOutput', ParentType, ContextType>;
  cameraViewpoint?: Resolver<TResolversTypes['TourCameraViewpointOutput'], ParentType, ContextType>;
  durationFromTourStart?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  tension?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
};

export type TTriggerProcessingOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['TriggerProcessingOutput'] = TResolversParentTypes['TriggerProcessingOutput']> = {
  success?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TTriggerProcessingUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['TriggerProcessingUnion'] = TResolversParentTypes['TriggerProcessingUnion']> = {
  __resolveType: TypeResolveFn<'ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput' | 'ProcessingErrorInternalServerErrorOutput' | 'ProcessingErrorInvalidInputForProcessingPaymentErrorOutput' | 'ProcessingErrorOperationNotAllowedOutput' | 'ProcessingErrorProcessingLimitReachedOutput' | 'ProcessingErrorResourceAccessDeniedOutput' | 'ProcessingErrorResourceNotFoundOutput' | 'ProcessingErrorStorageLimitReachedOutput' | 'TriggerProcessingOutput', ParentType, ContextType>;
};

export type TUnauthenticatedErrorResolvers<ContextType = any, ParentType extends TResolversParentTypes['UnauthenticatedError'] = TResolversParentTypes['UnauthenticatedError']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUnsharedGroupedAssetOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UnsharedGroupedAssetOutput'] = TResolversParentTypes['UnsharedGroupedAssetOutput']> = {
  result?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUnsharedGroupedAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UnsharedGroupedAssetUnion'] = TResolversParentTypes['UnsharedGroupedAssetUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorEntityNotFoundOutput' | 'AcsErrorOperationNotAllowedOutput' | 'AcsErrorOperationNotPossibleOutput' | 'UnsharedGroupedAssetOutput', ParentType, ContextType>;
};

export type TUpdateApplicationTemplateUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateApplicationTemplateUnion'] = TResolversParentTypes['UpdateApplicationTemplateUnion']> = {
  __resolveType: TypeResolveFn<'ApplicationErrorInvalidArgumentOutput' | 'ApplicationErrorOperationNotAllowedOutput' | 'ApplicationErrorResourceAccessDeniedOutput' | 'ApplicationErrorTooManyRequestsOutput' | 'ApplicationTemplateOutput', ParentType, ContextType>;
};

export type TUpdateAssetUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateAssetUnion'] = TResolversParentTypes['UpdateAssetUnion']> = {
  __resolveType: TypeResolveFn<'AssetErrorDuplicateNameOutput' | 'AssetErrorNameBlacklistedCharactersOutput' | 'AssetErrorNameBlankOutput' | 'AssetErrorNameForbiddenOutput' | 'AssetErrorNameForwardSlashOutput' | 'AssetErrorNameLeadingOrTrailingSpaceOutput' | 'AssetErrorNameTrailingDotOutput' | 'AssetErrorNameTwoDotsInRowOutput' | 'AssetErrorOperationNotAllowedOutput' | 'AssetErrorResourceAccessDeniedOutput' | 'AssetErrorTooManyRequestsOutput' | 'GroupedAssetOutput', ParentType, ContextType>;
};

export type TUpdateFolderUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateFolderUnion'] = TResolversParentTypes['UpdateFolderUnion']> = {
  __resolveType: TypeResolveFn<'FolderErrorDuplicateNameOutput' | 'FolderErrorNameBlacklistedCharactersOutput' | 'FolderErrorNameBlankOutput' | 'FolderErrorNameForbiddenOutput' | 'FolderErrorNameForwardSlashOutput' | 'FolderErrorNameLeadingOrTrailingSpaceOutput' | 'FolderErrorNameTrailingDotOutput' | 'FolderErrorNameTwoDotsInRowOutput' | 'FolderErrorOperationNotAllowedOutput' | 'FolderErrorResourceAccessDeniedOutput' | 'FolderErrorTooManyRequestsOutput' | 'FolderOutput', ParentType, ContextType>;
};

export type TUpdateLabelAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateLabelAnnotationUnion'] = TResolversParentTypes['UpdateLabelAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'LabelAnnotationOutput', ParentType, ContextType>;
};

export type TUpdateLimitingBoxAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateLimitingBoxAnnotationUnion'] = TResolversParentTypes['UpdateLimitingBoxAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'LimitingBoxAnnotationOutput', ParentType, ContextType>;
};

export type TUpdateMeasurementAnnotationUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateMeasurementAnnotationUnion'] = TResolversParentTypes['UpdateMeasurementAnnotationUnion']> = {
  __resolveType: TypeResolveFn<'AnnotationErrorOperationNotAllowedOutput' | 'MeasurementAnnotationOutput', ParentType, ContextType>;
};

export type TUpdateProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UpdateProjectUnion'] = TResolversParentTypes['UpdateProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorDuplicateNameOutput' | 'ProjectErrorInvalidInputOutput' | 'ProjectErrorNameBlacklistedCharactersOutput' | 'ProjectErrorNameBlankOutput' | 'ProjectErrorNameForbiddenOutput' | 'ProjectErrorNameForwardSlashOutput' | 'ProjectErrorNameLeadingOrTrailingSpaceOutput' | 'ProjectErrorNameTrailingDotOutput' | 'ProjectErrorNameTwoDotsInRowOutput' | 'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'ProjectOutput', ParentType, ContextType>;
};

export type TUploadThumbnailToProjectUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UploadThumbnailToProjectUnion'] = TResolversParentTypes['UploadThumbnailToProjectUnion']> = {
  __resolveType: TypeResolveFn<'ProjectErrorOperationNotAllowedOutput' | 'ProjectErrorResourceAccessDeniedOutput' | 'ProjectErrorTooManyRequestsOutput' | 'ProjectThumbnailOutput', ParentType, ContextType>;
};

export type TUsageAccountUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UsageAccountUnion'] = TResolversParentTypes['UsageAccountUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorOperationNotAllowedOutput' | 'UsageOutput', ParentType, ContextType>;
};

export type TUsageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UsageOutput'] = TResolversParentTypes['UsageOutput']> = {
  accountID?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  processing?: Resolver<Array<Maybe<TResolversTypes['ProcessingUsageOutput']>>, ParentType, ContextType, Partial<TUsageOutputProcessingArgs>>;
  projects?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  seats?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  storageArtifacts?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  storageAssets?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  streaming?: Resolver<TResolversTypes['StreamingUsageOutput'], ParentType, ContextType, Partial<TUsageOutputStreamingArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUsageTenantOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UsageTenantOutput'] = TResolversParentTypes['UsageTenantOutput']> = {
  processing?: Resolver<Array<Maybe<TResolversTypes['ProcessingUsageOutput']>>, ParentType, ContextType, Partial<TUsageTenantOutputProcessingArgs>>;
  projects?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  seats?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  storageArtifacts?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  storageAssets?: Resolver<TResolversTypes['BigInteger'], ParentType, ContextType>;
  streaming?: Resolver<TResolversTypes['StreamingUsageOutput'], ParentType, ContextType, Partial<TUsageTenantOutputStreamingArgs>>;
  tenantID?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUsageTenantUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UsageTenantUnion'] = TResolversParentTypes['UsageTenantUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorOperationNotAllowedOutput' | 'UsageTenantOutput', ParentType, ContextType>;
};

export type TUserDeletionOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserDeletionOutput'] = TResolversParentTypes['UserDeletionOutput']> = {
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<TResolversTypes['UserDeletionStatusGQL'], ParentType, ContextType>;
  user?: Resolver<TResolversTypes['UserProfileOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUserDeletionUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserDeletionUnion'] = TResolversParentTypes['UserDeletionUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'AcsErrorUserNotFoundOutput' | 'UserDeletionOutput', ParentType, ContextType>;
};

export type TUserFlagOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserFlagOutput'] = TResolversParentTypes['UserFlagOutput']> = {
  userFlag?: Resolver<Maybe<TResolversTypes['UserFlagEnum']>, ParentType, ContextType>;
  valid?: Resolver<Maybe<TResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUserProfileAppliedPageOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserProfileAppliedPageOutput'] = TResolversParentTypes['UserProfileAppliedPageOutput']> = {
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUserProfileOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserProfileOutput'] = TResolversParentTypes['UserProfileOutput']> = {
  accountRole?: Resolver<Maybe<TResolversTypes['AccountRoleEnum']>, ParentType, ContextType>;
  applicationRoles?: Resolver<Array<TResolversTypes['String']>, ParentType, ContextType>;
  companyInfo?: Resolver<Maybe<TResolversTypes['AccountInfoOutput']>, ParentType, ContextType>;
  email?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  lastLoginAt?: Resolver<Maybe<TResolversTypes['DateTime']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  modifiedAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  profilePictureUrl?: Resolver<Maybe<TResolversTypes['String']>, ParentType, ContextType>;
  signedUpAt?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  tenantRole?: Resolver<Maybe<TResolversTypes['TenantRoleEnum']>, ParentType, ContextType>;
  userFlags?: Resolver<Array<Maybe<TResolversTypes['UserFlagOutput']>>, ParentType, ContextType>;
  userSettings?: Resolver<TResolversTypes['UserSettingsOutput'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUserProfileResultOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserProfileResultOutput'] = TResolversParentTypes['UserProfileResultOutput']> = {
  appliedFilters?: Resolver<Maybe<TResolversTypes['FilterUserProfileAppliedOutput']>, ParentType, ContextType>;
  appliedPagination?: Resolver<Maybe<TResolversTypes['UserProfileAppliedPageOutput']>, ParentType, ContextType>;
  contents?: Resolver<Array<Maybe<TResolversTypes['UserProfileOutput']>>, ParentType, ContextType>;
  orderBy?: Resolver<Maybe<TResolversTypes['UserProfileOrderEnum']>, ParentType, ContextType>;
  pageNumber?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  pageSize?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<TResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUserProjectRoleOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserProjectRoleOutput'] = TResolversParentTypes['UserProjectRoleOutput']> = {
  userId?: Resolver<TResolversTypes['ID'], ParentType, ContextType>;
  userRole?: Resolver<TResolversTypes['ProjectRoleEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TUserProjectRoleUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserProjectRoleUnion'] = TResolversParentTypes['UserProjectRoleUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'AcsErrorProjectNotFoundOutput' | 'ListOfUserProjectRoleOutput', ParentType, ContextType>;
};

export type TUserSettingsOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['UserSettingsOutput'] = TResolversParentTypes['UserSettingsOutput']> = {
  language?: Resolver<Maybe<TResolversTypes['UserSettingsLanguageEnum']>, ParentType, ContextType>;
  listLayout?: Resolver<Maybe<TResolversTypes['UserSettingsListLayoutEnum']>, ParentType, ContextType>;
  measurementUnit?: Resolver<Maybe<TResolversTypes['UserSettingsMeasurementEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TValidationErrorResolvers<ContextType = any, ParentType extends TResolversParentTypes['ValidationError'] = TResolversParentTypes['ValidationError']> = {
  issues?: Resolver<Array<TResolversTypes['ValidationErrorIssue']>, ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TValidationErrorIssueResolvers<ContextType = any, ParentType extends TResolversParentTypes['ValidationErrorIssue'] = TResolversParentTypes['ValidationErrorIssue']> = {
  code?: Resolver<TResolversTypes['ValidationErrorIssueCode'], ParentType, ContextType>;
  message?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TVector3OutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['Vector3Output'] = TResolversParentTypes['Vector3Output']> = {
  x?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  y?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  z?: Resolver<TResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TVoucherOutputResolvers<ContextType = any, ParentType extends TResolversParentTypes['VoucherOutput'] = TResolversParentTypes['VoucherOutput']> = {
  appliedLimits?: Resolver<Array<Maybe<TResolversTypes['LimitToApplyOutput']>>, ParentType, ContextType>;
  expirationDate?: Resolver<TResolversTypes['DateTime'], ParentType, ContextType>;
  reusable?: Resolver<TResolversTypes['Boolean'], ParentType, ContextType>;
  signedUrl?: Resolver<TResolversTypes['String'], ParentType, ContextType>;
  voucherType?: Resolver<TResolversTypes['VoucherTypeEnum'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TVoucherUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['VoucherUnion'] = TResolversParentTypes['VoucherUnion']> = {
  __resolveType: TypeResolveFn<'AcsErrorOperationNotAllowedOutput' | 'AcsErrorSeatLimitReachedOutput' | 'AcsErrorUserAlreadyExistsInAccountOutput' | 'AcsErrorUserAlreadyExistsInOtherAccountOutput' | 'VoucherOutput', ParentType, ContextType>;
};

export type TWalletRefundUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['WalletRefundUnion'] = TResolversParentTypes['WalletRefundUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorBadRequestOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'TokensRefundOutput', ParentType, ContextType>;
};

export type TWalletUnionResolvers<ContextType = any, ParentType extends TResolversParentTypes['WalletUnion'] = TResolversParentTypes['WalletUnion']> = {
  __resolveType: TypeResolveFn<'SubscriptionErrorAccountNotSyncedOutput' | 'SubscriptionErrorFeatureNotAvailableOutput' | 'SubscriptionErrorOperationNotAllowedOutput' | 'SubscriptionNoValidIsoCountryFoundOutput' | 'TokenWalletOutput', ParentType, ContextType>;
};

export interface T_AnyScalarConfig extends GraphQLScalarTypeConfig<TResolversTypes['_Any'], any> {
  name: '_Any';
}

export type T_EntityResolvers<ContextType = any, ParentType extends TResolversParentTypes['_Entity'] = TResolversParentTypes['_Entity']> = {
  __resolveType: TypeResolveFn<'AccountInfoOutput' | 'AccountUsageOutput' | 'AddressDownloadOutput' | 'AddressHspcOutput' | 'AddressLtsOutput' | 'AddressOgc3DOutput' | 'AddressPageOutput' | 'AddressPanoramicOutput' | 'ApplicationTemplateOutput' | 'AssetOutput' | 'FileOutput' | 'FolderOutput' | 'GeoreferenceArtifactResultOutput' | 'GroupedAssetOutput' | 'JobPage' | 'JobSummaryGQL' | 'LabelAnnotationOutput' | 'LimitingBoxAnnotationOutput' | 'LuciadRIALicenseOutput' | 'MeasurementAnnotationOutput' | 'OrderGQL' | 'OrderItemGQL' | 'ProjectMemberOutput' | 'ProjectOutput' | 'SearchOutput' | 'SimpleAccountInfoOutput' | 'SimpleUserProfileOutput' | 'UsageOutput' | 'UsageTenantOutput' | 'UserProfileOutput', ParentType, ContextType>;
};

export type TResolvers<ContextType = any> = {
  AccessDeniedError?: TAccessDeniedErrorResolvers<ContextType>;
  AccountAddressOutput?: TAccountAddressOutputResolvers<ContextType>;
  AccountDetailOutput?: TAccountDetailOutputResolvers<ContextType>;
  AccountDetailUnion?: TAccountDetailUnionResolvers<ContextType>;
  AccountInfoAccountAddressIsNotValidOutput?: TAccountInfoAccountAddressIsNotValidOutputResolvers<ContextType>;
  AccountInfoAccountCompanyNameIsNotValidOutput?: TAccountInfoAccountCompanyNameIsNotValidOutputResolvers<ContextType>;
  AccountInfoAccountEmailIsNotValidOutput?: TAccountInfoAccountEmailIsNotValidOutputResolvers<ContextType>;
  AccountInfoAccountLegalEntityCouldNotBeChangedOutput?: TAccountInfoAccountLegalEntityCouldNotBeChangedOutputResolvers<ContextType>;
  AccountInfoErrorOperationNotAllowedOutput?: TAccountInfoErrorOperationNotAllowedOutputResolvers<ContextType>;
  AccountInfoOutput?: TAccountInfoOutputResolvers<ContextType>;
  AccountInfoUpdateUnion?: TAccountInfoUpdateUnionResolvers<ContextType>;
  AccountMembersPageOutput?: TAccountMembersPageOutputResolvers<ContextType>;
  AccountMembersResultOutput?: TAccountMembersResultOutputResolvers<ContextType>;
  AccountMembersResultUnion?: TAccountMembersResultUnionResolvers<ContextType>;
  AccountProfileAppliedPageOutput?: TAccountProfileAppliedPageOutputResolvers<ContextType>;
  AccountProfileResultOutput?: TAccountProfileResultOutputResolvers<ContextType>;
  AccountUsageOutput?: TAccountUsageOutputResolvers<ContextType>;
  AcsErrorEntityNotFoundOutput?: TAcsErrorEntityNotFoundOutputResolvers<ContextType>;
  AcsErrorInvalidInvitationSignatureOutput?: TAcsErrorInvalidInvitationSignatureOutputResolvers<ContextType>;
  AcsErrorInvalidVoucherOutput?: TAcsErrorInvalidVoucherOutputResolvers<ContextType>;
  AcsErrorOperationNotAllowedOutput?: TAcsErrorOperationNotAllowedOutputResolvers<ContextType>;
  AcsErrorOperationNotPossibleOutput?: TAcsErrorOperationNotPossibleOutputResolvers<ContextType>;
  AcsErrorProjectNotFoundOutput?: TAcsErrorProjectNotFoundOutputResolvers<ContextType>;
  AcsErrorSeatLimitReachedOutput?: TAcsErrorSeatLimitReachedOutputResolvers<ContextType>;
  AcsErrorTenantNotFoundOutput?: TAcsErrorTenantNotFoundOutputResolvers<ContextType>;
  AcsErrorTooManyRequestsOutput?: TAcsErrorTooManyRequestsOutputResolvers<ContextType>;
  AcsErrorUserAlreadyExistsInAccountOutput?: TAcsErrorUserAlreadyExistsInAccountOutputResolvers<ContextType>;
  AcsErrorUserAlreadyExistsInOtherAccountOutput?: TAcsErrorUserAlreadyExistsInOtherAccountOutputResolvers<ContextType>;
  AcsErrorUserAlreadyExistsOutput?: TAcsErrorUserAlreadyExistsOutputResolvers<ContextType>;
  AcsErrorUserNotFoundOutput?: TAcsErrorUserNotFoundOutputResolvers<ContextType>;
  AcsErrorVoucherAlreadyUsedOutput?: TAcsErrorVoucherAlreadyUsedOutputResolvers<ContextType>;
  AddFileUnion?: TAddFileUnionResolvers<ContextType>;
  AddLabelsUnion?: TAddLabelsUnionResolvers<ContextType>;
  AddressAssetFileDownloadOutput?: TAddressAssetFileDownloadOutputResolvers<ContextType>;
  AddressDownloadOutput?: TAddressDownloadOutputResolvers<ContextType>;
  AddressHspcOutput?: TAddressHspcOutputResolvers<ContextType>;
  AddressLtsOutput?: TAddressLtsOutputResolvers<ContextType>;
  AddressOgc3DOutput?: TAddressOgc3DOutputResolvers<ContextType>;
  AddressOutput?: TAddressOutputResolvers<ContextType>;
  AddressPageOutput?: TAddressPageOutputResolvers<ContextType>;
  AddressPanoramicOutput?: TAddressPanoramicOutputResolvers<ContextType>;
  AddressValidOutput?: TAddressValidOutputResolvers<ContextType>;
  AddressWfsOutput?: TAddressWfsOutputResolvers<ContextType>;
  AddressWmsOutput?: TAddressWmsOutputResolvers<ContextType>;
  AddressWmtsOutput?: TAddressWmtsOutputResolvers<ContextType>;
  AnnotationErrorOperationNotAllowedOutput?: TAnnotationErrorOperationNotAllowedOutputResolvers<ContextType>;
  AnnotationOrientationOutput?: TAnnotationOrientationOutputResolvers<ContextType>;
  AnnotationPointOutput?: TAnnotationPointOutputResolvers<ContextType>;
  AnnotationRotationOutput?: TAnnotationRotationOutputResolvers<ContextType>;
  AnnotationThumbnailOutput?: TAnnotationThumbnailOutputResolvers<ContextType>;
  AnnotationThumbnailUnion?: TAnnotationThumbnailUnionResolvers<ContextType>;
  AppClientOutput?: TAppClientOutputResolvers<ContextType>;
  ApplicationErrorInvalidArgumentOutput?: TApplicationErrorInvalidArgumentOutputResolvers<ContextType>;
  ApplicationErrorOperationNotAllowedOutput?: TApplicationErrorOperationNotAllowedOutputResolvers<ContextType>;
  ApplicationErrorResourceAccessDeniedOutput?: TApplicationErrorResourceAccessDeniedOutputResolvers<ContextType>;
  ApplicationErrorTooManyRequestsOutput?: TApplicationErrorTooManyRequestsOutputResolvers<ContextType>;
  ApplicationTemplateAppliedPageOutput?: TApplicationTemplateAppliedPageOutputResolvers<ContextType>;
  ApplicationTemplateOutput?: TApplicationTemplateOutputResolvers<ContextType>;
  ApplicationTemplateResultOutput?: TApplicationTemplateResultOutputResolvers<ContextType>;
  ApplicationTemplateUnion?: TApplicationTemplateUnionResolvers<ContextType>;
  ApplicationTemplatesUnion?: TApplicationTemplatesUnionResolvers<ContextType>;
  ArtifactAppliedPageOutput?: TArtifactAppliedPageOutputResolvers<ContextType>;
  ArtifactItemOutput?: TArtifactItemOutputResolvers<ContextType>;
  ArtifactResultOutput?: TArtifactResultOutputResolvers<ContextType>;
  ArtifactResultOutputV2?: TArtifactResultOutputV2Resolvers<ContextType>;
  AssetAppliedPageOutput?: TAssetAppliedPageOutputResolvers<ContextType>;
  AssetBBoxOutput?: TAssetBBoxOutputResolvers<ContextType>;
  AssetCountOutput?: TAssetCountOutputResolvers<ContextType>;
  AssetErrorCoarseRegistrationInputNotFound?: TAssetErrorCoarseRegistrationInputNotFoundResolvers<ContextType>;
  AssetErrorCoarseRegistrationLimitOutput?: TAssetErrorCoarseRegistrationLimitOutputResolvers<ContextType>;
  AssetErrorDownloadLimitReachedOutput?: TAssetErrorDownloadLimitReachedOutputResolvers<ContextType>;
  AssetErrorDuplicateNameOutput?: TAssetErrorDuplicateNameOutputResolvers<ContextType>;
  AssetErrorFeatureNotAvailableOutput?: TAssetErrorFeatureNotAvailableOutputResolvers<ContextType>;
  AssetErrorGroupedAssetRegistrationLimitOutput?: TAssetErrorGroupedAssetRegistrationLimitOutputResolvers<ContextType>;
  AssetErrorGroupedAssetsNotUnderSameFolderOutput?: TAssetErrorGroupedAssetsNotUnderSameFolderOutputResolvers<ContextType>;
  AssetErrorNameBlacklistedCharactersOutput?: TAssetErrorNameBlacklistedCharactersOutputResolvers<ContextType>;
  AssetErrorNameBlankOutput?: TAssetErrorNameBlankOutputResolvers<ContextType>;
  AssetErrorNameForbiddenOutput?: TAssetErrorNameForbiddenOutputResolvers<ContextType>;
  AssetErrorNameForwardSlashOutput?: TAssetErrorNameForwardSlashOutputResolvers<ContextType>;
  AssetErrorNameLeadingOrTrailingSpaceOutput?: TAssetErrorNameLeadingOrTrailingSpaceOutputResolvers<ContextType>;
  AssetErrorNameTrailingDotOutput?: TAssetErrorNameTrailingDotOutputResolvers<ContextType>;
  AssetErrorNameTwoDotsInRowOutput?: TAssetErrorNameTwoDotsInRowOutputResolvers<ContextType>;
  AssetErrorOperationNotAllowedOutput?: TAssetErrorOperationNotAllowedOutputResolvers<ContextType>;
  AssetErrorProcessingLimitReachedOutput?: TAssetErrorProcessingLimitReachedOutputResolvers<ContextType>;
  AssetErrorResourceAccessDeniedOutput?: TAssetErrorResourceAccessDeniedOutputResolvers<ContextType>;
  AssetErrorStorageLimitReachedOutput?: TAssetErrorStorageLimitReachedOutputResolvers<ContextType>;
  AssetErrorTooManyRequestsOutput?: TAssetErrorTooManyRequestsOutputResolvers<ContextType>;
  AssetOutput?: TAssetOutputResolvers<ContextType>;
  AssetThumbnailOutput?: TAssetThumbnailOutputResolvers<ContextType>;
  AssetUnion?: TAssetUnionResolvers<ContextType>;
  AssetsInAccountUnion?: TAssetsInAccountUnionResolvers<ContextType>;
  AssetsInFolderUnion?: TAssetsInFolderUnionResolvers<ContextType>;
  AutoRegisterAssetsUnion?: TAutoRegisterAssetsUnionResolvers<ContextType>;
  BaseAccountInfoErrorInterface?: TBaseAccountInfoErrorInterfaceResolvers<ContextType>;
  BaseAcsErrorInterface?: TBaseAcsErrorInterfaceResolvers<ContextType>;
  BaseAnnotationErrorInterface?: TBaseAnnotationErrorInterfaceResolvers<ContextType>;
  BaseApplicationErrorInterface?: TBaseApplicationErrorInterfaceResolvers<ContextType>;
  BaseAssetErrorInterface?: TBaseAssetErrorInterfaceResolvers<ContextType>;
  BaseFolderErrorInterface?: TBaseFolderErrorInterfaceResolvers<ContextType>;
  BaseLabelErrorInterface?: TBaseLabelErrorInterfaceResolvers<ContextType>;
  BaseLicenseErrorInterface?: TBaseLicenseErrorInterfaceResolvers<ContextType>;
  BaseProcessingErrorInterface?: TBaseProcessingErrorInterfaceResolvers<ContextType>;
  BaseProjectErrorInterface?: TBaseProjectErrorInterfaceResolvers<ContextType>;
  BaseQuotationErrorInterface?: TBaseQuotationErrorInterfaceResolvers<ContextType>;
  BaseShopErrorInterface?: TBaseShopErrorInterfaceResolvers<ContextType>;
  BaseSubscriptionErrorInterface?: TBaseSubscriptionErrorInterfaceResolvers<ContextType>;
  BaselayerAppliedPageOutput?: TBaselayerAppliedPageOutputResolvers<ContextType>;
  BaselayerHspcOutput?: TBaselayerHspcOutputResolvers<ContextType>;
  BaselayerLtsOutput?: TBaselayerLtsOutputResolvers<ContextType>;
  BaselayerOgc3DOutput?: TBaselayerOgc3DOutputResolvers<ContextType>;
  BaselayerOutput?: TBaselayerOutputResolvers<ContextType>;
  BaselayerPanoramicOutput?: TBaselayerPanoramicOutputResolvers<ContextType>;
  BaselayerResultOutput?: TBaselayerResultOutputResolvers<ContextType>;
  BaselayerUrlOutput?: TBaselayerUrlOutputResolvers<ContextType>;
  BaselayerWfsOutput?: TBaselayerWfsOutputResolvers<ContextType>;
  BaselayerWmsOutput?: TBaselayerWmsOutputResolvers<ContextType>;
  BaselayerWmtsOutput?: TBaselayerWmtsOutputResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInteger?: GraphQLScalarType;
  CancelCurrentSubscriptionOutput?: TCancelCurrentSubscriptionOutputResolvers<ContextType>;
  CancelCurrentSubscriptionUnion?: TCancelCurrentSubscriptionUnionResolvers<ContextType>;
  CartItemOutput?: TCartItemOutputResolvers<ContextType>;
  CartItemThumbnailOutput?: TCartItemThumbnailOutputResolvers<ContextType>;
  CartOutput?: TCartOutputResolvers<ContextType>;
  CartesianBoundsOutput?: TCartesianBoundsOutputResolvers<ContextType>;
  ChangeProjectRoleOutput?: TChangeProjectRoleOutputResolvers<ContextType>;
  ChangeProjectRoleUnion?: TChangeProjectRoleUnionResolvers<ContextType>;
  CheckoutOutput?: TCheckoutOutputResolvers<ContextType>;
  Collection?: TCollectionResolvers<ContextType>;
  Comment?: TCommentResolvers<ContextType>;
  CommentAnchor?: TCommentAnchorResolvers<ContextType>;
  CommentConnection?: TCommentConnectionResolvers<ContextType>;
  CommentEdge?: TCommentEdgeResolvers<ContextType>;
  CommentPayload?: TCommentPayloadResolvers<ContextType>;
  CommentsPayload?: TCommentsPayloadResolvers<ContextType>;
  ConfirmSubscriptionOutput?: TConfirmSubscriptionOutputResolvers<ContextType>;
  ConfirmSubscriptionUnion?: TConfirmSubscriptionUnionResolvers<ContextType>;
  Connection?: TConnectionResolvers<ContextType>;
  CoordinateSystemOutput?: TCoordinateSystemOutputResolvers<ContextType>;
  CopyAssetToFolderUnion?: TCopyAssetToFolderUnionResolvers<ContextType>;
  CoreBaselayer?: TCoreBaselayerResolvers<ContextType>;
  CoreLayer?: TCoreLayerResolvers<ContextType>;
  CountryOutput?: TCountryOutputResolvers<ContextType>;
  CreateApplicationTemplateUnion?: TCreateApplicationTemplateUnionResolvers<ContextType>;
  CreateAssetThumbnailUnion?: TCreateAssetThumbnailUnionResolvers<ContextType>;
  CreateAssetUnion?: TCreateAssetUnionResolvers<ContextType>;
  CreateFolderUnion?: TCreateFolderUnionResolvers<ContextType>;
  CreateLabelAnnotationUnion?: TCreateLabelAnnotationUnionResolvers<ContextType>;
  CreateLimitingBoxAnnotationUnion?: TCreateLimitingBoxAnnotationUnionResolvers<ContextType>;
  CreateMeasurementAnnotationUnion?: TCreateMeasurementAnnotationUnionResolvers<ContextType>;
  CreateProjectUnion?: TCreateProjectUnionResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  DeleteAnnotationOutput?: TDeleteAnnotationOutputResolvers<ContextType>;
  DeleteAnnotationUnion?: TDeleteAnnotationUnionResolvers<ContextType>;
  DeleteApplicationTemplateOutput?: TDeleteApplicationTemplateOutputResolvers<ContextType>;
  DeleteApplicationTemplateUnion?: TDeleteApplicationTemplateUnionResolvers<ContextType>;
  DeleteAssetOutput?: TDeleteAssetOutputResolvers<ContextType>;
  DeleteAssetThumbnailOutput?: TDeleteAssetThumbnailOutputResolvers<ContextType>;
  DeleteAssetThumbnailUnion?: TDeleteAssetThumbnailUnionResolvers<ContextType>;
  DeleteAssetUnion?: TDeleteAssetUnionResolvers<ContextType>;
  DeleteFolderOutput?: TDeleteFolderOutputResolvers<ContextType>;
  DeleteFolderUnion?: TDeleteFolderUnionResolvers<ContextType>;
  DeleteProjectOutput?: TDeleteProjectOutputResolvers<ContextType>;
  DeleteProjectThumbnailOutput?: TDeleteProjectThumbnailOutputResolvers<ContextType>;
  DeleteProjectThumbnailUnion?: TDeleteProjectThumbnailUnionResolvers<ContextType>;
  DeleteProjectUnion?: TDeleteProjectUnionResolvers<ContextType>;
  DeletedPaymentCardOutput?: TDeletedPaymentCardOutputResolvers<ContextType>;
  DeviceOutput?: TDeviceOutputResolvers<ContextType>;
  DeviceResultOutput?: TDeviceResultOutputResolvers<ContextType>;
  Edge?: TEdgeResolvers<ContextType>;
  EntityReferenceOutput?: TEntityReferenceOutputResolvers<ContextType>;
  Error?: TErrorResolvers<ContextType>;
  ExceededLimitOutput?: TExceededLimitOutputResolvers<ContextType>;
  ExecutedOutput?: TExecutedOutputResolvers<ContextType>;
  Extent?: TExtentResolvers<ContextType>;
  ExternalAccountMembersResultUnion?: TExternalAccountMembersResultUnionResolvers<ContextType>;
  Feature?: TFeatureResolvers<ContextType>;
  FeatureAsset?: TFeatureAssetResolvers<ContextType>;
  FeatureAssets?: TFeatureAssetsResolvers<ContextType>;
  FeatureItem?: TFeatureItemResolvers<ContextType>;
  FeatureProperties?: TFeaturePropertiesResolvers<ContextType>;
  FileAppliedPageOutput?: TFileAppliedPageOutputResolvers<ContextType>;
  FileFilterAppliedOutput?: TFileFilterAppliedOutputResolvers<ContextType>;
  FileOutput?: TFileOutputResolvers<ContextType>;
  FileResultOutput?: TFileResultOutputResolvers<ContextType>;
  FileStatusOutput?: TFileStatusOutputResolvers<ContextType>;
  FilterAccountMembersOutput?: TFilterAccountMembersOutputResolvers<ContextType>;
  FilterAccountProfileAppliedOutput?: TFilterAccountProfileAppliedOutputResolvers<ContextType>;
  FilterApplicationAppliedOutput?: TFilterApplicationAppliedOutputResolvers<ContextType>;
  FilterAssetAppliedOutput?: TFilterAssetAppliedOutputResolvers<ContextType>;
  FilterAssetNotOutput?: TFilterAssetNotOutputResolvers<ContextType>;
  FilterAssetOrOutput?: TFilterAssetOrOutputResolvers<ContextType>;
  FilterFolderAppliedOutput?: TFilterFolderAppliedOutputResolvers<ContextType>;
  FilterProjectAppliedOutput?: TFilterProjectAppliedOutputResolvers<ContextType>;
  FilterUserProfileAppliedOutput?: TFilterUserProfileAppliedOutputResolvers<ContextType>;
  FolderAppliedPageOutput?: TFolderAppliedPageOutputResolvers<ContextType>;
  FolderErrorDuplicateNameOutput?: TFolderErrorDuplicateNameOutputResolvers<ContextType>;
  FolderErrorNameBlacklistedCharactersOutput?: TFolderErrorNameBlacklistedCharactersOutputResolvers<ContextType>;
  FolderErrorNameBlankOutput?: TFolderErrorNameBlankOutputResolvers<ContextType>;
  FolderErrorNameForbiddenOutput?: TFolderErrorNameForbiddenOutputResolvers<ContextType>;
  FolderErrorNameForwardSlashOutput?: TFolderErrorNameForwardSlashOutputResolvers<ContextType>;
  FolderErrorNameLeadingOrTrailingSpaceOutput?: TFolderErrorNameLeadingOrTrailingSpaceOutputResolvers<ContextType>;
  FolderErrorNameTrailingDotOutput?: TFolderErrorNameTrailingDotOutputResolvers<ContextType>;
  FolderErrorNameTwoDotsInRowOutput?: TFolderErrorNameTwoDotsInRowOutputResolvers<ContextType>;
  FolderErrorOperationNotAllowedOutput?: TFolderErrorOperationNotAllowedOutputResolvers<ContextType>;
  FolderErrorResourceAccessDeniedOutput?: TFolderErrorResourceAccessDeniedOutputResolvers<ContextType>;
  FolderErrorResourceNotFoundOutput?: TFolderErrorResourceNotFoundOutputResolvers<ContextType>;
  FolderErrorTooManyRequestsOutput?: TFolderErrorTooManyRequestsOutputResolvers<ContextType>;
  FolderOutput?: TFolderOutputResolvers<ContextType>;
  FolderResultOutput?: TFolderResultOutputResolvers<ContextType>;
  FolderUnion?: TFolderUnionResolvers<ContextType>;
  GatewayInfoOutput?: TGatewayInfoOutputResolvers<ContextType>;
  GenerateInvitationUnion?: TGenerateInvitationUnionResolvers<ContextType>;
  GenerateMultiAssetDownloadLinkUnion?: TGenerateMultiAssetDownloadLinkUnionResolvers<ContextType>;
  GeoJSONShape?: TGeoJsonShapeResolvers<ContextType>;
  GeoreferenceAppliedPageOutput?: TGeoreferenceAppliedPageOutputResolvers<ContextType>;
  GeoreferenceAppliedPaginationOutput?: TGeoreferenceAppliedPaginationOutputResolvers<ContextType>;
  GeoreferenceArtifactResultOutput?: TGeoreferenceArtifactResultOutputResolvers<ContextType>;
  GeoreferenceOutput?: TGeoreferenceOutputResolvers<ContextType>;
  GeoreferenceResultsOutput?: TGeoreferenceResultsOutputResolvers<ContextType>;
  GeoreferenceVisibilityOutput?: TGeoreferenceVisibilityOutputResolvers<ContextType>;
  GroupedAssetGeoreferenceOutput?: TGroupedAssetGeoreferenceOutputResolvers<ContextType>;
  GroupedAssetGeoreferenceResultsOutput?: TGroupedAssetGeoreferenceResultsOutputResolvers<ContextType>;
  GroupedAssetOutput?: TGroupedAssetOutputResolvers<ContextType>;
  GroupedAssetPropertiesOutput?: TGroupedAssetPropertiesOutputResolvers<ContextType>;
  GroupedAssetResultOutput?: TGroupedAssetResultOutputResolvers<ContextType>;
  GroupedAssetTourKeyframeOutput?: TGroupedAssetTourKeyframeOutputResolvers<ContextType>;
  GroupedAssetTourOutput?: TGroupedAssetTourOutputResolvers<ContextType>;
  GroupedAssetTourResultOutput?: TGroupedAssetTourResultOutputResolvers<ContextType>;
  HXDRProductOutput?: THxdrProductOutputResolvers<ContextType>;
  InfoGQL?: TInfoGqlResolvers<ContextType>;
  InfoPage?: TInfoPageResolvers<ContextType>;
  InvitationOutput?: TInvitationOutputResolvers<ContextType>;
  ItemCollection?: TItemCollectionResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JobPage?: TJobPageResolvers<ContextType>;
  JobSummaryGQL?: TJobSummaryGqlResolvers<ContextType>;
  LabelAnnotationDataOutput?: TLabelAnnotationDataOutputResolvers<ContextType>;
  LabelAnnotationOutput?: TLabelAnnotationOutputResolvers<ContextType>;
  LabelErrorOperationNotAllowedOutput?: TLabelErrorOperationNotAllowedOutputResolvers<ContextType>;
  LabelErrorTooManyLabelsOutput?: TLabelErrorTooManyLabelsOutputResolvers<ContextType>;
  LabelOutput?: TLabelOutputResolvers<ContextType>;
  LabelsOutput?: TLabelsOutputResolvers<ContextType>;
  LabelsResultOutput?: TLabelsResultOutputResolvers<ContextType>;
  LayerAddress?: TLayerAddressResolvers<ContextType>;
  LayerAddressHspc?: TLayerAddressHspcResolvers<ContextType>;
  LayerAddressLts?: TLayerAddressLtsResolvers<ContextType>;
  LayerAddressOgc3DTiles?: TLayerAddressOgc3DTilesResolvers<ContextType>;
  LayerAddressWms?: TLayerAddressWmsResolvers<ContextType>;
  LayerAddressWmts?: TLayerAddressWmtsResolvers<ContextType>;
  LicenseErrorInvalidArgumentOutput?: TLicenseErrorInvalidArgumentOutputResolvers<ContextType>;
  LicenseErrorLicenseNotAvailableOutput?: TLicenseErrorLicenseNotAvailableOutputResolvers<ContextType>;
  LicenseErrorOperationNotAllowedOutput?: TLicenseErrorOperationNotAllowedOutputResolvers<ContextType>;
  LicenseErrorTooManyRequestsOutput?: TLicenseErrorTooManyRequestsOutputResolvers<ContextType>;
  LimitToApplyOutput?: TLimitToApplyOutputResolvers<ContextType>;
  LimitingBoxAnnotationDataOutput?: TLimitingBoxAnnotationDataOutputResolvers<ContextType>;
  LimitingBoxAnnotationOutput?: TLimitingBoxAnnotationOutputResolvers<ContextType>;
  ListOfUserProjectRoleOutput?: TListOfUserProjectRoleOutputResolvers<ContextType>;
  LocalizedNameOutput?: TLocalizedNameOutputResolvers<ContextType>;
  LuciadRIALicenseOutput?: TLuciadRiaLicenseOutputResolvers<ContextType>;
  LuciadRIALicenseUnion?: TLuciadRiaLicenseUnionResolvers<ContextType>;
  MeasurementAnnotationDataOutput?: TMeasurementAnnotationDataOutputResolvers<ContextType>;
  MeasurementAnnotationOutput?: TMeasurementAnnotationOutputResolvers<ContextType>;
  MoveAssetToFolderUnion?: TMoveAssetToFolderUnionResolvers<ContextType>;
  MultiAssetDownloadLinkOutput?: TMultiAssetDownloadLinkOutputResolvers<ContextType>;
  MultipartUploadETagOutput?: TMultipartUploadETagOutputResolvers<ContextType>;
  MultipartUploadPartsOutput?: TMultipartUploadPartsOutputResolvers<ContextType>;
  MultipartUploadUrlOutput?: TMultipartUploadUrlOutputResolvers<ContextType>;
  MultipartUploadUrlUnion?: TMultipartUploadUrlUnionResolvers<ContextType>;
  Mutation?: TMutationResolvers<ContextType>;
  MyAssetsProjectUnion?: TMyAssetsProjectUnionResolvers<ContextType>;
  Node?: TNodeResolvers<ContextType>;
  NotFoundError?: TNotFoundErrorResolvers<ContextType>;
  OrderGQL?: TOrderGqlResolvers<ContextType>;
  OrderItemGQL?: TOrderItemGqlResolvers<ContextType>;
  PageInfo?: TPageInfoResolvers<ContextType>;
  PaymentCardAddressOutput?: TPaymentCardAddressOutputResolvers<ContextType>;
  PaymentCardOutput?: TPaymentCardOutputResolvers<ContextType>;
  PaymentSystemOutput?: TPaymentSystemOutputResolvers<ContextType>;
  PersistAssetUnion?: TPersistAssetUnionResolvers<ContextType>;
  Point3DOutput?: TPoint3DOutputResolvers<ContextType>;
  PointCloudSourceLidarOutput?: TPointCloudSourceLidarOutputResolvers<ContextType>;
  PolygonOutput?: TPolygonOutputResolvers<ContextType>;
  ProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutput?: TProcessingErrorAccountNotInSyncOrInvalidAddressErrorOutputResolvers<ContextType>;
  ProcessingErrorInternalServerErrorOutput?: TProcessingErrorInternalServerErrorOutputResolvers<ContextType>;
  ProcessingErrorInvalidInputForProcessingPaymentErrorOutput?: TProcessingErrorInvalidInputForProcessingPaymentErrorOutputResolvers<ContextType>;
  ProcessingErrorOperationNotAllowedOutput?: TProcessingErrorOperationNotAllowedOutputResolvers<ContextType>;
  ProcessingErrorOutput?: TProcessingErrorOutputResolvers<ContextType>;
  ProcessingErrorProcessingLimitReachedOutput?: TProcessingErrorProcessingLimitReachedOutputResolvers<ContextType>;
  ProcessingErrorResourceAccessDeniedOutput?: TProcessingErrorResourceAccessDeniedOutputResolvers<ContextType>;
  ProcessingErrorResourceNotFoundOutput?: TProcessingErrorResourceNotFoundOutputResolvers<ContextType>;
  ProcessingErrorStorageLimitReachedOutput?: TProcessingErrorStorageLimitReachedOutputResolvers<ContextType>;
  ProcessingPipelineInfoOutput?: TProcessingPipelineInfoOutputResolvers<ContextType>;
  ProcessingPipelineTriggerOutput?: TProcessingPipelineTriggerOutputResolvers<ContextType>;
  ProcessingTypeOutput?: TProcessingTypeOutputResolvers<ContextType>;
  ProcessingUsageOutput?: TProcessingUsageOutputResolvers<ContextType>;
  Product2DAerialOutput?: TProduct2DAerialOutputResolvers<ContextType>;
  ProductOutput?: TProductOutputResolvers<ContextType>;
  ProductUnion?: TProductUnionResolvers<ContextType>;
  ProfilePictureOutput?: TProfilePictureOutputResolvers<ContextType>;
  ProjectAppliedPageOutput?: TProjectAppliedPageOutputResolvers<ContextType>;
  ProjectChangeCopyOperation?: TProjectChangeCopyOperationResolvers<ContextType>;
  ProjectChangeCreateOperation?: TProjectChangeCreateOperationResolvers<ContextType>;
  ProjectChangeDeleteOperation?: TProjectChangeDeleteOperationResolvers<ContextType>;
  ProjectChangeFolderCopyOperation?: TProjectChangeFolderCopyOperationResolvers<ContextType>;
  ProjectChangeFolderCreateOperation?: TProjectChangeFolderCreateOperationResolvers<ContextType>;
  ProjectChangeFolderDeleteOperation?: TProjectChangeFolderDeleteOperationResolvers<ContextType>;
  ProjectChangeFolderMoveOperation?: TProjectChangeFolderMoveOperationResolvers<ContextType>;
  ProjectChangeFolderOperation?: TProjectChangeFolderOperationResolvers<ContextType>;
  ProjectChangeFolderUpdateOperation?: TProjectChangeFolderUpdateOperationResolvers<ContextType>;
  ProjectChangeGroupedAssetCopyOperation?: TProjectChangeGroupedAssetCopyOperationResolvers<ContextType>;
  ProjectChangeGroupedAssetCreateOperation?: TProjectChangeGroupedAssetCreateOperationResolvers<ContextType>;
  ProjectChangeGroupedAssetDeleteOperation?: TProjectChangeGroupedAssetDeleteOperationResolvers<ContextType>;
  ProjectChangeGroupedAssetMoveOperation?: TProjectChangeGroupedAssetMoveOperationResolvers<ContextType>;
  ProjectChangeGroupedAssetOperation?: TProjectChangeGroupedAssetOperationResolvers<ContextType>;
  ProjectChangeGroupedAssetUpdateOperation?: TProjectChangeGroupedAssetUpdateOperationResolvers<ContextType>;
  ProjectChangeMoveOperation?: TProjectChangeMoveOperationResolvers<ContextType>;
  ProjectChangeOperation?: TProjectChangeOperationResolvers<ContextType>;
  ProjectChangeOutput?: TProjectChangeOutputResolvers<ContextType>;
  ProjectChangePayload?: TProjectChangePayloadResolvers<ContextType>;
  ProjectChangeProjectOperation?: TProjectChangeProjectOperationResolvers<ContextType>;
  ProjectChangeProjectUpdateOperation?: TProjectChangeProjectUpdateOperationResolvers<ContextType>;
  ProjectChangeUpdateOperation?: TProjectChangeUpdateOperationResolvers<ContextType>;
  ProjectChangeUserAccessGrantedOperation?: TProjectChangeUserAccessGrantedOperationResolvers<ContextType>;
  ProjectChangeUserAccessOperation?: TProjectChangeUserAccessOperationResolvers<ContextType>;
  ProjectChangeUserAccessRevokedOperation?: TProjectChangeUserAccessRevokedOperationResolvers<ContextType>;
  ProjectErrorDuplicateNameOutput?: TProjectErrorDuplicateNameOutputResolvers<ContextType>;
  ProjectErrorInvalidInputOutput?: TProjectErrorInvalidInputOutputResolvers<ContextType>;
  ProjectErrorNameBlacklistedCharactersOutput?: TProjectErrorNameBlacklistedCharactersOutputResolvers<ContextType>;
  ProjectErrorNameBlankOutput?: TProjectErrorNameBlankOutputResolvers<ContextType>;
  ProjectErrorNameForbiddenOutput?: TProjectErrorNameForbiddenOutputResolvers<ContextType>;
  ProjectErrorNameForwardSlashOutput?: TProjectErrorNameForwardSlashOutputResolvers<ContextType>;
  ProjectErrorNameLeadingOrTrailingSpaceOutput?: TProjectErrorNameLeadingOrTrailingSpaceOutputResolvers<ContextType>;
  ProjectErrorNameTrailingDotOutput?: TProjectErrorNameTrailingDotOutputResolvers<ContextType>;
  ProjectErrorNameTwoDotsInRowOutput?: TProjectErrorNameTwoDotsInRowOutputResolvers<ContextType>;
  ProjectErrorOperationNotAllowedOutput?: TProjectErrorOperationNotAllowedOutputResolvers<ContextType>;
  ProjectErrorResourceAccessDeniedForTargetUserOutput?: TProjectErrorResourceAccessDeniedForTargetUserOutputResolvers<ContextType>;
  ProjectErrorResourceAccessDeniedOutput?: TProjectErrorResourceAccessDeniedOutputResolvers<ContextType>;
  ProjectErrorTooManyRequestsOutput?: TProjectErrorTooManyRequestsOutputResolvers<ContextType>;
  ProjectMemberOutput?: TProjectMemberOutputResolvers<ContextType>;
  ProjectMembersResultOutput?: TProjectMembersResultOutputResolvers<ContextType>;
  ProjectOutput?: TProjectOutputResolvers<ContextType>;
  ProjectResultOutput?: TProjectResultOutputResolvers<ContextType>;
  ProjectThumbnailOutput?: TProjectThumbnailOutputResolvers<ContextType>;
  ProjectUnion?: TProjectUnionResolvers<ContextType>;
  ProjectsUnion?: TProjectsUnionResolvers<ContextType>;
  PropertySpatial?: TPropertySpatialResolvers<ContextType>;
  PropertyTemporal?: TPropertyTemporalResolvers<ContextType>;
  Provider?: TProviderResolvers<ContextType>;
  Query?: TQueryResolvers<ContextType>;
  QuotationErrorProductNotFoundOutput?: TQuotationErrorProductNotFoundOutputResolvers<ContextType>;
  QuotationItemOutput?: TQuotationItemOutputResolvers<ContextType>;
  QuotationOutput?: TQuotationOutputResolvers<ContextType>;
  QuotationProductOutput?: TQuotationProductOutputResolvers<ContextType>;
  QuotationUnion?: TQuotationUnionResolvers<ContextType>;
  QuoteOutput?: TQuoteOutputResolvers<ContextType>;
  ReferencedBoundsDimensionsOutput?: TReferencedBoundsDimensionsOutputResolvers<ContextType>;
  ReferencedBoundsGeolocationOutput?: TReferencedBoundsGeolocationOutputResolvers<ContextType>;
  ReferencedBoundsOutput?: TReferencedBoundsOutputResolvers<ContextType>;
  ReferrerOutput?: TReferrerOutputResolvers<ContextType>;
  RemoveLabelsUnion?: TRemoveLabelsUnionResolvers<ContextType>;
  Renderable?: TRenderableResolvers<ContextType>;
  ReportGenerationOutput?: TReportGenerationOutputResolvers<ContextType>;
  SearchLabelsUnion?: TSearchLabelsUnionResolvers<ContextType>;
  SearchMyAssetsProjectUnion?: TSearchMyAssetsProjectUnionResolvers<ContextType>;
  SearchOutput?: TSearchOutputResolvers<ContextType>;
  SearchProjectUnion?: TSearchProjectUnionResolvers<ContextType>;
  SelectedSubscriptionTierOutput?: TSelectedSubscriptionTierOutputResolvers<ContextType>;
  ServerError?: TServerErrorResolvers<ContextType>;
  SetActivePaymentCardOutput?: TSetActivePaymentCardOutputResolvers<ContextType>;
  SetupPaymentCardCreationOutput?: TSetupPaymentCardCreationOutputResolvers<ContextType>;
  SharedGroupedAssetOutput?: TSharedGroupedAssetOutputResolvers<ContextType>;
  SharedGroupedAssetUnion?: TSharedGroupedAssetUnionResolvers<ContextType>;
  SharingCodeOutput?: TSharingCodeOutputResolvers<ContextType>;
  SharingCodeUnion?: TSharingCodeUnionResolvers<ContextType>;
  ShopErrorBackendFailedOutput?: TShopErrorBackendFailedOutputResolvers<ContextType>;
  ShopErrorNoOverlapWithProductOutput?: TShopErrorNoOverlapWithProductOutputResolvers<ContextType>;
  ShopErrorOperationNotAllowedOutput?: TShopErrorOperationNotAllowedOutputResolvers<ContextType>;
  ShopErrorProductNotFoundOutput?: TShopErrorProductNotFoundOutputResolvers<ContextType>;
  ShopErrorProductPricingNotFoundOutput?: TShopErrorProductPricingNotFoundOutputResolvers<ContextType>;
  ShopSupportedCountryValidationOutput?: TShopSupportedCountryValidationOutputResolvers<ContextType>;
  SignupFromInviteUnion?: TSignupFromInviteUnionResolvers<ContextType>;
  SignupOutput?: TSignupOutputResolvers<ContextType>;
  SignupUnion?: TSignupUnionResolvers<ContextType>;
  SimpleAccountInfoOutput?: TSimpleAccountInfoOutputResolvers<ContextType>;
  SimpleResultOutput?: TSimpleResultOutputResolvers<ContextType>;
  SimpleResultUnion?: TSimpleResultUnionResolvers<ContextType>;
  SimpleUserProfileOutput?: TSimpleUserProfileOutputResolvers<ContextType>;
  SimpleUserProfileUnion?: TSimpleUserProfileUnionResolvers<ContextType>;
  StreamingUsageOutput?: TStreamingUsageOutputResolvers<ContextType>;
  SubProcessGQL?: TSubProcessGqlResolvers<ContextType>;
  Subscription?: TSubscriptionResolvers<ContextType>;
  SubscriptionAddonOutput?: TSubscriptionAddonOutputResolvers<ContextType>;
  SubscriptionErrorAccountNotSyncedOutput?: TSubscriptionErrorAccountNotSyncedOutputResolvers<ContextType>;
  SubscriptionErrorBadRequestOutput?: TSubscriptionErrorBadRequestOutputResolvers<ContextType>;
  SubscriptionErrorDuplicateWithSameAddonOutput?: TSubscriptionErrorDuplicateWithSameAddonOutputResolvers<ContextType>;
  SubscriptionErrorFeatureNotAvailableOutput?: TSubscriptionErrorFeatureNotAvailableOutputResolvers<ContextType>;
  SubscriptionErrorOperationNotAllowedOutput?: TSubscriptionErrorOperationNotAllowedOutputResolvers<ContextType>;
  SubscriptionInvoiceOutput?: TSubscriptionInvoiceOutputResolvers<ContextType>;
  SubscriptionInvoicesOutput?: TSubscriptionInvoicesOutputResolvers<ContextType>;
  SubscriptionInvoicesUnion?: TSubscriptionInvoicesUnionResolvers<ContextType>;
  SubscriptionLimitOutput?: TSubscriptionLimitOutputResolvers<ContextType>;
  SubscriptionNoValidIsoCountryFoundOutput?: TSubscriptionNoValidIsoCountryFoundOutputResolvers<ContextType>;
  SubscriptionOrderItemOutput?: TSubscriptionOrderItemOutputResolvers<ContextType>;
  SubscriptionOrderOutput?: TSubscriptionOrderOutputResolvers<ContextType>;
  SubscriptionOrdersOutput?: TSubscriptionOrdersOutputResolvers<ContextType>;
  SubscriptionOrdersUnion?: TSubscriptionOrdersUnionResolvers<ContextType>;
  SubscriptionOutput?: TSubscriptionOutputResolvers<ContextType>;
  SubscriptionPriceOutput?: TSubscriptionPriceOutputResolvers<ContextType>;
  SubscriptionQuotationAddonOutput?: TSubscriptionQuotationAddonOutputResolvers<ContextType>;
  SubscriptionQuotationOutput?: TSubscriptionQuotationOutputResolvers<ContextType>;
  SubscriptionQuotationTierOutput?: TSubscriptionQuotationTierOutputResolvers<ContextType>;
  SubscriptionQuotationUnion?: TSubscriptionQuotationUnionResolvers<ContextType>;
  SubscriptionTierAddonOptionOutput?: TSubscriptionTierAddonOptionOutputResolvers<ContextType>;
  SubscriptionTierAddonOutput?: TSubscriptionTierAddonOutputResolvers<ContextType>;
  SubscriptionTierOutput?: TSubscriptionTierOutputResolvers<ContextType>;
  SubscriptionTiersOutput?: TSubscriptionTiersOutputResolvers<ContextType>;
  SubscriptionTiersUnion?: TSubscriptionTiersUnionResolvers<ContextType>;
  SubscriptionTotalPriceOutput?: TSubscriptionTotalPriceOutputResolvers<ContextType>;
  SubscriptionUnion?: TSubscriptionUnionResolvers<ContextType>;
  SubscriptionsOutput?: TSubscriptionsOutputResolvers<ContextType>;
  SubscriptionsUnion?: TSubscriptionsUnionResolvers<ContextType>;
  Summary?: TSummaryResolvers<ContextType>;
  SummaryItemGQL?: TSummaryItemGqlResolvers<ContextType>;
  SupportedCountryLocalizedNameOutput?: TSupportedCountryLocalizedNameOutputResolvers<ContextType>;
  SupportedCountryOutput?: TSupportedCountryOutputResolvers<ContextType>;
  TaskGQL?: TTaskGqlResolvers<ContextType>;
  TenantDeletionOutput?: TTenantDeletionOutputResolvers<ContextType>;
  TenantDeletionUnion?: TTenantDeletionUnionResolvers<ContextType>;
  TenantOutput?: TTenantOutputResolvers<ContextType>;
  TenantUnion?: TTenantUnionResolvers<ContextType>;
  TenantsOutput?: TTenantsOutputResolvers<ContextType>;
  TenantsUnion?: TTenantsUnionResolvers<ContextType>;
  ThumbnailOutput?: TThumbnailOutputResolvers<ContextType>;
  TokenWalletOutput?: TTokenWalletOutputResolvers<ContextType>;
  TokensRefundOutput?: TTokensRefundOutputResolvers<ContextType>;
  TokensTransactionOutput?: TTokensTransactionOutputResolvers<ContextType>;
  TokensTransactionsOutput?: TTokensTransactionsOutputResolvers<ContextType>;
  TokensTransactionsUnion?: TTokensTransactionsUnionResolvers<ContextType>;
  Tour?: TTourResolvers<ContextType>;
  TourAppliedPageOutput?: TTourAppliedPageOutputResolvers<ContextType>;
  TourCameraViewpointOutput?: TTourCameraViewpointOutputResolvers<ContextType>;
  TourFilterAppliedOutput?: TTourFilterAppliedOutputResolvers<ContextType>;
  TourKeyframe?: TTourKeyframeResolvers<ContextType>;
  TriggerProcessingOutput?: TTriggerProcessingOutputResolvers<ContextType>;
  TriggerProcessingUnion?: TTriggerProcessingUnionResolvers<ContextType>;
  UnauthenticatedError?: TUnauthenticatedErrorResolvers<ContextType>;
  UnsharedGroupedAssetOutput?: TUnsharedGroupedAssetOutputResolvers<ContextType>;
  UnsharedGroupedAssetUnion?: TUnsharedGroupedAssetUnionResolvers<ContextType>;
  UpdateApplicationTemplateUnion?: TUpdateApplicationTemplateUnionResolvers<ContextType>;
  UpdateAssetUnion?: TUpdateAssetUnionResolvers<ContextType>;
  UpdateFolderUnion?: TUpdateFolderUnionResolvers<ContextType>;
  UpdateLabelAnnotationUnion?: TUpdateLabelAnnotationUnionResolvers<ContextType>;
  UpdateLimitingBoxAnnotationUnion?: TUpdateLimitingBoxAnnotationUnionResolvers<ContextType>;
  UpdateMeasurementAnnotationUnion?: TUpdateMeasurementAnnotationUnionResolvers<ContextType>;
  UpdateProjectUnion?: TUpdateProjectUnionResolvers<ContextType>;
  UploadThumbnailToProjectUnion?: TUploadThumbnailToProjectUnionResolvers<ContextType>;
  UsageAccountUnion?: TUsageAccountUnionResolvers<ContextType>;
  UsageOutput?: TUsageOutputResolvers<ContextType>;
  UsageTenantOutput?: TUsageTenantOutputResolvers<ContextType>;
  UsageTenantUnion?: TUsageTenantUnionResolvers<ContextType>;
  UserDeletionOutput?: TUserDeletionOutputResolvers<ContextType>;
  UserDeletionUnion?: TUserDeletionUnionResolvers<ContextType>;
  UserFlagOutput?: TUserFlagOutputResolvers<ContextType>;
  UserProfileAppliedPageOutput?: TUserProfileAppliedPageOutputResolvers<ContextType>;
  UserProfileOutput?: TUserProfileOutputResolvers<ContextType>;
  UserProfileResultOutput?: TUserProfileResultOutputResolvers<ContextType>;
  UserProjectRoleOutput?: TUserProjectRoleOutputResolvers<ContextType>;
  UserProjectRoleUnion?: TUserProjectRoleUnionResolvers<ContextType>;
  UserSettingsOutput?: TUserSettingsOutputResolvers<ContextType>;
  ValidationError?: TValidationErrorResolvers<ContextType>;
  ValidationErrorIssue?: TValidationErrorIssueResolvers<ContextType>;
  Vector3Output?: TVector3OutputResolvers<ContextType>;
  VoucherOutput?: TVoucherOutputResolvers<ContextType>;
  VoucherUnion?: TVoucherUnionResolvers<ContextType>;
  WalletRefundUnion?: TWalletRefundUnionResolvers<ContextType>;
  WalletUnion?: TWalletUnionResolvers<ContextType>;
  _Any?: GraphQLScalarType;
  _Entity?: T_EntityResolvers<ContextType>;
};

export type TDirectiveResolvers<ContextType = any> = {
  canonical?: TCanonicalDirectiveResolver<any, any, ContextType>;
  computed?: TComputedDirectiveResolver<any, any, ContextType>;
  key?: TKeyDirectiveResolver<any, any, ContextType>;
  live?: TLiveDirectiveResolver<any, any, ContextType>;
  merge?: TMergeDirectiveResolver<any, any, ContextType>;
};
