import {FolderInfo, useFolder} from './useFolder';
import React, {useEffect, useImperativeHandle, useState} from 'react';
import {useProjects} from './useProjects';
import {HxDRAssetList} from './HxDRAssetList';
import {ProcessingAsset} from './useProcessingAssets';

interface Props {
  onGoBack?: () => void;
  title?: string;
  pageSize: number;
  selectedAsset?: ProcessingAsset | null;
  onSelectAsset?: (asset: ProcessingAsset | null) => void;
  onDoubleClickAsset?: (asset: ProcessingAsset) => void;
  selectedFolder?: FolderInfo | null;
  onSelectFolder?: (folder: FolderInfo | null) => void;
  showAssets?: boolean;
  keepEnteredFolderSelected?: boolean;
}

export interface HxDRProjectsBrowserRef {
  enterFolder: (folder: FolderInfo) => void;
}

/**
 * Component that represents the contents of a HxDR project in a filesystem-like explorer.
 */
export const HxDRProjectsBrowser = React.forwardRef<
  HxDRProjectsBrowserRef,
  Props
>(
  (
    {
      onGoBack,
      title,
      pageSize,
      selectedAsset,
      onSelectAsset,
      onDoubleClickAsset,
      selectedFolder,
      onSelectFolder,
      showAssets,
      keepEnteredFolderSelected = false,
    },
    ref
  ) => {
    const [breadCrumbs, setBreadCrumbs] = useState<FolderInfo[]>([]);

    useImperativeHandle(ref, () => ({
      enterFolder: (folder) => setBreadCrumbs([folder, ...breadCrumbs]),
    }));

    useEffect(() => {
      onSelectAsset?.(null);
      if (!keepEnteredFolderSelected) {
        onSelectFolder?.(null);
      } else {
        onSelectFolder?.(breadCrumbs.length > 0 ? breadCrumbs[0] : null);
      }
    }, [breadCrumbs]);

    if (breadCrumbs.length === 0) {
      return (
        <HxDRProjects
          selectedFolder={selectedFolder}
          onSelectFolder={onSelectFolder}
          onEnterSubFolder={(rootFolder: FolderInfo) =>
            setBreadCrumbs([rootFolder])
          }
          onGoBack={onGoBack}
          title={title}
          pageSize={pageSize}
        />
      );
    } else {
      return (
        <HxDRAssetsFolder
          folder={breadCrumbs[0]}
          selectedAsset={selectedAsset}
          onSelectAsset={onSelectAsset}
          onDoubleClickAsset={onDoubleClickAsset}
          selectedFolder={selectedFolder}
          onSelectFolder={onSelectFolder}
          onEnterSubFolder={(subFolder: FolderInfo) =>
            setBreadCrumbs([subFolder, ...breadCrumbs])
          }
          onGoBack={() => setBreadCrumbs(breadCrumbs.slice(1))}
          pageSize={pageSize}
          showAssets={showAssets}
        />
      );
    }
  }
);

interface ProjectsProps {
  onEnterSubFolder: (subFolder: FolderInfo) => void;
  onGoBack?: () => void;
  title?: string;
  pageSize: number;
  selectedFolder?: FolderInfo | null;
  onSelectFolder?: (asset: FolderInfo) => void;
}

const HxDRProjects = ({
  onEnterSubFolder,
  selectedFolder,
  onSelectFolder,
  onGoBack,
  title,
  pageSize,
}: ProjectsProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const {projects, errors, busy, pagesAmount} = useProjects(
    pageNumber,
    pageSize
  );

  useEffect(() => {
    if (errors) {
      console.error(errors);
    }
  }, [errors]);

  return (
    <HxDRAssetList
      title={title ?? 'Projects'}
      pageNumber={pageNumber}
      pagesAmount={pagesAmount}
      onSelectPage={setPageNumber}
      selectedFolder={selectedFolder}
      onSelectFolder={onSelectFolder}
      onEnterSubFolder={onEnterSubFolder}
      subFolders={projects.map((project) => ({
        id: project.rootFolder.id,
        name: project.name,
      }))}
      busy={busy}
      onGoBack={onGoBack}
    />
  );
};

interface FolderProps {
  folder: FolderInfo;
  selectedAsset?: ProcessingAsset | null;
  onSelectAsset?: (item: ProcessingAsset) => void;
  onDoubleClickAsset?: (asset: ProcessingAsset) => void;
  selectedFolder?: FolderInfo | null;
  onSelectFolder?: (asset: FolderInfo) => void;
  onEnterSubFolder: (subFolder: FolderInfo) => void;
  onGoBack: () => void;
  pageSize: number;
  showAssets?: boolean;
}

const HxDRAssetsFolder = ({
  folder,
  selectedAsset,
  onSelectAsset,
  onDoubleClickAsset,
  selectedFolder,
  onSelectFolder,
  onEnterSubFolder,
  onGoBack,
  pageSize,
  showAssets = true,
}: FolderProps) => {
  const [pageNumber, setPageNumber] = useState(0);
  const {subFolders, assets, errors, pagesAmount, busy} = useFolder(
    folder.id,
    pageNumber,
    pageSize,
    showAssets
  );

  useEffect(() => {
    if (errors) {
      console.error(errors);
    }
  });

  useEffect(() => {
    setPageNumber(0);
  }, [folder.id]);

  return (
    <HxDRAssetList
      title={folder.name}
      pageNumber={pageNumber}
      pagesAmount={pagesAmount}
      onSelectPage={setPageNumber}
      subFolders={subFolders as FolderInfo[]}
      assets={assets}
      selectedAsset={selectedAsset}
      onSelectAsset={onSelectAsset}
      onDoubleClickAsset={onDoubleClickAsset}
      selectedFolder={selectedFolder}
      onSelectFolder={onSelectFolder}
      onEnterSubFolder={onEnterSubFolder}
      onGoBack={onGoBack}
      busy={busy}
    />
  );
};
