import React from 'react'
import { Routes, Route, BrowserRouter, Link, Outlet } from 'react-router-dom'
import { AppContextProvider } from './common/context/AppContext'
import { Home } from './pages/Home'
import { Layout } from './pages/Layout'
import { NotFound } from './pages/NotFound'
import { PointCloudAlignment } from './pages/PointCloudAlignment'
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack'

export const App = () => {
  return (
    <SnackbarProvider maxSnack={3}>
      <AppContextProvider>
        <BrowserRouter basename={process.env.BASE}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route
                path="projects/:project"
                element={<PointCloudAlignment />}
              />
              {/* Using path="*"" means "match anything", so this route
                  acts like a catch-all for URLs that we don't have explicit
                  routes for. */}
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppContextProvider>
    </SnackbarProvider>
  )
  // return (
  //   <PointCloudAlignment />
  // )
}
