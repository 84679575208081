import {useEffect, useRef, useState} from 'react';
import {
  GetFolderDocument,
  TAssetOrderEnum,
  TFolderOrderEnum,
  TGetFolderQuery,
  TGetFolderQueryVariables,
} from '../../graphql/gen/graphql';
import {getApolloClient} from '../../util/PersistanceUtil';
import {getResolvedBearerToken} from '../../authentication/AuthenticationUtil';
import {ApolloClient} from '@apollo/client';
import {useProcessingAssets} from './useProcessingAssets';

export interface FolderInfo {
  readonly name: string;
  readonly id: string;
}

async function getFolderData(
  client: ApolloClient<object>,
  folderId: string,
  options: {
    foldersPageNumber: number;
    foldersPageSize: number;
    foldersPageOffset?: number;
    assetsPageNumber: number;
    assetsPageSize: number;
    assetsPageOffset?: number;
  }
) {
  return client.query<TGetFolderQuery, TGetFolderQueryVariables>({
    query: GetFolderDocument,
    variables: {
      id: folderId,
      orderFoldersBy: TFolderOrderEnum.CREATED_AT_ASC,
      orderAssetsBy: TAssetOrderEnum.CREATED_AT_ASC,
      foldersPageNumber: options.foldersPageNumber,
      foldersPageSize: options.foldersPageSize,
      foldersPageOffset: options.foldersPageOffset,
      assetsPageNumber: options.assetsPageNumber,
      assetsPageSize: options.assetsPageSize,
      assetsPageOffset: options.assetsPageOffset,
    },
    fetchPolicy: 'no-cache',
  });
}

/**
 * React hook that returns the sub-folders and assets of the current users with the given pagination.
 * Assets will only be returned in pages after or on the page with the last sub-folder.
 */
export function useFolder(
  folderId: string,
  pageNumber: number,
  pageSize: number,
  withAssets: boolean
) {
  const [subFolders, setSubFolders] = useState<readonly FolderInfo[]>([]);
  const [assetInfos, setAssetInfos] = useState<readonly FolderInfo[]>([]);
  const assets = useProcessingAssets(
    assetInfos.map((assetInfo) => ({
      groupedAssetId: assetInfo.id,
      name: assetInfo.name,
    }))
  );
  const [fetchedData, setFetchedData] = useState(false);
  const [foldersTotal, setFoldersTotal] = useState<number>(-1);
  const [assetsTotal, setAssetsTotal] = useState<number>(-1);
  const [errors, setErrors] = useState<readonly Error[]>();
  const isInitialDataFetched = useRef<boolean>(false);
  const requestCounter = useRef<number>(0);

  const client = getApolloClient(getResolvedBearerToken());

  useEffect(() => {
    setAssetInfos([]);
    setSubFolders([]);
    setFoldersTotal(-1);
    setAssetsTotal(-1);
    setErrors(undefined);
    isInitialDataFetched.current = false;
  }, [folderId]);

  async function fetchInitialData() {
    const currentRequest = requestCounter.current++;
    requestCounter.current = currentRequest;

    //at first we don't know how many folders & assets there are, so we just request the first pageSize amount of them
    const {data, errors} = await getFolderData(client, folderId, {
      foldersPageNumber: 0,
      foldersPageSize: pageSize,
      assetsPageNumber: 0,
      assetsPageSize: pageSize,
    });
    if (requestCounter.current === currentRequest) {
      if (errors) {
        setErrors(errors);
      }
      if (errors) {
        setErrors(errors);
      } else if (data.folder?.__typename !== 'FolderOutput') {
        setErrors([
          new Error('Could not get folders: ' + data.folder!.message),
        ]);
      } else {
        const folders = data.folder.folders.contents as FolderInfo[];
        const assets = data.folder.assets.contents as FolderInfo[];
        setSubFolders(folders);
        setAssetInfos(
          withAssets ? assets.slice(0, pageSize - folders.length) : []
        );
        setAssetsTotal(withAssets ? Math.ceil(data.folder.assets.total) : 0);
        setFoldersTotal(Math.ceil(data.folder.folders.total));
        setFetchedData(true);
      }
      isInitialDataFetched.current = true;
    }
  }

  useEffect(() => {
    (async () => {
      setFetchedData(false);
      setSubFolders([]);
      setAssetInfos([]);
      if (!isInitialDataFetched.current) {
        fetchInitialData();
      } else {
        const currentRequest = requestCounter.current++;
        requestCounter.current = currentRequest;

        const remainingFolders = foldersTotal - pageNumber * pageSize;
        let foldersPageNumber = 0;
        let foldersPageSize = 0;
        let assetsPageNumber = 0;
        let assetsPageSize = 0;
        let assetsPageOffset = 0;

        if (remainingFolders >= pageSize) {
          foldersPageNumber = pageNumber;
          foldersPageSize = pageSize;
        } else if (remainingFolders > 0) {
          foldersPageNumber = pageNumber;
          foldersPageSize = pageSize;
          assetsPageSize = pageSize - (foldersTotal % pageSize);
        } else {
          assetsPageNumber = pageNumber - Math.ceil(foldersTotal / pageSize);
          assetsPageSize = pageSize;
          assetsPageOffset = (pageSize - (foldersTotal % pageSize)) % pageSize;
        }

        const {data, errors} = await getFolderData(client, folderId, {
          foldersPageNumber,
          foldersPageSize: Math.max(foldersPageSize, 1),
          assetsPageNumber,
          assetsPageSize: Math.max(assetsPageSize, 1),
          assetsPageOffset,
        });

        if (requestCounter.current === currentRequest) {
          if (errors) {
            setErrors(errors);
          } else if (data.folder?.__typename !== 'FolderOutput') {
            setErrors([
              new Error('Could not get folders: ' + data.folder!.message),
            ]);
          } else {
            if (foldersPageSize) {
              setSubFolders(data.folder.folders.contents as FolderInfo[]);
            }
            if (assetsPageSize && withAssets) {
              setAssetInfos(data.folder.assets.contents as FolderInfo[]);
            }
            setFoldersTotal(data.folder.folders.total);
            setAssetsTotal(withAssets ? data.folder.assets.total : 0);
          }
          setFetchedData(true);
        }
      }
    })();
  }, [folderId, pageSize, pageNumber]);

  return {
    subFolders,
    assets,
    busy: !fetchedData,
    pagesAmount: Math.max(
      Math.ceil((foldersTotal + assetsTotal) / pageSize),
      1
    ),
    errors,
  };
}
