import { getReference } from '@luciad/ria/reference/ReferenceProvider'
import { Point } from '@luciad/ria/shape/Point'
import { createPoint } from '@luciad/ria/shape/ShapeFactory'
import {
  Affine3DTransformation,
  createChainedTransformation,
  createTransformationFromGeoLocation,
} from '@luciad/ria/transformation/Affine3DTransformation'
import { WebGLMap } from '@luciad/ria/view/WebGLMap'

interface Rotation {
  w: number
  x: number
  y: number
  z: number
}

interface Translation {
  x: number
  y: number
  z: number
}

interface Pose {
  Rotation: Rotation
  Translation: Translation
}

interface RelativePose {
  DatasetIndex0: number
  DatasetIndex1: number
  RelativePose: Pose
}

interface AbsolutePose {
  DatasetIndex: number
  AbsolutePose: Pose
}

interface RegistrationResult {
  Datasets: string[]
  RelativePoses: RelativePose[]
  AbsolutePoses: AbsolutePose[]
}

export const getResultTransformationJson = (
  transformation: Affine3DTransformation,
  map: WebGLMap
) => {
  const geoLocation = createTransformationFromGeoLocation(
    createPoint(getReference('EPSG:4326'), [0.1, 0.1, 0]),
    { destinationReference: map.reference }
  )
  const relTransformation = createChainedTransformation(
    transformation,
    geoLocation.inverseTransformation
  )
  const poseOrigin: Pose = {
    Rotation: { w: 1.0, x: 0.0, y: 0.0, z: 0.0 },
    Translation: { x: 0.0, y: 0.0, z: 0.0 },
  }

  const point0: Point = createPoint(getReference('EPSG:4326'), [0, 0, 0])
  const pointX: Point = createPoint(getReference('EPSG:4326'), [1, 0, 0])
  const pointY: Point = createPoint(getReference('EPSG:4326'), [0, 1, 0])
  const pointZ: Point = createPoint(getReference('EPSG:4326'), [0, 0, 1])
  const pointTr0: Point = relTransformation.transform(point0)
  const pointTrX: Point = relTransformation.transform(pointX)
  const pointTrY: Point = relTransformation.transform(pointY)
  const pointTrZ: Point = relTransformation.transform(pointZ)

  const m00 = pointTrX.x - pointTr0.x
  const m01 = pointTrX.y - pointTr0.y
  const m02 = pointTrX.z - pointTr0.z

  const m10 = pointTrY.x - pointTr0.x
  const m11 = pointTrY.y - pointTr0.y
  const m12 = pointTrY.z - pointTr0.z

  const m20 = pointTrZ.x - pointTr0.x
  const m21 = pointTrZ.y - pointTr0.y
  const m22 = pointTrZ.z - pointTr0.z

  const qw = Math.sqrt(1 + m00 + m11 + m22) * 0.5
  const qx = (m21 - m12) / (4.0 * qw)
  const qy = (m02 - m20) / (4.0 * qw)
  const qz = (m10 - m01) / (4.0 * qw)

  const poseRel: Pose = {
    Rotation: { w: qw, x: qx, y: qy, z: qz },
    Translation: { x: pointTr0.x, y: pointTr0.y, z: pointTr0.z },
  }
  const r: RegistrationResult = {
    Datasets: ['0', '1'],
    RelativePoses: [
      {
        DatasetIndex0: 0,
        DatasetIndex1: 1,
        RelativePose: poseRel,
      },
    ],
    AbsolutePoses: [
      {
        DatasetIndex: 0,
        AbsolutePose: poseOrigin,
      },
      {
        DatasetIndex: 1,
        AbsolutePose: poseRel,
      },
    ],
  }
  const s = JSON.stringify(r, null, '\t')
  console.log(s)
  return s
}

export const downloadFile = (filename: string, text: string) => {
  const element = document.createElement('a')
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  )
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}
