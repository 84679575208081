import {LayerAddress, LayerAddressType} from '../util/LayerAddressLoader';
import {ApolloClient} from '@apollo/client';
import {
  CreateGeoreferenceDocument,
  DeleteGeoreferenceDocument,
  GetAssetForGeolocationDocument,
  TCreateGeoreferenceMutation,
  TCreateGeoreferenceMutationVariables,
  TDeleteGeoreferenceMutation,
  TDeleteGeoreferenceMutationVariables,
  TGeoreferencedArtifactTypeEnum,
  TGetAssetForGeolocationQuery,
  TGetAssetForGeolocationQueryVariables,
  TUpdateGeoreferenceMutation,
  TUpdateGeoreferenceMutationVariables,
  UpdateGeoreferenceDocument,
} from '../graphql/gen/graphql';
import {Vector3} from '@luciad/ria/util/Vector3';

const GEOLOCATION_ARTIFACT_TYPES = ['MESH', 'PANORAMIC', 'POINT_CLOUD'];
export type GeolocationArtifactType =
  (typeof GEOLOCATION_ARTIFACT_TYPES)[number];

export function isGeolocationArtifactType(
  type: string
): type is GeolocationArtifactType {
  return GEOLOCATION_ARTIFACT_TYPES.indexOf(type) >= 0;
}

/**
 * Asset containing information about its geolocations
 */
export interface GeolocationAsset {
  id: string;
  name: string;
  createdAt: string;
  thumbnailPath?: string | null;
  asset: {
    withEmbeddedGeoreference: boolean;
    artifacts: {
      contents: {
        addresses: {
          contents: LayerAddress[];
        };
        type: LayerAddressType;
      }[];
    };
  };
  groupedAssetGeoreferences: {
    contents: AssetGeolocation[];
  };
}

/**
 * All information needed to display a geolocated asset
 */
export interface AssetGeolocation {
  id: string;
  name: string;

  altitude: number;
  longitude: number;
  latitude: number;

  anchorX: number;
  anchorY: number;
  anchorZ: number;

  pitch: number;
  yaw: number;
  roll: number;

  artifacts: GeolocationArtifact[];
}

/**
 * Artifact containing address and visibility information for a geolocated layer.
 */
export interface GeolocationArtifact {
  id: string;
  visible: boolean;
  artifact: {
    addresses: {
      contents: LayerAddress[];
    };
    type: ArtifactType;
  };
}

export type ArtifactType =
  | 'IMAGE_2D_ORTHO'
  | 'LIDAR'
  | 'MESH'
  | 'PANORAMIC'
  | 'POINT_CLOUD'
  | 'VECTOR_OR_RASTER';

/**
 * Fetches the {@link GeolocationAsset} with given id from HxDR.
 */
export async function getGeolocationAsset(
  client: ApolloClient<object>,
  groupedAssetId: string
): Promise<GeolocationAsset> {
  const {data, errors} = await client.query<
    TGetAssetForGeolocationQuery,
    TGetAssetForGeolocationQueryVariables
  >({
    query: GetAssetForGeolocationDocument,
    variables: {
      groupedAssetId,
    },
    fetchPolicy: 'no-cache',
  });

  if (errors) {
    throw errors;
  } else if (data.asset?.__typename !== 'GroupedAssetOutput') {
    throw new Error('Could not get asset: ' + data.asset!.message);
  } else {
    return data.asset as GeolocationAsset;
  }
}

/**
 * Creates a persisted {@link GeolocationAsset} on HxDR.
 */
export async function createGeolocation(
  client: ApolloClient<object>,
  groupedAssetId: string,
  name: string,
  geolocation: Vector3,
  anchor: Vector3,
  artifactTypes: GeolocationArtifactType[]
): Promise<AssetGeolocation> {
  const {data, errors} = await client.mutate<
    TCreateGeoreferenceMutation,
    TCreateGeoreferenceMutationVariables
  >({
    mutation: CreateGeoreferenceDocument,
    variables: {
      parentId: groupedAssetId,
      name,
      longitude: geolocation.x,
      latitude: geolocation.y,
      altitude: geolocation.z,
      anchorX: anchor.x,
      anchorY: anchor.y,
      anchorZ: anchor.z,
      visibilities: artifactTypes.map((type) => ({
        type: type as TGeoreferencedArtifactTypeEnum,
        visible: true,
      })),
    },
    fetchPolicy: 'no-cache',
  });

  if (errors) {
    throw errors;
  }

  return data!.createGroupedAssetGeoreference as AssetGeolocation;
}

/**
 * Updates the given {@link GeolocationAsset} on HxDR.
 */
export async function updateGeolocation(
  client: ApolloClient<object>,
  geolocationId: string,
  update: AssetGeolocation
): Promise<AssetGeolocation> {
  const {data, errors} = await client.mutate<
    TUpdateGeoreferenceMutation,
    TUpdateGeoreferenceMutationVariables
  >({
    mutation: UpdateGeoreferenceDocument,
    variables: {
      altitude: update.altitude,
      anchorX: update.anchorX,
      anchorY: update.anchorY,
      anchorZ: update.anchorZ,
      flattenEnabled: true,
      flattenScale: 1,
      georeferenceId: update.id,
      latitude: update.latitude,
      longitude: update.longitude,
      name: update.name,
      pitch: update.pitch,
      yaw: update.yaw,
      roll: update.roll,
      scaleX: 1,
      scaleY: 1,
      scaleZ: 1,
      visible: update.artifacts.map(({visible, artifact}) => ({
        visible,
        type: artifact.type as TGeoreferencedArtifactTypeEnum,
      })),
    },
    fetchPolicy: 'no-cache',
  });

  if (errors) {
    throw errors;
  }

  return data!.updateGroupedAssetGeoreference as AssetGeolocation;
}

/**
 * Deletes the {@link GeolocationAsset} with given id from HxDR.
 */
export async function deleteGeolocation(
  client: ApolloClient<object>,
  geolocationId: string
): Promise<boolean> {
  const {data, errors} = await client.mutate<
    TDeleteGeoreferenceMutation,
    TDeleteGeoreferenceMutationVariables
  >({
    mutation: DeleteGeoreferenceDocument,
    variables: {id: geolocationId},
    fetchPolicy: 'no-cache',
  });

  if (errors) {
    throw errors;
  }
  return data!.deleteGroupedAssetGeoreference;
}
