import {Bounds} from '@luciad/ria/shape/Bounds';
import {Point} from '@luciad/ria/shape/Point';
import {getReference} from '@luciad/ria/reference/ReferenceProvider';
import {createBounds, createPoint} from '@luciad/ria/shape/ShapeFactory';
import {createTransformation} from '@luciad/ria/transformation/TransformationFactory';
import {CoordinateReference} from '@luciad/ria/reference/CoordinateReference';
import {Map} from '@luciad/ria/view/Map';
import {Vector3} from '@luciad/ria/util/Vector3';
import {distance, Length} from '../../util/Vector3Util';

const refLLH = getReference('EPSG:4326'); // 3D WGS:84
const ref3D = getReference('EPSG:4978');

/**
 * Holds the asset dimension information and provides support that is based on that.
 */
export class AssetDimension {
  private _radius: number; // radius of the asset's encircling sphere
  private _center: Point; // EPSG:4978
  private _centerLLH: Point; // EPSG:4326
  private _boundsLLH: Bounds;
  // max distance the camera can be placed away from the center of the asset
  private _maxDistance: number;

  constructor() {
    this._center = createPoint(ref3D, [0, 0, 0]);
    this._centerLLH = createPoint(refLLH, [0, 0, 0]);
    this._radius = 0;
    this._maxDistance = 0;
    this._boundsLLH = createBounds(refLLH, [0, 0, 0, 0, 0, 0]);
  }

  /**
   * The point is always referenced in EPSG:4978.
   */
  get center(): Point {
    return this._center;
  }

  get radius(): number {
    return this._radius;
  }

  get maxDistance(): number {
    return this._maxDistance;
  }

  public recomputeDimension(layerBounds: Bounds) {
    const { center, radius, boundsLLH, centerLLH } =
      calculateCenterAndRadius(layerBounds);
    this._center = center;
    this._radius = radius;
    this._maxDistance = 8 * radius;
    this._centerLLH = centerLLH;
    this._boundsLLH = boundsLLH;
  }

  public isInAssetWorld(vector: Vector3): boolean {
    return this.distanceToCenterVector(vector) < 0.9 * this._maxDistance;
  }

  public distanceToCenterVector(vector: Vector3): number {
    return distance(this._center, vector);
  }

  public isCenterInView(map: Map): boolean {
    const { viewSize, mapToViewTransformation } = map;

    try {
      const { x, y } = mapToViewTransformation.transform(this.center);
      const [w, h] = viewSize;
      return x > 0 && x < w && y > 0 && y < h;
    } catch (e) {
      return false;
    }
  }
}

function calculateCenterAndRadius(layerBounds: Bounds): {
  center: Point;
  radius: number;
  boundsLLH: Bounds;
  centerLLH: Point;
} {
  const identifier = layerBounds.reference?.identifier;
  if (!identifier) {
    throw new Error(`Unknown reference identifier`);
  }

  const assetRef = layerBounds.reference as CoordinateReference;

  const centerAsset = createPoint(assetRef, [
    layerBounds.x + layerBounds.width * 0.5,
    layerBounds.y + layerBounds.height * 0.5,
    layerBounds.z + layerBounds.depth * 0.5,
  ]);

  const tx3D = createTransformation(assetRef, ref3D);
  const center = tx3D.transform(centerAsset);

  const radius = Length({
    x: layerBounds.width * 0.5,
    y: layerBounds.height * 0.5,
    z: layerBounds.depth * 0.5,
  });

  const txLLH = createTransformation(assetRef, refLLH);
  const boundsLLH = txLLH.transformBounds(layerBounds);
  const centerLLH = txLLH.transform(center);

  return { center, radius, centerLLH, boundsLLH };
}
