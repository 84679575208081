import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  defaultDataIdFromObject,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {RetryLink} from '@apollo/client/link/retry';
import introspection from '../graphql/gen/introspection';

export const HXDR_DOMAIN = 'https://uat-hxdr.com';
export const GRAPHQL_ENDPOINT = HXDR_DOMAIN + '/graphql';

const INITIAL_RETRY_DELAY = 300;
const MAX_RETRY_DELAY = 5;

let client: ApolloClient<NormalizedCacheObject> | null = null;

/**
 * Returns the apollo client of this application that allows requests to the HxDR GraphQL API.
 * The apollo client is currently a static object that is never updated, which means that users need to refresh their
 * page when their bearer token is no longer valid.
 */
export function getApolloClient(bearerToken: string) {
  if (!client) {
    client = createApolloClient(bearerToken);
  }
  return client;
}

function createApolloClient(bearerToken: string) {
  const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
    fetch,
  });

  const retryLink = new RetryLink({
    delay: {
      initial: INITIAL_RETRY_DELAY,
      max: MAX_RETRY_DELAY,
      jitter: true,
    },
    attempts: (count, operation, error) => {
      if (
        !error.message ||
        (error.message !== 'Failed to fetch' && error.statusCode !== 500)
      ) {
        // retry only if it's a connection issue | 500 is (usually) a connection reset
        return false;
      }

      const retry = count <= MAX_RETRY_DELAY;
      operation.setContext((context: Record<string, any>) => ({
        ...context,
        retryCount: count,
      }));
      return retry;
    },
  });

  const authLink = setContext(async (_, {headers}) => {
    try {
      return {
        headers: {
          ...headers,
          Authorization: bearerToken,
        },
      };
    } catch (e) {
      console.error(e);
    }
    return headers;
  });

  const cache = new InMemoryCache({
    possibleTypes: introspection.possibleTypes,
    dataIdFromObject: (object) => {
      const {uuid} = object;
      if (typeof uuid === 'string') {
        return uuid;
      }
      return defaultDataIdFromObject(object);
    },
  });

  return new ApolloClient({
    cache,
    link: ApolloLink.from([authLink, retryLink, httpLink]),
    ssrMode: false,
    resolvers: {},
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
    connectToDevTools: true,
  });
}
