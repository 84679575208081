import {Controller} from '@luciad/ria/view/controller/Controller';
import {HandleEventResult} from '@luciad/ria/view/controller/HandleEventResult';
import {GestureEvent} from '@luciad/ria/view/input/GestureEvent';
import {GestureEventType} from '@luciad/ria/view/input/GestureEventType';
import {WebGLMap} from '@luciad/ria/view/WebGLMap';
import {GeoCanvas} from '@luciad/ria/view/style/GeoCanvas';
import {AnimationManager} from '@luciad/ria/view/animation/AnimationManager';
import {KeyEvent} from '@luciad/ria/view/input/KeyEvent';
import {AssetDimension} from "./AssetDimension";
import {GestureHelper, NavigationType} from './GestureHelper';
import {NavigationPan} from "./NavigationPan";
import {NavigationRotation} from "./NavigationRotation";
import {NavigationZoom} from './NavigationZoom';
import {NavigationKeys, NavigationKeysMode} from "./NavigationKeys";
import {AnchorHelper} from './AnchorHelper';

export class AssetNavigationController extends Controller {
  private readonly _assetDimension: AssetDimension;
  private readonly _gestureHelper: GestureHelper;
  private readonly _panNavigation: NavigationPan;
  private readonly _rotationNavigation: NavigationRotation;
  private readonly _zoomNavigation: NavigationZoom;
  private readonly _keyNavigation: NavigationKeys;
  // the zoom fraction on mouse wheeling

  private readonly mouseZoomFractionUnit = 0.12;
  private anchorHelper: AnchorHelper | null = null;

  constructor(assetDimension: AssetDimension) {
    super();

    this._assetDimension = assetDimension;

    this._gestureHelper = new GestureHelper([
      NavigationType.ROTATION,
      NavigationType.PAN,
      NavigationType.ZOOM,
      NavigationType.FIRST_PERSON_ROTATION,
    ]);

    this._panNavigation = new NavigationPan(assetDimension);
    this._rotationNavigation = new NavigationRotation(assetDimension);
    this._zoomNavigation = new NavigationZoom(assetDimension);
    this._keyNavigation = new NavigationKeys(assetDimension, {
      mappingMode: NavigationKeysMode.TANGENT_FORWARD,
    });
  }

  public override onActivate(map: WebGLMap) {
    super.onActivate(map);
    this.anchorHelper = new AnchorHelper(map, this._assetDimension);
    this._keyNavigation.activate(map);
  }

  public override onDeactivate(map: WebGLMap) {
    super.onDeactivate(map);
    this._keyNavigation.deactivate();
  }

  override onDraw(geoCanvas: GeoCanvas) {
    if (!this.isFirstPersonRotation()) {
      this.anchorHelper?.paint(geoCanvas, this._gestureHelper.actionType);
    }
  }

  private isFirstPersonRotation(): boolean {
    return (
      this._gestureHelper.isFirstPersonRotation() ||
      (this._gestureHelper.isRotating() && this._gestureHelper.isModifierCtrl())
    );
  }

  public override onGestureEvent(event: GestureEvent) {
    const { map, _gestureHelper, anchorHelper } = this;
    if (!anchorHelper || !map) {
      return HandleEventResult.EVENT_IGNORED;
    }

    const { type, viewPoint, viewPosition } = event;
    if (_gestureHelper.onGestureEvent(event)) {
      this.invalidate();
    }
    const { actionType, actionStarted, actionFinished } = _gestureHelper;
    if (actionStarted) {
      // compute gizmo anchor only when it is for panning, zooming or orbiting
      anchorHelper.computeAnchor(viewPoint, actionType);
    }

    if (type === GestureEventType.MOVE) {
      //to keep hovering working correctly
      return HandleEventResult.EVENT_IGNORED;
    }

    //stop current camera animations if the user moves
    if (!_gestureHelper.isIdle()) {
      AnimationManager.removeAnimation(map.cameraAnimationKey);
    }

    const { anchor } = anchorHelper;

    if (this.isFirstPersonRotation()) {
      this._rotationNavigation.rotateAroundCameraEye(map, viewPosition);
    } else if (_gestureHelper.isRotating()) {
      this._rotationNavigation.rotateAroundPivot(map, anchor, viewPosition);
    }

    if (_gestureHelper.isPanning()) {
      this._panNavigation.panCameraOverOrthogonalPlane(map, anchor, viewPoint);
    }

    if (_gestureHelper.isZooming()) {
      const scaleFraction = _gestureHelper.getZoomFraction(
        event,
        this.mouseZoomFractionUnit
      );
      const zoomScale = scaleFraction * _gestureHelper.getSpeedMultiplier();
      const ghostMode = _gestureHelper.isModifierCtrl();
      const surfaceCrossed = this._zoomNavigation.zoomToAnchor(
        map,
        anchor,
        zoomScale,
        ghostMode
      );
      // A new zoom anchor will be computed when camera crossed surface or on zooming out
      if (zoomScale < 0 || surfaceCrossed) {
        _gestureHelper.reset();
      }
    }

    if (actionFinished) {
      this._panNavigation.reset();
      this._rotationNavigation.reset();
    }

    return HandleEventResult.EVENT_HANDLED;
  }

  /**
   * Called when a key change has been detected.
   */
  override onKeyEvent(event: KeyEvent): HandleEventResult {
    if (!this._gestureHelper.isKeyEventAccepted(event)) {
      return HandleEventResult.EVENT_IGNORED;
    }

    const result = this._keyNavigation.onKeyEvent(event);
    if (result === HandleEventResult.EVENT_HANDLED && this.map) {
      AnimationManager.removeAnimation(this.map.cameraAnimationKey);
    }
    return result;
  }
}
