import {ProcessingState, ProcessingSubStates} from './AssetPersistanceUtil';
import React, {ReactNode} from 'react';
import {
  Box,
  CircularProgress,
  Inline,
  Stack,
  Text,
  Tooltip,
} from '@digitalreality/ui';
import {CheckmarkIcon, WarningFilledIcon} from '@digitalreality/ui-icons';

interface Props {
  state: ProcessingState;
  subStates: ProcessingSubStates;
}

const stateToIcon: {[key in ProcessingState]: ReactNode} = {
  [ProcessingState.FAILED]: <WarningFilledIcon fontSize="small" />,
  [ProcessingState.IN_PROGRESS]: <CircularProgress size={18} />,
  [ProcessingState.READY]: <CheckmarkIcon fontSize="small" />,
};

function createProcessingToolTip(subStates: ProcessingSubStates) {
  const entries = Object.entries(subStates);
  if (entries.length === 0) {
    return null;
  }
  return (
    <Stack>
      {entries.map(([label, subState]) => (
        <Inline key={label} width="100%" alignItems="center" gap="8px">
          <Text sx={{flex: 1}}>{label}</Text>
          {stateToIcon[subState]}
        </Inline>
      ))}
    </Stack>
  );
}

/**
 * Component that renders an icon and tooltip corresponding to the given processing state
 */
export const ProcessingStateIcon = ({state, subStates}: Props) => {
  return (
    <Tooltip title={createProcessingToolTip(subStates)}>
      <Box>{stateToIcon[state]}</Box>
    </Tooltip>
  );
};
