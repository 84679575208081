//this file contains hardcoded values used as a workaround for known issues, ideally this would be empty

/**
 * Default vertical offset for 3D layers coming from the catalog, such that they aren't hidden under the surface.
 */
const DEFAULT_VERTICAL_OFFSET = 5

/**
 * Vertical offset for certain 3D layers coming from the catalog, to ensure that they aren't hidden under or floating
 * above the surface.
 * The keys of this object correspond with layer address id's, with the offset as corresponding value
 */
const CUSTOM_DATASET_OFFSETS: { [layerAddressId: string]: number } = {
  '82623b8f-4597-372a-bd12-73df8c045f11': 55.2, //Milan - aerial
  '20fe2db9-ceb8-3f01-8ea4-0750e2eae268': 50.6, //Munich - aerial
  '03f153f2-d5ff-381d-84ff-e51d23f56977': 13.7, //newport.3dtiles
  '90d2c70f-c40d-383c-a548-9eef1179b9cf': 31.4, //us-ri-newport
  '65fbdb63-c712-36ca-b965-9639c5cf905f': 15.3, //Munich - supermesh
  '21a5c42e-acef-3796-9694-729050914328': 15.6, //NOB21
  '6a7776ef-2839-3550-84e4-3f15f40ce2d0': 18.2, //London - supermesh
  'ca47ec37-f6fb-3eb3-a0c2-73f1485f7f03': -25.9, //New York - supermesh
  '903c6972-6ccb-3471-be44-ed06588b2d70': 23.2, //St. Gallen - aireal
  '329ce3bc-c83d-381f-9106-bd9a31cdf03c': 39.3, //Lucerne - aireal
}

/**
 * Returns the vertical offset associated with the given layer address id, to ensure that the layer isn't hidden under
 * or floating above the surface.
 */
export function getVerticalLayerOffset(layerAddressId: string): number {
  return CUSTOM_DATASET_OFFSETS[layerAddressId] ?? DEFAULT_VERTICAL_OFFSET
}
