export interface Vector3Interface {
  x: number;
  y: number;
  z: number;
}
export class Vector3 {

  private _x: number;
  private _y: number;
  private _z: number;

  constructor(x?: number, y?: number, z?: number) {
    this._x = x || 0;
    this._y = y || 0;
    this._z = z || 0;
  }

  get z(): number {
    return this._z;
  }

  get y(): number {
    return this._y;
  }

  get x(): number {
    return this._x;
  }

  set z(value: number) {
    this._z = value;
  }

  set y(value: number) {
    this._y = value;
  }

  set x(value: number) {
    this._x = value;
  }

  subVectors(a: Vector3Interface, b: Vector3Interface): Vector3 {
    this._x = a.x - b.x;
    this._y = a.y - b.y;
    this._z = a.z - b.z;
    return this;
  }

  sub(v: Vector3, w?: Vector3): Vector3 {
    if (w !== undefined) {
      return this.subVectors(v, w);
    }
    this._x -= v._x;
    this._y -= v._y;
    this._z -= v._z;
    return this;
  }

  addVectors(a: Vector3Interface, b: Vector3Interface): Vector3 {
    this._x = a.x + b.x;
    this._y = a.y + b.y;
    this._z = a.z + b.z;
    return this;
  }

  add(v: Vector3, w?: Vector3): Vector3 {
    if (typeof w !== "undefined") {
      return this.addVectors(v, w);
    }
    this._x += v._x;
    this._y += v._y;
    this._z += v._z;
    return this;
  }
}