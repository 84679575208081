import {CircularProgressProps} from '@mui/material';
import {Absolute, CircularProgress, Relative, Text} from '@digitalreality/ui';
import React from 'react';

interface Props extends CircularProgressProps {
  value: number;
}

/**
 * Circular progress component that also shows the progress as a number
 */
export const CircularProgressWithLabel = ({value, size, ...rest}: Props) => {
  return (
    <Relative sx={{display: 'inline-flex'}}>
      <CircularProgress
        variant="determinate"
        value={value}
        size={size}
        {...rest}
      />
      <Absolute top="50%" left="50%" sx={{transform: 'translate(-50%, -50%)'}}>
        <Text
          fontSize={typeof size === 'number' ? size / 2 : size}
        >{`${Math.round(value)}`}</Text>
      </Absolute>
    </Relative>
  );
};
