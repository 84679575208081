import { Affine3DTransformation } from '@luciad/ria/transformation/Affine3DTransformation'
import {
  color,
  defaultColor,
  multiply,
} from '@luciad/ria/util/expression/ExpressionFactory'
import { PointCloudPointShape } from '@luciad/ria/view/style/PointCloudPointShape'
import { TileSet3DLayer } from '@luciad/ria/view/tileset/TileSet3DLayer'
import { ScalingMode } from '@luciad/ria/view/style/ScalingMode'

const QUALITY_FACTOR = 0.3

export const setPointcloudSettings = (
  layer: TileSet3DLayer,
  geoLocation: Affine3DTransformation,
  accentColor?: string
) => {
  return (
    (layer.qualityFactor = QUALITY_FACTOR),
    (layer.transformation = geoLocation),
    (layer.pointCloudStyle.pointShape = PointCloudPointShape.DISC),
    (layer.pointCloudStyle.pointSize = {
      mode: ScalingMode.WORLD_SIZE,
      minimumPixelSize: 2,
      worldSize: 0.02,
    }),
    accentColor
      ? (layer.pointCloudStyle.colorExpression = multiply(
          defaultColor(),
          color(accentColor)
        ))
      : ''
  )
}
