import {useEffect, useState} from 'react';
import {
  getAssetProcessingState,
  ProcessingState,
  ProcessingSubStates,
} from './AssetPersistanceUtil';
import {getApolloClient} from '../../util/PersistanceUtil';
import {getResolvedBearerToken} from '../../authentication/AuthenticationUtil';

export interface ProcessingAsset {
  groupedAssetId: string;
  name: string;
  state: ProcessingState;
  subStates: ProcessingSubStates;
}

const DEFAULT_UPDATE_INTERVAL = 2000; //in ms

/**
 * React hook that returns the processing state of the given assets
 */
export function useProcessingAssets(
  assets: {groupedAssetId: string; name: string}[],
  updateInterval = DEFAULT_UPDATE_INTERVAL
) {
  const [processingAssets, setProcessingAssets] = useState<ProcessingAsset[]>(
    []
  );

  useEffect(() => {
    async function updateState() {
      const states: ProcessingAsset[] = [];
      const promises: Promise<void>[] = [];
      for (const {groupedAssetId, name} of assets) {
        promises.push(
          getAssetProcessingState(
            getApolloClient(getResolvedBearerToken()),
            groupedAssetId
          )
            .then(({state, subStates}) => {
              states.push({groupedAssetId, name, state, subStates});
            })
            .catch() //for now, we assume that errors are caused by the asset being deleted
        );
      }
      await Promise.all(promises);
      setProcessingAssets(
        states.sort(
          (assetA, assetB) =>
            assets.findIndex(
              (asset) => asset.groupedAssetId === assetA.groupedAssetId
            ) -
            assets.findIndex(
              (asset) => asset.groupedAssetId === assetB.groupedAssetId
            )
        )
      );
    }

    const handle = setInterval(updateState, updateInterval);
    updateState();

    return () => {
      clearInterval(handle);
    };
  }, [
    assets
      .map(({groupedAssetId}) => groupedAssetId)
      .sort()
      .join(', '),
  ]);

  return processingAssets;
}
